import * as yup from "yup";

 export  const businesDetailsSchema = yup.object().shape({
  dealerName: yup.string().required("Business Legal Name  is required"),
  addressLine1:yup.string().required("Address Line 1 is required"),
   contactEmail: yup
     .string()
     .required("Email is required")
     .matches(
       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
       "Invalid email"
     ),
     zipPostalCode:yup.string().required("Zip is required"),
     contactName:yup.string().required("Name  is required"),
     countryRegion:yup.string().required("Country/Region is required  "),
     StateProvince:yup.string().required("State is required  "),
   // // role:yup.string().required("Role is required"),
  //  StateProvince: yup.string().required("State is Required"),
  //  countryRegion: yup.string().required("CountryRegion is Required"),
   city: yup.string().required("City is Required"),
  //  StateProvince: yup.object().shape({
  //    label: yup.string().required("State is Required "),
  //    value: yup.string().required("State is Required"),
  //  }),
   // .nullable() // for handling null value when clearing options via clicking "x"
   // .required("Role is required ")
 });
