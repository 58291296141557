export const customStyles1 = {
  menuList: (styles) => ({
    ...styles,
    background: "white",
  }),
  singleValue : (styles)=>({
    ...styles,
    fontSize: '12px',
    textAlign: 'left',
  }),
  placeholder : (styles)=>({
    ...styles,
    fontSize: '12px',
    textAlign: 'left',
  }),
  
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    // "&:hover": {
    //           backgroundColor: state.isSelected ? "#D9E9F3" : "#D9E9F3",
    //         },
    background: isSelected
      ? "hsla(351, 84%, 46%, 1)"
      : isFocused
      ? "#EAEAEA"
      : undefined,
    zIndex: 1,
    fontSize: 12,
    // color: 'black !important',
    cursor: "pointer",
  }),
  valueContainer: (base) => ({
    ...base,
    // minWidth: 172,
  }),
  menu: (base) => ({
    ...base,
    zIndex: 100,

    // height:"60px !important",
    border: "none",
  }),
  control: (base) => ({
    ...base,
    
    minHeight: 42,
    minWidth: 92,
    minWidth: 223,
    border: 0,
    // This line disable the blue border
    boxShadow: "none",
    height: 42,
    // border:"none",
    borderRadius: "none",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#CE0E2D",
    "&:hover": {
      color: "#CE0E2D",
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
  display: "none",
  })
};
