import { Box, Grid, Divider } from "@mui/material";

import { useStore } from "../store/home";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import "./AddEmployeeAccount.css";
import {
  StyledRequiredField,
  StyledShippingHeading,
  StyledShippingSubHeading,
} from "./StyledComponents/ShippingAddressStyled";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { showErrorToast, showSuccessToast } from "../utils/Toaster";
import {
  addEmployeeAccount,
  deleteDealerUser,
  updateProfileInformation,
} from "../api/posts";
import Select from "react-select";
import Loader from "../commenComp/Loader";
import { useEffect, useState } from "react";

import { DevTool } from "@hookform/devtools";
import { ErrorMessageStyled } from "./StyledComponents/CommonStyled";
import { ContainerDivStyled } from "./StyledComponents/BusinessDetailsStyled";
import TabletViewBlackContainer from "./TabletViewBlackContainer";
import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
  minLength,
} from "react-phone-number-input";

import "react-phone-number-input/style.css";
import commonMessages from "../commenComp/CommonMessages";
import { addEmployeeAcntSchema } from "../utils/schemas/addEmployeeAccountSchema";
import { customStyles } from "../utils/ReactSelectCustomStyles";
import { ReactComponent as RedCrossIcon } from "../Images/RedCrossIcon.svg";
import IlmorModal from "../commenComp/IlmorModal";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import { useNavigate } from "react-router-dom";

const textInputStyle = {
  color: "#FFF",
  fontFamily: "calibri",
  fontSize: "50px",
  borderBottomWidth: 1,
  borderBottomColor: "rgba(255, 255, 255, 0.3)",
  marginBottom: 20,
};



const AddEmployeeAccount = ({
  setIsEmployeeAccountsbtnClicked,
  prepopulateEditDealerUser,
  editID,
}) => {
  const [countryCode, setCountryCode] = useState("");
  const [cancelDialog, setCancelDialog] = useState(false);
  const [removeIdTablerow, setRemoveIdTablerow] = useState();
  const [parsedNumberTest, setParsedNumberTest] = useState();

  const [isValid, setIsValid] = useState(false);
  const [nationalNumber, setNationalNumber] = useState("");
  const [completePhoneNumber, setCompletePhoneNumber] = useState("");
  const [countryTextCode, setTextCountryCode] = useState("");

  const [textNationalNumber, setTextNationalNumber] = useState("");
  const [completeTextPhoneNumber, setCompleteTextPhoneNumber] = useState("");

  const { currentDevice, myProfile, token } = useStore();
  const { text, setText } = useNonPersistedStore();
  function addSpaceBeforeCapitalLetters(str) {
    return str.replace(/([A-Z])/g, " $1");
  }
  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addEmployeeAcntSchema),
    defaultValues: {
      firstname:
        prepopulateEditDealerUser &&
        prepopulateEditDealerUser?.length > 0 &&
        prepopulateEditDealerUser[0]?.firstname,
      lastname:
        prepopulateEditDealerUser &&
        prepopulateEditDealerUser?.length > 0 &&
        prepopulateEditDealerUser[0]?.lastname,
      userEmail:
        prepopulateEditDealerUser?.length > 0
          ? prepopulateEditDealerUser[0]?.email
          : "",
      role:
        prepopulateEditDealerUser?.length > 0
          ? {
              label: addSpaceBeforeCapitalLetters(
                prepopulateEditDealerUser[0]?.role
              ),
              value: prepopulateEditDealerUser[0]?.role,
            }
          : {},
      status:
        prepopulateEditDealerUser?.length > 0
          ? {
              label: `${
                prepopulateEditDealerUser[0]?.status == true
                  ? "Active"
                  : "Inactive"
              }`,
              value: `${prepopulateEditDealerUser[0]?.status}`,
            }
          : {},
      primaryPhone:
        prepopulateEditDealerUser?.length > 0 &&
        prepopulateEditDealerUser[0]?.phone
          ? prepopulateEditDealerUser[0]?.completePhoneNumber
          : "",
      textNotificationPhone:
        prepopulateEditDealerUser?.length > 0 &&
        prepopulateEditDealerUser[0]?.textNotificationPhone
          ? prepopulateEditDealerUser[0]?.completeTextPhoneNumber
          : "",
      sendTextNotification:
        prepopulateEditDealerUser?.length > 0
          ? prepopulateEditDealerUser[0]?.sendTextNotification
          : false,
      //   username: localStorage.getItem("email") || "",
      //   password: localStorage.getItem("password") || "",
    },
  });

  let userRole = [
    {
      label: "Purchasing Manager",
      value: "PurchasingManager",
    },
    {
      label: "Service Technician",
      value: "ServiceTechnician",
    },
    { label: "Admin", value: "Admin" },
  ];
  let statusSelect = [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ];
  const navigate = useNavigate();

  const mutation = useMutation((post) => addEmployeeAccount(post, token), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
          showSuccessToast(result?.data?.msg);
          setIsEmployeeAccountsbtnClicked(false);
          navigate({
            pathname: "/account/mgmt/accounts",
          });
        } else {
          showErrorToast(result?.data?.msg);
        }
      }
    },
    onError: (error) => {},
  });
  const removeDealerMutation = useMutation(
    (post) => deleteDealerUser(post, token),
    {
      onSuccess: (result) => {
        if (result) {
          if (result?.data?.success) {
            showSuccessToast(result?.data?.msg);
            setIsEmployeeAccountsbtnClicked(false);
            navigate({
              pathname: "/account/mgmt/accounts",
            });
          } else {
            showErrorToast(result?.data?.msg);
          }
        }
      },
      onError: (error) => {},
    }
  );

  const updateProfileInformationMutation = useMutation(
    (post) => updateProfileInformation(post, token),
    {
      onSuccess: (result) => {
        if (result) {
          if (result.data.success) {
            showSuccessToast(result?.data?.msg);
            setIsEmployeeAccountsbtnClicked(false);
            navigate({
              pathname: "/account/mgmt/accounts",
            });
          } else {
            showErrorToast(result?.data?.msg);
          }
        }
      },
      onError: (error) => {},
    }
  );

  const onSubmit = (data, event) => {
    event.preventDefault();
    // if((nationalNumber?.length<5 || nationalNumber?.length === undefined) || (textNationalNumber?.length === undefined || textNationalNumber?.length<5)){
    //   showErrorToast("Enter Minimum of 5 characters in Phone number Field")

    // }else{
    if (prepopulateEditDealerUser && prepopulateEditDealerUser?.length > 0) {
      updateProfileInformationMutation.mutate({
        ...data,
        role: data?.role?.value,
        //  dealerName: myProfile?.data?.bussinessDetailsInfo?.dealerName,
        status: data?.status?.value,
        profileType: "other",
        countryCode: countryCode,
        primaryPhone: nationalNumber,
        completePhoneNumber: completePhoneNumber,
        textNotificationPhone: textNationalNumber,

        countryTextCode: countryTextCode,
        completeTextPhoneNumber: completeTextPhoneNumber,
        id: editID,
      });
    } else {
      mutation.mutate({
        ...data,
        role: data?.role?.value,
        status: data?.status?.value,
        dealerName: myProfile?.bussinessDetailsInfo?.dealerName,
        countryCode: countryCode,
        primaryPhone: nationalNumber,
        completePhoneNumber: completePhoneNumber,
        textNotificationPhone: textNationalNumber,
        countryTextCode: countryTextCode,
        completeTextPhoneNumber: completeTextPhoneNumber,
      });
    }
    // }
  };
  useEffect(() => {
    if (prepopulateEditDealerUser && prepopulateEditDealerUser?.length > 0) {
      if (prepopulateEditDealerUser[0]?.phone) {
        setCountryCode(prepopulateEditDealerUser[0]?.countryCode);

        setCompletePhoneNumber(
          prepopulateEditDealerUser[0]?.completePhoneNumber
        );

        setNationalNumber(prepopulateEditDealerUser[0]?.phone);
      }

      if (prepopulateEditDealerUser[0]?.textNotificationPhone) {
        setTextCountryCode(prepopulateEditDealerUser[0]?.countryTextCode);

        setCompleteTextPhoneNumber(
          prepopulateEditDealerUser[0]?.completeTextPhoneNumber
        );

        setTextNationalNumber(
          prepopulateEditDealerUser[0]?.textNotificationPhone
        );
      }
    }
  }, []);

  const handlePhoneNumberChange = (data, field) => {
    if (!data) {
      setCompletePhoneNumber("");
      setNationalNumber("");
      setCountryCode("");
      return;
    }

    try {
      const parsedNumber = parsePhoneNumber(data?.toString());

      // parsedNumberTest,setParsedNumberTest
      // setValue("primaryPhone",parsedNumber?.number)
      setCountryCode(parsedNumber?.countryCallingCode);

      setCompletePhoneNumber(parsedNumber?.number);

      setNationalNumber(parsedNumber?.nationalNumber);
    } catch (error) {}
  };

  const handleTextPhoneNumberChange = (data, field) => {
    if (!data) {
      setCompleteTextPhoneNumber("");
      setTextNationalNumber("");
      setTextCountryCode("");
      return;
    }
    try {
      const parsedNumber = parsePhoneNumber(data?.toString());

      setTextCountryCode(parsedNumber?.countryCallingCode);

      setCompleteTextPhoneNumber(parsedNumber?.number);
      setTextNationalNumber(parsedNumber?.nationalNumber);
    } catch (error) {}
  };
  const handleCancel = (id) => {
    setCancelDialog(!cancelDialog);
    setRemoveIdTablerow(id);
  };
  const handleOkay = () => {
    removeDealerMutation?.mutate({ email: removeIdTablerow });
    setCancelDialog(!cancelDialog);
  };

  return (
    <>
      <IlmorModal
        isopen={cancelDialog}
        handleOkay={handleOkay}
        handleClose={handleCancel}
        message="DELETE USER"
        messageDetail={"The user will be removed immediately."}
        secondMessage={"Any existing orders/lists will not be affected."}
        fisrtButton={"Cancel"}
        secondButton={"Delete"}
      ></IlmorModal>
      <Box
        sx={{
          background: "#eaeaea",
          width: "100%",
        }}
      >
        {(mutation.isLoading || removeDealerMutation.isLoading) && <Loader></Loader>}
        {updateProfileInformationMutation.isLoading && <Loader></Loader>}

        <StyledShippingHeading
          sx={{
            padding:
              currentDevice == "Tablet"
                ? "24px 28px"
                : currentDevice == "Mobile"
                ? "24px 20px"
                : "26px 60px 0px 60px ",
          }}
          currentDevice={currentDevice}
        >
          {prepopulateEditDealerUser && prepopulateEditDealerUser?.length > 0
            ? "Edit Employee Account"
            : "  Add Employee Account"}
        </StyledShippingHeading>

        {currentDevice == "Tablet" && (
          <TabletViewBlackContainer currentDevice={currentDevice} />
        )}
        <ContainerDivStyled currentDevice={currentDevice}>
          <Grid container>
            <Grid item xs={12} sm={12} md={9}>
              <Grid
                container
                spacing={1}
                rowSpacing={3}
                pt={currentDevice == "Tablet" ? 3 : 0}
              >
                <Grid item sm={12} md={12}>
                  <div className="kngkjhr656">
                    <StyledShippingSubHeading>
                      {prepopulateEditDealerUser &&
                      prepopulateEditDealerUser?.length > 0
                        ? "Use the form below to edit the users information."
                        : commonMessages?.addEmployeeAccountSubHeading}
                    </StyledShippingSubHeading>{" "}
                  </div>
                </Grid>
                <Grid item sm={8} md={12}>
                  <StyledRequiredField>
                    {" "}
                    {commonMessages?.addEmployeeAccountRequiredField}
                  </StyledRequiredField>
                </Grid>
              </Grid>

              {currentDevice != "Desktop" && (
                <Divider sx={{ margin: "44px 0px" }} />
              )}
              {currentDevice == "Desktop" && (
                <Divider sx={{ margin: "26px 0px" }} />
              )}
              <Grid container spacing={1} className="tabletEmployee">
                <Grid item md={12} className="tabletPersonalInfoEmpAccount">
                  <StyledShippingSubHeading sx={{ fontWeight: "700" }}>
                    {commonMessages?.addEmployeeAccountPersonalInformation}
                  </StyledShippingSubHeading>
                </Grid>
                <Grid item sm={12} md={12}>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={`form shippingForm`}
                    noValidate
                  >
                    <Grid container spacing={1}>
                      <Grid
                        item
                        sm={6}
                        xs={6}
                        md={6}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <h5
                            style={{
                              textTransform: "capitalize",
                              marginRight: "5px",
                            }}
                            className={`labelDesktopAddShippingRequired`}
                          >
                            First Name
                          </h5>
                        </div>
                        <input
                          // maxlength="5"
                          // style={{ border: "none" }}
                          className={`InputFieldShippingAddress`}
                          type="text"
                          // value={email}
                          name="firstname"
                          // onChange={handleEmailChange}
                          {...register("firstname")}
                        ></input>
                        {errors?.firstname && (
                          <ErrorMessageStyled>
                            {errors.firstname?.message}
                          </ErrorMessageStyled>
                        )}
                      </Grid>
                      <Grid
                        item
                        sm={6}
                        xs={6}
                        md={6}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <h5 className={`labelDesktopAddShippingRequired`}>
                            Last Name
                          </h5>
                        </div>
                        <input
                          // style={{ border: "none" }}
                          className={`InputFieldShippingAddress`}
                          type="text"
                          // value={email}
                          name="lastname"
                          // onChange={handleEmailChange}
                          {...register("lastname")}
                        ></input>
                        {errors?.lastname && (
                          <ErrorMessageStyled>
                            {errors.lastname?.message}
                          </ErrorMessageStyled>
                        )}
                      </Grid>
                    </Grid>

                    <div>
                      <h5 className={`labelDesktopAddShippingRequired`}>
                        Email
                      </h5>
                    </div>
                    <input
                      // style={{ border: "none" }}
                      className={`InputFieldShippingAddress`}
                      type="text"
                      // value={email}
                      name="userEmail"
                      disabled={
                        prepopulateEditDealerUser &&
                        prepopulateEditDealerUser?.length > 0
                          ? true
                          : false
                      }
                      // onChange={handleEmailChange}
                      {...register("userEmail")}
                    />
                    {errors?.userEmail && (
                      <ErrorMessageStyled>
                        {errors.userEmail?.message}
                      </ErrorMessageStyled>
                    )}
                    <div>
                      <h5 className={`labelDesktopAddShipping`}>
                        Primary Phone
                      </h5>
                    </div>
                    {/* <input
                    className={`InputFieldShippingAddress`}
                    type="text"
                    name="primaryPhone"
                    {...register("primaryPhone")}
                  /> */}
                    <Controller
                      // rules={{ required: true }}
                      name="primaryPhone"
                      control={control}
                      render={({ onChange, value, onBlur, ref, field }) => {
                        return (
                          <PhoneInput
                            {...field}
                            // value={userRole.find((c) => c.value === value)}
                            // onChange={(selectedOption) => {
                            //   handleSelectChange(selectedOption?.label,selectedOption?.value);
                            // }}
                            // countries =  {["RU", "UA", "KZ","IN","PK","US","NZ"]}
                            placeholder="Enter Phone Number"
                            // onChange={(data)=>handlePhoneNumberChange(data,field)}
                            onChange={handlePhoneNumberChange}
                            //  international
                            countryCallingCodeEditable={false}
                            addInternationalOption={false}
                            defaultCountry="US"
                            className="ReactPhoneNumInputt"
                            minLength="7"
                            limitMaxLength={true}
                            style={{
                              backgroundColor: "white",
                              border: "none",
                              padding: "0px 10px",
                            }}
                          />
                        );
                      }}
                    />
                    {errors?.primaryPhone && (
                      <ErrorMessageStyled>
                        {errors.primaryPhone?.message}
                      </ErrorMessageStyled>
                    )}
                    {/* <Box
                      sx={{
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        className={`inputCheckboxGlobal`}
                        type="checkbox"
                        id="notifications"
                        name="sendTextNotification"
                        // value={true}
                        {...register("sendTextNotification")}
                      />
                      <label
                        className={`labelCheckboxGlobal`}
                        for="notifications"
                      >
                        Send Text Notifications to this address
                      </label>
                    </Box> */}
                    {/* <div>
                    <h5 className={`labelDesktopAddShipping`}>
                      Text Notifications
                    </h5>
                  </div>
                  <input
                    className={`InputFieldShippingAddress`}
                    type="text"
                    name="textNotificationPhone"
                    {...register("textNotificationPhone")}
                  /> */}
                    {/* <div>
                      <h5 className={`labelDesktopAddShipping`}>
                        Text Notifications
                      </h5>
                    </div> */}

                    {/* <Controller
                      // rules={{ required: true }}
                      name="textNotificationPhone"
                      control={control}
                      render={({ onChange, value, onBlur, ref, field }) => {
                        return (
                          <PhoneInput
                            {...field}
                            // value={customPhoneNumber}
                            // value={userRole.find((c) => c.value === value)}
                            // onChange={(selectedOption) => {
                            //   handleSelectChange(selectedOption?.label,selectedOption?.value);
                            // }}
                            // countries =  {["RU", "UA", "KZ","IN","PK","US","NZ"]}
                            placeholder="Enter Phone Number"
                            // onChange={(data)=>handlePhoneNumberChange(data,field)}
                            onChange={handleTextPhoneNumberChange}
                            // international
                            countryCallingCodeEditable={false}
                            addInternationalOption={false}
                            defaultCountry="US"
                            className="ReactPhoneNumInputt"
                            // minLength="7"
                            limitMaxLength={true}
                            style={{
                              backgroundColor: "white",
                              border: "none",
                              padding: "0px 10px",
                            }}
                          />
                        );
                      }}
                    />
                    {errors?.textNotificationPhone && (
                      <ErrorMessageStyled>
                        {errors.textNotificationPhone?.message}
                      </ErrorMessageStyled>
                    )} */}
                    <div>
                      <h5 className={`labelDesktopAddShippingRequired`}>
                        User Role
                      </h5>
                    </div>
                    <Controller
                      // rules={{ required: true }}
                      name="role"
                      control={control}
                      render={({ onChange, value, ref, field }) => {
                        return (
                          <Select
                            {...field}
                            isSearchable={false}
                            options={userRole}
                            // value={userRole.find((c) => c.value === value)}
                            // onChange={(selectedOption) => {
                            //   handleSelectChange(selectedOption?.label,selectedOption?.value);
                            // }}
                            className="jkdhs"
                            styles={customStyles}
                            // menuIsOpen={true}
                            // defaultValue={{
                            //   label:
                            //     prepopulateEditDealerUser &&
                            //     prepopulateEditDealerUser?.length > 0
                            //       ? prepopulateEditDealerUser[0]?.role
                            //       : "",
                            //   value:
                            //     prepopulateEditDealerUser &&
                            //     prepopulateEditDealerUser?.length > 0
                            //       ? prepopulateEditDealerUser[0]?.role
                            //       : "",
                            // }}
                          />
                        );
                      }}
                    />

                    {errors.role && (
                      <ErrorMessageStyled>
                        {errors.role.label?.message}
                      </ErrorMessageStyled>
                    )}
                    <div>
                      <h5 className={`labelDesktopAddShipping`}>
                        Account Status
                      </h5>
                    </div>
                    <Controller
                      // rules={{ required: true }}
                      name="status"
                      control={control}
                      render={({ onChange, value, ref, field }) => {
                        return (
                          <Select
                            {...field}
                            options={statusSelect}
                            isSearchable={false}
                            // value={statusSelect.find((c) => c.value === value)}
                            // onChange={(selectedOption) => {
                            //   handleSelectChange(selectedOption?.label,selectedOption?.value);
                            // }}
                            styles={customStyles}
                            className="jkdhs"
                            // menuIsOpen={true}
                            // defaultValue={{
                            //   label: `${
                            //     prepopulateEditDealerUser &&
                            //     prepopulateEditDealerUser?.length > 0
                            //       ? prepopulateEditDealerUser[0]?.status
                            //       : ""
                            //   }`,
                            //   value: `${
                            //     prepopulateEditDealerUser &&
                            //     prepopulateEditDealerUser?.length > 0
                            //       ? prepopulateEditDealerUser[0]?.status
                            //       : ""
                            //   }`,
                            // }}
                          />
                        );
                      }}
                    />

                    {errors.status && (
                      <ErrorMessageStyled>
                        {errors.status.label?.message}
                      </ErrorMessageStyled>
                    )}

                    {/* <div>
            <h5 className={`labelDesktopAddShipping`}>Account Status</h5>
          </div>
          <input
            className={`InputFieldShippingAddress`}
            type="text"
            name="status"
            {...register("status")}
          /> */}
                    {currentDevice == "Mobile" && (
                      <Divider sx={{ margin: "30px 0px 30px 0px" }} />
                    )}
                    {currentDevice == "Tablet" && (
                      <Divider sx={{ margin: "35px 0px 36px 0px" }} />
                    )}
                    {currentDevice == "Desktop" && (
                      <Divider sx={{ margin: "35px 0px 36px 0px" }} />
                    )}
                    <Box
                      className={`cancelButonBottomDesktop`}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        flexDirection: "row",
                        flexGrow: 1,
                        my: 1.5,
                        gap: "33px",

                        borderRadius: 1,
                      }}
                    >
                      <button
                        type="button"
                        style={{ marginRight: "18px" }}
                        className={`cancelButonAddShipping cancelButonCancelEmployeeRightDesktop`}
                        onClick={() => {
                          setIsEmployeeAccountsbtnClicked(false);
                          navigate({
                            pathname: "/account/mgmt/accounts",
                          });
                        }}
                      >
                        Cancel
                      </button>
                      {prepopulateEditDealerUser &&
                        prepopulateEditDealerUser?.length > 0 && (
                          <>
                            <Box
                              onClick={() => {
                                handleCancel(
                                  prepopulateEditDealerUser[0]?.email
                                );
                                // handleRemoveClick(row?.email);
                              }}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                              className="RightButtonDeleteDesktop"
                            >
                              <RedCrossIcon />
                              <button
                                type="button"
                                className={`cancelButonAddShipping`}
                              >
                                Delete User
                              </button>
                            </Box>
                          </>
                        )}
                      <button
                        className={`ilmorButon`}
                        //  onClick={handleSignIn}
                      >
                        {prepopulateEditDealerUser &&
                        prepopulateEditDealerUser?.length > 0
                          ? currentDevice == "Mobile"
                            ? "Update "
                            : "Update Account"
                          : " Add Account"}
                      </button>
                    </Box>
                  </form>
                </Grid>
                <DevTool control={control} />
              </Grid>
            </Grid>
          </Grid>
          <Box>
            {" "}
            {currentDevice == "Mobile" && (
              <TabletViewBlackContainer currentDevice={currentDevice} />
            )}
          </Box>
        </ContainerDivStyled>
      </Box>
    </>
  );
};

export default AddEmployeeAccount;
