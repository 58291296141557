import "./ShippingPaymentComponent.css";
import ShippingPayment from "./ShippingPayment";
import ReviewOrder from "./ReviewOrder";
import OrderComplete from "./OrderComplete";
import OrderSummary from "./OrderSummary";
import { useStore } from "../store/home";
import { ShoppingListComponent } from "./ShoppingListComponent";
import { ShoppingListComponentOrderDetail } from "./ShoppingListComponentOrderDetail";
import { Button, Grid } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import IlmorModal from "../commenComp/IlmorModal";
import RemoveIcon from "@mui/icons-material/Remove";
import { useLocation, useNavigate } from "react-router-dom";
import classnames from "classnames";
import AddIcon from "@mui/icons-material/Add";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { StyledOrderHistoryHeading } from "../components/StyledComponents/OrderHistoryStyled";
import commonMessages from "../commenComp/CommonMessages";
import ShippingPaymentOrder from "./ShippingPaymentOrder";
import OrderSummaryOrder from "./OrderSummaryOrder";
import { useMutation } from "@tanstack/react-query";
import { authorizeNet, cencelOrder } from "../api/posts";
import { showErrorToast, showSuccessToast } from "../utils/Toaster";
import Loader from "../commenComp/Loader";
import { ActiveInactiveStyled } from "./StyledComponents/EmployeeAccountStyled";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import TabletViewBlackContainer from "./TabletViewBlackContainer";

const ShippingPaymentComponentForOrderDetail = ({
  reviewOrder,
  orderDetail,
  modify,
  orderDetailData,
  defaultCardData,
  cencel,
  returnOrder,
}) => {
  const navigate = useNavigate();
  const { myProfile, setSideMenuAccount, token, currentDevice } = useStore();
  let message1 = ` ${commonMessages?.shippingPaymentComponentForOrderDetailMessageOneFirst}`;
  let message2 = ` ${commonMessages?.shippingPaymentComponentForOrderDetailMessageOneSecond}`;
  let message3 = ` ${commonMessages?.shippingPaymentComponentForOrderDetailMessageOneThird}`;
  let message4 = ` ${commonMessages?.shippingPaymentComponentForOrderDetailMessageOneForth}`;

  if (cencel) {
    message1 = ` ${commonMessages?.shippingPaymentComponentForOrderDetailMessageTwoFirst} `;
    message2 = `${commonMessages?.shippingPaymentComponentForOrderDetailMessageTwoSecond}`;
    message3 = ` `;
    message4 = ` `;
  }
  if (returnOrder) {
    message1 = `${commonMessages?.shippingPaymentComponentForOrderDetailMessagehree}`;
    message2 = ` `;
    message3 = ` `;
    message4 = ` `;
  }

  const [cancelDialog, setCancelDialog] = useState(false);
  const [orderDetailsSeparate, setOrderDetailsSeparate] = useState();
  const [orderDetailsProcessing, setOrderDetailsProcessing] = useState();
  const [orderDetailsReturn, setOrderDetailsRetuen] = useState();
  const [urlAndTrackingNumber, setUrlAndTrackingNumber] = useState();

  const handleClose = () => {
    setCancelDialog(!cancelDialog);
  };

  const refundAPI = useMutation((post) => authorizeNet(post), {
    onSuccess: (result) => {
      if (result?.data?.transactionResponse?.responseCode === "1") {
        showSuccessToast(
          // result.data.transactionResponse.messages[0].description
          "Order is cancelled . Refund is initiated"
        );
        setSideMenuAccount("orderDefault");
        navigate({
          pathname: "/account/orders",
        });
      } else {
        showErrorToast(result.data.messages.message[0].text);
      }
    },
    onError: (error) => {},
  });

  const voidTransaction = useMutation((post) => authorizeNet(post), {
    onSuccess: (result) => {
      if (result?.data?.transactionResponse?.responseCode === "1") {
        showSuccessToast(
          // result.data.transactionResponse.messages[0].description
          "Order is cancelled . Refund is initiated"
        );
        setSideMenuAccount("orderDefault");
        navigate({
          pathname: "/account/orders",
        });
      } else {
        showErrorToast(result.data.messages.message[0].text);
      }
    },
    onError: (error) => {},
  });
  const checkStatus = useMutation((post) => authorizeNet(post), {
    onSuccess: (result) => {
      if (result.data.messages.resultCode === "Ok") {
        if (
          result?.data?.transaction?.transactionStatus !== "settledSuccessfully"
        ) {
          const obj = {
            createTransactionRequest: {
              merchantAuthentication: {
                name: process.env.REACT_APP_MERCHANT_NAME,
                transactionKey: process.env.REACT_APP_MERCHANT_TRANSACTION_KEY,
              },
              refId: orderDetailData?.orderId,
              transactionRequest: {
                transactionType: "voidTransaction",
                refTransId: orderDetailData?.paymentInfo?.transactionId,
              },
            },
          };
          voidTransaction.mutate(obj);
        } else {
          let objLine = {}
          let arrLine = []
          if(orderDetailData?.items && orderDetailData?.items &&  orderDetailData?.items.length > 0){
            orderDetailData?.items.forEach((item)=>{
              let line = {}
              line.itemId = item?.partId
              line.name = item?.partName ? item?.partName.slice(0,20) : ''
              line.description = item?.partDescription ? item?.partDescription.slice(0,20) : ''
              line.quantity = item?.partQty
              line.unitPrice = item?.partDiscountCost
              arrLine.push(line)
            })
          }
          objLine.lineItem = arrLine
          const chargeProfile = {
            createTransactionRequest: {
              merchantAuthentication: {
                name: process.env.REACT_APP_MERCHANT_NAME,
                transactionKey: process.env.REACT_APP_MERCHANT_TRANSACTION_KEY,
              },
              refId: orderDetailData.orderId,
              transactionRequest: {
                transactionType: "refundTransaction",
                amount: orderDetailData?.paymentInfo?.Amount,
                payment: {
                  creditCard: {
                    cardNumber: defaultCardData.card.replace(/X/g, ""),
                    expirationDate: "XXXX",
                  },
                },
                lineItems:objLine,
                refTransId: orderDetailData?.paymentInfo?.transactionId,
              },
            },
          };
          refundAPI.mutate(chargeProfile);
        }
      } else {
        showErrorToast("Could not find status of your payment");
      }
    },
    onError: (error) => {},
  });

  const handle = () => {
    const status = {
      getTransactionDetailsRequest: {
        merchantAuthentication: {
          name: process.env.REACT_APP_MERCHANT_NAME,
          transactionKey: process.env.REACT_APP_MERCHANT_TRANSACTION_KEY,
        },
        transId: orderDetailData?.paymentInfo?.transactionId,
      },
    };
    checkStatus.mutate(status);
  };

  const cencelOrderAPI = useMutation((post) => cencelOrder(post, token), {
    onSuccess: (result) => {
      if (result.data.success) {
        if (result?.data?.refundInfo?.profileInformationId) {
          handle(result);
        } else {
          setSideMenuAccount("orderDefault");
          navigate({
            pathname: "/account/orders",
          });
          showSuccessToast("Order is succesfully cancelled");
        }
      } else {
        showErrorToast("The Order is already cancelled or does not exist");
      }
    },
    onError: (error) => {},
  });

  const handleCancelOrder = () => {
    setCancelDialog(false);
    const obj = {
      orderId: orderDetailData.id,
    };
    cencelOrderAPI.mutate(obj);
  };
  const [returnArr, setreturnArr] = useState([]);
  useEffect(() => {
    if (
      typeof orderDetailData?.items === "object" &&
      orderDetailData?.items !== null
    ) {
      if (!returnOrder) {
        Object.entries(orderDetailData?.items).forEach(([key, item]) => {
          if (key === "shipped") {
            setOrderDetailsSeparate(item);
          }
          if (key === "processing") {
            if (orderDetailData?.orderStatus === "Partially Shipped") {
              if (item && item.length > 0) {
                item.forEach((q) => {
                  q.partQty = q.remaingQty;
                });
              }
            }
            setOrderDetailsProcessing(item);
          }
          if (key === "returned") {
            setOrderDetailsRetuen(item);
          }
        });
      } else {
        let arr = [];
        Object.values(orderDetailData?.items).forEach((item) => {
          if (item instanceof Array) {
            if (item && item.length > 0) {
              arr.push(item);
            }
          } else {
            Object.values(item).forEach((q) => {
              if (q && q.length > 0) {
                q.forEach((ob) => {
                  let falg = true;
                  arr.forEach((prdo) => {
                    if (prdo.partId === ob.partId) {
                      falg = false;
                      prdo.partQty += ob.partQty;
                    }
                  });
                  if (falg) {
                    arr.push(ob);
                  }
                });
              }
            });
          }
        });
        setreturnArr(arr);
      }
    }
  }, [orderDetailData?.items]);

  const [expanded, setExpanded] = useState(false);
  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    // if (orderDetailsSeparate ) {
    //   alert("lsdnvklsdvn")
    //   const keys = orderDetailsSeparate[0]?.trackingNumber;
    //   const values = orderDetailsSeparate[0]?.trackingUrl;
    //   const result = keys?.map((key, index) => ({ link:key,title: values[index] }));
    //   setUrlAndTrackingNumber(result)
    // }
    if (orderDetailsSeparate && orderDetailsSeparate?.length > 0) {
      let newArr = orderDetailsSeparate;
      newArr.forEach((obj) => {
        let arr = [];
        obj.trackingNumber &&
          obj.trackingNumber.length > 0 &&
          obj.trackingNumber.forEach((innerObj, index) => {
            const tempObj = {};
            tempObj.link = innerObj;
            tempObj.title = obj.trackingUrl[index];
            arr.push(tempObj);
          });
        obj.dataShipped = arr;
      });
      setUrlAndTrackingNumber(newArr);
    }
  }, [orderDetailsSeparate]);

  return (
    <>
      {(refundAPI.isLoading ||
        cencelOrderAPI.isLoading ||
        voidTransaction.isLoading ||
        checkStatus.isLoading) && <Loader></Loader>}
      <IlmorModal
        isopen={cancelDialog}
        handleOkay={handleCancelOrder}
        handleClose={handleClose}
        message={"Cancel this order?"}
        fisrtButton={"Don’t Cancel"}
        secondButton={"Yes Cancel"}
      ></IlmorModal>
      <Grid container spacing={0.1}>
        {/* <div className="ShippingPaymentComponentTop"> */}
        {modify || cencel || returnOrder ? (
          <Grid item xs={12} md={8}>
            <div className="orderDetailMessage">
              {modify ? (
                <StyledOrderHistoryHeading>
                  {
                    commonMessages.shippingPaymentComponentForOrderDetailMessage1
                  }
                </StyledOrderHistoryHeading>
              ) : (
                <></>
              )}
              {modify ? (
                <StyledOrderHistoryHeading>
                  <br />
                  {
                    commonMessages.shippingPaymentComponentForOrderDetailMessage2
                  }
                </StyledOrderHistoryHeading>
              ) : (
                <></>
              )}
              {modify ? (
                <StyledOrderHistoryHeading>
                  <br />
                  {
                    commonMessages.shippingPaymentComponentForOrderDetailMessage3
                  }
                </StyledOrderHistoryHeading>
              ) : (
                <></>
              )}
              {modify ? (
                <StyledOrderHistoryHeading className="onceYouCheckTABLETbOOTOMPAdding">
                  <br />
                  {
                    commonMessages.shippingPaymentComponentForOrderDetailMessage4
                  }
                </StyledOrderHistoryHeading>
              ) : (
                <></>
              )}
              {cencel ? (
                <StyledOrderHistoryHeading>
                  {commonMessages.cancelOrder1}
                </StyledOrderHistoryHeading>
              ) : (
                <></>
              )}
              {cencel ? (
                <StyledOrderHistoryHeading>
                  <br />
                  {commonMessages.cancelOrder2}
                </StyledOrderHistoryHeading>
              ) : (
                <></>
              )}
              {returnOrder ? (
                <StyledOrderHistoryHeading className="tabletReturmOrderTopPadding">
                  {commonMessages.returnOrder1}
                </StyledOrderHistoryHeading>
              ) : (
                <></>
              )}
              {returnOrder ? (
                <StyledOrderHistoryHeading className="tabletReturmOrderBottomPadding">
                  <br></br>
                  {commonMessages.returnOrder2}
                </StyledOrderHistoryHeading>
              ) : (
                <></>
              )}
              {cencel && (
                <div
                  //  className="orderDetailTitle"
                  className={classnames("orderDetailTitle", {
                    fgnghnfhng67: cencel,
                  })}
                >
                  {cencel && (
                    <button
                      onClick={handleClose}
                      disabled={
                        myProfile?.profileInfo?.role === "ServiceTechnician"
                      }
                      className="ilmorButon"
                    >
                      Cancel Order
                    </button>
                  )}
                </div>
              )}
            </div>
            <Accordion
              onChange={handleAccordionChange}
              expanded={expanded}
              className={classnames(
                "accordianOrderCoupan dfgfhrtyhtrgtr orderDetailAccordian",
                {
                  noTopLine: orderDetailData.orderStatus === "Pending",
                }
              )}
            >
              <AccordionSummary
                expandIcon={
                  expanded ? <ExpandMoreIcon /> : <ExpandMoreIcon /> // Use custom icons based on the expanded state
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="accordianText"
              >
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    textTransform: "uppercase",
                    fontSize: "16px",
                    fontWeight: "700",
                    letterSpacing: "2.5px",
                    lineHeight: "120%",
                  }}
                >
                  Shipping / Billing
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="coupanAccordian">
                <ShippingPaymentOrder
                  orderDetail={orderDetail}
                  defaultCardData={defaultCardData}
                  orderDetailData={orderDetailData}
                  reviewOrder={reviewOrder}
                ></ShippingPaymentOrder>
              </AccordionDetails>
            </Accordion>
            {!reviewOrder && !cencel && (
              <ShoppingListComponentOrderDetail
                modify={modify}
                returnOrder={returnOrder}
                cencel={cencel}
                defaultCardData={defaultCardData}
                dataArray={returnOrder ? returnArr : orderDetailData.items}
                orderDetailData={orderDetailData}
                orderDetail={orderDetail}
              ></ShoppingListComponentOrderDetail>
            )}
            {cencel && (
              <ShoppingListComponent
                addtoCart={true}
                modify={modify}
                handleCancelOrder={handleCancelOrder}
                orderDetail={orderDetail}
                orderDetailData={orderDetailData}
                cencel={cencel}
                dataArray={orderDetailData.items}
                reviewOrder={!reviewOrder}
              ></ShoppingListComponent>
            )}
          </Grid>
        ) : (
          <Grid item xs={12} md={8}>
            <ShippingPaymentOrder
              orderDetail={orderDetail}
              orderDetailData={orderDetailData}
              defaultCardData={defaultCardData}
              reviewOrder={reviewOrder}
            ></ShippingPaymentOrder>
            {!reviewOrder && (
              <ShoppingListComponent
                addtoCart={true}
                modify={modify}
                dataArray={orderDetailData?.items}
                orderDetailData={orderDetailData}
                orderDetail={orderDetail}
                reviewOrder={!reviewOrder}
              ></ShoppingListComponent>
            )}
            {orderDetailsSeparate && (
              <div
                className={`billTtile marginTop parorder partOrderMob${
                  orderDetailData?.orderStatus === "Completed" ? "noLine" : ""
                } `}
              >
                PArts Ordered
              </div>
            )}
            {orderDetailsSeparate && orderDetailsSeparate?.length > 0 && (
              <>
                {" "}
                <ActiveInactiveStyled
                  sx={{
                    cursor: "auto",
                  }}
                  status={true}
                >
                  Shipped
                </ActiveInactiveStyled>
                {urlAndTrackingNumber &&
                  urlAndTrackingNumber?.length > 0 &&
                  urlAndTrackingNumber[0].dataShipped &&
                  urlAndTrackingNumber[0].dataShipped.length > 0 &&
                  orderDetailsSeparate &&
                  orderDetailsSeparate?.length == 1 && (
                    <>
                      <Box
                        sx={{
                          display: "inline",
                          fontSize: "14px",
                          fontWeight: "700",
                          marginLeft: "27px",
                        }}
                      >
                        Tracking Number:
                      </Box>
                      <Box
                        component="span"
                        sx={{
                          display: "inline",
                          color: "#CE0E2D",
                          fontSize: "13px",
                          fontWeight: "500",
                          textDecoration: "underline",
                          marginLeft: "4px",
                        }}
                      >
                        {urlAndTrackingNumber[0].dataShipped?.map(
                          (innerObj) => {
                            return (
                              <Box
                                component="span"
                                onClick={() => {
                                  window.open(innerObj.title, "_blank");
                                }}
                                sx={{
                                  display: "inline",
                                  color: "#CE0E2D",
                                  fontSize: "13px",
                                  fontWeight: "500",
                                  textDecoration: "underline",
                                  marginLeft: "4px",
                                  cursor: "pointer",
                                  marginRight: "10px",
                                }}
                              >
                                {innerObj?.link}
                              </Box>
                            );
                          }
                        )}

                        {/* {orderDetailsSeparate[0]?.trackingNumber.toString()} */}
                      </Box>
                    </>
                  )}
                {!reviewOrder && (
                  <ShoppingListComponent
                    urlAndTrackingNumber={urlAndTrackingNumber}
                    partiallyShippedRow={
                      orderDetailsSeparate?.length == 1 ||
                      orderDetailsSeparate?.length == 0
                        ? false
                        : true
                    }
                    addtoCart={true}
                    modify={modify}
                    orderDetailData={orderDetailData}
                    dataArray={orderDetailsSeparate}
                    orderDetail={orderDetail}
                    reviewOrder={!reviewOrder}
                  ></ShoppingListComponent>
                )}
              </>
            )}
            {orderDetailsSeparate &&
              orderDetailData?.orderStatus !== "Return Initiated" &&
              orderDetailData?.orderStatus !== "Return Completed" &&
              orderDetailData?.orderStatus !== "Denied" &&
              Object.keys(orderDetailsSeparate)?.length > 0 &&
              Object.entries(orderDetailsSeparate).map(([key, value]) => (
                <>
                  <div className="trckDetail">
                    <ActiveInactiveStyled
                      sx={{
                        cursor: "auto",
                      }}
                      status={true}
                    >
                      {`Shipped`}
                    </ActiveInactiveStyled>
                    <div className="trackDiv">
                      <span className="trackNumberText">{` Tracking Number : `}</span>
                      <Box
                        component="span"
                        onClick={() => {
                          window.open(value[0]?.trackingUrl, "_blank");
                        }}
                        sx={{
                          display: "inline",
                          color: "#CE0E2D",
                          fontSize: "13px",
                          fontWeight: "500",
                          textDecoration: "underline",
                          marginLeft: "4px",
                          cursor: "pointer",
                          marginRight: "10px",
                        }}
                      >
                        {value[0]?.trackingNumber}
                      </Box>
                      <span className="trackNumberDate">
                        {value[0]?.shippedDate.replace(/\./g, "/")}
                      </span>
                    </div>
                  </div>
                  {!reviewOrder && (
                    <ShoppingListComponent
                      // urlAndTrackingNumber={urlAndTrackingNumber}
                      partiallyShippedRow={
                        orderDetailsSeparate?.length == 1 ||
                        orderDetailsSeparate?.length == 0
                          ? false
                          : true
                      }
                      addtoCart={true}
                      noPartOrder={true}
                      modify={modify}
                      dataArray={value}
                      orderDetailData={orderDetailData}
                      orderDetail={orderDetail}
                      reviewOrder={!reviewOrder}
                    ></ShoppingListComponent>
                  )}
                </>
              ))}
            {orderDetailsProcessing && orderDetailsProcessing?.length > 0 && (
              <>
                {" "}
                <ActiveInactiveStyled
                  sx={{
                    marginTop: "40px",
                    marginBottom: "8px",
                    color: "white",
                    cursor: "auto",
                  }}
                  buttonColor={"#EE9209"}
                  status={false}
                >
                  Processing
                </ActiveInactiveStyled>
                {!reviewOrder && (
                  <ShoppingListComponent
                    addtoCart={true}
                    modify={modify}
                    orderDetailData={orderDetailData}
                    noPartOrder={true}
                    dataArray={orderDetailsProcessing}
                    orderDetail={orderDetail}
                    reviewOrder={!reviewOrder}
                  ></ShoppingListComponent>
                )}
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "20px",
                  }}
                >
                  {" "}
                  <button onClick={handleClose} className="ilmorButon">
                    Cancel Remaining Items
                  </button>
                </Box> */}
              </>
            )}

            {orderDetailsReturn && orderDetailsReturn?.length > 0 && (
              <>
                {" "}
                <ActiveInactiveStyled
                  sx={{
                    marginTop: "20px",
                    marginBottom: "8px",
                    color: "white",
                    cursor: "auto",
                  }}
                  buttonColor={"#EE9209"}
                  status={false}
                >
                  Returned
                </ActiveInactiveStyled>
                {!reviewOrder && (
                  <ShoppingListComponent
                    addtoCart={true}
                    modify={modify}
                    dataArray={orderDetailsReturn}
                    orderDetailData={orderDetailData}
                    orderDetail={orderDetail}
                    reviewOrder={!reviewOrder}
                  ></ShoppingListComponent>
                )}
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "20px",
                  }}
                >
                  {" "}
                  <button onClick={handleClose} className="ilmorButon">
                    Cancel Remaining Items
                  </button>
                </Box> */}
              </>
            )}

            {/* {!reviewOrder && (typeof dataArray === 'object' && dataArray !== null )(
              <ShoppingListComponent
                addtoCart={true}
                modify={modify}
                dataArray={orderDetailData.items}
                orderDetail={orderDetail}
                reviewOrder={!reviewOrder}
              ></ShoppingListComponent>
            )} */}
          </Grid>
        )}
        <Grid item xs={0} md={1}></Grid>
        <Grid item xs={12} md={3}>
          <OrderSummaryOrder
            orderDetail={true}
            orderDetailData={orderDetailData}
            reviewOrder={reviewOrder}
          ></OrderSummaryOrder>
        </Grid>
      </Grid>
      <Box>
        {currentDevice == "Mobile" && (
          <TabletViewBlackContainer currentDevice={currentDevice} />
        )}
      </Box>
    </>
  );
};

export default ShippingPaymentComponentForOrderDetail;
