import React from 'react'
import "./Notice.css";
import { useStore } from '../store/home'
import notice from '../Images/Notice.svg'

const Notice = ( {shipMessage} ) => {
    const { setScreenSize,setCurrentDevice,screenSize,currentDevice} = useStore()
    return (
        <div className={`notice${currentDevice==='Mobile' ? 'Mobile' : ''}`}>
            <img className='notice__image' src={notice} ></img>
            <div  className={`notice__text__section${currentDevice==='Mobile' ? 'Mobile' : ''}`}>
                <span className={`notice__heading${currentDevice==='Mobile' ? 'Mobile' : ''}`}>SHIPPING NOTICE!</span>
                <span className={`notice__heading__txt${currentDevice==='Mobile' ? 'Mobile' : ''}`} >{shipMessage}</span>
            </div>
        </div >
    )
}

export default Notice