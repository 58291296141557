import { useEffect } from "react";
import { useStore } from "../store/home";
import Header from "./Header";
import Notice from "./Notice";
import { deviceDetector, initialState } from "../commonFunction";
import FindParts from "../commenComp/FindParts";
import Footer from "./Footer";
import CategoryTile from "./CateogoryTile";
import BrowseCategories from "./BrowseCategories";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getShippingNotice, getMyCart, getMyProfile } from "../api/posts";
import Banner from "./Banner";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TaxExemptionPopUp from "./TaxExemptionPopUp";
import { Modal } from "@mui/material";
import AddToCartPopUp from "./AddToCartPopUp";
import MyProfile from "./MyProfile";
import PartsCatalog from "../commenComp/PartsCatalog";

// export const searchPartForSpecificEngine =

// {
//   "success": true,
//   "data": [
//     {
//       "productName": "Ilmor Part-1",
//       "productSKU": "B34061",
//       "productBasePrice": "Ilmor Part Price-1",
//       "inStockFlag": 1,
//       "productPriceInclTaxes": "Ilmor Total Cost-1(Incl. of all taxes)",
//       "discount": "X% OFF",
//       "MRP": "MRP-1",
//       "productSpecification": {
//         "brand": "X",
//         "tankCapacity": "X",
//         "countryOfOrigin": "X",
//         "size": "X",
//         "TBD...": "TBD..."
//       },
//       "productInformation": "Lorem ipsum dolor sit amet, consectetur adipisci elit,\n                                                sed eiusmod tempor incidunt ut labore et dolore magna aliqua.\n                                                Ut enim ad minim veniam,quis nostrum exercitationem ullam corporis\n                                                suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Quis\n                                                aute iure reprehenderit in voluptate velit esse cillum dolore eu\n                                                fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident,\n                                                sun tin culpa qui officia deserunt mollit anim id est laborum"
//     },
//     {
//       "productName": "Ilmor Part-2",
//       "productSKU": "B34062",
//       "productBasePrice": "Ilmor Part Price-2",
//       "inStockFlag": 1,
//       "productPriceInclTaxes": "Ilmor Total Cost-2(Incl. of all taxes)",
//       "discount": "X% OFF",
//       "MRP": "MRP-2",
//       "productSpecification": {
//         "brand": "X",
//         "tankCapacity": "X",
//         "countryOfOrigin": "X",
//         "size": "X",
//         "TBD...": "TBD..."
//       },
//       "productInformation": "Lorem ipsum dolor sit amet, consectetur adipisci elit,\n                                                sed eiusmod tempor incidunt ut labore et dolore magna aliqua.\n                                                Ut enim ad minim veniam,quis nostrum exercitationem ullam corporis\n                                                suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Quis\n                                                aute iure reprehenderit in voluptate velit esse cillum dolore eu\n                                                fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident,\n                                                sun tin culpa qui officia deserunt mollit anim id est laborum"
//     },
//     {
//       "productName": "Ilmor Part-3",
//       "productSKU": "B34063",
//       "productBasePrice": "Ilmor Part Price-3",
//       "inStockFlag": 1,
//       "productPriceInclTaxes": "Ilmor Total Cost-3(Incl. of all taxes)",
//       "discount": "X% OFF",
//       "MRP": "MRP-3",
//       "productSpecification": {
//         "brand": "X",
//         "tankCapacity": "X",
//         "countryOfOrigin": "X",
//         "size": "X",
//         "TBD...": "TBD..."
//       },
//       "productInformation": "Lorem ipsum dolor sit amet, consectetur adipisci elit,\n                                                sed eiusmod tempor incidunt ut labore et dolore magna aliqua.\n                                                Ut enim ad minim veniam,quis nostrum exercitationem ullam corporis\n                                                suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Quis\n                                                aute iure reprehenderit in voluptate velit esse cillum dolore eu\n                                                fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident,\n                                                sun tin culpa qui officia deserunt mollit anim id est laborum"
//     },
//   ]
// }

const Home = () => {
  const {
    setCurrentDevice,
    screenSize,
    setCategory,
    token,
    setModelYear,
    setEngineYear,
    setEngineModel,
    setCategoryName,
    userInfo,
    setKeyWord,
    setKeyWordFinal,
    myProfile,
    setMyProfile,
    setMyCartMini,
    myCart,
    setLastPage,
    setIsCard,
    dealerId,
    vals,
    isCurrentEngSet,
    isCurrentEngSetValue,
  } = useStore();

  const mutation = useMutation((post) => getMyProfile(post, token), {
    onSuccess: (result) => {
      setMyProfile(result?.data);
    },
    onError: (error) => {},
  });

  useEffect(() => {
    setIsCard(false);
  }, []);
  useEffect(() => {
    setKeyWordFinal("");
    setKeyWord("");
    const obj = {};
    obj.email = userInfo?.username;
    obj.dealerId = dealerId;
    // mutation.mutate(obj);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setCurrentDevice(deviceDetector(screenSize[0]));
  }, [screenSize]);
  const shipNotice = useQuery({
    queryKey: ["getShippingNotice"],
    retry: 0,
    refetchOnWindowFocus: false,
    queryFn: () => getShippingNotice(token),
  });
  const [shipMessage, setShipMessage] = useState(false);
  useEffect(() => {
    if (shipNotice?.data?.data?.success === "true") {
      setShipMessage(shipNotice?.data?.data?.data?.description);
    } else {
      setShipMessage(false);
    }
  }, [shipNotice?.data?.data]);
  const navigate = useNavigate();

  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  // };

  // useEffect(() => {
  //   if (
  //     myProfile?.profileInfo?.USDealer &&
  //     myProfile?.profileInfo?.firstLogin &&
  //     !myProfile?.profileInfo?.skip
  //   ) {
  //     //  if(myProfile?.profileInfo?.USDealer && myProfile?.profileInfo?.firstLogin  ){
  //     setIsModalOpen(true);
  //   }
  // }, []);

  const mutation_cart = useMutation((post) => getMyCart(post, token), {
    onSuccess: (result) => {
      setMyCartMini(result);
    },
    onError: (error) => {},
  });
  useEffect(() => {
    const obj = {
      email: userInfo.username,
      minicart: true,
      dealerId: dealerId,
    };
    mutation_cart.mutate(obj);
  }, []);

  const resetStore = () => {
    useStore.setState(initialState);
  };

  useEffect(() => {
    if (mutation_cart.error?.message.includes("401")) {
      setLastPage(false);
      localStorage.removeItem("token");
      resetStore();
      navigate("/sign-in", { replace: true });
    }
  }, [mutation_cart.error]);

  const [isModalOpen, setIsModalOpen] = useState();
  // const navigate = useNavigate();
  useEffect(() => {
    if (myProfile?.profileInfo?.firstLogin) {
      navigate({
        pathname: "/update-password",
      });
    }
    if (
      !dealerId &&
      myProfile?.profileInfo?.USDealer &&
      !myProfile?.profileInfo?.skip
    ) {
      setIsModalOpen(true);
    }
  }, [myProfile]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          aria-labelledby="taxExemption-modal"
          aria-describedby="taxExemption-modal-description"
        >
          {
            <div className="taxExemption-modal">
              <TaxExemptionPopUp
                handleCloseModal={handleCloseModal}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
              />
            </div>
          }
        </Modal>
      )}
      <Banner />
      {shipMessage && <Notice shipMessage={shipMessage} />}
      {isCurrentEngSet && isCurrentEngSetValue && vals ? (
        <PartsCatalog isHome={true} />
      ) : (
        <FindParts />
      )}
      <BrowseCategories />
      <Footer />
    </>
  );
};

export default Home;
