import Header from "./Header";
import Footer from "./Footer";
import "./Checkout.css";
import ShippingPayment from "./ShippingPayment";
import ReviewOrder from "./ReviewOrder";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import OrderComplete from "./OrderComplete";
import { useSearchParams } from "react-router-dom";
import { useStore } from "../store/home";
import NavigationBar from "../commenComp/NavigationBar";
import OrderSummary from "./OrderSummary";
import ShippingPaymentComponent from "./ShippingPaymentComponent";
import loaderImg from "../Images/loaderImg.gif";
import SubHeading from "../commenComp/SubHeading";
import SideMenu from "../commenComp/SideMenu";
import ProductTile from "./ProductTile";
import { getPartList, getProductListCategory } from "../api/posts";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Grid } from "@mui/material";
import PartsCatalog from "../commenComp/PartsCatalog";

const SearchResults = () => {
  const {
    token,
    setIsCard,
    isCard,
    isCurrentEngSet,
    currentDevice,
    setRecord,
    setTRecord,
    setPartListArray,
    setKeyWordFinal,
    keyWord,
    dealerId,
    categoryOptions,
    setCategoryIdSearch,
    setSelectedCategory,
    partListArray,
    setSort,
    sort,
  } = useStore();

  useEffect(() => {
    if (isCurrentEngSet) {
      setSort("comp");
    } else {
      setSort("partNumber");
    }
  }, [isCurrentEngSet]);
  const { headLoad, viaLink, setRefreshQ, refreshQ } = useNonPersistedStore();

  const [first, setfirst] = useState();
  const mutation1 = useMutation((post) => getProductListCategory(post), {
    mutationKey: "getProductListCategory",
    method: "GET",
    onSuccess: (result) => {
      setfirst(result.data.data);
    },
    onError: (error) => {},
  });
  useEffect(() => {
    const data = {
      token: token,
        dealerId:dealerId,
        EngineId: isCurrentEngSet.engineId,
    };
    mutation1.mutate(data);
  }, [isCurrentEngSet]);

  // const sideMenuData = useQuery({
  //   queryKey: ["sidemenu"],
  //   retry: 2,
  //   refetchOnWindowFocus: false,
  //   queryFn: () => getProductListCategory(token),
  // });

  const [catagory, setCatagory] = useState(false);
  useEffect(() => {
    // setTRecord(0)
    // setRecord(0)
    // setOffset(0)
    // setBox(1)
    // setIsCard(false);
  }, []);
  const [searchParams] = useSearchParams();

  const mutation = useMutation((post) => getPartList(post), {
    mutationKey: "getPartList",
    method: "GET",
    onSuccess: (result) => {
      setRefreshQ(!refreshQ);
      setPartListArray(result.data.data);
      setTRecord(result?.data?.total);
      setRecord(result?.data?.perPage);
      setKeyWordFinal(searchParams?.get("keyWord") ?? "");
    },
    onError: (error) => {},
  });

  useEffect(() => {
    // if (viaLink) {
    let timeoutId;
    if (searchParams?.get("category")) {
      setSelectedCategory(searchParams?.get("category"));
    } else {
      setSelectedCategory("");
    }
    const params = {
      token: token,
      categoryId: searchParams?.get("category") ?? "",
      subCategoryId: "",
      keyword: searchParams?.get("keyWord") ?? "",
      engineId: searchParams?.get("engineId") ?? "",
      dealerId: dealerId,
      perPage: 20,
      offset: searchParams?.get("p") ?? 0,
      sortBy: searchParams?.get("s") ?? sort,
    };
    let label = categoryOptions.filter(
      (item) => item?.value === parseInt(searchParams?.get("category"))
    );
    setCategoryIdSearch(label);
    timeoutId = setTimeout(() => {
      let arr = [];
      if (partListArray && partListArray.length > 0) {
        arr = partListArray.map((item) => {
          item.images = loaderImg;
          item.isLoad = true;
          return item;
        });
      }
      setPartListArray(arr);
      mutation.mutate(params);
    }, 0);
    // }
    return () => {
      clearTimeout(timeoutId); // Clear the timeout when the component unmounts or when the effect runs again
    };
  }, [searchParams]);

  useEffect(() => {
    if (first) {
      setCatagory(first);
    }
  }, [first]);

  useEffect(() => {
    if (currentDevice === "Mobile") {
      setIsCard(false);
    }
  }, [currentDevice]);

  let sortOptions = [
    {
      label: "Part Number",
      value: "partNumber",
    },
    // {
    //   label: "Price",
    //   value: "price",
    // },
    {
      label: "Compatibilty",
      value: "comp",
    },
  ];

  let sortOptions1 = [
    {
      label: "Part Number",
      value: "partNumber",
    },
    {
      label: "Price",
      value: "price",
    },
  ];
  return (
    // <div>
    //   <div className="searchTop">
    //     <Header page="Catalog"></Header>
    //     <SubHeading isSmallHeading={"Search results for "} />
    //   </div>
    //   <div className="catalog__mainbody">
    //     {/* <SideMenu data={catagory} /> */}
    //     <ProductTile
    //       noCurrEngine={true}
    //       disableView={true}
    //       sortOptions={sortOptions}
    //     ></ProductTile>
    //   </div>
    //   <Footer></Footer>
    // </div>
    <>
      <Grid container>
        <Grid item lg={12} className="catalogPageHeaderAndPartsCatalog">
          <Header page="Catalog"></Header>
          {isCurrentEngSet ? (
            <PartsCatalog isSearch={true} />
          ) : (
            <SubHeading isSmallHeading={"Search results for "} />
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <ProductTile
            noCurrEngine={true}
            disableView={true}
            load={headLoad}
            iscardView={true}
            sortOptions={isCurrentEngSet ? sortOptions : sortOptions1}
          ></ProductTile>
        </Grid>
        <Grid item md={12} sm={12} lg={12}>
          <Footer></Footer>
        </Grid>
      </Grid>
    </>
  );
};

export default SearchResults;
