import { styled, Typography } from "@mui/material";

export const SignInInfo_subHeading = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "16px",

  fontStyle: "normal",
  fontWeight: "500",
  width: "101%",
});

export const SignInInfo_subHeading2 = styled(Typography)({
    fontFamily: "Montserrat",
    fontSize: "14px",
  
    fontStyle: "normal",
    fontWeight: "500",
    width: "101%",
  });