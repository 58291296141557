import * as yup from "yup";
 export const contactSchema = yup.object().shape({
   companyOrganization: yup.string().required("Company is required"),
   email: yup
     .string()
     .required("Email is required")
     .matches(
       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
       "Invalid email"
     ),
     contactReason: yup.object().shape({
     label: yup.string().required("Reason is required"),
     value: yup.string().required("Reason is required"),
    }),
  //  reasonForContacting: yup.string().required("Reason is required"),

   // // role:yup.string().required("Role is required"),
   //  StateProvince: yup.string().required("State is Required"),
   //  countryRegion: yup.string().required("CountryRegion is Required"),
   firstName: yup.string().required("First name is Required"),
   lastName: yup.string().required("Last name is Required"),
   content: yup.string().required("Comments are Required"),
   
   //  StateProvince: yup.object().shape({
   //    label: yup.string().required("State is Required "),
   //    value: yup.string().required("State is Required"),
   //  }),
   // .nullable() // for handling null value when clearing options via clicking "x"
   // .required("Role is required ")
 });