import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { useStore } from "../store/home";
import NumericInput from "react-numeric-input";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Cross } from "../Images/crossRed.svg";
import { ReactComponent as RedCart } from "../Images/cartRedStrike.svg";
import { CircularProgress, Modal, Box } from "@mui/material";
import AddToCartPopUp from "./AddToCartPopUp";
import newlogo from "../Images/greyCartBtn.png";
import { useMutation } from "@tanstack/react-query";
import { checkAvail, addWishList, addToCart, getMyCart } from "../api/posts";
import { ReactComponent as GreyShopList } from "../Images/greyShopList.svg";
import classNames from "classnames";
import Loader from "../commenComp/Loader";
import commonMessages from "../commenComp/CommonMessages";
import { ReactComponent as RedCartEmpty } from "../Images/redCart.svg";
import { ReactComponent as GreyCart } from "../Images/greyCartIlmorrOrder.svg";
import { ReactComponent as SvgCArt } from "../Images/svgCArtGrey.svg";
import { toast } from "react-toastify";
import { addCommaDecimal, getCurrentDate } from "../commonFunction";
import { showErrorToast } from "../utils/Toaster";
import AddToShopPop from "./AddToShopPopUp";

export const QuickOrderComponent = () => {
  const {
    token,
    userInfo,
    quickOrderData,
    setQuickOrderData,
    dealerId,
    currentDevice,
    myProfile,
    setMyCartMini,
  } = useStore();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenShop, setIsModalOpenShop] = useState(false);
  const [change, setChange] = useState(false);
  const [isCheckItem, setIsCheckItem] = useState(true);

  useEffect(() => {
    if (quickOrderData.length > 0) {
      const flag = quickOrderData.every(
        (item) =>
          item.status === "No Match" ||
          !item.dealerPrice ||
          item.status === "Discontinued" ||
          (!item.isBackordered &&
            item.quantity > item?.inventory - item?.inStockQuantity)
      );
      if (!flag) {
        setIsCheckItem(false);
      } else {
        setIsCheckItem(true);
      }
    }
  }, [quickOrderData]);

  const buttonStyles = {
    fontFamily: "FontAwesome",
    fontSize: "12px",
  };

  const handleInputChange = (event, rowIndex, field) => {
    const updatedTableData = [...quickOrderData];
    updatedTableData[rowIndex][field] = event.target.value;
    setChange(false);
    setQuickOrderData(updatedTableData);
  };

  const handleDelete = (event, rowIndex, field) => {
    const arr = quickOrderData;
    setChange(false);
    arr.splice(rowIndex, 1);
    setQuickOrderData(arr);
  };

  const handleInputChangeSelect = (event, rowIndex, field) => {
    const updatedTableData = [...quickOrderData];
    updatedTableData[rowIndex][field] = event;
    setChange(false);
    setQuickOrderData(updatedTableData);
  };
  const [shopList, setShopList] = useState([]);
  const [shopListPop, setShopListPop] = useState([]);
  // const removeDuplicate = () =>
  // toast.success(`Remove duplicate items from the list`);

  const notifyShop = () =>
    toast.success(`${shopList.length} Products added to Shopping list`);

  const notifyCart = () =>
    toast.success(`${shopList.length} Products added to  Cart`);

  const notify = () => toast.success(commonMessages.noItemInQuickOrder);
  const addToShopList = useMutation((post) => addWishList(post, token), {
    onSuccess: (result) => {
      if (result.data.success) {
        // notifyShop();
        if (shopList.length > 0) {
          const filteredArray1 = quickOrderData.filter(
            (obj1) => !shopList.some((obj2) => obj2.partId === obj1.partId)
          );
          let arr = result?.data?.data
          if(result?.data?.data && result?.data?.data?.length > 0 && myProfile?.profileInfo?.role === "ServiceTechnician"){
             arr = result?.data?.data.filter(item => item.isPermission)
          }
          // setQuickOrderData(filteredArray1);
          setShopListPop(arr);
          setIsModalOpenShop(true);
        }
      }
    },
    onError: (error) => {},
  });

  const mutation_cart = useMutation((post) => getMyCart(post, token), {
    onSuccess: (result) => {
      setMyCartMini(result);
    },
    onError: (error) => {},
  });

  const mutationCart = useMutation((post) => addToCart(post, token), {
    onSuccess: (result) => {
      if (result.data.success) {
        // notifyCart();
        const obj = {
          email: userInfo.username,
          minicart: true,
          dealerId: dealerId,
        };
        mutation_cart.mutate(obj);
        setShopListPop(result?.data?.cart?.items);
        if (shopList.length > 0) {
          const filteredArray1 = quickOrderData.filter(
            (obj1) => !shopList.some((obj2) => obj2.partId === obj1.partId)
          );
          // setQuickOrderData(filteredArray1);
          setIsModalOpen(true);
        }
      }
    },
    onError: (error) => {},
  });
  const handleAddToList = (cart) => {
    setChange(false);
    if (!quickOrderData.length) {
      notify();
    } else {
      const arr = {
        partDetails: [],
      };
      let finalArr = [];
      let finalArrPop = [];
      quickOrderData.forEach((item) => {
        const obj = {};
        const objPop = {};
        if (
          !item.isBackordered &&
          item.quantity > item?.inventory - item?.inStockQuantity &&
          !(item.status === "Discontinued" || item.status === "No Match")
        ) {
          showErrorToast(
            `${commonMessages?.quickOrderComponentErrorOne}${item.partName}`
          );
        }
        if (
          !(
            item.status === "No Match" ||
            item.status === "Discontinued" ||
            (!item.isBackordered &&
              item.quantity > item?.inventory - item?.inStockQuantity)
          )
        ) {
          obj.partId = item.partId;
          obj.date = getCurrentDate();
          obj.notes = "";
          obj.inventory = item.inventory;
          obj.inventory = item.inventory;
          obj.message = item.message;
          obj.isBackordered = item.isBackordered;
          objPop.partId = item.partId;
          objPop.partQty = item?.quantity <= 0 ? 1 : item?.quantity;
          objPop.images = item.image;
          objPop.partDiscountCost = item.dealerPrice;
          objPop.partOrigionalCost = item.originalPrice;
          objPop.partStatus = item.status;
          objPop.partName = item.partName;
          objPop.partNumber = item.partNumber;
          objPop.partName = item.productName;
          objPop.inventory = item.inventory;
          objPop.isBackordered = item.isBackordered;
          objPop.message = item.message;
          objPop.inStockQuantity = item.inStockQuantity;
          const arr1 = [];
          if (item?.additionalInfoFlag) {
            item.additionalInfo.forEach((item) => {
              const obj = {};
              obj.title = item;
              obj.value = "";
              arr1.push(obj);
            });
          }
          if (!cart) {
            obj.isShoppingList = false;
            obj.other_notes = arr1;
          } else {
            obj.otherNotes = arr1;
          }
          obj.quantity = item.quantity <= 0 ? 1 : item.quantity;
          // if (item.isBackordered && item.quantity > item.inStockQuantity) {
          //   obj.quantity = item.inStockQuantity;
          // }
          if (item.partId) {
            finalArr.push(obj);
            finalArrPop.push(objPop);
          }
        }
      });
      arr.partDetails = finalArr;
      setShopList(finalArr);
      // setShopListPop(finalArrPop);
      if (finalArr.length > 0) {
        if (cart === "cart") {
          // let obj = [];
          // obj.push(finalArr);
          let data = {
            email: userInfo?.username,
            update: false,
            isPartDetail: false,
            items: finalArr,
          };
          mutationCart.mutate(data);
        } else {
          addToShopList.mutate(arr);
        }
      } else {
        showErrorToast(`${commonMessages?.quickOrderComponentErrorTwo}`);
      }
    }
  };

  const mutation = useMutation((post) => checkAvail(post, token), {
    onSuccess: (result) => {
      if (result.data.success) {
        setChange(true);
        setQuickOrderData(result.data.data);
      }
    },
    onError: (error) => {},
  });
  const handleCheck = () => {
    if (quickOrderData.length === 1 && quickOrderData[0].partName === "") {
      showErrorToast(`${commonMessages?.quickOrderComponentErrorThree}`);
    } else {
      setIsCheckItem(false);
      const filteredItems = quickOrderData.filter(
        (item) => item.partName !== ""
      );
      const duplicateExists = filteredItems.some(
        (item, index, array) =>
          array.findIndex((obj) => obj.partName === item.partName) !== index
      );
      if (!duplicateExists) {
        mutation.mutate(filteredItems);
      } else {
        showErrorToast(`${commonMessages?.quickOrderComponentErrorFour}`);
      }
    }
  };

  const handleAddItem = () => {
    const obj = {
      partName: "",
      quantity: 1,
      status: "",
      originalPrice: "",
      dealerPrice: "",
      message: "",
    };
    window.scrollBy(0, 90);
    const arr = quickOrderData;
    arr.push(obj);
    setQuickOrderData(arr);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleCheck();
    }
  };

  const handlePart = (data) => {
    navigate({
      pathname: "/part",
      search: `number=${data}`,
    });
  };

  return currentDevice !== "Mobile" ? (
    <div className={`quickOrderoutDesktop`}>
      {mutation.isLoading && <Loader></Loader>}
      {/* {addToShopList.isLoading && <Loader></Loader>} */}
      <div className={`quickOrderoutSpan1Desktop`}>
        <span>{commonMessages?.quickOrderComponentSubHeading}</span>
      </div>
      <div className={`quickOrderoutSpan1Desktop`}>
        <span>{commonMessages?.quickOrderComponentSubHeadingTwo}</span>
      </div>
      <table onKeyDown={handleKeyDown} className={`quickOrderTableDesktop`}>
        <thead className={`quickOrderTableHeadDesktop`}>
          <tr className={`quickOrderTableHeadRowDesktop`}>
            <th width="30%" className={`quickOrderTableHeadingDesktop`}>
              Part Number{" "}
            </th>
            <th width="18%" className={`quickOrderTableHeadingDesktop`}>
              Quantity
            </th>
            {myProfile?.profileInfo?.permissions?.includes(
              "add_to_own_cart"
            ) && (
              <th
                width="23%"
                className={`quickOrderTableHeadingDesktop quickPriceHeading`}
              >
                Price
              </th>
            )}
            <th width="28%" className={`quickOrderTableHeadingDesktop`}>
              Status
            </th>
            <th width="" className={`quickOrderTableHeadingDesktop`}></th>
          </tr>
        </thead>
        <tbody className={`quickOrderTableBodyDesktop`}>
          {quickOrderData?.map((row, index) => (
            <tr
              className={`quickOrderTableRowDesktop${
                index % 2 === 0 ? "EvenRow" : ""
              }`}
              key={index}
            >
              <td
                className={`quickOrderTableInputDivDesktop quickOrderInputFormTabletTd`}
              >
                <div
                  // className="partNUmberBox"
                  className={classNames(
                    "partNUmberBox inputQuickOrderTabletPart",
                    {
                      notAvailable:
                        row.status === "No Match" ||
                        row.status === "Discontinued",
                    }
                  )}
                >
                  <input
                    type="text"
                    className={`quickOrderTableInputDesktop inputInnerPAddngQuickOrderTabletPart`}
                    value={row.partName}
                    onChange={(event) =>
                      handleInputChange(event, index, "partName")
                    }
                  ></input>
                  {/* myProfile?.profileInfo?.permissions?.includes("add_to_own_cart") && */}
                  {(row.status === "No Match" ||
                    row.status === "Discontinued") &&
                  myProfile?.profileInfo?.permissions?.includes(
                    "add_to_own_cart"
                  ) ? (
                    <div className="redCartDiv">
                      <RedCart className="icon redCart"></RedCart>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </td>
              <Tooltip
                placement="right"
                title={
                  !row.isBackordered &&
                  row?.quantity > row?.inventory - row?.inStockQuantity &&
                  !(row.status === "Discontinued" || row.status === "No Match")
                    ? "Update the quantity to less than or equal to In Stock value"
                    : ""
                }
              >
                <td
                  className={`quickOrderTableQuantityDivDesktop tabletInputPAddingQuantity`}
                >
                  <NumericInput
                    className={classNames(
                      "quickOrderTableQuantityDesktop ftgrert33  fontForQuickorder",
                      {
                        updateQuant:
                          !row.isBackordered &&
                          row.quantity >
                            row?.inventory - row?.inStockQuantity &&
                          !(
                            row.status === "Discontinued" ||
                            row.status === "No Match"
                          ),
                      }
                    )}
                    //  buttonContentUp={<span>&#xf077;</span>}
                    style={buttonStyles}
                    mobile={false}
                    max={999}
                    // min={1}
                    strict
                    //  buttonContentDown={<span>&#xf078;</span>}
                    onChange={(event) =>
                      handleInputChangeSelect(event, index, "quantity")
                    }
                    onBlur={(event) => {
                      if (event.target.value == "" || event.target.value <= 0) {
                        handleInputChangeSelect(1, index, "quantity");
                      }
                    }}
                    value={row.quantity <= 0 ? "" : row.quantity}
                  />
                </td>
              </Tooltip>
              {myProfile?.profileInfo?.permissions?.includes(
                "add_to_own_cart"
              ) && (
                <>
                  {!(
                    row.status === "No Match" || row.status === "Discontinued"
                  ) ? (
                    <td className={`quickOrderTablePriceDivDesktop`}>
                      <span className={`quickOrderTablePriceDesktop`}>
                        <div className="shopPricQuick">
                          {row.dealerPrice && row.dealerPrice !== "0" ? (
                            <span className={`shoppingListTableprice`}>
                              ${addCommaDecimal(row.dealerPrice)}
                            </span>
                          ) : (
                            <></>
                          )}
                          {row.dealerPrice === "0" ? (
                            <span className={`shoppingListTableprice`}>
                              ${"0.00"}
                            </span>
                          ) : (
                            <></>
                          )}
                          {row.originalPrice &&
                            row.dealerPrice !== row.originalPrice &&
                            row.originalPrice !== "0" && (
                              <span
                                className={`shoppingListTablepriceDiscounted`}
                              >
                                $
                                {row.originalPrice &&
                                  row.dealerPrice !== row.originalPrice &&
                                  addCommaDecimal(row.originalPrice) &&
                                  addCommaDecimal(row.originalPrice)}
                              </span>
                            )}
                        </div>
                      </span>
                    </td>
                  ) : (
                    <td className={`quickOrderTablePriceDivDesktop`}>
                      <span className={`quickOrderTablePriceDesktop`}>
                        <div className="shopPricQuick">
                          <p className={`shoppingListTableprice `}>
                            {"\u2014"}
                          </p>
                        </div>
                      </span>
                    </td>
                  )}
                </>
              )}
              <td className={`quickOrderTableStatusDivDesktop`}>
                <div className=" shopPriceStatus">
                  <span
                    className={classNames("shoppingListTableprice textLeft", {
                      notAvailableText: !(
                        row.status === "In Stock" ||
                        row.status === "Special Order"
                      ),
                    })}
                  >
                    {row.status}
                  </span>
                  <br></br>
                  <span
                    // className={`shoppingListTableprice`}
                    className={classNames(
                      "shoppingListTableprice shoppingListTablepriceMessage",
                      {
                        notAvailableText: !(
                          row.status === "In Stock" ||
                          row.status === "Special Order"
                        ),
                      }
                    )}
                  >
                    {/* {row.message ? `(${row.message})` : ""} */}
                    {row.message ? (
                      row.message.includes("Use") ? (
                        <>
                          <span>{`(${row.message.split(" ")[0]} `}</span>
                          <span
                            onClick={() => {
                              handlePart(row.message.split(" ")[3]);
                            }}
                            className="under"
                          >{`${row.message.split(" ")[1]}`}</span>
                          <span>{` ${row.message.split(" ")[2]})`}</span>
                        </>
                      ) : (
                        `(${row.message})`
                      )
                    ) : (
                      ""
                    )}
                    {row.isSupercedeDiscontinue &&
                    row.isSupercedeDiscontinue.status &&
                    row.isSupercedeDiscontinue.replacementPartNumber &&
                    row.isSupercedeDiscontinue.replacementPartNumber.length >
                      0 ? (
                      <>
                        <div className="maxwidSku">
                          This part is a replacement for
                        </div>
                        <div className="maxwidSku">
                          {row.isSupercedeDiscontinue.replacementPartNumber}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </td>
              <td className={`quickOrderTableStatusDivDesktop`}>
                {!dealerId && (
                  <Cross
                    onClick={(event) => {
                      handleDelete(event, index, "partName");
                    }}
                    className="icon quickCross"
                  ></Cross>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={`quickOrderButtonSectionDesktop`}>
        {true && (
          <div onClick={handleAddItem} className={`quickOrderButtonAddDesktop`}>
            <div className={`quickOrder__buttonWhitePlusDesktop`}>+</div>
            <div className={`quickOrder__buttonWhiteDesktop`}>ADD ITEM</div>
          </div>
        )}
        {true && (
          <Box
            sx={{
              paddingTop:
                currentDevice === "Desktop"
                  ? "20px"
                  : currentDevice === "Tablet"
                  ? "22px"
                  : "22px",
            }}
            className={`quickOrderBottomButtonsDesktop quickOrderBottomButtonsTablet `}
          >
            <div
              className={`quickOrderCheckAvailDesktop${
                currentDevice === "Tablet" ? " quickOrderCheckAvailTablet" : ""
              }`}
            >
              <button
                onClick={handleCheck}
                disabled={quickOrderData.length === 0}
                className={`ilmorButon quickOrder__buttonDesktop`}
              >
                Check Availability
              </button>
            </div>
            <div className={`quickOrderTableRightButtonsDesktop`}>
              <div className=" minWid rtduiogjhr backgrounDArkRemove">
                {!addToShopList.isLoading ? (
                  <div
                    onClick={() => {
                      handleAddToList("");
                    }}
                    className={classNames("iconWrapperGrey center bgForCart", {
                      disabledGreyButton:
                        // quickOrderData.length === 0 || isCheckItem,
                        !change || isCheckItem,
                    })}
                  >
                    <GreyShopList className="GreyShopList icon"></GreyShopList>{" "}
                    Add to List
                  </div>
                ) : (
                  <div className="iconWrapper center bgForCart">
                    <CircularProgress style={{ color: "white" }} size={20} />
                  </div>
                )}
              </div>
              {myProfile?.profileInfo?.permissions?.includes(
                "add_to_own_cart"
              ) && (
                <div className="backgrounDArkRemove minWid">
                  {!mutationCart.isLoading ? (
                    <div
                      className={classNames(" paddRight center", {
                        disabledGreyButton:
                          // quickOrderData.length === 0 || isCheckItem,
                          !change || isCheckItem,
                        redGreyButtonBtnQuick:
                          // quickOrderData.length === 0 || isCheckItem,
                          change,
                      })}
                      // onClick={() => {
                      //   setIsModalOpen(true);
                      // }}
                      onClick={() => {
                        handleAddToList("cart");
                      }}
                    >
                      {change && !isCheckItem ? (
                        <RedCartEmpty className="GreyShopList icon"></RedCartEmpty>
                      ) : (
                        <GreyCart className=" icoxxxn"></GreyCart>
                        //   <img
                        //   className={`footer__image${currentDevice}`}
                        //   src={newlogo}
                        //   alt="Ilmor Logo"
                        // />
                      )}
                      Add to Cart
                    </div>
                  ) : (
                    <div className="iconWrapper center bgForCart">
                      <CircularProgress style={{ color: "white" }} size={20} />
                    </div>
                  )}
                </div>
              )}
              {/* <button
              className={`ilmorButon quickOrder__buttonDesktop`}
              disabled={quickOrderData.length === 0 || isCheckItem}
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
            </button> */}
            </div>
          </Box>
        )}
      </div>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="add-to-cart-modal"
        aria-describedby="add-to-cart-modal-description"
      >
        <div className="addToCart-modal">
          <AddToCartPopUp
            shopList={shopListPop}
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
          />
        </div>
      </Modal>
      <Modal
        open={isModalOpenShop}
        onClose={() => setIsModalOpenShop(false)}
        aria-labelledby="add-to-cart-modal"
        aria-describedby="add-to-cart-modal-description"
      >
        <div className="addToCart-modal">
          <AddToShopPop
            setIsModalOpen={setIsModalOpenShop}
            shopList={shopListPop}
            yourCart={true}
            isModalOpen={isModalOpenShop}
          />
        </div>
      </Modal>
    </div>
  ) : (
    <div
      onKeyDown={handleKeyDown}
      className={`quickOrderoutDesktop${
        currentDevice === "Mobile" ? " quickOrderoutMobile" : ""
      }`}
    >
      {mutation.isLoading && <Loader></Loader>}
      {addToShopList.isLoading && <Loader></Loader>}
      <div
        className={`quickOrderoutSpan1Desktop${
          currentDevice === "Mobile" ? " quickOrderoutSpan1Mobile" : ""
        }`}
      >
        <ol className="sdffgdf34336">
          <li className="sdf23e2">{commonMessages?.quickOrderComponentSubHeading2}</li>
          <li>{commonMessages?.quickOrderComponentSubHeadingTwo2}</li>
        </ol>
      </div>
      {/* <div
        className={`quickOrderoutSpan1Desktop${
          currentDevice === "Mobile" ? " quickOrderoutSpan1Mobile" : ""
        }`}
      >
        <ol></ol>
      </div> */}
      <table
        className={`quickOrderTableDesktop${
          currentDevice === "Mobile"
            ? " quickOrderTableMobile fgdfgrg4523 "
            : ""
        }`}
      >
        <thead
          className={`quickOrderTableHeadDesktop${
            currentDevice === "Mobile" ? " quickOrderTableHeadMobile " : ""
          }`}
        >
          <tr
            className={`quickOrderTableHeadRowDesktop${
              currentDevice === "Mobile" ? " quickOrderTableHeadRowMobile" : ""
            }`}
          >
            <th
              width="70%"
              className={`quickOrderTableHeadingDesktop${
                currentDevice === "Mobile"
                  ? " quickOrderTableHeadingMobile"
                  : ""
              }`}
            >
              Part Number{" "}
            </th>
            <th
              width="30%"
              className={`padrigh quickOrderTableHeadingDesktop${
                currentDevice === "Mobile"
                  ? " quickOrderTableHeadingMobile"
                  : ""
              }`}
            >
              Quantity
            </th>
            {/* <th width="" className={`quickOrderTableHeadingDesktop`}></th> */}
            {/* <th width="20%" className={`quickOrderTableHeadingDesktop${currentDevice === 'Mobile' ? ' quickOrderTableHeadingMobile' : ''}`}>Price</th> */}
            {/* <th width="35%" className={`quickOrderTableHeadingDesktop${currentDevice === 'Mobile' ? ' quickOrderTableHeadingMobile' : ''}`}>Status</th> */}
          </tr>
        </thead>
        <tbody
          className={`quickOrderTableBodyDesktop${
            currentDevice === "Mobile" ? " quickOrderTableBodyMobile" : ""
          }`}
        >
          {quickOrderData.map((row, index) => (
            <tr
              className={`quickOrderTableRowDesktop${
                index % 2 === 0 ? "EvenRow" : ""
              }`}
              key={index}
            >
              <td
                className={`quickOrderTableInputDivDesktop${
                  currentDevice === "Mobile"
                    ? " quickOrderTableInputDivMobile"
                    : ""
                }`}
              >
                <div
                  // className="partNUmberBox"
                  className={classNames("partNUmberBox", {
                    notAvailable:
                      row.status === "No Match" ||
                      row.status === "Discontinued",
                  })}
                >
                  <input
                    type="text"
                    className={`quickOrderTableInputDesktop`}
                    value={row.partName}
                    onChange={(event) =>
                      handleInputChange(event, index, "partName")
                    }
                  ></input>

                  {(row.status === "No Match" ||
                    row.status === "Discontinued") &&
                  myProfile?.profileInfo?.permissions?.includes(
                    "add_to_own_cart"
                  ) ? (
                    <div className="redCartDiv">
                      <RedCart className="icon redCart"></RedCart>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {/* <span className={`quickOrderTableStatusDesktop`}> */}
                {row.status && (
                  <div className="shopPrice shopPriceStatus mesalig mobiletopPddingQuicOrderLeftInStock">
                    <span
                      className={classNames("shoppingListTableprice", {
                        notAvailableText: !(
                          row.status === "In Stock" ||
                          row.status === "Special Order"
                        ),
                      })}
                    >
                      {row.status}
                    </span>
                    {/* <br></br> */}
                    <span
                      // className={`shoppingListTableprice`}
                      className={classNames(
                        "shoppingListTableprice shoppingListTablepriceMessage leftAliquick leftAlignMsg",
                        {
                          notAvailableText: !(
                            row.status === "In Stock" ||
                            row.status === "Special Order"
                          ),
                        }
                      )}
                    >
                      {row.message ? (
                        row.message.includes("Use") ? (
                          <>
                            <span>{`(${row.message.split(" ")[0]} `}</span>
                            <span
                              onClick={() => {
                                handlePart(row.message.split(" ")[3]);
                              }}
                              className="under"
                            >{`${row.message.split(" ")[1]}`}</span>
                            <span>{` ${row.message.split(" ")[2]})`}</span>
                          </>
                        ) : (
                          `(${row.message})`
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                )}
                {/* </span> */}
              </td>
              <td
                className={`quickOrderTableQuantityDivDesktop${
                  currentDevice === "Mobile"
                    ? " quickOrderTableQuantityDivMobile"
                    : ""
                }`}
              >
                <div style={{ gap: "10px" }} className="center mobSpave">
                  <NumericInput
                    className={classNames(
                      "quickOrderTableQuantityDesktop ftgrert33 jgyu676 quickOrderTableQuantityMobile",
                      {
                        updateQuant:
                          !row.isBackordered &&
                          row.quantity >
                            row?.inventory - row?.inStockQuantity &&
                          !(
                            row.status === "Discontinued" ||
                            row.status === "No Match"
                          ),
                      }
                    )}
                    mobile={false}
                    max={999}
                    // min={1}
                    strict
                    onChange={(event) =>
                      handleInputChangeSelect(event, index, "quantity")
                    }
                    onBlur={(event) => {
                      if (event.target.value == "" || event.target.value <= 0) {
                        handleInputChangeSelect(1, index, "quantity");
                      }
                    }}
                    value={row.quantity <= 0 ? "" : row.quantity}
                  />
                  <Cross
                    onClick={(event) => {
                      handleDelete(event, index, "partName");
                    }}
                    className="icon quickCross"
                  ></Cross>
                </div>

                {!(
                  row.status === "No Match" || row.status === "Discontinued"
                ) ? (
                  <span className={`quickOrderTablePriceDesktop `}>
                    {myProfile?.profileInfo?.permissions?.includes(
                      "add_to_own_cart"
                    ) && (
                      <div className="shopPrice mobiletopPddingQuicOrder">
                        <span className={`shoppingListTableprice`}>
                          {row.dealerPrice && row.dealerPrice !== "0" ? (
                            <span className={`shoppingListTableprice`}>
                              ${addCommaDecimal(row.dealerPrice)}
                            </span>
                          ) : (
                            <></>
                          )}
                          {row.dealerPrice === "0" ? (
                            <span className={`shoppingListTableprice`}>
                              ${"0.00"}
                            </span>
                          ) : (
                            <></>
                          )}
                        </span>
                        {row.originalPrice &&
                          row.dealerPrice !== row.originalPrice &&
                          row.originalPrice !== "0" && (
                            <span
                              className={`shoppingListTablepriceDiscounted`}
                            >
                              $
                              {row.originalPrice &&
                                row.dealerPrice !== row.originalPrice &&
                                addCommaDecimal(row.originalPrice) &&
                                addCommaDecimal(row.originalPrice)}
                            </span>
                          )}
                      </div>
                    )}
                  </span>
                ) : (
                  <span className={`quickOrderTablePriceDesktop`}>
                    <div className="shopPrice">
                      <p className={`shoppingListTableprice `}>{"\u2014"}</p>
                    </div>
                  </span>
                )}
              </td>
              {/* <td className={`quickOrderTableStatusDivDesktop`}>
                <Cross
                  onClick={(event) => {
                    handleDelete(event, index, "partName");
                  }}
                  className="icon quickCross"
                ></Cross>
              </td> */}
              {/* <td className={`quickOrderTablePriceDivDesktop${currentDevice === 'Mobile' ? ' quickOrderTablePriceDivMobile' : ''}`}>
                            </td> */}
              {/* <td  className={`quickOrderTableStatusDivDesktop${currentDevice === 'Mobile' ? ' quickOrderTableStatusDivMobile' : ''}`}>
                            </td> */}
            </tr>
          ))}
        </tbody>
      </table>
      <div
        className={`quickOrderButtonSectionDesktop${
          currentDevice === "Mobile" ? " quickOrderButtonSectionMobile" : ""
        }`}
      >
        <div
          className={`quickOrderButtonAddDesktop${
            currentDevice === "Mobile" ? " quickOrderButtonAddMobile" : ""
          }`}
        >
          <div
            onClick={handleAddItem}
            className={`quickOrder__buttonWhitePlusDesktop${
              currentDevice === "Mobile"
                ? " quickOrder__buttonWhitePlusMobile"
                : ""
            }`}
          >
            +
          </div>
          <div
            onClick={handleAddItem}
            className={`quickOrder__buttonWhiteDesktop${
              currentDevice === "Mobile" ? " quickOrder__buttonWhiteMobile" : ""
            }`}
          >
            ADD ITEM
          </div>
        </div>
        <div
          className={`quickOrderBottomButtonsDesktop${
            currentDevice === "Mobile"
              ? " quickOrderBottomTopButtonsMobile"
              : ""
          }`}
        >
          <div
            className={`quickOrderCheckAvailDesktop${
              currentDevice === "Mobile" ? " quickOrderCheckAvailMobile" : ""
            }`}
          >
            <button
              onClick={handleCheck}
              disabled={quickOrderData.length === 0}
              className={` quickOrder__buttonDesktop ${
                currentDevice === "Mobile" ? " quickOrder__buttonMobile" : ""
              }`}
            >
              Check Availability
            </button>
          </div>
          {/* <div className={`quickOrderTableRightButtonsDesktop${currentDevice === 'Mobile' ? ' quickOrderTableRightButtonsMobile' : ''}`}>
                        <button className={`quickOrder__buttonGreyDesktop${currentDevice === 'Mobile' ? ' quickOrder__buttonGreyMobile' : ''}`}>Add to List</button>
                        <button className={`quickOrder__buttonDesktop${currentDevice === 'Mobile' ? ' quickOrder__buttonMobile' : ''}`}></button>
                    </div> */}
        </div>
        <div
          className={`quickOrderBottomButtonsDesktop${
            currentDevice === "Mobile" ? " quickOrderBottomButtonsMobile" : ""
          }`}
        >
          {/* <div  className={`quickOrderCheckAvailDesktop${currentDevice === 'Mobile' ? ' quickOrderCheckAvailMobile' : ''}`}>
                        <button className={`quickOrder__buttonDesktop${currentDevice === 'Mobile' ? ' quickOrder__buttonMobile' : ''}`}>Check Availibilty</button>
                    </div> */}
          <div
            className={`quickOrderTableRightButtonsDesktop${
              currentDevice === "Mobile"
                ? " quickOrderTableRightButtonsMobile"
                : ""
            }`}
          >
            <div
              onClick={() => {
                handleAddToList("");
              }}
              className={classNames(
                "iconWrapperGrey quickOrder__buttonGreyMobile quickOrder__buttonGreyDesktop center bgForCart addToliStMobileCenterr",
                {
                  disabledGreyButton:
                    // quickOrderData.length === 0 || isCheckItem,
                    !change || isCheckItem,
                }
              )}
            >
              <GreyShopList className="GreyShopList icon"></GreyShopList> Add to
              List
            </div>

            {myProfile?.profileInfo?.permissions?.includes(
              "add_to_own_cart"
            ) && (
              <div
                onClick={() => {
                  handleAddToList("cart");
                }}
                className={classNames(
                  "iconWrapper paddRight quickOrder__buttonGreyMobile center addToCartMobileCenterr",
                  {
                    disabledGreyButton:
                      // quickOrderData.length === 0 || isCheckItem,
                      !change || isCheckItem,
                    redGreyButtonBtnQuick:
                      // quickOrderData.length === 0 || isCheckItem,
                      change,
                  }
                )}
                // onClick={() => {
                //   setIsModalOpen(true);
                // }}
              >
                {change && !isCheckItem ? (
                  <RedCartEmpty className="GreyShopList icon"></RedCartEmpty>
                ) : (
                  <GreyCart className=" icoxxxn"></GreyCart>
                  //   <img
                  //   className={`footer__image${currentDevice}`}
                  //   src={newlogo}
                  //   alt="Ilmor Logo"
                  // />
                )}
                Add to Cart
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="add-to-cart-modal"
        aria-describedby="add-to-cart-modal-description"
      >
        <div className="addToCart-modal">
          <AddToCartPopUp
            setIsModalOpen={setIsModalOpen}
            shopList={shopListPop}
            isModalOpen={isModalOpen}
          />
        </div>
      </Modal>
      <Modal
        open={isModalOpenShop}
        onClose={() => setIsModalOpenShop(false)}
        aria-labelledby="add-to-cart-modal"
        aria-describedby="add-to-cart-modal-description"
      >
        <div className="addToCart-modal">
          <AddToShopPop
            setIsModalOpen={setIsModalOpenShop}
            shopList={shopListPop}
            yourCart={true}
            isModalOpen={isModalOpenShop}
          />
        </div>
      </Modal>
    </div>
  );
};
