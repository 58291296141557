import React, { useEffect } from 'react'
import './Loader.css'
const Loader = () => {

    useEffect(()=>{
         document.body.classList.add('loading');
         return () => {
            document.body.classList.remove('loading');
          }
    },[])
    
    return (
        <div id="loading-wrapper">

            <div id="loading-contents">
                <div className="ripple" id="ripple1"></div>
                <div className="ripple" id="ripple2"></div>
                <div className="ripple" id="ripple3"></div>
                <div id="loading-text">loading</div>
            </div>
        </div>
    )
}

export default Loader