import React from 'react'
import CategoryTile from './CateogoryTile'
import { useStore } from '../store/home'
import "./BrowseCategories.css"


export default function BrowseCategories({}) {
    const { setScreenSize,setCurrentDevice,screenSize,currentDevice} = useStore()
  

  return (
    <>
    
                    <CategoryTile/>
        
    </>
  )
}
