import React, { useRef, useState } from "react";
import {
  StyledRequiredField,
  StyledShippingHeading,
  StyledShippingSubHeading,
} from "../StyledComponents/ShippingAddressStyled";
import { Box, Checkbox, Grid, Divider } from "@mui/material";
import { useStore } from "../../store/home";
import { ContainerDivStyled } from "../StyledComponents/BusinessDetailsStyled";
import { ErrorMessageStyled } from "../StyledComponents/CommonStyled";
import { DevTool } from "@hookform/devtools";
import { ReactComponent as Visa } from "../../Images/visa.svg";
import { ReactComponent as Question } from "../../Images/questionMark.svg";
import Loader from "../../commenComp/Loader";
import { Radio } from "@material-ui/core";
import { useNonPersistedStore } from "../../store/NonPersistedStore";
import { ReactComponent as American } from "../../Images/master.svg";
import american from "../../Images/american.png";
import { ReactComponent as Discover } from "../../Images/discover.svg";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@material-ui/core/styles";
import * as yup from "yup";
import Select from "react-select";
import { useMutation } from "@tanstack/react-query";
import { authorizeNet, getStateCity, storePay } from "../../api/posts";
import { toast } from "react-toastify";
import { useNavigate,useLocation } from "react-router-dom";
import { customStyles } from "../../utils/ReactSelectCustomStyles";
import { useEffect } from "react";
import commonMessages from "../../commenComp/CommonMessages";
import TabletViewBlackContainer from "../TabletViewBlackContainer";
import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";

const AddPaymentMethod = () => {
  const [data1, setData] = useState(false);
  const [stateCityData, setStateCityData] = useState([]);
  const [countrySelectOption, setCountrySelectOption] = useState();
  const { setCardLoad } = useNonPersistedStore();
  const stateRef = useRef();
  const [nationalNumber, setNationalNumber] = useState("");
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (prepopulateEditAddress && prepopulateEditAddress?.length > 0) {
  //     handleCountrySelectChange(getValues("countryRegion"));
  //   }
  // }, [countrySelectOption]);

  useEffect(() => {
    if (myProfile?.countryList) {
      setCountrySelectOption(myProfile?.countryList);
    }
  }, []);

  const [completePhoneNumber, setCompletePhoneNumber] = useState("");

  useEffect(() => {
    if(completePhoneNumber){
      if (errors?.primaryPhone?.message === "Phone number is required") {
        errors.primaryPhone.message = "";
      }
    }
  }, [completePhoneNumber])
  
  const location = useLocation();
  const handlePhoneNumberChange = (data, field) => {
    if (!data) {
      setCompletePhoneNumber("");
      setNationalNumber("");

      return;
    }

    try {
      const parsedNumber = parsePhoneNumber(data?.toString());

      // setCountryCode(parsedNumber?.countryCallingCode);

      setCompletePhoneNumber(parsedNumber?.number);
      // setCustomPhoneNumber(parsedNumber?.number)
      setNationalNumber(parsedNumber?.nationalNumber);
    } catch (error) {}
  };

  const handleCountrySelectChange = (value) => {
    stateRef.current.setValue("StateProvince", null);
    if (value) {
      getStateCityMutation.mutate({ countryID: value });
    }
  };
  const schema = yup.object().shape({
    name: yup
      .string()
      .min(1, "Card Holder name must have at least 1 character")
      .max(50, "Card Holder name can have at most 50 characters")
      .required("Card Holder Name is required"),
    addressLine1: yup
      .string()
      .min(1, "Address must have at least 1 character")
      .max(60, "Address can have at most 60 characters")
      .required("Address is required"),
    countryRegion: yup
      .string()
      .min(1, "Country must have at least 1 character")
      .max(60, "Country can have at most 60 characters")
      .required("Country is required"),
    StateProvince: yup
      .string()
      .min(1, "State must have at least 1 character")
      .max(40, "State can have at most 40 characters")
      .required("State is required"),
    city: yup
      .string()
      .min(1, "City must have at least 1 character")
      .max(40, "City can have at most 40 characters")
      .required("City is required"),
    zipPostalCode: yup
      .string()
      .min(1, "Zip must have at least 1 character")
      .max(20, "Zip can have at most 20 characters")
      .required("Zip is required"),
    cardNumber: yup
      .string()
      .required("Card number is required")
      .matches(
        /^[0-9 ]+$/,
        "Card Number should only contain numbers and spaces"
      )
      .test("valid-card-number", "Card Number is not valid", (value) =>
        validateCardNumber(value)
      )
      .test("cardNumber", "Invalid card number", (value) => {
        const minCardLength = 12;
        const maxCardLength = 19;

        // Remove any non-digit characters from the input
        const cleanedNumber = value.replace(/\D/g, "");

        // Validate the length of the card number
        if (
          cleanedNumber.length < minCardLength ||
          cleanedNumber.length > maxCardLength
        ) {
          return false;
        }

        // Perform additional validation checks if needed
        // ...

        return true;
      })
      .required("Card Number is required"),
    expiryMonth: yup.string().required("Expiry Month is required"),
    expiryYear: yup.string().required("Expiry Year is required"),
    security: yup
      .string()
      .matches(/^\d{3,4}$/, "CVV must be 3 or 4 digits long")
      .required("Security Code is required"),
  });

  const currentYear = new Date().getFullYear();
  // const StyledRadio = styled(Radio)({
  //   color: "black", // Change the color here
  //   "&.Mui-checked": {
  //     color: "black", // Change the color for the checked state
  //   },
  // });

  const options = [
    { value: "01", label: "01" },
    { value: "02", label: "02" },
    { value: "03", label: "03" },
    { value: "04", label: "04" },
    { value: "05", label: "05" },
    { value: "06", label: "06" },
    { value: "07", label: "07" },
    { value: "08", label: "08" },
    { value: "09", label: "09" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
  ];

  const options2 = [...Array(10)].map((_, index) => ({
    value: (currentYear + index).toString(),
    label: (currentYear + index).toString(),
  }));

  const [load, setload] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    currentDevice,
    setSideMenuAccount,
    setcusProfile,
    token,
    cusProfile,
    myProfile,
  } = useStore();
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      //   businessLocationName:
      //     prepopulateEditAddress &&
      //     prepopulateEditAddress?.length > 0 &&
      //     prepopulateEditAddress[0]?.businessLocationName,
      //   addressLine1:
      //     prepopulateEditAddress &&
      //     prepopulateEditAddress?.length > 0 &&
      //     prepopulateEditAddress[0]?.address1,
      //   username: localStorage.getItem("email") || "",
      //   password: localStorage.getItem("password") || "",
    },
  });
  const [formattedValue, setFormattedValue] = useState("");
  const [formattedValue1, setFormattedValue1] = useState("");
  useEffect(() => {
    if (formattedValue) {
      if (errors?.cardNumber?.message === "Card number is required") {
        errors.cardNumber.message = "";
      }
    }
    if (formattedValue1) {
      if (errors?.security?.message === "Security Code is required") {
        errors.security.message = "";
      }
    }
    if (formattedValue1 && formattedValue1?.length > 1) {
      if (errors?.security?.message === "CVV must be 3 or 4 digits long") {
        errors.security.message = "";
      }
    }
    if (!validateCardNumber(formattedValue)) {
      if (errors?.cardNumber?.message === "Card Number is not valid") {
        errors.cardNumber.message = "";
      }
    }
  }, [errors, formattedValue, formattedValue1]);
  const getAllCards = {
    getCustomerProfileRequest: {
      merchantAuthentication: {
        name: process.env.REACT_APP_MERCHANT_NAME,
        transactionKey: process.env.REACT_APP_MERCHANT_TRANSACTION_KEY,
      },
      customerProfileId: cusProfile,
      // includeIssuerInfo: true,
      unmaskExpirationDate: true,
    },
  };

  const [allCards, getAllCardsArray] = useState(false);
  const [isDefault, setIsdefault] = useState(false);
  const authorize = useMutation((post) => authorizeNet(post), {
    onSuccess: (result) => {
      if (result) {
        setload(false);
        getAllCardsArray(result.data.profile.paymentProfiles);
      }
    },
    onError: (error) => {
      setload(false);
    },
  });
  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      if (cusProfile) {
        setload(true);
        authorize.mutate(getAllCards);
      }
      return;
    }
  }, [cusProfile]);

  useEffect(() => {
    if (!allCards) {
      setIsdefault(true);
      setValue("isDefault", true);
    } else {
      setIsdefault(false);
      setValue("isDefault", false);
    }
  }, [allCards]);

  const validateCardNumber = (value) => {
    // Remove any non-digit characters from the input value
    const cleanedValue = value.replace(/\D/g, "");

    // Validate the card number using a custom validation function or a library like Luhn algorithm

    // Example Luhn algorithm validation
    let sum = 0;
    let isEven = false;

    for (let i = cleanedValue.length - 1; i >= 0; i--) {
      let digit = parseInt(cleanedValue.charAt(i), 10);

      if (isEven) {
        if ((digit *= 2) > 9) digit -= 9;
      }

      sum += digit;
      isEven = !isEven;
    }

    return sum % 10 === 0;
  };

  const handleInputChange = (event) => {
    const input = event.target.value;
    const cleanedValue = input.replace(/\D/g, ""); // Remove non-digit characters
    let newFormattedValue = "";
    for (let i = 0; i < cleanedValue.length; i++) {
      newFormattedValue += cleanedValue.charAt(i);
      if ((i + 1) % 4 === 0 && i !== cleanedValue.length - 1) {
        newFormattedValue += " ";
      }
    }
    setFormattedValue(newFormattedValue);
  };

  // const CardNumberInput = ({ value, onChange }) => {
  //   const handleChange = (event) => {
  //     setTimeout(() => {
  //       document?.getElementById("card").focus();
  //     }, 0);
  //     // Remove any non-digit characters from the input value
  //     const cleanedValue = event.target.value.replace(/\D/g, "");

  //     // Format the cleaned value with spaces at specific intervals
  //     let formattedValue = "";
  //     for (let i = 0; i < cleanedValue.length; i++) {
  //       formattedValue += cleanedValue.charAt(i);
  //       if ((i + 1) % 4 === 0 && i !== cleanedValue.length - 1) {
  //         formattedValue += " ";
  //       }
  //     }

  //     onChange(formattedValue);
  //   };

  //   return (
  //     <>
  //       <input
  //         type="text"
  //         ref={inputRef}
  //         id="card"
  //         value={value}
  //         inputMode="numeric"
  //         maxLength={23}
  //         onChange={handleChange}
  //         className={`InputFieldShippingAddress`}
  //       />
  //       {/* {errors.cardNumber && <span>{errors.cardNumber.message}</span>} */}
  //     </>
  //   );
  // };

  // const CardSecuirtyInput = ({ value, onChange }) => {
  //   const handleChange = (event) => {
  //     setTimeout(() => {
  //       document?.getElementById("ccv").focus();
  //     }, 100);
  //     const cleanedValue = event.target.value.replace(/\D/g, "");
  //     if (cleanedValue.length <= 4) {
  //       let formattedValue = "";
  //       for (let i = 0; i < cleanedValue.length; i++) {
  //         formattedValue += cleanedValue.charAt(i);
  //         if ((i + 1) % 4 === 0 && i !== cleanedValue.length - 1) {
  //           formattedValue += " ";
  //         }
  //       }

  //       onChange(formattedValue);
  //     }
  //   };

  //   return (
  //     <>
  //       <input
  //         type="text"
  //         value={value}
  //         inputMode="numeric"
  //         id="ccv"
  //         onChange={handleChange}
  //         // onFocus={handleFocus}
  //         className={`InputFieldShippingAddress inputBoxDisable`}
  //         maxLength={4}
  //       />
  //       <Tooltip
  //         placement="right"
  //         title={
  //           "CVV (Card Verification Value) is a three-digit security code printed on the back of your credit card. It helps ensure that the person making the transaction possesses the physical card. Please enter the three-digit code to complete the transaction"
  //         }
  //       >
  //         <Question className="catogeryIcon imageReltive"></Question>
  //       </Tooltip>
  //     </>
  //   );
  // };

  const [selectedValue, setSelectedValue] = useState("true");

  const storePayMethod = useMutation((post) => storePay(post, token), {
    onSuccess: (result) => {
      showSuccessToast("Card addedd successfully");
      setSideMenuAccount("paymentMethods");
      navigate("/account/mgmt/payment", { state: { useCard: location?.state?.useCard } });
      setCardLoad(true);
    },
    onError: (error) => {},
  });

  const storePayMethodnew = useMutation((post) => storePay(post, token), {
    onSuccess: (result) => {
      // showSuccessToast("New Customer profile is created in Authorize.net");
      setSideMenuAccount("paymentMethods");
      navigate("/account/mgmt/payment", { state: { useCard: location?.state?.useCard } });
    },
    onError: (error) => {},
  });

  const [cus, setCus] = useState("");
  const [payid, setPayId] = useState("");

  const authAPI = useMutation((post) => authorizeNet(post), {
    onSuccess: (result) => {
      if (result.data.messages.resultCode === "Ok") {
        const obj = {};
        obj.id = myProfile.profileInfo.id;
        obj.ReferenceLabel = getValues("reference");
        obj.ProfileInformationId = result.data.customerPaymentProfileId
          ? result.data.customerPaymentProfileId
          : payid;
        obj.Active = selectedValue === "true";
        obj.flag = 1;
        obj.CustomerProfileId = result.data.customerProfileId
          ? result.data.customerProfileId
          : cus;
        storePayMethod.mutate(obj);
      } else {
        showErrorToast(result.data.messages.message[0].text);
      }
    },
    onError: (error) => {},
  });

  const authAPICreate = useMutation((post) => authorizeNet(post), {
    onSuccess: (result) => {
      if (result.data.messages.resultCode === "Ok") {
        const obj = {};
        obj.id = myProfile.profileInfo.id;
        obj.ReferenceLabel = getValues("reference");
        obj.ProfileInformationId = result.data.customerPaymentProfileIdList[0];
        obj.Active = selectedValue === "true";
        obj.flag = 1;
        obj.CustomerProfileId = result?.data?.customerProfileId;
        setCus(result?.data?.customerProfileId);
        setPayId(result.data.customerPaymentProfileIdList[0]);
        setcusProfile(result?.data?.customerProfileId);
        storePayMethodnew.mutate(obj);
        const updateCard = {
          updateCustomerPaymentProfileRequest: {
            merchantAuthentication: {
              name: process.env.REACT_APP_MERCHANT_NAME,
              transactionKey: process.env.REACT_APP_MERCHANT_TRANSACTION_KEY,
            },
            customerProfileId: result.data.customerProfileId,
            paymentProfile: {
              billTo: {
                firstName: data1?.name.split(" ")[0]
                  ? data1?.name.split(" ")[0]
                  : "",
                lastName: data1?.name.split(" ")[1]
                  ? data1?.name.split(" ")[1]
                  : "",
                  address: data1?.addressLine1,
                  city: data1?.city,
                  state: data1?.StateProvince,
                  zip: data1?.zipPostalCode,
                  country: data1?.countryRegion,
                  phoneNumber: completePhoneNumber,
              },
              payment: {
                creditCard: {
                  cardNumber: data1.cardNumber.replace(/\s/g, ""),
                  expirationDate: data1.expiryYear + "-" + data1.expiryMonth,
                },
              },
              defaultPaymentProfile: true,
              customerPaymentProfileId:
                result.data.customerPaymentProfileIdList[0],
            },
            validationMode: "liveMode",
          },
        };
        authAPI.mutate(updateCard);
      } else {
        showErrorToast(result.data.messages.message[0].text);
      }
    },
    onError: (error) => {},
  });
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleChange1 = (event) => {
    setFormattedValue1(event.target.value);
  };
  // const handleSignIn = () => {
  //   let ele = false;
  //   ele = document.getElementById("errorCard");
  //   let ele1 = false;
  //   ele1 = document.getElementById("namecar");
  //   let ele2 = false;
  //   ele2 = document.getElementById("expiryMonthcar");
  //   let ele3 = false;
  //   ele3 = document.getElementById("expiryyeaercar");
  //   if (ele && !ele1) {
  //     setTimeout(() => {
  //       document?.getElementById("card").focus();
  //     }, 100);
  //   }
  //   if (!ele && !ele1 && !ele2 && !ele3) {
  //     setTimeout(() => {
  //       document?.getElementById("ccv").focus();
  //     }, 100);
  //   }
  // };

  const onSubmit = (data, event) => {
    setData(data);
    event.preventDefault();
    if (cusProfile) {
      const addCard = {
        createCustomerPaymentProfileRequest: {
          merchantAuthentication: {
            name: process.env.REACT_APP_MERCHANT_NAME,
            transactionKey: process.env.REACT_APP_MERCHANT_TRANSACTION_KEY,
          },
          customerProfileId: cusProfile,
          paymentProfile: {
            billTo: {
              firstName: data?.name.split(" ")[0]
                ? data?.name.split(" ")[0]
                : "",
              lastName: data?.name.split(" ")[1]
                ? data?.name.split(" ")[1]
                : "",
              address: data?.addressLine1,
              city: data?.city,
              state: data?.StateProvince,
              zip: data?.zipPostalCode,
              country: data?.countryRegion,
              phoneNumber: completePhoneNumber,
            },
            payment: {
              creditCard: {
                cardNumber: data.cardNumber.replace(/\s/g, ""),
                expirationDate: data.expiryYear + "-" + data.expiryMonth,
              },
            },
            defaultPaymentProfile: data.isDefault,
          },
          validationMode: "liveMode",
        },
      };
      authAPI.mutate(addCard);
    } else {
      let createNewCustomerProfilePayload = {
        createCustomerProfileRequest: {
          merchantAuthentication: {
            name: process.env.REACT_APP_MERCHANT_NAME,
            transactionKey: process.env.REACT_APP_MERCHANT_TRANSACTION_KEY,
          },
          profile: {
            merchantCustomerId: myProfile?.profileInfo?.customernumber
              ? myProfile?.profileInfo?.customernumber
              : "",
            email: myProfile.profileInfo.email,
            paymentProfiles: {
              customerType: "individual",
              billTo: {
                firstName: data?.name.split(" ")[0]
                  ? data?.name.split(" ")[0]
                  : "",
                lastName: data?.name.split(" ")[1]
                  ? data?.name.split(" ")[1]
                  : "",
                  address: data?.addressLine1,
                  city: data?.city,
                  state: data?.StateProvince,
                  zip: data?.zipPostalCode,
                  country: data?.countryRegion,
                  phoneNumber: completePhoneNumber,
              },
              payment: {
                creditCard: {
                  cardNumber: data.cardNumber.replace(/\s/g, ""),
                  expirationDate: data.expiryYear + "-" + data.expiryMonth,
                },
              },
            },
          },
          // validationMode: "liveMode",
        },
      };
      authAPICreate.mutate(createNewCustomerProfilePayload);
    }
  };

  const [flagFirstRender, setflagflagFirstRender] = useState(true);
  const getStateCityMutation = useMutation(
    (post) => getStateCity(post, token),
    {
      onSuccess: (result) => {
        if (result) {
          if (result.data.success) {
            // setValue("StateProvince", 56);
            if (flagFirstRender) {
              setValue("StateProvince", "notset");
              setflagflagFirstRender(false);
            }
            showSuccessToast(result?.data?.msg);
            setStateCityData(result?.data?.data);
          } else {
            showErrorToast(result?.data?.msg);
          }
        }
      },
      onError: (error) => {},
    }
  );

  return (
    <Box
      sx={{
        background: "#eaeaea",
        width: "100%",
      }}
    >
      <ContainerDivStyled pad={true} currentDevice={currentDevice}>
        {(authAPI.isLoading ||
          authAPICreate.isLoading ||
          load ||
          storePayMethod.isLoading) && <Loader></Loader>}
        <Grid container>
          <Grid item xs={12} sm={12} md={9}>
            {" "}
            <Grid container spacing={1}>
              <Grid item>
                <div className="addpay">
                  <StyledShippingHeading currentDevice={currentDevice}>
                    {commonMessages?.addPaymentMethodHeading}
                  </StyledShippingHeading>
                </div>
              </Grid>
              {currentDevice == "Tablet" && (
                <Grid item sm={12} md={12}>
                  <TabletViewBlackContainer />
                </Grid>
              )}

              <Grid item sm={12} md={12}>
                <StyledShippingSubHeading>
                  {commonMessages?.addPaymentMethodsSubHeadingOne}
                </StyledShippingSubHeading>{" "}
              </Grid>
              <Grid
                display={"flex"}
                gap={2}
                item
                sm={12}
                md={12}
                className="DesktopVisaAddPayment"
              >
                <Visa className="catogeryIcon"></Visa>
                <American className="catogeryIcon"></American>
                <Discover className="catogeryIcon"></Discover>  
                <div className="americad center">
                <img src={american} className="cardWidthameruc"></img>
                </div>
              </Grid>
              <Grid item xs={8} sm={8} md={12}>
                <StyledRequiredField>
                  {commonMessages?.addEmployeeAccountRequiredField}
                </StyledRequiredField>
              </Grid>
            </Grid>
            {currentDevice == "Mobile" && (
              <Divider sx={{ margin: "25px 0px 25px 0px" }} />
            )}
            {currentDevice == "Tablet" && (
              <Divider sx={{ margin: "30px 0px 34px 0px" }} />
            )}
            {currentDevice == "Desktop" && (
              <Divider sx={{ margin: "30px 0px 34px 0px" }} />
            )}
            <div>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={`form shippingForm`}
                noValidate
              >
                <div className="labelDesktopAddShippingBottomDesktopPadding gkrtj454">
                  <h5 className={`labelDesktopAddShipping caps`}>
                    Reference Label
                  </h5>

                  <input
                    // style={{ border: "none" }}
                    className={`InputFieldShippingAddress`}
                    // autoFocus
                    type="text"
                    // value={email}
                    name="reference"
                    // onChange={handleEmailChange}
                    {...register("reference")}
                  />

                  <div className="thisCanBe">
                    {commonMessages?.addPaymentMethodsSubHeadingTwo}
                  </div>
                  {errors?.reference && (
                    <ErrorMessageStyled>
                      {errors.reference?.message}
                    </ErrorMessageStyled>
                  )}
                </div>
                <div>
                  <h5 className={`labelDesktopAddShipping caps`}>
                    Card Status
                  </h5>

                  <div className="cardStatus cardStatusDesktopAddAPyment">
                    <Radio
                      checked={selectedValue === "true"}
                      onChange={handleChange}
                      value={"true"}
                      color="black"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                    />
                    <div className="activeInactive">Active</div>
                    <Radio
                      checked={selectedValue === "false"}
                      onChange={handleChange}
                      value={"false"}
                      color="black"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "B" }}
                    />
                    <div className="activeInactive">Inactive</div>
                  </div>
                </div>
                <div className={`passwordlabelDesktopAddShipping`}>
                  <h5 className={`labelDesktopAddShippingRequired`}>ADDRESS</h5>
                </div>
                <input
                  name="addressLine1"
                  // style={{ border: "none" }}
                  className={`InputFieldShippingAddress`}
                  type="text"
                  {...register("addressLine1")}
                ></input>
                {errors?.addressLine1 && (
                  <ErrorMessageStyled>
                    {errors?.addressLine1?.message}
                  </ErrorMessageStyled>
                )}
                <div className={`passwordlabelDesktopAddShipping`}>
                  <h5 className={`labelDesktopAddShippingRequired`}>COUNTRY</h5>
                </div>
                <Controller
                  // rules={{ required: true }}
                  name="countryRegion"
                  control={control}
                  render={({ onChange, value, ref, field }) => {
                    return (
                      <Select
                        // className='select'
                        {...field}
                        className="jkdhs"
                        placeholder={<div></div>}
                        options={countrySelectOption}
                        value={countrySelectOption?.find(
                          (option) => option.label === field.label
                        )}
                        // value={}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption?.label);
                          handleCountrySelectChange(selectedOption?.value);
                        }}
                        styles={customStyles}
                        //  menuIsOpen={true}
                        // defaultValue={userRole.find((c) => c.value === countryValue)}
                      />
                    );
                  }}
                />
                {errors?.countryRegion && (
                  <ErrorMessageStyled>
                    {errors?.countryRegion?.message}
                  </ErrorMessageStyled>
                )}
                <Grid container spacing={1}>
                  <Grid
                    item
                    sm={4}
                    xs={12}
                    md={4}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className={`emailLabel`}>
                      <h5 className={`labelDesktopAddShippingRequired`}>
                        STATE / PROVINCE
                      </h5>
                    </div>
                    <Controller
                      // rules={{ required: true }}
                      name="StateProvince"
                      control={control}
                      render={({ onChange, value, ref, field }) => {
                        return (
                          <Select
                            // className='select'
                            {...field}
                            // value={stateCityData?.find(
                            //   (option) => option.value === field.value
                            // )}
                            isLoading={getStateCityMutation.isLoading}
                            isDisabled={!getValues("countryRegion")}
                            options={stateCityData}
                            className="jkdhs"
                            placeholder={<div></div>}
                            //  value={stateCityData.find((c) => c.value === value)}
                            value={stateCityData?.find(
                              (option) => option.label === field.label
                            )}
                            onChange={(selectedOption) => {
                              field?.onChange(selectedOption?.label);
                            }}
                            ref={stateRef}
                            styles={customStyles}
                            //  menuIsOpen={true}
                            // defaultValue={userRole.find((c) => c.value === countryValue)}
                          />
                        );
                      }}
                    />
                    {errors?.StateProvince && (
                      <ErrorMessageStyled>
                        {errors?.StateProvince?.message}
                      </ErrorMessageStyled>
                    )}
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={12}
                    sm={4}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className={`emailLabel`}>
                      <h5 className={`labelDesktopAddShippingRequired`}>
                        CITY
                      </h5>
                    </div>
                    <input
                      // style={{ border: "none" }}
                      className={`InputFieldShippingAddress`}
                      type="text"
                      // value={email}
                      name="city"
                      // onChange={handleEmailChange}
                      {...register("city")}
                    ></input>{" "}
                    {errors?.city && (
                      <ErrorMessageStyled>
                        {errors?.city?.message}
                      </ErrorMessageStyled>
                    )}
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={12}
                    sm={4}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className={`emailLabel`}>
                      <h5 className={`labelDesktopAddShippingRequired`}>
                        ZIP POSTAL CODE
                      </h5>
                    </div>
                    <input
                      // style={{ border: "none" }}
                      className={`InputFieldShippingAddress`}
                      type="text"
                      maxlength="12"
                      // value={email}
                      name="zipPostalCode"
                      // onChange={handleEmailChange}
                      {...register("zipPostalCode")}
                    ></input>
                    {errors?.zipPostalCode && (
                      <ErrorMessageStyled>
                        {errors?.zipPostalCode?.message}
                      </ErrorMessageStyled>
                    )}
                  </Grid>
                </Grid>
                <div className={`passwordlabelDesktopAddShipping`}>
                  <h5 className={`labelDesktopAddShipping`}>PRIMARY PHONE</h5>
                </div>
                <Controller
                  // rules={{ required: true }}
                  name="primaryPhone"
                  control={control}
                  render={({ onChange, value, onBlur, ref, field }) => {
                    return (
                      <PhoneInput
                        {...field}
                        // value={customPhoneNumber}
                        // value={userRole.find((c) => c.value === value)}
                        // onChange={(selectedOption) => {
                        //   handleSelectChange(selectedOption?.label,selectedOption?.value);
                        // }}
                        // countries =  {["RU", "UA", "KZ","IN","PK","US","NZ"]}
                        placeholder="Enter Phone Number"
                        // onChange={(data)=>handlePhoneNumberChange(data,field)}
                        onChange={handlePhoneNumberChange}
                        // international
                        countryCallingCodeEditable={false}
                        addInternationalOption={false}
                        defaultCountry="US"
                        className="ReactPhoneNumInputt"
                        minLength="7"
                        limitMaxLength={true}
                        style={{
                          backgroundColor: "white",
                          border: "none",
                          padding: "0px 10px",
                        }}
                      />
                    );
                  }}
                />
                <div>
                  <h5 className={`labelDesktopAddShippingRequired caps caps`}>
                    Cardholder Name
                  </h5>
                  <input
                    className={`InputFieldShippingAddress`}
                    type="text"
                    name="name"
                    max={50}
                    {...register("name")}
                  />
                  {errors?.name && (
                    <ErrorMessageStyled id="namecar">
                      {errors.name?.message}
                    </ErrorMessageStyled>
                  )}
                </div>
                <div>
                  <h5 className={`labelDesktopAddShippingRequired caps caps`}>
                    Card Number
                  </h5>

                  {/* <Controller
                    name="cardNumber"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <CardNumberInput {...field} />}
                  /> */}
                  <input
                    type="text"
                    value={formattedValue}
                    id="card"
                    {...register("cardNumber")}
                    inputMode="numeric"
                    maxLength={23}
                    onChange={handleInputChange}
                    className={`InputFieldShippingAddress`}
                  />
                  {errors?.cardNumber && (
                    <ErrorMessageStyled id="errorCard">
                      {errors.cardNumber?.message}
                    </ErrorMessageStyled>
                  )}
                </div>
                <div>
                  <h5 className={`labelDesktopAddShippingRequired  caps`}>
                    Expiration Date
                  </h5>

                  {/* <input
                    // style={{ border: "none" }}
                    className={`InputFieldShippingAddress`}
                    type="text"
                    // value={email}
                    name="expiry"
                    // onChange={handleEmailChange}
                    {...register("expiry")}
                  /> */}
                  <Grid container spacing={1}>
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Controller
                        name="expiryMonth"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Select
                            {...field}
                            className="addpaymentcardwidth"
                            id="expiryMonth"
                            value={options.find(
                              (option) => option.value === field.value
                            )}
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption?.value);
                            }}
                            styles={customStyles}
                            options={[
                              { value: "01", label: "01" },
                              { value: "02", label: "02" },
                              { value: "03", label: "03" },
                              { value: "04", label: "04" },
                              { value: "05", label: "05" },
                              { value: "06", label: "06" },
                              { value: "07", label: "07" },
                              { value: "08", label: "08" },
                              { value: "09", label: "09" },
                              { value: "10", label: "10" },
                              { value: "11", label: "11" },
                              { value: "12", label: "12" },
                            ]}
                            placeholder="MM"
                          />
                        )}
                      />
                      {errors?.expiryMonth && (
                        <ErrorMessageStyled id="expiryMonthcar">
                          {errors.expiryMonth?.message}
                        </ErrorMessageStyled>
                      )}
                    </Grid>
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Controller
                        name="expiryYear"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Select
                            {...field}
                            id="expiryYear"
                            className="addpaymentcardwidth"
                            value={options2.find(
                              (option) => option.value === field.value
                            )}
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption?.value);
                            }}
                            styles={customStyles}
                            options={options2}
                            placeholder="YY"
                          />
                        )}
                      />
                      {errors?.expiryYear && (
                        <ErrorMessageStyled id="expiryyeaercar">
                          {errors.expiryYear?.message}
                        </ErrorMessageStyled>
                      )}
                    </Grid>
                  </Grid>
                </div>
                <div className={`passwordlabelDesktopAddShipping   `}>
                  <h5 className={`labelDesktopAddShippingRequired caps caps`}>
                    Security Code
                  </h5>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      sm={2}
                      xs={2}
                      md={2}
                      sx={{
                        display: "flex",

                        flexDirection: "column",
                        maxWidth: "121px !important",
                      }}
                      className={`shamoonDesktopAddPAymentBottom`}
                    >
                      {/* <Controller
                        name="security"
                        control={control}
                        defaultValue=""
                        render={({ field }) => <CardSecuirtyInput {...field} />}
                      /> */}
                      <div className="dflkjgfdk232">
                        <input
                          type="text"
                          value={formattedValue1}
                          name="security"
                          inputMode="numeric"
                          {...register("security")}
                          id="ccv"
                          onChange={handleChange1}
                          // onFocus={handleFocus}
                          className={`InputFieldShippingAddress inputBoxDisable`}
                          maxLength={4}
                        />
                        <Tooltip
                          placement="right"
                          title={
                            "CVV (Card Verification Value) is a three-digit security code printed on the back of your credit card. It helps ensure that the person making the transaction possesses the physical card. Please enter the three-digit code to complete the transaction"
                          }
                        >
                          <Question className="catogeryIcon imageReltive"></Question>
                        </Tooltip>
                      </div>
                      {errors?.security && (
                        <ErrorMessageStyled className="minWid">
                          {errors?.security?.message}
                        </ErrorMessageStyled>
                      )}
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      className="asdflkhserjklfh"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      {/* <input
                        name="isDefault"
                        // style={{ border: "none" }}
                        className={`inputCheckboxGlobal`}
                        disabled={isDefault}
                        type="checkbox"
                        {...register("isDefault")}
                      ></input> */}
                      <Controller
                        name="isDefault"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            disabled={isDefault}
                            sx={{
                              // color: "white",
                              // "&.Mui-checked": {
                              //   color: "white",
                              // },
                              // "& .MuiSvgIcon-root": {
                              //   fill: "black",
                              // },
                              // "&.Mui-checked .MuiSvgIcon-root": {
                              //   fill: "black",
                              // },
                              "&.Mui-checked .MuiIconButton-label": {
                                backgroundColor: "black",
                              },
                            }}
                            checked={field.value ? true : false}
                          />
                        )}
                      />
                      <div className="addPayfont">
                        {commonMessages?.addPaymentMethodsSubHeadingThree}
                      </div>
                    </Grid>
                  </Grid>
                </div>
                {currentDevice != "Desktop" && (
                  <Divider sx={{ margin: "30px 0px 30px 0px" }} />
                )}
                {currentDevice == "Desktop" && (
                  <Divider sx={{ margin: "36px 0px 30px 0px" }} />
                )}

                <Box
                  className="sdkfnmcsdl gumaanDesktopTalhaAnjumBottomPadding"
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "row",
                    flexGrow: 1,
                    p: 1,
                    m: 1,
                    gap: 2,

                    borderRadius: 1,
                  }}
                >
                  <button
                    style={{ marginRight: "18px" }}
                    type="button"
                    className={`cancelButonAddShipping`}
                    // onClick={() => setSideMenuAccount("paymentMethods")}
                    onClick={() => {
                      setSideMenuAccount("paymentMethods");
                      navigate("/account/mgmt/payment", { state: { useCard: location?.state?.useCard } });
                    }}
                  >
                    Cancel
                  </button>
                  <button className={`ilmorButon`}>Add Payment Method</button>
                </Box>
              </form>

              <DevTool control={control} />
            </div>
          </Grid>
        </Grid>
        <Box>
          {currentDevice == "Mobile" && (
            <TabletViewBlackContainer currentDevice={currentDevice} />
          )}
        </Box>
      </ContainerDivStyled>
    </Box>
  );
};

export default AddPaymentMethod;
