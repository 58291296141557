import { Box, Button, styled } from "@mui/material";
export const ContainerDivStyled = styled(Box)(
  ({ theme, bg, currentDevice, pad, greyUpperPart }) => ({
    display: "flex",
    flexDirection: "column",
    background: bg ? bg : "#eaeaea",
    padding:
      currentDevice == "Desktop"
        ? "24px 60px 60px 60px"
        : currentDevice == "Tablet"
        ? "28px " //causing issue in shop engine so removed important
        : "0px 20px 0px 20px",
    // padding:
    //   currentDevice == "Mobile" && pad ? "40px 20px 0px 20px" : "0px 20px 0px 20px",
    // paddingBottom:currentDevice =="Desktop" ? "60px":"0px",

    // width:" 100%"
  })
);
