import React, { useState, useEffect } from "react";
import { TextField, Button, Grid, Paper, makeStyles } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import "./ForgetPassword.css";
import logo from "../Images/ILMOR_LOGO.png";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { render } from "@testing-library/react";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../api/posts";
import { useStore } from "../store/home";
import commonMessages from "../commenComp/CommonMessages";
import { showErrorToast } from "../utils/Toaster";
import Loader from "../commenComp/Loader";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(10),
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
    maxHeight: 600,
    backgroundColor: "rgb(255,255,255)",
    boxShadow: "rgb(153, 153, 153) 2px 2px 2px",
    borderRadius: 10,
    letterSpacing: 0.8,
  },
  textField: {
    margin: theme.spacing(3),
    width: "70%",
    backgroundColor: "rgb(255,255,255)",
    // marginLeft: "-7%",
  },
  button: {
    marginTop: theme.spacing(2),
    borderRadius: 0,
    marginBottom: "8%",
    textTransform: "capitalize",
  },
}));

const ForgetPassword = () => {
  const { currentDevice, setForgotEmail } = useStore();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const classes = useStyles();
  const mutation = useMutation((post) => forgotPassword(post), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
          navigate("/reset-password", {
            state: { resposnse: result.data.success },
          });
        } else {
          showErrorToast(result?.data?.msg);
        }
      }
    },
    onError: (error) => {},
  });
  const onSubmit = (data, event) => {
    event.preventDefault();
    setForgotEmail(data.username);
    const obj = {
      email: data.username,
    };
    mutation.mutate(obj);
    // navigate({
    //   pathname : "/reset-password",
    //   state: { email: data.username},
    // });
  };

  const schema = yup.object().shape({
    username: yup.string().email("The entered email is not valid"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const [userNotValid, isUserNotValid] = useState(false);
  const [invalidCredMessage, setInvalidCredMessage] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setInvalidCredMessage(false);
      isUserNotValid(false);
    } else {
      if (Object.keys(errors).includes("username")) {
        toast.dismiss()
        window.scrollTo(0,0)
        const ele = document.getElementById('sdjfhn454')
        ele.click();
        showErrorToast(errors.username.message)
        isUserNotValid(true);
      } else {
        isUserNotValid(false);
      }
    }
  }, [errors]);

  return (
    <>
      {mutation.isLoading && <Loader></Loader>}
      <div className={`ForgetPw__main${currentDevice}`}>
        <div className={`ForgetPw_Container${currentDevice}`}>
          <div className={`ForgetPw_logo${currentDevice}`}>
            <img
              className={`ForgetPw_title-image${currentDevice}`}
              src={logo}
              id="sdjfhn454"
              alt="company-logo"
            />
          </div>
          <div className={`ForgetPw_info__main${currentDevice}`}>
            <div className={`ForgetPw_headingBox${currentDevice}`}>
              <h2
                className={`ForgetPw_heading${currentDevice}   tabletHeadingParaSet`}
                style={{
                  marginBottom:
                    currentDevice == "Mobile"
                      ? "12px"
                      : currentDevice == "Tablet"
                      ? "25px"
                      : "15px",
                }}
              >
                {commonMessages?.forgetPasswordHeading}
              </h2>
              <div className={`passwordRecoveryMsg${currentDevice}`}>
                {commonMessages?.forgetPasswordSubHeadingOne}
              </div>
            </div>

            {/* <hr className={`ForgetPw_lineBreak__main${currentDevice}`}></hr> */}

            {invalidCredMessage && (
              <div className={`invalidCredMessage${currentDevice}`}>
                {commonMessages?.forgetPasswordSubHeadingTwo}
              </div>
            )}
            {currentDevice != "Tablet" && (
              <Divider
                variant="middle"
                className="fklgyhnkfyhj343"
                sx={{ width: "100%", margin: "20px 0px 20px 0px" }}
              />
            )}
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={`ForgetPw_form${currentDevice}`}
            >
              <div className={`ForgetPw_emailLabel${currentDevice}`}>
                <h5
                  className={`ForgetPw_label${currentDevice} tabeltBootomEmailInputPadding`}
                >
                  EMAIL
                </h5>
              </div>
              <input
                className={`ForgetPw_usernameInput${currentDevice}${
                  userNotValid ? " FogetPw_usernameInputwarning" : ""
                }`}
                type="text"
                // value={email}
                name="username"
                onChange={handleEmailChange}
                {...register("username")}
                required
              ></input>

              <div className={`just1 ResetPwBtnBox${currentDevice}`}>
                <button
                  className={`ResetPw${currentDevice}`}
                  type="button"
                  onClick={() => {
                    navigate('/sign-in');
                  }}
                  // disabled={!email || !password}
                >
                  {" "}
                  <span className={`curp ResetPwLabel${currentDevice}`}>
                    Back to Sign In Page
                  </span>
                </button>
                <button
                  className={`mgright ResetPw${currentDevice}`}
                  type="submit"
                  // onClick={handleSignIn}
                  // disabled={!email || !password}
                >
                  {" "}
                  <span className={`curp ResetPwLabel${currentDevice}`}>
                    Reset Password
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
