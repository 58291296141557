import React, { useEffect, useState } from "react";

import "./ShippingAddressBook.css";
import { Box, Grid } from "@mui/material";
import AddShippingAddress from "./AddShippingAddress";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  removeShippingAddress,
  selectShippingAddress,
  shippingAddressDefault,
} from "../api/posts";
import { useStore } from "../store/home";
import { ReactComponent as AddressIcon } from "../Images/ShippingAddressIcon.svg";
import { ReactComponent as PlusIcon } from "../Images/PlusIcon.svg";
import { ReactComponent as AccountOverviewShippingAddress } from "../Images/AccountOverviewShippingAddress.svg";

import {
  styled,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
} from "@mui/material";
import { showErrorToast, showSuccessToast } from "../utils/Toaster";
import {
  StyledShippingHeading,
  CardContentStyled,
  StyledShippingSubHeading,
  CardHeadingStyled,
} from "../components/StyledComponents/ShippingAddressStyled";
import Loader from "../commenComp/Loader";
import TabletViewBlackContainer from "./TabletViewBlackContainer";
import { ContainerDivStyled } from "./StyledComponents/BusinessDetailsStyled";
import { AddEmployeeAccountBtnStyled } from "./StyledComponents/EmployeeAccountStyled";
import commonMessages from "../commenComp/CommonMessages";
import IlmorModal from "../commenComp/IlmorModal";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { truncBoy } from "../commonFunction";

const ShippingAddressBook = () => {
  const [isShippingAddressbtnClicked, setIsShippingAddressbtnClicked] =
    useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [removeIdTablerow, setRemoveIdTablerow] = useState();
  const [removeCardId, setRemoveCardId] = useState("");
  const [selectShippingAddressData, setSelectShippingAddressData] = useState();
  const [prepopulateEditAddress, setPrepopulateEditAddress] = useState();
  const location = useLocation();
  const navigate = useNavigate();

  const [editID, setEditID] = useState();

  const {
    token,
    dealerId,
    userInfo,
    myProfile,
    setDomesticCH,
    sideMenuAccount,
    currentDevice,
    setshipPrice,
  } = useStore();
  const {
    setUseThisAddress,
    useThisAddress,
    setShippingPaymentAddressUpdated,
    selectAdd,
    setselectAdd,
  } = useNonPersistedStore();

  const removeShippingMutation = useMutation(
    (post) => removeShippingAddress(post, token),
    {
      onSuccess: (result) => {
        if (result) {
          mutation.mutate({ email: userInfo?.username, dealerId: dealerId });
          showSuccessToast(result?.data?.msg);

          // navigate(
          //   "/reset-password",{ state: { resposnse: result.data.success } });
        }
      },
      onError: (error) => {},
    }
  );

  const mutation = useMutation((post) => selectShippingAddress(post, token), {
    onSuccess: (result) => {
      if (result) {
        setSelectShippingAddressData(result?.data?.data);
        if (selectAdd) {
          if (result?.data?.data && result?.data?.data?.length > 0) {
            let item =
            result?.data?.data[result?.data?.data.length - 1];
            handleUseThisAddress(item);
            setselectAdd(false)
          }
        }
        // setPrepopulateEditAddress(result?.data?.data)
        // navigate(
        //   "/reset-password",{ state: { resposnse: result.data.success } });
      }
    },
    onError: (error) => {},
  });
  const mutationDefaultShipping = useMutation(
    (post) => shippingAddressDefault(post, token),
    {
      onSuccess: (result) => {
        if (result) {
          if (result?.data?.success) {
            showSuccessToast(result?.data?.msg);
            const obj = {
              email: userInfo.username,
              dealerId: dealerId,
            };
            mutation.mutate(obj);
          } else {
            showErrorToast(result?.data?.msg);
          }

          // setPrepopulateEditAddress(result?.data?.data)
          // navigate(
          //   "/reset-password",{ state: { resposnse: result.data.success } });
        }
      },
      onError: (error) => {},
    }
  );
  useEffect(() => {
    if (!isShippingAddressbtnClicked) {
      mutation.mutate({ email: userInfo?.username, dealerId: dealerId });
    }
  }, [isShippingAddressbtnClicked]);

  const handleEditClick = (id) => {
    setIsShippingAddressbtnClicked(true);
    const editResponse = selectShippingAddressData?.filter((item) => {
      return item?.id == id;
    });
    setPrepopulateEditAddress(editResponse);
    setEditID(id);
    window.scrollTo(0, 0);
    let str = `id=${id}`;
    navigate({
      pathname: "/account/mgmt/shipping/edit",
      search: `${str}`,
    });
    // mutation.mutate({ id: id });
  };

  const handleCancel = (id) => {
    setCancelDialog(!cancelDialog);
    setRemoveIdTablerow(id);
  };

  const handleOkay = () => {
    removeShippingMutation.mutate({ id: removeIdTablerow });
    setCancelDialog(!cancelDialog);
  };
  const handleRemoveClick = (id) => {
    removeShippingMutation.mutate({ id: id });
  };
  const handleDefaultClick = (id) => {
    mutationDefaultShipping.mutate({ id: id });
    // alert("default api not ready yet");
  };

  const AddShippingAddressClick = () => {
    window.scrollTo(0, 0);
    setIsShippingAddressbtnClicked(true);
    setPrepopulateEditAddress();
    navigate({
      pathname: "/account/mgmt/shipping/add",
    });
  };
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (location.pathname === "/account/mgmt/shipping/add") {
      setIsShippingAddressbtnClicked(true);
      setPrepopulateEditAddress();
      window.scrollTo(0, 0);
    }
    if (
      location.pathname === "/account/mgmt/shipping/edit" &&
      selectShippingAddressData &&
      searchParams?.get("id")
    ) {
      setIsShippingAddressbtnClicked(true);
      const editResponse = selectShippingAddressData?.filter((item) => {
        return item?.id == searchParams?.get("id");
      });
      setPrepopulateEditAddress(editResponse);
      setEditID(searchParams?.get("id"));
      window.scrollTo(0, 0);
    }
  }, [location, selectShippingAddressData]);

  const handleUseThisAddress = (item) => {
    if (item?.country && item?.country?.label === "United States") {
      setDomesticCH("domestic");
    } else {
      setDomesticCH("inter");
    }
    setShippingPaymentAddressUpdated(item);
    setUseThisAddress(false);
    navigate({
      pathname: "/checkout",
    });
  };

  useEffect(() => {
    setshipPrice("");
  }, []);

  return (
    <>
      {mutation.isLoading && <Loader></Loader>}
      <IlmorModal
        isopen={cancelDialog}
        handleOkay={handleOkay}
        handleClose={handleCancel}
        message={commonMessages?.removeShippingAddressCard}
        messageDetail={"The user will be removed immediately."}
        secondMessage={"Any existing orders/lists will not be affected."}
        fisrtButton={"Cancel"}
        secondButton={"Delete"}
      ></IlmorModal>
      {isShippingAddressbtnClicked ? (
        <AddShippingAddress
          editID={editID}
          setIsShippingAddressbtnClicked={setIsShippingAddressbtnClicked}
          prepopulateEditAddress={prepopulateEditAddress}
        />
      ) : (
        // <div className={`ShippingAddressbackGround`}>
        //   <div className={`ShippingouterContainer`}>
        <>
          <Box
            sx={{
              background: "#eaeaea",
              width: "100%",
            }}
          >
            <StyledShippingHeading
              sx={{
                padding:
                  currentDevice == "Tablet"
                    ? "24px 28px"
                    : currentDevice == "Mobile"
                    ? "24px 20px"
                    : "30px 60px 0px 60px ",
              }}
              currentDevice={currentDevice}
            >
              {commonMessages?.shippingAddressHeading}
            </StyledShippingHeading>

            {currentDevice == "Tablet" && (
              <TabletViewBlackContainer currentDevice={currentDevice} />
            )}
            <ContainerDivStyled currentDevice={currentDevice}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid
                    container
                    spacing={1}
                    rowSpacing={3}
                    pt={currentDevice == "Tablet" ? 3 : 0}
                  >
                    <Grid
                      className="paddingfforshipadrestitle"
                      item
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <StyledShippingSubHeading>
                        {commonMessages?.shippingAddressSubHeading}
                      </StyledShippingSubHeading>
                      <StyledShippingSubHeading>
                        {commonMessages?.shippingAddressSecondSubHeading}
                      </StyledShippingSubHeading>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    className="werf"
                    rowSpacing={3}
                    columnSpacing={3}
                    sx={{ marginBottom: "20px", marginTop: "2px" }}
                  >
                    {selectShippingAddressData &&
                      selectShippingAddressData?.length > 0 &&
                      selectShippingAddressData?.map((item, index) => {
                        return (
                          <Grid
                            className="paddinadjusment"
                            item
                            lg={4}
                            md={6}
                            xs={12}
                            sm={6}
                            sx={{}}
                          >
                            <Card
                              className="heightShipAdress"
                              sx={{
                                // height: 221,
                                // padding: 4,
                                position: "relative",
                                wordBreak: "break-word",
                              }}
                            >
                              <Grid className="swew" container spacing={1}>
                                <div className="adbook">
                                  <Grid
                                    className="spcingShip"
                                    sx={{ marginTop: "4px" }}
                                    item
                                  >
                                    {item?.isBillingAddress ? (
                                      <AddressIcon className="icon"></AddressIcon>
                                    ) : (
                                      <AccountOverviewShippingAddress className="icon"></AccountOverviewShippingAddress>
                                    )}
                                  </Grid>
                                  <Grid item md={10}>
                                    <CardHeadingStyled>
                                      {item?.locationName &&
                                        truncBoy(item?.locationName, 16)}
                                    </CardHeadingStyled>

                                    <CardContentStyled>
                                      {item?.address1 &&
                                        truncBoy(item?.address1, 35)}
                                    </CardContentStyled>
                                    <CardContentStyled>
                                      {item?.address2 &&
                                        truncBoy(item?.address2, 35)}
                                    </CardContentStyled>
                                    {/* <CardContentStyled>
                                    {item?.city?.label}
                                  </CardContentStyled> */}
                                    <CardContentStyled>
                                      {`${
                                        item?.city?.label &&
                                        truncBoy(item?.city?.label, 15)
                                      }, ${item?.state?.label} ${
                                        item?.zipCode
                                      }`}
                                    </CardContentStyled>
                                    <CardContentStyled>
                                      {item?.country?.label}
                                    </CardContentStyled>
                                    {item?.isDefault && (
                                      <button
                                        style={{ marginTop: "8px" }}
                                        size="small"
                                        className={`ilmorRoundGreenButton`}
                                      >
                                        {" "}
                                        Default
                                      </button>
                                    )}
                                  </Grid>
                                </div>
                              </Grid>

                              <CardActions>
                                {/* <Button size="small"> {item?.icon}</Button> */}
                              </CardActions>
                              {myProfile?.profileInfo?.permissions?.includes(
                                "manage_shipping_locations"
                              ) && (
                                <Box
                                  sx={{
                                    position: "absolute",
                                    // bottom: "32px",
                                    left: "16px", //done for shoipping adress
                                  }}
                                  className="buttonGroup posDesktopShip"
                                >
                                  {!useThisAddress && (
                                    <>
                                      {!(
                                        item.isBillingAddress &&
                                        myProfile?.profileInfo?.role !== "Admin"
                                      ) && (
                                        <Button
                                          className={`ShippingAddressEditButton`}
                                          sx={{
                                            minWidth: "unset",

                                            textTransform: "none",
                                            color: "#CE0E2D",
                                            textAlign: "center",
                                            fontFamily: "Montserrat",
                                            fontSize: "13px",
                                            fontWeight: "500",
                                            textDecoration: "underline",
                                            padding: 0.5,
                                          }}
                                          onClick={() => {
                                            handleEditClick(item?.id);
                                          }}
                                        >
                                          Edit
                                        </Button>
                                      )}
                                      {!(
                                        item.isBillingAddress &&
                                        myProfile?.profileInfo?.role !== "Admin"
                                      ) && (
                                        <Button
                                          sx={{
                                            minWidth: "unset",
                                            padding: 0.5,
                                            textTransform: "none",
                                            color: "#CE0E2D",
                                            textAlign: "center",
                                            fontFamily: "Montserrat",
                                            fontSize: "13px",
                                            fontWeight: "500",
                                            textDecoration: "underline",
                                          }}
                                          onClick={() => {
                                            handleCancel(item?.id);
                                            // handleRemoveClick(row?.email);
                                          }}
                                        >
                                          Remove
                                        </Button>
                                      )}
                                      {!item?.isDefault && (
                                        <Button
                                          sx={{
                                            minWidth: "unset",
                                            textTransform: "none",
                                            color: "#CE0E2D",
                                            padding: 0.5,
                                            textAlign: "center",
                                            fontFamily: "Montserrat",
                                            fontSize: "13px",
                                            fontWeight: "500",
                                            textDecoration: "underline",
                                          }}
                                          onClick={() => {
                                            handleDefaultClick(item?.id);
                                          }}
                                        >
                                          {" "}
                                          Set As Default
                                        </Button>
                                      )}
                                    </>
                                  )}
                                  {useThisAddress && (
                                    <Button
                                      sx={{
                                        minWidth: "unset",
                                        padding: 0.5,
                                        textTransform: "none",
                                        color: "#CE0E2D",
                                        textAlign: "center",
                                        fontFamily: "Montserrat",
                                        fontSize: "13px",
                                        fontWeight: "500",
                                        textDecoration: "underline",
                                      }}
                                      onClick={() => {
                                        handleUseThisAddress(item);
                                      }}
                                    >
                                      Use This Address
                                    </Button>
                                  )}
                                </Box>
                              )}
                            </Card>
                          </Grid>
                        );
                      })}
                  </Grid>
                  {myProfile?.profileInfo?.permissions?.includes(
                    "manage_shipping_locations"
                  ) && (
                    <Box className="marginbott">
                      <AddEmployeeAccountBtnStyled
                        // disabled={!myProfile?.profileInfo?.permissions?.includes("manage_shipping_locations" ) }
                        sx={{
                          width:
                            currentDevice === "Mobile" &&
                            sideMenuAccount === "shipping"
                              ? "100%"
                              : "fit-content",
                        }}
                        onClick={() => AddShippingAddressClick()}
                      >
                        <Box component="span" sx={{ display: "inline" }}>
                          {" "}
                          <PlusIcon /> {commonMessages?.addShippingAddressBtn}
                        </Box>
                      </AddEmployeeAccountBtnStyled>
                      {/* <button
                    className={`ilmorButon`}
                    onClick={() => AddShippingAddressClick()}
                  >
                    Add Shipping Address
                  </button> */}
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Box>
                {currentDevice == "Mobile" && (
                  <TabletViewBlackContainer currentDevice={currentDevice} />
                )}
              </Box>
            </ContainerDivStyled>
          </Box>
        </>
        //   </div>
        // </div>
      )}
    </>
  );
};

export default ShippingAddressBook;
