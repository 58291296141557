import React, { useState, useEffect } from "react";
import "./AddToCartPopUp.css";
import { useStore } from "../store/home";
import { ReactComponent as CloseButton } from "../Images/closeButton.svg";
import { ShoppingListComponent } from "./ShoppingListComponent";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import commonMessages from "../commenComp/CommonMessages";
import { getMyCart } from "../api/posts";
import Loader from "../commenComp/Loader";
import { addCommaDecimal } from "../commonFunction";

export default function AddToCartPopUp({
  isModalOpen,
  shopList,
  setIsModalOpen,
}) {
  const { token, userInfo, myCartMini, myProfile } = useStore();
  // const [isModalOpen , setIsModalOpen] = useState(false);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const navigate = useNavigate();
  const handleViewCart = () => {
    navigate({
      pathname: "/cart",
    });
  };

  const [dataArray, setDataArray] = useState(shopList);
  const [sum, setSum] = useState(0);
  const mutation1 = useMutation((post) => getMyCart(post, token), {
    mutationKey: "getMyCart",
    onSuccess: (result) => {
      // setDataArray(result.data);
    },
    onError: (error) => {},
  });
  useEffect(() => {
    let sum = 0;
    if (dataArray && dataArray?.length > 0) {
      dataArray?.forEach((item) => {
        sum = sum + parseInt(item?.partQty) * parseFloat(item?.partDiscountCost);
      });
    }
    setSum(sum);
  }, [dataArray]);

  const handleRefreshCallBack = () => {
    let data = {
      email: userInfo?.username,
    };
    // mutation1.mutate(data);
  };

  const han1 = () => {
    setIsModalOpen(false);
  };
  const han2 = (e) => {
    e.stopPropagation()
  };
  return (
    isModalOpen && (
      <div onClick={han1} className={`addToCart_main`}>
        {mutation1.isLoading ? (
          <div>
            <Loader></Loader>
          </div>
        ) : (
          <div onClick={han2}  className={`addToCart_centerComponent`}>
            <div className={`addToCart_header`}>
              <div className={`addToCart_headerTitle`}>
                <span className={`addToCart_titleLabel`}>
                  <span className={`addToCart_numberOfProduct`}>
                    {myCartMini?.data?.minicart?.itemCount}
                  </span>
                  {`${dataArray?.length} `}
                  {`${
                    dataArray?.length === 1
                      ? "PRODUCT ADDED TO CART"
                      : "PRODUCTS ADDED TO CART"
                  } `}
                </span>
              </div>
              <div
                onClick={handleCloseModal}
                className={`addToCart_closeBtn center curPoint`}
              >
                <CloseButton
                  className={`addToCart_closeBtnIcon `}
                ></CloseButton>
              </div>
            </div>

            <div className={`addToCart_body`}>
              {/* <ShoppingListComponent addToCart = {true}/> */}
              <div className="addToCart_shoppingList">
                <ShoppingListComponent
                  // dataArray={dataArray?.data?.items}
                  dataArray={dataArray}
                  isCart={true}
                  noUpdateCart={true}
                  pop={true}
                  handleRefreshCallBack={handleRefreshCallBack}
                  reviewOrder={true}
                  addtoCart={true}
                  isMobile={true}
                  yourCart={true}
                  noRowColor={true}
                ></ShoppingListComponent>
              </div>
            </div>
            <div className={`addToCart_footer`}>
              {myProfile?.profileInfo?.permissions?.includes(
                "add_to_own_cart"
              ) ? (
                <div className={`addToCart_footerText`}>
                  <h4 className={`addToCart_estimateCartTotal`}>
                    {`Estimated Cart Total :  `}
                  </h4>
                  <span className={`addToCart_totalPrice`}>
                    {`  $${addCommaDecimal(sum)}`}
                  </span>
                </div>
              ) : (
                <div></div>
              )}
              <div className={`addToCart_footerBtns`}>
                <button className={`addToCart_continueShopBtn`}>
                  <span
                    onClick={handleCloseModal}
                    // onClick={() => {
                    //   navigate({
                    //     pathname: "/catalog",
                    //   });
                    // }}
                    className={`addToCart_continueShopLabel curPoint`}
                  >
                    Continue Shopping
                  </span>
                </button>
                <button
                  onClick={handleViewCart}
                  className={`addToCart_viewCartBtn curPoint`}
                >
                  <span className={`addToCart_viewCartLabel`}>View Cart</span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  );
}
