import React, { useState, useEffect } from "react";
import "./LoginPage.css";
import logo from "../Images/ILMOR_LOGO.png";
import logo1 from "../Images/chekcboxsvg.svg";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  dealerLoginAPI,
  loginToken,
  getFooterLinks,
  getAllDropdownOptions,
  getMyProfile,
  getPay,
  getMyCart,
} from "../api/posts";
import { deviceDetector, initialState } from "../commonFunction";
import { useStore } from "../store/home";
import Loader from "../commenComp/Loader";
import jwt from "jwt-decode";
import commonMessages from "../commenComp/CommonMessages";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import { Box, Checkbox } from "@mui/material";
import { showErrorToast } from "../utils/Toaster";

const LoginForm = () => {
  const [dealerLogin, setdealerLogin] = useState(false);
  const {
    currentDevice,
    setToken,
    myProfile,
    setUserInfo,
    userInfo,
    setCurrentUserEmail,
    token,
    setFooterApiLinkData,
    setSideMenuAccount,
    setDealerId,
    setcusProfile,
    setMyProfile,
    dealerId,
    setPass,
    setEmailStore,
    setMyCartMini,
    setFooterKeyZustand,
    setSelectedCategory,
    setFooterContentdataInZustand,
    setRefreshToken,
    lastPage,
    setLastPage,
    parID,
    setVals,
  } = useStore();

  const resetStore = () => {
    useStore.setState(initialState);
  };
  const handleTimout = () => {
    setTimeout(() => {
      // showErrorToast("User logged out due to token expiration");
      setLastPage(false);
      localStorage.removeItem("token");
      localStorage.removeItem("my-persisted-store");
      setSideMenuAccount("home");
      setSelectedCategory("home");
      resetStore();
      setFooterContentdataInZustand([]);
      navigate("/sign-in", { replace: true });
      setToken("");
    }, 28800000);
  };

  const dealer = useMutation((post) => dealerLoginAPI(post), {
    onSuccess: (result) => {
      setUserIsLoggedIn(result.data.data.token);
      localStorage.setItem("token", result.data.data.token);
      const decoded = jwt(result.data.data.token);
      setUserInfo(decoded);
      setToken(result.data.data.token);
      handleTimout();
      let redirect = false;
      setCurrentUserEmail(loginData.username);
      navigate({
        // pathname: redirect ? "/update-password" : "/home",
        pathname: redirect ? "/update-password" : "/",
      });
    },
    onError: (error) => {},
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const dealerIdParam = urlParams.get("dealerId");
    const decoded = atob(dealerIdParam);
    if (token) {
      setdealerLogin(token);
      const obj = {
        token,
        dealerId: dealerIdParam,
      };
      dealer.mutate(obj);
    }
    if (dealerIdParam) {
      setDealerId(decoded);
    } else {
      setDealerId("");
    }
  }, []);

  const getPayMethod = useMutation((post) => getPay(post, token), {
    onSuccess: (result) => {
      if (result?.data?.success && result?.data?.data?.CustomerProfileId) {
        setcusProfile(result?.data?.data?.CustomerProfileId);
      }
    },
    onError: (error) => {},
  });

  const mutation12 = useMutation((post) => getMyProfile(post, token), {
    onSuccess: (result) => {
      const data = {
        id: result?.data?.profileInfo?.id,
      };
      getPayMethod.mutate(data);
      setMyProfile(result?.data);
    },
    onError: (error) => {},
  });

  const mutation_cartMINI = useMutation((post) => getMyCart(post, token), {
    onSuccess: (result) => {
      setMyCartMini(result);
    },
    onError: (error) => {},
  });

  useEffect(() => {
    if (token?.length > 0) {
      const obj = {};
      obj.email = userInfo?.username;
      obj.dealerId = dealerId;
      mutation12.mutate(obj);
      const obj1 = {
        email: userInfo.username,
        minicart: true,
        dealerId: dealerId,
      };
      mutation_cartMINI.mutate(obj1);
    }
  }, [dealerId, userInfo, token]);

  // useEffect(() => {
  //   if (token) {
  //     navigate({
  //       pathname: `${currentLocation ? currentLocation : '/account'}`,
  //     });
  //   }
  // }, [token]);

  const schema = yup.object().shape({
    username: yup.string().email("The entered email is not valid"),
    password: yup
      .string()
      .min(4, "Password must be at least 4 characters")
      .max(20, "Password must be at maximum 20 characters"),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: localStorage.getItem("email") || "",
      password: localStorage.getItem("password") || "",
    },
  });
  // const {
  //   data: footerApiData,
  //   error,
  //   refetch,
  //   isSuccess,
  //   isFetching,
  //   isLoading,
  // } = useQuery({
  //   queryKey: ["getFooterLinks"],
  //   retry: 0,
  //   enabled: false,
  //   // refetchOnWindowFocus: false,
  //   queryFn: () => getFooterLinks(localStorage.getItem("token")),
  // });

  const mutationFooter = useMutation((post) => getFooterLinks(post), {
    mutationKey: "getFooterLinks",
    method: "GET",
    onSuccess: (result) => {
      setFooterApiLinkData(result.data.data);
    },
    onError: (error) => {},
  });
  // Resetting Logic for username

  useEffect(() => {
    if (localStorage.getItem("email") !== undefined) {
      // const storedEmail = localStorage.getItem("email")
      setEmail(localStorage.getItem("email"));
      reset({ username: localStorage.getItem("email") });
    }
  }, []);

  // Resetting logic for password

  useEffect(() => {
    if (localStorage.getItem("password") !== undefined) {
      // const storedPassword = localStorage.getItem("password")
      setPassword(localStorage.getItem("password"));
      reset({ password: localStorage.getItem("password") });
    }
  }, []);

  const mutation1 = useMutation((post) => getAllDropdownOptions(post), {
    mutationKey: "getDropDownValues",
    method: "GET",
    onSuccess: (result) => {
      if (result.data.success && !sessionStorage.getItem("timeout")) {
        navigate({
          pathname: "/",
        });
      }
    },
    onError: (error) => {},
  });

  useEffect(() => {
    if (localStorage.getItem("token")) {
      const params = {
        token: token,
        year: true,
        make: "",
      };
      mutation1.mutate(params);
    }
  }, []);

  const [email, setEmail] = useState("");
  const [invalidCredMessage, setInvalidCredMessage] = useState(false);
  const [inactiveMessage, setInactiveMessage] = useState(false);

  const [loginData, setLoginData] = useState("");
  const [userIsLoggedIn, setUserIsLoggedIn] = useState("");

  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const navigate = useNavigate();
  const mutation = useMutation((post) => loginToken(post), {
    onSuccess: (result) => {
      const decoded = jwt(result.data.token);
      setUserInfo(decoded);
      if (decoded.isAcive) {
        handleTimout();
        setSideMenuAccount("home");
        setVals(false)
        setUserIsLoggedIn(result.data.token);
        mutationFooter.mutate(result.data.token);
        localStorage.setItem("token", result.data.token);
        setRefreshToken(result.data.refresh_token);
        setToken(result.data.token);
        let redirect = myProfile?.profileInfo?.firstLogin;
        setCurrentUserEmail(loginData.username);
        setTimeout(() => {
          navigate({
            // pathname: redirect ? "/update-password" : "/home",
            pathname: redirect ? "/update-password" : lastPage ? lastPage : "/",
            search: `number=${parID}`,
          });
        }, 10);
        if (rememberMe && loginData.username !== "") {
          localStorage.setItem("email", loginData.username);
        }
        if (rememberMe && loginData.password !== "") {
          localStorage.setItem("password", loginData.password);
        }
      } else {
        setInactiveMessage(true);
      }
    },
    onError: (error) => {
      // reset({ username: '' })
      setInvalidCredMessage(true);
    },
  });

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const onSubmit = (data, event) => {
    event.preventDefault();
    setEmail(data.username);
    setPassword(data.password);
    setEmailStore(data.username)
    setPass(data.password);
    const obj = {
      username: data.username,
      password: data.password,
    };
    setLoginData(obj);
    if (data.username) {
      mutation.mutate(obj);
    }
  };
  const handleFooterClick = (key) => {
    setFooterKeyZustand(key);

    navigate({
      pathname: "/content/contact",
    });
  };

  const onSubmit2 = (data, event) => {
    event.preventDefault();
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };

  const handleSignIn = () => {
    const obj = {
      username: email,
      password: password,
    };
    setLoginData(obj);
  };
  const [userNotValid, isUserNotValid] = useState(false);
  const [passNotValid, isPassNotValid] = useState(false);
  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setInvalidCredMessage(false);
      isUserNotValid(false);
      isPassNotValid(false);
    } else {
      if (Object.keys(errors).includes("username")) {
        isUserNotValid(true);
      } else {
        isUserNotValid(false);
      }
      if (Object.keys(errors).includes("password")) {
        isPassNotValid(true);
      } else {
        isPassNotValid(false);
      }
      setInvalidCredMessage(true);
    }
  }, [errors]);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <>
      {(mutation.isLoading ||
        mutation1.isLoading ||
        mutationFooter.isLoading ||
        dealer.isLoading) && <Loader></Loader>}
      <div className={`loginPage__main${currentDevice}`}>
        <div className={`loginContainer${currentDevice}`}>
          <div className={`logo${currentDevice}`}>
            <img
              className={`title-image${currentDevice}`}
              src={logo}
              alt="company-logo"
            />
          </div>
          <div className={`info__main${currentDevice}`}>
            <div className={`headingBox${currentDevice}`}>
              <h2 className={`heading${currentDevice}`}>
                {commonMessages?.loginFormHeading}
              </h2>
              {invalidCredMessage && (
                <div className={`invalidCredMessage${currentDevice}`}>
                  {commonMessages?.loginFormSubHeading}
                </div>
              )}
              {inactiveMessage && !invalidCredMessage &&(
                <div className={`invalidCredMessage${currentDevice}`}>
                  {commonMessages?.loginFormSubHeadingInactive}
                </div>
              )}
              {/* <div className={`usernameWarningContainer${currentDevice}`}>
                <p className={`warning${currentDevice}`}>
                  {errors?.username?.message}
                </p>
              </div> */}
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={`form${currentDevice}`}
            >
              <div className={`emailLabel${currentDevice}`}>
                <h5 className={`label${currentDevice}`}>EMAIL</h5>
              </div>
              <input
                // style={{ border: "none" }}
                className={`usernameInput${currentDevice}${
                  userNotValid || invalidCredMessage
                    ? " usernameInputwarning"
                    : ""
                }`}
                type="text"
                // value={email}
                name="username"
                required
                onChange={handleEmailChange}
                {...register("username")}
              ></input>
              <div className={`passwordLabel${currentDevice}`}>
                <h5 className={`label${currentDevice}`}>PASSWORD</h5>
              </div>
              <input
                // style={{ border: "none" }}
                className={`usernameInput${currentDevice}${
                  passNotValid || invalidCredMessage
                    ? " usernameInputwarning"
                    : ""
                }`}
                type="password"
                required
                // value={password}
                // name="password"
                // onChange={handlePasswordChange}
                {...register("password")}
              ></input>

              <div className={` checkBox${currentDevice}`}>
                {/* <input
                  className={`inputCheckboxGlobal`}
                  type="checkbox"
                  onChange={handleRememberMeChange}
                />{" "} */}
                <Checkbox
                  {...label}
                  className="opp"
                  sx={{
                    "&.Mui-checked .MuiIconButton-label": {
                      backgroundColor: "black",
                    },
                    "&.MuiCheckbox-root": {
                      border: "none", // Remove border
                    },
                    "&.Mui-checked": {
                      outline: "none", // Remove outline when checked
                    },
                    "&.Mui-focusVisible .MuiSvgIcon-root": {
                      outline: "none",
                    },
                    "&:focus": {
                      outline: "none",
                    },
                  }}
                  name="Remember me"
                  onChange={handleRememberMeChange}
                  // color="default"
                />
                <span class="checkmark"></span>
                <label className={`rememberMe${currentDevice}`}>
                  Remember me
                </label>
              </div>

              <div className={`btnFrgtPw${currentDevice}`}>
                <button
                  className={`ilmorButon signInBtn${currentDevice}`}
                  onClick={handleSignIn}
                  // disabled={!email || !password}
                >
                  {" "}
                  Sign In
                </button>
                <div className={`forgotPasswordLabel${currentDevice}`}>
                  <Link
                    className={`forgetPw${currentDevice}`}
                    to="/forgot-password"
                  >
                    <h4>Forgot Password</h4>
                  </Link>
                </div>
              </div>
            </form>
            <hr className={`lineBreak__main${currentDevice}`}></hr>
            <div className={`footerInfo_${currentDevice}`}>
              <p className={`info${currentDevice}    paraTabletSignInlast`}>
                <Box
                  sx={{
                    display: "inline",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    handleFooterClick("CONTACT");
                  }}
                >
                  {commonMessages?.loginFormContactOne}
                </Box>{" "}
                if you would like to become a certified parts dealer and access
                this site, or{" "}
                <Box
                  sx={{
                    display: "inline",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    window.open("https://parts.ilmor.com/", "_blank");
                  }}
                >
                  visit our retail parts store
                </Box>{" "}
                {commonMessages?.loginFormContactFour}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
