import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import "./IlmorModal.css";
import Modal from "@mui/material/Modal";
import Select from "react-select";
import Fade from "@mui/material/Fade";
import InputBase from "@mui/material/InputBase";
import Typography from "@mui/material/Typography";
import { ReactComponent as Alert } from "../Images/alertIlmor.svg";
import { Grid } from "@mui/material";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import { useStore } from "../store/home";
import { customStyles } from "../utils/ReactSelectCustomStyles";
import { useQuery } from "@tanstack/react-query";
import { getReturnReasonListing } from "../api/posts";
import { customStyles1 } from "../utils/ReactSelectCustomStylesMinWid";

const IlmorModal = ({
  message,
  fisrtButton,
  handleClose,
  secondButton,
  handleOkay,
  isopen,
  input,
  placeholder,
  messageDetail,
  secondMessage,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    borderRadius: "8px",
    background: "#FFF",
    boxShadow: 24,
    boxShadow:
      "0px 1px 2px 0px rgba(0, 0, 0, 0.12), 0px 1px 20px 0px rgba(0, 0, 0, 0.35), 0px 1px 16px 0px rgba(0, 0, 0, 0.16)",
    p: 4,
  };
  const {
    myProfile,
    setMyProfile,
    token,
    returnReasonZustand,
    setReturnReasonZustand,
    setReturnOptionZustand,
    returnOptionZustand,
  } = useStore();
  const [countrySelectOption, setCountrySelectOption] = useState();
  useEffect(() => {
    if (myProfile?.countryList) {
      setCountrySelectOption(myProfile?.countryList);
    }
  }, []);

  const handleOptionChange = (option) => {
    setReturnOptionZustand(option);
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isopen}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isopen}>
          <Box sx={style}>
            <div className="modalMain">
              <div className="maodalTitle">
                <Alert className="icon"></Alert>
                <div className="messageModal">{message}</div>
              </div>
              <Grid
                container
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "4px",
                }}
              >
                {messageDetail && (
                  <Grid
                    item
                    sx={{
                      fontSize: "12px",
                      fontWeight: "450",
                      textAlign: "center !important",
                      alignItems: "center",
                    }}
                  >
                    {messageDetail}
                  </Grid>
                )}
                {secondMessage && (
                  <Grid
                    item
                    md={12}
                    sx={{
                      fontSize: "12px",
                      fontWeight: "450",
                      textAlign: "center !important",
                      alignItems: "center",
                    }}
                  >
                    {secondMessage}
                  </Grid>
                )}
                {input && (
                  <Grid
                    item
                    md={12}
                    sx={{
                      fontSize: "12px",
                      fontWeight: "450",
                      textAlign: "center !important",
                      alignItems: "center",
                      border: "1px solid rgba(97, 94, 92, 0.15)",
                      background: "#FFF",
                      marginTop: "20px",
                    }}
                  >
                    <Select
                      value={returnOptionZustand}
                      onChange={handleOptionChange}
                      options={returnReasonZustand}
                      // styles={customStyles}
                      styles={customStyles1}
                      // menuIsOpen={true}
                      // defaultValue={userRole.find((c) => c.value === countryValue)}
                    />
                    {/* <InputBase
                      style={{
                        backgroundColor: "white",
                        border: "none",
                        outline: "none",
                        padding: "5px",
                        fontStyle: "italic",
                        display: "flex",
                      }}
                      placeholder={placeholder}
                      onChange={(e) => {
                        setReason(e.target.value);
                      }}
                      value={reason}
                      inputProps={{ "aria-label": "search google maps" }}
                    /> */}
                  </Grid>
                )}
              </Grid>

              <div className="modalBut">
                {fisrtButton && (
                  <button onClick={handleClose} className="ilmorButonWhite">
                    {fisrtButton}
                  </button>
                )}
                {input ? (
                  <button
                    onClick={handleOkay}
                    // disabled={!reason}
                    className="ilmorButon"
                  >
                    {secondButton}
                  </button>
                ) : (
                  <button onClick={handleOkay} className="ilmorButon">
                    {secondButton}
                  </button>
                )}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default IlmorModal;
