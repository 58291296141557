import React from "react";
import Header from "./Header";
import SideMenu from "../commenComp/SideMenu";
import MyProfile from "./MyProfile";
import Footer from "./Footer";
import "./MyAccount.css";
import { useStore } from "../store/home";
import { getMyProfile } from "../api/posts";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import Loader from "../commenComp/Loader";
import ShippingAddressBook from "./ShippingAddressBook";
import EmployeeAccounts from "./EmployeeAccounts";
import OrderStatusHistory from "./OrderStatusHistory";
import OrderDetail from "./OrderDetail";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import AddEmployeeAccount from "./AddEmployeeAccount";
import AddTaxExemptForm from "./AddTaxExemptForm";
import AccountHome from "./AccountHome/AccountHome";
import BusinessDetails from "./BusinessDetails/BusinessDetails";
import PaymentMethods from "./PaymentMethods/PaymentMethods";
import AddPaymentMethod from "./PaymentMethods/AddPaymentMethod";
import { useEffect } from "react";
import SignInInfo from "./SignInInfo";
import EditPaymentMethod from "./PaymentMethods/EditPaymentMethod";
import TaxExemptionPopUp from "./TaxExemptionPopUp";
import { useState } from "react";
import { Modal } from "@mui/material";

export default function MyAccount() {
  const {
    sideMenuAccount,
    myProfile,
    setMyProfile,
    dealerId,
    userInfo,
    token,
    setKeyWord,
    setKeyWordFinal,
    orderHistoryState,
    setQuickOrderData,
  } = useStore();
  useEffect(() => {
    setKeyWordFinal("");
    setKeyWord("");
  }, []);

  const location = useLocation();

  const { setstart1, start1, setBox, box, boxPage } = useNonPersistedStore();
  const mutation = useMutation((post) => getMyProfile(post, token), {
    onSuccess: (result) => {
      setMyProfile(result?.data);
    },
    onError: (error) => {},
  });
  useEffect(() => {
    const obj = {};
    obj.email = userInfo?.username;
    setstart1(0);
    setBox(1);
    obj.dealerId = dealerId;
    mutation.mutate(obj);
    setQuickOrderData([
      {
        partName: "",
        quantity: 1,
        status: "",
        originalPrice: "",
        dealerPrice: "",
        message: "",
      },
      {
        partName: "",
        quantity: 1,
        status: "",
        originalPrice: "",
        dealerPrice: "",
        message: "",
      },
      {
        partName: "",
        quantity: 1,
        status: "",
        originalPrice: "",
        dealerPrice: "",
        message: "",
      },
      {
        partName: "",
        quantity: 1,
        status: "",
        originalPrice: "",
        dealerPrice: "",
        message: "",
      },
      {
        partName: "",
        quantity: 1,
        status: "",
        originalPrice: "",
        dealerPrice: "",
        message: "",
      },
    ]);
  }, []);
  const myAccountCategory = [
    {
      name: "Account Home",
      id: "home",
    },
    {
      name: "Order Status & History",
      id: "orderDefault",
    },
    {
      name: "Account Management",
      id: "employee",
      subcategories: [
        {
          name: "Employee Accounts",
          id: "employee",
        },
        {
          name: "Business Details",
          id: "businessDetails",
        },
        {
          name: "Shipping Addresses",
          id: "shipping",
        },
        {
          name: "Payment Methods",
          id: "paymentMethods",
          disabled: !(
            myProfile?.profileInfo?.role !== "ServiceTechnician"
          ),
        },
        {
          name: "Tax Exemption",
          id: "tax",
          disabled: !(
            !dealerId &&
            myProfile?.profileInfo?.role !== "ServiceTechnician" &&
            myProfile?.profileInfo?.USDealer &&
            myProfile?.profileInfo?.permissions?.includes(
              "manage_tax_exempt_form"
            )
          ),
        },
      ],
    },
    {
      name: "My Profile",
      id: "myProfile",
      // subcategories:[
      //   {
      //     name: "Sign In Information",
      //     id: 'signInInfo',
      //   },
      // ]
    },
  ];

  // const [isModalOpen, setIsModalOpen] = useState();
  // const navigate = useNavigate();
  // useEffect(() => {
  //   if (myProfile?.profileInfo?.firstLogin) {
  //     navigate({
  //       pathname: "/update-password",
  //     });
  //   }
  //   if (
  //     !dealerId &&
  //     myProfile?.profileInfo?.USDealer &&
  //     !myProfile?.profileInfo?.skip
  //   ) {
  //     setIsModalOpen(true);
  //   }
  // }, [myProfile]);

  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  // };
  return (
    <>
      {/* {isModalOpen && (
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          aria-labelledby="taxExemption-modal"
          aria-describedby="taxExemption-modal-description"
        >
          {
            <div className="taxExemption-modal">
              <TaxExemptionPopUp
                handleCloseModal={handleCloseModal}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
              />
            </div>
          }
        </Modal>
      )} */}
      <div>
        <Header page="MyAccount" />
      </div>
      <div className="myAccountBody">
        <SideMenu data={myAccountCategory} signOutShow={true} />
        {location.pathname === "/account" && <AccountHome />}
        {location.pathname === "/account/orders" && <OrderStatusHistory />}
        {location.pathname === "/account/order-detail" &&
          !(
            sideMenuAccount === "orderModify" ||
            sideMenuAccount === "orderCencel" ||
            sideMenuAccount === "returnOrder"
          ) && <OrderDetail />}
        {location.pathname === "/account/order-detail" &&
          sideMenuAccount === "orderModify" && <OrderDetail modify={true} />}
        {location.pathname === "/account/order-detail" &&
          sideMenuAccount === "orderCencel" && <OrderDetail cencel={true} />}
        {location.pathname === "/account/order-detail" &&
          sideMenuAccount === "returnOrder" && (
            <OrderDetail returnOrder={true} />
          )}
        {/* {sideMenuAccount === "orderModify" && <OrderDetail modify={true} />} */}
        {/* {sideMenuAccount === "orderCencel" && <OrderDetail cencel={true} />} */}
        {/* {sideMenuAccount === "returnOrder" && (
          <OrderDetail returnOrder={true} />
        )} */}
        {location.pathname === "/account/mgmt/shipping" && (
          <>
            <ShippingAddressBook />
          </>
        )}
        {location.pathname === "/account/mgmt/shipping/add" && (
          <>
            <ShippingAddressBook />
          </>
        )}
        {location.pathname === "/account/mgmt/shipping/edit" && (
          <>
            <ShippingAddressBook />
          </>
        )}
        {location.pathname === "/account/mgmt/accounts" && <EmployeeAccounts />}
        {location.pathname === "/account/mgmt/accounts/add" && (
          <EmployeeAccounts />
        )}
        {location.pathname === "/account/mgmt/accounts/edit" && (
          <EmployeeAccounts />
        )}
        {/* {location.pathname==="/account/mgmt/accounts"  && <EmployeeAccounts />} */}
        {location.pathname === "/account/profile" && <MyProfile />}
        {location.pathname === "/account/mgmt/signinfo" && <SignInInfo />}
        {location.pathname === "/account/mgmt/business" && <BusinessDetails />}
        {/* {location.pathname === "/account/mgmt/payment" &&
          !(
            sideMenuAccount === "addPaymentMethods" ||
            sideMenuAccount === "editPaymentMethods"
          ) && <PaymentMethods />}
        {location.pathname === "/account/mgmt/payment" &&
          sideMenuAccount === "addPaymentMethods" && <AddPaymentMethod />}
        {location.pathname === "/account/mgmt/payment" &&
          sideMenuAccount === "editPaymentMethods" && <EditPaymentMethod />} */}
        {location.pathname === "/account/mgmt/payment" && <PaymentMethods />}
        {location.pathname === "/account/mgmt/payment/add" && <AddPaymentMethod />}
        {location.pathname === "/account/mgmt/payment/edit" && <PaymentMethods />}
        {location.pathname === "/account/mgmt/tax" && <AddTaxExemptForm />}
        {/* title = "ACCOUNTS" */}
        {/* <MyProfile /> 
        <EmployeeAccounts/> */}
      </div>
      <div className="myAccountFooter">
        <Footer />
      </div>
    </>
  );
}
