import React, { useState, useEffect } from "react";
import { useStore } from "../store/home";
import "./MyProfile.css";
import Select from "react-select";
// import InputBase from "@mui/material/InputBase";
// import Input from '@material-ui/core/Input';
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import classnames from "classnames";
import { Box, Grid } from "@mui/material";
import { getMyProfile, updateMyProfile } from "../api/posts";
import { showErrorToast, showSuccessToast } from "../utils/Toaster";
import { useMutation } from "@tanstack/react-query";
import {
  StyledShippingHeading,
  CardContentStyled,
  StyledShippingSubHeading,
  StyledRequiredField,
} from "../components/StyledComponents/ShippingAddressStyled";
import {
  InputBoxFirstLastName,
  InputBoxLabel,
  UserRoleMyProfile,
} from "../components/StyledComponents/MyProfileStyled";
import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import { ErrorMessageStyled } from "./StyledComponents/CommonStyled";
import TabletViewBlackContainer from "./TabletViewBlackContainer";
import Loader from "../commenComp/Loader";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import commonMessages from "../commenComp/CommonMessages";
import { ContainerDivStyled } from "./StyledComponents/BusinessDetailsStyled";
import { initialState } from "../commonFunction";

export default function MyProfile() {
  const [completePhoneNumber, setCompletePhoneNumber] = useState("");
  const [nationalNumber, setNationalNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [countryTextCode, setTextCountryCode] = useState("");
  const [completeTextPhoneNumber, setCompleteTextPhoneNumber] = useState("");
  const [textNationalNumber, setTextNationalNumber] = useState("");

  const {
    currentDevice,
    dealerId,
    setFooterContentdataInZustand,
    setSelectedCategory,
    myProfile,
    setMyProfile,
    userInfo,
    token,
    setSideMenuAccount,
    setLastPage,
    setToken,
  } = useStore();
  const {
    setEditPasswordBtnClick,
    editEmailBtnClick,
    editPasswordBtnClick,
    setEditEmailBtnClick,
  } = useNonPersistedStore();

  const navigate = useNavigate();

  const [myProfileForm, setMyProfileForm] = useState(myProfile?.profileInfo);
  const schema = yup.object().shape({
    firstname: yup.string().required("Name  is required"),
    lastname: yup.string().required("Name  is required"),
    // primaryPhone: yup.number(),
  });

  const {
    register,
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // primaryPhone: myProfileForm?.primaryPhone,
      primaryPhone: myProfile?.profileInfo?.primaryPhone,
      // textNotificationPhone: myProfileForm?.textNotificationPhone,
      // sendTextNotification: myProfileForm?.reciveTextNotification,
    },
  });
  const resetStore = () => {
    useStore.setState(initialState);
  };
  const handleSignOut = () => {
    localStorage.removeItem("token");
    setLastPage(false);
    localStorage.removeItem("my-persisted-store");
    setSideMenuAccount("home");
    setSelectedCategory("home");
    setFooterContentdataInZustand([]);
    resetStore();
    setToken("");
    navigate("/sign-in", { replace: true });
  };

  const onSubmit = (data, event) => {
    // if (!nationalNumber || nationalNumber?.length === 1) {
    //   showErrorToast("Enter a valid phone number");
    // }

    event.preventDefault();
    mutation.mutate({
      ...data,
      // countryCode: countryCode,
      primaryPhone: nationalNumber,
      completePhoneNumber: completePhoneNumber,
      // countryTextCode: countryTextCode,
      // completeTextPhoneNumber: completeTextPhoneNumber,
      // textNationalNumber: textNationalNumber,

      profileType: "other",
      id: myProfileForm?.id,
    });
  };

  useEffect(() => {
    if (myProfile?.profileInfo?.primaryPhone) {
      setCompletePhoneNumber(myProfile?.profileInfo?.primaryPhone);
    }
  }, []);

  const updateClicked = () => {};

  const getMyProfileMutation = useMutation(
    (post) => getMyProfile(post, token),
    {
      onSuccess: (result) => {
        setMyProfile(result.data);
      },
      onError: (error) => {},
    }
  );
  useEffect(() => {
    const obj = {};
    obj.email = userInfo?.username;
    obj.dealerId = dealerId;
    getMyProfileMutation.mutate(obj);
  }, []);

  const mutation = useMutation((post) => updateMyProfile(post, token), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
          showSuccessToast(result?.data?.msg);
          const obj = {};
          obj.email = userInfo?.username;
          obj.dealerId = dealerId;
          getMyProfileMutation.mutate(obj);
        } else {
          showErrorToast(result?.data?.msg);
        }
      }
    },
    onError: (error) => {},
  });

  // useEffect(() => {
  //   mutation.mutate(myProfile?.profileInfo?.id);
  // }, []);
  const handlePhoneNumberChange = (data, field) => {
    if (!data) {
      setNationalNumber("");
      setCountryCode("");
      setCompletePhoneNumber("");

      return;
    }

    try {
      const parsedNumber = parsePhoneNumber(data?.toString());

      setNationalNumber(parsedNumber?.nationalNumber);
      setCountryCode(parsedNumber?.countryCallingCode);
      setCompletePhoneNumber(parsedNumber?.number);
    } catch (error) {}
  };

  const handleTextPhoneNumberChange = (data, field) => {
    if (!data) {
      setCompleteTextPhoneNumber("");
      setTextNationalNumber("");
      setTextCountryCode("");
      return;
    }
    try {
      const parsedNumber = parsePhoneNumber(data?.toString());

      setTextCountryCode(parsedNumber?.countryCallingCode);

      setCompleteTextPhoneNumber(parsedNumber?.number);
      setTextNationalNumber(parsedNumber?.nationalNumber);
    } catch (error) {}
  };

  const initialPrimaryPhone = "";
  const initialTextNotifications = ""; // Set your initial value for textNotifications

  const mutation1 = useMutation((post) => getMyProfile(post, token), {
    onSuccess: (result) => {
      setMyProfile(result?.data);
    },
    onError: (error) => {},
  });
  const handleCancel = () => {
    const obj = {};
    obj.email = userInfo?.username;
    obj.dealerId = dealerId;
    mutation1.mutate(obj);
    window.location.reload();
    reset({
      primaryPhone: myProfile?.profileInfo?.primaryPhone,
      firstname: myProfileForm?.firstname,
      lastname: myProfileForm?.lastname,
    });
  };

  const editEmail = () => {
    setSideMenuAccount("signInInfo");
    navigate({
      pathname: "/account/mgmt/signinfo",
    });
    setEditEmailBtnClick(true);
    setEditPasswordBtnClick(false);
  };

  const editPassword = () => {
    setSideMenuAccount("signInInfo");
    navigate({
      pathname: "/account/mgmt/signinfo",
    });
    setEditPasswordBtnClick(true);
    setEditEmailBtnClick(false);
  };

  return (
    <>
      {(mutation.isLoading || mutation1.isLoading) && <Loader></Loader>}
      <Box sx={{ width: "100%", background: "#eaeaea" }}>
        <StyledShippingHeading
          className="tyhmkl56"
          sx={{
            padding:
              currentDevice == "Tablet"
                ? "24px 28px"
                : currentDevice == "Mobile"
                ? "24px 20px"
                : "60px 60px 0px 60px ",
          }}
          currentDevice={currentDevice}
        >
          My Profile
        </StyledShippingHeading>{" "}
        {/* {currentDevice == "Tablet" && (
          <div className="myProfile_blackContainer">
            <button className="myProfile_signOutBtn" onClick={handleSignOut}>
              {" "}
              <span className="myProfile_signOutBtnLabel">Sign Out</span>
            </button>
          </div>
        )} */}
        {currentDevice == "Tablet" && (
          <TabletViewBlackContainer currentDevice={currentDevice} />
        )}
        <ContainerDivStyled currentDevice={currentDevice}>
          <Grid container>
            <Grid item xs={12} sm={12} md={9}>
              <Grid
                container
                sx={{ display: "flex", flexDirection: "column" }}
                className="myProfSubHeading"
              >
                <Grid item>
                  <StyledShippingSubHeading
                    sx={{
                      marginTop: currentDevice == "Tablet" ? "30px" : "0px",
                    }}
                  >
                    {commonMessages?.myProfileSubHeadingOne}
                  </StyledShippingSubHeading>
                </Grid>
                <br></br>
                <Grid item>
                  <StyledShippingSubHeading className="labelrequired1">
                    {commonMessages?.addEmployeeAccountRequiredField}
                  </StyledShippingSubHeading>
                </Grid>
              </Grid>
              {myProfile?.profileInfo?.role === "Admin" && (
                <>
                  <hr className="myProfileLineBreak"></hr>

                  <UserRoleMyProfile>USER ROLE</UserRoleMyProfile>

                  <StyledShippingSubHeading
                    sx={{ marginTop: "16px", fontSize: "15px" }}
                  >
                    {commonMessages?.myProfileSubHeadingTwo}
                  </StyledShippingSubHeading>
                </>
              )}
                            {myProfile?.profileInfo?.role === "PurchasingManager" && (
                <>
                  <hr className="myProfileLineBreak"></hr>

                  <UserRoleMyProfile>USER ROLE</UserRoleMyProfile>

                  <StyledShippingSubHeading
                    sx={{ marginTop: "16px", fontSize: "15px" }}
                  >
                      {`You are a Purchasing Manager of your company`}
                  </StyledShippingSubHeading>
                </>
              )}
                            {myProfile?.profileInfo?.role === "ServiceTechnician" && (
                <>
                  <hr className="myProfileLineBreak"></hr>

                  <UserRoleMyProfile>USER ROLE</UserRoleMyProfile>

                  <StyledShippingSubHeading
                    sx={{ marginTop: "16px", fontSize: "15px" }}
                  >
                    {`You are a Service Technician of your company`}
                  </StyledShippingSubHeading>
                </>
              )}

              <hr className="myProfileLineBreak"></hr>

              <UserRoleMyProfile>
                {commonMessages?.myProfileSubHeadingThree}
              </UserRoleMyProfile>

              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={0} sx={{ padding: "16px 0px" }}>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    lg={6}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <InputBoxLabel
                      className="labelrequired"
                      sx={{ marginTop: "10px" }}
                    >
                      {`First Name `}
                    </InputBoxLabel>
                    <input
                      style={{
                        display: "flex",
                        height: "42px",
                        padding: "16px",
                        alignItems: "center",
                        gap: "12px",
                        alignSelf: "stretch",
                        border: "1px solid rgba(97, 94, 92, 0.15)",
                        background: "var(--white, #FFF)",
                      }}
                      name="firstname"
                      type="text"
                      defaultValue={myProfileForm?.firstname}
                      // onChange={(e)=> e.target.value}
                      className={`myProfile_firstNameInput`}
                      {...register("firstname")}
                    ></input>
                    {errors?.firstname && (
                      <ErrorMessageStyled>
                        {errors.firstname?.message}
                      </ErrorMessageStyled>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    lg={6}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <InputBoxLabel
                      className="labelrequired"
                      sx={{ marginTop: "10px" }}
                    >
                      {`Last Name `}
                    </InputBoxLabel>
                    <input
                      style={{
                        display: "flex",
                        height: "42px",
                        padding: "16px",
                        alignItems: "center",
                        gap: "12px",
                        alignSelf: "stretch",
                        border: "1px solid rgba(97, 94, 92, 0.15)",
                        background: "var(--white, #FFF)",
                      }}
                      name="lastname"
                      type="text"
                      defaultValue={myProfileForm?.lastname}
                      className={`myProfile_lastNameInput`}
                      {...register("lastname")}
                    ></input>
                    {errors?.lastname && (
                      <ErrorMessageStyled>
                        {errors.lastname?.message}
                      </ErrorMessageStyled>
                    )}
                  </Grid>
                </Grid>
                <Grid className="dfglkjbhedrfjh" container sx={{ padding: "16px 0px" }}>
                  <Grid item xs={12} sm={12} lg={12}>
                    <InputBoxLabel
                      sx={{ marginTop: "10px", marginBottom: "4px" }}
                    >
                      Primary Phone
                    </InputBoxLabel>

                    <Controller
                      // rules={{ required: true }}
                      name="primaryPhone"
                      control={control}
                      render={({ onChange, value, onBlur, ref, field }) => {
                        return (
                          <PhoneInput
                            {...field}
                            // defaultValue={myProfileForm?.permissions?.primaryPhone}
                            onChange={handlePhoneNumberChange}
                            // international
                            countryCallingCodeEditable={false}
                            defaultCountry="US"
                            className="ReactPhoneNumInput"
                            minLength="7"
                            limitMaxLength={true}
                            style={{
                              backgroundColor: "white",
                              border: "none",
                              padding: "0px 10px",
                            }}
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
                {/* <Grid container className={`myProfile_sendNotiFcn`}>
                <Grid item>
                  <input
                    className={`myProfile_sendNotiFcncheckBox`}
                    type="checkbox"
                    name="sendTextNotification"
                    {...register("sendTextNotification")}
                  />
                </Grid>
                <span class="checkmark"></span>
                <Grid item>
                  <div className={`myProfile_sendNotiFcnLabel`}>
                    Send text notifications to this address
                  </div>
                </Grid>
              </Grid> */}

                {/* <Grid container>
                <Grid item xs={12} sm={12} lg={12}>
                  <InputBoxLabel sx={{ marginTop: "10px" }}>
                    Text Notifications
                  </InputBoxLabel>
                  <Controller
                    // rules={{ required: true }}
                    name="textNotificationPhone"
                    control={control}
                    render={({ onChange, value, onBlur, ref, field }) => {
                      return (
                        <PhoneInput
                          {...field}
                          // defaultValue={myProfileForm?.permissions?.primaryPhone}
                          onChange={handleTextPhoneNumberChange}
                          // international
                          countryCallingCodeEditable={false}
                          defaultCountry="US"
                          className="ReactPhoneNumInput"
                          minLength="7"
                          limitMaxLength={true}
                          style={{
                            backgroundColor: "white",
                            border: "none",
                            padding: "0px 10px",
                          }}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid> */}
                {!dealerId && <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={12}
                  className={`myProfile_btnBox`}
                >
                  <button
                    style={{ marginRight: "18px" }}
                    type="button"
                    disabled={dealerId}
                    className={`cancelButonAddShipping`}
                    onClick={handleCancel}
                  >
                    <span className={`myProfile_cancelLabel`} type="button">
                      Cancel
                    </span>
                  </button>
                  <button disabled={dealerId} className={`ilmorButon`}>
                    <span className={`myProfile_updateLabel`}>Update</span>
                  </button>
                </Grid>}
              </form>

              <hr className="myProfileLineBreak"></hr>
              <UserRoleMyProfile>
                {commonMessages?.myProfileSubHeadingFour}
              </UserRoleMyProfile>
              <Grid container>
                <Grid item xs={12} sm={12} lg={12}>
                  <InputBoxLabel
                    sx={{ marginTop: "10px", marginBottom: "4px" }}
                  >
                    EMAIL
                  </InputBoxLabel>
                  <div className={`myProfile_inputWithIcon`}>
                    <MailOutlineIcon className={`myProfile_inputIcon`} />
                    <input
                      className={`myProfile_signInInput`}
                      placeholder="person@company.com"
                      disabled
                      defaultValue={myProfile?.profileInfo?.email}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} lg={12}>
                  <InputBoxLabel
                    sx={{ marginTop: "10px", marginBottom: "4px" }}
                  >
                    Password
                  </InputBoxLabel>
                  <div className={`myProfile_inputWithIcon`}>
                    <input
                      disabled
                      className={`myProfile_passwordInput`}
                      placeholder="*********"
                    ></input>
                  </div>
                </Grid>
              </Grid>
             { !dealerId && <div
               className={classnames("myProfile_btnBox2", {
                editEmailPasswordClass: currentDevice === "Mobile",
              })}
               >
                <button
                  className={`ilmorButon`}
                  disabled={dealerId}
                  onClick={() => {
                    window.scrollTo(0,0)
                    editEmail();
                  }}
                >
                  <span className={`myProfile_editLabel`}>Edit Email</span>
                </button>
                <button
                  className={`ilmorButon`}
                  disabled={dealerId}
                  onClick={() => {
                    window.scrollTo(0,0)
                    editPassword();
                  }}
                >
                  <span className={`myProfile_editLabel`}>Edit Password</span>
                </button>
              </div>}
            </Grid>
          </Grid>
          <Box>
            {currentDevice == "Mobile" && (
              <TabletViewBlackContainer currentDevice={currentDevice} />
            )}
          </Box>
        </ContainerDivStyled>
      </Box>
    </>
  );
}
