import React, { useEffect, useState } from "react";
import {
  CardContentStyled,
  CardHeadingStyled,
  StyledShippingHeading,
} from "../StyledComponents/ShippingAddressStyled";
import { useNavigate } from "react-router-dom";
import { Grid, Card, Box } from "@mui/material";
import { authorizeNet, getMyProfile, getPay } from "../../api/posts";
import { useMutation } from "@tanstack/react-query";
import { useStore } from "../../store/home";
import { ReactComponent as AddressIcon } from "../../Images/ShippingAddressIcon.svg";
import { ReactComponent as AccountOverviewShippingAddress } from "../../Images/AccountOverviewShippingAddress.svg";
import { ReactComponent as AccountOverviewPurchase } from "../../Images/AccountOverviewPurchase.svg";

import { ReactComponent as American } from "../../Images/master.svg";
import { ReactComponent as Discover } from "../..//Images/discover.svg";
import { ReactComponent as Visa } from "../../Images/visa.svg";
import { ReactComponent as AccountOverviewPayment } from "../../Images/AccountOverviewPayment.svg";
import { ReactComponent as AccountOverviewTaxExemption } from "../../Images/AccountOverviewTaxExemption.svg";

import { ReactComponent as AccountOverviewMyProfile } from "../../Images/AccountOverviewMyProfile.svg";
import { ReactComponent as RedArrowIcon } from "../../Images/RedArrowIcon.svg";
import { ActiveInactiveStyled } from "../StyledComponents/EmployeeAccountStyled";
import TabletViewBlackContainer from "../TabletViewBlackContainer";
import { ContainerDivStyled } from "../StyledComponents/BusinessDetailsStyled";
import Loader from "../../commenComp/Loader";
import moment from "moment";

const AccountHome = () => {
  const [accountOverview, setAccountOverview] = useState();
  const [d, setd] = useState(false);
  const [a, setA] = useState(false);
  const {
    token,
    setToken,
    myProfile,
    dealerId,
    userInfo,
    cusProfile,
    setSideMenuAccount,
    currentDevice,
  } = useStore();
  const mutation = useMutation((post) => getMyProfile(post, token), {
    onSuccess: (result) => {
      setAccountOverview(result?.data);
    },
    onError: (error) => {},
  });
  useEffect(() => {
    const obj = {};
    obj.email = userInfo?.username;
    obj.dealerId = dealerId;
    mutation.mutate(obj);
  }, []);

  const getAllCards = {
    getCustomerProfileRequest: {
      merchantAuthentication: {
        name: process.env.REACT_APP_MERCHANT_NAME,
        transactionKey: process.env.REACT_APP_MERCHANT_TRANSACTION_KEY,
      },
      customerProfileId: cusProfile,
      // includeIssuerInfo: "true",
      unmaskExpirationDate: true,
    },
  };
  const navigate = useNavigate();
  const [allCards, getAllCardsArray] = useState(false);
  const [allCardsStatus, getAllCardsArrayStatus] = useState(false);
  const getPayMethod = useMutation((post) => getPay(post, token), {
    onSuccess: (result) => {
      if (result?.data?.success && result?.data?.data?.CustomerProfileId) {
        getAllCardsArrayStatus(result?.data?.data?.cards);
      }
    },
    onError: (error) => {},
  });

  useEffect(() => {
    const data = {
      id: myProfile?.profileInfo?.id,
    };
    getPayMethod.mutate(data);
  }, []);
  const [defCardData, setDefaultCard] = useState(false);

  useEffect(() => {
    if (
      allCards &&
      allCards?.profile?.paymentProfiles &&
      allCards?.profile?.paymentProfiles?.length > 0
    ) {
      let defCard = false;
      allCards.profile.paymentProfiles.forEach((item) => {
        if (item.defaultPaymentProfile) {
          const obj = {};
          obj.reference = item?.billTo?.firstName;
          obj.reference1 = item?.billTo?.lastName;
          obj.cusProfile = allCards?.profile?.customerProfileId;
          obj.customerPaymentProfileId = item?.customerPaymentProfileId;
          obj.card = item?.payment?.creditCard?.cardNumber;
          obj.expiration = item?.payment?.creditCard?.expirationDate;
          obj.status = item?.defaultPaymentProfile;
          obj.cardType = item?.payment?.creditCard?.cardType;
          defCard = obj;
        }
      });
      if (defCard) {
        setDefaultCard(defCard);
      } else {
        setDefaultCard(false);
      }
    }
  }, [allCards]);
  const authorize = useMutation((post) => authorizeNet(post), {
    onSuccess: (result) => {
      if (result) {
        getAllCardsArray(result.data);
      }
    },
    onError: (error) => {},
  });

  useEffect(() => {
    if (defCardData) {
      if (allCardsStatus?.length > 0 && defCardData) {
        allCardsStatus.forEach((item) => {
          if (
            item.profileInformationId === defCardData.customerPaymentProfileId
          ) {
            setA(item.active)
            setd(item.referenceLabel);
          }
        });
      }
    }
  }, [defCardData, allCardsStatus]);
  useEffect(() => {
    if (cusProfile) {
      authorize.mutate(getAllCards);
    }
  }, [cusProfile]);
  return (
    <>
      {(mutation.isLoading ||
        getPayMethod.isLoading ||
        authorize.isLoading) && <Loader></Loader>}
      <Box
       className='serfhui76'
        sx={{
          background: "#eaeaea",
          width: "100%",
        }}
      >
        <StyledShippingHeading
        className="nkerfgj5654m"
          sx={{
            padding:
              currentDevice == "Tablet"
                ? "24px 28px"
                : currentDevice == "Mobile"
                ? "24px 20px 1px 20px"
                : "60px 60px 0px 60px ",
          }}
          currentDevice={currentDevice}
        >
          ACCOUNT OVERVIEW
        </StyledShippingHeading>
        {currentDevice == "Tablet" && (
          <TabletViewBlackContainer currentDevice={currentDevice} />
        )}
        <ContainerDivStyled currentDevice={currentDevice}>
          <Grid className="rtyhjkl5645" container>
            <Grid className="njerftgkl4" item xs={12} sm={12} md={12}>
              <Grid
                container
                className="sdfdsfsdf"
                rowSpacing={3}
                columnSpacing={3}
                sx={{ marginBottom: "20px", marginTop: "2px" }}
              >
                <Grid className="dfgrtertrt6" item lg={4} md={6} xs={12} sm={6} sx={{}}>
                  <div className="padtopformyprofe"> MY PROFILE</div>
                  <Card className="heghtforcard" sx={{ height: 280, padding: 4, position: "relative" }}>
                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ marginRight: "20px" }}>
                        <AccountOverviewMyProfile className="icon"></AccountOverviewMyProfile>
                      </Box>
                      <Box>
                        <CardHeadingStyled>
                          {accountOverview?.profileInfo?.firstname}{" "}
                          {accountOverview?.profileInfo?.lastname}
                        </CardHeadingStyled>
                        {accountOverview?.profileInfo?.primaryPhone && (
                          <CardContentStyled>
                            {accountOverview?.profileInfo?.primaryPhone}
                          </CardContentStyled>
                        )}
                        {accountOverview?.profileInfo
                          ?.reciveTextNotification ? (
                          <>
                            {" "}
                            <CardContentStyled></CardContentStyled>
                          </>
                        ) : (
                          <>
                            {" "}
                            {/* <CardContentStyled>
                              You have not Opted In for text messages
                            </CardContentStyled> */}
                          </>
                        )}
                      </Box>
                    </Box>

                    {!dealerId && (
                      <Box
                      className='dfgkfgjhdfiyj112'
                        sx={{
                          bottom: "32px",
                          right: "32px",
                          position: "absolute",
                          cursor: "pointer",
                          fontSize: "13px",
                          fontWeight: 700,
                          "&:hover": {
                           
                            color: '#900a1f',
                            transitionDuration:" 500ms",

                            
                          },
                        }}
                        onClick={() => {
                          window.scrollTo(0,0)
                          setSideMenuAccount("myProfile");
                          navigate({
                            pathname: "/account/profile",
                          });
                        }}
                      >
                        Manage Profile <Box
                            component="div"
                            sx={{ display: "inline", marginLeft: "8px" }}
                          >
                            <RedArrowIcon />
                          </Box>
                      </Box>
                    )}
                  </Card>
                </Grid>
                <Grid  className="dfgrtertrt6" item lg={4} md={6} xs={12} sm={6} sx={{}}>
                  <div  className="padtopformyprofe"> BUSINESS DETAILS</div>
                  <Card className="heghtforcard"  sx={{ height: 280, padding: 4, position: "relative" }}>
                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ marginRight: "20px" }}>
                        <AddressIcon className="icon"></AddressIcon>
                      </Box>
                      <Box>
                        <CardHeadingStyled>
                          {accountOverview?.bussinessDetailsInfo?.dealerName}
                        </CardHeadingStyled>
                        <CardContentStyled>
                          {
                            accountOverview?.bussinessDetailsInfo
                              ?.dealerAddress1
                          }
                        </CardContentStyled>
                        <CardContentStyled>
                          {
                            accountOverview?.bussinessDetailsInfo
                              ?.dealerAddress2
                          }
                        </CardContentStyled>

                        {/* <CardContentStyled>
                          {
                            accountOverview?.bussinessDetailsInfo?.dealerCountry
                              ?.label
                          }
                        </CardContentStyled> */}
                        {/* <CardContentStyled>
                          {
                            accountOverview?.bussinessDetailsInfo?.dealerCity
                              ?.label
                          }
                        </CardContentStyled> */}
                        <CardContentStyled>
                          {`${accountOverview?.bussinessDetailsInfo?.dealerCity?.label} ${accountOverview?.bussinessDetailsInfo?.dealerState?.label ? accountOverview?.bussinessDetailsInfo?.dealerState?.label + ',' : ''}  ${accountOverview?.bussinessDetailsInfo?.dealerZipCode}`}
                        </CardContentStyled>
                        <CardContentStyled>
                          {
                            accountOverview?.bussinessDetailsInfo?.dealerCountry
                              ?.label
                          }
                        </CardContentStyled>
                        {/* <CardContentStyled>
                          {accountOverview?.bussinessDetailsInfo?.dealerZipCode}
                        </CardContentStyled> */}
                      </Box>
                    </Box>

                    {!dealerId &&
                      !(
                        myProfile?.profileInfo?.role === "ServiceTechnician" ||
                        myProfile?.profileInfo?.role === "PurchasingManager"
                      ) && (
                        <Box
                        className='dfgkfgjhdfiyj112'
                          sx={{
                            bottom: "32px",
                            right: "32px",
                            position: "absolute",
                            fontSize: "13px",
                            cursor: "pointer",
                            fontWeight: 700,
                            "&:hover": {
                           
                              color: '#900a1f',
                              transitionDuration:" 500ms",
 
                              
                            },
                          }}
                          onClick={() => {
                            window.scrollTo(0,0)
                            setSideMenuAccount("businessDetails");  
                            navigate({
                              pathname: "/account/mgmt/business",
                            });
                          }}
                        >
                          Manage Details <Box
                            component="div"
                            sx={{ display: "inline", marginLeft: "8px" }}
                          >
                            <RedArrowIcon />
                          </Box>
                        </Box>
                      )}
                  </Card>
                </Grid>
                <Grid  className="dfgrtertrt6" item lg={4} md={6} xs={12} sm={6} sx={{}}>
                  <div  className="padtopformyprofe"> SHIPPING ADDRESSES</div>
                  <Card className="heghtforcard" sx={{ height: 280, padding: 4, position: "relative" }}>
                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ marginRight: "20px" }}>
                        <AccountOverviewShippingAddress className="icon"></AccountOverviewShippingAddress>
                      </Box>
                      <Box>
                        <CardHeadingStyled>
                          {accountOverview?.shippingInfo?.locationName}
                        </CardHeadingStyled>
                        <CardContentStyled>
                          {accountOverview?.shippingInfo?.address1}
                        </CardContentStyled>
                        <CardContentStyled>
                          {accountOverview?.shippingInfo?.address2}
                        </CardContentStyled>
                        {/* 
                        <CardContentStyled>
                          {accountOverview?.shippingInfo?.country}
                        </CardContentStyled> */}
                        {/* <CardContentStyled>
                          {accountOverview?.shippingInfo?.city}
                        </CardContentStyled> */}
                        <CardContentStyled>
                          {`${accountOverview?.shippingInfo?.city?.label ?? ''} ${accountOverview?.shippingInfo?.state?.label  ? accountOverview?.shippingInfo?.state?.label+ ','  : ''} ${accountOverview?.shippingInfo?.zipCode  ?? ''}`}
                        </CardContentStyled>

                        <CardContentStyled>
                          {accountOverview?.shippingInfo?.country?.label}
                        </CardContentStyled>
                        {/* <CardContentStyled>
                          {accountOverview?.shippingInfo?.zipCode}
                        </CardContentStyled> */}

                        <Box className='martopActiveCard'>
                          <CardContentStyled>
                            {accountOverview?.shippingInfo?.isDefault && (
                              <ActiveInactiveStyled status={true}>
                                Default
                              </ActiveInactiveStyled>
                            )}
                          </CardContentStyled>
                        </Box>
                      </Box>
                    </Box>

                    {!dealerId &&
                      myProfile?.profileInfo?.role !== "ServiceTechnician" && (
                        <Box
                        className='dfgkfgjhdfiyj112'
                          sx={{
                            bottom: "32px",
                            right: "32px",
                            position: "absolute",
                            fontSize: "13px",
                            cursor: "pointer",
                            fontWeight: 700,
                            "&:hover": {
                           
                              color: '#900a1f',
                              transitionDuration:" 500ms",
 
                              
                            },
                          }}
                          onClick={() => {
                            window.scrollTo(0,0)
                            setSideMenuAccount("shipping");  
                            navigate({
                              pathname: "/account/mgmt/shipping",
                            });
                          }}
                        >
                          Manage Addresses <Box
                            component="div"
                            sx={{ display: "inline", marginLeft: "8px" }}
                          >
                            <RedArrowIcon />
                          </Box>
                        </Box>
                      )}
                  </Card>
                </Grid>
                {myProfile?.profileInfo?.role !== "ServiceTechnician" && (
                  <Grid  className="dfgrtertrt6" item lg={4} md={6} xs={12} sm={6} sx={{}}>
                    <div  className="padtopformyprofe"> PAYMENT METHODS</div>
                    <Card className="heghtforcard" 
                      sx={{ height: 280, padding: 4, position: "relative" }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <Box sx={{ marginRight: "20px" }}>
                          <AccountOverviewPayment className="icon"></AccountOverviewPayment>
                        </Box>
                        {defCardData ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              // gap: "10px",
                            }}
                          >
                            <CardHeadingStyled>{d ? d : ""}</CardHeadingStyled>
                            <CardContentStyled>
                              {`${
                                defCardData?.reference
                                  ? defCardData?.reference
                                  : ""
                              } ${
                                defCardData?.reference1
                                  ? defCardData?.reference1
                                  : ""
                              } `}
                            </CardContentStyled>
                            <div className="center just">
                              {/* <>
                            {defCardData.cardType === "Visa" ? (
                              <Visa className="catogeryIcon"></Visa>
                            ) : (
                              ""
                            )}
                            {defCardData.cardType === "AmericanExpress" ? (
                              <American className="catogeryIcon"></American>
                            ) : (
                              ""
                            )}
                            {defCardData.cardType === "MasterCard" ? (
                              <American className="catogeryIcon"></American>
                            ) : (
                              ""
                            )}
                            {defCardData.cardType === "JCB" ? (
                              <Discover className="catogeryIcon"></Discover>
                            ) : (
                              ""
                            )}
                            {defCardData.cardType === "Discover" ? (
                              <Discover className="catogeryIcon"></Discover>
                            ) : (
                              ""
                            )}
                          </> */}

                              <CardContentStyled>
                                {`${defCardData?.cardType}`}
                              </CardContentStyled>
                              <CardContentStyled>
                                {`-${defCardData?.card?.replace(/X/g, "")}`}
                              </CardContentStyled>
                            </div>
                            <CardContentStyled>
                              {/* {defCardData?.expiration} */}
                              {moment(defCardData?.expiration).format("MM/YY")}
                            </CardContentStyled>
                            {/* {accountOverview?.paymentMethods?.expiryDate} */}
                            <Box className="martopActiveCard">
                              {a ? (
                                <ActiveInactiveStyled
                                  sx={{
                                    "& .MuiSlider-thumb": {
                                      // borderRadius: "1px",
                                    },
                                  }}
                                  status={true}
                                >
                                  Active
                                </ActiveInactiveStyled>
                              ) : (
                                <ActiveInactiveStyled status={false}>
                                  InActive
                                </ActiveInactiveStyled>
                              )}
                            </Box>
                          </Box>
                        ) : (
                          <CardHeadingStyled>
                            No default payment method found
                          </CardHeadingStyled>
                        )}
                      </Box>
                      {!dealerId &&
                        myProfile?.profileInfo?.role !==
                          "ServiceTechnician" && (
                          <Box
                          className='dfgkfgjhdfiyj112'
                            sx={{
                              bottom: "32px",
                              right: "32px",
                              position: "absolute",
                              cursor: "pointer",
                              fontSize: "13px",
                              fontWeight: 700,
                              "&:hover": {
                           
                                color: '#900a1f',
                                transitionDuration:" 500ms",
   
                                
                              },
                            }}
                            onClick={() => {
                              window.scrollTo(0,0)
                              setSideMenuAccount("paymentMethods");
                              navigate({
                                pathname: "/account/mgmt/payment",
                              });
                            }}
                          >
                            Manage Payments <Box
                            component="div"
                            sx={{ display: "inline", marginLeft: "8px" }}
                          >
                            <RedArrowIcon />
                          </Box>
                          </Box>
                        )}
                    </Card>
                  </Grid>
                )}
                {accountOverview?.canManagePurchaseOrder &&
                  myProfile?.profileInfo?.role !== "ServiceTechnician" && (
                    <Grid  className="dfgrtertrt6" item lg={4} md={6} xs={12} sm={6} sx={{}}>
                      <div  className="padtopformyprofe"> PURCHASE ORDERS</div>
                      <Card className="heghtforcard"  sx={{ height: 280, padding: 4 }}>
                        <Box sx={{ display: "flex" }}>
                          <Box sx={{ marginRight: "20px" }}>
                            <AccountOverviewPurchase></AccountOverviewPurchase>
                          </Box>
                          <CardHeadingStyled>
                            {accountOverview?.canManagePurchaseOrder
                              ? "You are authorised to use purchase order"
                              : "You are not authorised to use purchase order"}
                          </CardHeadingStyled>
                        </Box>
                      </Card>
                    </Grid>
                  )}

                {myProfile?.profileInfo?.USDealer && (
                  <Grid  className="dfgrtertrt6" item lg={4} md={6} xs={12} sm={6} sx={{}}>
                    <div  className="padtopformyprofe">TAX EXEMPTION FORM</div>
                    <Card className="heghtforcard" 
                      sx={{ height: 280, padding: 4, position: "relative" }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <Box sx={{ marginRight: "20px" }}>
                          <AccountOverviewTaxExemption className="icon"></AccountOverviewTaxExemption>
                        </Box>
                        {!accountOverview?.taxExemptionForm?.status &&
                        accountOverview?.taxExemptionForm?.msg?.length > 0 ? (
                          <Box>
                            {" "}
                            <CardHeadingStyled>
                              {accountOverview?.taxExemptionForm?.msg}
                            </CardHeadingStyled>
                          </Box>
                        ) : (
                          <Box>
                            <CardHeadingStyled>
                              {
                                accountOverview?.bussinessDetailsInfo
                                  ?.dealerName
                              }
                            </CardHeadingStyled>
                            <CardContentStyled>
                              Start :
                              {` ${accountOverview?.taxExemptionForm?.startDate}`}
                            </CardContentStyled>
                            <CardContentStyled>
                              End :
                              {` ${accountOverview?.taxExemptionForm?.expiryDate}`}
                            </CardContentStyled>

                            <Box className='martopActiveCard'>
                              {accountOverview?.taxExemptionForm?.status ? (
                                <ActiveInactiveStyled
                                  sx={{
                                    "& .MuiSlider-thumb": {
                                      // borderRadius: "1px",
                                    },
                                  }}
                                  status={true}
                                >
                                  Active
                                </ActiveInactiveStyled>
                              ) : (
                                <ActiveInactiveStyled status={false}>
                                  InActive
                                </ActiveInactiveStyled>
                              )}
                            </Box>
                          </Box>
                        )}
                      </Box>

                      {!dealerId &&
                        myProfile?.profileInfo?.role !== "ServiceTechnician" &&
                        myProfile?.profileInfo?.permissions?.includes(
                          "manage_tax_exempt_form"
                        ) && (
                          <Box
                          className='dfgkfgjhdfiyj112'
                            sx={{
                              bottom: "32px",
                              right: "32px",
                              position: "absolute",
                              fontSize: "13px",
                              cursor: "pointer",
                              fontWeight: 700,
                              "&:hover": {
                           
                                color: '#900a1f',
                                transitionDuration:" 500ms",
   
                                
                              },
                            }}
                            onClick={() => {
                              window.scrollTo(0,0)
                              setSideMenuAccount("tax");
                              navigate({
                                pathname: "/account/mgmt/tax",
                              });
                            }}
                          >
                            Manage Tax Exemption <Box
                            component="div"
                            sx={{ display: "inline", marginLeft: "8px" }}
                          >
                            <RedArrowIcon />
                          </Box>
                          </Box>
                        )}
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Box className='marginforaccontover'>
            {currentDevice == "Mobile" && (
              <TabletViewBlackContainer currentDevice={currentDevice} />
            )}
          </Box>
        </ContainerDivStyled>
      </Box>
    </>
  );
};

export default AccountHome;
