import React from "react";
import {
  StyledRequiredFields,
  StyledShippingHeading,
  StyledShippingSubHeading,
} from "./StyledComponents/ShippingAddressStyled";
import { Box, Button, Grid } from "@mui/material";
import {
  InputBoxLabel,
  UserRoleMyProfile,
} from "./StyledComponents/MyProfileStyled";
import {
  SignInInfo_subHeading,
  SignInInfo_subHeading2,
} from "./StyledComponents/SignInInfoStyled";
import TabletViewBlackContainer from "./TabletViewBlackContainer";
import { useStore } from "../store/home";
import { useForm } from "react-hook-form";
import { Divider } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessageStyled } from "./StyledComponents/CommonStyled";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { signInInfo } from "../api/posts";
import { showErrorToast, showSuccessToast } from "../utils/Toaster";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import commonMessages from "../commenComp/CommonMessages";
import Loader from "../commenComp/Loader";
import { toast } from "react-toastify";
import classNames from "classnames";

export default function SignInInfo() {
  const navigate = useNavigate();

  const [passwordValues, setPasswordValues] = useState({});
  const {
    currentDevice,
    setSideMenuAccount,
    token,
    setToken,
    myProfile,
    setSelectedCategory,
    setLastPage,
    setFooterContentdataInZustand,
  } = useStore();
  const {
    setEditPasswordBtnClick,
    editEmailBtnClick,
    editPasswordBtnClick,
    setEditEmailBtnClick,
  } = useNonPersistedStore();
  const schema = yup.object().shape({
    currentPassword: yup.string().required("Current Password is required"),
    email: yup
      .string()
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email"
      ),
    newPassword:
      editPasswordBtnClick && yup.string().required("New Password is required"),
    confirmNewPassword:
      editPasswordBtnClick &&
      yup
        .string()
        .required("Confirmation is required")
        .oneOf([yup.ref("newPassword")], "Passwords do not match"),
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,

    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),

    defaultValues: {
      email: myProfile?.profileInfo?.email,
    },
  });

  const onSubmit = (data, event) => {
    event.preventDefault();

    mutation.mutate({
      ...data,
      id: myProfile?.profileInfo?.id,
    });
  };

  const handleForgetPw = () => {
    localStorage.removeItem("token");
    setLastPage(false);
    localStorage.removeItem("my-persisted-store");
    navigate({
      pathname: "/forgot-password",
    });
  };

  const handleCancel = () => {
    // Reset primaryPhone and textNotifications to their initial values
    // reset({ currentPassword: "", email: "" , newPassword:"", confirmNewPassword:""});
    setSideMenuAccount("myProfile");
    navigate({
      pathname: "/account/profile",
    });
  };

  const mutation = useMutation((post) => signInInfo(post, token), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
          showSuccessToast(result?.data?.msg);
          // reset()
          setLastPage(false);
          localStorage.removeItem("token");
          localStorage.removeItem("my-persisted-store");
          setSideMenuAccount("home");
          setSelectedCategory("home");
          setFooterContentdataInZustand([]);
          setToken("");
          navigate("/sign-in", { replace: true });
        } else {
          toast.dismiss();
          toast.error(result?.data?.msg, {
            position: "top-right",
            autoClose: 20000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
          });
        }
      }
    },
    onError: (error) => {},
  });

  return (
    <div className="ShippingAddressbackGround">
      {mutation.isLoading && <Loader></Loader>}
      <div className="ShippingouterContainerMyProfile">
        <Grid container sx={{ display: "flex", flexDirection: "row" }}>
          <Grid item md={12}>
            <div className="paddingSighInfoTab">
              <StyledShippingHeading
                sx={{ fontSize: currentDevice === "Mobile" ? "24px" : "40px" }}
              >
                {commonMessages?.signInInfoHeading}
              </StyledShippingHeading>
            </div>
          </Grid>

          {currentDevice == "Tablet" && (
            <Grid container sx={{ display: "flex" }}>
              <TabletViewBlackContainer currentDevice={currentDevice} />
            </Grid>
          )}

          <Grid container spacing={2} sx={{ padding: "16px 0px 16px 0px" }}>
            <Grid item xs={12} sm={12} md={12} sx={{ marginTop: "16px" }}>
              <div className="tabletpaddinsigh">
                <StyledShippingSubHeading>
                  {editPasswordBtnClick
                    ? commonMessages?.signInInfoSubHeading
                    : "You can change your email here"}
                </StyledShippingSubHeading>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <StyledShippingSubHeading>
                {commonMessages?.signInInfoSubHeadingTwo}
              </StyledShippingSubHeading>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} sm={12} sx={{ marginTop: "16px" }}>
            <Box>
              <StyledRequiredFields className="labelrequired1">
                {commonMessages?.addEmployeeAccountRequiredField}
              </StyledRequiredFields>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Divider sx={{ margin: "27px 0px 19px 0px", width: "100%" }} />
          </Grid>
        </Grid>

        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ marginBottom: "-50px" }}
        >
          <Grid container sx={{ display: "flex", flexDirection: "column" }}>
            <Grid item>
              <InputBoxLabel
                className="labelrequired"
                sx={{ marginTop: "10px" }}
              >
                CURRENT PASSWORD
              </InputBoxLabel>
              <input
                style={{
                  width: "100%",
                  display: "flex",
                  height: "42px",
                  padding: "16px",
                  alignItems: "center",
                  gap: "12px",
                  alignSelf: "stretch",
                  border: "1px solid rgba(97, 94, 92, 0.15)",
                  background: "var(--white, #FFF)",
                  marginTop: "2px",
                }}
                name="currentPassword"
                type="password"
                // defaultValue={myProfileForm?.firstname}
                // onChange={(e)=> e.target.value}
                className={`myProfile_firstNameInput`}
                {...register("currentPassword")}
              ></input>
              {errors?.currentPassword && (
                <ErrorMessageStyled>
                  {errors.currentPassword?.message}
                </ErrorMessageStyled>
              )}
            </Grid>

            {editEmailBtnClick && (
              <Grid className="sdfsdfwegghh   editInputFieldMArginTAblet" item sx={{ marginTop: "16px" }} spacing={1}>
                <InputBoxLabel
                  className="labelrequired"
                  sx={{ marginTop: "10px" }}
                >
                  EMAIL
                </InputBoxLabel>
                <input
                  style={{
                    width: "100%",
                    display: "flex",
                    height: "42px",
                    padding: "16px",
                    alignItems: "center",
                    gap: "12px",
                    alignSelf: "stretch",
                    border: "1px solid rgba(97, 94, 92, 0.15)",
                    background: "var(--white, #FFF)",
                    marginTop: "2px",
                  }}
                  name="email"
                  type="text"
                  // defaultValue={myProfileForm?.firstname}
                  // onChange={(e)=> e.target.value}
                  className={`myProfile_firstNameInput`}
                  {...register("email")}
                ></input>
                {errors?.email && (
                  <ErrorMessageStyled>
                    {errors.email?.message}
                  </ErrorMessageStyled>
                )}
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={12} sx={{ margin: "36px 0px !important" }}>
          {  currentDevice !="Tablet"  &&   <hr style={{ borderColor: "#d1d1d033" }}></hr>}
          </Grid>

          <Grid container sx={{ display: "flex", flexDirection: "column" }}>
            {editPasswordBtnClick && (
              <>
                {" "}
                <Grid className="skdjfsdjkl tabletChooseanewPassword" item md={12} >
                  <UserRoleMyProfile
                    sx={{ marginTop: "0px", padding: "0px 0px 18px 0px" }}
                  >
                    Choose a New Password
                  </UserRoleMyProfile>
                </Grid>
                <Grid className="srstjkhgjktr tabletChooseanewPasswordBottomCheck" item md={12} my={2}>
                  <StyledShippingSubHeading>
                    {/* Leave the following blank if only updating email, otherwise
                    enter your new password twice. */}
                    The new password must have atleast 10 characters, 1 lower
                    case, 1 upper case, 1 number and one special character
                  </StyledShippingSubHeading>
                </Grid>
                <Grid item md={12}>
                  <InputBoxLabel
                    className="labelrequired"
                    sx={{ marginTop: "10px" }}
                  >
                    New Password
                  </InputBoxLabel>
                  <input
                    style={{
                      width: "100%",
                      display: "flex",
                      height: "42px",
                      padding: "16px",
                      alignItems: "center",
                      gap: "12px",
                      alignSelf: "stretch",
                      border: "1px solid rgba(97, 94, 92, 0.15)",
                      background: "var(--white, #FFF)",
                      marginTop: "2px",
                    }}
                    name="newPassword"
                    type="password"
                    // defaultValue={myProfileForm?.firstname}
                    // onChange={(e)=> e.target.value}
                    className={`myProfile_firstNameInput`}
                    {...register("newPassword")}
                    // onChange={(e) => {
                    //   const newPassword = e.target.value;
                    //   setPasswordValues((prevValues) => ({
                    //     ...prevValues,
                    //     newPassword,
                    //   }));
                    // }}
                  ></input>
                  {errors?.newPassword && (
                    <ErrorMessageStyled>
                      {errors.newPassword?.message}
                    </ErrorMessageStyled>
                  )}
                </Grid>
                <InputBoxLabel
                  className="labelrequired"
                  sx={{ marginTop: "17px", width: "100%" }}
                >
                  Confirm New Password
                </InputBoxLabel>
                <input
                  style={{
                    width: "100%",
                    display: "flex",
                    height: "42px",
                    padding: "16px",
                    alignItems: "center",
                    gap: "12px",
                    alignSelf: "stretch",
                    border: "1px solid rgba(97, 94, 92, 0.15)",
                    background: "var(--white, #FFF)",
                    marginTop: "2px",
                  }}
                  name="confirmNewPassword"
                  type="password"
                  // defaultValue={myProfileForm?.firstname}
                  // onChange={(e)=> e.target.value}
                  // className={`signInInfo_confPwInput`}
                  {...register("confirmNewPassword")}
                  // onChange={(e) => {
                  //   const confirmNewPassword = e.target.value;
                  //   setPasswordValues((prevValues) => ({
                  //     ...prevValues,
                  //     confirmNewPassword,
                  //   }));
                  // }}
                ></input>
                {errors?.confirmNewPassword && (
                  <ErrorMessageStyled>
                    {errors.confirmNewPassword?.message}
                  </ErrorMessageStyled>
                )}{" "}
              </>
            )}

            <Grid
              className="tabletdownpadding marginBottomSignInInfoMobile tabletPaddingCancelEmail"
              item
              xs={12}
              sm={12}
              md={12}
              sx={{
                display: "flex",
                padding: "0px 0px 8px 0px",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                marginBottom:
                  currentDevice === "Mobile" ? "30px !important" : "0px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  padding: "12px 20px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                }}
                my={2}
              >
                {editPasswordBtnClick && (
                  <span
                    className="signinFP"
                    onClick={handleForgetPw}
                    style={{
                      color: "var(--brand-colors-black, #07080C)",
                      textAlign: "center",

                      /* Text sm/Bold */
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "18px",
                      cursor: "pointer",
                    }}
                  >
                    Forgot Password
                  </span>
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "12px",
                }}
                className={classNames("", {
                  chakUpdateEmailEditClickSignInBtnTablet: editEmailBtnClick,
                })}
                
              >
                <Box
                  sx={{
                    display: "flex",
                    padding: "12px 20px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <span
                    className="signinCancel"
                    onClick={handleCancel}
                    style={{
                      color: "var(--brand-colors-black, #07080C)",
                      textAlign: "centre",
                      cursor: "pointer",

                      /* Text sm/Bold */
                      fontFamily: "Montserrat",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "18px" /* 138.462% */,
                    }}
                  >
                    Cancel
                  </span>
                </Box>

                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    background: "var(--brand-colors-ilmor-red, #CE0E2D)",
                    display: "flex",
                    padding: "12px 20px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "16px",
                    border: "none",
                    borderRadius: "0px",
                    "&:hover": {
                      background: "darkRed",
                    },
                  }}
                  // disabled={
                  //   errors.confirmNewPassword ||
                  //   passwordValues.newPassword !==
                  //     passwordValues.confirmNewPassword
                  // }
                >
                  {" "}
                  <span
                    style={{
                      color: "#FFF",
                      textAlign: "center",

                      /* Text sm/Bold */
                      fontFamily: "Montserrat",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "18px",
                    }}
                  >
                    Update
                  </span>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>

        <Box sx={{ marginBottom: "-20px" }}>
          {currentDevice == "Mobile" && (
            <TabletViewBlackContainer currentDevice={currentDevice} />
          )}
        </Box>
      </div>
    </div>
  );
}
