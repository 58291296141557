import "./OrderComplete.css";
import { Grid ,Box} from "@mui/material";
import { useStore } from "../store/home";
import { useEffect } from "react";
import commonMessages from "../commenComp/CommonMessages";
import { useMutation } from "@tanstack/react-query";
import { getMyCart } from "../api/posts";

const OrderComplete = () => {
  const {
    paymentStatus,
    userInfo,
    setMyCartMini,
    orderDetailInfoComp,
    token,
    dealerId,
    isPO,
    orderFail,
    currentDevice,
  } = useStore();
  const mutation_cartMINI = useMutation((post) => getMyCart(post, token), {
    onSuccess: (result) => {
      setMyCartMini(result);
    },
    onError: (error) => {},
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    const obj = {
      email: userInfo.username,
      minicart: true,
      dealerId: dealerId,
    };
    mutation_cartMINI.mutate(obj);
  }, []);

  return (
    <>
      {!orderFail ? (
        <>
          {paymentStatus?.data?.transactionResponse?.responseCode === "1" || isPO ? (
            <Grid container spacing={1} className="orderCom">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  color: " #07080C",
                  /* Display md/Bold */
                  fontSize: " 24px",
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  lineHeight: "120%",
                  paddingBottom:
                    currentDevice !== "Mobile" ? "32px !important" : "",
                  textTransform: "uppercase",
                }}
                // className="orderCompTitile"
              >
                <Box component="span" sx={{ display: "inline" }}>
                  {commonMessages?.orderCompleteHeading}
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  color: "#000",
                  /* Text lg/medium */
                  fontSize: "16px",
                  borderTop: "2px solid #07080C",
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  lineHeight: currentDevice !== "Mobile" ? "24px" : "8px",
                  marginBottom: currentDevice==="Tablet" && "-12px",
                  paddingTop: "32px !important",
                  textAlign: "left",
                  borderTop: "2px solid #07080C",
                }}
                // className="orderCompThank"
              >
                <Box component="span" sx={{ display: "inline" }}>
                  {commonMessages?.orderCompleteSubHeadingOne}
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="orderCompThank1"
              >
                <span>{`${commonMessages?.orderCompleteSubHeadingOne1} ${userInfo.username}`}</span>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="orderCompId"
              >
                <span>{`${commonMessages?.orderCompleteSubHeadingTwo} ${orderDetailInfoComp.orderId}`}</span>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="orderCompThank1 paddiv"
              >
                <div>{commonMessages?.orderCompleteSubHeadingThree}</div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="orderCompThank1 paddiv"
              >
                <div> {commonMessages?.orderCompleteSubHeadingFour}</div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="orderCompSweet paddiv"
              >
                <div>
                  {`${commonMessages?.orderCompleteSubHeadingFive} 844-GO-ILMOR (844-464-5667)`}
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="buttonGroupOrder"
              >
                {/* <button className="ilmorButon">Print Summary</button>
            <button className="ilmorButon">Download Summary</button>
            <button className="ilmorButon">Email Summary</button> */}
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1} className="orderCom">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="orderCompTitile"
              >
                <span>Payment Failed</span>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="orderCompThank"
              >
                {/* <span>{paymentStatus?.data?.messages?.message[0]?.text}</span> */}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="orderCompId"
              >
                {paymentStatus &&
                  paymentStatus?.data?.transactionResponse &&
                  paymentStatus?.data?.transactionResponse?.errors &&
                  paymentStatus?.data?.transactionResponse?.errors.length >
                    0 && (
                    <span>
                      {
                        paymentStatus?.data?.transactionResponse?.errors[0]
                          ?.errorText
                      }
                    </span>
                  )}
              </Grid>
              {/* <Grid item  xs={12} sm={12} md={12} lg={12} xl={12} className="orderCompSweet">
      <span>Go to Order Status & History to review, track, modify or cancel.<br></br> We’ll send an email with tracking information when your order ships.<br></br> If you need assistance, call XXXXXXXXXXX.</span>
    </Grid> */}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="buttonGroupOrder"
              >
                {/* <button className="ilmorButon">Print Summary</button>
            <button className="ilmorButon">Download Summary</button>
            <button className="ilmorButon">Email Summary</button> */}
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <>
          <Grid container spacing={1} className="orderCom">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="orderCompTitile"
            >
              <span>Order could not be placed</span>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="orderCompThank"
            >
              <span>
                {"Order could not be placed due to a technical issue ."}
              </span>
            </Grid>
            {/* <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="orderCompId"
            >
              <span>
                {paymentStatus?.data?.transactionResponse?.errors[0]?.errorText}
              </span>
            </Grid> */}
          </Grid>
        </>
      )}
    </>
  );
};

export default OrderComplete;
