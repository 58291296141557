import React, { useEffect, useRef, useState } from "react";
import "./App.css";
// import DummyComponent from './components/DummyComponent';
import { useStore } from "./store/home";
import LoginPage from "./components/LoginPage";
import { Route, Routes } from "react-router-dom";
import ForgetPassword from "./components/ForgetPassword";
import TaxCertificate from "./components/TaxCertificate";
import { routes } from "./router/Routes";
import Home from "./components/Home";
import Catalog from "./components/Catalog";
import { useIdleTimer } from "react-idle-timer";
import { ToastContainer, toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import PrivateRoute from "./router/PrivateRoute";
import PasswordRecovery from "./components/PasswordRecovery";
import { deviceDetector, initialState } from "./commonFunction";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../src/commenComp/Loader";
import {
  getProductListCategory,
  getShippingNotice,
  getPartList,
  getPay,
  getRefresh,
  getGetAllBanners,
  getMyProfile,
} from "./api/posts";
import "react-toastify/dist/ReactToastify.css";
import { useMutation } from "@tanstack/react-query";
import NotFound from "./components/NotFound/NotFound";
import FooterContent from "./components/FooterContent/FooterContent";
import Warranty from "./components/Warranty";
import { showErrorToast } from "./utils/Toaster";

function App() {
  const navigate = useNavigate();
  const {
    setScreenSize,
    setCurrentDevice,
    screenSize,
    setKeyWord,
    setKeyWordFinal,
    token,
    setToken,
    userInfo,
    setcusProfile,
    loading,
    categorySide,
    setCategoryName,
    setSideMenuAccount,
    setSelectedCategory,
    setFooterContentdataInZustand,
    setCategoryId,
    setCategory,
    myProfile,
    setUnsaved,
    setBanners,
    dealerId,
    setRefreshToken,
    refreshToken,
    setMyProfile,
    setLastPage,
    isCurrentEngSet,
    setCategorySide,
    checkActive,
  } = useStore();
  const location = useLocation();

  const [first, setfirst] = useState();
  const [load, setLoad] = useState(true);
  const mutation1 = useMutation((post) => getProductListCategory(post), {
    mutationKey: "getProductListCategory",
    method: "GET",
    onSuccess: (result) => {
      setfirst(result.data.data);
      setCategorySide(result.data.data);
    },
    onError: (error) => {
      if (location.pathname !== "/content/contact") {
        localStorage.removeItem("token");
        navigate("/sign-in");
        if (location.pathname !== "/sign-in") {
          setTimeout(() => {
            toast.dismiss();
            showErrorToast(error?.response?.data?.msg);
          }, 1000);
        }
      }
    },
  });
  const mutation2 = useMutation((post) => getProductListCategory(post), {
    mutationKey: "getProductListCategory",
    method: "GET",
    onSuccess: (result) => {
      setfirst(result.data.data);
      setCategorySide(result.data.data);
      setLoad(false);
    },
    onError: (error) => {
      localStorage.removeItem("token");
      setLoad(false);
      navigate("/sign-in");
    },
  });

  useEffect(() => {
    const data = {
      dealerId: dealerId,
      token: token,
      EngineId:
        location.pathname === "/catalog" ? isCurrentEngSet.engineId : "",
    };
    // if (location.pathname !== "/sign-in") {
    mutation1.mutate(data);
    // }
  }, [token, checkActive]);

  useEffect(() => {
    const data = {
      dealerId: dealerId,
      token: token,
      EngineId:
        location.pathname === "/catalog" ? isCurrentEngSet.engineId : "",
    };
    if (
      !localStorage.getItem("token") &&
      location.pathname !== "/content/contact" &&
      location.pathname !== "/sign-in"
    ) {
      mutation2.mutate(data);
    } else {
      setLoad(false);
    }
  }, [isCurrentEngSet]);

  useEffect(() => {
    setKeyWordFinal("");
    setKeyWord("");
  }, []);

  useEffect(() => {
    if (location.pathname !== "/search") {
      setCategoryId("");
    }
    if (location.pathname !== "/shopping-list") {
      setUnsaved(false);
    }
  }, [location]);

  const [state, setState] = useState("Active");
  const [count, setCount] = useState(0);
  const [remaining, setRemaining] = useState(0);

  const handleTimout = () => {
    // localStorage.removeItem("token");
    sessionStorage.setItem("timeout", true);
    localStorage.removeItem("my-persisted-store");
    setSideMenuAccount("home");
    setSelectedCategory("home");
    resetStore();
    setFooterContentdataInZustand([]);
    navigate("/sign-in", { replace: true });
    setToken("");
  };

  const onIdle = () => {
    if (location.pathname !== "/sign-in") {
      setLastPage(location.pathname);
      showErrorToast("User logged out due to inactivity");
      console.log("User logged out by idle timer of 4 hrs");
      setState("Idle");
      handleTimout();
    }
  };

  const onActive = () => {
    setState("Active");
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 60 * 60 * 4000, //temporarily set to 4hrs till jwt is not enabled
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime]);

  const refresh = useMutation((post) => getRefresh(post, token), {
    onSuccess: (result) => {
      // setUserIsLoggedIn(result.data.token);
      localStorage.setItem("token", result.data.token);
      setRefreshToken(result.data.refresh_token);
      setToken(result.data.token);
    },
    onError: (error) => {},
  });

  const banner = useMutation((post) => getGetAllBanners(post), {
    mutationKey: "getGetAllBanners",
    method: "GET",
    onSuccess: (result) => {
      setBanners(result.data.data);
    },
    onError: (error) => {},
  });

  useEffect(() => {
    if (localStorage.getItem("token")) {
      banner.mutate(token);
    }
  }, [token]);

  useEffect(() => {
    let timeoutId = "";
    if (token && refreshToken && !dealerId) {
      timeoutId = setTimeout(() => {
        const data = {
          refresh_token: refreshToken,
        };
        refresh.mutate(data);
      }, 1800000);
    } else {
      clearTimeout(timeoutId);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [refreshToken, token, dealerId]);

  useEffect(() => {
    if (first) {
      const arr = Object.values(first);
      const arrr = [];
      // arrr.unshift({ label: "All Products", value: "" });
      arrr.unshift({ label: "Categories", value: "34", isDisabled: true });
      arr.map((item) => {
        const obj = {};
        obj.value = item.id;
        obj.label = item.name;
        arrr.push(obj);
      });
      setCategory(arrr);
    }
  }, [first]);

  const getPayMethod = useMutation((post) => getPay(post, token), {
    onSuccess: (result) => {
      if (result?.data?.success && result?.data?.data?.CustomerProfileId) {
        setcusProfile(result?.data?.data?.CustomerProfileId);
      }
    },
    onError: (error) => {},
  });

  const mutationProf = useMutation((post) => getMyProfile(post, token), {
    onSuccess: (result) => {
      setMyProfile(result?.data);
    },
    onError: (error) => {},
  });

  useEffect(() => {
    if (myProfile?.profileInfo?.id) {
      const data = {
        id: myProfile?.profileInfo?.id,
      };
      if (token?.length > 0) {
        // getPayMethod.mutate(data);
      }
    }
  }, [myProfile]);

  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      let data = {
        dealerId: dealerId,
        email: userInfo?.username,
      };
      mutationProf.mutate(data);
    }
  }, []);

  useEffect(() => {
    setCurrentDevice(deviceDetector(screenSize[0]));
  }, [screenSize]);

  useEffect(() => {
    const handleWindowResize = () => {
      setScreenSize([
        document.documentElement.clientWidth,
        document.documentElement.clientHeight,
      ]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const resetStore = () => {
    useStore.setState(initialState);
  };

  useEffect(() => {
    if (categorySide) {
      const arrCat = [];
      const arr = Object.values(categorySide);
      arr.forEach((item) => {
        const objCat = {};
        objCat.data = item;
        arrCat.push(objCat);
      });
      setCategoryName(arrCat);
    }
  }, [categorySide]);

  return (
    <div className="App">
      {loading && <Loader></Loader>}
      {(mutation2.isLoading || load || mutation1.isLoading) && (
        <>
          <Loader></Loader>
          <Loader></Loader>
        </>
      )}
      <ToastContainer
        hideProgressBar={true}
        position="top-right"
        newestOnTop={true}
        pauseOnHover={true}
        draggable={true}
        closeOnClick={true}
        theme="dark"
        autoClose={5000}
      ></ToastContainer>
      <Routes>
        {routes?.map((item) => {
          return (
            <Route
              exact
              path={item.link}
              key={item.link}
              element={<PrivateRoute />}
            >
              <Route exact path={item.link} element={item.element} />
            </Route>
          );
        })}
        <Route
          exact
          path="/content/shipping-policy"
          element={<FooterContent />}
          // key="/tax-certificate"
        />
        <Route
          exact
          path="/content/warranty"
          element={<Warranty />}
          // key="/tax-certificate"
        />
        <Route
          exact
          path="/content/contact"
          element={<FooterContent />}
          // key="/tax-certificate"
        />
        <Route
          exact
          path="/content/returns"
          element={<FooterContent />}
          // key="/tax-certificate"
        />
        <Route exact path="/sign-in" element={<LoginPage />} key="/sign-in" />
        <Route
          exact
          path="/forgot-password"
          element={<ForgetPassword />}
          key="/forgot-password"
        />
        <Route
          exact
          path="/tax-certificate"
          element={<TaxCertificate />}
          key="/tax-certificate"
        />
        <Route
          exact
          path="/reset-password"
          element={<PasswordRecovery />}
          key="/forgot-password"
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
