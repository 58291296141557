import React, { useState } from 'react'
import "./ShopCart.css";
import { useNavigate } from "react-router-dom";
import { useStore } from '../store/home';


const ShopCart = ({closeAll}) => {
    const { currentDevice,myCartMini ,myProfile} = useStore();
    const[shopCart, setShopCart]= useState(
        myCartMini?.data?.minicart
    )
    const navigate = useNavigate();

    const handleViewCart = ()=>{
        closeAll()
        navigate({
            pathname: "/cart",
        });
    }
  
    return (
      <div className="shop_cart">
        <h6 className="numberOfItems">{shopCart?.count} {(shopCart?.count ===1)?"ITEM":"ITEMS"}</h6>
        <p className={`totalAmount ${(shopCart?.amount.toString().length>9)?"shrinkAmt":""}`}>
          $
          {shopCart?.amount.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          
        </p>
        {/* <p className='totalAmount'>${shopCart?.amount.toFixed(2)}</p> */}
        <button
          onClick={handleViewCart}
          className="btnViewCart"
          disabled={
            myProfile?.profileInfo?.role === "ServiceTechnician"
              ? true
              : false
          }
        >
          View Cart
        </button>
      </div>
    );

}

export default ShopCart