import "./OrderSummary.css";
import { ReactComponent as Radio } from "../Images/radioButton.svg";
import { ReactComponent as RadioEmpty } from "../Images/radioEmpty.svg";
import { ReactComponent as Visa } from "../Images/visa.svg";
import InputBase from "@mui/material/InputBase";
import { debounce } from "lodash";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useStore } from "../store/home";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { authorizeNetTokenApi } from "../api/posts";
import Tooltip from "@mui/material/Tooltip";
import { useMutation } from "@tanstack/react-query";
import Loader from "../commenComp/Loader";
import { useEffect, useRef, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import classnames from "classnames";
import {
  applyCoupon,
  authorizeNet,
  createOrder,
  getFee,
  getMyCart,
  logAPI,
  updatePayInfo,
} from "../api/posts";
import IlmorModal from "../commenComp/IlmorModal";
import commonMessages from "../commenComp/CommonMessages";
import { showErrorToast } from "../utils/Toaster";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import { addCommaDecimal } from "../commonFunction";

const OrderSummary = ({
  reviewOrder,
  orderDetail,
  dataArray,
  isFee,
  isCart,
  handleRefreshCallBack,
}) => {
  const [start, setStart] = useState("");
  const [shipIsText, setShipIsText] = useState("");
  const [tax, setTax] = useState(0);
  const [handleFee, setHandleFee] = useState(0);
  const [cp, setcp] = useState(dataArray?.coupon);
  const location = useLocation();
  const navigate = useNavigate();


  const currentPath = location.pathname;
  const {
    checkoutState,
    setCheckoutState,
    setPaymentStatus,
    shipPrice,
    isPOValue,
    service,
    finalShipPrice,
    setMyCartMini,
    token,
    userInfo,
    myProfile,
    shipInterOption,
    cusProfile,
    setIsPOValue,
    isDomestic,
    isPO,
    defaultCard,
    orderDetailInfo,
    setFinalShipPrice,
    dealerId,
    setOrderDetailInfo,
    setOrderDetailInfoComp,
    myCart,
    setMyCart,
    setOrderFail,
    setCode,
    setAllow,
    currentDevice,
    isCardActive,
    snote,
    setSnote,
    allow,
    setCheckActive,
    checkActive,
  } = useStore();
  const {
    shippinPaymentAddressUpdated,
    failMandate,
    failShip,
    setfailShip,
    change,
    quant,
    load,
    setdropFee,
    dropFee,
    setdropFeestate,
    tempSummary,
    cload,
    dropFeestate,
    loadcart,
    loadcart1,
    setLow,
    setCload,
    isDomesticOption,
    chooseCard,
    setShipDis,
  } = useNonPersistedStore();
  const email = userInfo.username;

  useEffect(() => {
    if (dataArray?.coupon) {
      setcp(dataArray?.coupon);
    } else {
      setcp(false);
    }
    if (dataArray?.shippingDiscount) {
      setShipDis(dataArray?.shippingDiscount);
    } else {
      setShipDis(false);
    }
    if (dataArray?.data?.success && !dataArray?.data?.data?.estiamtedTotal) {
      navigate({
        pathname: "/cart",
      });
    }
  }, [dataArray]);
  const mutation_cart = useMutation((post) => getMyCart(post, token), {
    onSuccess: (result) => {
      setMyCart(result);
    },
    onError: (error) => {},
  });
  const mutation_cartMINI = useMutation((post) => getMyCart(post, token), {
    onSuccess: (result) => {
      setMyCartMini(result);
    },
    onError: (error) => {},
  });
  // const [dropFeestate, setdropFeestate] = useState(false);
  const feeMut = useMutation((post) => getFee(post, token), {
    onSuccess: (result) => {
      setdropFee(result.data.handellingFees);
      setdropFeestate(result.data.handellingFees);
    },
    onError: (error) => {},
  });
  const initialRender = useRef(true);
  useEffect(() => {
    if (
      initialRender.current &&
      location.pathname === "/checkout" &&
      !chooseCard
    ) {
      initialRender.current = false;
      const obj = {
        email: userInfo.username,
      };
      if (
        isFee &&
        reviewOrder &&
        Object.keys(shippinPaymentAddressUpdated)?.length === 0
      ) {
        feeMut.mutate(obj);
      }
      return;
    }
  }, []);

  // useEffect(() => {
  //   if (isNaN(dataArray.shipping)) {
  //     setShipIsText(dataArray.shipping);
  //   } else {
  //     setShipIsText("");
  //     if (isCart) {
  //       setFinalShipPrice(dataArray.shipping);
  //     }
  //   }
  //   if (dataArray.estimatedTax && !isNaN(dataArray.estimatedTax)) {
  //     // setTax(dataArray.estimatedTax);
  //   } else {
  //     setTax(0);
  //   }
  // }, [dataArray]);

  const debouncedRefreshCallback = debounce((prop) => {
    showErrorToast(prop);
  }, 2000);

  useEffect(() => {
    if (dataArray.shipping || dataArray.shipping === 0) {
      setShipIsText(dataArray.shipping);
    }

    if (dataArray.estimatedTax || dataArray.estimatedTax === 0) {
      setTax(dataArray.estimatedTax);
    }
    if (dataArray.handellingFees || dataArray.handellingFees === 0) {
      setHandleFee(dataArray.handellingFees);
    }
    if (dataArray.shippingChargeError) {
      setfailShip(dataArray.shippingChargeError);
      debouncedRefreshCallback(dataArray.shippingChargeError);
    } else {
      setfailShip(false);
    }
  }, [dataArray]);
  useEffect(() => {
    if (!isCart) {
      if (!finalShipPrice || isNaN(finalShipPrice)) {
        setFinalShipPrice(0);
      }
    }
  }, [finalShipPrice]);

  const updatePay = useMutation((post) => updatePayInfo(post, token), {
    onSuccess: (result) => {
      const obj = {};
      setOrderDetailInfoComp(result.data.data);
      obj.email = userInfo?.username;
      obj.dealerId = dealerId;
      mutation_cart.mutate(obj);
      if (result.data.success) {
        setCheckoutState("order");
        if (isPO) {
        }
      } else {
      }
    },
    onError: (error) => {},
  });
  const [paylod,setPaylod] =useState(false)
  const logt = useMutation((post) => logAPI(post, token), {
    onSuccess: (result) => {},
    onError: (error) => {},
  });

  const authAPI = useMutation((post) => authorizeNet(post), {
    onSuccess: (result) => {
      let dataPay = {
        apiName : "Authorize payment",
        payload : paylod,
        respose : result,
      }
      logt.mutate(dataPay)
      const obj = {
        id: orderDetailInfo.id,
        paymentStatus: false,
        paymentMethod: isPO ? "PO" : "card",
        paymentStart: start.toLocaleString(),
        paymentFinish: new Date().toLocaleString(),
        customerPONumber: isPOValue,
        profileInformationId: defaultCard.customerPaymentProfileId,
        customerProfileId: defaultCard.cusProfile,
      };
      if (result?.data?.transactionResponse?.responseCode === "1") {
        obj.paymentStatus = true;
        obj.paymentReference = result.data.transactionResponse.refTransID;
        obj.paymentProvider = result.data.transactionResponse.transId;
        obj.email = userInfo?.username;
        obj.paymentState = !isPO
          ? result.data.transactionResponse.responseCode
          : "";
        setPaymentStatus(result);
        // setCheckoutState("order");
      } else {
        setPaymentStatus(result);
        obj.paymentStatus = false;
        // showErrorToast(result?.data?.messages?.message[0]?.text);
        if(result?.data?.transactionResponse && result?.data?.transactionResponse?.errors && result?.data?.transactionResponse?.errors.length > 0){
          showErrorToast(result?.data?.transactionResponse?.errors[0]?.errorText);
        } else {
          showErrorToast('This transaction has been declined')
        }
        // setCheckoutState("order");
      }
      updatePay.mutate(obj);
    },
    onError: (error) => {

    },
  });

  const [cancelDialog, setCancelDialog] = useState(false);
  const [couponInput, setCouponInput] = useState("");
  const handleCancel = () => {
    setCancelDialog(!cancelDialog);
  };

  const createOrderAPI = useMutation((post) => createOrder(post, token), {
    onSuccess: (result) => {
      setSnote('')
      if (result.data.success) {
        setOrderFail(false);
        setOrderDetailInfo(result.data.data);
        setStart(new Date());
        let objLine = {}
        let arrLine = []
        if(myCart && myCart.items &&  myCart.items.length > 0){
          myCart.items.forEach((item)=>{
            let line = {}
            line.itemId = item?.partId
            line.name = item?.partName ? item?.partName.slice(0,20) : ''
            line.description = item?.partDescription ? item?.partDescription.slice(0,20) : ''
            line.quantity = item?.partQty
            line.unitPrice = item?.partDiscountCost
            arrLine.push(line)
          })
        }
        objLine.lineItem = arrLine
        const chargeProfile = {
          createTransactionRequest: {
            merchantAuthentication: {
              name: process.env.REACT_APP_MERCHANT_NAME,
              transactionKey: process.env.REACT_APP_MERCHANT_TRANSACTION_KEY,
            },
            refId: result.data.data.orderId,
            transactionRequest: {
              transactionType: "authCaptureTransaction",
              amount: dataArray?.estiamtedTotal.toFixed(2),
              profile: {
                customerProfileId: cusProfile,
                paymentProfile: {
                  paymentProfileId: defaultCard.customerPaymentProfileId,
                },
              },
              order : {
                invoiceNumber : result.data.data.orderId
              },
              lineItems:objLine,
            },
          },
        };
        if (isPO) {
          const obj = {
            id: result.data.data.id,
            paymentMethod: "PO",
            paymentStart: "",
            paymentFinish: "",
            email: userInfo?.username,
            customerPONumber: isPOValue,
            profileInformationId: "",
            customerProfileId: "",
            paymentStatus: true,
          };
          updatePay.mutate(obj);
        } else {
          setPaylod(chargeProfile)
          authAPI.mutate(chargeProfile);
        }
      } else {
        setOrderFail(true);
        showErrorToast(result?.data?.msg);
        setCheckoutState("order");
      }
    },
    onError: (error) => {
      setOrderFail(true);
      // showErrorToast(result?.data?.msg);
      setCheckoutState("order");
    },
  });

  const handleOkay = () => {
    setCancelDialog(false);
    const obj = {};
    obj.email = userInfo?.username;
    obj.dropShipFee = tempSummary.handellingFees ?? "0";
    obj.paymentMethod = isPO ? "PO" : "card";
    obj.shippingNote = snote ? snote : "";
    obj.shippingId =
      shippinPaymentAddressUpdated &&
      Object.values(shippinPaymentAddressUpdated).length > 0
        ? shippinPaymentAddressUpdated.id
        : "";
    if (isDomestic === "domestic") {
      obj.shipMethodLabel = shipPrice.label;

      obj.shipService = "ups";
      obj.shipMethodValue = shipPrice && shipPrice?.value?.split(" ")[0];
      obj.shipMethodPrice = shipPrice && shipPrice?.price ? shipPrice.price : 0;
      obj.shipMethodCode = shipPrice && shipPrice?.code ? shipPrice.code : "";
      if (dataArray.shipping === "TBD") {
        obj.shipMethodPrice = "TBD";
        obj.shipMethodValue = "";
        obj.shipMethodLabel = "";
      }
      if (dataArray.shipping === "FREE") {
        obj.shipMethodPrice = "FREE";
      }
    } else if (isDomestic === "inter") {
      obj.shipMethodLabel = service.label;
      obj.shipService = service?.value === "dhl" ? "dhl" : "ups";
      obj.shipMethodValue = service && service?.value?.split(" ")[0];
      obj.shipMethodPrice =
        shipInterOption && shipInterOption.TotalCharges
          ? shipInterOption.TotalCharges
          : 0;
      obj.shipMethodCode =
        shipInterOption && shipInterOption.Code ? shipInterOption.Code : "";
      if (dataArray.shipping === "TBD") {
        obj.shipMethodPrice = "TBD";
        obj.shipMethodValue = "";
        obj.shipMethodLabel = "";
      }
      if (dataArray.shipping === "FREE") {
        obj.shipMethodPrice = "";
      }
    }
    createOrderAPI.mutate(obj);
  };
  const coupon = useMutation((post) => applyCoupon(post, token), {
    onSuccess: (result) => {
      if (result.data.success) {
        handleRefreshCallBack();
      } else {
        setCload(false)
        showErrorToast(result.data.msg);
      }
    },
    onError: (error) => {
      setCload(false)
    },
  });

  const handleCoupon = (item) => {
    if (location.pathname === "/checkout") {
      setCload(true);
    }
    if (item === 1 && location.pathname === "/checkout") {
      setLow(true);
    }
    const obj = {};
    obj.email = userInfo?.username;
    obj.couponCode = cp ? cp : couponInput;
    obj.status = item;
    obj.isTBD = dataArray.shipping === "TBD" ? true : false;
    obj.shipping = dataArray.shipping;
    setCode(cp ? cp : couponInput);
    coupon.mutate(obj);
  };

  const handleCheckoutState = () => {
    setCheckActive(!checkActive)
    setAllow(true);
    if (!failShip) {
      if (currentPath.includes("cart")) {
        if (failMandate && quant) {
          const weight = dataArray.items
            .filter((item) => item.weight === "0")
            .map((item) => item.partName);
          const isStock = dataArray.items.reduce(
            (accumulator, currentValue) => {
              return (
                accumulator &&
                !(
                  currentValue.partStatus === "0 in stock." &&
                  !currentValue.isBackordered
                )
              );
            },
            true
          );
          const isNoPrice = dataArray.items.reduce(
            (accumulator, currentValue) => {
              return accumulator && !(currentValue.partDiscountCost === "0");
            },
            true
          );
          setIsPOValue("");
          if (weight.length === 0) {
            if (isStock && isNoPrice) {
              navigate({
                pathname: "/checkout",
              });
            } else {
              if (!isStock) {
                showErrorToast("Remove parts that are 0 in stock");
              } else {
                showErrorToast("Remove parts that have 0 price");
              }
            }
          } else {
            let str = "";
            weight.forEach((item) => {
              str += item + ",";
            });
            showErrorToast(
              `These parts have zero weight - ${str} Remove them to place the order`
            );
          }
        } else {
          if (!failMandate) {
            const inputFields = Array.from(document.querySelectorAll("input"));
            const inputFieldsWithoutValueAndAddRed = inputFields.filter(
              (input) =>
                input.value === "" && input.classList.contains("addRed")
            );
            inputFieldsWithoutValueAndAddRed.forEach((input) => {
              input.classList.add("red-border");
            });
            setTimeout(() => {
              inputFieldsWithoutValueAndAddRed.forEach((input) => {
                input.classList.remove("red-border");
              });
            }, 2000);
            showErrorToast(
              "Fill the mandatory additional infomation Fields for placing the order"
            );
          }
          if (!quant) {
            showErrorToast(
              "Update the quantity of parts according to the instock quantity available"
            );
          }
        }
      } else {
        if (checkoutState === "review") {
          // setCancelDialog(true);
          handleOkay();
        }
        if (checkoutState === "ship") {
          setCheckoutState("review");
        }
      }
      const obj = {
        email: userInfo.username,
        dealerId: dealerId,
        minicart: true,
      };
      mutation_cartMINI.mutate(obj);
    } else {
      showErrorToast(failShip);
    }
  };

  return (
    <div
      className={classnames("normaordersummary ", {
        padingForReviewOrder: location.pathname === "/checkout" && reviewOrder,
      })}
    >
      {(authAPI.isLoading ||
        updatePay.isLoading ||
        // feeMut.isLoading ||
        createOrderAPI.isLoading) && (
        // coupon.isLoading
        <>
          <Loader></Loader>
          <Loader></Loader>
        </>
      )}
      <IlmorModal
        isopen={cancelDialog}
        handleOkay={handleOkay}
        handleClose={handleCancel}
        message={"PROCEED?"}
        messageDetail={commonMessages.confirmPay}
        fisrtButton={"Cancel"}
        secondButton={"Confirm"}
      ></IlmorModal>
      {/* {getAuthourizeToken.isLoading && <Loader></Loader>} */}
      {/* {formToken &&
        <AcceptHosted formToken={formToken} integration="redirect">
          Continue to Redirect
        </AcceptHosted>
      } */}

      <div className="orderSummary tabeltLineSpacingUpShopping">
        <div
          // className="orderTitile fgebdgrbd"
          className={classnames("orderTitile", {
            fgebdgrbd: location.pathname === "/checkout" && !reviewOrder,
          })}
        >
          <span>Order Summary</span>
        </div>
        {
          <>
            <div
              className={classnames("orderPrices orderPricesMobileRight", {})}
            >
              <div className="orderSub">
                <span>Subtotal</span>
                {dataArray?.subTotal && !isNaN(dataArray?.subTotal) && (
                  <span>{`$${dataArray?.subTotal.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`}</span>
                )}
              </div>
              {cp && (
                <div className="orderSub orderSubRed">
                  {dataArray?.couponDiscount &&
                  !isNaN(dataArray?.couponDiscount) ? (
                    <>
                      <span>You Saved</span>
                      <span>{`$${dataArray?.couponDiscount.toFixed(2)}`}</span>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              )}
              <div className="orderSub">
                {isCart ? (
                  <span>Estimated Shipping</span>
                ) : (
                  <span>Shipping</span>
                )}
                <span>
                <span className="strike" >{`${dataArray.shipping === 'FREE' && dataArray?.shippingDiscount ? '$'+dataArray?.shippingDiscount : ''}  `}</span>
                  {` ${
                  isNaN(shipIsText)
                    ? shipIsText
                    : shipIsText
                    ? "$" + parseFloat(shipIsText).toFixed(2)
                    : "$0.00"
                } `}</span>
              </div>
              {tax ? (
                <div className="orderSub">
                  {isCart ? <span>Estimated Tax</span> : <span>Tax</span>}
                  <span>{`${tax ? "$" + addCommaDecimal(tax) : "$0.00"}`}</span>
                </div>
              ) : (
                <></>
              )}
              {(handleFee || dropFeestate) && !isCart ? (
                <div className="orderSub">
                  <span>Drop Ship Fee</span>
                  <span>{`${dropFeestate ? "$" + "20.00" : "$0"}`}</span>
                </div>
              ) : (
                <></>
              )}
              {/* {shipIsText ? (
            <div className="orderSub">
              {isCart ? <span>Estimated Shipping</span> : <span>Shipping</span>}
              <span>{`${shipIsText}`}</span>
            </div>
          ) : (
            <div className="orderSub">
              {isCart ? <span>Estimated Shipping</span> : <span>Shipping</span>}
              {(finalShipPrice || finalShipPrice === 0) &&
              !isNaN(parseFloat(finalShipPrice)) ? (
                <span>{`$${parseFloat(finalShipPrice).toFixed(2)}`}</span>
              ) : (
                <span>{`${finalShipPrice}`}</span>
              )}
            </div>
          )}
          <div className="orderSub">
            {isCart ? <span>Estimated Tax</span> : <span>Tax</span>}
            {(dataArray?.estimatedTax || dataArray?.estimatedTax === 0) &&
              !isNaN(dataArray?.estimatedTax) && (
                <span>{`$${dataArray?.estimatedTax.toFixed(2)}`}</span>
              )}
          </div> */}
            </div>
            <div
              className={classnames("orderTotal orderPricesMobileRight", {})}
            >
              <div className="orderSub">
                <span className="orderSubTitle">Estimated Total</span>
                {dataArray?.estiamtedTotal &&
                !isNaN(dataArray?.estiamtedTotal) &&
                location.pathname === "/yourcart" ? (
                  <span className="orderSubPrice">{`$${parseFloat(
                    dataArray?.estiamtedTotal
                  )
                    // parseFloat(finalShipPrice) +
                    // parseFloat(tax)
                    .toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}</span>
                ) : (
                  // .toFixed(2)
                  <span className="orderSubPrice">{`$${parseFloat(
                    dataArray?.estiamtedTotal + parseFloat(dropFee ?? 0)
                  )
                    // parseFloat(finalShipPrice) +
                    // parseFloat(tax)
                    .toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}</span>
                  // .toFixed(2)
                )}
              </div>
            </div>
            {!(!reviewOrder && currentPath.includes("checkout")) && (
              <div className="orderCoupan">
                <Accordion
                  className={classnames("accordianOrderCoupan ", {
                    orderDetailCoupan: orderDetail,
                  })}
                >
                  <AccordionSummary
                    // expandIcon={<ExpandMoreIcon />}
                    expandIcon={<ExpandMoreIcon />}
                    px
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classnames(
                      "accordianText orderPricesAccordianMobileRight",
                      {}
                    )}
                  >
                    <Typography
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "13px",
                        fontWeight: "700",
                      }}
                    >
                      Use Coupon Code
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className={classnames("coupanAccordian ", {
                      orderDetailCoupanDetail: orderDetail,
                    })}
                  >
                    {!cp ? (
                      <>
                        <InputBase
                          style={{
                            backgroundColor: "white",
                            border: "none",
                            outline: "none",
                            padding: "10px",
                            fontStyle: "italic",
                          }}
                          onChange={(e) => {
                            setCouponInput(e.target.value);
                          }}
                          placeholder="Enter Code"
                          // onChange={(e) => { setInput(e.target.value) }}
                          // value={input}
                          className="header__input__base notWidth borderColor"
                          inputProps={{ "aria-label": "search google maps" }}
                        />
                        {/* <button className="ilmorButon" >Apply</button> */}
                        {(loadcart1 ||
                          cload ||
                          load ||
                          coupon.isLoading ||
                          mutation_cart.isLoading) &&
                        couponInput ? (
                          <div className="loadcoup">
                            <CircularProgress
                              style={{ color: "#CE0E2D" }}
                              size={20}
                            />
                          </div>
                        ) : (
                          <button
                            onClick={() => {
                              handleCoupon(1);
                            }}
                            className="applyCoupan"
                          >
                            Apply
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        <div
                          // className="orderSub topMargin notopmargin"
                          className={classnames(
                            "orderSub fgh topMargin notopmargin",
                            {
                              appliedcouapn:
                                dataArray?.couponDiscount &&
                                location.pathname === "/checkout",
                            }
                          )}
                        >
                          <div className="orderSubTitleDiv">
                            <div className="sdfsrfgtdth">
                              <span className="orderSubTitle">{`${cp}`}</span>{" "}
                              <span className="sfdgebry">applied</span>
                            </div>
                            {!(
                              loadcart1 ||
                              load ||
                              cload ||
                              coupon.isLoading ||
                              mutation_cart.isLoading
                            ) ? (
                              <span
                                onClick={() => {
                                  handleCoupon(0);
                                }}
                                className="removeOrderPage"
                              >
                                Remove
                              </span>
                            ) : (
                              <CircularProgress
                                style={{ color: "#CE0E2D" }}
                                size={20}
                              />
                            )}
                          </div>
                          {dataArray?.couponDiscount &&
                          !isNaN(dataArray?.couponDiscount) ? (
                            <span className="orderSubPrice coupandiscounfont">{`-$${dataArray?.couponDiscount.toFixed(
                              2
                            )}`}</span>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    )}
                  </AccordionDetails>
                </Accordion>
              </div>
            )}
            {!reviewOrder && cp && currentPath.includes("checkout") && (
              <div className="orderCoupan">
                <>
                  <div
                    // className="orderSub topMargin topbottommarging"
                    className={classnames(
                      "orderSub topMargin sdfsdfsdf topbottommarging",
                      {
                        appliedcouapn:
                          cp &&
                          location.pathname === "/checkout" &&
                          reviewOrder,
                      }
                    )}
                  >
                    <div className="orderSubTitleDiv">
                      {/* <span className="orderSubTitle coupad">{`${cp} applied`}</span> */}
                      <div className="sdfsrfgtdth">
                        <span className="orderSubTitle">{`${cp}`}</span>{" "}
                        <span className="sfdgebry">applied</span>
                      </div>
                      {/* <span
                    onClick={() => {
                      handleCoupon(0);
                    }}
                    className="removeOrderPage"
                  >
                    Remove
                  </span> */}
                    </div>
                    {dataArray?.couponDiscount &&
                    !isNaN(dataArray?.couponDiscount) ? (
                      <span className="orderSubPrice coupandiscounfont">{`-$${dataArray?.couponDiscount.toFixed(
                        2
                      )}`}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              </div>
            )}{" "}
          </>

          // : (
          //   <div className="orderLoader">
          //     <CircularProgress style={{ color: "black" }} size={30} />
          //   </div>
          // )
        }
        {!orderDetail && !dealerId && (
          <>
            {reviewOrder &&
              myProfile?.profileInfo?.role !== "ServiceTechnician" && (
                <>
                  <Tooltip
                    placement="bottom"
                    title={
                      !isCardActive && !isPO
                        ?  "The selected card is Inactive. Choose an active card to proceed"
                        : (dataArray.shipping !== 'TBD' && isDomestic === "domestic" && !isDomesticOption) ? "Select a shipping method" :""
                    }
                  >
                    <button
                      onClick={handleCheckoutState}
                      disabled={
                        (!isPOValue?.trim() &&
                          isPO &&
                          !myProfile?.isTermThirty) ||
                        load ||
                        (dataArray.shipping !== 'TBD' && isDomestic === "domestic" && !isDomesticOption) ||
                        (!isCardActive && !isPO) ||
                        dataArray?.estiamtedTotal < 0
                      }
                      className="ilmorButon topMargin allowevent"
                    >
                      {currentDevice != "Desktop"
                        ? "Review Order"
                        : "Review & Place Order"}
                    </button>
                  </Tooltip>
                </>
              )}
            {!reviewOrder &&
              myProfile?.profileInfo?.role !== "ServiceTechnician" && (
                <div
                  className={classnames("paymentbutton", {
                    padBotCart: location.pathname === "/cart",
                  })}
                >
                  {/* <AcceptHosted style = {{border: 'none'}} formToken={authToken} integration="redirect"> */}
                  <Tooltip
                    placement="bottom"
                    title={
                      currentPath.includes("cart") && !change
                        ? "You have unsaved changes in your cart"
                        : ""
                    }
                  >
                    <button
                      onClick={handleCheckoutState}
                      disabled={loadcart || loadcart1 || currentPath.includes("cart") && !change}
                      className="ilmorButon topMargin"
                    >
                      {currentPath.includes("cart")
                        ? "Proceed to Checkout"
                        : "Place Order"}
                    </button>
                  </Tooltip>
                  {/* </AcceptHosted> */}
                </div>
              )}
          </>
        )}
      </div>
    </div>
  );
};

export default OrderSummary;
