import { Box ,Grid} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getShippingPolicy } from "../../api/posts";
import { useStore } from "../../store/home";
import Footer from "../Footer";
import Header from "../Header";
import {
  ShippingPolicyGreyHeading,
  ShippingPolicyHeading,
  ShippingPolicyParagraph,
} from "../StyledComponents/CommonStyled";

const ReturnExchange = () => {
  const { token } = useStore();
  const {
    data: returnExchangeData,
    error,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["getShippingPolicy"],
    retry: 0,
    refetchOnWindowFocus: false,
    queryFn: () => getShippingPolicy("return&exchange", token),
  });

  return (
    <>
      <Header page="Catalog"></Header>

      {returnExchangeData?.data?.data?.map((item) => {
        return (
          <>
          
            <ShippingPolicyGreyHeading
              sx={{
                backgroundColor: "#E4E4E4",
                padding: "20px 48px 20px 48px",
              }}
            >
            
              {item?.title}
            </ShippingPolicyGreyHeading>
          </>
        );
      })}

      <Grid
        container
        justifyContent="center"
        spacing={2}
        sx={{ wordBreak: "break-word", padding: "30px 0px 70px 0px" }}
      >
        <Grid item xs={8}>
          {returnExchangeData?.data?.data?.map((item) => {
            return (
              <>
              
                <Box dangerouslySetInnerHTML={{ __html: item?.contentData }} />
                {/* <ShippingPolicyParagraph>
              {item?.contentData}
            </ShippingPolicyParagraph> */}
              </>
            );
          })}
        </Grid>
      </Grid>

      <Footer />
    </>
  );
};

export default ReturnExchange;
