import React, { useRef } from "react";
import { useState } from "react";
import Select from "react-select";
import { Button } from "@material-ui/core";
import "./PartsCatalog.css";
import { useStore } from "../store/home";
import { useNonPersistedStore } from "../store/NonPersistedStore";
// import useCollapse from 'react-collapsed';
// import EastIcon from "@mui/icons-material/East";
import { ReactComponent as ViewPartArrow } from "../Images/arroWhite.svg";
import currenEngine from "../Images/currentEngine.png";
import { ReactComponent as Interchange } from "../Images/interchange.svg";
import { ReactComponent as Cross } from "../Images/cross.svg";
import { useMutation } from "@tanstack/react-query";
import {
  getAllDropdownOptions,
  getProductListCategory,
  searcPartsYMM,
} from "../api/posts";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { customStyles } from "../utils/ReactSelectCustomStyles";
import loaderImg from "../Images/loaderImg.gif";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { customStyles3 } from "../utils/ReactSelectCustomStylesPart";
import { Box } from "@mui/material";
import { green } from "@mui/material/colors";

const PartsCatalog = ({ isSearch, isHome }) => {
  const {
    setTRecord,
    setRecord,
    isCurrentEngSet,
    setPartListArray,
    setIsCurrentEngSet,
    setSelectedSubCategory,
    selectedSubCategory,
    dealerId,
    setBox,
    setOffset,
    setCategorySide,
    selectedCategory,
    token,
    currentDevice,
    setVals,
    setIsCurrentEngSetValue,
    isCurrentEngSetValue,
    partListArray,
    noPartLoad,
  } = useStore();
  const [visible, setVisible] = useState(false);

  const [yearValue, setYearValue] = useState("");
  const [typeValue, setTypeValue] = useState(null);
  const [modelValue, setModelValue] = useState(null);

  const navigate = useNavigate();
  const [year, setYear] = useState([]);
  const [type, setType] = useState([]);
  const [model, setModel] = useState([]);

  const { setnoPartLoad,setRefreshQ,refreshQ  } = useNonPersistedStore();
  const mutation11 = useMutation((post) => getProductListCategory(post), {
    mutationKey: "getProductListCategory",
    method: "GET",
    onSuccess: (result) => {
      setnoPartLoad(false);
      setCategorySide(result.data.data);
    },
    onError: (error) => {
      setnoPartLoad(false);
    },
  });

  // useEffect(() => {
  //   const data = {
  //     token: token,
  //     EngineId: isCurrentEngSet.engineId,
  //   };
  //   mutation11.mutate(data);
  // }, [isCurrentEngSet])

  // useEffect(() => {
  //   if (isCurrentEngSet && isCurrentEngSetValue) {
  //     navigate({
  //       pathname: "/catalog",
  //       search: `isShopByDiagram=${isCurrentEngSet.isShopByDiagram}&yearId=${isCurrentEngSetValue.year}&year=${isCurrentEngSet.year}&make=${isCurrentEngSet.make}&makeId=${isCurrentEngSetValue.make}&model=${isCurrentEngSet.model}&modelId=${isCurrentEngSetValue.model}&category=${selectedCategory}&subCategory=${selectedSubCategory}&engineId=${isCurrentEngSet.engineId}`,
  //     });
  //   }
  // }, [isCurrentEngSet,isCurrentEngSetValue]);

  const mutation1 = useMutation((post) => getAllDropdownOptions(post), {
    mutationKey: "getDropDownValues",
    method: "GET",
    onSuccess: (result) => {
      if (result?.data?.data?.year && !yearValue) {
        const options = Object.entries(result?.data?.data?.year).map(
          ([label, value]) => ({
            label,
            value,
          })
        );
        setYear(options.reverse());
      }
    },
    onError: (error) => {},
  });
  const mutation2 = useMutation((post) => getAllDropdownOptions(post), {
    mutationKey: "getDropDownValues",
    method: "GET",
    onSuccess: (result) => {
      if (result?.data?.data?.make && yearValue) {
        const options = Object.entries(result?.data?.data?.make).map(
          ([value, label]) => ({
            label,
            value,
          })
        );
        setType(options);
      }
    },
    onError: (error) => {},
  });
  const mutation3 = useMutation((post) => getAllDropdownOptions(post), {
    mutationKey: "getDropDownValues",
    method: "GET",
    onSuccess: (result) => {
      if (result?.data?.data?.make && yearValue && typeValue) {
        const options = Object.entries(result?.data?.data?.model).map(
          ([value, label]) => ({
            label,
            value,
          })
        );
        setModel(options);
      }
    },
    onError: (error) => {},
  });
  // const initialRender = useRef(true);
  useEffect(() => {
    // if (initialRender.current) {
    // initialRender.current = false;
    const params = {
      token: token,
      year: true,
      make: "",
    };
    mutation1.mutate(params);
    // return;
    // }
  }, []);
  useEffect(() => {
    if (yearValue) {
      const params = {
        token: token,
        year: yearValue.value,
        make: "",
      };
      mutation2.mutate(params);
    }
  }, [yearValue]);

  useEffect(() => {
    if (typeValue) {
      const params = {
        token: token,
        year: yearValue.value,
        make: typeValue.value,
      };
      mutation3.mutate(params);
    }
  }, [typeValue]);

  const isButtonDisabled = !yearValue || !typeValue || !modelValue;

  const handleOption1Change = (option) => {
    setYearValue(option);
    setTypeValue(null);
    setModelValue(null);
  };

  const handleOption2Change = (option) => {
    setTypeValue(option);
    setModelValue(null);
  };

  const handleOption3Change = (option) => {
    setModelValue(option);
  };

  const searchParts = useMutation((post) => searcPartsYMM(post, token), {
    onSuccess: (result) => {
      const obj = { ...isCurrentEngSet };
      obj.engineId = result.data.engineId;
      const data = {
        dealerId:dealerId,
        token: token,
        EngineId: result.data.engineId,
      };
      setRefreshQ(!refreshQ)
      setPartListArray(result.data.data);
      mutation11.mutate(data);
      obj.engineImage = result.data.engineImage;
      obj.isShopByDiagram = result.data.isShopByDiagram;
      setIsCurrentEngSet(obj);
      // setSelectedSubCategory("");
      // setSelectedCategory("");
      navigate({
        pathname: "/catalog",
        search: `isShopByDiagram=${obj.isShopByDiagram}&yearId=${
          isCurrentEngSetValue.year
        }&year=${obj.year}&make=${obj.make}&makeId=${
          isCurrentEngSetValue.make
        }&model=${obj.model}&modelId=${
          isCurrentEngSetValue.model
        }&category=${selectedCategory}&offset=${0}&subCategory=${selectedSubCategory}&engineId=${
          obj.engineId
        }`,
      });
    },
    onError: (error) => {},
  });
  const handleSave = () => {
    setnoPartLoad(true);
    setTRecord(0);
    setOffset(0);
    setBox(1);
    const arr = {
      year: yearValue.value,
      make: typeValue.value,
      model: modelValue.value,
      dealerId: dealerId,
      categoryId: selectedCategory,
      subcategoryId: selectedSubCategory,
    };
    const arrLabel = {
      year: yearValue.label,
      make: typeValue.label,
      model: modelValue.label,
    };

    setIsCurrentEngSet(arrLabel);
    setIsCurrentEngSetValue(arr);
    let arr1 = [];
    if (partListArray && partListArray.length > 0) {
      arr1 = partListArray.map((item) => {
        item.images = loaderImg;
        item.isLoad = true;
        return item;
      });
    }
    setRefreshQ(!refreshQ)
    setPartListArray(arr1);
    searchParts.mutate(arr);
  };
  return (
    <>
      <div className={`partsCat__main${currentDevice} partsCatCurrenrt`}>
        <div className={`partsCat__title1${currentDevice}`}>
          {isSearch ? (
            <b className={`partsCat__b${currentDevice}`}>SEARCH RESULTS</b>
          ) : (
            <>
              {isHome ? (
                <b className={`partsCat__b${currentDevice}`}>CURRENT ENGINE</b>
              ) : (
                <b className={`partsCat__b${currentDevice}`}>PARTS CATALOG</b>
              )}
            </>
          )}
          {isCurrentEngSet &&
          isCurrentEngSet?.isShopByDiagram !== "0" &&
          isCurrentEngSet?.isShopByDiagram !== "undefined" &&
          !noPartLoad &&
          isCurrentEngSet.isShopByDiagram ? (
            <div
              onClick={() => {
                navigate({
                  pathname: "/shopEngineDiagram",
                  search: `engineId=${isCurrentEngSet.engineId}&model=${isCurrentEngSet.model}&make=${isCurrentEngSet.make}&year=${isCurrentEngSet.year}`,
                });
              }}
              className="ilmorButon shopbyTab"
            >
              <span>Shop Engine Diagram</span>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className={`partsCat__findparts${currentDevice}`}>
          {!isCurrentEngSet && (
            <div className={`partsCat__title2${currentDevice}`}>
              <h2 className={`partsCat__h2${currentDevice}`}>
                FIND PARTS FOR A SPECIFIC ENGINE
              </h2>
            </div>
          )}
          {isCurrentEngSet && isCurrentEngSetValue ? (
            <div className="currentEngine">
              {/* <div className="currentImage">
                <img className="currentImagewidth" src={currenEngine} />
              </div> */}
              <div className="currentShopDetail">
                <span className="currentRedText">currently shopping</span>
                <span className="currEngineName">
                  {isCurrentEngSet.year}
                  {
                    <Box
                      component="span"
                      sx={{
                        display: "inline",
                        color: "#78797B",
                        margin: "0px 10px 0px 10px",
                        fontSize: "16px",
                      }}
                    >
                      |
                    </Box>
                  }
                  {isCurrentEngSet.make}
                  {
                    <Box
                      component="span"
                      sx={{
                        display: "inline",
                        color: "#78797B",
                        margin: "0px 10px 0px 10px",
                        fontSize: "16px",
                      }}
                    >
                      |
                    </Box>
                  }
                  {isCurrentEngSet.model}
                </span>
                <div className="currentShopButtons">
                  <div
                    onClick={() => {
                      setIsCurrentEngSet(false);
                      setIsCurrentEngSetValue(false);
                      setVals(false);
                      const data = {
                        token: token,
                        EngineId: "",
                      };
                      mutation11.mutate(data);
                      navigate({
                        search: `isShopByDiagram=${""}&yearId=${""}&year=${""}&make=${""}&makeId=${""}&model=${""}&modelId=${""}&engineId=${""}`,
                      });
                    }}
                    className="currentShopButtonDiv"
                  >
                    <Interchange className="icon"></Interchange>
                    <span className="currButonText">Change</span>
                  </div>
                  <div
                    onClick={() => {
                      setIsCurrentEngSet(false);
                      setIsCurrentEngSetValue(false);
                      setVals(false);
                      setYearValue("");
                      const data = {
                        token: token,
                        EngineId: "",
                      };
                      mutation11.mutate(data);
                      setTypeValue("");
                      setModelValue("");
                      navigate({
                        search: `isShopByDiagram=${""}&category=${selectedCategory}&subCategory=${selectedSubCategory}&yearId=${""}&s=${"partNumber"}&year=${""}&make=${""}&makeId=${""}&model=${""}&modelId=${""}&engineId=${""}`,
                      });
                    }}
                    className="currentShopButtonDiv"
                  >
                    <Cross className="icon"></Cross>
                    <span className="currButonText">Remove</span>
                  </div>
                </div>
              </div>
              {isCurrentEngSet &&
              !noPartLoad &&
              isCurrentEngSet?.isShopByDiagram !== "0" &&
              isCurrentEngSet?.isShopByDiagram !== "undefined" &&
              isCurrentEngSet?.isShopByDiagram ? (
                <div
                  onClick={() => {
                    navigate({
                      pathname: "/shopEngineDiagram",
                      search: `engineId=${isCurrentEngSet.engineId}&model=${isCurrentEngSet.model}&make=${isCurrentEngSet.make}&year=${isCurrentEngSet.year}`,
                    });
                  }}
                  className="ilmorButon margiCurr shopbyDesk minwdthSop"
                >
                  <span>Shop Engine Diagram</span>
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <div className={`partsCat__boxesDesktop`}>
              <Select
                // className={`partsCat__boxDesktop`}
                styles={customStyles3}
                value={yearValue}
                isSearchable={false}
                placeholder="Model Year"
                isLoading={mutation1.isLoading}
                onChange={handleOption1Change}
                options={year}
                // menuIsOpen ={true}
              />
              <Select
                isSearchable={false}
                styles={customStyles3}
                className={`${!yearValue ? " findPartsBoxdisabled" : ""}`}
                // className={`partsCat__boxDesktop${
                //   !yearValue ? " partsCatBoxdisabled" : ""
                // }`}
                value={typeValue}
                placeholder="Engine Type"
                onChange={handleOption2Change}
                isLoading={mutation2.isLoading}
                options={type}
                isDisabled={!yearValue}
              />
              <Select
                isSearchable={false}
                styles={customStyles3}
                className={`${!typeValue ? " findPartsBoxdisabled" : ""}`}
                // className={`partsCat__boxDesktop${
                //   !typeValue ? " partsCatBoxdisabled" : ""
                // }`}
                value={modelValue}
                placeholder="Engine Model"
                isLoading={mutation3.isLoading}
                onChange={handleOption3Change}
                options={model}
                isDisabled={!typeValue}
              />
              <button
                className={`partsCat__btnDesktop${
                  isButtonDisabled ? " partsCatBtndisabled" : " viewPartsActive"
                }`}
                onClick={handleSave}
                disabled={isButtonDisabled}
              >
                <span className={`viewPartsTextDesktop`}>View Parts</span>
                {/* <EastIcon className={`eastIcon$Desktop`} /> */}
                <ViewPartArrow className={`revers viewPartsArrow$Desktop`} />
              </button>
            </div>
          )}
        </div>

        {/*===========> COLLAPSIBLE FIND PARTS HERE CODE FOR MOBILE <==================  */}

        {isCurrentEngSet ? (
          <div className="currentEngine MobilwComp">
            {/* <div className="currentImage">
              <img className="currentImagewidth" src={currenEngine} />
            </div> */}
            <div className="currentShopDetail">
              <span className="currentRedText">currently shopping</span>
              <span className="currEngineName">
                {`${isCurrentEngSet.year} | ${isCurrentEngSet.make} | ${isCurrentEngSet.model}`}
              </span>
              <div className="currentShopButtons">
                <div
                  onClick={() => {
                    setIsCurrentEngSet(false);
                    setIsCurrentEngSetValue(false);
                    setVals(false);
                    const data = {
                      token: token,
                      EngineId: "",
                    };
                    mutation11.mutate(data);
                    navigate({
                      search: `isShopByDiagram=${""}&yearId=${""}&year=${""}&make=${""}&makeId=${""}&model=${""}&modelId=${""}&engineId=${""}`,
                    });
                  }}
                  className="currentShopButtonDiv"
                >
                  <Interchange className="icon"></Interchange>
                  <span className="currButonText">Change</span>
                </div>
                <div
                  onClick={() => {
                    setIsCurrentEngSet(false);
                    setIsCurrentEngSetValue(false);
                    setVals(false);
                    const data = {
                      token: token,
                      EngineId: "",
                    };
                    mutation11.mutate(data);
                    navigate({
                      search: `isShopByDiagram=${""}&category=${selectedCategory}&subCategory=${selectedSubCategory}&yearId=${""}&s=${"partNumber"}&year=${""}&make=${""}&makeId=${""}&model=${""}&modelId=${""}&engineId=${""}`,
                    });
                  }}
                  className="currentShopButtonDiv"
                >
                  <Cross className="icon"></Cross>
                  <span className="currButonText">Remove</span>
                </div>
              </div>
              {isCurrentEngSet &&
              !noPartLoad &&
              isCurrentEngSet?.isShopByDiagram !== "0" &&
              isCurrentEngSet?.isShopByDiagram !== "undefined" &&
              isCurrentEngSet?.isShopByDiagram ? (
                <div
                  onClick={() => {
                    navigate({
                      pathname: "/shopEngineDiagram",
                      search: `engineId=${isCurrentEngSet.engineId}&model=${isCurrentEngSet.model}&make=${isCurrentEngSet.make}&year=${isCurrentEngSet.year}`,
                    });
                  }}
                  className="ilmorButon margiCurr shopbyMob"
                >
                  <span>Shop Engine Diagram</span>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <div
            className={`partsCat__findpartsCollapseExp${currentDevice} mobilebot`}
          >
            <Accordion className="accpart">
              <AccordionSummary
                className={` nopaddingImportant`}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div
                  className={`partsCat__findpartsCollapseExph2${currentDevice}`}
                >
                  FIND PARTS FOR A SPECIFIC ENGINE
                </div>
                {/* <Typography>Accordion Title</Typography> */}
              </AccordionSummary>
              <AccordionDetails>
                {!isCurrentEngSet && (
                  <div
                    className={`partsCat__findpartsCollapseExp ${currentDevice}`}
                  >
                    <div className={`partsCat__boxes${currentDevice}`}>
                      <Select
                        styles={customStyles3}
                        // className={`partsCat__boxDesktop`}
                        value={yearValue}
                        placeholder="Model Year"
                        onChange={handleOption1Change}
                        options={year}
                        isSearchable={false}
                        // menuIsOpen ={true}
                      />
                      <Select
                        styles={customStyles3}
                        isSearchable={false}
                        className={`${
                          !yearValue ? " findPartsBoxdisabled" : ""
                        }`}
                        // className={`partsCat__boxDesktop${
                        //   !yearValue ? " partsCatBoxdisabled" : ""
                        // }`}
                        value={typeValue}
                        placeholder="Engine Type"
                        onChange={handleOption2Change}
                        options={type}
                        isDisabled={!yearValue}
                      />
                      <Select
                        styles={customStyles3}
                        className={`${
                          !typeValue ? " findPartsBoxdisabled" : ""
                        }`}
                        // className={`partsCat__boxDesktop${
                        //   !typeValue ? " partsCatBoxdisabled" : ""
                        // }`}
                        value={modelValue}
                        placeholder="Engine Model"
                        onChange={handleOption3Change}
                        options={model}
                        isSearchable={false}
                        isDisabled={!typeValue}
                      />
                      <button
                        className={`partsCat__btn${currentDevice}${
                          isButtonDisabled
                            ? " partsCatBtndisabled"
                            : " viewPartsActive"
                        }`}
                        onClick={handleSave}
                        disabled={isButtonDisabled}
                      >
                        <span className={`viewPartsText${currentDevice}`}>
                          View Parts{" "}
                        </span>
                        {/* <EastIcon className={`eastIcon${currentDevice}`} /> */}
                        <ViewPartArrow
                          className={`revers viewPartsArrow$Desktop`}
                        />
                      </button>
                    </div>
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
          </div>
        )}
      </div>
    </>
  );
};

export default PartsCatalog;
