import "./OrderSummary.css";
import { ReactComponent as Radio } from "../Images/radioButton.svg";
import { ReactComponent as RadioEmpty } from "../Images/radioEmpty.svg";
import { ReactComponent as Visa } from "../Images/visa.svg";
import InputBase from "@mui/material/InputBase";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useStore } from "../store/home";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { authorizeNetTokenApi } from "../api/posts";
import { useMutation } from "@tanstack/react-query";
import Loader from "../commenComp/Loader";
import { useEffect, useState } from "react";
import classnames from "classnames";
import {
  applyCoupon,
  authorizeNet,
  createOrder,
  getMyCart,
  updatePayInfo,
} from "../api/posts";
import IlmorModal from "../commenComp/IlmorModal";
import commonMessages from "../commenComp/CommonMessages";
import { showErrorToast } from "../utils/Toaster";
import { addCommaDecimal } from "../commonFunction";
import { Box } from "@mui/material";

const OrderSummaryOrder = ({
  reviewOrder,
  orderDetail,
  dataArray,
  orderDetailData,
  handleRefreshCallBack,
}) => {
  const [start, setStart] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  const {
    checkoutState,
    setCheckoutState,
    setPaymentStatus,
    shipPrice,
    isPOValue,
    service,
    dealerId,
    token,
    userInfo,
    cusProfile,
    isPO,
    defaultCard,
    orderDetailInfo,
    setOrderDetailInfo,
    setMyCartMini,
    shippinPaymentAddressUpdated,
    currentDevice,
  } = useStore();
  const [handleFee, setHandleFee] = useState(0);

  const email = userInfo.username;
  const mutation_cart = useMutation((post) => getMyCart(post, token), {
    onSuccess: (result) => {
      setMyCartMini(result);
    },
    onError: (error) => {},
  });
  useEffect(() => {
    const obj = {
      email: userInfo.username,
      dealerId: dealerId,
      minicart: true,
    };
    mutation_cart.mutate(obj);
  }, []);

  const updatePay = useMutation((post) => updatePayInfo(post, token), {
    onSuccess: (result) => {
      const obj = {
        email: userInfo.username,
        dealerId: dealerId,
        minicart: true,
      };
      mutation_cart.mutate(obj);
      if (result.data.success) {
        if (isPO) {
          setCheckoutState("order");
        }
      } else {
      }
    },
    onError: (error) => {},
  });

  const authAPI = useMutation((post) => authorizeNet(post), {
    onSuccess: (result) => {
      const obj = {
        id: orderDetailInfo.id,
        paymentMethod: isPO ? "PO" : "card",
        paymentStart: start.toLocaleString(),
        paymentFinish: new Date().toLocaleString(),
        customerPONumber: isPOValue,
        profileInformationId: defaultCard.customerPaymentProfileId,
        customerProfileId: defaultCard.cusProfile,
      };
      if (result.data.messages.resultCode === "Ok") {
        obj.paymentStatus = true;
        obj.paymentReference = result.data.transactionResponse.refTransID;
        obj.paymentProvider = result.data.transactionResponse.transId;
        obj.paymentState = !isPO
          ? result.data.transactionResponse.responseCode
          : "";
        setPaymentStatus(result);
        setCheckoutState("order");
      } else {
        setPaymentStatus(result);
        obj.paymentStatus = false;
        showErrorToast(result.data.messages.message[0].text);
        setCheckoutState("order");
      }
      updatePay.mutate(obj);
    },
    onError: (error) => {},
  });

  const [cancelDialog, setCancelDialog] = useState(false);
  const [couponInput, setCouponInput] = useState("");
  const handleCancel = () => {
    setCancelDialog(!cancelDialog);
  };

  const createOrderAPI = useMutation((post) => createOrder(post, token), {
    onSuccess: (result) => {
      if (result.data.success) {
        setOrderDetailInfo(result.data.data);
        setStart(new Date());
        const chargeProfile = {
          createTransactionRequest: {
            merchantAuthentication: {
              name: process.env.REACT_APP_MERCHANT_NAME,
              transactionKey: process.env.REACT_APP_MERCHANT_TRANSACTION_KEY,
            },
            refId: orderDetailInfo.orderId,
            transactionRequest: {
              transactionType: "authCaptureTransaction",
              amount: dataArray?.estiamtedTotal.toFixed(2),
              profile: {
                customerProfileId: cusProfile,
                paymentProfile: {
                  paymentProfileId: defaultCard.customerPaymentProfileId,
                },
              },
              // lineItems: {
              //   lineItem: {
              //     itemId: "1",
              //     name: "vase",
              //     description: "Cannes logo",
              //     quantity: "18",
              //     unitPrice: "45.00"
              //   }
              // },
              // processingOptions: {
              //   isSubsequentAuth: "true"
              // },
              // subsequentAuthInformation: {
              //   originalNetworkTransId: "123456789123456",
              //   originalAuthAmount: "45.00",
              //   reason: "resubmission"
              // },
              // authorizationIndicatorType: {
              //   authorizationIndicator: "final"
              // }
            },
          },
        };
        if (isPO) {
          const obj = {
            id: orderDetailInfo.id,
            paymentMethod: "PO",
            paymentStart: "",
            paymentFinish: "",
            customerPONumber: isPOValue,
            profileInformationId: "",
            customerProfileId: "",
            paymentStatus: true,
          };
          updatePay.mutate(obj);
        } else {
          authAPI.mutate(chargeProfile);
        }
      } else {
        showErrorToast(result?.data?.msg);
      }
    },
    onError: (error) => {},
  });

  const handleOkay = () => {
    setCancelDialog(false);
    const obj = {};
    obj.email = userInfo?.username;
    obj.shippingId =
      shippinPaymentAddressUpdated &&
      Object.values(shippinPaymentAddressUpdated).length > 0
        ? shippinPaymentAddressUpdated.id
        : "";
    if (!service) {
      obj.shipMethodLabel = shipPrice.label;
      obj.shipMethodValue = shipPrice && shipPrice.value.split(" ")[0];
      obj.shipMethodPrice = shipPrice.price;
    } else {
      obj.shipMethodLabel = service.label;
      obj.shipMethodValue = service && service.value.split(" ")[0];
      obj.shipMethodPrice = shipPrice.price;
    }
    createOrderAPI.mutate(obj);
  };

  const coupon = useMutation((post) => applyCoupon(post, token), {
    onSuccess: (result) => {
      if (result.data.success) {
        handleRefreshCallBack();
      } else {
        showErrorToast(result.data.msg);
      }
    },
    onError: (error) => {},
  });

  const handleCoupon = (item) => {
    const obj = {};
    obj.email = userInfo?.username;
    obj.couponCode = dataArray?.coupon ? dataArray?.coupon : couponInput;
    obj.status = item;
    coupon.mutate(obj);
  };

  const handleCheckoutState = () => {
    if (currentPath.includes("cart")) {
      navigate({
        pathname: "/checkout",
      });
    } else {
      if (checkoutState === "review") {
        setCancelDialog(true);
      }
      if (checkoutState === "ship") {
        setCheckoutState("review");
      }
    }
  };

  function extractValue(str) {
    const regex = /\(([^)]+)\)/; // Matches any characters except ) within parentheses
    const match = regex.exec(str);
    return match ? match[1] : null; // Return the captured value or null if not found
  }

  return (
    <div
      className={classnames(" ", {
        orderpadMod: location.pathname !== "/account",
      })}
    >
      {(authAPI.isLoading || createOrderAPI.isLoading || coupon.isLoading) && (
        <Loader></Loader>
      )}
      <IlmorModal
        isopen={cancelDialog}
        handleOkay={handleOkay}
        handleClose={handleCancel}
        message={commonMessages.confirmPay}
        fisrtButton={"No"}
        secondButton={"Yes"}
      ></IlmorModal>
      {/* {getAuthourizeToken.isLoading && <Loader></Loader>} */}
      {/* {formToken &&
        <AcceptHosted formToken={formToken} integration="redirect">
          Continue to Redirect
        </AcceptHosted>
      } */}
      <div className="orderSummary ordertopPadding">
        <div className="orderTitile">
          <span>Order Summary</span>
        </div>
        <div className="orderPrices orderPricesMobileRight">
          <div className="orderSub ">
            <span>Subtotal</span>
            {orderDetailData?.subTotal && !isNaN(orderDetailData?.subTotal) && (
              <span>{`$${parseFloat(orderDetailData?.subTotal).toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}`}</span>
            )}
          </div>
          {/* return code */}

          {orderDetailData?.orderStatus === "Denied" ? (
            <></>
          ) : (
            <></>
            // <div className="orderSub ">
            //   {orderDetailData?.pendingCredit > 0 &&
            //   !isNaN(orderDetailData?.pendingCredit) ? (
            //     <span>Pending Credit1</span>
            //   ) : (
            //     <></>
            //   )}
            //   {orderDetailData?.pendingCredit &&
            //   !isNaN(orderDetailData?.pendingCredit) ? (
            //     <span>{`$${parseFloat(
            //       orderDetailData?.pendingCredit
            //     ).toLocaleString(undefined, {
            //       minimumFractionDigits: 2,
            //       maximumFractionDigits: 2,
            //     })}`}</span>
            //   ) : (
            //     <></>
            //   )}
            // </div>
          )}
          {orderDetailData?.couponDiscount &&
          parseInt(orderDetailData?.couponDiscount) ? (
            <div className="orderSub orderSubRed">
              <span>You Saved</span>
              {orderDetailData?.couponDiscount &&
              !isNaN(orderDetailData?.couponDiscount) ? (
                <span>{`$${parseFloat(orderDetailData?.couponDiscount).toFixed(
                  2
                )}`}</span>
              ) : (
                <span>$0</span>
              )}
            </div>
          ) : (
            <></>
          )}
          {orderDetailData?.shipping !== 0 &&
            orderDetailData?.shipping !== null && (
              <div className="orderSub">
                <span>Shipping</span>
                {(orderDetailData.shipping || orderDetailData.shipping === 0) &&
                !isNaN(parseFloat(orderDetailData.shipping)) ? (
                  <span>{`$${addCommaDecimal(orderDetailData.shipping)}`}</span>
                ) : (
                  <span>
                    <span className="strike">{`${
                      orderDetailData.shipping === "FREE" &&
                      orderDetailData.shippingMethod &&
                      orderDetailData.shippingMethod.includes("$")
                        ? extractValue(orderDetailData.shippingMethod)
                        : ""
                    } `}</span>
                    {`${orderDetailData.shipping}`}
                  </span>
                )}
              </div>
            )}
          {orderDetailData.estimatedTax ? (
            <div className="orderSub">
              <span>Tax</span>
              {(orderDetailData?.estimatedTax ||
                orderDetailData?.estimatedTax === 0) &&
                !isNaN(orderDetailData?.estimatedTax) && (
                  <span>{`$${addCommaDecimal(
                    orderDetailData?.estimatedTax
                  )}`}</span>
                )}
            </div>
          ) : (
            <></>
          )}
          {orderDetailData.handellingFees ? (
            <div className="orderSub">
              <span>Drop Ship Fee</span>
              <span>{`${
                orderDetailData.handellingFees
                  ? "$" + orderDetailData.handellingFees?.toFixed(2)
                  : "$0"
              }`}</span>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="orderTotal">
          <div className="orderSub orderPricesMobileRight nopaddinfgissue">
            <span className="orderSubTitle">Estimated Total</span>
            {orderDetailData?.total && !isNaN(orderDetailData?.total) && (
              <span className="orderSubPrice  mediaqueryEstmatedTotalMob">{`$${parseFloat(
                orderDetailData?.total
              ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}</span>
            )}
          </div>
        </div>
        {orderDetailData?.newTotal &&
        false &&
        !isNaN(orderDetailData?.newTotal) &&
        orderDetailData?.orderStatus != "Denied" ? (
          <>
            <div className="orderTotal">
              {/* return code */}
              <div className="orderSub orderPricesMobileRight nopaddinfgissue">
                <span className="orderSubTitle">Refund Total</span>
                {orderDetailData?.newTotal &&
                  !isNaN(orderDetailData?.newTotal) && (
                    <span className="orderSubPrice coupandiscounfont mediaqueryEstmatedTotalMob">{`$${parseFloat(
                      orderDetailData?.newTotal
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}</span>
                  )}
              </div>
            </div>
          </>
        ) : (
          <></>
          // <div className="orderTotal">
          //   <div className="orderSub orderPricesMobileRight nopaddinfgissue">
          //     <span className="orderSubTitle">Estimated Total</span>
          //     {orderDetailData?.total && !isNaN(orderDetailData?.total) && (
          //       <span className="orderSubPrice  mediaqueryEstmatedTotalMob">{`$${parseFloat(
          //         orderDetailData?.total
          //       ).toLocaleString(undefined, {
          //         minimumFractionDigits: 2,
          //         maximumFractionDigits: 2,
          //       })}`}</span>
          //     )}
          //   </div>
          // </div>
        )}
        {orderDetailData?.coupon && (
          <div className="orderCoupan">
            <Accordion
              className={classnames("accordianOrderCoupan ", {
                orderDetailCoupan: orderDetail,
              })}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                px
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="accordianText"
              >
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "13px",
                    fontWeight: "700",
                    fontSize: "13px",
                  }}
                >
                  Use Coupon Code
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                className={classnames("coupanAccordian bagroundgrey", {
                  orderDetailCoupanDetail: orderDetail,
                })}
              >
                {
                  <>
                    <div className="orderSub topMargin notopmargin">
                      <div className="orderSubTitleDiv ordepad">
                        <div>
                          <span className="orderSubTitle ">{`${orderDetailData?.coupon} `}</span>
                          <span className="sdfddsfsdfsd">applied</span>
                        </div>
                        {/* <span
                        onClick={() => {
                          handleCoupon(0);
                        }}
                        className="removeOrderPage"
                      >
                        Remove
                      </span> */}
                      </div>
                      {orderDetailData?.couponDiscount &&
                        !isNaN(orderDetailData?.couponDiscount) && (
                          <span className="orderSubPrice coupandiscounfont">{`-$${parseFloat(
                            orderDetailData?.couponDiscount
                          ).toFixed(2)}`}</span>
                        )}
                    </div>
                  </>
                }
              </AccordionDetails>
            </Accordion>
          </div>
        )}
        {orderDetailData?.orderStatus == "Return Initiated" ||
        orderDetailData?.orderStatus == "Return Completed" ? (
          <Box
            className="orderSummary ordertopPadding"
            sx={{ marginTop: currentDevice !== "Mobile" ? "90px" : "" }}
          >
            <Box className="orderTitile">
              <Box component="span" sx={{ display: "inline" }}>
                {" "}
                Return Summary
              </Box>
            </Box>
            <div className="orderPrices orderPricesMobileRight">
              {orderDetailData?.orderStatus == "Denied" ? (
                <></>
              ) : (
                <div className="orderSub ">
                  {orderDetailData?.pendingCredit > 0 &&
                  !isNaN(orderDetailData?.pendingCredit) ? (
                    <span>Pending Credit</span>
                  ) : (
                    <></>
                  )}
                  {orderDetailData?.pendingCredit &&
                  !isNaN(orderDetailData?.pendingCredit) ? (
                    <span>{`$${parseFloat(
                      orderDetailData?.pendingCredit
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}</span>
                  ) : (
                    <></>
                  )}
                </div>
              )}
              {orderDetailData?.returnTotalDiscount !== 0 &&
                orderDetailData?.returnTotalDiscount !== null && (
                  <div className="orderSub orderSubRed">
                    <span>You Saved</span>
                    {(orderDetailData?.returnTotalDiscount ||
                      orderDetailData?.returnTotalDiscount === 0) &&
                    !isNaN(parseFloat(orderDetailData?.returnTotalDiscount)) ? (
                      <span>{`$${addCommaDecimal(
                        orderDetailData?.returnTotalDiscount
                      )}`}</span>
                    ) : (
                      <span>{`${orderDetailData?.returnTotalDiscount}`}</span>
                    )}
                  </div>
                )}
              {orderDetailData?.shipping !== 0 && false &&
                orderDetailData?.shipping !== null && (
                  <div className="orderSub">
                    <span>Shipping</span>
                    {(orderDetailData.shipping ||
                      orderDetailData.shipping === 0) &&
                    !isNaN(parseFloat(orderDetailData.shipping)) ? (
                      <span>{`$${addCommaDecimal(
                        orderDetailData.shipping
                      )}`}</span>
                    ) : (
                      <span>{`${orderDetailData.shipping}`}</span>
                    )}
                  </div>
                )}
              {orderDetailData?.returnTotalTax !== 0 &&
                orderDetailData?.returnTotalTax !== null && (
                  <div className="orderSub">
                    <span>Tax</span>
                    {(orderDetailData?.returnTotalTax ||
                      orderDetailData?.returnTotalTax === 0) &&
                    !isNaN(parseFloat(orderDetailData?.returnTotalTax)) ? (
                      <span>{`$${addCommaDecimal(
                        orderDetailData?.returnTotalTax
                      )}`}</span>
                    ) : (
                      <span>{`${orderDetailData?.returnTotalTax}`}</span>
                    )}
                  </div>
                )}
            </div>

            {orderDetailData?.newTotal &&
            !isNaN(orderDetailData?.newTotal) &&
            orderDetailData?.orderStatus != "Denied" ? (
              <>
                <div className="orderTotal">
                  <div className="orderSub orderPricesMobileRight nopaddinfgissue">
                    <span className="orderSubTitle">Refund Total</span>
                    {orderDetailData?.newTotal &&
                      !isNaN(orderDetailData?.newTotal) && (
                        <span className="orderSubPrice coupandiscounfont mediaqueryEstmatedTotalMob">{`$${parseFloat(
                          orderDetailData?.newTotal
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`}</span>
                      )}
                  </div>
                </div>
              </>
            ) : (
              <div className="orderTotal"></div>
            )}

            {!orderDetail && (
              <>
                {reviewOrder && (
                  <button
                    onClick={handleCheckoutState}
                    disabled={!isPOValue && isPO}
                    className="ilmorButon topMargin"
                  >
                    Review & Place Order
                  </button>
                )}
                {!reviewOrder && (
                  <div className="paymentbutton">
                    {/* <AcceptHosted style = {{border: 'none'}} formToken={authToken} integration="redirect"> */}
                    <button
                      onClick={handleCheckoutState}
                      className="ilmorButon topMargin"
                    >
                      {currentPath.includes("cart")
                        ? "Proceed to Checkout"
                        : "Place Order"}
                    </button>
                    {/* </AcceptHosted> */}
                  </div>
                )}
              </>
            )}
          </Box>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default OrderSummaryOrder;
