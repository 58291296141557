import React, { useRef } from "react";
import {
  StyledRequiredField,
  StyledShippingHeading,
  StyledShippingSubHeading,
} from "../StyledComponents/ShippingAddressStyled";
import { Box, Checkbox, Divider, Grid } from "@mui/material";
import { useNonPersistedStore } from "../../store/NonPersistedStore";
import { Radio } from "@material-ui/core";
import { useStore } from "../../store/home";
import { ContainerDivStyled } from "../StyledComponents/BusinessDetailsStyled";
import { ErrorMessageStyled } from "../StyledComponents/CommonStyled";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DevTool } from "@hookform/devtools";
import { ReactComponent as Visa } from "../../Images/visa.svg";
import { ReactComponent as Cross } from "../../Images/crossRed.svg";
import Loader from "../../commenComp/Loader";
import { ReactComponent as American } from "../../Images/master.svg";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import { ReactComponent as Discover } from "../../Images/discover.svg";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select from "react-select";
import { ReactComponent as Question } from "../../Images/questionMark.svg";
import { styled } from "@material-ui/core/styles";
import { useMutation } from "@tanstack/react-query";
import { authorizeNet, getStateCity, storePay } from "../../api/posts";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import { customStyles } from "../../utils/ReactSelectCustomStyles";
import { useEffect } from "react";
import { useState } from "react";
import commonMessages from "../../commenComp/CommonMessages";
import IlmorModal from "../../commenComp/IlmorModal";
import TabletViewBlackContainer from "../TabletViewBlackContainer";
import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";

const EditPaymentMethod = () => {
  const schema = yup.object().shape({
    name: yup
      .string()
      .min(1, "Card Holder name must have at least 1 character")
      .max(50, "Card Holder name can have at most 50 characters")
      .required("Card Holder Name is required"),
    addressLine1: yup
      .string()
      .min(1, "Address must have at least 1 character")
      .max(60, "Address can have at most 60 characters")
      .required("Address is required"),
    countryRegion: yup
      .string()
      .min(1, "Country must have at least 1 character")
      .max(60, "Country can have at most 60 characters")
      .required("Country is required"),
    StateProvince: yup
      .string()
      .min(1, "State must have at least 1 character")
      .max(40, "State can have at most 40 characters")
      .required("State is required"),
    city: yup
      .string()
      .min(1, "City must have at least 1 character")
      .max(40, "City can have at most 40 characters")
      .required("City is required"),
    zipPostalCode: yup
      .string()
      .min(1, "City must have at least 1 character")
      .max(20, "City can have at most 20 characters")
      .required("City is required"),
    cardNumber: yup
      .string()
      .required("Card number is required"),
    //   .matches(
    //     /^[0-9 ]+$/,
    //     "Card Number should only contain numbers and spaces"
    //   )
    //   .test("valid-card-number", "Card Number is not valid", (value) =>
    //     validateCardNumber(value)
    //   )
    //   .required("Card Number is required"),
    expiryMonth: yup.string().required("Expiry Month is required"),
    expiryYear: yup.string().required("Expiry Year is required"),
    security: yup
      .string()
      .matches(/^\d{3,4}$/, "CVV must be 3 or 4 digits long")
      .required("Security Code is required"),
  });
  const [selectedValue, setSelectedValue] = useState("a");
  const [card, getCard] = useState(false);
  const stateRef = useRef();
  const [nationalNumber, setNationalNumber] = useState("");
  const [completePhoneNumber, setCompletePhoneNumber] = useState("");
  const [countrySelectOption, setCountrySelectOption] = useState();
  const [conty, setCountry] = useState("");
  const [stateval, setStateVal] = useState("");
  const [stateCityData, setStateCityData] = useState([]);
  const handleCountrySelectChange = (value) => {
    stateRef.current.setValue("StateProvince", null);
    setCountry(countrySelectOption?.find((option) => option.label === value));
    if (value) {
      getStateCityMutation.mutate({ countryID: value });
    }
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const [flagFirstRender, setflagflagFirstRender] = useState(true);

  useEffect(() => {
    if (myProfile?.countryList && card) {
      setCountrySelectOption(myProfile?.countryList);
    }

  }, [card]);

  const getStateCityMutation = useMutation(
    (post) => getStateCity(post, token),
    {
      onSuccess: (result) => {
        if (result) {
          if (result.data.success) {
            // setValue("StateProvince", 56);
            if (flagFirstRender) {
              setValue("StateProvince", card?.billTo?.state);

              setStateVal(
                result?.data?.data?.find(
                  (option) => option.label === card?.billTo?.state
                )
              );
              setflagflagFirstRender(false);
            }
            showSuccessToast(result?.data?.msg);
            setStateCityData(result?.data?.data);
          } else {
            showErrorToast(result?.data?.msg);
          }
        }
      },
      onError: (error) => {},
    }
  );
  const currentYear = new Date().getFullYear();
  // const StyledRadio = styled(Radio)({
  //   color: "black", // Change the color here
  //   "&.Mui-checked": {
  //     color: "black", // Change the color for the checked state
  //   },
  // });

  const handlePhoneNumberChange = (data, field) => {
    if (!data) {
      setCompletePhoneNumber("");
      setNationalNumber("");

      return;
    }

    try {
      const parsedNumber = parsePhoneNumber(data?.toString());

      // setCountryCode(parsedNumber?.countryCallingCode);

      setCompletePhoneNumber(parsedNumber?.number);
      // setCustomPhoneNumber(parsedNumber?.number)
      setNationalNumber(parsedNumber?.nationalNumber);
    } catch (error) {}
  };

  const options = [
    { value: "01", label: "01" },
    { value: "02", label: "02" },
    { value: "03", label: "03" },
    { value: "04", label: "04" },
    { value: "05", label: "05" },
    { value: "06", label: "06" },
    { value: "07", label: "07" },
    { value: "08", label: "08" },
    { value: "09", label: "09" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
  ];

  const options2 = [...Array(10)].map((_, index) => ({
    value: (currentYear + index).toString(),
    label: (currentYear + index).toString(),
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  const [searchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: card?.billTo?.firstName && card?.billTo?.firstName,
      addressLine1: card?.billTo?.address && card?.billTo?.address,
      countryRegion: card?.billTo?.country && card?.billTo?.country,
      StateProvince: card?.billTo?.state && card?.billTo?.state,
      city: card?.billTo?.city && card?.billTo?.city,
      primaryPhone: card?.billTo?.phoneNumber && card?.billTo?.phoneNumber,
      zipPostalCode: card?.billTo?.zip && card?.billTo?.zip,
      cardNumber: card && card?.payment?.creditCard?.cardNumber,
      expiryMonth:
        card && card?.payment?.creditCard?.expirationDate.split("-")[0],
      expiryYear:
        card && card?.payment?.creditCard?.expirationDate.split("-")[1],
    },
  });

  const {
    currentDevice,
    token,
    setSideMenuAccount,
    editCardInfo,
    myProfile,
    cusProfile,
    cardEditId,
  } = useStore();
  const getAllCards = {
    getCustomerPaymentProfileRequest: {
      merchantAuthentication: {
        name: process.env.REACT_APP_MERCHANT_NAME,
        transactionKey: process.env.REACT_APP_MERCHANT_TRANSACTION_KEY,
      },
      customerProfileId: cusProfile,
      customerPaymentProfileId: searchParams?.get("card"),
      unmaskExpirationDate: true,
    },
  };
  const [formattedValue1, setFormattedValue1] = useState("");
  const [formattedValue, setFormattedValue] = useState("");

  const handleChange1 = (event) => {
    setFormattedValue1(event.target.value);
  };
  const handleChange234 = (value) => {
    setStateVal(stateCityData?.find((option) => option.label === value));
  };
  const authorize = useMutation((post) => authorizeNet(post), {
    onSuccess: (result) => {
      if (result) {
        getCard(result.data.paymentProfile);
      }
    },
    onError: (error) => {},
  });
  useEffect(() => {
    authorize.mutate(getAllCards);
  }, []);

  useEffect(() => {
    if (conty) {
      getStateCityMutation.mutate({ countryID: conty.value });
    }
  }, [conty]);

  useEffect(() => {
    if (card && countrySelectOption) {
      setCountry(
        countrySelectOption?.find(
          (option) => option.label === card?.billTo?.country
        )
      );
    }
  }, [card,countrySelectOption]);

  useEffect(() => {
    let str = "";
    str = card?.billTo?.firstName;
    if (card?.billTo?.lastName) {
      str = str + " " + card?.billTo?.lastName;
    }
    setValue("name", str);
    setValue("addressLine1", card?.billTo?.address);
    setValue("countryRegion", card?.billTo?.country);
    setValue("StateProvince", card?.billTo?.state);
    setValue("zipPostalCode", card?.billTo?.zip);
    setValue("city", card?.billTo?.city);
    setValue("primaryPhone", card?.billTo?.phoneNumber);
    setFormattedValue(card?.payment?.creditCard?.cardNumber);
    setValue(
      "expiryMonth",
      card?.payment?.creditCard?.expirationDate.split("-")[1]
    );
    setValue(
      "expiryYear",
      card?.payment?.creditCard?.expirationDate.split("-")[0]
    );
    setValue("isDefault", card?.defaultPaymentProfile);
    setValue("reference", editCardInfo?.reference);
    setSelectedValue(editCardInfo?.active ? "true" : "false");
  }, [card]);


  const handleInputChange = (event) => {
    const input = event.target.value;
    const cleanedValue = input.replace(/\D/g, ""); // Remove non-digit characters
    let newFormattedValue = "";
    for (let i = 0; i < cleanedValue.length; i++) {
      newFormattedValue += cleanedValue.charAt(i);
      if ((i + 1) % 4 === 0 && i !== cleanedValue.length - 1) {
        newFormattedValue += " ";
      }
    }
    setFormattedValue(newFormattedValue);
  };

  const validateCardNumber = (value) => {
    // Remove any non-digit characters from the input value
    const cleanedValue = value.replace(/\D/g, "");

    // Validate the card number using a custom validation function or a library like Luhn algorithm

    // Example Luhn algorithm validation
    let sum = 0;
    let isEven = false;

    for (let i = cleanedValue.length - 1; i >= 0; i--) {
      let digit = parseInt(cleanedValue.charAt(i), 10);

      if (isEven) {
        if ((digit *= 2) > 9) digit -= 9;
      }

      sum += digit;
      isEven = !isEven;
    }

    return sum % 10 === 0;
  };

  const navigate = useNavigate();
  // const CardNumberInput = ({ value, onChange }) => {
  //   const handleChange = (event) => {
  //     // Remove any non-digit characters from the input value
  //     const cleanedValue = event.target.value.replace(/\D/g, "");

  //     // Format the cleaned value with spaces at specific intervals
  //     let formattedValue = "";
  //     for (let i = 0; i < cleanedValue.length; i++) {
  //       formattedValue += cleanedValue.charAt(i);
  //       if ((i + 1) % 4 === 0 && i !== cleanedValue.length - 1) {
  //         formattedValue += " ";
  //       }
  //     }

  //     onChange(formattedValue);
  //   };

  //   return (
  //     <>
  //       <input
  //         type="text"
  //         value={value}
  //         disabled={true}
  //         onChange={handleChange}
  //         className={`InputFieldShippingAddress`}
  //         placeholder="Card Number"
  //       />
  //       {/* {errors.cardNumber && <span>{errors.cardNumber.message}</span>} */}
  //     </>
  //   );
  // };

  // const CardSecuirtyInput = ({ value, onChange }) => {
  //   const handleChange = (event) => {
  //     // Remove any non-digit characters from the input value
  //     const cleanedValue = event.target.value.replace(/\D/g, "");

  //     // Only update the state if the length is less than or equal to 4
  //     if (cleanedValue.length <= 4) {
  //       // Format the cleaned value with spaces at specific intervals
  //       let formattedValue = "";
  //       for (let i = 0; i < cleanedValue.length; i++) {
  //         formattedValue += cleanedValue.charAt(i);
  //         if ((i + 1) % 4 === 0 && i !== cleanedValue.length - 1) {
  //           formattedValue += " ";
  //         }
  //       }

  //       onChange(formattedValue);
  //     }
  //   };

  //   return (
  //     <>
  //       <div className="dflkjgfdk232">
  //         <input
  //           type="text"
  //           value={value}
  //           onChange={handleChange}
  //           className={`InputFieldShippingAddress inputBoxDisable`}
  //           maxLength={4}
  //         />
  //         <Tooltip
  //           placement="right"
  //           title={
  //             "CVV (Card Verification Value) is a three-digit security code printed on the back of your credit card. It helps ensure that the person making the transaction possesses the physical card. Please enter the three-digit code to complete the transaction"
  //           }
  //         >
  //           <Question className="catogeryIcon imageReltive"></Question>
  //         </Tooltip>
  //       </div>
  //     </>
  //   );
  // };

  const notify = (item) => toast.success(item);

  const storePayMethod = useMutation((post) => storePay(post, token), {
    onSuccess: (result) => {
      notify("Card Updated Successfully");
      setSideMenuAccount("paymentMethods");
      navigate({
        pathname: "/account/mgmt/payment",
      });
    },
    onError: (error) => {},
  });

  const authAPI = useMutation((post) => authorizeNet(post), {
    onSuccess: (result) => {
      if (result.data.messages.resultCode === "Ok") {
        const obj = {};
        obj.id = myProfile.profileInfo.id;
        obj.ReferenceLabel = getValues("reference");
        obj.uId = editCardInfo.uId;
        obj.Active = selectedValue === "true";
        obj.flag = 0;
        storePayMethod.mutate(obj);
      } else {
        showErrorToast(result.data.messages.message[0].text);
      }
    },
    onError: (error) => {},
  });

  const [cancelDialog, setCancelDialog] = useState(false);
  const [removeCardUId, setRemoveCardUId] = useState("");

  const handleCancel = (data) => {
    setCancelDialog(!cancelDialog);
    setRemoveCardUId(searchParams?.get("uid"));
  };

  const storePayMethodREmove = useMutation((post) => storePay(post, token), {
    onSuccess: (result) => {
      notify("Card is Removed Successfully");
      setSideMenuAccount("paymentMethods");
      navigate({
        pathname: "/account/mgmt/payment",
      });
    },
    onError: (error) => {},
  });

  const authAPIRemove = useMutation((post) => authorizeNet(post), {
    onSuccess: (result) => {
      if (result.data.messages.resultCode === "Ok") {
        const obj = {};
        obj.id = myProfile.profileInfo.id;
        obj.uId = removeCardUId;
        obj.flag = -1;
        storePayMethodREmove.mutate(obj);
      } else {
        showErrorToast(result.data.messages.message[0].text);
      }
    },
    onError: (error) => {},
  });

  const handleRemoveCard = (id) => {
    const removeCard = {
      deleteCustomerPaymentProfileRequest: {
        merchantAuthentication: {
          name: process.env.REACT_APP_MERCHANT_NAME,
          transactionKey: process.env.REACT_APP_MERCHANT_TRANSACTION_KEY,
        },
        customerProfileId: cusProfile,
        customerPaymentProfileId: id,
      },
    };
    authAPIRemove.mutate(removeCard);
  };

  const handleOkay = () => {
    handleRemoveCard(searchParams?.get("card"));
    setCancelDialog(!cancelDialog);
  };

  const onSubmit = (data, event) => {
    event.preventDefault();
    const updateCard = {
      updateCustomerPaymentProfileRequest: {
        merchantAuthentication: {
          name: process.env.REACT_APP_MERCHANT_NAME,
          transactionKey: process.env.REACT_APP_MERCHANT_TRANSACTION_KEY,
        },
        customerProfileId: cusProfile,
        paymentProfile: {
          billTo: {
            firstName: data?.name.split(" ")[0] ? data?.name.split(" ")[0] : "",
            lastName: data?.name.split(" ")[1] ? data?.name.split(" ")[1] : "",
            address: data?.addressLine1,
            city: data?.city,
            state: data?.StateProvince,
            zip: data?.zipPostalCode,
            country: data?.countryRegion,
            phoneNumber: completePhoneNumber ? completePhoneNumber : card?.billTo?.phoneNumber,
          },
          payment: {
            creditCard: {
              cardNumber: formattedValue.replace(/\s/g, ""),
              expirationDate: data.expiryYear + "-" + data.expiryMonth,
            },
          },
          defaultPaymentProfile: data.isDefault,
          customerPaymentProfileId:  searchParams?.get("card"),
        },
        validationMode: "liveMode",
      },
    };
    authAPI.mutate(updateCard);
  };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <Box
      sx={{
        background: "#eaeaea",
        width: "100%",
      }}
    >
      <ContainerDivStyled currentDevice={currentDevice}>
        <IlmorModal
          isopen={cancelDialog}
          handleOkay={handleOkay}
          handleClose={handleCancel}
          message={commonMessages?.removeCard}
          messageDetail={"The Card will be removed immediately."}
          secondMessage={"Any existing orders/lists will not be affected."}
          fisrtButton={"Cancel"}
          secondButton={"Delete"}
        ></IlmorModal>
        {(authorize.isLoading ||
          storePayMethodREmove.isLoading ||
          storePayMethod.isLoading ||
          authAPIRemove.isLoading) && <Loader></Loader>}
        {authAPI.isLoading && <Loader></Loader>}
        <Grid container>
          <Grid item xs={12} sm={12} md={9}>
            <Grid container spacing={1}>
              <Grid item>
                <StyledShippingHeading currentDevice={currentDevice}>
                  {commonMessages?.EditPaymentMethodsHeading}
                </StyledShippingHeading>
              </Grid>
              {currentDevice == "Tablet" && (
                <Grid item sm={12} md={12}>
                  <TabletViewBlackContainer />
                </Grid>
              )}
              <Grid item sm={12} md={12}>
                <StyledShippingSubHeading>
                  {commonMessages?.EditPaymentMethodsSubHeadingOne}
                </StyledShippingSubHeading>{" "}
              </Grid>
              {/* <Grid display={"flex"} gap={2} item sm={12} md={12}>
              <Visa className="catogeryIcon"></Visa>
              <American className="catogeryIcon"></American>
              <Discover className="catogeryIcon"></Discover>
            </Grid> */}
              <Grid item sm={8} md={12}>
                <StyledRequiredField>
                  {commonMessages?.addEmployeeAccountRequiredField}
                </StyledRequiredField>
              </Grid>
            </Grid>

            {currentDevice != "Desktop" && <hr className="hrLineBreak"></hr>}
            {currentDevice == "Desktop" && (
              <Divider sx={{ margin: "30px 0px 21px 0px" }} />
            )}

            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className={`form shippingForm`}
                  noValidate
                >
                  <div className="labelDesktopAddShippingBottomDesktopPadding gkrtj454">
                    <h5 className={`labelDesktopAddShipping caps`}>
                      Reference Label
                    </h5>
                    <input
                      // style={{ border: "none" }}
                      className={`InputFieldShippingAddress`}
                      // autoFocus
                      type="text"
                      // value={email}
                      name="reference"
                      // onChange={handleEmailChange}
                      {...register("reference")}
                    />
                    <div className="thisCanBe">
                      {" "}
                      {commonMessages?.addPaymentMethodsSubHeadingTwo}
                    </div>
                    {errors?.reference && (
                      <ErrorMessageStyled>
                        {errors.reference?.message}
                      </ErrorMessageStyled>
                    )}
                  </div>
                  <div>
                    <h5 className={`labelDesktopAddShipping`}>Card Status</h5>

                    <div className="cardStatus cardStatusDesktopAddAPyment">
                      <Radio
                        checked={selectedValue === "true"}
                        onChange={handleChange}
                        value="true"
                        color="black"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "A" }}
                      />
                      <div className="activeInactive">Active</div>
                      <Radio
                        checked={selectedValue === "false"}
                        onChange={handleChange}
                        value="false"
                        color="black"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "B" }}
                      />
                      <div className="activeInactive">Inactive</div>
                    </div>
                  </div>
                  <div className={`passwordlabelDesktopAddShipping`}>
                    <h5 className={`labelDesktopAddShippingRequired`}>
                      ADDRESS
                    </h5>
                  </div>
                  <input
                    name="addressLine1"
                    // style={{ border: "none" }}
                    className={`InputFieldShippingAddress`}
                    type="text"
                    {...register("addressLine1")}
                  ></input>
                  {errors?.addressLine1 && (
                    <ErrorMessageStyled>
                      {errors?.addressLine1?.message}
                    </ErrorMessageStyled>
                  )}
                  <div className={`passwordlabelDesktopAddShipping`}>
                    <h5 className={`labelDesktopAddShippingRequired`}>
                      COUNTRY
                    </h5>
                  </div>
                  <Controller
                    // rules={{ required: true }}
                    name="countryRegion"
                    control={control}
                    render={({ onChange, value, ref, field }) => {
                      return (
                        <Select
                          // className='select'
                          {...field}
                          className="jkdhs"
                          placeholder={<div></div>}
                          options={countrySelectOption}
                          value={conty}
                          // value={}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption?.label);
                            handleCountrySelectChange(selectedOption?.value);
                          }}
                          styles={customStyles}
                          //  menuIsOpen={true}
                          // defaultValue={userRole.find((c) => c.value === countryValue)}
                        />
                      );
                    }}
                  />
                  {errors?.countryRegion && (
                    <ErrorMessageStyled>
                      {errors?.countryRegion?.message}
                    </ErrorMessageStyled>
                  )}
                  <Grid container spacing={1}>
                    <Grid
                      item
                      sm={4}
                      xs={12}
                      md={4}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div className={`emailLabel`}>
                        <h5 className={`labelDesktopAddShippingRequired`}>
                          STATE / PROVINCE
                        </h5>
                      </div>
                      <Controller
                        // rules={{ required: true }}
                        name="StateProvince"
                        control={control}
                        render={({ onChange, value, ref, field }) => {
                          return (
                            <Select
                              // className='select'
                              {...field}
                              // value={stateCityData?.find(
                              //   (option) => option.value === field.value
                              // )}
                              isLoading={getStateCityMutation.isLoading}
                              isDisabled={!getValues("countryRegion")}
                              options={stateCityData}
                              className="jkdhs"
                              placeholder={<div></div>}
                              //  value={stateCityData.find((c) => c.value === value)}
                              value={stateval}
                              onChange={(selectedOption) => {
                                field?.onChange(selectedOption?.label);
                                handleChange234(selectedOption?.value);
                              }}
                              ref={stateRef}
                              styles={customStyles}
                              //  menuIsOpen={true}
                              // defaultValue={userRole.find((c) => c.value === countryValue)}
                            />
                          );
                        }}
                      />
                      {errors?.StateProvince && (
                        <ErrorMessageStyled>
                          {errors?.StateProvince?.message}
                        </ErrorMessageStyled>
                      )}
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                      sm={4}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div className={`emailLabel`}>
                        <h5 className={`labelDesktopAddShippingRequired`}>
                          CITY
                        </h5>
                      </div>
                      <input
                        // style={{ border: "none" }}
                        className={`InputFieldShippingAddress`}
                        type="text"
                        // value={email}
                        name="city"
                        // onChange={handleEmailChange}
                        {...register("city")}
                      ></input>{" "}
                      {errors?.city && (
                        <ErrorMessageStyled>
                          {errors?.city?.message}
                        </ErrorMessageStyled>
                      )}
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                      sm={4}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div className={`emailLabel`}>
                        <h5 className={`labelDesktopAddShippingRequired`}>
                          ZIP POSTAL CODE
                        </h5>
                      </div>
                      <input
                        // style={{ border: "none" }}
                        className={`InputFieldShippingAddress`}
                        type="text"
                        maxlength="12"
                        // value={email}
                        name="zipPostalCode"
                        // onChange={handleEmailChange}
                        {...register("zipPostalCode")}
                      ></input>
                      {errors?.zipPostalCode && (
                        <ErrorMessageStyled>
                          {errors?.zipPostalCode?.message}
                        </ErrorMessageStyled>
                      )}
                    </Grid>
                  </Grid>
                  <div className={`passwordlabelDesktopAddShipping`}>
                    <h5 className={`labelDesktopAddShipping`}>PRIMARY PHONE</h5>
                  </div>
                  <Controller
                    // rules={{ required: true }}
                    name="primaryPhone"
                    control={control}
                    render={({ onChange, value, onBlur, ref, field }) => {
                      return (
                        <PhoneInput
                          {...field}
                          // value={customPhoneNumber}
                          // value={userRole.find((c) => c.value === value)}
                          // onChange={(selectedOption) => {
                          //   handleSelectChange(selectedOption?.label,selectedOption?.value);
                          // }}
                          // countries =  {["RU", "UA", "KZ","IN","PK","US","NZ"]}
                          placeholder="Enter Phone Number"
                          // onChange={(data)=>handlePhoneNumberChange(data,field)}
                          onChange={handlePhoneNumberChange}
                          // international
                          countryCallingCodeEditable={false}
                          addInternationalOption={false}
                          defaultCountry="US"
                          className="ReactPhoneNumInputt"
                          minLength="7"
                          limitMaxLength={true}
                          style={{
                            backgroundColor: "white",
                            border: "none",
                            padding: "0px 10px",
                          }}
                        />
                      );
                    }}
                  />
                  <div>
                    <h5 className={`labelDesktopAddShippingRequired caps`}>
                      Cardholder Name
                    </h5>
                    <input
                      className={`InputFieldShippingAddress`}
                      type="text"
                      name="name"
                      {...register("name")}
                    />
                    {errors?.name && (
                      <ErrorMessageStyled>
                        {errors.name?.message}
                      </ErrorMessageStyled>
                    )}
                  </div>

                  <div>
                    <h5 className={`labelDesktopAddShippingRequired `}>
                      CARD NUMBER
                    </h5>
                    {/* <Controller
                      name="cardNumber"
                      control={control}
                      defaultValue=""
                      render={({ field }) => <CardNumberInput {...field} />}
                    /> */}
                    <input
                      type="text"
                      name="cardNumber"
                      value={formattedValue}
                      placeholder={formattedValue}
                      disabled={false}
                      id="card"
                      {...register("cardNumber")}
                      inputMode="numeric"
                      maxLength={23}
                      onChange={handleInputChange}
                      className={`InputFieldShippingAddress`}
                    />
                    {errors?.cardNumber && (
                      <ErrorMessageStyled>
                        {errors.cardNumber?.message}
                      </ErrorMessageStyled>
                    )}
                  </div>
                  <div>
                    <h5
                      className={`labelDesktopAddShipping labelDesktopAddShippingRequired caps`}
                    >
                      Expiration Date
                    </h5>

                    {/* <input
                    // style={{ border: "none" }}
                    className={`InputFieldShippingAddress`}
                    type="text"
                    // value={email}
                    name="expiry"
                    // onChange={handleEmailChange}
                    {...register("expiry")}
                  /> */}
                    <Grid container spacing={1}>
                      <Grid
                        item
                        className="addpaymentcardwidthDesktopLeft"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Controller
                          name="expiryMonth"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <Select
                              className="addpaymentcardwidth "
                              {...field}
                              id="expiryMonth"
                              value={options.find(
                                (option) => option.value === field.value
                              )}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption?.value);
                              }}
                              styles={customStyles}
                              options={[
                                { value: "01", label: "01" },
                                { value: "02", label: "02" },
                                { value: "03", label: "03" },
                                { value: "04", label: "04" },
                                { value: "05", label: "05" },
                                { value: "06", label: "06" },
                                { value: "07", label: "07" },
                                { value: "08", label: "08" },
                                { value: "09", label: "09" },
                                { value: "10", label: "10" },
                                { value: "11", label: "11" },
                                { value: "12", label: "12" },
                              ]}
                              placeholder="MM"
                            />
                          )}
                        />
                        {errors?.expiryMonth && (
                          <ErrorMessageStyled>
                            {errors.expiryMonth?.message}
                          </ErrorMessageStyled>
                        )}
                      </Grid>
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Controller
                          name="expiryYear"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <Select
                              {...field}
                              id="expiryYear"
                              value={options2.find(
                                (option) => option.value === field.value
                              )}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption?.value);
                              }}
                              styles={customStyles}
                              options={options2}
                              placeholder="YY"
                              className="addpaymentcardwidth"
                            />
                          )}
                        />
                        {errors?.expiryYear && (
                          <ErrorMessageStyled>
                            {errors.expiryYear?.message}
                          </ErrorMessageStyled>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                  <div className={`passwordlabelDesktopAddShipping`}>
                    <h5 className={`labelDesktopAddShippingRequired caps`}>
                      Security Code
                    </h5>
                    <Grid className="sdfsd1" container spacing={1}>
                      <Grid
                        className="dkjgbnkfghjbhnkjt"
                        item
                        sm={2}
                        xs={2}
                        md={2}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          maxWidth: "121px !important",
                        }}
                      >
                        {/* <Controller
                          name="security"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <CardSecuirtyInput {...field} />
                          )}
                        /> */}
                        <div className="dflkjgfdk232">
                          <input
                            type="text"
                            value={formattedValue1}
                            name="security"
                            inputMode="numeric"
                            {...register("security")}
                            id="ccv"
                            onChange={handleChange1}
                            // onFocus={handleFocus}
                            className={`InputFieldShippingAddress inputBoxDisable`}
                            maxLength={4}
                          />
                          <Tooltip
                            placement="right"
                            title={
                              "CVV (Card Verification Value) is a three-digit security code printed on the back of your credit card. It helps ensure that the person making the transaction possesses the physical card. Please enter the three-digit code to complete the transaction"
                            }
                          >
                            <Question className="catogeryIcon imageReltive"></Question>
                          </Tooltip>
                        </div>
                        {errors?.security && (
                          <ErrorMessageStyled className="minWid">
                            {errors?.security?.message}
                          </ErrorMessageStyled>
                        )}
                      </Grid>
                      <Grid
                        className="sdfsd3"
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        {/* <input
                          name="isDefault"
                          // style={{ border: "none" }}
                          className={`inputCheckboxGlobal`}
                          type="checkbox"
                          {...register("isDefault")}
                        ></input> */}
                        <Controller
                          name="isDefault"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              sx={{
                                // color: "white",
                                // "&.Mui-checked": {
                                //   color: "white",
                                // },
                                // "& .MuiSvgIcon-root": {
                                //   fill: "black",
                                // },
                                // "&.Mui-checked .MuiSvgIcon-root": {
                                //   fill: "black",
                                // },
                                "&.Mui-checked .MuiIconButton-label": {
                                  backgroundColor: "black",
                                },
                              }}
                              checked={field.value ? true : false}
                            />
                          )}
                        />
                        <div>
                          {commonMessages?.addPaymentMethodsSubHeadingThree}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  {currentDevice != "Desktop" && (
                    <hr className="hrLineBreak"></hr>
                  )}
                  {currentDevice == "Desktop" && (
                    <Divider sx={{ margin: "39px 0px 30px 0px" }} />
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flexDirection: "row",
                      flexGrow: 1,
                      p: 1,
                      m: 1,
                      gap: "24px",

                      borderRadius: 1,
                    }}
                  >
                    <button
                      type="button"
                      className={`cancelButonAddShipping padright`}
                      onClick={() => {
                        setSideMenuAccount("paymentMethods");
                        navigate({
                          pathname: "/account/mgmt/payment",
                        });
                      }}
                    >
                      Cancel
                    </button>
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                        handleCancel(searchParams?.get("card"));
                      }}
                      className="center"
                    >
                      <Cross className="catogeryIcon padright"></Cross>
                      <button
                        type="button"
                        className={`cancelButonAddShipping`}
                      >
                        Delete Card
                      </button>
                    </div>
                    <button
                      className={`ilmorButon`}
                      //  onClick={handleSignIn}
                    >
                      Update Payment Method
                    </button>
                  </Box>
                </form>
              </Grid>
              <DevTool control={control} />
            </Grid>
          </Grid>
        </Grid>
        <Box>
          {currentDevice == "Mobile" && (
            <TabletViewBlackContainer currentDevice={currentDevice} />
          )}
        </Box>
      </ContainerDivStyled>
    </Box>
  );
};

export default EditPaymentMethod;
