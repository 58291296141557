import { Box, Grid } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { getSVG, getSvgHotSpot } from "../../api/posts";
import { useStore } from "../../store/home";
import Footer from "../Footer";
import { ReactSVG } from "react-svg";
import top from "../../Images/top.png";
import { useNavigate } from "react-router-dom";
// import flower from "../../Images/flower.svg";
import Header from "../Header";
import { ReactComponent as LeftArrow } from "../../Images/leftArrow.svg";
import { StyledShippingHeadingEngine } from "../StyledComponents/ShippingAddressStyled";
import SideMenuEngine from "../../commenComp/SideMenuEngine";
import TileListEngine from "../TileListEngine";
import Loader from "../../commenComp/Loader";
import { ReactComponent as ArrowIlmor } from "../../Images/arrowBack.svg";
import { ReactComponent as ArrowIlmorRed } from "../../Images/arrowBackRed.svg";
import { showErrorToast } from "../../utils/Toaster";
import classnames from "classnames";
import { useSearchParams } from "react-router-dom";
import { useNonPersistedStore } from "../../store/NonPersistedStore";
import TabletViewBlackContainer from "../TabletViewBlackContainer";
import TabletViewBlackContainerEngine from "../TabletViewBlackContainerEngine";
import { ContainerDivStyled } from "../StyledComponents/BusinessDetailsStyled";
import { useRef } from "react";
import { lowerBoy } from "../../commonFunction";

const ShopEngine = () => {
  const { isCurrentEngSet, token, currentDevice, dealerId } = useStore();
  const [hotspot, setHotspot] = useState("");
  const [hotspot2, setHotspot2] = useState(false);
  // const [svgData, setSvgData] = useState(false);
  const [svgDataOriginal, setSvgDataOriginal] = useState(false);
  // const [svgDataOriginalL2, setSvgDataOriginalL2] = useState(false);
  const [options, setOptions] = useState(false);
  // const [partList, setPartList] = useState(false);
  // const [notLast, setNotLast] = useState(true);
  const [second, setSecond] = useState(1);
  const [isLT, setLT] = useState(false);
  const [load, setload] = useState(false);
  const {
    selectedCategory,
    setPartList,
    partList,
    setSelectedCategory,
    setSvgData,
    svgData,
    setNotLast,
    notLast,
    svgDataOriginalL2,
    setSvgDataOriginalL2,
    partListL2,
    setPartListL2,
    mod,
    setMod,
    setdis,
    setdis1,
    setdis2,
    setdis3,
    setdis4,
    dis,
    dis1,
    dis2,
    dis3,
    dis4,
  } = useNonPersistedStore();
  // const [dis, setdis] = useState(false);
  const hotspotAPI = useMutation((post) => getSvgHotSpot(post), {
    mutationKey: "getSvgHotSpot",
    method: "GET",
    onSuccess: (result) => {
      setload(true);
      setTimeout(() => {
        setload(false);
      }, 1000);
      setdis(true);
      setTimeout(() => {
        setdis(false);
      }, 2000);
      if (result.data.success) {
        // setSvgData(result.data.data.component);
        const fetchSvgData = async () => {
          try {
            setdis3(true);
            const response = await fetch(result.data.data.component);
            const data = await response.text();
            const modifiedSvgData = data.replace(/xlink:href/g, "link");
            const blob = new Blob([modifiedSvgData], { type: "image/svg+xml" });
            const svgUrl = URL.createObjectURL(blob);
            setMod(svgUrl);
            setIsLoading(false);
            setdis3(false);
          } catch (error) {
            setdis3(false);
            console.error("Error fetching SVG data:", error);
            setIsLoading(false);
          }
        };
        fetchSvgData();
        setPartList(result.data.data.partList);
        let arr = isLT && isLT?.split("-");
        if (arr && arr.length > 0) {
          setSelectedCategory(arr[0]);
        }
        if (result.data.data.isChildComponent) {
          setPartListL2(result.data.data.partList);
          setSecond(2);
          const fetchSvgData = async () => {
            try {
              setdis4(true);
              const response = await fetch(result.data.data.component);
              const data = await response.text();
              const modifiedSvgData = data.replace(/xlink:href/g, "link");
              const blob = new Blob([modifiedSvgData], {
                type: "image/svg+xml",
              });
              const svgUrl = URL.createObjectURL(blob);
              setSvgDataOriginalL2(svgUrl);
              setIsLoading(false);
              setdis4(false);
            } catch (error) {
              setdis4(false);
              console.error("Error fetching SVG data:", error);
              setIsLoading(false);
            }
          };
          fetchSvgData();
          setNotLast(true);
        } else {
          setSecond(3);
          setNotLast(false);
        }
      } else {
        showErrorToast(result.data.message);
        setSvgData(svgDataOriginal);
        setPartList([]);
      }
    },
    onError: (error) => {
      showErrorToast("Data not found");
    },
  });

  const [searchParams] = useSearchParams();
  function myfun(hotspotid, nextId, evt) {
    evt.preventDefault();
    if (nextId && !nextId?.includes("part-")) {
      const params = {
        token: token,
        hotspotId: nextId,
        dealerId:dealerId,
        engineId: isCurrentEngSet.engineId || "",
      };
      setLT(nextId);
      let strId = evt.target.parentElement.id;
      if (!strId) {
        strId = evt.target.parentElement.parentElement.id;
      }
      let svgDocument = evt.target.ownerSVGElement;
      let hotVal = "";
      let hotspot = svgDocument?.getElementById(strId);
      if (hotspot) {
        hotVal = hotspot.querySelector("a")?.getAttribute("link");
        hotspot?.setAttribute("opacity", "0");
      }
      if (document?.getElementById(`menu.${hotVal}`)) {
        document.getElementById(`menu.${hotVal}`).style.background = "black";
        document.getElementById(`menu.${hotVal}`).style.borderRadius = "0px";
      }
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("category", nextId);
      const newSearchParams = queryParams.toString();
      navigate({
        search: `?${newSearchParams}`,
      });
      if (nextId && isCurrentEngSet.engineId) {
        hotspotAPI.mutate(params);
      }
    } else {
      if (nextId) {
        const element = document.getElementById(lowerBoy(nextId).slice(5, nextId.length));
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
          element.style.border = "2px solid red";
          setTimeout(() => {
            element.style.background = "";
            element.style.border = "";
            // element.scrollIntoView({ behavior: "smooth" });
          }, 2000);
        } else {
          showErrorToast(
            "This part is not available online. Please contact Ilmor for further assistance."
          );
        }
      } else {
        showErrorToast(
          "This part is not available online. Please contact Ilmor for further assistance."
        );
      }
    }
  }

  const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   const fetchSvgData = async () => {
  //     try {
  //       const response = await fetch(svgData);
  //       const data = await response.text();
  //       const modifiedSvgData = data.replace(/xlink:href/g, "link");
  //       const blob = new Blob([modifiedSvgData], { type: "image/svg+xml" });
  //       const svgUrl = URL.createObjectURL(blob);
  //       setMod(svgUrl);
  //       setIsLoading(false);
  //     } catch (error) {
  //       console.error("Error fetching SVG data:", error);
  //       setIsLoading(false);
  //     }
  //   };
  //   fetchSvgData();
  // }, [svgData]);

  useEffect(() => {
    setHotspot2(!hotspot2);
  }, [hotspot]);

  // useEffect(() => {
  //   if(searchParams?.get("category")){
  //     setSelectedCategory(searchParams?.get("category"))
  //   } else {
  //     setSelectedCategory('')
  //   }
  // }, [searchParams]);

  useEffect(() => {
    window.myComponentInstance = {
      myfun,
    };
    return () => {
      window.myComponentInstance = null;
    };
  }, []);
  const navigate = useNavigate();
  const handleback = () => {
    // setload(true);
    // setTimeout(() => {
    //   setload(false);
    // }, 4000);
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("category", "");
    const newSearchParams = queryParams.toString();
    navigate({
      search: `?${newSearchParams}`,
    });
    setNotLast(true);
    if (second === 3 && svgDataOriginalL2) {
      setSecond(2);
      setMod(svgDataOriginalL2);
      // setSvgDataOriginalL2(false);
      setPartList(partListL2);
    } else {
      setSecond(1);
      setPartList(false);
      setSelectedCategory("");
      setSvgDataOriginalL2(false);
      setMod(svgDataOriginal);
    }
  };

  const handlTop = () => {
    window.scrollTo(0, 200);
  };
  const getSVGAPI = useMutation((post) => getSVG(post), {
    mutationKey: "getSVG",
    method: "GET",
    onSuccess: (result) => {
      if (
        result.data.data &&
        result.data.data.category &&
        result.data.data.category.length > 0
      ) {
        const options = result.data.data.category.map((item) => {
          return { name: item.categoryName, id: item.componentName };
        });
        setOptions(options);
      }
      setNotLast(true);
      // setSvgData(result.data.data.svgData);
      const fetchSvgData = async () => {
        try {
          const response = await fetch(result.data.data.svgData);
          const data = await response.text();
          const modifiedSvgData = data.replace(/xlink:href/g, "link");
          const blob = new Blob([modifiedSvgData], { type: "image/svg+xml" });
          const svgUrl = URL.createObjectURL(blob);
          setSvgDataOriginal(svgUrl);
          setMod(svgUrl);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching SVG data:", error);
          setIsLoading(false);
        }
      };
      fetchSvgData();
      if (searchParams?.get("category") && searchParams?.get("engineId")) {
        setSelectedCategory(searchParams?.get("category"));
        const params = {
          token: token,
          dealerId: dealerId,
          hotspotId: searchParams?.get("category"),
          engineId: searchParams?.get("engineId") || "",
        };
        hotspotAPI.mutate(params);
      }
    },
    onError: (error) => {},
  });
  useEffect(() => {
    const params = {
      token: token,
      // engineId: isCurrentEngSet.engineId || "",
      engineId: searchParams?.get("engineId") ?? "",
    };
    getSVGAPI.mutate(params);
    setMod();
    setPartList(false);
    setSelectedCategory("");
  }, []);

  // useEffect(() => {
  //   if (mod) {
  //     setload(true);
  //     setTimeout(() => {
  //       setload(false);
  //     }, 2500);
  //   }
  // }, [mod]);

  return (
    <Box
      sx={{
        background: "#eaeaea",
        width: "100%",
      }}
    >
      {(getSVGAPI.isLoading || load || isLoading || hotspotAPI.isLoading) && (
        <Loader></Loader>
      )}
      {getSVGAPI.isLoading && <Loader></Loader>}
      <Grid sm={12} md={12} lg={12} item>
        <Header page="Catalog"></Header>
      </Grid>
      <Grid
        sx={{
          // display: "flex",
          backgroundColor: "#EAEAEA",
          // alignItems: "center",
          // padding: "20px 48px",
        }}
        container
        className="headingShop"
      >
        <Grid
          sx={{ display: "flex", alignItems: "center" }}
          className="maxWidthbut fdngl434"
          item
          sm={12}
          md={3}
        >
          <div
            onClick={() => {
              navigate("/partscatalog");
            }}
            className="ilmorButon"
          >
            <ArrowIlmor className="catogeryIcon"></ArrowIlmor>
            <div className="marginleftarro" style={{ marginLeft: "4px" }}>
              Parts Catalog
            </div>
          </div>
        </Grid>
        <Grid item>
          <StyledShippingHeadingEngine
            sx={{
              fontSize:
                currentDevice === "Desktop"
                  ? "40px"
                  : currentDevice === "Tablet"
                  ? "30px"
                  : "24px",
            }}
            currentDevice={currentDevice}
            className="headingCssOrder shopEngineTitle"
          >
            {`${isCurrentEngSet.year ?? searchParams?.get("year")} ${
              isCurrentEngSet.make ?? searchParams?.get("make")
            } `}
          </StyledShippingHeadingEngine>
          <StyledShippingHeadingEngine
            sx={{
              fontSize:
                currentDevice === "Desktop"
                  ? "40px"
                  : currentDevice === "Tablet"
                  ? "30px"
                  : "24px",
            }}
            currentDevice={currentDevice}
            className="headingCssOrder shopEngineTitle dsfsdf987"
          >
            {`${isCurrentEngSet.model ?? searchParams?.get("model")}`}
          </StyledShippingHeadingEngine>
        </Grid>
      </Grid>
      <div
        className={classnames("svgEngineDiv", {
          noFlex: currentDevice === "Desktop",
          nppadisvh: currentDevice === "Mobile",
        })}
      >
        <Grid style={{ maxWidth: "300px" }} item xs={12} md={3}>
          <SideMenuEngine options={options} />
        </Grid>
        {currentDevice !== "Desktop" && (
          <TabletViewBlackContainerEngine
            options={options}
            currentDevice={currentDevice}
          />
        )}
        <ContainerDivStyled
          className="nopaddinforMobTab"
          bg="white"
          currentDevice={currentDevice}
        >
          <Grid container>
            <Grid item xs={12} sm={12} md={9}></Grid>
            <Grid spacing={1} container>
              <Grid className="esfer" item xs={12} md={true}>
                {mod && selectedCategory && (
                  <div onClick={handleback} className="backEngineDiv">
                    <ArrowIlmorRed className="catogeryIcon"></ArrowIlmorRed>
                    <div className="backSvg">Back</div>
                  </div>
                )}
                <div
                  className={classnames("svgDiv", {
                    disableDiv: dis || dis1 || dis2 || dis3 || dis4,
                  })}
                >
                  {mod && (
                    <ReactSVG
                      src={mod}
                      // src={flower}
                    ></ReactSVG>
                  )}
                </div>
                {partList &&
                  partList?.map((item) => {
                    return (
                      <TileListEngine
                        item={item}
                        className="card"
                      ></TileListEngine>
                    );
                  })}
                {/* {partList && partList.length > 10 && (
                  <img onClick={handlTop} className="topImg" src={top}></img>
                )} */}
              </Grid>
            </Grid>
          </Grid>
        </ContainerDivStyled>
      </div>
      <Footer />
    </Box>
  );
};

export default ShopEngine;
