import React, { useState } from "react";
import { Box, Grid, InputLabel, Select, MenuItem } from "@mui/material";
import { AddEmployeeAccountBtnStyled } from "./StyledComponents/EmployeeAccountStyled";
import { initialState, myAccountCategory } from "../commonFunction";
import { useStore } from "../store/home";
import { useNavigate } from "react-router-dom";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import Loader from "../commenComp/Loader";
import { getSvgHotSpot } from "../api/posts";
import { useMutation } from "@tanstack/react-query";
import { showErrorToast } from "../utils/Toaster";
import { ReactComponent as ArrowIlmor } from "../Images/downDrop.svg";
import { customStyles } from "../utils/ReactSelectCustomStyles";
const TabletViewBlackContainerEngine = ({ currentDevice, options }) => {
  const { isCurrentEngSet, token, dealerId } = useStore();
  const navigate = useNavigate();
  const [age, setAge] = useState();
  const handleMaterialSelectChange = (event) => {
    setAge(event.target.value);
  };
  // const [selectedCategory, setSelectedCategory] = useState("second");
  const resetStore = () => {
    useStore.setState(initialState);
  };
  const {
    selectedCategory,
    setSelectedCategory,
    setPartList,
    partList,
    setSvgData,
    svgData,
    setNotLast,
    notLast,
    svgDataOriginalL2,
    setMod,
    setdis2,
    setSvgDataOriginalL2,
  } = useNonPersistedStore();

  function HideHotSpot(hotspotid) {
    if (selectedCategory !== hotspotid) {
      document
        ?.getElementById("svg")
        ?.getElementById(`${hotspotid}`)
        ?.setAttribute("opacity", "0");
    }
  }
  const [load, setload] = useState(false);
  const hotspotAPI = useMutation((post) => getSvgHotSpot(post), {
    mutationKey: "getSvgHotSpot",
    method: "GET",
    onSuccess: (result) => {
      if (result.data.success) {
        const fetchSvgData = async () => {
          try {
            setdis2(true);
            const response = await fetch(result.data.data.component);
            const data = await response.text();
            const modifiedSvgData = data.replace(/xlink:href/g, "link");
            const blob = new Blob([modifiedSvgData], { type: "image/svg+xml" });
            const svgUrl = URL.createObjectURL(blob);
            if (result.data.data.isChildComponent) {
              setSvgDataOriginalL2(svgUrl);
              setNotLast(true);
            } else {
              setNotLast(false);
            }
            setdis2(false);
            setMod(svgUrl);
            // setIsLoading(false);
          } catch (error) {
            setdis2(false);
            console.error("Error fetching SVG data:", error);
            // setIsLoading(false);
          }
        };
        fetchSvgData();
        // setSvgData(result.data.data.component);
        setPartList(result.data.data.partList);
        // if (result.data.data.isChildComponent) {
        //   setSvgDataOriginalL2(result.data.data.component);
        //   setNotLast(true);
        // } else {
        //   setNotLast(false);
        // }
      } else {
        showErrorToast(result.data.message);
        // setSvgData(svgDataOriginal);
        // setPartList([]);
      }
    },
    onError: (error) => {},
  });
  const handleCategoryClick = (cat) => {
    setload(true);
    setTimeout(() => {
      setload(false);
    }, 2000);
    const params = {
      token: token,
      hotspotId: cat,
      dealerId: dealerId,
      engineId: isCurrentEngSet.engineId || "",
    };
    hotspotAPI.mutate(params);
    if (selectedCategory !== cat) {
      options.forEach((item) => {
        HideHotSpot(item.id);
      });
      document
        ?.getElementById("svg")
        ?.getElementById(`${cat}`)
        ?.setAttribute("opacity", "0.5");
      setSelectedCategory(cat);
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("category", cat);
      const newSearchParams = queryParams.toString();
      navigate({
        search: `?${newSearchParams}`,
      });
    } else {
      setSelectedCategory("");
    }
  };

  const plcaHold = () => {
    return (
      <Box
        component="span"
        sx={{
          display: "inline",
          fontSize: 14,
          color: "rgba(6, 7, 10, 0.50)",
          fontWeight: 500,
          fontStyle: "italic",
        }}
      >
        select engine area
      </Box>
    );
  };
  return (
    <>
      {load && <Loader></Loader>}
      <Grid
        container
        rowSpacing={1}
        columnSpacing={3}
        className="gridBlackContainer1"
        sx={{
          width: "100vw !important",
          // maxWidth:"100%",
          position: "relative",
          left: "50%",
          right: "50%",
          marginLeft: "-50vw",
          marginRight: " -50vw",
          marginTop: currentDevice == "Mobile" ? "65px" : "2px",
          marginBottom: "2px",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px",
          backgroundColor: "#07080C",
        }}
      >
        <Grid
          item
          spacing={0}
          md={12}
          sm={12}
          lg={12}
          sx={{
            paddingLeft: "8px !important",
            width: "100%",
            paddingTop: "0px !important",
          }}
        >
          <Box className="dpfleengin">
            <InputLabel
              id="demo-simple-select-label"
              className="inenlab"
              sx={{
                color: "white",
                background: "none",
              }}
            >
              ENGINE AREA
            </InputLabel>
            <Select
              sx={{
                // width:"100%",
                width: currentDevice == "Tablet" ? "50%" : "100%",
                height: 60,
                // marginRight: currentDevice == "Tablet" ? 15 : 2,
                border: "1px solid darkgrey",
                color: "black",
                bgcolor: "white",
                "& .MuiSvgIcon-root": {
                  color: "red",
                },
              }}
              labelId="demo-simple-select-label"
              className="seldivrad sdfsduyjyu"
              style={customStyles}
              IconComponent={ArrowIlmor}
              placeholder="select engine area"
              id="demo-simple-select"
              // value={age}
              value={selectedCategory}
              label="Age"
              // onChange={handleMaterialSelectChange}
              displayEmpty={true}
              renderValue={
                selectedCategory !== "" ? undefined : () => plcaHold()
              }
              // renderValue ={'sdfsdf'}
              MenuProps={{
                PaperProps: {
                  sx: {
                    // bgcolor: "#615E5C",
                    "&& .Mui-selected": {
                      backgroundColor: "#ce0e2d !important",
                      color: "white",
                    },
                    "& .MuiMenuItem-root": {
                      padding: "5px 15px",
                      color: "black",
                      fontFamily: "Montserrat",
                      justifyContent: "left",
                      fontWeight: 500,
                      fontSize: "14px",
                    },
                  },
                },
              }}
            >
              {options &&
                options.length > 0 &&
                options?.map(({ id, name }, index) => (
                  <MenuItem
                    key={index}
                    value={id}
                    onClick={() => {
                      setAge(id);
                      handleCategoryClick(id);
                    }}
                  >
                    {name}
                  </MenuItem>
                ))}

              {/* <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem> */}
            </Select>
          </Box>
        </Grid>
        {/* <Grid
        item
        sm={4}
        xs={12}
      
        sx={{
          paddingLeft: "8px !important",
          "@media (min-width:600px)": {
            display: "flex",
            justifyContent: "flex-end",
          },
          // ml: currentDevice == "Tablet" ? "0px" : "0px",
        }}
      >
        <AddEmployeeAccountBtnStyled onClick={handleSignOut}>
          {" "}
          SignOut{" "}
        </AddEmployeeAccountBtnStyled>
      </Grid> */}
      </Grid>
    </>
  );
};

export default TabletViewBlackContainerEngine;
