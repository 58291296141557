import React, { useState } from "react";
import "./Pagination.css";
import { useStore } from "../store/home";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import Select from "react-select";
import { ReactComponent as CatGrey } from "../Images/categoryGrey.svg";
import { ReactComponent as ListGrey } from "../Images/listGrey.svg";
import { ReactComponent as ArrowIlmor } from "../Images/arrowIlmor.svg";
import classnames from "classnames";
import loaderImg from "../Images/loaderImg.gif";
import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { getPartList, searcPartsYMM } from "../api/posts";
import Loader from "../commenComp/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { customStyles } from "../utils/ReactSelectCustomStyles";

const Pagination3 = ({
  disableSort,
  handleSortCallBack,
  disableView,
  sortOptions,
  startProp,
  handlePagination,
  iscardView,
}) => {
  const {
    setScreenSize,
    setCurrentDevice,
    categoryOptions,
    partListArray,
    isCard,
    setIsCard,
    screenSize,
    currentDevice,
    trecord,
    offset,
    setOffset,
    setBox,
    box,
    token,
    categoryId,
    keyWord,
    isCurrentEngSet,
    dealerId,
    sort,
    record,
    setPartListArray,
    setTRecord,
    setRecord,
    selectedCategory,
    selectedSubCategory,
    isCurrentEngSetValue,
  } = useStore();
  const handleToggleCard = () => {
    setIsCard(false);
  };
  const navigate = useNavigate();

  const handleToggleList = () => {
    setIsCard(true);
  };
  const location = useLocation();
  const {  setRefreshQ, refreshQ } = useNonPersistedStore();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if(searchParams?.get("p")){
      setOffset(parseInt(searchParams?.get("p")))
    } else {
      setOffset(0)
    }
  }, [searchParams]);

  const mutation = useMutation((post) => getPartList(post), {
    mutationKey: "getPartList",
    method: "GET",
    onSuccess: (result) => {
      setRefreshQ(!refreshQ)
      setPartListArray(result.data.data);
      setTRecord(result?.data?.total);
      setRecord(result?.data?.perPage);
    },
    onError: (error) => {},
  });

  const searchParts = useMutation((post) => searcPartsYMM(post, token), {
    onSuccess: (result) => {
      setRefreshQ(!refreshQ)
      setPartListArray(result?.data?.data);
    },
    onError: (error) => {},
  });

  const handleSearch = (currOff) => {
    
    // let arr = [];
    // if (partListArray && partListArray.length > 0) {
    //   arr = partListArray.map((item) => {
    //     item.images = loaderImg;
    //     item.isLoad = true;
    //     return item;
    //   });
    // }
    setRefreshQ(!refreshQ)
    // setPartListArray(arr);
    let sub = "";
    let cat =  searchParams?.get("category") ?? "";
    let key = keyWord;
    if (location.pathname === "/catalog") {
      sub =  searchParams?.get("subCategory") ?? "";
      cat = searchParams?.get("category") ?? "";
      key = "";
    }
    const params = {
      token: token,
      categoryId: cat,
      subCategoryId: sub,
      keyword: key,
      engineId: isCurrentEngSet?.engineId ?? "",
      dealerId: dealerId,
      perPage: 20,
      offset:  currOff,
      sortBy: sort ? sort : "partNumber",
    };
    if(location.pathname !== '/search'){
      let arr = [];
      if (partListArray && partListArray.length > 0) {
        arr = partListArray.map((item) => {
          item.images = loaderImg;
          item.isLoad = true;
          return item;
        });
      }
      setPartListArray(arr);
      mutation.mutate(params);
    }
  };

  const handleSearchEngine = (currOff) => {
    // setPartListArray([]);
    // let arr = [];
    // if (partListArray && partListArray.length > 0) {
    //   arr = partListArray.map((item) => {
    //     item.images = loaderImg;
    //     item.isLoad = true;
    //     return item;
    //   });
    // }
    // setRefreshQ(!refreshQ)
    // setPartListArray(arr);
    if (isCurrentEngSetValue) {
      // const arr = {
      //   year: isCurrentEngSetValue.year,
      //   make: isCurrentEngSetValue.make,
      //   model: isCurrentEngSetValue.model,
      //   dealerId: dealerId,
      //   categoryId: searchParams?.get("category") ?? "",
      //   subcategoryId: searchParams?.get("subCategory") ?? "",
      //   perPage: 20,
      //   offset:  currOff,
      //   sortBy: sort ? sort : "partNumber",
      // };
      // searchParts.mutate(arr);
    } else {
      let arr = [];
      if (partListArray && partListArray.length > 0) {
        arr = partListArray.map((item) => {
          item.images = loaderImg;
          item.isLoad = true;
          return item;
        });
      }
      setRefreshQ(!refreshQ)
      setPartListArray(arr);
      handleSearch(currOff);
    }
  };

  // const handleSort = (value) => {
  // };
  const [selectedOption, setSelectedOption] = useState(
    searchParams?.get("s") ?? null
  );

  const handlePage = (page) => {
    let currOff = page;
    if (page !== "next" && page !== "prev") {
      
      setOffset(page);
      if (location.pathname === "/catalog") {
        handleSearchEngine(currOff);
      } else {
        handleSearch(currOff);
      }
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("p", currOff);
      const newSearchParams = queryParams.toString();
      navigate({
        search: `?${newSearchParams}`,
      });
    } else if (page === "next") {
      if ((offset + 1) * 20 < trecord) {
        setOffset(offset + 1);
        // currOff += 1;
        if (location.pathname === "/catalog") {
          handleSearchEngine(offset + 1);
        } else {
          handleSearch(offset + 1);
        }
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("p", (offset + 1))
        const newSearchParams = queryParams.toString();
        navigate({
          search: `?${newSearchParams}`,
        });
      }
    } else if (page === "prev") {
      if (offset + 1 !== 1) {
        setOffset(offset - 1);
        // currOff += 1;
        if (location.pathname === "/catalog") {
          handleSearchEngine(offset - 1);
        } else {
          handleSearch(offset - 1);
        }
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("p", (offset - 1))
        const newSearchParams = queryParams.toString();
        navigate({
          search: `?${newSearchParams}`,
        });
      }
    }
  };

  useEffect(() => {
    handlePagination(offset);
  }, [offset]);

  useEffect(() => {
    if (trecord && trecord > 0) {
      let num = parseInt(trecord / 20);
      let numPer = parseInt(trecord % 20);
      if (!numPer) {
        setBox(num);
      } else {
        setBox(num + 1);
      }
    }
  }, [trecord]);
  return (
    <div 
    className={classnames("Pagination", {
      borderForGrey: location.pathname === '/catalog',
    })}
    
    >
      {/* {(mutation.isLoading || searchParts.isLoading )&& <Loader></Loader>} */}
      {/* {searchParts.isLoading && <Loader></Loader>} */}
      {!disableSort && (
        <div className="paginationLeftSide">
          {(!disableView || (iscardView && isCurrentEngSet)) && (
            <>
              <span className="pageView">View</span>
              <div
                onClick={handleToggleCard}
                className={classnames("catogeryIconWrapper", {
                  activeOption: !isCard,
                  inactiveOption: isCard,
                })}
                //    className={`catogeryIconWrapper ${!isCard ? ' activeOption' : ' inactiveOption'}`}
              >
                <CatGrey className="catogeryIcon"></CatGrey>
              </div>
              <div
                onClick={handleToggleList}
                className={classnames("listIconWrapper", {
                  activeOption: isCard,
                  inactiveOption: !isCard,
                })}
              >
                <ListGrey className="listIcon"></ListGrey>
              </div>
            </>
          )}
          <span className="pageSort">Sort</span>
          <Select
            // className="header__select paginantionHeaderSelect"
            defaultValue={selectedOption}
            styles={customStyles}
            isSearchable={false}
            className="minwidsort"
            value={
              searchParams?.get("s")
                ? sortOptions.filter(
                    (item) => item?.value === searchParams?.get("s")
                  )
                : sortOptions.filter((item) => item?.value === sort)
            }
            placeholder="Sorting Options"
            // styles={customStyles}
            onChange={handleSortCallBack}
            // menuIsOpen={true}
            options={sortOptions}
          />
        </div>
      )}

      {iscardView &&
      currentDevice !== "Mobile" &&
      trecord &&
      !isCurrentEngSet ? (
        <>
          <span className="pageView">View</span>
          <div className="center">
            <div
              onClick={handleToggleCard}
              className={classnames("catogeryIconWrapper", {
                activeOption: !isCard,
                inactiveOption: isCard,
              })}
              //    className={`catogeryIconWrapper ${!isCard ? ' activeOption' : ' inactiveOption'}`}
            >
              <CatGrey className="catogeryIcon"></CatGrey>
            </div>
            <div
              onClick={handleToggleList}
              className={classnames("listIconWrapper", {
                activeOption: isCard,
                inactiveOption: !isCard,
              })}
            >
              <ListGrey className="listIcon"></ListGrey>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {trecord && trecord > 0 && trecord < 100 ? (
        <div
          className={classnames("paginationRightSide ", {
            leftMarginAuto: disableSort,
            blurPage: mutation.isLoading || searchParts.isLoading,
          })}
        >
          <span className="paginationNumber">{`${
            offset < 1 ? offset + 1 : offset * 20
          }-${
            (offset + 1) * 20 < trecord ? (offset + 1) * 20 : trecord
          } of ${trecord}`}</span>
          {trecord && (
            <div className="paginationNumberBoxWhole">
              {/* <span className="paginationNumberBox activeBox">1</span> */}
              <div className="paginationNumberBoxWhole">
                {offset + 1 !== 1 && (
                  <div
                    onClick={() => {
                      handlePage("prev");
                    }}
                    className="iconWrapper rotate greay paginationNumberBox"
                  >
                    <ArrowIlmor className="icon"></ArrowIlmor>
                  </div>
                )}
                {Array(box)
                  .fill()
                  .map((item, i) => (
                    <span
                      onClick={() => {
                        handlePage(i);
                      }}
                      className={classnames("paginationNumberBox", {
                        activeBox: offset === i,
                      })}
                    >
                      {i + 1}
                    </span>
                  ))}
                {/* <span className="paginationNumberBox activeBox">1</span>
              <span className="paginationNumberBox">2</span>
              <span className="paginationNumberBox">3</span>
              <span className="paginationNumberBox">4</span> */}
                {(offset + 1) * 20 < trecord && (
                  <div
                    onClick={() => {
                      handlePage("next");
                    }}
                    className="iconWrapper greay paginationNumberBox"
                  >
                    <ArrowIlmor className="icon"></ArrowIlmor>
                  </div>
                )}
              </div>
              {/* <span className="paginationNumberBox">2</span>
          <span className="paginationNumberBox">3</span>
          <span className="paginationNumberBox">4</span> */}
              {/* <div className="iconWrapper greay paginationNumberBox">
              <ArrowIlmor className="icon"></ArrowIlmor>
            </div> */}
            </div>
          )}
        </div>
      ) : (
        <>
          {trecord && trecord > 0 ? (
            <div
              className={classnames("paginationRightSide", {
                leftMarginAuto: disableSort,
                blurPage: mutation.isLoading || searchParts.isLoading,
              })}
            >
              <span className="paginationNumber">{`${
                offset < 1
                  ? offset + 1
                  : parseInt(offset * 20).toLocaleString(undefined, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
              }-${
                (offset + 1) * 20 < trecord
                  ? (offset + 1) * 20
                  : parseInt(trecord).toLocaleString(undefined, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
              } of ${parseInt(trecord).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}`}</span>
              {trecord && (
                <div className="paginationNumberBoxWhole">
                  <div className="paginationNumberBoxWhole">
                    {offset + 1 !== 1 && (
                      <div
                        onClick={() => {
                          handlePage("prev");
                        }}
                        className="iconWrapper rotate greay paginationNumberBox"
                      >
                        <ArrowIlmor className="icon"></ArrowIlmor>
                      </div>
                    )}
                    {Array(box)
                      .fill()
                      .map((_, i) => {
                        if (
                          (i >= offset - 2 && i < offset + 2) ||
                          i === box - 1 ||
                          i === 0 ||
                          i === offset / 20 ||
                          i === offset / 20 - 1 ||
                          i === offset / 20 + 1 ||
                          (i === offset - 3 && offset > 3) ||
                          (i === offset + 3 && offset < box - 4)
                        ) {
                          if (i === offset - 3 && offset > 3) {
                            return <span key={i}>...</span>;
                          }
                          if (i === offset + 3 && offset < box - 4) {
                            return <span key={i}>...</span>;
                          }
                          return (
                            <span
                              key={i}
                              onClick={() => handlePage(i)}
                              className={classnames("paginationNumberBox", {
                                activeBox: offset === i,
                              })}
                            >
                              {i + 1}
                            </span>
                          );
                        }
                        return null;
                      })}
                    {(offset + 1) * 20 < trecord && (
                      <div
                        onClick={() => {
                          handlePage("next");
                        }}
                        className="iconWrapper greay paginationNumberBox"
                      >
                        <ArrowIlmor className="icon"></ArrowIlmor>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};

export default Pagination3;
