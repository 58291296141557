import React, { useState } from "react";
import { useStore } from "../store/home";
import "./ShopComponent.css";
import Select from "react-select";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { customStyles } from "../utils/ReactSelectCustomStyles";
export default function ShopComponent({ categoryList, cat }) {
  const {
    currentDevice,
    setSubArray,
    subArray,
    setSelectedSubCategory,
    selectedCategory,
    setSelectedCategory,
  } = useStore();
  const [selectedOption1, setSelectedOption1] = useState(cat);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    setSelectedOption1(cat);
  }, [cat]);
  const handleChange = (data) => {
    let arr = [];
    if (data?.arr && data?.arr?.length > 0) {
      arr = [...data?.arr];
      arr.unshift({ label: "All Parts", value: "" });
    }
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("category", data.value);
    queryParams.set("subCategory", "");
    queryParams.set("p", "0");
    const newSearchParams = queryParams.toString();
    navigate({
      search: `?${newSearchParams}`,
    });
    setSubArray(arr);
    setSelectedOption1(data);
    setSelectedOption2("");
    setSelectedCategory(data.value);
    setSelectedSubCategory("");
  };
  const handleChangeSub = (data) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("category", selectedCategory);
    queryParams.set("subCategory", data.value);
    queryParams.set("p", "0");
    const newSearchParams = queryParams.toString();
    navigate({
      search: `?${newSearchParams}`,
    });
    setSelectedOption2(data);
    setSelectedSubCategory(data.value);
  };

  return (
    <div className={`shopComp__main${currentDevice}`}>
      <div className={`category__container${currentDevice}`}>
        <div className={`fontHead categoryName${currentDevice}`}>CATEGORY</div>
        <Select
          // className={`shopComp__box1${currentDevice}`}
          className="werwer23 dfgdf44fw43"
          value={selectedOption1}
          styles={customStyles}
          isSearchable={false}
          onChange={handleChange}
          // placeholder="Base Engine"
          options={categoryList}
          // menuIsOpen ={true}
        />
      </div>
      <div className={`subcontainer__container${currentDevice}`}>
        <div className={`fontHead categoryName${currentDevice}`}>SUBCATEGORY</div>
        <Select
          // className={`shopComp__box1${currentDevice}`}
          value={selectedOption2}
          styles={customStyles}
          className="werwer23 "
          onChange={handleChangeSub}
          isSearchable={false}
          placeholder="All Parts"
          options={subArray || []}
          // menuIsOpen ={true}
        />
      </div>
    </div>
  );
}
