import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import "./IlmorModal.css";
import Modal from "@mui/material/Modal";
import { useNavigate, useLocation } from "react-router-dom";

import Fade from "@mui/material/Fade";

import { useNonPersistedStore } from "../store/NonPersistedStore";
import { useStore } from "../store/home";

import { ReactComponent as CloseButton } from "../Images/closeButton.svg";

const ContactUsTemplateModal = ({
  message,
  fisrtButton,
  handleClose,

  isopen,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 400,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    borderRadius: "8px",
    background:
      "var(--Blue-angle-gradient, linear-gradient(124deg, #111926 9.05%, #00556E 98.82%))",

    boxShadow: 24,
    boxShadow:
      "0px 1px 2px 0px rgba(0, 0, 0, 0.12), 0px 1px 20px 0px rgba(0, 0, 0, 0.35), 0px 1px 16px 0px rgba(0, 0, 0, 0.16)",
    p: 4,
  };
  const {
    myProfile,
    setMyProfile,
    token,
    returnReasonZustand,
    setReturnReasonZustand,
    setReturnOptionZustand,
    returnOptionZustand,
  } = useStore();
  const navigate = useNavigate();

  const [countrySelectOption, setCountrySelectOption] = useState();
  const [reasonReturnData, setReasonReturnData] = useState();

  const [returnOption, setReturnOption] = useState();

  const { setReason, reason } = useNonPersistedStore();
  const handleNavigate = () => {
    navigate("/sign-in");
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isopen}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isopen}>
          <Box sx={style}>
            <Box>
              <p style={{ textAlign: "center", padding: "20px 0px 10px 0px !important" }}>
                <img
                  height="50px"
                  src="https://ilmordev.pimcoreclient.com/_default_upload_bucket/ilmor-mail-logo1.png"
                />
              </p>
              <Box
                onClick={handleClose}
                className={`addToCart_closeBtn center curPoint`}
              >
                <CloseButton
                  className={`addToCart_closeBtnIcon `}
                ></CloseButton>
              </Box>
              <table
                cellspacing="0"
                cellpadding="0"
                border="0"
                width="100%"
                style={{
                  margin: "auto",
                  width: "600px",
                  background: "#000",
                  padding: "10px",
                  fontSize: " 16px",
                  fontFamily: "arial",
                }}
              >
                <tr>
                  <td style={{ backgroundColor: "#FFFFFF" }}>
                    <table
                      cellspacing="0"
                      cellpadding="0"
                      border="0"
                      width="100%"
                    >
                      <tr>
                        <td
                          style={{
                            backgroundColor: "#FFFFFF",
                            padding: "20px 0",
                            textAlign: "center",
                          }}
                        >
                          <table
                            cellspacing="0"
                            cellpadding="0"
                            border="0"
                            width="100%"
                          >
                            <tr style={{ borderBottom: "none" }}>
                              <td
                                style={{
                                  textAlign: "center",
                                  padding: "0px 30px",
                                }}
                              >
                                <h1
                                  style={{
                                    color: " #000",

                                    // textAlign: "center",
                                    fontFamily: "Arial",
                                  }}
                                >
                                  CONTACT FORM{" "}
                                </h1>{" "}
                                <h1
                                  style={{
                                    color: " #000",

                                    textAlign: "center",
                                    fontFamily: "Arial",
                                    marginTop: "-20px",
                                  }}
                                >
                                  SUBMISSION
                                </h1>
                                <p
                                  style={{
                                    color: "#000",
                                    textAlign: "center",
                                    fontFamily: "Arial",
                                    fontSize: "13px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                  }}
                                >
                                  Thank you for reaching out to us. Someone from{" "}
                                  <br />
                                  our team will reply to your email shortly.
                                </p>
                              </td>
                            </tr>

                            {!token && (
                              <Box>
                                <button
                                  onClick={() => handleNavigate()}
                                  // className='select'}
                                  style={{
                                    width: "fit-content",
                                    margin: "22px auto 40px",
                                    // marginTop: "22px",
                                    // marginBottom: " 23px",
                                  }}
                                  className={`ilmorButon dfds big`}

                                  //  onClick={handleSignIn}
                                >
                                  <Box
                                    component="span"
                                    sx={{ display: "inline" }}
                                  >
                                    {" "}
                                    Login to Parts Store{" "}
                                  </Box>
                                </button>
                              </Box>
                            )}
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ContactUsTemplateModal;
