import React, { useEffect, useRef, useState } from "react";
import {
  StyledRequiredField,
  StyledShippingHeading,
  StyledShippingSubHeading,
} from "../StyledComponents/ShippingAddressStyled";
import { Box, Divider, Grid } from "@mui/material";
import { useStore } from "../../store/home";
import { ContainerDivStyled } from "../StyledComponents/BusinessDetailsStyled";
import { ErrorMessageStyled } from "../StyledComponents/CommonStyled";
import { DevTool } from "@hookform/devtools";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select from "react-select";
import { businesDetailsSchema } from "../../utils/schemas/businessDetailsSchema";
import commonMessages from "../../commenComp/CommonMessages";
import { customStyles } from "../../utils/ReactSelectCustomStyles";
import TabletViewBlackContainer from "../TabletViewBlackContainer";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import {
  getMyProfile,
  getStateCity,
  updateBusinessDetails,
} from "../../api/posts";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import { useMutation } from "@tanstack/react-query";
import Loader from "../../commenComp/Loader";
import { useNavigate } from "react-router-dom";

const BusinessDetails = () => {
  const {
    dealerId,
    setSideMenuAccount,
    setStopNavigate,
    currentDevice,
    userInfo,
    token,
    myProfile,
    setMyProfile,
  } = useStore();
  const navigate = useNavigate();
  const [completePhoneNumber, setCompletePhoneNumber] = useState("");
  const [disabledCity, setDisabledCity] = useState();
  const [stateCityData, setStateCityData] = useState([]);
  const [nationalNumber, setNationalNumber] = useState();
  const [countrySelectOption, setCountrySelectOption] = useState();
  const [flagFirstRender, setflagflagFirstRender] = useState(true);
  const stateRef = useRef();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    control,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(businesDetailsSchema),
    defaultValues: {
      dealerName: myProfile?.bussinessDetailsInfo?.dealerName
        ? myProfile?.bussinessDetailsInfo?.dealerName
        : "",

      addressLine1: myProfile?.bussinessDetailsInfo?.dealerAddress1
        ? myProfile?.bussinessDetailsInfo?.dealerAddress1
        : "",
      addressLine2: myProfile?.bussinessDetailsInfo?.dealerAddress2
        ? myProfile?.bussinessDetailsInfo?.dealerAddress2
        : "",
      contactName: myProfile?.bussinessDetailsInfo?.primaryContactName
        ? myProfile?.bussinessDetailsInfo?.primaryContactName
        : "",
      primaryPhone: myProfile?.bussinessDetailsInfo?.primaryContactPhone
        ? myProfile?.bussinessDetailsInfo?.primaryContactPhone
        : "",
      contactEmail: myProfile?.bussinessDetailsInfo?.priamryContactEmail
        ? myProfile?.bussinessDetailsInfo?.priamryContactEmail
        : "",
      countryRegion: myProfile?.bussinessDetailsInfo?.dealerCountry
        ? myProfile?.bussinessDetailsInfo?.dealerCountry?.value
        : "",
      //  StateProvince:myProfile?.data?.bussinessDetailsInfo?.dealerState ?   myProfile?.data?.bussinessDetailsInfo?.dealerState?.value : "",
      city: myProfile?.bussinessDetailsInfo?.dealerCity
        ? myProfile?.bussinessDetailsInfo?.dealerCity?.label
        : "",
      zipPostalCode: myProfile?.bussinessDetailsInfo?.dealerZipCode
        ? myProfile?.bussinessDetailsInfo?.dealerZipCode
        : "",
      // username: localStorage.getItem("email") || "",
      // password: localStorage.getItem("password") || "",
    },
  });
  const mutation = useMutation((post) => getMyProfile(post, token), {
    onSuccess: (result) => {
      setMyProfile(result?.data);
    },
    onError: (error) => {},
  });
  useEffect(() => {
    const obj = {};
    obj.email = userInfo?.username;
    obj.dealerId = dealerId;
    mutation.mutate(obj);
    // mutation.mutate({currentUserEmail})
  }, []);

  const updateBusinessDetailsMutation = useMutation(
    (post) => updateBusinessDetails(post, token),
    {
      onSuccess: (result) => {
        if (result) {
          if (result.data.success) {
            showSuccessToast(result?.data?.msg);
            const email = userInfo.username;
            mutation.mutate({ email });
          } else {
            showErrorToast(result?.data?.msg);
          }
        }
      },
      onError: (error) => {},
    }
  );
  const getStateCityMutation = useMutation(
    (post) => getStateCity(post, token),
    {
      onSuccess: (result) => {
        if (result) {
          if (result.data.success) {
            if (flagFirstRender) {
              setValue(
                "StateProvince",
                myProfile?.bussinessDetailsInfo?.dealerState?.value
              );
              setflagflagFirstRender(false);
            }
            showSuccessToast(result?.data?.msg);
            setStateCityData(result?.data?.data);
          } else {
            showErrorToast(result?.data?.msg);
          }
        }
      },
      onError: (error) => {},
    }
  );

  // useEffect(() => {

  // if(stateCityData?.length>0){
  //   setflagflagFirstRender(false)
  //   setValue("StateProvince", myProfile?.data?.bussinessDetailsInfo?.dealerState?.value,myProfile?.data?.bussinessDetailsInfo?.dealerState?.label)
  // }

  // }, [stateCityData])

  let timeZone = [
    { label: "Australia", value: "australia" },
    { label: "America", value: "america" },
    { label: "India", value: "India" },
  ];

  useEffect(() => {
    setNationalNumber(myProfile?.bussinessDetailsInfo?.nationalNumber);
  }, []);

  const onSubmit = (data, event) => {
    // const filteredCity=   stateCityData?.filter((item)=>{

    //   return  item.value==getValues("StateProvince")?.value
    //    })
    // const cityValidation = getValues("StateProvince")?.cities?.find(
    //   (val) => getValues("City") === val?.label
    // );
    // if (!cityValidation) {
    //   showErrorToast("Enter Valid City");
    //   return;
    // }
    if (!nationalNumber || nationalNumber?.length === 1) {
      showErrorToast("Enter a valid phone number");
      return;
    }
    event.preventDefault();
    updateBusinessDetailsMutation.mutate({
      ...data,
      dealerId: myProfile?.bussinessDetailsInfo?.dealerId,

      //  dealerName: myProfile?.data?.bussinessDetailsInfo?.dealerName,
      //  City: cityValidation?.value,
      primaryPhone: completePhoneNumber,
      nationalNumber: nationalNumber,
    });
  };
  useEffect(() => {
    if (myProfile?.bussinessDetailsInfo?.primaryContactPhone) {
      setCompletePhoneNumber(
        myProfile?.bussinessDetailsInfo?.primaryContactPhone
      );
    }
    if (myProfile?.countryList) {
      setCountrySelectOption(myProfile?.countryList);
    }
  }, []);
  const handlePhoneNumberChange = (data, field) => {
    if (!data) {
      setCompletePhoneNumber("");
      setNationalNumber("");

      return;
    }

    try {
      const parsedNumber = parsePhoneNumber(data?.toString());
      setCompletePhoneNumber(parsedNumber?.number);
      setNationalNumber(parsedNumber?.nationalNumber);
    } catch (error) {}
  };
  useEffect(() => {
    handleCountrySelectChange(getValues("countryRegion"));
  }, [countrySelectOption]);

  const handleCountrySelectChange = (value) => {
    stateRef.current.setValue("StateProvince", null);
    // if(check){
    //   setValue("city","")
    // }
    if (value) {
      getStateCityMutation.mutate({ countryID: value });
    }
  };

  // updateBusinessDetails
  return (
    <>
      {mutation.isLoading && <Loader></Loader>}
      {/* {getStateCityMutation.isLoading && <Loader></Loader>} */}
      {updateBusinessDetailsMutation.isLoading && <Loader></Loader>}

      <Box
        sx={{
          background: "#eaeaea",
          width: "100%",
        }}
      >
        <StyledShippingHeading
          sx={{
            padding:
              currentDevice == "Tablet"
                ? "24px 28px"
                : currentDevice == "Mobile"
                ? "24px 20px"
                : "30px 60px 0px 60px ",
          }}
          currentDevice={currentDevice}
        >
          {commonMessages?.businessDetailsHeading}
        </StyledShippingHeading>
        {currentDevice == "Tablet" && (
          <TabletViewBlackContainer currentDevice={currentDevice} />
        )}
        <ContainerDivStyled currentDevice={currentDevice}>
          <Grid container>
            <Grid item xs={12} sm={12} md={9}>
              {" "}
              <Grid container spacing={1}>
                <Grid item sm={12} md={12}>
                  <StyledShippingSubHeading>
                    {commonMessages?.businessDetailsSubheading}
                  </StyledShippingSubHeading>{" "}
                </Grid>
                <Grid item sm={8} md={12}>
                  <StyledRequiredField>Required fields</StyledRequiredField>
                </Grid>
              </Grid>
              <Divider sx={{ margin: "35px 0px" }} />
              <Grid container spacing={1}>
                <Grid item sm={8} md={12}>
                  <StyledShippingSubHeading
                    sx={{
                      fontWeight: "700",
                      marginBottom: currentDevice == "Desktop" ? "-13px" : "",
                    }}
                  >
                    DETAILS
                  </StyledShippingSubHeading>
                </Grid>
                <Grid item sm={12} md={12}>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={`form shippingForm`}
                    noValidate
                  >
                    <div>
                      <h5 className={`labelDesktopAddShippingRequired`}>
                        BUSINESS LEGAL NAME
                      </h5>
                    </div>
                    <input
                      // style={{ border: "none" }}
                      className={`InputFieldShippingAddress`}
                      type="text"
                      // value={email}
                      name="dealerName"
                      // onChange={handleEmailChange}
                      {...register("dealerName", {
                        disabled:
                          !myProfile?.profileInfo?.permissions?.includes(
                            "manage_bussiness_details"
                          ),
                      })}
                    />
                    {errors?.dealerName && (
                      <ErrorMessageStyled>
                        {errors.dealerName?.message}
                      </ErrorMessageStyled>
                    )}

                    <div>
                      <h5 className={`labelDesktopAddShippingRequired`}>
                        Address Line 1
                      </h5>
                    </div>
                    <input
                      // style={{ border: "none" }}
                      maxLength={100}
                      className={`InputFieldShippingAddress`}
                      type="text"
                      // value={email}
                      name="addressLine1"
                      // onChange={handleEmailChange}
                      {...register("addressLine1", {
                        disabled:
                          !myProfile?.profileInfo?.permissions?.includes(
                            "manage_bussiness_details"
                          ),
                      })}
                    />
                    {errors?.addressLine1 && (
                      <ErrorMessageStyled>
                        {errors?.addressLine1?.message}
                      </ErrorMessageStyled>
                    )}
                    <div>
                      <h5 className={`labelDesktopAddShipping`}>
                        Address Line 2
                      </h5>
                    </div>
                    <input
                      // style={{ border: "none" }}
                      className={`InputFieldShippingAddress`}
                      maxLength={100}
                      type="text"
                      // value={email}
                      name="addressLine2"
                      // onChange={handleEmailChange}
                      {...register("addressLine2", {
                        disabled:
                          !myProfile?.profileInfo?.permissions?.includes(
                            "manage_bussiness_details"
                          ),
                      })}
                    />
                    <div className={`passwordlabelDesktopAddShipping`}>
                      <h5 className={`labelDesktopAddShippingRequired`}>
                        COUNTRY / REGION
                      </h5>
                    </div>
                    <Controller
                      // rules={{ required: true }}
                      name="countryRegion"
                      control={control}
                      render={({ onChange, value, ref, field }) => {
                        return (
                          <Select
                            className="padselect"
                            isDisabled={
                              !myProfile?.profileInfo?.permissions?.includes(
                                "manage_bussiness_details"
                              )
                            }
                            // className='select'
                            {...field}
                            options={countrySelectOption}
                            value={countrySelectOption?.find(
                              (option) => option.value === field.value
                            )}
                            // value={}
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption?.value);
                              handleCountrySelectChange(selectedOption?.value);
                            }}
                            styles={customStyles}
                            // menuIsOpen={true}
                            // defaultValue={userRole.find((c) => c.value === countryValue)}
                          />
                        );
                      }}
                    />

                    {errors?.countryRegion && (
                      <ErrorMessageStyled>
                        {errors?.countryRegion?.message}
                      </ErrorMessageStyled>
                    )}
                    <Grid container spacing={1}>
                      <Grid
                        item
                        sm={4}
                        xs={12}
                        md={4}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div className={`emailLabel`}>
                          <h5 className={`labelDesktopAddShippingRequired`}>
                            STATE / PROVINCE
                          </h5>
                        </div>
                        <Controller
                          // rules={{ required: true }}
                          name="StateProvince"
                          control={control}
                          render={({ onChange, value, ref, field }) => {
                            return (
                              <Select
                                // className="header__select"
                                className="padselect"
                                isDisabled={
                                  !getValues("countryRegion") ||
                                  !myProfile?.profileInfo?.permissions?.includes(
                                    "manage_bussiness_details"
                                  )
                                }
                                placeholder={<div></div>}
                                // className='select'
                                {...field}
                                value={stateCityData?.find(
                                  (option) => option.value === field.value
                                )}
                                isLoading={getStateCityMutation.isLoading}
                                options={stateCityData}
                                // value={timeZone.find((c) => c.value === value)}
                                // onChange={(selectedOption) => {
                                //   handleSelectChange(selectedOption?.label,selectedOption?.value);
                                // }}
                                onChange={(selectedOption) => {
                                  field?.onChange(selectedOption?.value);
                                }}
                                ref={stateRef}
                                styles={customStyles}
                                //  menuIsOpen={true}
                                // defaultValue={userRole.find((c) => c.value === countryValue)}
                              />
                            );
                          }}
                        />

                        {errors?.StateProvince && (
                          <ErrorMessageStyled>
                            {errors?.StateProvince?.message}
                          </ErrorMessageStyled>
                        )}
                      </Grid>
                      <Grid
                        item
                        md={4}
                        xs={12}
                        className="dfklgnmojer"
                        sm={4}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div className={`emailLabel`}>
                          <h5 className={`labelDesktopAddShippingRequired`}>
                            CITY
                          </h5>
                        </div>
                        <input
                          // style={{ border: "none" }}
                          className={`InputFieldShippingAddress`}
                          disabled={!getValues("countryRegion")}
                          type="text"
                          // value={email}
                          name="city"
                          // onChange={handleEmailChange}
                          {...register("city", {
                            disabled:
                              !myProfile?.profileInfo?.permissions?.includes(
                                "manage_bussiness_details"
                              ),
                          })}
                        ></input>{" "}
                        {errors?.city && (
                          <ErrorMessageStyled>
                            {errors?.city?.message}
                          </ErrorMessageStyled>
                        )}
                      </Grid>
                      <Grid
                        item
                        md={4}
                        xs={12}
                        className="dfklgnmojer"
                        sm={4}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div className={`emailLabel`}>
                          <h5 className={`labelDesktopAddShippingRequired`}>
                            ZIP POSTAL CODE
                          </h5>
                        </div>
                        <input
                          // style={{ border: "none" }}
                          className={`InputFieldShippingAddress`}
                          type="text"
                          maxlength="12"
                          // value={email}
                          name="zipPostalCode"
                          // onChange={handleEmailChange}
                          {...register("zipPostalCode", {
                            disabled:
                              !myProfile?.profileInfo?.permissions?.includes(
                                "manage_bussiness_details"
                              ),
                          })}
                        ></input>
                        {errors?.zipPostalCode && (
                          <ErrorMessageStyled>
                            {errors?.zipPostalCode?.message}
                          </ErrorMessageStyled>
                        )}
                      </Grid>
                    </Grid>
                    <div className="jhkjhrek343">
                      <StyledShippingSubHeading
                        sx={{ fontWeight: "700", lineHeight: "50px",letterSpacing:'1.5px' }}
                      >
                        PRIMARY CONTACT
                      </StyledShippingSubHeading>
                    </div>
                    <StyledShippingSubHeading
                      sx={{ fontSize: "13px", fontWeight: "500" }}
                    >
                      This is separate from a user account.
                    </StyledShippingSubHeading>
                    <div>
                      <h5 className={`labelDesktopAddShippingRequired`}>
                        NAME
                      </h5>
                    </div>
                    <input
                      // style={{ border: "none" }}
                      className={`InputFieldShippingAddress`}
                      type="text"
                      // value={email}
                      name="contactName"
                      // onChange={handleEmailChange}
                      {...register("contactName", {
                        disabled:
                          !myProfile?.profileInfo?.permissions?.includes(
                            "manage_bussiness_details"
                          ),
                      })}
                    />
                    {errors?.contactName && (
                      <ErrorMessageStyled>
                        {errors.contactName?.message}
                      </ErrorMessageStyled>
                    )}
                    <div className={`passwordlabelDesktopAddShipping`}>
                      <h5 className={`labelDesktopAddShippingRequired`}>
                        PRIMARY PHONE
                      </h5>
                    </div>
                    <Controller
                      // rules={{ required: true }}
                      name="primaryPhone"
                      control={control}
                      render={({ onChange, value, onBlur, ref, field }) => {
                        return (
                          <PhoneInput
                            disabled={
                              !myProfile?.profileInfo?.permissions?.includes(
                                "manage_bussiness_details"
                              )
                            }
                            {...field}
                            // value={userRole.find((c) => c.value === value)}
                            // onChange={(selectedOption) => {
                            //   handleSelectChange(selectedOption?.label,selectedOption?.value);
                            // }}
                            // countries =  {["RU", "UA", "KZ","IN","PK","US","NZ"]}
                            placeholder="Enter Phone Number"
                            // onChange={(data)=>handlePhoneNumberChange(data,field)}
                            onChange={handlePhoneNumberChange}
                            //  international
                            countryCallingCodeEditable={false}
                            addInternationalOption={false}
                            defaultCountry="US"
                            className="ReactPhoneNumInputt"
                            minLength="7"
                            limitMaxLength={true}
                            style={{
                              backgroundColor: "white",
                              border: "none",
                              padding: "0px 10px",
                            }}
                          />
                        );
                      }}
                    />
                    {errors?.primaryPhone && (
                      <ErrorMessageStyled>
                        {errors.primaryPhone?.message}
                      </ErrorMessageStyled>
                    )}

                    <div className={`emailLabel`}>
                      <h5 className={`labelDesktopAddShippingRequired`}>
                        EMAIL 1
                      </h5>
                    </div>
                    <input
                      // style={{ border: "none" }}
                      className={`InputFieldShippingAddress`}
                      type="text"
                      // value={email}
                      name="contactEmail"
                      // onChange={handleEmailChange}
                      {...register("contactEmail", {
                        disabled:
                          !myProfile?.profileInfo?.permissions?.includes(
                            "manage_bussiness_details"
                          ),
                      })}
                    ></input>
                    {errors?.contactEmail && (
                      <ErrorMessageStyled>
                        {errors.contactEmail?.message}
                      </ErrorMessageStyled>
                    )}
                    <Divider sx={{ margin: "40px 0px" }} />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        flexDirection: "row",
                        flexGrow: 1,
                        my: 1.5,
                        gap: 2,
                        alignItems: "center",

                        borderRadius: 1,
                      }}
                    >
                      <button
                        type="button"
                        style={{ marginRight: "14px" }}
                        className={`cancelButonAddShipping`}
                        onClick={() => {
                          setSideMenuAccount("home");
                          setStopNavigate(true);
                          navigate({
                            pathname: "/account",
                          });
                        }}
                      >
                        Cancel
                      </button>
                      {!(
                        myProfile?.profileInfo?.role === "ServiceTechnician" ||
                        myProfile?.profileInfo?.role === "PurchasingManager"
                      ) && (
                        <button
                          disabled={
                            !myProfile?.profileInfo?.permissions?.includes(
                              "manage_bussiness_details"
                            )
                          }
                          // className='select'}
                          className={`ilmorButon`}
                          //  onClick={handleSignIn}
                        >
                          Update
                        </button>
                      )}
                    </Box>
                  </form>
                </Grid>

                <DevTool control={control} />
              </Grid>
            </Grid>
          </Grid>
          <Box>
            {" "}
            {currentDevice == "Mobile" && (
              <TabletViewBlackContainer currentDevice={currentDevice} />
            )}
          </Box>
        </ContainerDivStyled>
      </Box>
    </>
  );
};

export default BusinessDetails;
