import axios from "axios";
import apiEndpoints from "../api/apiEndpoints";
import { API_URL } from "../config/index";
const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Methods": "POST",
  "Access-Control-Allow-Headers": "Content-Type",
};
export async function fetchPosts() {
  const response = await fetch(
    `${API_URL}${apiEndpoints.GET_FEATURED_PRODUCTS}`
  );
  return response.json();
}

export async function loginToken(data) {
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.GET_LOGIN_TOKEN}`,
    data,
    { headers: headers }
  );
}

export async function getProductListCategory(data) {
  const headers = {
    Authorization: `Bearer ${data.token}`,
  };
  return axios.get(
    `${apiEndpoints.API_URL}${
      apiEndpoints.GET_PRODUCT_LISTING_BY_CATEGORY
    }?engineId=${data.EngineId ? data.EngineId : ""}&dealerId=${data.dealerId ? data.dealerId : ""}`,
    { headers: headers }
  );
}
export async function getGetAllBanners(token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.get(`${apiEndpoints.API_URL}${apiEndpoints.GET_ALL_BANNERS}`, {
    headers: headers,
  });
}
export async function getShippingNotice(token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.get(`${apiEndpoints.API_URL}${apiEndpoints.GET_SHIP_NOTICE}`, {
    headers: headers,
  });
}

export async function getBus(token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.get(`${apiEndpoints.API_URL}${apiEndpoints.BUSSINESS}`, {
    headers: headers,
  });
}

export async function updateBus(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.UPDATE_BUSSINESS}`,
    data,
    { headers: headers }
  );
}

export async function getPartDetail(data) {
  const headers = {
    Authorization: `Bearer ${data.token}`,
  };
  if (data?.currentEngine) {
    return axios.get(
      `${apiEndpoints.API_URL}${apiEndpoints.GET_PART_DETAIL}${data.number}/${data.currentEngine}?dealerId=${data.dealerId}`,
      { headers: headers }
    );
  } else {
    return axios.get(
      `${apiEndpoints.API_URL}${apiEndpoints.GET_PART_DETAIL}${data.number}?dealerId=${data.dealerId}`,
      { headers: headers }
    );
  }
}

export async function getPartList(param) {
  const headers = {
    Authorization: `Bearer ${param.token}`,
  };
  return axios.get(
    `${apiEndpoints.API_URL}${apiEndpoints.GET_PART_LIST}?dealerId=${
      param.dealerId
    }&categoryId=${param.categoryId}&subCategoryId=${
      param.subCategoryId
    }&perPage=${param.perPage}&sortBy=${param.sortBy}&offset=${
      param.offset
    }&keyword=${param.keyword}&engineId=${
      param.engineId ? param.engineId : ""
    }`,
    { headers: headers }
  );
}

export async function getMyWishList(param) {
  const headers = {
    Authorization: `Bearer ${param.token}`,
  };
  return axios.get(
    `${apiEndpoints.API_URL}${apiEndpoints.GET_WISH_LIST}?dealerId=${param.dealerId}`,
    { headers: headers }
  );
}

export async function forgotPassword(data) {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.FORGOT_PASSWORD}`,
    data,
    { headers: headers }
  );
}
export async function addEditShippingAddress(data, token) {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.ADD_EDIT_SHIPPING_ADDRESS}`,
    data,
    { headers: headers }
  );
}
export async function addEmployeeAccount(data, token) {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.ADD_EMPLOYEE_ACCOUNT}`,
    data,
    { headers: headers }
  );
}

export async function addWishList(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.ADD_WISH_LIST}`,
    data,
    { headers: headers }
  );
}

export async function resetModifyId(token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.RESET_MODIFY_ID}`,{},{ headers: headers }
  );
}

export async function updateWishList(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.UPDATE_WISH_LIST}`,
    data,
    { headers: headers }
  );
}

export async function addToCart(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.ADD_TO_CART}`,
    data,
    { headers: headers }
  );
}

export async function removeFromCart(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.REMOVE_CART}`,
    data,
    { headers: headers }
  );
}

export async function dealerLoginAPI(data) {
  const headers = {
    "Access-Control-Allow-Headers": "X-Custom-Auth",
    "Access-Control-Allow-Origin": "*",
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.DEALER_LOGIN}`,
    data,
    { headers: headers }
  );
}

export async function checkAvail(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.CHECK_AVAILIBILITY}`,
    data,
    { headers: headers }
  );
}

export async function searcPartsYMM(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.GET_PARTS_FOR_SPECIFIC}`,
    data,
    { headers: headers }
  );
}

export async function removeItemWish(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.REMOVE_ITEM_WISHLIST}`,
    data,
    { headers: headers }
  );
}

export async function authorizeNet(data) {
  return axios.post(`${process.env.REACT_APP_AUTH_API}`, data);
}

export async function getAllDropdownOptions(param) {
  const headers = {
    Authorization: `Bearer ${param.token}`,
  };
  return axios.get(
    `${apiEndpoints.API_URL}${apiEndpoints.GET_ALL_DROPDOWN_VALUES}?year=${
      param.year
    }${param.make ? `&make=${param.make}` : ""}`,
    { headers: headers }
  );
}
export async function getSVG(param) {
  const headers = {
    Authorization: `Bearer ${param.token}`,
  };
  return axios.get(
    `${apiEndpoints.API_URL}${apiEndpoints.GET_SVG}/${
      param.engineId ? param.engineId : ""
    }`,
    { headers: headers }
  );
}
export async function getSvgHotSpot(param) {
  const headers = {
    Authorization: `Bearer ${param.token}`,
  };
  return axios.get(
    `${apiEndpoints.API_URL}${apiEndpoints.GET_SVG_HOTSPOT}/${
      param.engineId ? param.engineId : ""
    }/${param.hotspotId}/${param.dealerId ? param.dealerId : 'dealerId'}`,
    { headers: headers }
  );
}

export async function getMyProfile(data, token) {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.GET_MY_PROFILE}`,
    data,
    { headers: headers }
  );
}
export async function storeAdditionalTaxDetails(data, token) {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.TAX_EXEMPT_FORM}`,
    data,
    { headers: headers }
  );
}
export async function selectShippingAddress(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/x-www-form-urlencoded",
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.SELECT_SHIPPING_ADDRESS}`,
    data,
    { headers: headers }
  );
}
export async function removeShippingAddress(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/x-www-form-urlencoded",
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.REMOVE_SHIPPING_ADDRESS}`,
    data,
    { headers: headers }
  );
}
export async function getAllOrderHistory(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/x-www-form-urlencoded",
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.GET_ALL_ORER_HISTORY}`,
    data,
    { headers: headers }
  );
}

export async function getAllOrderHistoryRep(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/x-www-form-urlencoded",
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.REPORT}`,
    data,
    { headers: headers }
  );
}

export async function getAllDealer(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/x-www-form-urlencoded",
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.GET_ALL_DEALER}`,
    data,
    { headers: headers }
  );
}

export async function deleteDealerUser(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/x-www-form-urlencoded",
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.DELETE_DEALER}`,
    data,
    { headers: headers }
  );
}

export async function updateProfileInformation(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/x-www-form-urlencoded",
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.UPDATE_PROFILE_INFORMATION}`,
    data,
    { headers: headers }
  );
}
export async function shippingAddressDefault(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/x-www-form-urlencoded",
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.SHIPPING_ADDRESS_DEFAULT}`,
    data,
    { headers: headers }
  );
}

export async function updateMyProfile(data, token) {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.UPDATE_MY_PROFILE}`,
    data,
    { headers: headers }
  );
}

export async function updateBusinessDetails(data, token) {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.UPDATE_BUSINESS_DETAILS}`,
    data,
    { headers: headers }
  );
}
export async function getStateCity(data, token) {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.GET_STATE_CITY}`,
    data,
    { headers: headers }
  );
}
export async function getMyCart(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.GET_MY_CART}`,
    data,
    { headers: headers }
  );
}

export async function getRefresh(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.GET_REFRESH}`,
    data,
    { headers: headers }
  );
}

export async function ups(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.post(`${apiEndpoints.API_URL}${apiEndpoints.UPS_RATE}`, data, {
    headers: headers,
  });
}

export async function logAPI(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.post(`${apiEndpoints.API_URL}${apiEndpoints.LOG_API}`, data, {
    headers: headers,
  });
}

export async function createOrder(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.CREATE_ORDER}`,
    data,
    { headers: headers }
  );
}

export async function dhl(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.post(`${apiEndpoints.API_URL}${apiEndpoints.DHL_RATE}`, data, {
    headers: headers,
  });
}
export async function applyCoupon(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.APPLY_COUPON}`,
    data,
    { headers: headers }
  );
}
export async function getShipRate(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.GET_SHIP_RATE}`,
    data,
    { headers: headers }
  );
}
export async function updatePayInfo(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.UPDATE_PAY_INFO}`,
    data,
    { headers: headers }
  );
}
export async function getOrder(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.post(`${apiEndpoints.API_URL}${apiEndpoints.GET_ORDER}`, data, {
    headers: headers,
  });
}
export async function cencelOrder(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.CENCEL_ORDER}`,
    data,
    { headers: headers }
  );
}
export async function signInInfo(data, token) {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.SIGN_IN_INFO}`,
    data,
    { headers: headers }
  );
}
export async function getPay(data, token) {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: `Bearer ${token}`,
  };
  return axios.post(`${apiEndpoints.API_URL}${apiEndpoints.GET_PAY}`, data, {
    headers: headers,
  });
}
export async function storePay(data, token) {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: `Bearer ${token}`,
  };
  return axios.post(`${apiEndpoints.API_URL}${apiEndpoints.STORE_PAY}`, data, {
    headers: headers,
  });
}

export async function getShippingPolicy(data, token) {
  // const headers = {
  //   'Authorization': `Bearer ${token}`,
  // }
  if (data && data?.length > 0) {
    return axios.get(
      `${apiEndpoints.API_URL}${apiEndpoints.GET_SHIPPING_POLICY}/${data}`

      // { headers: headers }
    );
  }
}

export async function getShippingPolicy1(data, token) {
  // const headers = {
  //   'Authorization': `Bearer ${token}`,
  // }
  if (data && data?.length > 0) {
    return axios.get(
      `${apiEndpoints.API_URL}${apiEndpoints.GET_SHIPPING_POLICY}/${data}`

      // { headers: headers }
    );
  }
}
export async function getFooterLinks(token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.get(
    `${apiEndpoints.API_URL}${apiEndpoints.GET_FOOTER_CONTENT}`
    // { headers: headers }
  );
}
export async function getReturnReasonListing(token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.get(
    `${apiEndpoints.API_URL}${apiEndpoints.GET_RETURN_REASON_LISTING}`,
    { headers: headers }
    // { headers: headers }
  );
}

export async function postContactFormdata(data, token) {
  const headers = {
    // 'Authorization': `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.POST_CONTACT_FORM_DATA}`,
    data,
    { headers: headers }
  );
}

export async function modifyOrder(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.MODIFY_ORDER}`,
    data,
    { headers: headers }
  );
}

export async function getFee(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.post(`${apiEndpoints.API_URL}${apiEndpoints.GET_FEES}`, data, {
    headers: headers,
  });
}

export async function returnOrderAPI(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.RETURN_ORDER}`,
    data,
    { headers: headers }
  );
}

export async function updateOrderSummay(data, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.post(
    `${apiEndpoints.API_URL}${apiEndpoints.UPDATE_ORDER_SUMMARY}`,
    data,
    { headers: headers }
  );
}
