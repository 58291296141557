import React,{useState, useCallback, useEffect} from 'react'
import "./TaxCertificate.css";
import logo from "../Images/ILMOR_LOGO.png";
import { useForm, FormField } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
// import { taxCertificate } from "../api/posts";
import { useStore } from "../store/home";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ReactComponent as CALENDAR } from '../Images/calendar.svg';

const TaxCertificate = () => {
  const { currentDevice } = useStore();
  const navigate = useNavigate();
  // const mutation = useMutation((post) => taxCertificate(post), {
  //   onSuccess: (result) => {
  //     navigate({
  //       pathname: "/home",
  //   });
  //   },
  //   onError: (error) => {
  //   },
  // });t78to8
  const onSubmit = (data, event) => {
    event.preventDefault();
  };

  const schema = yup.object().shape({
    username: yup.string().email("Invalid email").required("Email is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [file, setFile] = useState()

  useEffect(() => {
    const actualBtn = document?.getElementById('actual-btn');
    const fileChosen = document?.getElementById('file-chosen');
    actualBtn.addEventListener('change', function(){
      fileChosen.textContent = this.files[0].name
    })
  }, []);

    
  function handleFile(event) {
  setFile(event.target.files[0])
  }
  function handleUpload() {
  }

  const [startDate, setStartDate] = useState();
  const [expiryDate, setExpiryDate] = useState();
  
  // const [startDate, setStartDate] = useState(new Date());
  // const [expiryDate, setExpiryDate] = useState(new Date());
  

var month = startDate?.getUTCMonth() + 1; //months from 1-12
var day = startDate?.getUTCDate();
var year = startDate?.getUTCFullYear();

let newdate = year + "/" + month + "/" + day;
  return (
    <>
      <form className='form_container'>
        <h5>EXEMPTION REASON</h5>
        <textarea name="exReason" rows={10} cols={100} />
        <div className='caldate'>
          <div>
            <h5>SIGNED DATE</h5>
            <CALENDAR className="icon"></CALENDAR>
            {/* <DatePicker value={date} onChange={(newdate) => setValue(newdate)} /> */}
            <DatePicker dateFormat={'MM/dd/yy'} selected={startDate} inputMode="none" onChange={(date) => setStartDate(date)}  onFocus={(e) => e.target.readOnly = true}/>     
          </div>                                                                                                                                                                                                
         <div className='datepic'>
            <h5>EXPIRATION DATE</h5>
            <CALENDAR className="icon"></CALENDAR>
            <DatePicker dateFormat={'MM/dd/yy'} selected={expiryDate} inputMode="none" onChange={(date) => setExpiryDate(date)}  onFocus={(e) => e.target.readOnly = true}/>
         </div>
        </div>

        <h5>UPLOAD DOCUMENT</h5>
        {/* <input type="file" name ="file" onChange={handleFile}></input> */}
        <input type="file" id="actual-btn" hidden/>
        <label for="actual-btn">Choose File</label>
        <span id="file-chosen">No file chosen</span>

        <div className='btn_cancel'>
          <button className={`TE_button`}>Cancel</button>
          <button className={`ilmorButon`} >Add Tax Exemption Form</button>
      </div>
      </form>

      {/* <hr /> */}
     
    </>
    // <>
    //   <div className={`TaxCert_main`}>
    //     <div className={`TaxCert_Container`}>
    //       <div className={`TaxCert_logo`}>
    //         <img
    //           className={`TaxCert_title-image`}
    //           src={logo}
    //           alt="company-logo"
    //         />
    //       </div>
    //       <div className={`TaxCert_info__main`}>
    //           <h2 className={`TaxCert_heading`}>TAX CERTIFICATE</h2>
    //           <hr className={`TaxCert_lineBreak__main`}></hr>
    //            <form
    //           onSubmit={handleSubmit(onSubmit)}
    //           className={`TaxCert_form`}
    //           >
    //           <div className={`certificate_upload`}>
    //             <label>Certificate Number:   </label>
    //             <input type="text" name="Certificate Number" placeholder="Certificate Number" /> 
              
    //             <label>Upload Certificate</label>
    //             <input type="file" name ="file" onChange={handleFile}></input>
    //             <button>Upload</button>
                
    //           </div>
    //         <div>
    //             <span>Start Date</span>
    //             <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />          
    //             <br />                                                                                          
    //             <span>Expiry Date</span>                                                                                                                                                                                            
    //             <DatePicker selected={expiryDate} onChange={(date) => setExpiryDate(date)} />
    //         </div>
    //         <div className='submitSkip'>
    //           <div>
    //             <button className={`Submit_btn`}> 
    //             Submit
    //             </button>
    //         </div>
    //           <a href=' 'className='onlySkip'>Skip</a>
    //         </div>
    //         </form>

    //       </div>
    //     </div>
    //   </div>
    // </>
  );
};

export default TaxCertificate;
