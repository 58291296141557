import React from "react";
import { useState } from "react";
import "./NavigationBar.css";
import { useStore } from "../store/home";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowIlmor } from "../Images/arrowBack.svg";

const NavigationBar = ({ heading }) => {
  const { checkoutState, setCheckoutState, currentDevice } = useStore();
  const navigate = useNavigate();
  const handleCheckoutState = (data) => {
    // if (checkoutState !== 'order') {
    setCheckoutState(data);
    // }
  };

  const handleBack = (data) => {
    navigate({
      pathname: "/cart",
    });
  };

  return (
    <div className="navigation">
      <div className="leftSide">
        <div
          onClick={() => {
            handleBack();
          }}
          className="ilmorButon"
        >
          <ArrowIlmor className="catogeryIcon"></ArrowIlmor>
          <div style={{ marginLeft: "12px" }}>Back to Cart</div>
        </div>
      </div>
      {currentDevice !== "Mobile" ? (
        <div className="rightSide width navigationBar">
          <div
            // onClick={() => {
            //   handleCheckoutState("ship");
            // }}
            className={`box1 ${checkoutState === "ship" ? " ActiveBox1" : ""}`}
          >
            <span>1. Shipping & Payment</span>
          </div>
          <div
            // onClick={() => {
            //   handleCheckoutState("review");
            // }}
            className={`box2 ${
              checkoutState === "review" ? " ActiveBox1" : ""
            }`}
          >
            <span>2. review Order</span>
          </div>
          <div
            // onClick={() => {
            //   handleCheckoutState("order");
            // }}
            className={`box3 ${checkoutState === "order" ? " ActiveBox1" : ""}`}
          >
            <span>3. Order Complete</span>
          </div>
        </div>
      ) : (
        <div>
          {checkoutState === "ship" ? (
            <div>
              <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
              <div className="outdiv">
                <div className="indiv">1. Shipping & Payment </div>
                <div className="opbg">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.&nbsp;&nbsp;
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {checkoutState === "review" ? (
            <div>
              <div className="outdiv outrit">
              <div className="opbg">Payment&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <div className="indiv">2. Review order</div>
                <div className="opbg" style={{width : '86px'}}>&nbsp;&nbsp;&nbsp;&nbsp;3. Order</div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {checkoutState === "order" ? (
            <div>
              <div className="outdiv popo">
              <div className="opbg">ORDER&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <div className="indiv">3. ORDER COMPLETE </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};

export default NavigationBar;
