import React, { useEffect, useState } from "react";
import { useStore } from "../store/home";
import "./SideMenu.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { initialState } from "../commonFunction";
import { useSearchParams } from "react-router-dom";
// import { categoryList } from "../Constants";

export default function SideMenu({ data, title, signOutShow }) {
  const navigate = useNavigate();
  const {
    token,
    selectedCategory,
    setSelectedCategory,
    setSideMenuAccount,
    selectedSubCategory,
    setSelectedSubCategory,
    sideMenuAccount,
    currentDevice,
    setLastPage,
    stopNavigate,
    setStopNavigate,
    setFooterContentdataInZustand,
    setToken,
    setOffset,
  } = useStore();
  const location = useLocation();
  const handleCategoryClick = (cat) => {
    if (location.pathname.includes("/account")) {
      window.scrollTo(0,0)
      if (cat.id === "home") {
        navigate({
          pathname: "/account",
        });
      }
      if (cat.id === "orderDefault") {
        navigate({
          pathname: "/account/orders",
        });
      }
      if (cat.id === "employee") {
        navigate({
          pathname: "/account/mgmt/accounts",
        });
      }
      if (cat.id === "myProfile") {
        navigate({
          pathname: "/account/profile",
        });
      }
    } else {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("category", cat.id);
      queryParams.set("subCategory", "");
      queryParams.set("p", "0");
      const newSearchParams = queryParams.toString();
      navigate({
        search: `?${newSearchParams}`,
      });
    }
    setOffset(0);
    if (
      cat.id === "employee" &&
      (selectedSubCategory === "" || selectedSubCategory === "employee")
    ) {
      setSelectedSubCategory("employee");
    } else {
      setSelectedSubCategory("");
    }
    setSideMenuAccount(cat.id);
    setSelectedCategory(cat.id);
  };
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams?.get("category")) {
      setSelectedCategory(searchParams?.get("category"));
      setSideMenuAccount(searchParams?.get("category"));
    }
    if (location.pathname.includes("/account")) {
      if (location.pathname === "/account") {
        setSelectedCategory("home");
      }
      if (location.pathname === "/account/orders") {
        setSelectedCategory("orderDefault");
      }
      if(location.pathname === '/account/order-detail'){
        if(sideMenuAccount !== 'orderModify' || sideMenuAccount !== 'orderCencel' || sideMenuAccount !== 'orderDetail' ||  sideMenuAccount !== 'returnOrder' )
        {
          // setSideMenuAccount('orderDetail')
        }
      }
      if (location.pathname === "/account/mgmt/accounts" || location.pathname === "/account/mgmt/accounts/add" || location.pathname === "/account/mgmt/accounts/edit"  || location.pathname === "/account/mgmt/shipping/add" || location.pathname === "/account/mgmt/shipping/edit") {
        setSelectedCategory("employee");
      }
      if (location.pathname === "/account/profile") {
        setSelectedCategory("myProfile");
      }
      if (location.pathname === "/account/mgmt/accounts"|| location.pathname === "/account/mgmt/accounts/add" || location.pathname === "/account/mgmt/accounts/edit") {
        setSelectedSubCategory("employee");
      }
      if (location.pathname === "/account/mgmt/business") {
        setSelectedSubCategory("businessDetails");
      }
      if (location.pathname === "/account/mgmt/shipping"  || location.pathname === "/account/mgmt/shipping/add" || location.pathname === "/account/mgmt/shipping/edit") {
        setSelectedSubCategory("shipping");
      }
      if (location.pathname === "/account/mgmt/payment") {
        setSelectedSubCategory("paymentMethods");
      }
      if (location.pathname === "/account/mgmt/tax") {
        setSelectedSubCategory("tax");
      }
    }
  }, [searchParams, location]);

  const handleSubCategoryClick = (event, subCat) => {
    if (location.pathname.includes("/account")) {
      window.scrollTo(0,0)
      if (subCat.id === "employee") {
        navigate({
          pathname: "/account/mgmt/accounts",
        });
      }
      if (subCat.id === "businessDetails") {
        navigate({
          pathname: "/account/mgmt/business",
        });
      }
      if (subCat.id === "shipping") {
        navigate({
          pathname: "/account/mgmt/shipping",
        });
      }
      if (subCat.id === "paymentMethods") {
        navigate({
          pathname: "/account/mgmt/payment",
        });
      }
      if (subCat.id === "tax") {
        navigate({
          pathname: "/account/mgmt/tax",
        });
      }
    } else {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("category", selectedCategory);
      queryParams.set("subCategory", subCat.id);
      queryParams.set("p", "0");
      const newSearchParams = queryParams.toString();
      navigate({
        search: `?${newSearchParams}`,
      });
    }
    setOffset(0);
    if (event) {
      event.stopPropagation();
    }
    setSelectedSubCategory(subCat.id);
    setSideMenuAccount(subCat.id);
  };

  const resetStore = () => {
    useStore.setState(initialState);
  };

  const handleSignOut = () => {
    setLastPage(false);
    localStorage.removeItem("token");
    localStorage.removeItem("my-persisted-store");
    setSideMenuAccount("home");
    setSelectedCategory("home");
    resetStore();
    setFooterContentdataInZustand([]);
    navigate("/sign-in", { replace: true });
    setToken("");
  };

  useEffect(() => {
    if (stopNavigate) {
      const obj = {};
      obj.id = sideMenuAccount;
      handleCategoryClick(obj);
      setStopNavigate(false);
    }
  }, [sideMenuAccount]);

  return (
    <>
      <div className={`sidemenu__maincontainer${currentDevice}`}>
        <div className={`sidemenu__category${currentDevice}`}>
          <div className={`sidemenu__categoryTitleBox${currentDevice}`}>
            <h2 className={`sidemenu__categoryLabel${currentDevice}`}>
              {!signOutShow ? "CATEGORY" : "ACCOUNT"}
            </h2>
            {/* {title} */}
          </div>
          <div className={`sidemenu__categoryList${currentDevice}`}>
            <div className={`sidemenu__list${currentDevice}`}>
              <p>
                {data &&
                  Object.values(data).map((category, index) => (
                    <div
                      key={index}
                      className={`sidemenu__list2${currentDevice}`}
                      onClick={() => handleCategoryClick(category)}
                    >
                      <p
                        className={`categoryLabelDesktop ${
                          selectedCategory == category.id ? "highlighted" : ""
                        }`}
                        onClick={() => handleCategoryClick(category)}
                      >
                        {category.name}
                      </p>
                      <div>
                        {selectedCategory == category.id &&
                          category.subcategories && (
                            <>
                              {Object.values(category.subcategories).map(
                                (subCat, subIndex) =>
                                  !subCat?.disabled && (
                                    <div
                                      key={subIndex}
                                      className={`sidemenu__subcategory${currentDevice}`}
                                    >
                                      <p
                                        className={`sidemenu__subcategoryList${
                                          selectedSubCategory == subCat.id
                                            ? " highlightedSub"
                                            : ""
                                        }`}
                                        onClick={(event) =>
                                          handleSubCategoryClick(event, subCat)
                                        }
                                      >
                                        {subCat.name}
                                      </p>
                                    </div>
                                  )
                              )}
                              {category.subcategories &&
                                Object.values(category.subcategories).length >
                                  0 && <div className="sideMenuBorder"></div>}
                            </>
                          )}
                      </div>
                    </div>
                  ))}
              </p>
            </div>
          </div>
        </div>

        {signOutShow && (
          <div className={`sidemenu__signOutBtnDiv${currentDevice}`}>
            <button className={`sidemenu__signOutBtn${currentDevice}`}>
              <span className="signOutLabel" onClick={handleSignOut}>
                Sign Out
              </span>
            </button>
          </div>
        )}
      </div>
    </>
  );
}
