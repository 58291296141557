import React from "react";
import "./ProductTile.css";
import { useStore } from "../store/home";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import CampaignIcon from "@mui/icons-material/Campaign";
import Tile from "./Tile";
import TileList from "./TileList";
import Pagination2 from "./Pagination2";
import currSVG from "../Images/currentEngineSVG.png";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as RArrow } from "../Images/rightAroShop.svg";
import commonMessages from "../commenComp/CommonMessages";
import { useMutation } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { getPartList } from "../api/posts";
import Loader from "../commenComp/Loader";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination3 from "./Pagination3";

const ProductTile = ({
  disableView,
  sortOptions,
  noCurrEngine,
  load,
  iscardView,
}) => {
  const {
    setScreenSize,
    isCard,
    isCurrentEngSet,
    setIsCard,
    currentDevice,
    setPartListArray,
    partListArray,
    dealerId,
    offset,
    setSort,
    setTRecord,
    setRecord,
    token,
    categoryId,
    setCategoryId,
    keyWord,
  } = useStore();

  const location = useLocation();
  const navigate = useNavigate();
  const { setRefreshQ, refreshQ } = useNonPersistedStore();
  // const handleSortCallBack = (value) => {
  //   if (partListArray && partListArray.length > 0) {
  //     const arrr = partListArray;
  //     let sortedArray = [];
  //     // const sortedArray = [...arrr].sort((a, b) => a.part_qty - b.part_qty);
  //     if (value.value === "price") {
  //       sortedArray = [...arrr].sort(
  //         (a, b) => a.productEffectiveCost - b.productEffectiveCost
  //       );
  //     }
  //     if (value.value === "partNumer") {
  //       const sortLogic = (a, b) => {
  //         const lowerCaseA = a.partNumber.toLowerCase();
  //         const lowerCaseB = b.partNumber.toLowerCase();

  //         if (lowerCaseA < lowerCaseB) {
  //           return -1;
  //         }
  //         if (lowerCaseA > lowerCaseB) {
  //           return 1;
  //         }
  //         return 0;
  //       };
  //       sortedArray = arrr.sort(sortLogic);
  //     }
  //     if (value.value === "comp") {
  //       const sortLogic = (a, b) => {
  //         const lowerCaseA = a.isEngineCompartible;
  //         const lowerCaseB = b.isEngineCompartible;

  //         if (lowerCaseA < lowerCaseB) {
  //           return 1;
  //         }
  //         if (lowerCaseA > lowerCaseB) {
  //           return -1;
  //         }
  //         return 0;
  //       };
  //       sortedArray = arrr.sort(sortLogic);
  //     }
  //     setPartListArray(sortedArray);
  //   }
  // };
  const handlePagination = (startVal) => {
    window.scrollTo(0, 0);
  };

  // useEffect(() => {
  //   setIsCard(false);
  // }, []);
  useEffect(() => {
    if (currentDevice === "Mobile") {
      setIsCard(false);
    }
  }, [currentDevice]);

  const mutation = useMutation((post) => getPartList(post), {
    mutationKey: "getPartList",
    method: "GET",
    onSuccess: (result) => {
      setRefreshQ(!refreshQ)
      setPartListArray(result.data.data);
      setTRecord(result?.data?.total);
      setRecord(result?.data?.perPage);
      // setKeyWordFinal(keyWord);
      // setCategoryIdSearch(categoryId);
      // if (okToNavigate) {
      //   navigate({
      //     pathname: "/search",
      //   });
      // }
    },
    onError: (error) => {},
  });

  const handleSearch = (sort) => {
    setPartListArray([]);
    let sub = "";
    let cat = categoryId ? categoryId.value : "";
    const params = {
      token: token,
      categoryId: cat,
      subCategoryId: sub,
      keyword: keyWord,
      engineId: isCurrentEngSet?.engineId ?? "",
      dealerId: dealerId,
      perPage: 20,
      offset: offset,
      sortBy: sort ? sort : "partNumber",
    };
    mutation.mutate(params);
  };

  const handleSortCallBack = (startVal) => {
    if (location.pathname === "/search") {
      handleSearch(startVal.value);
    }
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("s", startVal.value);
    const newSearchParams = queryParams.toString();
    navigate({
      search: `?${newSearchParams}`, // Include the '?' to start the query string
    });
    setSort(startVal.value);
  };
  return (
    <div className="cardsMain">
      {mutation.isLoading && <Loader></Loader>}
      <Pagination3
        sortOptions={sortOptions}
        disableView={disableView}
        disableSort={!isCurrentEngSet && location.pathname === "/search"}
        startProp={offset}
        iscardView={iscardView}
        handlePagination={handlePagination}
        handleSortCallBack={handleSortCallBack}
      ></Pagination3>
      <div className="cards">
        {isCard ? (
          <div className="cardView">
            {isCurrentEngSet &&
            partListArray &&
            partListArray?.length > 0 &&
            !partListArray[0]?.isLoad &&
            !load &&
            isCurrentEngSet.isShopByDiagram ? (
              !noCurrEngine &&
              !(!partListArray || !partListArray.length) &&
              currentDevice !== "Mobile" && (
                <div
                  onClick={() => {
                    navigate({
                      pathname: "/shopEngineDiagram",
                      search: `engineId=${isCurrentEngSet.engineId}&model=${isCurrentEngSet.model}&make=${isCurrentEngSet.make}&year=${isCurrentEngSet.year}`,
                    });
                  }}
                  className={`cardListParentDesktop currSvgDiv currSvgLis`}
                >
                  <div className="currImagesvgSap currImagesvgMob">
                    {commonMessages?.productTileHeading}
                  </div>
                  <RArrow></RArrow>
                </div>
              )
            ) : (
              <></>
            )}
            {partListArray &&
              partListArray?.length > 0 &&
              partListArray?.map((item, index) => {
                if (true) {
                  return <TileList item={item} className="card"></TileList>;
                }
              })}
          </div>
        ) : (
          <div className="listView">
            {!iscardView &&
            partListArray &&
            partListArray?.length > 0 &&
            !partListArray[0]?.isLoad &&
            isCurrentEngSet &&
            offset === 0 &&
            !load &&
            isCurrentEngSet.isShopByDiagram ? (
              !(!partListArray || !partListArray.length) &&
              currentDevice !== "Mobile" && (
                <div
                  onClick={() => {
                    navigate({
                      pathname: "/shopEngineDiagram",
                      search: `engineId=${isCurrentEngSet.engineId}&model=${isCurrentEngSet.model}&make=${isCurrentEngSet.make}&year=${isCurrentEngSet.year}`,
                    });
                  }}
                  className={`cardParentDesktop currSvgDiv`}
                >
                  <div className="currImagesvgSap">Shop</div>
                  <div className="currImagesvgSap">Engine</div>
                  <div className="currImagesvgSap smCss">
                    Diagram <RArrow></RArrow>
                  </div>
                </div>
              )
            ) : (
              <></>
            )}
            {partListArray &&
              partListArray?.length > 0 &&
              partListArray?.map((item, index) => {
                if (true) {
                  return <Tile item={item} className="card"></Tile>;
                }
              })}
          </div>
        )}
        {(!partListArray || !partListArray.length) && (
          <div className="center cardNopart">
            {!load ? (
              <span>{commonMessages?.productTileSubHeading} </span>
            ) : (
              <></>
            )}
            {/* <CircularProgress style={{ color: 'black' }} size={50} /> */}
            {/* <CircularProgress style={{ color: 'red' }} size={20} /> */}
          </div>
        )}
      </div>

      <Pagination3
        handlePagination={handlePagination}
        disableSort={true}
        startProp={offset}
      ></Pagination3>
    </div>
  );
};

export default ProductTile;
