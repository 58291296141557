import React, { useState, useEffect } from "react";
import "./TileList.css";
import { useStore } from "../store/home";
import NumericInput from "react-numeric-input";
import {
  addCommaDecimal,
  addCommaDecimalreturnZ,
  getCurrentDate,
} from "../commonFunction";
import { ReactComponent as GreyShopList } from "../Images/greyShopList.svg";
import { ReactComponent as RedCart } from "../Images/redCart.svg";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import { useLocation, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import place from "../Images/placeHolder.png";
import loaderImg from "../Images/loaderImg.gif";
import { useMutation } from "@tanstack/react-query";
import { ReactComponent as Checkmark } from "../Images/checkMark.svg";
import { ReactComponent as Exclamation } from "../Images/notCompatible.svg";
import { AddEmployeeAccountBtnStyled } from "./StyledComponents/EmployeeAccountStyled.js";
import { toast } from "react-toastify";
import { Modal } from "@mui/material";
import AddToCartPopUp from "./AddToCartPopUp";
import AddToShopPop from "./AddToShopPopUp";
import { addToCart, getMyCart, updateWishList } from "../api/posts";
import { showErrorToast } from "../utils/Toaster";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Box from "@mui/material/Box";

const TileList = ({ item }) => {
  const {
    token,
    userInfo,
    myProfile,
    dealerId,
    setMyCartMini,
    isCurrentEngSet,
  } = useStore();
  const { refreshQ } = useNonPersistedStore();
  const navigate = useNavigate();
  const [shopListPop, setShopListPop] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenShop, setIsModalOpenShop] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const location = useLocation();
  const handleInputChangeSelect = (event) => {
    setQuantity(event);
  };

  const mutation_cart = useMutation((post) => getMyCart(post, token), {
    onSuccess: (result) => {
      setMyCartMini(result);
    },
    onError: (error) => {},
  });
  // useEffect(() => {
  //   const obj = {
  //     email: userInfo.username,
  //     dealerId: dealerId,
  //     minicart: true,
  //   };
  //   mutation_cart.mutate(obj);
  // }, []);

  const notify = () => toast.success("Product added to cart");
  const notifyShop = () => toast.success("Product added to Shopping list");
  const mutation = useMutation((post) => updateWishList(post, token), {
    onSuccess: (result) => {
      setShopListPop(result?.data?.data);
      setIsModalOpenShop(true);
      // notifyShop();
    },
    onError: (error) => {},
  });

  useEffect(() => {
    setQuantity(1);
  }, [refreshQ]);

  const mutationCart = useMutation((post) => addToCart(post, token), {
    onSuccess: (result) => {
      if (result.data.success) {
        // notify();
        const obj = {
          email: userInfo.username,
          minicart: true,
        };
        setShopListPop(result?.data?.cart?.items);
        setIsModalOpen(true);
        mutation_cart.mutate(obj);
      } else {
        showErrorToast(result?.data?.msg);
      }
    },
    onError: (error) => {
      showErrorToast("Internal Server Error");
    },
  });

  const handlePart = (data) => {
    navigate({
      pathname: "/part",
      search: `number=${data}`,
    });
  };

  const handleProductClick = (id) => {
    navigate({
      pathname: "/part",
      search: `number=${id}`,
    });
  };

  const handleAddToShoppingList = () => {
    const arr1 = [];
    if (item?.additionalInfoFlag) {
      item.additionalInfo.forEach((item) => {
        const obj = {};
        obj.title = item;
        obj.value = "";
        arr1.push(obj);
      });
    }
    const arr = {
      partId: item.partId,
      quantity: quantity <= 1 ? 1 : quantity,
      isPartDetail: false,
      dealerId:dealerId,
      date: getCurrentDate(),
      notes: "",
      other_notes: arr1,
    };
    mutation.mutate(arr);
  };

  const handleAddToCart = () => {
    const arr1 = [];
    if (item?.additionalInfoFlag) {
      item.additionalInfo.forEach((item) => {
        const obj = {};
        obj.title = item;
        obj.value = "";
        arr1.push(obj);
      });
    }
    const arr = {
      partId: item.partId,
      quantity: quantity <= 1 ? 1 : quantity,
      // date: getCurrentDate(),
      notes: "",
      otherNotes: arr1,
    };
    let obj = [];
    obj.push(arr);
    let data = {
      email: userInfo?.username,
      update: false,
      isPartDetail: false,
      items: obj,
    };
    mutationCart.mutate(data);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const handleLoad = () => {
    setIsLoading(false);
    item.isLoad = false;
  };

  const handleError = () => {
    setHasError(true);
    setIsLoading(false);
  };

  return (
    <div className={`cardListParentDesktop`}>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="add-to-cart-modal"
        aria-describedby="add-to-cart-modal-description"
      >
        <div className="addToCart-modal">
          <AddToCartPopUp
            shopList={shopListPop}
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
          />
        </div>
      </Modal>
      <Modal
        open={isModalOpenShop}
        onClose={() => setIsModalOpenShop(false)}
        aria-labelledby="add-to-cart-modal"
        aria-describedby="add-to-cart-modal-description"
      >
        <div className="addToCart-modal">
          <AddToShopPop
            setIsModalOpen={setIsModalOpenShop}
            shopList={shopListPop}
            yourCart={true}
            isModalOpen={isModalOpenShop}
          />
        </div>
      </Modal>
      <div
        onClick={() => {
          !item?.isDiscontinue?.status && handleProductClick(item.partNumber);
        }}
        className="cardListLesftSide"
      >
        <div id="child" className={`cardList__imageDivDesktop`}>
          {isLoading && (
            <div className="loaderDiv center">
              <img
                className={`cardList__imageDesktop imgLoad`}
                src={loaderImg}
                alt="Loading..."
              />
            </div>
          )}
          {!item.isLoad ? (
            <img
              className={`cardList__imageDesktop`}
              onLoad={handleLoad}
              onError={handleError}
              style={{ display: isLoading || hasError ? "none" : "block" }}
              src={item?.images ? item?.images : place}
              alt={
                item?.imagesAltText || item?.partNumber || "Ilmor Part Image"
              }
            />
          ) : (
            // <div className="loaderDiv center">
            //   <img
            //     className={`cardList__imageDesktop imgLoad`}
            //     src={loaderImg}
            //     alt="Loading..."
            //   />
            // </div>
            <Skeleton
              circle
              height="150px"
              style={{ margin: "0px 10px 0px 0px" }}
              width="150px"
              containerClassName="avatar-skeleton"
            />
          )}
        </div>
        <div className="csrdListDetail">
          {!item.isLoad ? (
            <span
              className={`cardList__newTextDesktop  ${
                item.productLabel ? "" : " dpHidden"
              }`}
            >{`${item.productLabel}`}</span>
          ) : (
            <Skeleton width={40} height={10} />
          )}
          <div>
            {!item.isLoad ? (
              <span className="cardListheadingText">{`${item.partNumber}`}</span>
            ) : (
              <Skeleton width={100} height={15} />
            )}
            {/* <span className="cardListheadigSubText">(Replaces 93914-12)</span> */}
          </div>
          {!item.isLoad ? (
            <span className="cardListsubheadingText">{`${item.partName}`}</span>
          ) : (
            <Skeleton width={250} height={20} />
          )}
          {!item.isLoad ? (
            <span className="cardListbackordered">
              {" "}
              {item?.productBackOrder &&
              !item.isBasePart &&
              item?.inventory <= item?.inStockQuantity
                ? "Backordered"
                : ""}
            </span>
          ) : (
            <Skeleton width={60} height={10} />
          )}
          {item?.isDiscontinue?.status && (
            <Box
              sx={{
                marginTop: "4px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {!item.isLoad ? (
                <Box
                  sx={{ color: "#CE0E2D", fontWeight: "700", fontSize: "12px" }}
                >
                  Discontinued
                </Box>
              ) : (
                <></>
              )}
              {item?.isDiscontinue?.replacementPartNumber ? (
                <Box
                  sx={{
                    marginTop: "4px",
                    fontWeight: "700",
                    fontSize: "12px",
                  }}
                >
                  {!item.isLoad ? (
                    <>
                      {" "}
                      <span>{`Use part `}</span>
                      <span
                        onClick={() => {
                          handlePart(item?.isDiscontinue?.replacementPartId);
                        }}
                        className="under"
                      >
                        {item?.isDiscontinue?.replacementPartNumber}
                      </span>
                      <span>{` instead`}</span>{" "}
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              ) : (
                <Box
                  sx={{ marginTop: "2px", fontWeight: "700", fontSize: "12px" }}
                >
                  {!item.isLoad ? `Contact your Ilmor rep for support` : <></>}
                </Box>
              )}
            </Box>
          )}
        </div>
      </div>

      {item?.isDiscontinue?.status ? (
        <>
          {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ color: "#CE0E2D", fontWeight: "700", fontSize: "12px" }}>
              Discontinued
            </Box>
            {item?.isDiscontinue?.replacementPartNumber ? (
              <Box
                sx={{
                  marginTop: "4px",
                  fontWeight: "700",
                  fontSize: "12px",
                }}
              >
                <span>{`Use part `}</span>
                <span
                  onClick={() => {
                    handlePart(item?.isDiscontinue?.replacementPartId);
                  }}
                  className="under"
                >
                  {item?.isDiscontinue?.replacementPartNumber}
                </span>
                <span>{` instead`}</span>
              </Box>
            ) : (
              <Box
                sx={{ marginTop: "2px", fontWeight: "700", fontSize: "12px" }}
              >
                Contact your Ilmor rep for support
              </Box>
            )}
          </Box> */}
        </>
      ) : (
        <div className="cardListRightSide">
          {item?.isSupercedeDiscontinue?.status && (
            <Box
              sx={{
                fontWeight: "700",
                fontSize: "12px",
                display: "flex",
              }}
            >
              {`${"This part is a replacement for "} `}
              <div className="divSku">
                {item?.isSupercedeDiscontinue?.replacementPartNumber &&
                  item?.isSupercedeDiscontinue?.replacementPartNumber.length >
                    0 &&
                  item?.isSupercedeDiscontinue?.replacementPartNumber.map(
                    (itemName, i) => (
                      <>
                        <span
                          style={{ color: "#CE0E2D" }}
                          key={itemName}
                        >{`${itemName}`}</span>
                        {i !==
                          item?.isSupercedeDiscontinue?.replacementPartNumber
                            .length -
                            1 && <span>{`, `}</span>}
                      </>
                    )
                  )}
              </div>
            </Box>
          )}
          <div className="cardListpriceDetail">
            {item?.isBasePart &&
            myProfile?.profileInfo?.permissions?.includes("add_to_own_cart") ? (
              <span style={{ fontWeight: 700, marginRight: "10px" }}>
                {!item.isLoad ? `From:` : <Skeleton width={40} height={10} />}
              </span>
            ) : (
              <></>
            )}{" "}
            {(myProfile?.profileInfo?.permissions?.includes(
              "add_to_own_cart"
            ) ||
              dealerId) && (
              <>
                {!item.isLoad ? (
                  <>
                    <span className="cardListprice">{`$${
                      item?.productEffectiveCost &&
                      addCommaDecimalreturnZ(item?.productEffectiveCost)
                    }`}</span>
                    {item?.productOrigionalCost !==
                      item?.productEffectiveCost &&
                      addCommaDecimal(item?.productOrigionalCost) && (
                        <span className="cardListdiscountedPrice">{`$${
                          item.productOrigionalCost &&
                          addCommaDecimal(item?.productOrigionalCost)
                        }`}</span>
                      )}
                  </>
                ) : (
                  <Skeleton width={150} height={30} />
                )}
              </>
            )}
          </div>
          {item?.isBasePart ? (
            <>
              {
                <div className="drlkfgjeriu34">
                  {" "}
                  {!item.isLoad ? (
                    <AddEmployeeAccountBtnStyled
                      sx={{ marginLeft: "20px" }}
                      className="minwidSelOpt"
                      onClick={() => {
                        handleProductClick(item?.partNumber);
                      }}
                    >
                      Select Options
                    </AddEmployeeAccountBtnStyled>
                  ) : (
                    <>
                      {" "}
                      <Skeleton width={50} height={30} />
                    </>
                  )}
                </div>
              }
            </>
          ) : (
            <>
              {!dealerId && (
                <>
                  {!item.isLoad ? (
                    <div className={`cardListbottomBoxDesktop`}>
                      <div className="inputBoxNUmber">
                        <NumericInput
                          className={`quickOrderTableQuantityDesktop lightBorder`}
                          mobile={false}
                          strict
                          max={999}
                          value={quantity <= 0 ? "" : quantity}
                          // min={1}
                          onChange={handleInputChangeSelect}
                          onBlur={(event) => {
                            if (
                              event.target.value == "" ||
                              event.target.value <= 0
                            ) {
                              handleInputChangeSelect(1);
                            }
                          }}
                          // value={quantity}
                        />
                      </div>
                      <div className="iconWrapper center bgForCart">
                        {mutation.isLoading ? (
                          <CircularProgress
                            style={{ color: "white" }}
                            size={20}
                          />
                        ) : (
                          <GreyShopList
                            onClick={handleAddToShoppingList}
                            className="GreyShopList icon"
                          ></GreyShopList>
                        )}
                      </div>
                      {myProfile?.profileInfo?.permissions?.includes(
                        "add_to_own_cart"
                      ) && (
                        <div className="iconWrapper center bgForCartRed">
                          {mutationCart.isLoading ? (
                            <CircularProgress
                              style={{ color: "white" }}
                              size={20}
                            />
                          ) : (
                            <RedCart
                              onClick={handleAddToCart}
                              className="GreyShopList icon"
                            ></RedCart>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <Skeleton
                      style={{ margin: "0px 0px 0px 30px" }}
                      width={200}
                      height={50}
                    />
                  )}
                </>
              )}
            </>
          )}

          {isCurrentEngSet?.engineId && location?.pathname === "/search" && (
            <Box
              className="toppos"
              sx={{
                color: item?.isEngineCompartible ? "#40A900" : "#CE0E2D;",
                position: "absolute",
                // top: "60px",
                right: "0",
                display: "flex",
                alignItems: "center",
                fontSize: "12px",
                fontWeight: "700",
              }}
            >
              {item?.isEngineCompartible ? (
                <>
                  {" "}
                  <Checkmark className={`fillGreen`}></Checkmark>
                  <>Compatible with current engine</>
                </>
              ) : (
                <>
                  <Exclamation className="fillRedNonCompatible"></Exclamation>
                  <>Not compatible with current engine</>
                </>
              )}
            </Box>
          )}
          {/* </Box> */}
        </div>
      )}
    </div>
  );
};

export default TileList;
