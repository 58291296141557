import { Box, Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useStore } from "../../store/home";
import { getShippingPolicy } from "../../api/posts";
import Footer from "../Footer";
import Header from "../Header";
import { ShippingPolicyGreyHeading, ShippingPolicyHeading, ShippingPolicyParagraph } from "../StyledComponents/CommonStyled";

const ShippingPolicy = () => {
  const {token} = useStore();
    const { data:shippingPolicyData, error, refetch,isLoading } = useQuery({
    queryKey: ["getShippingPolicy"],
    retry: 0,
    refetchOnWindowFocus: false,
    queryFn: () => getShippingPolicy("shippingPolicy",token),
  });
 
 const policyArray = [
   {
     type: "heading",
     content: "turnaround",
   },
   {
     type: "para",
     content:
       "Turnaround Our regular business hours are Monday-Friday, 7:00AM to 4:00PM US EST. Orders received before 1:00PM EST on a regular business day will be shipped same day, unless otherwise specified on the part description. Orders received after 1:00PM EST, on a weekend, or on a holiday will be shipped the following business day, unless otherwise specified on the part description.",
   },
   {
     type: "heading",
     content: "Order Tracking",
   },
   {
     type: "para",
     content:
       "If a tracking number is provided by the shipping carrier, we will update the order and email the tracking information to the email address in the online store account profile. Note: International orders may be subject to customs clearance procedures that can cause delays in delivery, or may not be trackable by the shipping carrier.",
   },
   {
     type: "heading",
     content: "Shipping Rates",
   },
   {
     type: "para",
     content:
       "The rate charged for shipping an order is based on the weight of products and the ship-to location. During checkout, and prior to final payment, the cost of shipping an order will be shown based on the selected shipping method.",
   },
   {
     type: "heading",
     content: "Back Orders",
   },
   {
     type: "para",
     content:
       " If an item is on back order, we will ship you whatever part of your order is currently in stock. When the item becomes available, we will ship you the rest of your order. You will receive email confirmations  when any/all of your order has shipped. You will not be charged any additional shipping and handling for the second shipment, if required.",
   },
   {
     type: "heading",
     content: "Shortages",
   },
   {
     type: "para",
     content:
       "Many orders ship in multiple cartons and may arrive days apart, so check your packing slip before calling us to report a shortage. All claims for shortages must be made with the carrier within five (5) days of receipt of goods.",
   },
   {
     type: "heading",
     content: " Damaged Goods",
   },
   {
     type: "para",
     content:
       " If an item reaches you in damaged condition, save the shipping carton and notify Ilmor at once. Please do not return damaged or broken merchandise to us unless you receive our instructions to do so. This is necessary so that you do not lose your right to file a claim. All  claims for damaged goods must be made with the carrier within five (5) days of receipt of goods.",
   },
   {
     type: "greyHeading",
     content: "SHIPPING OPTIONS",
   },
   {
     type: "heading",
     content: "SHIPPING WITHIN USA",
   },
   {
     type: "smallHeading",
     content: "GROUND",
   },
   {
    type: "para",
    content: " Day-definite delivery typically in one to five days for all 50 States and Puerto Rico",
  },
  {
    type: "smallHeading",
    content: "2ND DAY AIR",
  },
  {
   type: "para",
   content: "  Delivery by the end of the second business day for all 50 States and Puerto Rico (with some limitations in Alaska and Hawaii",
 },
//  {
//   type: "bullets",
//   content: "  Delivery by the end of the second business day for all 50 States and Puerto Rico (with some limitations in Alaska and Hawaii",
// },

      {
        type: "bullets",
        content: [
          {
            type: "point",
            content: "commercial destinations:Next business day delivery by 3:00 or 4:40 p.m. where Next Day Air delivery is commited by 10:30 a.m. or 12:00",
          },
          {
            type: "point",
            content: "ac1sdfsdsdf",
          },
        ],
      },
 ];
 
 return (
   <>
     <Header page="Catalog"></Header>
     {shippingPolicyData?.data?.data?.map((item) => {
        return (
          <>
          
            <ShippingPolicyGreyHeading
              sx={{
                backgroundColor: "#E4E4E4",
                padding: "20px 48px 20px 48px",
              }}
            >
            
              {item?.title}
            </ShippingPolicyGreyHeading>
          </>
        );
      })}
   

     <Grid
       container
       justifyContent="center"
       spacing={2}
       sx={{ wordBreak: "break-word",padding:"30px 0px 70px 0px" }}
     >
       <Grid item xs={8}>
       {shippingPolicyData?.data?.data?.map((item) => {
        return (
          <>
         
            <Box dangerouslySetInnerHTML={{ __html: item?.contentData }} />
            {/* <ShippingPolicyParagraph>
              {item?.contentData}
            </ShippingPolicyParagraph> */}
          </>
        );
      })}
     
      

        
         {/* <ul>
           <li>Coffee</li>
           <li>Tea</li>
           <li>Coca Cola</li>
         </ul> */}
       </Grid>
     </Grid>
     <Footer />
   </>
 );
};

export default ShippingPolicy;
