import Header from "./Header";
import Footer from "./Footer";
import "./ShoppingList.css";
import SubHeading from "../commenComp/SubHeading";
import { ShoppingListComponent } from "./ShoppingListComponent";
import { useStore } from "../store/home";
import { useQuery } from "@tanstack/react-query";
import { getMyWishList } from "../api/posts";
import Loader from "../commenComp/Loader";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";

const ShoppingList = () => {
  const { token, setKeyWord, setKeyWordFinal, dealerId, myProfile } =
    useStore();

  const shopList = useQuery({
    queryKey: ["getMyWishList"],
    retry: 2,
    refetchOnWindowFocus: false,
    queryFn: () => getMyWishList({ token: token, dealerId: dealerId }),
    // queryFn: () => (isCart ? getMyCart(token) : getMyWishList(token)),
  });

  const [dataArray, setDataArray] = useState([]);
  useEffect(() => {
    let arr = shopList?.data?.data?.data;
    if (
      myProfile?.profileInfo?.role === "ServiceTechnician" &&
      arr &&
      arr.length > 0
    ) {
      arr = arr.filter((item) => item.isPermission);
    }
    setDataArray(arr);
  }, [shopList?.data?.data?.data, myProfile]);

  useEffect(() => {
    setKeyWordFinal("");
    setKeyWord("");
  }, []);

  const handleRefreshCallBack = () => {
    shopList.refetch();
  };

  return (
    <>
      {shopList.isFetching && <Loader></Loader>}
      <>
        <div>
          <Header page="Catalog"></Header>
          <SubHeading heading={"Shopping List"} />
          {(!dataArray || dataArray?.length === 0) && (
            <div className="center cartEmpty">
              <PlaylistRemoveIcon
                style={{ fontSize: 100 }}
              ></PlaylistRemoveIcon>
              <div className="cartEmptyMessage">
                your shopping list is empty
              </div>
            </div>
          )}
        </div>
        <div className="ShoppingListFormComponent">
          <ShoppingListComponent
            dataArray={dataArray}
            handleRefreshCallBack={handleRefreshCallBack}
          ></ShoppingListComponent>
        </div>
        <Footer></Footer>
      </>
    </>
  );
};

export default ShoppingList;
