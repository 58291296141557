import "./ShippingPayment.css";
// import { ReactComponent as Radio } from "../Images/radioButton.svg";
// import { ReactComponent as RadioEmpty } from "../Images/radioEmpty.svg";
import InputBase from "@mui/material/InputBase";
import classnames from "classnames";
import { useStore } from "../store/home";
import { Radio } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import Select from "react-select";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { authorizeNet, dhl, getShipRate, ups } from "../api/posts";
import { useEffect } from "react";
import Loader from "../commenComp/Loader";
import commonMessages from "../commenComp/CommonMessages";
import { customStyles } from "../utils/ReactSelectCustomStyles";
import { showErrorToast } from "../utils/Toaster";
import { useNonPersistedStore } from "../store/NonPersistedStore";

import { ReactComponent as Visa } from "../Images/visa.svg";
import { ReactComponent as Master } from "../Images/master.svg";
import american from "../Images/american.png";
import jcb from "../Images/jcb.png";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { ReactComponent as Discover } from "../Images/discover.svg";

const ShippingPaymentOrder = ({
  reviewOrder,
  orderDetail,
  defaultCardData,
  orderDetailData,
}) => {
  const {
    checkoutState,
    token,
    myProfile,
    setDefaultCard,
    cusProfile,
    setservice,
    isPO,
    shipPrice,
    setshipPrice,
    isPOValue,
    setFinalShipPrice,
    setIsPOValue,
    service,
    setSideMenuAccount,
    setIsPO,
    setDomestic,
    isDomestic,
    currentDevice,
  } = useStore();
  const { setUseThisAddress, shippinPaymentAddressUpdated } =
    useNonPersistedStore();
  const navigate = useNavigate();
  const handleChange = (event) => {
    setIsPO(!isPO);
  };

  useEffect(() => {
    if (!defaultCardData) {
      setIsPO(true);
    }
  }, [defaultCardData]);

  const handleChangePay = (event) => {
    setSideMenuAccount("paymentMethods");
    navigate({
      pathname: "/account/mgmt/payment",
    });
  };

  const upsAPIInter = useMutation((post) => ups(post, token), {
    onSuccess: (result) => {
      if (result.data.success) {
        if (result?.data?.data) {
          setshipPrice(result?.data?.data[0].TotalCharges);
        }
      } else {
        showErrorToast(result.data.msg);
      }
    },
    onError: (error) => {},
  });

  const dhlAPI = useMutation((post) => dhl(post, token), {
    onSuccess: (result) => {
      if (result.data.success) {
        if (result?.data?.data) {
          setshipPrice(result?.data?.data[0]?.TotalCharges);
        }
      } else {
        showErrorToast(result?.data?.msg);
      }
    },
    onError: (error) => {},
  });

  const handleService = (option) => {
    setFinalShipPrice(option.price);
    setservice(option);
  };
  const handleServiceDomestic = (option) => {
    setFinalShipPrice(option.price);
    setLowest(option);
    setshipPrice(option);
  };
  const [isDomesticOption, setDomesticOption] = useState(false);
  useEffect(() => {
    if (reviewOrder) {
      if (isDomestic === "domestic") {
        const obj = {};
        obj.userId = "1116";
        obj.weight = "25";
        obj.requestOption = "Shop";
        upsAPIDome.mutate(obj);
      } else if (isDomestic === "inter") {
        if (service.value === "dhl") {
          const obj = {};
          obj.userId = "1116";
          obj.weight = "25";
          dhlAPI.mutate(obj);
        }
        if (service.value === "ups") {
          const obj = {};
          obj.userId = "1116";
          obj.weight = "25";
          obj.serviceId = "07";
          obj.requestOption = "Rate";
          upsAPIInter.mutate(obj);
        }
      }
    }
  }, [service, isDomestic, reviewOrder]);

  // const StyledRadio = styled(Radio)({
  //   color: "black", // Change the color here
  //   "&.Mui-checked": {
  //     color: "black", // Change the color for the checked state
  //   },
  // });

  const getAllCards = {
    getCustomerProfileRequest: {
      merchantAuthentication: {
        name: process.env.REACT_APP_MERCHANT_NAME,
        transactionKey: process.env.REACT_APP_MERCHANT_TRANSACTION_KEY,
      },
      customerProfileId: cusProfile,
      // includeIssuerInfo: "true",
      unmaskExpirationDate: true,
    },
  };

  const [allCards, getAllCardsArray] = useState(false);

  const authorize = useMutation((post) => authorizeNet(post), {
    onSuccess: (result) => {
      if (result) {
        getAllCardsArray(result.data);
      }
    },
    onError: (error) => {},
  });
  const [lowest, setLowest] = useState("");
  const upsAPIDome = useMutation((post) => ups(post, token), {
    onSuccess: (result) => {
      if (result.data.success) {
        if (result?.data?.data && result?.data?.data.length > 0) {
          const arr = [];
          result?.data?.data.forEach((item) => {
            const obj = {};
            obj.label = `${item.Description} ($${item.TotalCharges})`;
            obj.value = item.Code + " " + item.TotalCharges;
            obj.price = parseFloat(item.TotalCharges);
            arr.push(obj);
          });
          const minPriceOption = arr.reduce((minOption, currentOption) =>
            currentOption.price < minOption.price ? currentOption : minOption
          );
          setFinalShipPrice(minPriceOption.price);
          setservice(minPriceOption);
          setLowest(minPriceOption);
          setDomesticOption(arr);
        }
      } else {
        showErrorToast(result.data.msg);
      }
    },
    onError: (error) => {},
  });

  useEffect(() => {
    if (checkoutState === "ship") {
      authorize.mutate(getAllCards);
      if (myProfile?.profileInfo?.USDealer) {
        // const obj = {};
        // obj.userId = "1116";
        // obj.weight = "25";
        // obj.requestOption = "Shop";
        setDomestic("domestic");
        // upsAPIDome.mutate(obj);
      } else {
        setDomestic("inter");
      }
    }
  }, []);

  function convertDateFormat(originalDate) {
    const [year, month] = originalDate.split("-");
    const formattedDate = `${month}/${year.slice(-2)}`;
    return formattedDate;
  }
  const getShipRateAPI = useMutation((post) => getShipRate(post, token), {
    onSuccess: (result) => {
      if (result.data.data) {
      }
    },
    onError: (error) => {},
  });

  useEffect(() => {
    if (
      shippinPaymentAddressUpdated &&
      Object.values(shippinPaymentAddressUpdated).length > 0
    ) {
      let obj = {};
      if (isDomestic === "domestic") {
        obj = {
          userId: "",
          weight: "25",
          addressId: shippinPaymentAddressUpdated.id,
          shipmentMethod: "ups_domestic",
          serviceId: "",
        };
      } else if (isDomestic === "inter") {
        if (service.value === "dhl") {
          obj = {
            userId: "",
            weight: "25",
            addressId: shippinPaymentAddressUpdated.id,
            shipmentMethod: "dhl_worldwide",
            serviceId: "",
          };
        } else {
          obj = {
            userId: "",
            weight: "25",
            addressId: shippinPaymentAddressUpdated.id,
            shipmentMethod: "ups_worldwide",
            serviceId: "07",
          };
        }
      }
      getShipRateAPI.mutate(obj);
    }
  }, [shippinPaymentAddressUpdated]);

  useEffect(() => {
    if (
      allCards &&
      allCards?.profile?.paymentProfiles &&
      allCards?.profile?.paymentProfiles?.length > 0
    ) {
      let defCard = false;
      allCards.profile.paymentProfiles.forEach((item) => {
        if (item.defaultPaymentProfile) {
          const obj = {};
          obj.reference = item?.billTo?.firstName;
          obj.reference1 = item?.billTo?.lastName;
          obj.cusProfile = allCards?.profile?.customerProfileId;
          obj.customerPaymentProfileId = item?.customerPaymentProfileId;
          obj.card = item?.payment?.creditCard?.cardNumber;
          obj.expiration = item?.payment?.creditCard?.expirationDate;
          obj.status = item?.defaultPaymentProfile;
          obj.cardType = item?.payment?.creditCard?.cardType;
          defCard = obj;
        }
      });
      if (defCard) {
        setDefaultCard(defCard);
      } else {
        setDefaultCard(false);
      }
    }
  }, [allCards]);

  function extractValue(str, i) {
    // const regex = /\(([^)]+)\)/; // Matches any characters except ) within parentheses
    // const match = regex.exec(str);
    // return match ? match[1] : null; // Return the captured value or null if not found
    let arr = str.split("(");
    if (arr && arr.length > 0) {
      return arr[i];
    }
  }

  return (
    <div className="shipping">
      {(authorize.isLoading ||
        upsAPIDome.isLoading ||
        dhlAPI.isLoading ||
        upsAPIInter.isLoading) && <Loader></Loader>}
      {!orderDetail && (
        <div className="shippingTitile">
          {reviewOrder ? "Shipping & payment" : "Review Order"}
        </div>
      )}
      <div
        className={classnames("billingShop", {
          orderDetailBillShopiing: orderDetail,
        })}
      >
        <div className="billTtile">
          <span>Billing Address</span>
        </div>
        <div className="billTtile2">
          <span>{orderDetailData?.billingInfo?.locationName}</span>
        </div>
        <div className="billTtile3">
          <span>{orderDetailData?.billingInfo?.address1}</span>
          <span>{orderDetailData?.billingInfo?.address2}</span>
          <span>{`${orderDetailData?.billingInfo?.city}, ${orderDetailData?.billingInfo?.state} ${orderDetailData?.billingInfo?.zipCode}`}</span>
          <span>{orderDetailData?.billingInfo?.country}</span>
        </div>
      </div>
      <div className="shippingAddress">
        <div className="billTtile">
          <span>Shipping Address</span>
        </div>
        <div className="billTtile2">
          <span>{orderDetailData?.shippingInfo?.locationName}</span>
        </div>
        <div className="billAddLastComp">
          <div className="billTtile3">
            <span>{orderDetailData?.shippingInfo?.address1}</span>
            <span>{orderDetailData?.shippingInfo?.address2}</span>
            <span>{`${orderDetailData?.shippingInfo?.city}, ${orderDetailData?.shippingInfo?.state} ${orderDetailData?.shippingInfo?.zipCode}`}</span>
            <span>{orderDetailData?.shippingInfo?.country}</span>
          </div>
          {reviewOrder && (
            <div className="billChangeButt">
              <button
                onClick={() => {
                  setSideMenuAccount("shipping");
                  // setUseThisAddress(true);
                  navigate({
                    pathname: "/account/mgmt/shipping",
                  });
                }}
                className="ilmorButonGrey"
              >
                Change
              </button>
            </div>
          )}
        </div>
      </div>
      {orderDetailData.shipping !== "TBD" &&
      orderDetailData.shippingMethod &&
      orderDetailData.shippingMethod !== null ? (
        <div className="shippingAddress">
          <div className="billTtile">
            <span>Shipping Method</span>
          </div>
          <>
            {orderDetailData.shipping === "FREE" &&
            orderDetailData.shippingMethod &&
            orderDetailData.shippingMethod.includes("$") ? (
              <div className="center">
                <span className="shipMethodSel billTtile3">
                  {`${extractValue(orderDetailData.shippingMethod, 0)} `}
                </span>
                <span className="strike shipMethodSel billTtile3">
                  {` (${extractValue(orderDetailData.shippingMethod, 1)} `}
                </span>
                <span className="shipMethodSel billTtile3">
                  &nbsp;{` FREE`}
                </span>
              </div>
            ) : (
              <span className="shipMethodSel billTtile3">
                {orderDetailData.shippingMethod}
              </span>
            )}
          </>
          {reviewOrder && (
            <div className="billAddLastComp  shipMethod shipMethodDomestic">
              {isDomestic === "domestic" && (
                <Select
                  className="shipMethodDropdown"
                  placeholder="Choose Delivery Service"
                  options={isDomesticOption || []}
                  onChange={handleServiceDomestic}
                  value={lowest}
                  styles={customStyles}
                />
              )}
            </div>
          )}
          {reviewOrder && (
            <div className="billAddLastComp shipMethod">
              {isDomestic === "inter" && (
                <Select
                  className="shipMethodDropdown"
                  placeholder="Choose Delivery Service"
                  value={service}
                  options={[
                    {
                      label: "UPS Worldwide",
                      value: "ups",
                    },
                    {
                      label: "DHL Express Worldwide",
                      value: "dhl",
                    },
                  ]}
                  onChange={handleService}
                  styles={customStyles}
                />
              )}
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
      <div
        className={classnames("payment payOrder", {
          pyamnetMOdifyBlackLine: orderDetailData.orderStatus === "Pending",
        })}
      >
        <div className="billTtile">
          <span>Payment method</span>
        </div>
        <div className="paymetnOptions padfororderdeatal">
          {reviewOrder && defaultCardData && (
            <Radio
              checked={!isPO}
              onChange={handleChange}
              value="a"
              name="radio-buttons"
              inputProps={{ "aria-label": "B" }}
            />
          )}
          {defaultCardData ? (
            <>
              {
                <>
                  <div className="payemntDept">
                    <span className="personDetail">
                      {`${
                        defaultCardData?.reference
                          ? defaultCardData?.reference
                          : ""
                      } ${
                        defaultCardData?.reference1
                          ? defaultCardData?.reference1
                          : ""
                      }`}
                    </span>
                  </div>
                  <div className="paymethodCard">
                    {defaultCardData.cardType === "Visa" ? (
                      <Visa className="catogeryIcon"></Visa>
                    ) : (
                      ""
                    )}
                    {defaultCardData.cardType === "AmericanExpress" ? (
                      <img src={american} className="cardWidth"></img>
                    ) : (
                      ""
                    )}
                    {defaultCardData.cardType === "MasterCard" ? (
                      <Master className="catogeryIcon"></Master>
                    ) : (
                      ""
                    )}
                    {defaultCardData.cardType === "JCB" ? (
                      <img src={jcb} className="cardWidth"></img>
                    ) : (
                      ""
                    )}
                    {defaultCardData.cardType === "Discover" ? (
                      <Discover className="catogeryIcon"></Discover>
                    ) : (
                      ""
                    )}
                    {!(
                      defaultCardData.cardType === "Discover" ||
                      defaultCardData.cardType === "JCB" ||
                      defaultCardData.cardType === "MasterCard" ||
                      defaultCardData.cardType === "Visa" ||
                      defaultCardData.cardType === "AmericanExpress"
                    ) ? (
                      <CreditCardIcon sx={{ fontSize: 35 }}></CreditCardIcon>
                    ) : (
                      ""
                    )}
                  </div>
                  <span className="visaCode">
                    {defaultCardData.card.replace(/X/g, "")}
                  </span>
                  <span className="visaCode">
                    {" "}
                    {convertDateFormat(defaultCardData?.expiration)}
                  </span>
                </>
              }
              {!reviewOrder && !isPO && (
                <>
                  <span className="personDetail">
                    {defaultCardData?.reference
                      ? defaultCardData?.reference
                      : "" + " " + defaultCardData?.reference1
                      ? defaultCardData?.reference1
                      : ""}
                  </span>
                  <div className="paymethodCard">
                    {defaultCardData.cardType === "Visa" ? (
                      <Visa className="catogeryIcon"></Visa>
                    ) : (
                      ""
                    )}
                    {defaultCardData.cardType === "AmericanExpress" ? (
                      <img src={american} className="cardWidth"></img>
                    ) : (
                      ""
                    )}
                    {defaultCardData.cardType === "MasterCard" ? (
                      <Master className="catogeryIcon"></Master>
                    ) : (
                      ""
                    )}
                    {defaultCardData.cardType === "JCB" ? (
                      <img src={jcb} className="cardWidth"></img>
                    ) : (
                      ""
                    )}
                    {defaultCardData.cardType === "Discover" ? (
                      <Discover className="catogeryIcon"></Discover>
                    ) : (
                      ""
                    )}
                    {!(
                      defaultCardData.cardType === "Discover" ||
                      defaultCardData.cardType === "JCB" ||
                      defaultCardData.cardType === "MasterCard" ||
                      defaultCardData.cardType === "Visa" ||
                      defaultCardData.cardType === "AmericanExpress"
                    ) ? (
                      <CreditCardIcon sx={{ fontSize: 35 }}></CreditCardIcon>
                    ) : (
                      ""
                    )}
                  </div>
                  <span className="visaCode">
                    {defaultCardData.card.replace(/X/g, "")}
                  </span>
                  <span className="visaCode">
                    {" "}
                    {convertDateFormat(defaultCardData.expiration)}
                  </span>
                </>
              )}
            </>
          ) : (
            <>
              {(orderDetailData?.paymentInfo?.po ||
                orderDetailData?.paymentMethod === "po") && (
                <span className="personDetail padRight">Purchase Order</span>
              )}
              {orderDetailData?.paymentInfo?.po && (
                <>
                  <span className="personDetail">
                    {orderDetailData?.paymentInfo?.po}
                  </span>
                </>
              )}
            </>
          )}
          {orderDetailData?.paymentInfo?.cardName && (
            <>
              {/* {orderDetailData?.paymentInfo?.po && (
                <span className="personDetail padRight">Purchase Order</span>
              )} */}
              {orderDetailData?.paymentInfo?.cardName && (
                <>
                  <span className="billTtile3">
                    {orderDetailData?.paymentInfo?.cardName}
                  </span>
                </>
              )}
            </>
          )}
          {reviewOrder && (
            <div className="billChangeButt">
              <button onClick={handleChangePay} className="ilmorButonGrey">
                {defaultCardData ? "Change" : "Add"}
              </button>
            </div>
          )}
        </div>
        <div className="paymetnOptionsPO">
          {reviewOrder && myProfile?.canManagePurchaseOrder && (
            <Radio
              checked={isPO}
              onChange={handleChange}
              value="b"
              color="black"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
            />
          )}
          {/* 
          {reviewOrder && myProfile?.canManagePurchaseOrder && (
            <span className="personDetail padRight">Purchase Order1</span>
          )} */}
          {reviewOrder && myProfile?.canManagePurchaseOrder && (
            <InputBase
              style={{
                backgroundColor: "white",
                border: "none",
                outline: "none",
                padding: "5px",
                fontStyle: "italic",
              }}
              placeholder="PO Number / Reference"
              value={isPOValue}
              // onChange={(e) => { setInput(e.target.value) }}
              // value={input}
              onChange={(e) => {
                setIsPOValue(e.target.value);
              }}
              className="header__input__base maxWidth"
              inputProps={{ "aria-label": "search google maps" }}
            />
          )}
        </div>
      </div>
      {orderDetailData?.shippingNote && <>
        <div
          className={classnames("payment payOrder", {
            pyamnetMOdifyBlackLine: orderDetailData.orderStatus === "Pending" ,
            erfree: currentDevice ==="Mobile" && orderDetailData.orderStatus === "Pending",
          })}
        >
          <div className="billTtile">
            <span>Shipping Notes</span>
          </div>
          <span className="personDetail2321">{orderDetailData?.shippingNote}</span>
        </div>
      </>}
    </div>
  );
};

export default ShippingPaymentOrder;
