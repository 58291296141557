import React, { useEffect } from "react";
import "./Footer.css";
import { useStore } from "../store/home";
import CampaignIcon from "@mui/icons-material/Campaign";
import { useState } from "react";
import { Box } from "@mui/material";
import logo from "../Images/ILMOR_whitetext.png";
import newlogo from "../Images/ImorFooterWhitePNGlogo.png";
import { useNavigate, useLocation } from "react-router-dom";
import commonMessages from "../commenComp/CommonMessages";
import {
  getFooterLinks,
  getShippingPolicy,
  getShippingPolicy1,
} from "../api/posts";
import { useMutation, useQuery } from "@tanstack/react-query";
import Loader from "../commenComp/Loader";
import IlmorModal from "../commenComp/IlmorModal";
let fisrtRender = true;
const Footer = ({ defineCallbackFunctionInParent = () => {} }) => {
  const {
    setFooterKeyZustand,
    footerKeyZustand,
    footerApiLinkData,
    setSideMenuAccount,
    setSelectedCategory,
    setFooterContentdataInZustand,
    setUnsaved,
    unsaved,
    token,
    currentDevice,
  } = useStore();
  const navigate = useNavigate();
  const [footerKey, setFooterKey] = useState("");
  const [localFooterApiLinkData, setLocalFooterApiLinkData] = useState([]);
  const location = useLocation();
  // const {
  //   data: contentData,
  //   error: contenError,
  //   refetch: contentRefetch,
  //   isSuccess,
  //   isFetching: contentisLoading,
  // } = useQuery({
  //   queryKey: ["getShippingPolicy"],
  //   retry: 0,
  //   enabled: false,
  //   refetchOnWindowFocus: false,
  //   queryFn: () => getShippingPolicy(footerKeyZustand, token),
  // });

  const mutation = useMutation((post) => getShippingPolicy1(post), {
    mutationKey: "getShippingPolicy",
    method: "GET",
    onSuccess: (result) => {
      if (result.data.success) {
        window.scrollTo(0, 0);
        setFooterContentdataInZustand(result?.data?.data);
        defineCallbackFunctionInParent(result?.data?.data);
      }
    },
    onError: (error) => {},
  });

  useEffect(() => {
    if (location.pathname.includes("content")) {
      mutation.mutate(footerKeyZustand);
    }
  }, [footerKeyZustand]);

  useEffect(() => {
    if (location.pathname === '/content/shipping-policy') {
      setFooterKeyZustand('shippingPolicy')
    }
    if (location.pathname === '/content/returns') {
      setFooterKeyZustand('Return&Exchange')
    }
    if (location.pathname === '/content/contact') {
      setFooterKeyZustand('CONTACT')
    }
  }, [location]);

  const handleFooterClick = (key) => {
    if(key === 'shippingPolicy'){
      navigate({
        pathname: "/content/shipping-policy",
        //  pathname: `/${key}`,
      });
    }
    if(key === 'Return&Exchange'){
      navigate({
        pathname: "/content/returns",
        //  pathname: `/${key}`,
      });
    }
    if(key === 'CONTACT'){
      navigate({
        pathname: "/content/contact",
        //  pathname: `/${key}`,
      });
    }
    setFooterKeyZustand(key);
    setFooterKey(key);
    // navigate({
    //   pathname: "/content",
    //   //  pathname: `/${key}`,
    // });
  };

  // useEffect(() => {
  //   if (footerKeyZustand) {
  //     contentRefetch();
  //     if (isSuccess) {
  //       setFooterContentdataInZustand(contentData?.data?.data);
  //       defineCallbackFunctionInParent(contentData?.data?.data);
  //     }
  //   }
  // }, [footerKeyZustand, contentData?.data?.data]);
  useEffect(() => {
    setLocalFooterApiLinkData(footerApiLinkData);
  }, [footerApiLinkData]);

  const [cancelDialog, setCancelDialog] = useState(false);
  const handleClose = () => {
    setUnsaved(false);
    setCancelDialog(false);
  };
  const handleOkay = () => {
    const buttonElement = document.getElementById("shopListSave");
    if (buttonElement) {
      buttonElement.click();
    }
    setUnsaved(false);
    setCancelDialog(false);
  };

  const handleModal = () => {
    if (unsaved && !cancelDialog) {
      setCancelDialog(true);
    }
  };

  return (
    <div onClick={handleModal} className={`footer${currentDevice}`}>
      <IlmorModal
        isopen={cancelDialog}
        handleOkay={handleOkay}
        handleClose={handleClose}
        message={"You have unsaved changes in your shopping list"}
        messageDetail={"Use Save button to save changes"}
        fisrtButton={"Don't Save"}
        secondButton={"Save"}
      ></IlmorModal>
      {mutation.isLoading && <Loader></Loader>}
      <div
        className={`footer__topbox${currentDevice} ${
          unsaved ? "disabledHeader" : ""
        }`}
      >
        <div className={`fuima footer__Image${currentDevice}`}>
          <img
            className={`footer__image${currentDevice}`}
            src={newlogo}
            alt="Ilmor Logo"
          />
        </div>

        <div className={`footer__Text${currentDevice}`}>
          <span
            onClick={() => {
              window.scrollTo(0, 0);
              setSideMenuAccount("home");
              setSelectedCategory("home");
              navigate({
                pathname: "/account",
              });
            }}
            className={`footer__h6${currentDevice}`}
          >
            MY ACCOUNT
          </span>
          {localFooterApiLinkData &&
            localFooterApiLinkData?.length > 0 &&
            localFooterApiLinkData?.map((item, index) => {
              const key = Object.keys(item)[0];
              const value = item[key];
              return (
                <span
                  key={index}
                  onClick={() => {
                    handleFooterClick(key);
                  }}
                  className={`footer__h6${currentDevice}`}
                >
                  {/* {key},  */}

                  {value}
                </span>
              );
            })}

          {/* <span className={`footer__h6${currentDevice}`}>MY ACCOUNT</span>
            <span
              onClick={() => {
                navigate({
                  pathname: "/ReturnExchange",
                });
              }}
              className={`footer__h6${currentDevice}`}
            >
              RETURNS / EXCHANGES
            </span>
            <span
              onClick={() => {
                navigate({
                  pathname: "/ShippingPolicy",
                });
              }}
              className={`footer__h6${currentDevice}`}
            >
              SHIPPING POLICY
            </span>
            <span className={`footer__h6${currentDevice}`}>CONTACT</span> */}
        </div>
      </div>
      <div className={`footer__bottombox${currentDevice}`}>
        <span>{commonMessages?.footerStatement}</span>{" "}
        {currentDevice !== "Desktop" ? <br></br> : <></>}
        <span
          className="underLine"
          onClick={() => {
            window.open(" https://www.ilmor.com/Privacy", "_blank");
          }}
        >
          {"Privacy"}
        </span>
        |{" "}
        <span
          className="underLine"
          onClick={() => {
            window.open("https://www.ilmor.com/Terms", "_blank");
          }}
        >
          {"Terms of Use"}
        </span>{" "}
        |{" "}
        <span
          className="underLine"
          onClick={() => {
            navigate({
              pathname: "/content/warranty",
            });
          }}
        >
          {"Warranty"}
        </span>{" "}
        <Box component="span" sx={{ display: "inline", marginRight: "3px" }}>
          {" "}
          |{" "}
        </Box>
        <span
          className="underLine"
          onClick={() => {
            window.open("https://www.ilmor.com/Cookies", "_blank");
          }}
        >
          {"Cookie Policy"}
        </span>
      </div>
    </div>
  );
};

export default Footer;
