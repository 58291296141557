import { MarginTwoTone } from "@mui/icons-material";
import { styled, Typography } from "@mui/material";

export const UserRoleMyProfile = styled(Typography)({
    color: "var(--brand-colors-black, #07080C)",

    /* Display sm/Bold */
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "800",
    lineHeight: "120%", /* 19.2px */
    letterSpacing: "2.5px",
    textTransform:"uppercase",
    marginTop : "35px"
    
    
  });

  export const InputBoxLabel = styled(Typography)({
    width: "155px",
    color: "var(--brand-colors-black, #07080C)",
/* forms/label/Bold */
fontFamily: "Montserrat",
fontSize: "10px",
fontStyle: "normal",
fontWeight: "700",
lineHeight: "normal",
letterSpacing: "1px",
textTransform: "uppercase",
  });

  export const InputBoxFirstLastName = styled(Typography)({
    display: "flex",
height: "42px",
padding: "16px",
alignItems: "center",
gap: "12px",
alignSelf: "stretch",
border: "1px solid rgba(97, 94, 92, 0.15)",
background: "var(--white, #FFF)",
  });

