import React, { useState } from "react";
import { useStore } from "../store/home";
import NumericInput from "react-numeric-input";
import coolant from "../Images/coolant.png";
import Tooltip from "@mui/material/Tooltip";
import Accordion from "@mui/material/Accordion";
import Select from "react-select";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import classnames from "classnames";
import place from "../Images/placeHolder.png";
import { useEffect } from "react";
import { Box, Checkbox } from "@mui/material";
import {
  addToCart,
  applyCoupon,
  getMyCart,
  removeFromCart,
  removeItemWish,
  resetModifyId,
} from "../api/posts";
import {
  sortArrayOptions,
  bulkActions,
  bulkActionsTech,
  addCommaDecimal,
  addCommaDecimalreturnZ,
} from "../commonFunction";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { addWishList } from "../api/posts";
import { getCurrentDate } from "../commonFunction";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../commenComp/Loader";
import CircularProgress from "@mui/material/CircularProgress";
import IlmorModal from "../commenComp/IlmorModal";
import { Grid } from "@material-ui/core";
import moment from "moment";
import { customStyles } from "../utils/ReactSelectCustomStyles";
import { showErrorToast, showSuccessToast } from "../utils/Toaster";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import { ActiveInactiveStyled } from "./StyledComponents/EmployeeAccountStyled";

export const ShoppingListComponent = ({
  reviewOrder,
  noUpdateCart,
  partiallyShippedRow,
  urlAndTrackingNumber,
  noPartOrder,
  addtoCart,
  orderDetailData,
  orderDetail,
  cencel,
  handleCancelOrder,
  isMobile,
  yourCart,
  isAutoApi,
  noRowColor,
  pop,
  dataArray,
  handleRefreshCallBack,
}) => {
  const {
    userInfo,
    token,
    currentDevice,
    setUnsaved,
    myProfile,
    dealerId,
    cCode,
    setMyCartMini,
    sideMenuAccount,
  } = useStore();
  const [tableData, setTableData] = useState([]);
  const [quantssArrow, setQuantssArrow] = useState();

  const [changeDetected, setChangeDetected] = useState(false);
  const { change, setChange, setLoadCArt, loadcart,loadcart1 } = useNonPersistedStore();
  const [countRemove, setCountRemove] = useState("");
  const location = useLocation();
  const buttonStyles = {
    fontFamily: "FontAwesome",
    fontSize: "12px",
  };

  useEffect(() => {
    let arr = [];
    if (dataArray && dataArray?.length > 0) {
      arr = dataArray;
      arr.forEach((element) => {
        const obj = {};
        if (
          Array.isArray(element.otherNotes) &&
          element.otherNotes.length > 0
        ) {
          obj[element.addedBy] = element.otherNotes;
          element.otherNotes = obj;
        }
        element.isloadingRemove = false;
        element.isloadingCart = false;
        element.isChecked = false;
      });
    } else {
      setUnsaved(false);
    }
    // if(typeof dataArray === 'object' && dataArray !== null){
    //   alert("aachakkkk")

    // }
    setTableData(arr);
  }, [dataArray]);

  const [expanded, setExpanded] = useState(false);
  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  const coupon = useMutation((post) => applyCoupon(post, token), {
    onSuccess: (result) => {
      if (result.data.success) {
        // handleRefreshCallBack();
      } else {
        showErrorToast(result.data.msg);
      }
    },
    onError: (error) => {},
  });
  const mutation_cart = useMutation((post) => getMyCart(post, token), {
    onSuccess: (result) => {
      if (result?.data?.minicart?.count === 0) {
        if (cCode) {
          const obj = {};
          obj.email = userInfo?.username;
          obj.couponCode = cCode;
          obj.status = 0;
          coupon.mutate(obj);
        }
      }
      setMyCartMini(result);
    },
    onError: (error) => {},
  });
  useEffect(() => {
    const obj = {
      email: userInfo.username,
      dealerId: dealerId,
      minicart: true,
    };
    // mutation_cart.mutate(obj);
  }, []);

  const handleInputChange = (event, recordId) => {
    setChangeDetected(true);
    const updatedTableData = tableData.map((record) => {
      if (record.partId + record?.addedBy === recordId) {
        return { ...record, notes: event.target.value };
      }
      return record;
    });
    setChange(false);
    if (location.pathname === "/shopping-list") {
      setUnsaved(true);
    }
    setTableData(updatedTableData);
  };

  const listIsupdated = () =>
    toast.success("Shopping list is updated", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "dark",
    });

  const mutation = useMutation((post) => addWishList(post, token), {
    onSuccess: (result) => {
      if (result.data.success) {
        setUnsaved(false);
        listIsupdated();
      }
    },
    onError: (error) => {},
  });

  const handleAddToCartapi = (arAuto) => {
    let arr = [];
    arAuto.forEach((element) => {
      const obj = {};
      if (element?.partQty <= 0) {
        setQuantssArrow(1);
      } else {
        setQuantssArrow(element?.partQty);
      }

      obj.partId = element.partId;
      obj.quantity = element?.partQty <= 0 ? 1 : element?.partQty;
      // obj.addedBy= element?.addedBy;
      obj.notes = element.notes;
      const objFinal = {};
      if (element?.otherNotes && Object.values(element.otherNotes).length > 0) {
        Object.entries(element.otherNotes).forEach(([key, item]) => {
          const arr1 = [];
          item.forEach((par) => {
            const objnote = {};
            objnote.title = par.title;
            objnote.value = par.value;
            arr1.push(objnote);
          });
          objFinal[key] = arr1;
        });
      }
      obj.otherNotes = objFinal;
      arr.push(obj);
    });
    let data = {
      email: userInfo?.username,
      isPartDetail: true,
      update: true,
      items: arr,
    };
    toast.dismiss()
    mutationCart.mutate(data);
  };

  const handleInputChangeSelect = (event, row, allow) => {
    setChangeDetected(true);
    const arrr = tableData;
    arrr.forEach((element) => {
      if (element.partId === row.partId) {
        if(event != element.partQty){
          let ele = document.getElementById(row.partId)
          if(ele){
            ele.focus()
          }
        }
        if (event < 0) {
          element.partQty = 1;
        } else {
          element.partQty = event;
        }
      }
    });
    if (location.pathname === "/shopping-list") {
      setUnsaved(true);
    }
    setChange(false);
    setTableData(arrr);
    if (isAutoApi && allow) {
      handleAddToCartapi(arrr);
    }
  };

  const notifyShop = () =>
    toast.success("Item removed from Shopping List", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "dark",
    });

  const notifyShopCart = () =>
    toast.success("Item removed from Cart", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "dark",
    });

  const removeItem = useMutation((post) => removeItemWish(post, token), {
    onSuccess: (result) => {
      setAllCheck(false);
      notifyShop();
      handleRefreshCallBack();
    },
    onError: (error) => {},
  });

  const resetId = useMutation((post) => resetModifyId(token), {
    onSuccess: (result) => {
      setAllCheck(false);
      notifyShop();
      handleRefreshCallBack();
    },
    onError: (error) => {},
  });

  const removeItemCart = useMutation((post) => removeFromCart(post, token), {
    onSuccess: (result) => {
      if(result?.data?.minicart?.itemCount?.count === 0 && myProfile?.profileInfo?.previousOrderId){
        resetId.mutate()
      }
      const obj = {
        email: userInfo.username,
        dealerId: dealerId,
        minicart: true,
      };
      mutation_cart.mutate(obj);
      notifyShopCart();
      handleRefreshCallBack();
    },
    onError: (error) => {},
  });

  const notifyShop1 = () => 
    toast.success(`Products removed from Shopping list`);

  const removeItemAll = useMutation((post) => removeItemWish(post, token), {
    onSuccess: (result) => {
      // notifyShop1();
      showSuccessToast(`${selectedItems.length === 1 ?'Product removed from Shopping list' : 'Products removed from Shopping list'}`)
      setAllCheck(false);
      handleRefreshCallBack();
    },
    onError: (error) => {},
  });
  const handleSave = () => {
    setChangeDetected(false);
    const finalarray = [];
    tableData?.forEach((element) => {
      const arr1 = [];
      const obj = {};
      obj.partId = element.partId;
      obj.isShoppingList = true;
      obj.quantity = element?.partQty <= 0 ? 1 : element?.partQty;
      obj.date = getCurrentDate();
      obj.notes = element.notes || "";
      const objFinal = {};
      if (
        element?.otherNotes &&
        Object.values(element?.otherNotes).length > 0
      ) {
        Object.entries(element.otherNotes).forEach(([key, item]) => {
          const arr1 = [];
          item.forEach((par) => {
            const objnote = {};
            objnote.title = par.title;
            objnote.value = par.value;
            arr1.push(objnote);
          });
          objFinal[key] = arr1;
        });
      }

      // if (element?.otherNotes) {
      //   element?.otherNotes?.forEach((item) => {
      //     const objnote = {};
      //     objnote[item.title] = item.value;
      //     arr1.push(objnote);
      //   });
      // }
      // obj.other_notes = arr1;
      obj.other_notes = objFinal;
      obj.userId = element?.userId;

      finalarray.push(obj);
    });
    const arr = {
      partDetails: finalarray,
    };
    mutation.mutate(arr);
  };

  const [selectedItems, setSelected] = useState([]);
  const [allCheck, setAllCheck] = useState(false);
  const handleRemove = (row) => {
    const arrr = tableData;
    arrr.forEach((element) => {
      if (element.partId === row.partId) {
        element.isloadingRemove = true;
      }
    });
    setTableData(arrr);
    const arr = [];
    const obj = {};
    obj.wishlistId = row.wishlistId;
    obj.partId = row.partId;
    obj.addedUserId = row.userId;
    arr.push(obj);

    removeItem.mutate(arr);
  };

  const handleRemoveCart = (row) => {
    const arrr = tableData;
    arrr.forEach((element) => {
      if (element.partId === row.partId) {
        element.isloadingRemove = true;
      }
    });
    setTableData(arrr);
    const obj = {};
    obj.email = userInfo?.username;
    obj.partId = row.partId;
    obj.partName = row.partName;
    removeItemCart.mutate(obj);
  };
  const notify = () => toast.success("Your Cart is updated");
  const notify1 = () => toast.success("Item was added to cart");
  const notify2 = () => toast.success("All selected itmes added to cart");

  const mutationCart = useMutation((post) => addToCart(post, token), {
    onSuccess: (result) => {
      if (result.data.success) {
        notify();
        const obj = {
          email: userInfo.username,
          minicart: true,
        };
        mutation_cart.mutate(obj);
        handleRefreshCallBack();
        // window.scrollTo(0, 0);
      }
    },
    onError: (error) => {},
  });

  const mutationCartSingle = useMutation((post) => addToCart(post, token), {
    onSuccess: (result) => {
      if (result.data.success) {
        setAllCheck(false);
        notify1();
        const obj = {
          email: userInfo.username,
          minicart: true,
        };
        mutation_cart.mutate(obj);
        handleRefreshCallBack();
      }
    },
    onError: (error) => {},
  });

  const mutationCartAll = useMutation((post) => addToCart(post, token), {
    onSuccess: (result) => {
      notify2();
      const obj = {
        email: userInfo.username,
        minicart: true,
      };
      mutation_cart.mutate(obj);
      handleRefreshCallBack();
    },
    onError: (error) => {},
  });

  const handleAddToCart = () => {
    let arr = [];
    tableData.forEach((element) => {
      const obj = {};
      obj.partId = element?.partId;
      obj.quantity = element?.partQty <= 0 ? 1 : element?.partQty;
      // obj.addedBy= element?.addedBy;
      obj.notes = element.notes;
      const objFinal = {};
      if (element?.otherNotes && Object.values(element.otherNotes).length > 0) {
        Object.entries(element.otherNotes).forEach(([key, item]) => {
          const arr1 = [];
          item.forEach((par) => {
            const objnote = {};
            objnote.title = par.title;
            objnote.value = par.value;
            arr1.push(objnote);
          });
          objFinal[key] = arr1;
        });
      }
      obj.otherNotes = objFinal;
      arr.push(obj);
    });
    let data = {
      email: userInfo?.username,
      isPartDetail: true,
      update: true,
      items: arr,
    };
    toast.dismiss()
    mutationCart.mutate(data);
  };

  const handleAddToCartSingleItem = (row) => {
    if (row.partStatus !== "Discontinued") {
      const arrr = tableData;
      arrr.forEach((element) => {
        if (element.partId === row.partId) {
          element.isloadingCart = true;
        }
      });
      const arrNote = [];
      if (row.otherNotes && Object.values(row.otherNotes).length > 0) {
        Object.values(row.otherNotes).forEach((item) => {
          item.forEach((par) => {
            const obj = {};
            obj.title = par.title;
            obj.value = par.value;
            arrNote.push(obj);
          });
        });
      }

      const arr = {
        partId: row.partId,
        quantity: row?.partQty <= 1 ? 1 : row?.partQty,
        // isPartDetail: false,
        // date: getCurrentDate(),
        notes: row.notes,
        otherNotes: arrNote,
        addedBy: row?.addedBy,
        userId: row?.userId,
      };
      let obj = [];
      obj.push(arr);

      let data = {
        wishListId: row?.wishlistId,
        email: userInfo?.username,
        isPartDetail: true,
        items: obj,
      };
      mutationCartSingle.mutate(data);
    } else {
      showErrorToast("Discontinued part cannot be moved to shopping list");
    }
  };

  const handleSort = (selection) => {
    if (selection.value === "status") {
      const arrr = tableData;
      const sortedArray = [...arrr].sort((a, b) =>
        a.partStatus.toLowerCase().localeCompare(b.partStatus.toLowerCase())
      );
      setTableData(sortedArray);
    }
    if (selection.value === "descAZ") {
      const arrr = tableData;
      const sortedArray = [...arrr].sort((a, b) =>
        a.partName.toLowerCase().localeCompare(b.partName.toLowerCase())
      );
      setTableData(sortedArray);
    }
    if (selection.value === "partNo") {
      const arrr = tableData;
      const sortedArray = [...arrr].sort((a, b) =>
        a?.partNumber?.toString()?.toLowerCase()?.localeCompare(b?.partNumber?.toString()?.toLowerCase())
      );
      setTableData(sortedArray);
    }
    if (selection.value === "addedBy") {
      const arrr = tableData;
      const sortedArray = [...arrr].sort((a, b) =>
        a?.addedBy?.toLowerCase()?.localeCompare(b?.addedBy?.toLowerCase())
      );
      setTableData(sortedArray);
    }
    if (selection.value === "priceLH") {
      const arrr = tableData;
      const sortedArray = [...arrr].sort((a, b) => {
        const aValue = a?.partDiscountCost;
        const bValue = b?.partDiscountCost;
        const aIsNumber = !isNaN(parseFloat(aValue));
        const bIsNumber = !isNaN(parseFloat(bValue));

        if (aIsNumber && bIsNumber) {
          return parseFloat(aValue) - parseFloat(bValue);
        } else if (aIsNumber) {
          return -1;
        } else if (bIsNumber) {
          return 1;
        } else {
          return aValue.localeCompare(bValue);
        }
      });

      setTableData(sortedArray);
    }
    if (selection.value === "priceHL") {
      const arrr = tableData;
      const sortedArray = [...arrr].sort((a, b) => {
        const aValue = a?.partDiscountCost;
        const bValue = b?.partDiscountCost;
        const aIsNumber = !isNaN(parseFloat(aValue));
        const bIsNumber = !isNaN(parseFloat(bValue));

        if (aIsNumber && bIsNumber) {
          return parseFloat(bValue) - parseFloat(aValue);
        } else if (aIsNumber) {
          return -1;
        } else if (bIsNumber) {
          return 1;
        } else {
          return aValue.localeCompare(bValue);
        }
      });

      setTableData(sortedArray);
    }
    if (selection.value === "newToOld") {
      const arrr = tableData;
      const sortedArray = [...arrr].sort((a, b) => {
        const aValue = a.addedDate.toLowerCase();
        const bValue = b.addedDate.toLowerCase();
        const aIsDate = !isNaN(Date.parse(aValue));
        const bIsDate = !isNaN(Date.parse(bValue));

        if (aIsDate && bIsDate) {
          return new Date(aValue) - new Date(bValue);
        } else if (aIsDate) {
          return -1;
        } else if (bIsDate) {
          return 1;
        } else {
          return aValue.localeCompare(bValue);
        }
      });

      setTableData(sortedArray);
    }
    if (selection.value === "oldToNew") {
      const arrr = tableData;
      const sortedArray = [...arrr].sort((a, b) => {
        const aValue = a.addedDate.toLowerCase();
        const bValue = b.addedDate.toLowerCase();
        const aIsDate = !isNaN(Date.parse(aValue));
        const bIsDate = !isNaN(Date.parse(bValue));

        if (aIsDate && bIsDate) {
          return new Date(bValue) - new Date(aValue);
        } else if (aIsDate) {
          return -1;
        } else if (bIsDate) {
          return 1;
        } else {
          return aValue.localeCompare(bValue);
        }
      });

      setTableData(sortedArray);
    }
  };

  const navigate = useNavigate();
  const [cancelDialog, setCancelDialog] = useState(false);
  const handleCancel = () => {
    setCancelDialog(!cancelDialog);
  };

  const handleOkay = () => {
    setCancelDialog(!cancelDialog);
    handleCancelOrder();
  };

  const handleApply = () => {
    if (bulkAction.value === "move") {
      setUnsaved(false);
      const filteredArray1 = tableData?.filter((obj1) =>
        selectedItems.some((obj2) => obj2.partId === obj1.partId)
      );
      let flag = true;
      filteredArray1.forEach((item) => {
        if (item.partStatus === "Discontinued") {
          flag = false;
        }
      });
      if (flag) {
        const arr = [];
        filteredArray1 &&
          filteredArray1.length > 0 &&
          filteredArray1?.forEach((element) => {
            const arrNote = [];
            const objFinal = {};
            let arr1 = [];
            if (element && element.otherNotes) {
              if (element && Object.values(element?.otherNotes).length > 0) {
                Object.entries(element.otherNotes).forEach(([key, item]) => {
                  // item.forEach((par) => {
                  const objnote = {};
                  objnote.title = item.title;
                  objnote.value = item.value;
                  arr1.push(objnote);
                  // });
                  arr1 = item;
                  objFinal[key] = arr1;
                });
              }
            }
            const obj = {};
            obj.partId = element.partId;
            obj.quantity = element.partQty <= 1 ? 1 : element.partQty;
            obj.addedBy = element?.addedBy;
            obj.notes = element.notes;
            obj.otherNotes = arr1;
            obj.userId = element?.userId;

            arr.push(obj);
          });
        let data = {
          wishListId: filteredArray1[0]?.wishlistId,
          email: userInfo?.username,
          isPartDetail: true,
          items: arr,
        };

        mutationCartAll.mutate(data);
      } else {
        showErrorToast("Remove dicontinued parts from bulk selection");
      }
    }
    if (bulkAction.value === "remove") {
      const filteredArray1 = tableData.filter((obj1) =>
        selectedItems.some(
          (obj2) =>
            (obj2.partId === obj1.partId &&
              obj2?.userId === myProfile?.profileInfo?.id) ||
            (myProfile?.profileInfo?.role !== "ServiceTechnician" &&
              obj1.isChecked)
        )
      );
      setCountRemove(filteredArray1.length);
      const arr = [];
      if (filteredArray1.length > 0) {
        filteredArray1.forEach((element) => {
          const obj = {};
          obj.wishlistId = element.wishlistId;
          obj.partId = element.partId;
          obj.addedUserId = element.userId;

          arr.push(obj);
        });
        removeItemAll.mutate(arr);
      } else {
        setAllCheck(false);
        handleRefreshCallBack();
        showErrorToast("No product was removed from Shopping list");
      }
    }
  };
  
  useEffect(() => {
    if (mutationCart.isLoading) {
      setLoadCArt(true);
    } else {
      setLoadCArt(false);
    }
  }, [mutationCart.isLoading]);

  const [ok, setOk] = useState(false);
  useEffect(() => {
    if (tableData.length > 0) {
      const selItem = tableData.filter((item) => item.isChecked);
      const allFlagsChecked = tableData.every((obj) => obj.isChecked);
      if (allFlagsChecked) {
        setAllCheck(true);
      }
      setSelected(selItem);
    }
  }, [tableData, ok]);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const handleCheck = (flag, row) => {
    if (!flag) {
      setAllCheck(false);
    }
    const arrr = tableData;
    arrr.forEach((element) => {
      if (element.partId + element.userId === row.partId + row.userId) {
        element.isChecked = flag;
      }
    });
    setOk(!ok);
    setTableData(arrr);
  };

  const handleAddInfo = (row, item, val, key, index) => {
    setChangeDetected(true);
    const updatedTableData = [...tableData];
    const partNote = updatedTableData.find((partNote) => {
      if (
        partNote?.otherNotes &&
        Object.values(partNote?.otherNotes).length > 0
      ) {
        if (partNote.addedBy) {
          return (
            partNote.partId + partNote.addedBy === row.partId + row.addedBy
          );
        } else {
          return partNote.partId === row.partId;
        }
      }
    });

    if (partNote) {
      const obj = partNote?.otherNotes[key];
      const obval = {};
      obval.title = item.title;
      obval.value = val;

      obj[index] = obval;

      // const note = partNote.otherNotes.find(
      //   (note) => note.title === item.title
      // );
      // if (note) {
      //   note.value = val;
      // }
    }
    setChange(false);
    if (location.pathname === "/shopping-list") {
      setUnsaved(true);
    }
    setTableData(updatedTableData);
  };

  const handleCheckAll = (flag) => {
    setAllCheck(flag);
    const arrr = tableData;
    const currentTime = new Date();
    const updatedTableData = tableData.map((element) => ({
      ...element,
      isChecked: flag,
    }));
    setOk(!ok);
    setTableData([...updatedTableData]);
  };

  const handleProductClick = (id, flag) => {
    if (!flag) {
      navigate({
        pathname: "/part",
        search: `number=${id}`,
      });
    }
  };
  const [bulkAction, setbulkAction] = useState("");
  return (
    <>
      <IlmorModal
        isopen={cancelDialog}
        handleOkay={handleOkay}
        handleClose={handleCancel}
        message={"Cancel this order?"}
        fisrtButton={"Don’t Cancel"}
        secondButton={"Yes Cancel"}
      ></IlmorModal>
      {/* {tableData && tableData.length === 0 && (
        <div className="cartEmptyMessage">
          {isCart ? "your cart is empty" : "your shopping list is empty"}{" "}
        </div>
      )} */}
      {(mutation.isLoading || mutationCartAll.isLoading) && (
        // mutationCart.isLoading

        <Loader></Loader>
      )}
      {removeItemAll.isLoading && <Loader></Loader>}
      {/* {reviewOrder &&
        !addtoCart &&
        !yourCart &&
        tableData &&
        tableData.length > 0 && (
          <div className="billTtile marginTop">PArts Ordered</div>
        )} */}
      {(currentDevice === "Desktop" ||
        yourCart ||
        (reviewOrder && currentDevice !== "Mobile")) &&
        tableData &&
        tableData.length > 0 && (
          <div
            // className={`shoppingListoutDesktop`
            className={classnames("shoppingListoutDesktop sd", {
              noborderShop:
                (orderDetailData?.orderStatus !== "Return Initiated" &&
                  orderDetailData?.orderStatus !== "Return Completed" &&
                  orderDetailData?.orderStatus !== "Partially Shipped" &&
                  orderDetailData?.orderStatus !== "Denied" &&
                  orderDetailData?.orderStatus !== "Pending" &&
                  orderDetailData?.orderStatus !== "Completed" &&
                  location.pathname !== "/checkout" &&
                  location.pathname !== "/cart" &&
                  location.pathname !== "/shopping-list") ||
                (location.pathname !== "/checkout" &&
                  location.pathname !== "/cart" &&
                  location.pathname !== "/shopping-list" &&
                  orderDetailData?.orderStatus !== "Denied" &&
                  orderDetailData?.orderStatus !== "Pending" &&
                  orderDetailData?.orderStatus !== "Return Completed" &&
                  orderDetailData?.orderStatus !== "Completed" &&
                  currentDevice === "Tablet"),
              btoporderdetail:
                orderDetailData?.orderStatus === "Pending" &&
                !cencel &&
                location.pathname === "/account/order-detail",
              drkjgdrkhjgb: location.pathname === "/checkout" && reviewOrder,
            })}
          >
            {!reviewOrder && !yourCart && (
              <div className="selectlistTopDiv">
                {!dealerId ? (
                  <div className="leftSideShop">
                    <Select
                      styles={customStyles}
                      className="headerSelectApply"
                      // defaultValue={''}
                      placeholder="Bulk Actions"
                      isSearchable={false}
                      value={bulkAction}
                      onChange={(e) => {
                        setbulkAction(e);
                      }}
                      // menuIsOpen={true}
                      options={
                        myProfile?.profileInfo?.permissions?.includes(
                          "add_to_own_cart"
                        )
                          ? bulkActions
                          : bulkActionsTech
                      }
                    />
                    <button
                      disabled={
                        !bulkAction ||
                        !selectedItems.length > 0 ||
                        (bulkAction.value === "move" &&
                          myProfile?.profileInfo?.role === "ServiceTechnician")
                      }
                      className="ilmorButon"
                      onClick={handleApply}
                      style={{ marginLeft: "10px" }}
                    >
                      Apply
                    </button>
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="rightSide width">
                  <span className="sortText">SORT BY</span>
                  <Select
                    styles={customStyles}
                    //  className="header__select iLmorborder"
                    className="headerSelectSortBy"
                    // defaultValue={''}
                    isSearchable={false}
                    placeholder="Date added - New to Old"
                    onChange={handleSort}
                    // menuIsOpen={true}
                    options={sortArrayOptions}
                  />
                </div>
              </div>
            )}
            {!yourCart &&
              !noPartOrder &&
              reviewOrder &&
              orderDetailData?.orderStatus !== "Return Initiated" &&
              orderDetailData?.orderStatus !== "Denied" &&
              orderDetailData?.orderStatus !== "Return Completed" && (
                <div
                  className={classnames("billTtile marginTop partOrderMob", {
                    line: orderDetailData?.isOrderHistorical === 1,
                  })}
                >
                  PArts Ordered
                </div>
              )}
            {orderDetailData?.isOrderHistorical === 1 && (
              <div className="trckDetail">
                <ActiveInactiveStyled
                  sx={{
                    cursor: "auto",
                  }}
                  status={true}
                >
                  {orderDetailData.orderStatus}
                </ActiveInactiveStyled>
                <div className="trackDiv">
                  <span className="trackNumberText">{` Tracking Number : `}</span>
                  <Box
                    component="span"
                    onClick={() => {
                      window.open(
                        orderDetailData?.items[0]?.trackingUrl,
                        "_blank"
                      );
                    }}
                    sx={{
                      display: "inline",
                      color: "#CE0E2D",
                      fontSize: "13px",
                      fontWeight: "500",
                      textDecoration: "underline",
                      marginLeft: "4px",
                      cursor: "pointer",
                      marginRight: "10px",
                    }}
                  >
                    {orderDetailData?.items[0]?.trackingNumber}
                  </Box>
                  <span className="trackNumberDate">
                    {orderDetailData?.shippingDate.replace(/\./g, "/")}
                  </span>
                </div>
              </div>
            )}
            {tableData && selectedItems && tableData.length > 0 && (
              <table
                className={`shoppingListTableDesktop desktopCancelOrder${
                  reviewOrder ? " widthForTable" : ""
                }${addtoCart ? " widthForTableAtc" : ""}`}
              >
                {currentDevice !== "Mobile" && orderDetail && (
                  <thead className={classnames("shoppingListTableHeadDesktop")}>
                    <tr className={`shoppingListTableHeadRowDesktop`}>
                      <th
                        width="15%"
                        style={{ textAlign: "left", paddingLeft: "8px" }}
                        className={classnames(
                          "shoppingListTableHeadingDesktop part ",
                          {
                            partOnlyDesktopYourCart:
                              location.pathname == "/cart",
                          }
                        )}
                      >
                        Part
                      </th>
                      <th
                        width="10%"
                        style={{ textAlign: "center" }}
                        className={`shoppingListTableHeadingDesktop`}
                      >
                        Qty
                      </th>
                      {/* return code */}
                      {tableData[0]?.orderItemStatus == "intiatedReturn" ||
                      tableData[0]?.orderItemStatus == "return_completed" ||
                      tableData[0]?.orderItemStatus == "denied" ? (
                        <th
                          width="10%"
                          style={{ textAlign: "center" }}
                          className={`shoppingListTableHeadingDesktop`}
                        >
                          Return Qty
                        </th>
                      ) : (
                        <></>
                      )}
                      {(myProfile?.profileInfo?.permissions?.includes(
                        "add_to_own_cart"
                      ) ||
                        dealerId) && (
                        <th
                          style={{ textAlign: "center" }}
                          width="5%"
                          className={`shoppingListTableHeadingDesktop sdf`}
                        >
                          Price
                        </th>
                      )}
                      <th
                        width="10%"
                        style={{ textAlign: "center" }}
                        className={`shoppingListTableHeadingDesktop`}
                      >
                        Total
                      </th>
                      {/* {partiallyShippedRow && (
                        <th
                          width="10%"
                          style={{ textAlign: "center" }}
                          className={`shoppingListTableHeadingDesktop`}
                        >
                          Tracking Number
                        </th>
                      )} */}
                    </tr>
                  </thead>
                )}
                {!reviewOrder && (
                  <thead
                    className={classnames("shoppingListTableHeadDesktop", {
                      headBorder: !yourCart,
                    })}
                  >
                    <tr className={`shoppingListTableHeadRowDesktop`}>
                      {!reviewOrder && !yourCart && (
                        <th width="1%">
                          {/* <input
                            className={`myProfile_sendNotiFcncheckBox`}
                            onClick={(event) =>
                              handleCheckAll(event.target.checked)
                            }
                            value={allCheck}
                            checked={allCheck}
                            type="checkbox"
                          /> */}
                          <Checkbox
                            {...label}
                            className="inputshoplist1"
                            value={allCheck}
                            checked={allCheck}
                            onClick={(event) =>
                              handleCheckAll(event.target.checked)
                            }
                            sx={{
                              "&.Mui-checked .MuiIconButton-label": {
                                backgroundColor: "black",
                              },
                            }}
                            // onChange={handleRememberMeChange}
                            // color="default"
                          />
                        </th>
                      )}
                      <th
                        width="20%"
                        className={`shoppingListTableHeadingDesktop part`}
                      >
                        Part
                      </th>
                      <th
                        width="9%"
                        style={{ textAlign: "center" }}
                        className={`shoppingListTableHeadingDesktop`}
                      >
                        Qty
                      </th>

                      {(myProfile?.profileInfo?.permissions?.includes(
                        "add_to_own_cart"
                      ) ||
                        dealerId) && (
                        <th
                          width="9%"
                          style={{ textAlign: "center" }}
                          className={`shoppingListTableHeadingDesktop`}
                        >
                          Price
                        </th>
                      )}
                      {!reviewOrder && !yourCart && (
                        <th
                          style={{ textAlign: "center" }}
                          width="7%"
                          className={`shoppingListTableHeadingDesktop`}
                        >
                          Status
                        </th>
                      )}
                      {!reviewOrder && !yourCart && (
                        <th
                          style={{ textAlign: "center" }}
                          width="7%"
                          className={`shoppingListTableHeadingDesktop`}
                        >
                          Added By
                        </th>
                      )}
                      {!reviewOrder && !yourCart && (
                        <th
                          style={{ textAlign: "center" }}
                          width="7%"
                          className={`shoppingListTableHeadingDesktop`}
                        >
                          Date Added
                        </th>
                      )}
                      {reviewOrder && (
                        <th
                          width="10%"
                          style={{ textAlign: "center" }}
                          className={`shoppingListTableHeadingDesktop`}
                        >
                          Total
                        </th>
                      )}
                      {yourCart && (
                        <th
                          width="12%"
                          style={{ textAlign: "center" }}
                          className={`shoppingListTableHeadingDesktop`}
                        >
                          Total
                        </th>
                      )}
                    </tr>
                  </thead>
                )}
                <tbody
                  className={classnames("shoppingListTableBodyDesktop ", {
                    addtocart: location.pathname === "/quick-order-form" || noUpdateCart ,
                    noBotomObrt:
                      (location.pathname === "/account" &&
                        sideMenuAccount === "orderDetail") ||
                      (reviewOrder && location.pathname === "/checkout"),
                  })}
                >
                  {tableData?.map((row, index) => {
                    const styless =
                      index === tableData?.length - 1
                        ? { borderBottom: "1px solid red" }
                        : "";

                    return (
                      <>
                        {" "}
                        <tr
                          //  style={styless}
                          className={`shoppingListTableRowDesktop${
                            !noRowColor
                              ? index % 2 === 0
                                ? " EvenRow"
                                : ""
                              : ""
                          } ${orderDetail ? " ordeDetailWhiteRow" : ""} ${pop ? " xcfxff23" : ""}`}
                          key={row.partId + row.addedBy}
                        >
                          {!reviewOrder && !yourCart && (
                            <td className={`shoppingListprice`}>
                              {/* <input
                                className={`myProfile_sendNotiFcncheckBox`}
                                onClick={(event) =>
                                  handleCheck(event.target.checked, row)
                                }
                                checked={row.isChecked}
                                type="checkbox"
                              /> */}
                              <Checkbox
                                {...label}
                                checked={row.isChecked}
                                onClick={(event) =>
                                  handleCheck(event.target.checked, row)
                                }
                                sx={{
                                  "&.Mui-checked .MuiIconButton-label": {
                                    backgroundColor: "black",
                                  },
                                }}
                                // onChange={handleRememberMeChange}
                                // color="default"
                              />
                            </td>
                          )}
                          <td className={`shoppingListTableInputDivDesktop`}>
                            <div className="shopRow1">
                              <Tooltip
                                placement="top"
                                title={
                                  row?.partStatus === "Discontinued"
                                    ? "The part is discontinued "
                                    : ""
                                }
                              >
                                <div
                                  onClick={() => {
                                    handleProductClick(
                                      row.partNumber,
                                      row?.partStatus === "Discontinued"
                                    );
                                  }}
                                  className={classnames(
                                    "shopListRow1LeftSide",
                                    {
                                      disableButtonCart:
                                        row?.partStatus === "Discontinued",
                                      marginLeftImageOurCart:
                                        location.pathname == "/cart",
                                    }
                                  )}
                                >
                                  <img
                                    className="shopListRow1Image"
                                    src={row.images ? row.images : place}
                                    alt={
                                      row?.imagesAltText ||
                                      row?.partNumber ||
                                      "Ilmor Part Image"
                                    }
                                  ></img>
                                </div>
                              </Tooltip>

                              <div className="shopListRow1RightSide">
                                <div className="diplayTag">
                                  {row?.productLabel && (
                                    <span
                                      className={`cardList__newTextDesktop`}
                                    >
                                      {row?.productLabel}
                                    </span>
                                  )}
                                  <div
                                    onClick={() => {
                                      handleProductClick(
                                        row.partNumber,
                                        row?.partStatus === "Discontinued"
                                      );
                                    }}
                                    className={classnames("curp minwidst", {
                                      disableButtonCart:
                                        row?.partStatus === "Discontinued",
                                    })}
                                  >
                                    {row?.partNumber && (
                                      <span
                                        className={`shoppingListSmallTitile`}
                                      >
                                        {(row?.partNumber).toString().length <=
                                        20
                                          ? row?.partNumber
                                          : row?.partNumber &&
                                            (row?.partNumber)
                                              .toString()
                                              ?.slice(0, 20) + "..."}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <span            onClick={() => {
                                      handleProductClick(
                                        row.partNumber,
                                        row?.partStatus === "Discontinued"
                                      );
                                    }} className={`curp shoppingListTitle`}>
                                  {row.partName}
                                </span>
                                {location.pathname !== "/quick-order-form" && (
                                  <span
                                    className={`shoppingListTitle notAvailableText`}
                                  >
                                    {row.isBackordered &&
                                    row?.inventory <= row?.inStockQuantity
                                      ? "Backordered"
                                      : ""}
                                  </span>
                                )}
                                {location.pathname !== "/shopping-list" ? (
                                  <div className="marBot">
                                    {row.partStatus && (
                                      <span
                                        className={classnames(
                                          "shoppingListTitle ",
                                          {
                                            notAvailableText:
                                              row.partStatus ===
                                                "Backordered" ||
                                              row.partStatus.includes("Only"),
                                          }
                                        )}
                                      >
                                        {row.partStatus ? row.partStatus : ""}
                                      </span>
                                    )}
                                    {row.specialOrder === "Yes" && (
                                      <span
                                        className={`shoppingListTitle notAvailableText shoppingListTablepriceMessage`}
                                      >
                                        {row.specialOrder === "Yes"
                                          ? "(Longer Lead Time)"
                                          : ""}
                                      </span>
                                    )}
                                    {row.message && (
                                      <>
                                        <br></br>
                                        <span
                                          className={`shoppingListTitle  shoppingListTablepriceMessage`}
                                        >
                                          {row.message
                                            ? ` (${row.message})`
                                            : ""}
                                        </span>
                                      </>
                                    )}
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {!reviewOrder && (
                                  <>
                                    {!yourCart && (
                                      <div className="inputBoxDiv">
                                        <input
                                          disabled={
                                            myProfile?.profileInfo?.role ===
                                              "ServiceTechnician" &&
                                            !row?.isPermission
                                          }
                                          type="text"
                                          placeholder="Add internal notes"
                                          className={`shoppingListInput`}
                                          value={row.notes}
                                          // if(partNote.addedBy){
                                          //   return partNote.partId + partNote.addedBy === row.partId + row.addedBy
                                          // }else{
                                          //   return partNote.partId  === row.partId
                                          // }
                                          onChange={(event) =>
                                            handleInputChange(
                                              event,
                                              row.partId + row.addedBy
                                            )
                                          }
                                        ></input>
                                      </div>
                                    )}
                                    {row?.otherNotes &&
                                      Object.values(row?.otherNotes).length >
                                        0 &&
                                      Object.values(row?.otherNotes)[0].length >
                                        0 && (
                                        <>
                                          {location.pathname !==
                                            "/shopping-list" && (
                                            <div className="requiredINfoClass">
                                              <div className="requiredINfo">
                                                Required information
                                              </div>
                                              <span className="imp">*</span>
                                            </div>
                                          )}
                                          <Grid
                                            container
                                            style={{
                                              // flexDirection: "column",
                                              // display: "flex",

                                              paddingLeft: "5px",
                                              textAlign: "left",
                                            }}
                                            spacing={1}
                                            lg={12}
                                            xs={12}
                                            md={12}
                                          >
                                            {Object.entries(
                                              row?.otherNotes
                                            )?.map(
                                              ([key, value]) =>
                                                value &&
                                                value.length > 0 && (
                                                  <>
                                                    <Grid
                                                      container
                                                      style={{
                                                        marginTop: "3px",
                                                      }}
                                                      spacing={1}
                                                    >
                                                      {value?.map(
                                                        (innerArray, index) => (
                                                          <>
                                                            <Grid item md={6}>
                                                              {location.pathname !==
                                                                "/shopping-list" && (
                                                                <div className="labelPartDetail">
                                                                  {
                                                                    innerArray.title
                                                                  }
                                                                  <span className="red">
                                                                    {` *`}
                                                                  </span>
                                                                </div>
                                                              )}
                                                              <input
                                                                className={`InputFieldShippingAddressPayment shoppingListInput addRed`}
                                                                placeholder={
                                                                  innerArray.title
                                                                }
                                                                disabled={
                                                                  myProfile
                                                                    ?.profileInfo
                                                                    ?.role ===
                                                                    "ServiceTechnician" &&
                                                                  !row?.isPermission
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) => {
                                                                  handleAddInfo(
                                                                    row,
                                                                    innerArray,
                                                                    event.target
                                                                      .value,
                                                                    key,
                                                                    index
                                                                  );
                                                                }}
                                                                value={
                                                                  innerArray.value
                                                                }
                                                                type="text"
                                                                name="firstname"
                                                              ></input>
                                                            </Grid>
                                                            <br></br>
                                                          </>
                                                        )
                                                      )}
                                                    </Grid>
                                                  </>
                                                )
                                            )}
                                          </Grid>
                                        </>
                                      )}
                                  </>
                                )}
                                {orderDetail && (
                                  <>
                                    {row?.otherNotes &&
                                      Object.values(row?.otherNotes).length >
                                        0 &&
                                      Object.values(row?.otherNotes)[0].length >
                                        0 && (
                                        <>
                                          {location.pathname !==
                                            "/shopping-list" && (
                                            <div className="requiredINfoClass">
                                              <div className="requiredINfo">
                                                Required information
                                              </div>
                                              <span className="imp">*</span>
                                            </div>
                                          )}
                                          <Grid
                                            container
                                            style={{
                                              // flexDirection: "column",
                                              // display: "flex",
                                              // marginTop: "20px",
                                              textAlign: "left",
                                            }}
                                            spacing={1}
                                            lg={12}
                                            xs={12}
                                            md={12}
                                          >
                                            {Object.entries(
                                              row?.otherNotes
                                            )?.map(
                                              ([key, value]) =>
                                                value &&
                                                value.length > 0 &&
                                                value?.map(
                                                  (innerArray, index) => (
                                                    <>
                                                      <Grid item md={6}>
                                                        {location.pathname !==
                                                          "/shopping-list" && (
                                                          <div className="labelPartDetail">
                                                            {innerArray.title}
                                                            <span className="red">
                                                              *
                                                            </span>
                                                          </div>
                                                        )}
                                                        <input
                                                          className={`InputFieldShippingAddressPayment shoppingListInput`}
                                                          placeholder={
                                                            innerArray.title
                                                          }
                                                          disabled={
                                                            myProfile
                                                              ?.profileInfo
                                                              ?.role ===
                                                              "ServiceTechnician" &&
                                                            !row?.isPermission
                                                          }
                                                          onChange={(event) => {
                                                            handleAddInfo(
                                                              row,
                                                              innerArray,
                                                              event.target
                                                                .value,
                                                              key,
                                                              index
                                                            );
                                                          }}
                                                          value={
                                                            innerArray.value
                                                          }
                                                          type="text"
                                                          name="firstname"
                                                        ></input>
                                                      </Grid>
                                                    </>
                                                  )
                                                )
                                            )}
                                          </Grid>
                                        </>
                                      )}
                                  </>
                                )}
                                {!reviewOrder && !yourCart && (
                                  <div className="shopListLow">
                                    {row?.isloadingRemove ? (
                                      <CircularProgress
                                        style={{ color: "#CE0E2D" }}
                                        size={20}
                                      />
                                    ) : (
                                      <>
                                        {
                                          // !(
                                          //   myProfile?.profileInfo.role ===
                                          //     "ServiceTechnician" &&
                                          //   !row?.isPermission
                                          // ) &&

                                          myProfile?.profileInfo?.role !==
                                          "ServiceTechnician" ? (
                                            <span
                                              onClick={(event) =>
                                                handleRemove(row)
                                              }
                                              className={`shopLiRemove`}
                                            >
                                              {<>Remove</>}
                                            </span>
                                          ) : (
                                            myProfile?.profileInfo?.role ===
                                              "ServiceTechnician" &&
                                            row?.userId ==
                                              myProfile?.profileInfo?.id && (
                                              <span
                                                onClick={(event) =>
                                                  handleRemove(row)
                                                }
                                                className={`shopLiRemove`}
                                              >
                                                {row?.isPermission && (
                                                  <>Remove</>
                                                )}
                                              </span>
                                            )
                                          )
                                        }
                                      </>
                                    )}
                                    {myProfile?.profileInfo?.permissions?.includes(
                                      "add_to_own_cart"
                                    ) &&
                                      (row?.isPermission ||
                                        myProfile?.profileInfo?.role !==
                                          "ServiceTechnician") && (
                                        <div className="center sepBarDiv">
                                          <span className="sepBar">|</span>
                                        </div>
                                      )}
                                    {row.isloadingCart ? (
                                      <CircularProgress
                                        style={{ color: "#CE0E2D" }}
                                        size={20}
                                      />
                                    ) : (
                                      <>
                                        {myProfile?.profileInfo?.permissions?.includes(
                                          "add_to_own_cart"
                                        ) && (
                                          <span
                                            onClick={(event) =>
                                              handleAddToCartSingleItem(row)
                                            }
                                            className={`shopLiMovToCart moveCart`}
                                          >
                                            Move to Cart
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </div>
                                )}
                                {/* {
                        <div
                          className="backOrderedLabel"
                          style={{ color: "red" }}
                        >
                          {row.isBackordered ? "Backordered2" : ""}
                        </div>
                      } */}
                              </div>
                            </div>
                          </td>
                          {!reviewOrder && (
                            <td
                              className={classnames(
                                "shoppingListTableQuantityDivDesktop",
                                {
                                  blurQuant: mutationCart.isLoading || loadcart1 || loadcart,
                                }
                              )}
                            >
                              <div className="numericInputOrderDetailDiv">
                                
                                <NumericInput
                                  disabled={
                                    myProfile?.profileInfo?.role ===
                                      "ServiceTechnician" && !row?.isPermission
                                  }
                                  className={`shoppingListTableQuantityDesktop lighBorder`}
                                  style={buttonStyles}
                                  max={999}
                                  // min={1}
                                  value={row.partQty <= 0 ? "" : row.partQty}
                                  strict
                                  id={row.partId}
                                  mobile={false}
                                  onChange={(event) => {
                                    handleInputChangeSelect(event, row);
                                  }}
                                  onBlur={(event) => {
                                    if (event.target.value =="") {
                                      handleInputChangeSelect(1, row, true);
                                    } else {
                                      handleInputChangeSelect(event.target.value, row, true);
                                    }
                                  }}
                                  // value={row.partQty}
                                />
                                {yourCart && (
                                  <div className="removeLabelDiv">
                                    {row?.isloadingRemove ? (
                                      <CircularProgress
                                        style={{ color: "#CE0E2D" }}
                                        size={20}
                                      />
                                    ) : (
                                      !dealerId && (
                                        <span
                                          onClick={() => {
                                            handleRemoveCart(row);
                                          }}
                                          className="removeLabel"
                                        >
                                          Remove
                                        </span>
                                      )
                                    )}
                                  </div>
                                )}
                                {/* {(
                      <td className={`shoppingListdateTotaldiv`}>
                      <span className={`shoppingListTableprice`}>
                      Qty : {row.quant}
                      </span>
                      </td>
                    )} */}
                              </div>
                            </td>
                          )}
                          {reviewOrder && (
                            <td
                              className={classnames(
                                "shoppingListdateTotaldiv",
                                {
                                  nominWidth:
                                    location.pathname === "/account" ||
                                    location.pathname === "/checkout",
                                    dggy56dr45: pop
                                }
                              )}
                            >
                              <div className="shopPrice">
                                <span className={`shoppingListTableprice`}>
                                  {`${addtoCart || true ? "Qty:" : ""} ${
                                    row.partQty
                                  }`}
                                </span>
                              </div>
                            </td>
                          )}
                          {/* return code */}
                          {reviewOrder &&
                          (row?.orderItemStatus == "intiatedReturn" ||
                            row?.orderItemStatus == "return_completed" ||
                            row?.orderItemStatus == "denied") ? (
                            <td
                              className={classnames(
                                "shoppingListdateTotaldiv",
                                {
                                  nominWidth:
                                    location.pathname === "/account" ||
                                    location.pathname === "/checkout",
                                }
                              )}
                            >
                              <div className="shopPrice">
                                <span className={`shoppingListTableprice`}>
                                  {row?.returnQty}
                                </span>
                              </div>
                            </td>
                          ) : (
                            <></>
                          )}
                          {(myProfile?.profileInfo?.permissions?.includes(
                            "add_to_own_cart"
                          ) ||
                            dealerId) && (
                            <td className={`shoppingListprice`}>
                              <div className="shopPrice">
                                {row?.partDiscountCost && (
                                  <span className={`shoppingListTableprice`}>
                                    $
                                    {parseFloat(
                                      row?.partDiscountCost
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </span>
                                )}
                                {row?.partOrigionalCost &&
                                  parseFloat(row?.partOrigionalCost) !==
                                    parseFloat(row?.partDiscountCost) &&
                                  addCommaDecimal(row?.partOrigionalCost) && (
                                    <span
                                      className={`shoppingListTablepriceDiscounted`}
                                    >
                                      $
                                      {parseFloat(
                                        row?.partOrigionalCost
                                      ).toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </span>
                                  )}
                                {yourCart && (
                                  <span
                                    className={`shoppingListTablepriceCross`}
                                  >
                                    {row.price}
                                  </span>
                                )}
                              </div>
                            </td>
                          )}

                          {!reviewOrder && !yourCart && (
                            <td className={`shoppingListstatus`}>
                              <div className="shopPrice">
                                <span
                                  className={classnames(
                                    "shoppingListTablestatus",
                                    {
                                      notAvailableText:
                                        row.partStatus === "Backordered",
                                    }
                                  )}
                                >
                                  {row.partStatus}
                                </span>
                              </div>
                            </td>
                          )}
                          {!reviewOrder && !yourCart && (
                            <td className={`shoppingListaddedBy`}>
                              <div className="shopPrice">
                                <span className={`shoppingListTableaddedBy`}>
                                  {row.addedBy}
                                </span>
                              </div>
                            </td>
                          )}
                          {!reviewOrder && !yourCart && (
                            <td className={`shoppingListdate`}>
                              <div className="shopPrice">
                                <span className={`shoppingListTabledate`}>
                                  {/* {row.addedDate} */}
                                  {row?.addedDate}
                                </span>
                              </div>
                            </td>
                          )}
                          {/* {reviewOrder && (
                <td className={`shoppingListdateTotaldiv`}>
                  <span className={`shoppingListTabledateTotal`}>
                    {row.total}
                  </span>
                </td>
              )} */}

                          {(yourCart || orderDetail || addtoCart) &&
                            (myProfile?.profileInfo?.permissions?.includes(
                              "add_to_own_cart"
                            ) ||
                              dealerId) && (
                              <td
                                className={classnames(
                                  "shoppingListdateTotaldiv",
                                  {
                                    nominWidth:
                                      location.pathname === "/account" ||
                                      location.pathname === "/checkout",
                                  }
                                )}
                              >
                                <div className="shopPrice">
                                  <span
                                    className={`shoppingListTabledateTotal`}
                                  >
                                    $
                                    {(
                                      row.partQty * row?.partDiscountCost
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </span>
                                </div>
                              </td>
                            )}
                          {partiallyShippedRow &&
                            false &&
                            urlAndTrackingNumber && (
                              <td className={`shoppingListdateTotaldiv`}>
                                <div className="shopPrice">
                                  <span
                                    className={`shoppingListTabledateTotal`}
                                  >
                                    {urlAndTrackingNumber[
                                      index
                                    ]?.dataShipped?.map((innerObj) => {
                                      return (
                                        <Box
                                          component="span"
                                          onClick={() => {
                                            window.open(
                                              innerObj.title,
                                              "_blank"
                                            );
                                          }}
                                          sx={{
                                            display: "block",
                                            color: "#CE0E2D",
                                            fontSize: "13px",
                                            fontWeight: "500",
                                            textDecoration: "underline",
                                            marginLeft: "4px",
                                            cursor: "pointer",
                                            marginRight: "10px",
                                          }}
                                        >
                                          {innerObj?.link}
                                        </Box>
                                      );
                                    })}

                                    {/* {(row?.trackingNumber).toString()} */}
                                  </span>
                                </div>
                              </td>
                            )}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
                {cencel && (
                  <div className="drftgfet422">
                    <Box className="desktopInnerDivBtnTest">
                      <button
                        style={{
                          margin: "14px 8px 0 0",
                        }}
                        onClick={handleCancel}
                        disabled={
                          myProfile?.profileInfo?.role === "ServiceTechnician"
                        }
                        className={`ilmorButon`}
                      >
                        Cancel Order
                      </button>
                    </Box>
                  </div>
                )}
              </table>
            )}

            {!reviewOrder && !yourCart && (
              <div className={`shoppingListButtonSectionDesktop`}>
                <div className={`shoppingListBottomButtonsDesktop`}>
                  {!dealerId ? (
                    <div className="leftSideShop">
                      <Select
                        styles={customStyles}
                        //  className="header__select iLmorborder"
                        className="headerSelectApply"
                        // defaultValue={''}
                        isSearchable={false}
                        placeholder="Bulk Actions"
                        value={bulkAction}
                        onChange={(e) => {
                          setbulkAction(e);
                        }}
                        // menuIsOpen={true}
                        options={
                          myProfile?.profileInfo?.permissions?.includes(
                            "add_to_own_cart"
                          )
                            ? bulkActions
                            : bulkActionsTech
                        }
                      />
                      <button
                        onClick={handleApply}
                        disabled={
                          !bulkAction ||
                          !selectedItems.length > 0 ||
                          (bulkAction.value === "move" &&
                            myProfile?.profileInfo?.role ===
                              "ServiceTechnician")
                        }
                        className="ilmorButon"
                        style={{ marginLeft: "10px" }}
                      >
                        Apply
                      </button>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div
                    className={classnames(
                      "shoppingListTableRightButtonsDesktop",
                      {
                        df343gdfg: location.pathname === "/shopping-list",
                      }
                    )}
                  >
                    <button
                      onClick={handleSave}
                      disabled={dealerId || !changeDetected}
                      id="shopListSave"
                      className={`ilmorButon`}
                    >
                      Save Updates
                    </button>
                  </div>
                </div>
              </div>
            )}

            {yourCart && (
              <Grid
                container
                style={{
                  display: "flex",
                  padding: "16px 8px",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "235px",
                  alignSelf: "stretch",
                  // borderTop: "2px solid var(--brand-colors-black, #07080C)",
                }}
              >
                {!(location.pathname === "/quick-order-form" || noUpdateCart) && (
                  <Grid item>
                    <button
                      disabled={change || mutationCart.isLoading}
                      onClick={handleAddToCart}
                      className="ilmorButon"
                    >
                      <span className="updateCartLabel">Update Cart</span>
                    </button>
                  </Grid>
                )}
              </Grid>
            )}
          </div>
        )}

      {/*================> TABLET CODE <============================*/}

      {currentDevice === "Tablet" && !reviewOrder && !yourCart && (
        <div
          // className={`shoppingListoutDesktop`}
          className={classnames("shoppingListoutDesktop sdf", {
            noborderShop:
              orderDetailData?.orderStatus !== "Return Initiated" &&
              location.pathname === "/myAccount",
            jtgygjty:
              tableData?.length === 0 && location.pathname === "/shopping-list",
          })}
        >
          {!reviewOrder && !yourCart && (
            <div className="selectlistTopDiv">
              {!dealerId ? (
                <div className="leftSideShop">
                  <Select
                    styles={customStyles}
                    className="headerSelectApplyTablet"
                    // className="header__select iLmorborder"
                    // defaultValue={''}
                    placeholder="Bulk Actions"
                    isSearchable={false}
                    value={bulkAction}
                    onChange={(e) => {
                      setbulkAction(e);
                    }}
                    // menuIsOpen={true}
                    options={
                      myProfile?.profileInfo?.permissions?.includes(
                        "add_to_own_cart"
                      )
                        ? bulkActions
                        : bulkActionsTech
                    }
                  />
                  <button
                    onClick={handleApply}
                    disabled={
                      !bulkAction ||
                      !selectedItems.length > 0 ||
                      (bulkAction.value === "move" &&
                        myProfile?.profileInfo?.role === "ServiceTechnician")
                    }
                    className="ilmorButon"
                    style={{ marginLeft: "10px" }}
                  >
                    Apply
                  </button>
                </div>
              ) : (
                <div></div>
              )}
              <div className="rightSide">
                <span className="sortText">SORT BY</span>
                <Select
                  styles={customStyles}
                  className="headerSelectSortByTablet"
                  isSearchable={false}
                  // className="header__select iLmorborder"
                  // defaultValue={''}
                  placeholder="Date added - New to Old"
                  onChange={handleSort}
                  // onChange={[]}
                  // menuIsOpen={true}
                  options={sortArrayOptions}
                />
              </div>
            </div>
          )}
          <table
            className={classnames("shoppingListTableDesktop", {
              btop: location.pathname === "/shopping-list",
            })}
          >
            {
              <thead className={`shoppingListTableHeadDesktop`}>
                <tr className={`shoppingListTableHeadRowDesktop`}>
                  {!yourCart && (
                    <th width="1%">
                      {/* <input
                        className={`myProfile_sendNotiFcncheckBox`}
                        onClick={(event) =>
                          handleCheckAll(event.target.checked)
                        }
                        value={allCheck}
                        type="checkbox"
                      /> */}
                      <Checkbox
                        {...label}
                        className={classnames(" ", {
                          inputshoplist3:
                            location.pathname === "/shopping-list",
                        })}
                        sx={{
                          // color: "white",
                          // "&.Mui-checked": {
                          //   color: "white",
                          // },
                          // "& .MuiSvgIcon-root": {
                          //   fill: "black",
                          // },
                          // "&.Mui-checked .MuiSvgIcon-root": {
                          //   fill: "black",
                          // },
                          "&.Mui-checked .MuiIconButton-label": {
                            backgroundColor: "black",
                          },
                        }}
                        value={allCheck}
                        onClick={(event) =>
                          handleCheckAll(event.target.checked)
                        }
                        // color="default"
                      />
                    </th>
                  )}
                  {
                    <th
                      width="100%"
                      className={`shoppingListTableHeadingDesktop thWalaPartShoppingListTablet`}
                    >
                      Part
                    </th>
                  }
                  {reviewOrder && (
                    <th
                      width="5%"
                      className={`shoppingListTableHeadingDesktop`}
                    >
                      Qty
                    </th>
                  )}
                  {/* { yourCart && <th width="5%" className={`shoppingListTableHeadingDesktop`}>
                    Qty
                  </th>} */}
                  {reviewOrder &&
                    (myProfile?.profileInfo?.permissions?.includes(
                      "add_to_own_cart"
                    ) ||
                      dealerId) && (
                      <th
                        width="5%"
                        className={`shoppingListTableHeadingDesktop`}
                      >
                        Price
                      </th>
                    )}
                  {/* {yourCart && <th width="5%" className={`shoppingListTableHeadingDesktop`}>
                    Price
                  </th>} */}
                  {/* {yourCart  && (
                    <th
                      width="10%"
                      className={`shoppingListTableHeadingDesktop`}
                    >
                      Total
                    </th>
                  )} */}
                </tr>
              </thead>
            }
            <tbody className={`shoppingListTableBodyDesktop`}>
              {tableData && tableData?.length > 0 ? (
                tableData?.map((row, index) => (
                  <tr
                    className={`shoppingListTableRowDesktop${
                      index % 2 === 0 ? " EvenRow" : ""
                    } ${orderDetail ? " ordeDetailWhiteRow" : ""}`}
                    key={index}
                  >
                    {!yourCart && (
                      <td className={`shoppingListprice`}>
                        {/* <input
                          className={`myProfile_sendNotiFcncheckBox`}
                          onClick={(event) =>
                            handleCheck(event.target.checked, row)
                          }
                          checked={row.isChecked}
                          type="checkbox"
                        /> */}
                        <Checkbox
                          {...label}
                          className={classnames(" ", {
                            srrflihj: location.pathname === "/shopping-list",
                          })}
                          sx={{
                            // color: "white",
                            // "&.Mui-checked": {
                            //   color: "white",
                            // },
                            // "& .MuiSvgIcon-root": {
                            //   fill: "black",
                            // },
                            // "&.Mui-checked .MuiSvgIcon-root": {
                            //   fill: "black",
                            // },
                            "&.Mui-checked .MuiIconButton-label": {
                              backgroundColor: "black",
                            },
                          }}
                          checked={row.isChecked}
                          onClick={(event) =>
                            handleCheck(event.target.checked, row)
                          }
                          // color="default"
                        />
                      </td>
                    )}
                    <td className={`shoppingListTableInputDivDesktop`}>
                      <div className="shopRow1">
                        <div className="shopListRow1LeftSide   tabletImageShoppingListContainerLeft">
                          <img
                            className="shopListRow1Image"
                            onClick={() => {
                              handleProductClick(
                                row.partNumber,
                                row?.partStatus === "Discontinued"
                              );
                            }}
                            alt={
                              row?.imagesAltText ||
                              row?.partNumber ||
                              "Ilmor Part Image"
                            }
                            src={row.images ? row.images : place}
                          ></img>
                        </div>
                        <div
                          className={classnames("shopListRow1RightSide", {
                            padingforshop:
                              location.pathname === "/shopping-list",
                          })}
                        >
                          <div className="shopListTabDetailsSecMain">
                            <div className="shopListTabDetailsSec1 ">
                              <div className="shopListTabDetailsSec1 shopListNewPartnameFlex">
                                {row?.productLabel && (
                                  <span className={`cardList__newTextDesktop`}>
                                    {row?.productLabel}
                                  </span>
                                )}
                                <div
                                  onClick={() => {
                                    handleProductClick(
                                      row.partNumber,
                                      row?.partStatus === "Discontinued"
                                    );
                                  }}
                                  className={classnames("curp minwidst", {
                                    disableButtonCart:
                                      row?.partStatus === "Discontinued",
                                  })}
                                >
                                  {row?.partNumber && (
                                    <span className={`shoppingListSmallTitile`}>
                                      {(row?.partNumber).toString().length <= 20
                                        ? row?.partNumber
                                        : row?.partNumber &&
                                          (row?.partNumber)
                                            .toString()
                                            ?.slice(0, 20) + "..."}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <span    onClick={() => {
                                    handleProductClick(
                                      row.partNumber,
                                      row?.partStatus === "Discontinued"
                                    );
                                  }} className={`curp shoppingListTitle`}>
                                {row.partName}
                              </span>
                              <br></br>
                              <span
                                className={classnames(
                                  "shoppingListTablestatus",
                                  {
                                    notAvailableText:
                                      row.partStatus === "Backordered",
                                  }
                                )}
                              >
                                {row.partStatus}
                              </span>
                            </div>
                            {!reviewOrder && !yourCart && (
                              <div
                                // className="shopListTabDetailsSec2"
                                className={classnames(
                                  "shopListTabDetailsSec2",
                                  {
                                    dpflexqty: location.pathname === "/account",
                                  }
                                )}
                              >
                                <span className="shopTableLabel">Qty</span>
                                <NumericInput
                                  disabled={
                                    myProfile?.profileInfo?.role ===
                                      "ServiceTechnician" && !row?.isPermission
                                  }
                                  className={`shoppingListTableQuantityDesktop lighBorder`}
                                  style={buttonStyles}
                                  max={999}
                                  // min={1}
                                  value={row.partQty <= 0 ? "" : row.partQty}
                                  strict
                                  id={row.partId}
                                  mobile={false}
                                  onChange={(event) =>
                                    handleInputChangeSelect(event, row)
                                  }
                                  onBlur={(event) => {
                                   
                                    if (event.target.value =="") {
                                      
                                      handleInputChangeSelect(1, row, true);
                                    } else {
                                      handleInputChangeSelect(event.target.value, row, true);
                                    }
                                  }}
                                  // value={row.partQty}
                                />
                              </div>
                            )}
                          </div>
                          <div
                            // className="shopListDetailTablet"
                            className={classnames("shopListDetailTablet", {
                              btop: location.pathname === "/shopping-list",
                              btop1: location.pathname === "/shopping-list",
                            })}
                          >
                            {(myProfile?.profileInfo?.permissions?.includes(
                              "add_to_own_cart"
                            ) ||
                              dealerId) && (
                              <div className="shopListTabDiv">
                                <span className="shopTableLabel">Price</span>
                                <span className={`shoppingListTableprice `}>
                                  {row?.partDiscountCost && (
                                    <span
                                      className={`shoppingListTableprice xcvg`}
                                    >
                                      ${addCommaDecimalreturnZ(row?.partDiscountCost)}
                                    </span>
                                  )}
                                  {row?.partOrigionalCost &&
                                    parseFloat(row?.partOrigionalCost) !==
                                      parseFloat(row?.partDiscountCost) &&
                                    addCommaDecimal(row?.partOrigionalCost) && (
                                      <span
                                        className={`shoppingListTablepriceDiscounted texcen`}
                                      >
                                        $
                                        {addCommaDecimal(
                                          row?.partOrigionalCost
                                        )}
                                      </span>
                                    )}
                                </span>
                              </div>
                            )}
                            {reviewOrder && (
                              <td className={`shoppingListdateTotaldiv`}>
                                <span className={`shoppingListTableprice`}>
                                  Qty : {row.partQty}
                                </span>
                              </td>
                            )}
                            {reviewOrder && (
                              <td className={`shoppingListdateTotaldiv`}>
                                <span className={`shoppingListTabledateTotal`}>
                                  {100}
                                </span>
                              </td>
                            )}
                            <div className="shopListTabDiv">
                              <span className="shopTableLabel">Added By</span>
                              <span className={`shoppingListTableaddedBy`}>
                                {row.addedBy}
                              </span>
                            </div>
                            {!yourCart && (
                              <div className="shopListTabDiv">
                                <span className="shopTableLabel">
                                  Date Added
                                </span>
                                <span
                                  className={`shoppingListTabledate dingolf`}
                                >
                                  {/* {row.addedDate} */}
                                  {row?.addedDate}
                                </span>
                              </div>
                            )}
                          </div>
                          {!reviewOrder && (
                            <>
                              <input
                                disabled={
                                  myProfile?.profileInfo?.role ===
                                    "ServiceTechnician" && !row?.isPermission
                                }
                                type="text"
                                placeholder="Add internal notes"
                                className={`shoppingListInput`}
                                value={row.notes}
                                onChange={(event) =>
                                  handleInputChange(
                                    event,
                                    row.partId + row.addedBy
                                  )
                                }
                              ></input>
                            </>
                          )}
                          {(!reviewOrder || orderDetail) && (
                            <>
                              <Grid
                                container
                                style={{
                                  // flexDirection: "column",
                                  // display: "flex",
                                  marginTop: "20px",
                                  textAlign: "left",
                                }}
                                spacing={1}
                                lg={12}
                                xs={12}
                                md={12}
                              >
                                {row?.otherNotes &&
                                  Object?.entries(row?.otherNotes)?.map(
                                    ([key, value]) =>
                                      value &&
                                      value.length > 0 &&
                                      value?.map((innerArray, index) => (
                                        <>
                                          <Grid item md={6}>
                                            {location.pathname !==
                                              "/shopping-list" && (
                                              <div className="labelPartDetail">
                                                {innerArray.title}
                                                <span className="red">*</span>
                                              </div>
                                            )}
                                            <input
                                              className={`InputFieldShippingAddressPayment shoppingListInput`}
                                              // disabled={false}
                                              placeholder={innerArray.title}
                                              disabled={
                                                myProfile?.profileInfo?.role ===
                                                  "ServiceTechnician" &&
                                                !row?.isPermission
                                              }
                                              onChange={(event) => {
                                                handleAddInfo(
                                                  row,
                                                  innerArray,
                                                  event.target.value,
                                                  key,
                                                  index
                                                );
                                              }}
                                              value={innerArray.value}
                                              type="text"
                                              name="firstname"
                                            ></input>
                                          </Grid>
                                        </>
                                      ))
                                  )}
                              </Grid>
                            </>
                          )}
                          {!yourCart && (
                            <div className="shopListLow">
                              {row?.isloadingRemove ? (
                                <CircularProgress
                                  style={{ color: "#CE0E2D" }}
                                  size={20}
                                />
                              ) : (
                                <>
                                  {!(
                                    myProfile?.profileInfo?.role ===
                                      "ServiceTechnician" && !row?.isPermission
                                  ) && (
                                    <span
                                      onClick={(event) => handleRemove(row)}
                                      className={`shopLiRemove`}
                                    >
                                      Remove
                                    </span>
                                  )}
                                </>
                              )}
                              {myProfile?.profileInfo?.permissions?.includes(
                                "add_to_own_cart"
                              ) && (
                                <div className="center sepBarDiv">
                                  <span className="sepBar">|</span>
                                </div>
                              )}
                              {myProfile?.profileInfo?.permissions?.includes(
                                "add_to_own_cart"
                              ) && (
                                <span
                                  onClick={(event) =>
                                    handleAddToCartSingleItem(row)
                                  }
                                  className={`shopLiMovToCart moveCart shopLiRemove`}
                                >
                                  Move to Cart
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <div className="cartEmptyMessage">
                  your shopping list is empty{" "}
                </div>
              )}
            </tbody>
          </table>
          {
            <div className={`shoppingListButtonSectionDesktop`}>
              <div
                className={classnames("shoppingListBottomButtonsDesktop", {
                  justifyContentRight: location.pathname !== "/shopping-list",
                })}
              >
                {!dealerId ? (
                  <div className="leftSideShop">
                    <Select
                      styles={customStyles}
                      className="headerSelectBottomTablet cutToCutTabletBulkActionBottom"
                      // className="header__select iLmorborder"
                      isSearchable={false}
                      // defaultValue={''}
                      placeholder="Bulk Actions"
                      value={bulkAction}
                      onChange={(e) => {
                        setbulkAction(e);
                      }}
                      // menuIsOpen={true}
                      options={
                        myProfile?.profileInfo?.permissions?.includes(
                          "add_to_own_cart"
                        )
                          ? bulkActions
                          : bulkActionsTech
                      }
                    />
                    <button
                      onClick={handleApply}
                      disabled={
                        !bulkAction ||
                        !selectedItems.length > 0 ||
                        (bulkAction.value === "move" &&
                          myProfile?.profileInfo?.role === "ServiceTechnician")
                      }
                      className="ilmorButon"
                      style={{ marginLeft: "10px" }}
                    >
                      Apply
                    </button>
                  </div>
                ) : (
                  <div></div>
                )}
                <div className={`shoppingListTableRightButtonsDesktop`}>
                  <button
                    onClick={handleSave}
                    disabled={dealerId || !changeDetected}
                    id="shopListSave"
                    className={`ilmorButon`}
                  >
                    Save Updates
                  </button>
                </div>
              </div>
            </div>
          }
        </div>
      )}

      {/* ==================> MOBILE CODE <==============================*/}
      {((currentDevice === "Mobile" && !reviewOrder && !yourCart) ||
        (currentDevice === "Mobile" && orderDetail) ||
        (isMobile && currentDevice === "Mobile")) &&
        tableData &&
        tableData.length > 0 && (
          <div
            // className={`shoppingListoutDesktop`}
            className={classnames("shoppingListoutDesktop ", {
              noborderShop:
                orderDetailData?.orderStatus !== "Return Initiated" &&
                orderDetailData?.orderStatus !== "Pending",
              marginCorrectionForReview: location.pathname === "/checkout",
              bbbot:
                (location.pathname === "/checkout" &&
                  reviewOrder &&
                  !expanded) ||
                (location.pathname === "/myAccount" &&
                  orderDetailData.orderStatus === "Processing" &&
                  !expanded),
            })}
          >
            {orderDetailData?.isOrderHistorical === 1 && (
              <div className="trckDetail">
                <ActiveInactiveStyled
                  sx={{
                    cursor: "auto",
                  }}
                  status={true}
                >
                  {orderDetailData.orderStatus}
                </ActiveInactiveStyled>
                <div className="trackDiv">
                  <span className="trackNumberText">{` Tracking Number : `}</span>
                  <Box
                    component="span"
                    onClick={() => {
                      window.open(
                        orderDetailData?.items[0]?.trackingUrl,
                        "_blank"
                      );
                    }}
                    sx={{
                      display: "inline",
                      color: "#CE0E2D",
                      fontSize: "13px",
                      fontWeight: "500",
                      textDecoration: "underline",
                      marginLeft: "4px",
                      cursor: "pointer",
                      marginRight: "10px",
                    }}
                  >
                    {orderDetailData?.items[0]?.trackingNumber}
                  </Box>
                  <span className="trackNumberDate">
                    {orderDetailData?.shippingDate.replace(/\./g, "/")}
                  </span>
                </div>
              </div>
            )}
            {!reviewOrder && !yourCart && (
              <div className="selectlistTopDiv">
                {/* <div className='leftSideShop'>
                            <Select
                                className='header__select iLmorborder'
                                // defaultValue={''}
                                placeholder='Bulk Actions'
                                onChange={[]}
                                // menuIsOpen={true}
                                options={[]}
                            />
                            <button className='ilmorButon'>Apply</button>
                        </div> */}
                {!reviewOrder && !yourCart && (
                  <div className="rightSide mobsortright">
                    <span className="sortText">SORT BY</span>
                    <Select
                      styles={customStyles}
                      className="headerSortByMobile"
                      isSearchable={false}
                      // className="header__select iLmorborder"
                      // defaultValue={''}
                      placeholder="Date Added - New to Old"
                      onChange={handleSort}
                      // onChange={[]}
                      // menuIsOpen={true}
                      options={sortArrayOptions}
                    />
                  </div>
                )}
              </div>
            )}
            {location.pathname === "/checkout" ||
            (location.pathname === "/account" &&
              orderDetailData.orderStatus === "Processing") ? (
              <Accordion
                className={classnames(" lineOrder", {
                  partacoror: location.pathname === "/account",
                  // lineOrder1: location.pathname === "/checkout" && reviewOrder,
                })}
                expanded={expanded}
                onChange={handleAccordionChange}
              >
                <AccordionSummary
                  expandIcon={
                    expanded ? <ExpandMoreIcon /> : <ExpandMoreIcon /> // Use custom icons based on the expanded state
                  }
                  px
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="accordianText 45645yrgf marginFormobile"
                >
                  <Typography
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: "700",
                      letterSpacing: "2.5",
                      textTransform: "uppercase",
                      // marginLeft:'18px'
                    }}
                    className={classnames(" ", {
                      normamarginshop: location.pathname === "/checkout",
                      sjkhbdskhj: location.pathname === "/account",
                    })}
                  >
                    PARTS ORDERED
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <table className={`shoppingListTableDesktop`}>
                    <thead className={`shoppingListTableHeadDesktop`}>
                      <tr className={`shoppingListTableHeadRowDesktop`}>
                        {/* <th width="1%" >
                                    <input type="checkbox" id="ckbCheckAll"></input>
                                </th> */}
                        <th
                          width="100%"
                          className={`shoppingListTableHeadingDesktop`}
                        ></th>
                      </tr>
                    </thead>
                    <tbody className={`shoppingListTableBodyDesktop`}>
                      {tableData && tableData.length > 0 ? (
                        tableData.map((row, index) => (
                          <tr
                            className={`shoppingListTableRowDesktop${
                              index % 2 === 0 ? " EvenRow" : ""
                            } ${orderDetail ? " ordeDetailWhiteRow" : ""}`}
                            key={index}
                          >
                            {/* <td className={`shoppingListprice`}> */}
                            {/* </td> */}
                            {!yourCart && (
                              <td
                                // className={`shoppingListTableInputDivDesktop bbtop`}
                                className={classnames(
                                  "shoppingListTableInputDivDesktop",
                                  {
                                    bbtop:
                                      currentDevice === "Mobile" &&
                                      location.pathname === "/shopping-list",
                                  }
                                )}
                              >
                                <div className="shopRow1">
                                  <div className="shopListRow1LeftSide">
                                    {!reviewOrder && (
                                      // <input
                                      //   className={`myProfile_sendNotiFcncheckBox`}
                                      //   onClick={(event) =>
                                      //     handleCheck(event.target.checked, row)
                                      //   }
                                      //   checked={row.isChecked}
                                      //   type="checkbox"
                                      // />
                                      <Checkbox
                                        {...label}
                                        onClick={(event) =>
                                          handleCheck(event.target.checked, row)
                                        }
                                        checked={row.isChecked}
                                        sx={{
                                          // color: "white",
                                          // "&.Mui-checked": {
                                          //   color: "white",
                                          // },
                                          // "& .MuiSvgIcon-root": {
                                          //   fill: "black",
                                          // },
                                          // "&.Mui-checked .MuiSvgIcon-root": {
                                          //   fill: "black",
                                          // },
                                          "&.Mui-checked .MuiIconButton-label":
                                            {
                                              backgroundColor: "black",
                                            },
                                        }}
                                        // onChange={handleRememberMeChange}
                                        // color="default"
                                      />
                                    )}
                                    <Tooltip
                                      placement="top"
                                      title={
                                        row?.partStatus === "Discontinued"
                                          ? "The part is discontinued "
                                          : ""
                                      }
                                    >
                                      <div
                                        onClick={() => {
                                          handleProductClick(
                                            row.partNumber,
                                            row?.partStatus === "Discontinued"
                                          );
                                        }}
                                        className={classnames(
                                          "shopListRow1LeftSide",
                                          {
                                            disableButtonCart:
                                              row?.partStatus ===
                                              "Discontinued",
                                          }
                                        )}
                                      >
                                        {" "}
                                        <img
                                          className="shopListRow1Image"
                                          alt={
                                            row?.imagesAltText ||
                                            row?.partNumber ||
                                            "Ilmor Part Image"
                                          }
                                          src={row.images ? row.images : place}
                                        ></img>
                                      </div>
                                    </Tooltip>
                                  </div>
                                  <div className="shopListRow1RightSide">
                                    <div className="shopListTabDetailsSecMain">
                                      <div className="shopListTabDetailsSec1 ">
                                        <div className="shopListTabDetailsSec1">
                                          {row?.productLabel && (
                                            <span
                                              className={`cardList__newTextDesktop`}
                                            >
                                              {row?.productLabel}
                                            </span>
                                          )}
                                          <div
                                            onClick={() => {
                                              handleProductClick(
                                                row.partNumber,
                                                row?.partStatus ===
                                                  "Discontinued"
                                              );
                                            }}
                                            className={classnames("curp", {
                                              disableButtonCart:
                                                row?.partStatus ===
                                                "Discontinued",
                                            })}
                                          >
                                            <span
                                              className={`shoppingListSmallTitile`}
                                            >
                                              {row.partNumber}
                                            </span>
                                          </div>
                                        </div>
                                        <span    onClick={() => {
                                    handleProductClick(
                                      row.partNumber,
                                      row?.partStatus === "Discontinued"
                                    );
                                  }} className={`curp shoppingListTitle`}>
                                          {row.partName}
                                        </span>
                                      </div>
                                      <span
                                        className={classnames(
                                          "shoppingListTablestatus",
                                          {
                                            notAvailableText:
                                              row.partStatus === "Backordered",
                                          }
                                        )}
                                      >
                                        {row.partStatus}
                                      </span>
                                      <div
                                        className="backOrderedLabel"
                                        style={{ color: "red" }}
                                      >
                                        {location.pathname !==
                                          "/quick-order-form" && (
                                          <span
                                            className={`shoppingListTitle notAvailableText`}
                                          >
                                            {row?.isBackordered &&
                                            row?.inventory <=
                                              row?.inStockQuantity
                                              ? "Backordered"
                                              : ""}
                                          </span>
                                        )}
                                      </div>
                                      {!reviewOrder && !yourCart && (
                                        <div className="shopListTabDetailsSec2">
                                          <span className="shopTableLabel">
                                            Qty
                                          </span>
                                          <NumericInput
                                            disabled={
                                              myProfile?.profileInfo?.role ===
                                                "ServiceTechnician" &&
                                              !row?.isPermission
                                            }
                                            className={`shoppingListTableQuantityDesktop lighBorder`}
                                            style={buttonStyles}
                                            max={999}
                                            id={row.partId}
                                            // min={1}
                                            value={
                                              row.partQty <= 0
                                                ? ""
                                                : row.partQty
                                            }
                                            strict
                                            mobile={false}
                                            onChange={(event) =>
                                              handleInputChangeSelect(
                                                event,
                                                row
                                              )
                                            }
                                            onBlur={(event) => {
                                   
                                              if (event.target.value =="") {
                                                handleInputChangeSelect(1, row, true);
                                              } else {
                                                handleInputChangeSelect(event.target.value, row, true);
                                              }
                                            }}
                                            // value={row.partQty}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="">
                                  <div
                                    // className="shopListDetailTablet"
                                    className={classnames(
                                      "shopListDetailTablet",
                                      {
                                        topbordrno:
                                          location.pathname === "/checkout" &&
                                          currentDevice !== "Mobile",
                                      }
                                    )}
                                  >
                                    {reviewOrder && (
                                      <div className="shopListTabDiv">
                                        <span className="shopTableLabel">
                                          Qty
                                        </span>
                                        <span
                                          className={`shoppingListTableprice`}
                                        >
                                          {row.partQty}
                                        </span>
                                      </div>
                                    )}
                                    {(myProfile?.profileInfo?.permissions?.includes(
                                      "add_to_own_cart"
                                    ) ||
                                      dealerId) && (
                                      <div className="shopListTabDiv">
                                        <span className="shopTableLabel">
                                          Price
                                        </span>
                                        {row?.partDiscountCost && (
                                          <span
                                            className={`shoppingListTableprice d454f`}
                                          >
                                            $
                                            {addCommaDecimal(
                                              row?.partDiscountCost
                                            )}
                                          </span>
                                        )}
                                        {row?.partOrigionalCost &&
                                          parseFloat(row?.partOrigionalCost) !==
                                            parseFloat(row?.partDiscountCost) &&
                                          addCommaDecimal(
                                            row?.partOrigionalCost
                                          ) && (
                                            <span
                                              className={`shoppingListTablepriceDiscounted texcen`}
                                            >
                                              $
                                              {addCommaDecimal(
                                                row?.partOrigionalCost
                                              )}
                                            </span>
                                          )}
                                      </div>
                                    )}
                                    {reviewOrder &&
                                      (myProfile?.profileInfo?.permissions?.includes(
                                        "add_to_own_cart"
                                      ) ||
                                        dealerId) && (
                                        <div className="shopListTabDiv">
                                          <span className="shopTableLabel">
                                            Total
                                          </span>
                                          <span
                                            className={`shoppingListTableprice dsfdsf311`}
                                          >
                                            $
                                            {addCommaDecimal(
                                              row.partQty *
                                                row?.partDiscountCost
                                            )}
                                          </span>
                                        </div>
                                      )}
                                    {/* {partiallyShippedRow && (
                                <div className={`shopListTabDiv`}>
                                  <span className="shopTableLabel">
                                    Tracking Number
                                  </span>
                                  <div className="shopPrice">
                                    <span
                                      className={`shoppingListTabledateTotal`}
                                    >
                                      {(row?.trackingNumber).toString()}
                                    </span>
                                  </div>
                                </div>
                              )} */}
                                    {!reviewOrder && (
                                      <div className="shopListTabDiv">
                                        <span className="shopTableLabel">
                                          Added By
                                        </span>
                                        <span
                                          className={`shoppingListTableaddedBy`}
                                        >
                                          {row.addedBy}
                                        </span>
                                      </div>
                                    )}
                                    {!reviewOrder && !yourCart && (
                                      <div className="shopListTabDiv">
                                        <span className="shopTableLabel">
                                          Date Added
                                        </span>
                                        <span
                                          className={`shoppingListTabledate`}
                                        >
                                          {/* {row.addedDate} */}
                                          {row?.addedDate}
                                        </span>
                                      </div>
                                    )}
                                    {/* {!reviewOrder && !yourCart && (
                            <div className="shopListTabDiv">
                              <span className="shopTableLabel">Status</span>
                              <span className={`shoppingListTablestatus`}>
                                {row.partStatus}
                              </span>
                            </div>
                          )} */}
                                    {reviewOrder && !addtoCart && (
                                      <td
                                        className={`shoppingListdateTotaldiv`}
                                      >
                                        <span
                                          className={`shoppingListTableprice`}
                                        >
                                          Qty : {row.partQty}
                                        </span>
                                      </td>
                                    )}
                                    {reviewOrder &&
                                      !addtoCart &&
                                      (myProfile?.profileInfo?.permissions?.includes(
                                        "add_to_own_cart"
                                      ) ||
                                        dealerId) && (
                                        <td
                                          className={`shoppingListdateTotaldiv`}
                                        >
                                          <span
                                            className={`shoppingListTabledateTotal`}
                                          >
                                            ${row.part_price}
                                          </span>
                                        </td>
                                      )}
                                  </div>
                                  {(!reviewOrder || orderDetail) && (
                                    <div className="otherNoteOrder">
                                      {row?.otherNotes &&
                                        Object.values(row?.otherNotes).length >
                                          0 &&
                                        Object.values(row?.otherNotes)[0]
                                          .length > 0 && (
                                          <>
                                            {location.pathname !==
                                              "/shopping-list" && (
                                              <div className="requiredINfoClass">
                                                <div className="requiredINfo">
                                                  Required information
                                                </div>
                                                <span className="imp">*</span>
                                              </div>
                                            )}
                                            <Grid
                                              container
                                              style={{
                                                // flexDirection: "column",
                                                // display: "flex",
                                                // marginTop: "20px",
                                                textAlign: "left",
                                              }}
                                              spacing={1}
                                              lg={12}
                                              xs={12}
                                              md={12}
                                            >
                                              {Object.entries(
                                                row?.otherNotes
                                              ).map(
                                                ([key, value]) =>
                                                  value &&
                                                  value.length > 0 &&
                                                  value?.map(
                                                    (innerArray, index) => (
                                                      <>
                                                        <Grid item md={6}>
                                                          {location.pathname !==
                                                            "/shopping-list" && (
                                                            <div className="labelPartDetail">
                                                              {innerArray.title}
                                                              <span className="red">
                                                                *
                                                              </span>
                                                            </div>
                                                          )}
                                                          <input
                                                            className={`InputFieldShippingAddressPayment shoppingListInput`}
                                                            // disabled={false}
                                                            disabled={
                                                              myProfile?.profileInfo?.role ===
                                                                "ServiceTechnician" &&
                                                              !row?.isPermission
                                                            }
                                                            placeholder={
                                                              innerArray.title
                                                            }
                                                            onChange={(
                                                              event
                                                            ) => {
                                                              handleAddInfo(
                                                                row,
                                                                innerArray,
                                                                event.target
                                                                  .value,
                                                                key,
                                                                index
                                                              );
                                                            }}
                                                            value={
                                                              innerArray.value
                                                            }
                                                            type="text"
                                                            name="firstname"
                                                          ></input>
                                                        </Grid>
                                                      </>
                                                    )
                                                  )
                                              )}
                                            </Grid>
                                          </>
                                        )}
                                    </div>
                                  )}
                                </div>
                                {!reviewOrder && (
                                  <>
                                    <input
                                      disabled={
                                        myProfile?.profileInfo?.role ===
                                          "ServiceTechnician" &&
                                        !row?.isPermission
                                      }
                                      type="text"
                                      placeholder="Add internal notes"
                                      className={`shoppingListInput`}
                                      value={row.notes}
                                      onChange={(event) =>
                                        handleInputChange(
                                          event,
                                          row.partId + row.addedBy
                                        )
                                      }
                                    ></input>
                                  </>
                                )}

                                {/* { !addToCart && <input
                          type="text"
                          className={`shoppingListInput`}
                          value={row.input}
                          onChange={(event) =>
                            handleInputChange(event, index, "input")
                          }
                        ></input>} */}
                                {!reviewOrder && (
                                  <div className="shopListLow">
                                    {row?.isloadingRemove ? (
                                      <CircularProgress
                                        style={{ color: "#CE0E2D" }}
                                        size={20}
                                      />
                                    ) : (
                                      <>
                                        {!(
                                          myProfile?.profileInfo?.role ===
                                            "ServiceTechnician" &&
                                          !row?.isPermission
                                        ) && (
                                          <span
                                            onClick={(event) =>
                                              handleRemove(row)
                                            }
                                            className={`shopLiRemove`}
                                          >
                                            Remove
                                          </span>
                                        )}
                                      </>
                                    )}
                                    {myProfile?.profileInfo?.permissions?.includes(
                                      "add_to_own_cart"
                                    ) && (
                                      <div className="center sepBarDiv">
                                        <span className="sepBar">|</span>
                                      </div>
                                    )}
                                    {myProfile?.profileInfo?.permissions?.includes(
                                      "add_to_own_cart"
                                    ) && (
                                      <span
                                        onClick={(event) =>
                                          handleAddToCartSingleItem(row)
                                        }
                                        className={`shopLiMovToCart moveCart`}
                                      >
                                        Move to Cart
                                      </span>
                                    )}
                                  </div>
                                )}
                              </td>
                            )}
                          </tr>
                        ))
                      ) : (
                        <div className="cartEmptyMessage">
                          your shopping list is empty{" "}
                        </div>
                      )}
                    </tbody>
                  </table>
                </AccordionDetails>
              </Accordion>
            ) : (
              <table className={`shoppingListTableDesktop`}>
                <thead className={`shoppingListTableHeadDesktop`}>
                  <tr className={`shoppingListTableHeadRowDesktop`}>
                    {/* <th width="1%" >
                                    <input type="checkbox" id="ckbCheckAll"></input>
                                </th> */}
                    <th
                      width="100%"
                      className={`shoppingListTableHeadingDesktop`}
                    ></th>
                  </tr>
                </thead>
                <tbody
                  className={classnames("shoppingListTableBodyDesktop", {
                    bbtop:
                      currentDevice === "Mobile" &&
                      location.pathname === "/shopping-list",
                  })}
                >
                  {tableData && tableData.length > 0 ? (
                    tableData.map((row, index) => (
                      <tr
                        className={`shoppingListTableRowDesktop${
                          index % 2 === 0 ? " EvenRow" : ""
                        } ${orderDetail ? " ordeDetailWhiteRow" : ""}`}
                        key={index}
                      >
                        {/* <td className={`shoppingListprice`}> */}
                        {/* </td> */}
                        {!yourCart && (
                          <td
                            // className={`shoppingListTableInputDivDesktop bbtop`}
                            className={classnames(
                              "shoppingListTableInputDivDesktop"
                              // {
                              //   bbtop:
                              //     currentDevice === "Mobile" &&
                              //     location.pathname === "/shopping-list",
                              // }
                            )}
                          >
                            <div className="shopRow1">
                              <div className="shopListRow1LeftSide ">
                                {!reviewOrder && (
                                  // <input
                                  //   className={`myProfile_sendNotiFcncheckBox`}
                                  //   onClick={(event) =>
                                  //     handleCheck(event.target.checked, row)
                                  //   }
                                  //   checked={row.isChecked}
                                  //   type="checkbox"
                                  // />
                                  <Checkbox
                                    {...label}
                                    className="ffgrtgtrj"
                                    onClick={(event) =>
                                      handleCheck(event.target.checked, row)
                                    }
                                    checked={row.isChecked}
                                    sx={{
                                      // color: "white",
                                      // "&.Mui-checked": {
                                      //   color: "white",
                                      // },
                                      // "& .MuiSvgIcon-root": {
                                      //   fill: "black",
                                      // },
                                      // "&.Mui-checked .MuiSvgIcon-root": {
                                      //   fill: "black",
                                      // },
                                      "&.Mui-checked .MuiIconButton-label": {
                                        backgroundColor: "black",
                                      },
                                    }}
                                    // onChange={handleRememberMeChange}
                                    // color="default"
                                  />
                                )}
                                <Tooltip
                                  placement="top"
                                  title={
                                    row?.partStatus === "Discontinued"
                                      ? "The part is discontinued "
                                      : ""
                                  }
                                >
                                  <div
                                    onClick={() => {
                                      handleProductClick(
                                        row.partNumber,
                                        row?.partStatus === "Discontinued"
                                      );
                                    }}
                                    className={classnames(
                                      "shopListRow1LeftSide",
                                      {
                                        disableButtonCart:
                                          row?.partStatus === "Discontinued",
                                      }
                                    )}
                                  >
                                    {" "}
                                    <img
                                      className="shopListRow1Image"
                                      alt={
                                        row?.imagesAltText ||
                                        row?.partNumber ||
                                        "Ilmor Part Image"
                                      }
                                      src={row.images ? row.images : place}
                                    ></img>
                                  </div>
                                </Tooltip>
                              </div>
                              <div className="shopListRow1RightSide">
                                <div className="shopListTabDetailsSecMain">
                                  <div className="shopListTabDetailsSec1">
                                    <div
                                      className={classnames(
                                        "shopListTabDetailsSec1 ",
                                        {
                                          flexFoPartname:
                                            location.pathname === "/account" ||
                                            location.pathname ===
                                              "/shopping-list",
                                        }
                                      )}
                                    >
                                      {row?.productLabel && (
                                        <span
                                          className={`cardList__newTextDesktop`}
                                        >
                                          {row?.productLabel}
                                        </span>
                                      )}
                                      <div
                                        onClick={() => {
                                          handleProductClick(
                                            row.partNumber,
                                            row?.partStatus === "Discontinued"
                                          );
                                        }}
                                        className={classnames("curp", {
                                          disableButtonCart:
                                            row?.partStatus === "Discontinued",
                                        })}
                                      >
                                        <span
                                          className={`shoppingListSmallTitile`}
                                        >
                                          {row.partNumber}
                                        </span>
                                      </div>
                                    </div>
                                    <span    onClick={() => {
                                    handleProductClick(
                                      row.partNumber,
                                      row?.partStatus === "Discontinued"
                                    );
                                  }}  className={`curp shoppingListTitle`}>
                                      {row.partName}
                                    </span>
                                  </div>
                                  <span
                                    className={classnames(
                                      "shoppingListTablestatus",
                                      {
                                        notAvailableText:
                                          row.partStatus === "Backordered",
                                      }
                                    )}
                                  >
                                    {row.partStatus}
                                  </span>
                                  <div
                                    className="backOrderedLabel"
                                    style={{ color: "red" }}
                                  >
                                    {location.pathname !==
                                      "/quick-order-form" && (
                                      <span
                                        className={`shoppingListTitle notAvailableText`}
                                      >
                                        {row?.isBackordered &&
                                        row?.inventory <= row?.inStockQuantity
                                          ? "Backordered"
                                          : ""}
                                      </span>
                                    )}
                                  </div>
                                  {!reviewOrder && !yourCart && (
                                    <div className="shopListTabDetailsSec2">
                                      <span className="shopTableLabel">
                                        Qty
                                      </span>
                                      <NumericInput
                                        disabled={
                                          myProfile?.profileInfo?.role ===
                                            "ServiceTechnician" &&
                                          !row?.isPermission
                                        }
                                        className={`shoppingListTableQuantityDesktop lighBorder`}
                                        style={buttonStyles}
                                        max={999}
                                        // min={1}
                                        strict
                                        id={row.partId}
                                        mobile={false}
                                        value={
                                          row.partQty <= 0 ? "" : row.partQty
                                        }
                                        onChange={(event) =>
                                          handleInputChangeSelect(event, row)
                                        }
                                        onBlur={(event) => {
                                          if (event.target.value =="") {
                                            handleInputChangeSelect(1, row, true);
                                          } else {
                                            handleInputChangeSelect(event.target.value, row, true);
                                          }
                                        }}
                                        // value={row.partQty}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="">
                              <div
                                // className="shopListDetailTablet"
                                className={classnames("shopListDetailTablet", {
                                  topbordrno: location.pathname === "/checkout",
                                })}
                              >
                                {reviewOrder && (
                                  <div className="shopListTabDiv">
                                    <span className="shopTableLabel">Qty</span>
                                    <span className={`shoppingListTableprice`}>
                                      {row.partQty}
                                    </span>
                                  </div>
                                )}
                                {(myProfile?.profileInfo?.permissions?.includes(
                                  "add_to_own_cart"
                                ) ||
                                  dealerId) && (
                                  <div className="shopListTabDiv">
                                    <span className="shopTableLabel">
                                      Price
                                    </span>
                                    {row?.partDiscountCost && (
                                      <span
                                        className={`shoppingListTableprice dsfsd434343`}
                                      >
                                        $
                                        {addCommaDecimalreturnZ(row?.partDiscountCost)}
                                      </span>
                                    )}
                                    {row?.partOrigionalCost &&
                                      parseFloat(row?.partOrigionalCost) !==
                                        parseFloat(row?.partDiscountCost) &&
                                      addCommaDecimal(
                                        row?.partOrigionalCost
                                      ) && (
                                        <span
                                          className={`shoppingListTablepriceDiscounted texcen`}
                                        >
                                          $
                                          {addCommaDecimal(
                                            row?.partOrigionalCost
                                          )}
                                        </span>
                                      )}
                                  </div>
                                )}
                                {reviewOrder &&
                                  (myProfile?.profileInfo?.permissions?.includes(
                                    "add_to_own_cart"
                                  ) ||
                                    dealerId) && (
                                    <div className="shopListTabDiv">
                                      <span className="shopTableLabel">
                                        Total
                                      </span>
                                      <span
                                        className={`shoppingListTableprice`}
                                      >
                                        $
                                        {addCommaDecimal(
                                          row.partQty * row?.partDiscountCost
                                        )}
                                      </span>
                                    </div>
                                  )}
                                {/* {partiallyShippedRow && (
                                <div className={`shopListTabDiv`}>
                                  <span className="shopTableLabel">
                                    Tracking Number
                                  </span>
                                  <div className="shopPrice">
                                    <span
                                      className={`shoppingListTabledateTotal`}
                                    >
                                      {(row?.trackingNumber).toString()}
                                    </span>
                                  </div>
                                </div>
                              )} */}
                                {!reviewOrder && (
                                  <div className="shopListTabDiv">
                                    <span className="shopTableLabel">
                                      Added By
                                    </span>
                                    <span
                                      className={`shoppingListTableaddedBy`}
                                    >
                                      {row.addedBy}
                                    </span>
                                  </div>
                                )}
                                {!reviewOrder && !yourCart && (
                                  <div className="shopListTabDiv">
                                    <span className="shopTableLabel">
                                      Date Added
                                    </span>
                                    <span className={`shoppingListTabledate`}>
                                      {/* {row.addedDate} */}
                                      {row?.addedDate}
                                    </span>
                                  </div>
                                )}
                                {/* {!reviewOrder && !yourCart && (
                            <div className="shopListTabDiv">
                              <span className="shopTableLabel">Status</span>
                              <span className={`shoppingListTablestatus`}>
                                {row.partStatus}
                              </span>
                            </div>
                          )} */}
                                {reviewOrder && !addtoCart && (
                                  <td className={`shoppingListdateTotaldiv`}>
                                    <span className={`shoppingListTableprice`}>
                                      Qty : {row.partQty}
                                    </span>
                                  </td>
                                )}
                                {reviewOrder &&
                                  !addtoCart &&
                                  (myProfile?.profileInfo?.permissions?.includes(
                                    "add_to_own_cart"
                                  ) ||
                                    dealerId) && (
                                    <td className={`shoppingListdateTotaldiv`}>
                                      <span
                                        className={`shoppingListTabledateTotal`}
                                      >
                                        ${row.part_price}
                                      </span>
                                    </td>
                                  )}
                              </div>
                              {!reviewOrder && (
                                <div className="drrktyh">
                                  <input
                                    disabled={
                                      myProfile?.profileInfo?.role ===
                                        "ServiceTechnician" &&
                                      !row?.isPermission
                                    }
                                    type="text"
                                    placeholder="Add internal notes"
                                    className={`shoppingListInput`}
                                    value={row.notes}
                                    onChange={(event) =>
                                      handleInputChange(
                                        event,
                                        row.partId + row.addedBy
                                      )
                                    }
                                  ></input>
                                </div>
                              )}
                              {(!reviewOrder || orderDetail) && (
                                <div className="otherNoteOrder">
                                  {row?.otherNotes &&
                                    Object.values(row?.otherNotes).length > 0 &&
                                    Object.values(row?.otherNotes)[0].length >
                                      0 && (
                                      <>
                                        {location.pathname !==
                                          "/shopping-list" && (
                                          <div className="requiredINfoClass">
                                            <div className="requiredINfo">
                                              Required information
                                            </div>
                                            <span className="imp">*</span>
                                          </div>
                                        )}
                                        <Grid
                                          container
                                          style={{
                                            // flexDirection: "column",
                                            // display: "flex",
                                            // marginTop: "20px",
                                            textAlign: "left",
                                          }}
                                          spacing={1}
                                          lg={12}
                                          xs={12}
                                          md={12}
                                        >
                                          {Object.entries(row?.otherNotes).map(
                                            ([key, value]) =>
                                              value &&
                                              value.length > 0 &&
                                              value?.map(
                                                (innerArray, index) => (
                                                  <>
                                                    <Grid
                                                      className="width100"
                                                      item
                                                      md={12}
                                                    >
                                                      {location.pathname !==
                                                        "/shopping-list" && (
                                                        <div className="labelPartDetail">
                                                          {innerArray.title}
                                                          <span className="red">
                                                            *
                                                          </span>
                                                        </div>
                                                      )}
                                                      <input
                                                        className={`InputFieldShippingAddressPayment shoppingListInput`}
                                                        // disabled={false}
                                                        disabled={
                                                          myProfile?.profileInfo?.role ===
                                                            "ServiceTechnician" &&
                                                          !row?.isPermission
                                                        }
                                                        placeholder={
                                                          innerArray.title
                                                        }
                                                        onChange={(event) => {
                                                          handleAddInfo(
                                                            row,
                                                            innerArray,
                                                            event.target.value,
                                                            key,
                                                            index
                                                          );
                                                        }}
                                                        value={innerArray.value}
                                                        type="text"
                                                        name="firstname"
                                                      ></input>
                                                    </Grid>
                                                  </>
                                                )
                                              )
                                          )}
                                        </Grid>
                                      </>
                                    )}
                                </div>
                              )}
                            </div>

                            {/* { !addToCart && <input
                          type="text"
                          className={`shoppingListInput`}
                          value={row.input}
                          onChange={(event) =>
                            handleInputChange(event, index, "input")
                          }
                        ></input>} */}
                            {!reviewOrder && (
                              <div className="shopListLow">
                                {row?.isloadingRemove ? (
                                  <CircularProgress
                                    style={{ color: "#CE0E2D" }}
                                    size={20}
                                  />
                                ) : (
                                  <>
                                    {!(
                                      myProfile?.profileInfo?.role ===
                                        "ServiceTechnician" &&
                                      !row?.isPermission
                                    ) && (
                                      <span
                                        onClick={(event) => handleRemove(row)}
                                        className={`shopLiRemove`}
                                      >
                                        Remove
                                      </span>
                                    )}
                                  </>
                                )}
                                {myProfile?.profileInfo?.permissions?.includes(
                                  "add_to_own_cart"
                                ) && (
                                  <div className="center sepBarDiv">
                                    <span className="sepBar">|</span>
                                  </div>
                                )}
                                {myProfile?.profileInfo?.permissions?.includes(
                                  "add_to_own_cart"
                                ) && (
                                  <span
                                    onClick={(event) =>
                                      handleAddToCartSingleItem(row)
                                    }
                                    className={`shopLiMovToCart moveCart`}
                                  >
                                    Move to Cart
                                  </span>
                                )}
                              </div>
                            )}
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <div className="cartEmptyMessage">
                      your shopping list is empty{" "}
                    </div>
                  )}
                </tbody>
              </table>
            )}
            {!reviewOrder && (
              <div className={`shoppingListButtonSectionDesktop`}>
                <div className={`shoppingListBottomButtonsDesktop jdfhgv`}>
                  {!dealerId ? (
                    <div className="leftSideShop">
                      <Select
                        styles={customStyles}
                        className={classnames("headerBottomMobile", {
                          actionMobileBulk: true,
                        })}
                        isSearchable={false}
                        // defaultValue={''}
                        placeholder="Bulk Actions"
                        value={bulkAction}
                        onChange={(e) => {
                          setbulkAction(e);
                        }}
                        // menuIsOpen={true}
                        options={
                          myProfile?.profileInfo?.permissions?.includes(
                            "add_to_own_cart"
                          )
                            ? bulkActions
                            : bulkActionsTech
                        }
                      />
                      <button
                        onClick={handleApply}
                        disabled={
                          !bulkAction ||
                          !selectedItems.length > 0 ||
                          (bulkAction.value === "move" &&
                            myProfile?.profileInfo?.role ===
                              "ServiceTechnician")
                        }
                        className="ilmorButon"
                        style={{ marginLeft: "10px" }}
                      >
                        Apply
                      </button>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {
                    <div
                      className={classnames(
                        "shoppingListTableRightButtonsDesktop",
                        {
                          bordertopForReview:
                            location.pathname === "/shopping-list",
                        }
                      )}
                    >
                      <button
                        onClick={handleSave}
                        disabled={dealerId || !changeDetected}
                        id="shopListSave"
                        className={`ilmorButon`}
                      >
                        Save Updates
                      </button>
                    </div>
                  }
                </div>
              </div>
            )}
          </div>
        )}
    </>
  );
};
