import { Box, Button, styled } from "@mui/material";
export const ErrorMessageStyled = styled(Box)(({ theme, status }) => ({
    color: "#CE0E2D",
   
    fontFamily: "Montserrat",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "500",
    marginTop: "5px",

}));

export const LabelStyled = styled(Box)(({ theme, status }) => ({
    color: "#CE0E2D",
   
    fontFamily: "Montserrat",
    marginBottom: "0%",
    letterSpacing: "2px",
    fontSize: "80%",
    display: "flex",

}));

export const ShippingPolicyHeading = styled(Box)(({ theme, status }) => ({
  color: "#000",

  fontFamily: "Montserrat",

  fontWeight: "700",
  fontSize: "24px",
  textTransform: "uppercase",
}));
export const ShippingPolicyParagraph = styled(Box)(({ theme, status }) => ({
  color: "#000",

  fontFamily: "Montserrat",

  fontWeight: "500",
  fontSize: "16px",
}));

export const ShippingPolicyGreyHeading = styled(Box)(({ theme, status }) => ({
    color: "#000",
  
    fontFamily: "Montserrat",
  
    fontWeight: "700",
    fontSize: "40px",
    textTransform: "uppercase",
  }));

  export const StyledFourOFour = styled(Box)(({ theme ,text}) => ({
    textAlign: "center",

    color: "#FFF",

    fontFamily: "Montserrat",

    fontWeight: "700",
    fontSize:text=="fourOfour" ?"112px" :text=="pageNot" ?"24px":"16px", 
   
  }));
  
  



