import React, { useState } from "react";
import { useStore } from "../store/home";
import NumericInput from "react-numeric-input";
import coolant from "../Images/coolant.png";
import place from "../Images/placeHolder.png";
import classnames from "classnames";
import { Checkbox, Grid } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { useEffect } from "react";
import {
  authorizeNet,
  modifyOrder,
  removeItemWish,
  returnOrderAPI,
} from "../api/posts";
import { sortArrayOptions, bulkActions, addCommaDecimal } from "../commonFunction";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { addWishList } from "../api/posts";
import { getCurrentDate } from "../commonFunction";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import Loader from "../commenComp/Loader";
import CircularProgress from "@mui/material/CircularProgress";
import IlmorModal from "../commenComp/IlmorModal";
import { showErrorToast, showSuccessToast } from "../utils/Toaster";

export const ShoppingListComponentOrderDetail = ({
  reviewOrder,
  addtoCart,
  orderDetail,
  cencel,
  modify,
  yourCart,
  dataArray,
  returnOrder,
  defaultCardData,
  orderDetailData,
  handleRefreshCallBack,
}) => {
  const {
    setSideMenuAccount,
    token,
    myProfile,
    currentDevice,
    dealerId,
    returnOptionZustand,
    setReturnOptionZustand,
  } = useStore();
  const { reason, setReason } = useNonPersistedStore();
  const [tableData, setTableData] = useState([]);
  const [tableDataOrg, setTableDataOrg] = useState([]);
  const [returnCheckBoxCount, setReturnCheckBoxCount] = useState();
  const buttonStyles = {
    fontFamily: "FontAwesome",
    fontSize: "12px",
  };
  const [change, setChange] = useState(true);
  const [change1, setChange1] = useState(true);
  const navigate = useNavigate();

  const handleAddInfo = (row, item, val, key, index) => {
    const updatedTableData = [...tableData];
    const partNote = updatedTableData.find(
      (partNote) =>
        Object.values(partNote.otherNotes).length > 0 &&
        partNote.partId === row.partId
    );

    if (partNote) {
      const obj = partNote.otherNotes[key];
      const obval = {};
      obval.title = item.title;
      obval.value = val;
      obj[index] = obval;
      // const note = partNote.otherNotes.find(
      //   (note) => note.title === item.title
      // );
      // if (note) {
      //   note.value = val;
      // }
    }
    setChange(false);
    setTableData(updatedTableData);
  };

  useEffect(() => {
    setTableDataOrg(dataArray);
  }, [dataArray]);

  useEffect(() => {
    if (tableData.length > 0 && tableDataOrg.length > 0) {
      const arr = tableData.map((item) => item.partQty);
      const arr1 = tableDataOrg.map((item) => item.partQty);
      let flag = true;
      arr.forEach((item, index) => {
        if (parseInt(item) !== arr1[index]) {
          flag = false;
        }
      });
      setChange1(flag);
      if (arr.length !== arr1.length) {
        setChange1(false);
      }
    }
  }, [tableData, tableDataOrg]);

  useEffect(() => {
    let arr = [];
    if (dataArray && dataArray?.length > 0) {
      arr = dataArray;
      arr.forEach((element) => {
        element.isloading = false;
        element.isReturn = false;
        element.returnQuantity=1
        element.total = element.partQty * element.partDiscountCost;
      });
    }
    setTableData(arr);
  }, [dataArray]);
  
  const [returnOk, setreturnOk] = useState(false);
  useEffect(() => {
    const isReturn = tableData?.some((item) => item?.isReturn);
    setreturnOk(!isReturn);
  }, [tableData]);

  const handelReturnSelect = (row,e) => {

    let updatedArray = tableData && tableData?.map((item) => {
      if (item?.partId === row?.partId) {
        return { ...item, isReturn: !item.isReturn, };
      }
      return item;
    });
     
    
    const updatedArrayReturnQty = updatedArray && updatedArray?.map((item) => {
      if (item?.partId === row?.partId && !e.target.checked ) {
      
         return { ...item, returnQuantity:  1};
      }
       return item;
    });
    
    
    setTableData(updatedArrayReturnQty);
  const countIsReturn =  updatedArrayReturnQty?.filter(obj=>obj["isReturn"]).length
  setReturnCheckBoxCount(countIsReturn)
  };

  const listIsupdated = () =>
    toast.success("Shopping list is updated", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "dark",
    });

  const mutation = useMutation((post) => addWishList(post, token), {
    onSuccess: (result) => {
      listIsupdated();
    },
    onError: (error) => {},
  });

  const handleInputChangeSelect = (event, row) => {
    const updatedTableData = tableData.map((element) => {
      if (element.partId === row.partId) {
        return {
          ...element,
          partQty: event.toString(),
          total: event * element.partDiscountCost,
        };
      }
      return element;
    });
    setChange(false);
    setTableData(updatedTableData);
  };

  const handleRemove = (row) => {
    const arrr = tableData.filter((item) => item.partId !== row.partId);
    setChange(false);
    setTableData(arrr);
    // const arr = [];
    // const obj = {};
    // obj.wishlistId = row.wishlistId;
    // obj.partId = row.partId;
    // arr.push(obj);
    // removeItem.mutate(arr);
  };

  const [cancelDialog, setCancelDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);
  const [returnDialog, setReturnDialog] = useState(false);
  const handleCancel = () => {
    setCancelDialog(!cancelDialog);
  };
  const handleUpdate = () => {
    setUpdateDialog(!updateDialog);
  };
  const handleViewCart = () => {
    navigate({
      pathname: "/cart",
    });
  };

  const returnMutaion = useMutation((post) => returnOrderAPI(post, token), {
    onSuccess: (result) => {
      setReason("");
      if (result.data.success) {
        showSuccessToast("Order return initiated.");
      } else {
        showErrorToast("The Order could not be returned");
      }
      setSideMenuAccount("orderDefault");
      navigate({
        pathname: "/account/orders",
      });
    },
    onError: (error) => {
      setReason("");
      showErrorToast("The Order could not be returned");
      setSideMenuAccount("orderDefault");
      navigate({
        pathname: "/account/orders",
      });
    },
  });

  const handleReturn = () => {
    let arr = [];
    let objss = {};
    if (tableData && tableData.length > 0) {
      tableData.forEach((item) => {
        if (item.isReturn) {
          arr.push(item.partId);
          objss[item.partId] = item?.returnQuantity;
        }
      });
    }
  
    if (arr.length > 0) {
      const obj = {
        orderId: orderDetailData.id,
         partIds: objss,
        // partIds: arr,

        reason: returnOptionZustand?.value,
      };
    
      returnMutaion.mutate(obj);
    }
    setReturnDialog(false);
  };
  const handleReturnClose = () => {
    setReturnOptionZustand([])
    setReturnDialog(!returnDialog);
  };

  const handleOkay = () => {
    setCancelDialog(false);
    setUpdateDialog(false);
  };

  const refundAPI = useMutation((post) => authorizeNet(post), {
    onSuccess: (result) => {
      if (result?.data?.transactionResponse?.responseCode === "1") {
        showSuccessToast(
          // result.data.transactionResponse.messages[0].description
          "Previously placed order is cancelled . Refund is initiated"
        );
        handleViewCart();
      } else {
        showErrorToast(result.data.messages.message[0].text);
      }
    },
    onError: (error) => {},
  });

  const voidTransaction = useMutation((post) => authorizeNet(post), {
    onSuccess: (result) => {
      if (result?.data?.transactionResponse?.responseCode === "1") {
        showSuccessToast(
          // result.data.transactionResponse.messages[0].description'
          "Previously placed order is cancelled . Refund is initiated"
        );
        handleViewCart();
      } else {
        showErrorToast(result.data.messages.message[0].text);
      }
    },
    onError: (error) => {},
  });

  const checkStatus = useMutation((post) => authorizeNet(post), {
    onSuccess: (result) => {
      if (result.data.messages.resultCode === "Ok") {
        if (
          result?.data?.transaction?.transactionStatus !== "settledSuccessfully"
        ) {
          const obj = {
            createTransactionRequest: {
              merchantAuthentication: {
                name: process.env.REACT_APP_MERCHANT_NAME,
                transactionKey: process.env.REACT_APP_MERCHANT_TRANSACTION_KEY,
              },
              refId: orderDetailData?.orderId,
              transactionRequest: {
                transactionType: "voidTransaction",
                refTransId: orderDetailData?.paymentInfo?.transactionId,
              },
            },
          };
          voidTransaction.mutate(obj);
        } else {
          let objLine = {}
          let arrLine = []
          if(orderDetailData?.items && orderDetailData?.items &&  orderDetailData?.items.length > 0){
            orderDetailData?.items.forEach((item)=>{
              let line = {}
              line.itemId = item?.partId
              line.name = item?.partName ? item?.partName.slice(0,20) : ''
              line.description = item?.partDescription ? item?.partDescription?.slice(0,20) : ''
              line.quantity = item?.partQty
              line.unitPrice = item?.partDiscountCost
              arrLine.push(line)
            })
          }
          objLine.lineItem = arrLine
          const chargeProfile = {
            createTransactionRequest: {
              merchantAuthentication: {
                name: process.env.REACT_APP_MERCHANT_NAME,
                transactionKey: process.env.REACT_APP_MERCHANT_TRANSACTION_KEY,
              },
              refId: orderDetailData.orderId,
              transactionRequest: {
                transactionType: "refundTransaction",
                amount: orderDetailData?.paymentInfo?.Amount,
                payment: {
                  creditCard: {
                    cardNumber: defaultCardData.card.replace(/X/g, ""),
                    expirationDate: "XXXX",
                  },
                },
                lineItems:objLine,
                refTransId: orderDetailData?.paymentInfo?.transactionId,
              },
            },
          };
          refundAPI.mutate(chargeProfile);
        }
      } else {
        showErrorToast("Could not find status of your payment");
      }
    },
    onError: (error) => {},
  });

  const handle = () => {
    const status = {
      getTransactionDetailsRequest: {
        merchantAuthentication: {
          name: process.env.REACT_APP_MERCHANT_NAME,
          transactionKey: process.env.REACT_APP_MERCHANT_TRANSACTION_KEY,
        },
        transId: orderDetailData?.paymentInfo?.transactionId,
      },
    };
    checkStatus.mutate(status);
  };

  const modifyOrderAPI = useMutation((post) => modifyOrder(post, token), {
    onSuccess: (result) => {
      if (result.data.success) {
        if (result?.data?.refundInfo?.profileInformationId) {
          handle(result);
        } else {
          showSuccessToast(result?.data?.msg);
          handleViewCart();
        }
      } else {
        showErrorToast(
          "The Order has moved to Processing state and cannot be modified"
        );
      }
    },
    onError: (error) => {},
  });

  const handleProductClick = (id, flag) => {
    if (!flag) {
      navigate({
        pathname: "/part",
        search: `number=${id}`,
      });
    }
  };

  const handleUpdateOkay = () => {
    const obj = {};
    obj.orderId = orderDetailData.id;
    let arr = [];
    if (tableData && tableData.length > 0) {
      tableData.forEach((item) => {
        const obj = {};
        obj.partId = item.partId;
        obj.quantity = item.partQty <= 1 ? 1 : item.partQty;
        obj.addedBy = "";
        obj.notes = null;
        obj.otherNotes = [];
        arr.push(obj);
      });
    }
    obj.items = arr;
    setUpdateDialog(false);
    modifyOrderAPI.mutate(obj);
  };
  const handleRowReturnQuantityChange = (val, row, partId) => {
  

    const newSetdatata = tableData?.map((obj, id) => {
      if (obj?.partId == partId) {
        obj.returnQuantity = val;
        return obj;
      } else {
        return obj;
      }
    });
 
    setTableData(newSetdatata);
  };


  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <>
      <IlmorModal
        isopen={cancelDialog}
        handleOkay={handleOkay}
        handleClose={handleCancel}
        message={"Cancel this order?"}
        fisrtButton={"Don’t Cancel"}
        secondButton={"Yes Cancel"}
      ></IlmorModal>
      <IlmorModal
        isopen={updateDialog}
        handleOkay={handleUpdateOkay}
        handleClose={handleUpdate}
        message={"Modify Order?"}
        messageDetail={
          "The existing order will be cancelled and you will be required to place a new order again"
        }
        fisrtButton={"Cancel"}
        secondButton={"Confirm"}
      ></IlmorModal>
      <IlmorModal
        isopen={returnDialog}
        handleOkay={handleReturn}
        handleClose={handleReturnClose}
        message={"Why are you returning the Selected Items?"}
        fisrtButton={"Cancel"}
        input={true}
        placeholder={"reason for returning"}
        secondButton={"Return Items"}
      ></IlmorModal>
      {(mutation.isLoading ||
        voidTransaction.isLoading ||
        refundAPI.isLoading ||
        returnMutaion.isLoading ||
        checkStatus.isLoading ||
        modifyOrderAPI.isloading) && <Loader></Loader>}
      <div className="billTtile marginTop partOrderMob">PArts Ordered</div>
      <div className={`shoppingListoutDesktop`}>
        <table className={`shoppingListTableDesktop orderDetailTable`}>
          {currentDevice !== "Mobile" && orderDetail && (
            <thead className={`shoppingListTableHeadDesktop`}>
              <tr className={`shoppingListTableHeadRowDesktop`}>
                <th
                  width="15%"
                  style={{ textAlign: "left", paddingLeft: "8px" }}
                  className={classnames(
                    "shoppingListTableHeadingDesktop part",
                    {
                      paddingLeftForReturn:
                        orderDetailData.orderStatus === "Completed",
                    }
                  )}
                >
                  Part
                </th>
                <th
                  style={{ textAlign: "center" }}
                  width="10%"
                  className={`shoppingListTableHeadingDesktop`}
                >
                  Qty
                </th>
                {/* return code */}
                {returnCheckBoxCount && returnCheckBoxCount > 0 ? (
                  <th
                    style={{ textAlign: "center" }}
                    width="20%"
                    className={`shoppingListTableHeadingDesktop sdf`}
                  >
                    Return Qty
                  </th>
                ) : (
                  <></>
                )}

                <th
                  style={{ textAlign: "center" }}
                  width="20%"
                  className={`shoppingListTableHeadingDesktop sdf`}
                >
                  Price
                </th>

                <th
                  style={{ textAlign: "center" }}
                  width="5%"
                  className={`shoppingListTableHeadingDesktop`}
                >
                  Total
                </th>
              </tr>
            </thead>
          )}
          {(currentDevice === "Desktop" || currentDevice === "Tablet") && (
            <tbody className={`shoppingListTableBodyDesktop `}>
              {tableData && tableData?.length > 0 ? (
                tableData?.map((row, index) => (
                  <tr
                    className={`shoppingListTableRowDesktop${
                      index % 2 === 0 ? " EvenRow" : ""
                    } ${orderDetail ? " ordeDetailWhiteRow" : ""}`}
                    key={index}
                  >
                    <td className={`shoppingListTableInputDivDesktop`}>
                      <div className="shopRow1">
                        {returnOrder && (
                          // <input
                          //   className="returnOrderCheckBox"
                          //   type="checkbox"
                          //   checked={row.isReturn}
                          //   onClick={() => {
                          //     handelReturnSelect(row);
                          //   }}
                          // ></input>

                          <Checkbox
                            {...label}
                            checked={row?.isReturn}
                            onClick={(e) => {
                              handelReturnSelect(row, e);
                            }}
                            sx={
                              {
                                // color: "white",
                                // "&.Mui-checked": {
                                //   color: "white",
                                // },
                                // "& .MuiSvgIcon-root": {
                                //   fill: "black",
                                // },
                                // "&.Mui-checked .MuiSvgIcon-root": {
                                //   fill: "black",
                                // },
                                // "&.Mui-checked .MuiIconButton-label": {
                                //   backgroundColor: "black",
                                // },
                              }
                            }
                            // color="default"
                          />
                        )}

                        <Tooltip
                          placement="top"
                          title={
                            row?.partStatus === "Discontinued"
                              ? "The part is discontinued "
                              : ""
                          }
                        >
                          <div
                            onClick={() => {
                              handleProductClick(
                                row.partNumber,
                                row?.partStatus === "Discontinued"
                              );
                            }}
                            className={classnames("shopListRow1LeftSide", {
                              disableButtonCart:
                                row?.partStatus === "Discontinued",
                            })}
                          >
                            <img
                              className="shopListRow1Image"
                              src={row.images ? row.images : place}
                            ></img>
                          </div>
                        </Tooltip>
                        <div className="shopListRow1RightSide">
                          <div className="diplayTag">
                            {row?.productLabel && (
                              <span className={`cardList__newTextDesktop`}>
                                {row?.productLabel}
                              </span>
                            )}
                            {row.partNumber && (
                              <span className={`shoppingListSmallTitile`}>
                                {(row?.partNumber).toString().length <= 20
                                  ? row?.partNumber
                                  : row?.partNumber &&
                                    (row?.partNumber).toString()?.slice(0, 20) +
                                      "..."}
                              </span>
                            )}
                          </div>
                          <span               onClick={() => {
                              handleProductClick(
                                row.partNumber,
                                row?.partStatus === "Discontinued"
                              );
                            }} className={`curp shoppingListTitle`}>
                            {row.partName}
                          </span>
                          <span
                            className={`shoppingListTitle notAvailableText`}
                          >
                            {row.isBackordered &&
                            row?.inventory <= row?.inStockQuantity
                              ? "Backordered"
                              : ""}
                          </span>

                          <div className="marBot">
                            {row.partStatus ? (
                              <span
                                className={`shoppingListTitle notAvailableText `}
                              >
                                {row.partStatus ? row.partStatus : ""}
                              </span>
                            ) : (
                              <></>
                            )}
                            {row.message ? <br></br> : <></>}
                            {row.message ? (
                              <span
                                className={`shoppingListTitle  shoppingListTablepriceMessage`}
                              >
                                {row.message ? ` (${row.message})` : ""}
                              </span>
                            ) : (
                              <></>
                            )}
                            {
                              <>
                                {row?.otherNotes &&
                                  Object.values(row?.otherNotes).length > 0 &&
                                  Object.values(row?.otherNotes)[0].length >
                                    0 && (
                                    <>
                                      <div className="requiredINfoClass">
                                        <div className="requiredINfo">
                                          Required information
                                        </div>
                                        <span className="imp">*</span>
                                      </div>
                                      <Grid
                                        container
                                        style={{
                                          // flexDirection: "column",
                                          // display: "flex",
                                          // marginTop: "20px",
                                          textAlign: "left",
                                        }}
                                        spacing={1}
                                        lg={12}
                                        xs={12}
                                        md={12}
                                      >
                                        {Object.entries(row?.otherNotes).map(
                                          ([key, value]) =>
                                            value &&
                                            value.length > 0 &&
                                            value?.map((innerArray, index) => (
                                              <>
                                                <Grid
                                                  className="gridcss"
                                                  item
                                                  md={6}
                                                >
                                                  <div className="labelPartDetail">
                                                    {innerArray.title}
                                                    <span className="red">
                                                      *
                                                    </span>
                                                  </div>
                                                  <input
                                                    className={`InputFieldShippingAddressPayment shoppingListInput`}
                                                    onChange={(event) => {
                                                      handleAddInfo(
                                                        row,
                                                        innerArray,
                                                        event.target.value,
                                                        key,
                                                        index
                                                      );
                                                    }}
                                                    value={innerArray.value}
                                                    type="text"
                                                    name="firstname"
                                                  ></input>
                                                </Grid>
                                              </>
                                            ))
                                        )}
                                      </Grid>
                                    </>
                                  )}
                              </>
                            }
                          </div>
                        </div>
                      </div>
                    </td>
                    <td
                      className={`shoppingListTableQuantityDivDesktop orderDetailQuant`}
                    >
                      {!returnOrder ? (
                        <div className="numericInputOrderDetailDiv">
                          <NumericInput
                            className={`shoppingListTableQuantityDesktop`}
                            style={buttonStyles}
                            max={999}
                            min={1}
                            value={row.partQty <= 0 ? "" : row.partQty}
                            strict
                            mobile={false}
                            onChange={(event) =>
                              handleInputChangeSelect(event, row)
                            }
                            onBlur={(event) => {
                              if (event.target.value == "" || event.target.value <= 0) {
                                handleInputChangeSelect(1, row);
                              }
                            }}
                            // value={row.partQty}
                          />
                          {tableData.length > 1 ? (
                            <div className="shopListLow">
                              <span
                                onClick={(event) => handleRemove(row)}
                                className={`shopLiRemove orderRemove`}
                              >
                                Remove
                              </span>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <div className="numericInputOrderDetailDiv">
                          <div className="shopListTabDiv">
                            <span className="shoppingListTableprice">
                              {" "}
                              Qty : {row?.partQty}
                            </span>

                            {/* <span className={`shoppingListTableprice`}>
                              {row.partQty}
                            </span> */}
                          </div>
                        </div>
                      )}
                    </td>
                    {/* return code */}
                    {returnCheckBoxCount && returnCheckBoxCount > 0 ? (
                      <td style={{ textAlign: "center" }}>
                        {" "}
                        {returnOrder && row?.isReturn && row?.partQty ? (
                          <>
                            <NumericInput
                              className={`shoppingListTableQuantityDesktop lighBorder`}
                              style={buttonStyles}
                              min={1}
                              max={row?.partQty}
                              mobile={false}
                              // min={1}
                              strict
                              defaultValue={1}
                              onChange={(e) =>
                                handleRowReturnQuantityChange(
                                  e,
                                  row,
                                  row?.partId
                                )
                              }
                              onBlur={(event) => {
                                if (event.target.value == "" || event.target.value <= 0) {
                                  handleRowReturnQuantityChange(
                                    1,
                                    row,
                                    row?.partId
                                  );
                                }
                              }}
                              value={!row?.isReturn ? 1 : row?.returnQuantity}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </td>
                    ) : (
                      <></>
                    )}
                    <td className={`shoppingListprice`}>
                      <div className="shopPrice">
                        {row?.partDiscountCost && (
                          <span className={`shoppingListTableprice`}>
                            ${addCommaDecimal(row?.partDiscountCost)}
                          </span>
                        )}
                        {row?.partOrigionalCost &&
                          row?.partOrigionalCost !== row?.partDiscountCost &&
                          addCommaDecimal(row?.partOrigionalCost) && (
                            <span
                              className={`shoppingListTablepriceDiscounted`}
                            >
                              ${addCommaDecimal(row?.partOrigionalCost)}
                            </span>
                          )}
                      </div>
                    </td>
                    <td className={`shoppingListdateTotaldiv`}>
                      <div className="shopPrice">
                        <span
                          className={`shoppingListTableprice fontforTotoal`}
                        >
                          $
                          {row.total?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <div className="cartEmptyMessage">
                  your shopping list is empty{" "}
                </div>
              )}
            </tbody>
          )}
        </table>
        {currentDevice !== "Mobile" && (
          <div className={`shoppingListButtonSectionDesktop `}>
            <div
              // className={`shoppingListBottomButtonsDesktop orderDetailButoon`}
              className={classnames(
                "shoppingListBottomButtonsDesktop orderDetailButoon",
                {
                  orderDetailButoon: orderDetailData.orderStatus === "Pending",
                }
              )}
            >
              {returnOrder && !dealerId && (
                <button
                  onClick={handleReturnClose}
                  disabled={
                    returnOk ||
                    myProfile?.profileInfo?.role === "ServiceTechnician"
                  }
                  className={`ilmorButon`}
                >
                  Return Selected Items
                </button>
              )}
              {cencel && (
                <button
                  onClick={handleCancel}
                  disabled={
                    myProfile?.profileInfo?.role === "ServiceTechnician"
                  }
                  className={`ilmorButon`}
                >
                  Cancel Return
                </button>
              )}
              {modify && (
                <button
                  disabled={
                    change1 ||
                    myProfile?.profileInfo?.role === "ServiceTechnician"
                  }
                  onClick={handleUpdate}
                  className={`ilmorButon`}
                >
                  Update Cart
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      {/*================> TABLET CODE <============================*/}

      {/* ==================> MOBILE CODE <==============================*/}
      {tableData && tableData?.length > 0 && currentDevice === "Mobile" ? (
        <div className={`shoppingListoutDesktop`}>
          {
            // <div className="selectlistTopDiv">
            //   {/* <div className='leftSideShop'>
            //                 <Select
            //                     className='header__select iLmorborder'
            //                     // defaultValue={''}
            //                     placeholder='Bulk Actions'
            //                     onChange={[]}
            //                     // menuIsOpen={true}
            //                     options={[]}
            //                 />
            //                 <button className='ilmorButon'>Apply</button>
            //             </div> */}
            //   {!reviewOrder && (
            //     <div className="rightSide">
            //       <span className="sortText">SORT BY</span>
            //       <Select
            //         className="header__select iLmorborder"
            //         // defaultValue={''}
            //         placeholder="Date added - new to old"
            //         onChange={[]}
            //         // menuIsOpen={true}
            //         options={[]}
            //       />
            //     </div>
            //   )}
            // </div>
          }
          <table className={`shoppingListTableDesktop orderDetailTable`}>
            {/* <thead className={`shoppingListTableHeadDesktop`}>
              <tr className={`shoppingListTableHeadRowDesktop`}>
                <th width="1%" >
                                    <input type="checkbox" id="ckbCheckAll"></input>
                                </th>
                <th
                  width="100%"
                  className={`shoppingListTableHeadingDesktop`}
                ></th>
              </tr>
            </thead> */}
            <tbody className={`shoppingListTableBodyDesktop`}>
              {tableData?.map((row, index) => (
                <tr
                  className={`shoppingListTableRowDesktop${
                    index % 2 === 0 ? " EvenRow" : ""
                  } ${orderDetail ? " ordeDetailWhiteRow" : ""}`}
                  key={index}
                >
                  {/* <td className={`shoppingListprice`}> */}
                  {/* </td> */}
                  <td className={`shoppingListTableInputDivDesktop`}>
                    <div className="shopRow1">
                      {returnOrder && (
                        // <input
                        //   className="returnOrderCheckBox"
                        //   type="checkbox"
                        //   checked={row.isReturn}
                        //   onClick={() => {
                        //     handelReturnSelect(row);
                        //   }}
                        // ></input>
                        <Checkbox
                          {...label}
                          checked={row?.isReturn}
                          onClick={(e) => {
                            handelReturnSelect(row, e);
                          }}
                          sx={
                            {
                              // color: "white",
                              // "&.Mui-checked": {
                              //   color: "white",
                              // },
                              // "& .MuiSvgIcon-root": {
                              //   fill: "black",
                              // },
                              // "&.Mui-checked .MuiSvgIcon-root": {
                              //   fill: "black",
                              // },
                              // "&.Mui-checked .MuiIconButton-label": {
                              //   backgroundColor: "black",
                              // },
                            }
                          }
                          // color="default"
                        />
                      )}
                      <Tooltip
                        placement="top"
                        title={
                          row?.partStatus === "Discontinued"
                            ? "The part is discontinued "
                            : ""
                        }
                      >
                        {/* <div className="shopListRow1LeftSide"> */}
                        {/* {!reviewOrder  && !yourCart && (
                          <input
                            className="returnOrderCheckBox"
                            type="checkbox"
                            onClick={() => {}}
                          ></input>
                        )} */}
                        {/* <img
                          className="shopListRow1Image"
                          src={row.images}
                        ></img>
                      </div> */}
                        <div
                          onClick={() => {
                            handleProductClick(
                              row.partNumber,
                              row?.partStatus === "Discontinued"
                            );
                          }}
                          className={classnames("shopListRow1LeftSide", {
                            disableButtonCart:
                              row?.partStatus === "Discontinued",
                          })}
                        >
                          <img
                            className="shopListRow1Image"
                            src={row.images ? row.images : place}
                          ></img>
                        </div>
                      </Tooltip>
                      <div className="shopListRow1RightSide">
                        <div className="shopListTabDetailsSecMain">
                          <div className="shopListTabDetailsSec1">
                            <div className="shopListTabDetailsSec1">
                              {row?.productLabel && (
                                <span className={`cardList__newTextDesktop`}>
                                  {row?.productLabel}
                                </span>
                              )}
                              {row?.partNumber && (
                                <span className={`shoppingListSmallTitile`}>
                                  {(row?.partNumber).toString().length <= 20
                                    ? row?.partNumber
                                    : row?.partNumber &&
                                      (row?.partNumber)
                                        .toString()
                                        ?.slice(0, 20) + "..."}
                                </span>
                              )}
                            </div>
                            <span               onClick={() => {
                              handleProductClick(
                                row.partNumber,
                                row?.partStatus === "Discontinued"
                              );
                            }} className={`curp shoppingListTitle`}>
                              {row.partName}
                            </span>
                            {!reviewOrder && !yourCart && (
                              <>
                                <div className="">
                                  <span className={`shoppingListTablestatus`}>
                                    {row.partStatus}
                                  </span>
                                </div>
                                <div
                                  className="backOrderedLabel"
                                  style={{ color: "red" }}
                                >
                                  {row.isBackordered &&
                                  row?.inventory <= row?.inStockQuantity
                                    ? "Backordered"
                                    : ""}
                                </div>
                              </>
                            )}
                            {
                              <>
                                {row?.otherNotes &&
                                  Object.values(row?.otherNotes).length > 0 &&
                                  Object.values(row?.otherNotes)[0].length >
                                    0 && (
                                    <>
                                      <div className="requiredINfoClass">
                                        <div className="requiredINfo">
                                          Required information
                                        </div>
                                        <span className="imp">*</span>
                                      </div>
                                      <Grid
                                        container
                                        style={{
                                          // flexDirection: "column",
                                          // display: "flex",
                                          // marginTop: "20px",
                                          textAlign: "left",
                                        }}
                                        spacing={1}
                                        lg={12}
                                        xs={12}
                                        md={12}
                                      >
                                        {Object.entries(row?.otherNotes).map(
                                          ([key, value]) =>
                                            value &&
                                            value.length > 0 &&
                                            value?.map((innerArray, index) => (
                                              <>
                                                <Grid
                                                  className="gridcss"
                                                  item
                                                  md={6}
                                                >
                                                  <div className="labelPartDetail">
                                                    {innerArray.title}
                                                    <span className="red">
                                                      *
                                                    </span>
                                                  </div>
                                                  <input
                                                    className={`InputFieldShippingAddressPayment shoppingListInput`}
                                                    onChange={(event) => {
                                                      handleAddInfo(
                                                        row,
                                                        innerArray,
                                                        event.target.value,
                                                        key,
                                                        index
                                                      );
                                                    }}
                                                    value={innerArray.value}
                                                    type="text"
                                                    name="firstname"
                                                  ></input>
                                                </Grid>
                                              </>
                                            ))
                                        )}
                                      </Grid>
                                    </>
                                  )}
                              </>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={classnames(
                        "shopListDetailTablet  mobileQtyReturnParTReturn",
                        {
                          noborderquant:
                            orderDetailData.orderStatus === "Completed",
                        }
                      )}
                    >
                      {reviewOrder && (
                        <div className="shopListTabDiv">
                          <span className="shoppingListTableprice">
                            {" "}
                            Qty : {row.partQty}
                          </span>
                          {/* <span className={`shoppingListTableprice`}>
                            {row.partQty}
                          </span> */}
                        </div>
                      )}
                      {!returnOrder && (
                        <div className="shopListTabDetailsSec2 dpflexqty">
                          <span className="shopTableLabel">Qty</span>
                          <NumericInput
                            className={`shoppingListTableQuantityDesktop`}
                            style={buttonStyles}
                            max={999}
                            // min={1}
                            value={row.quantity <= 0 ? "" : row.quantity}
                            strict
                            mobile={false}
                            onChange={(event) =>
                              handleInputChangeSelect(event, row)
                            }
                            onBlur={(event) => {
                              if (event.target.value == "" || event.target.value <= 0) {
                                handleInputChangeSelect(1, row);
                              }
                            }}

                            // value={row.partQty}
                          />
                          {!reviewOrder && (
                            <div className="shopListLow">
                              {row.isloading ? (
                                <CircularProgress
                                  style={{ color: "#CE0E2D" }}
                                  size={20}
                                />
                              ) : (
                                <span
                                  onClick={(event) => handleRemove(row)}
                                  className={`redUnderLinedText`}
                                >
                                  Remove
                                </span>
                              )}
                              {/* <span className={`shopLiMovToCart moveCart`}>Move to Cart</span> */}
                            </div>
                          )}
                        </div>
                      )}
                      {returnOrder && (
                        <td className={`shoppingListdateTotaldiv`}>
                          {currentDevice === "Mobile" ? (
                            <div
                              // className="centerqunt"
                              className={classnames("centerqunt", {
                                rightforReturn:
                                  orderDetailData.orderStatus === "Completed",
                              })}
                            >
                              <span className={`shoppingListTableprice`}>
                                Qty
                              </span>
                              <span className={`shoppingListTableprice`}>
                                {row.partQty}
                              </span>
                            </div>
                          ) : (
                            <span className={`shoppingListTableprice`}>
                              Qty : {row.partQty}
                            </span>
                          )}
                        </td>
                      )}
                      {returnCheckBoxCount && returnCheckBoxCount > 0 ? (
                        <>
                          {returnOrder && row?.isReturn && row?.partQty ? (
                            <Box>
                              <Box
                                sx={{
                                  marginBottom: "10px",
                                  fontWeight: 700,
                                  fontSize: "13px",
                                }}
                              >
                                Return Qty
                              </Box>
                              <Box>
                                {" "}
                                <>
                                  <NumericInput
                                    className={`shoppingListTableQuantityDesktop lighBorder`}
                                    style={buttonStyles}
                                    min={1}
                                    max={row?.partQty}
                                    mobile={false}
                                    // min={1}
                                    strict
                                    defaultValue={1}
                                    onChange={(e) =>
                                      handleRowReturnQuantityChange(
                                        e,
                                        row,
                                        row?.partId
                                      )
                                    }
                                    onBlur={(event) => {
                                      if (event.target.value == "" || event.target.value <= 0) {
                                        handleRowReturnQuantityChange(
                                          1,
                                          row,
                                          row?.partId
                                        );
                                      }
                                    }}
                                    value={
                                      !row?.isReturn ? 1 : row?.returnQuantity
                                    }
                                  />
                                </>
                              </Box>
                            </Box>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="shopListTabDiv">
                        <span className="shopTableLabel">Price</span>
                        {row?.partDiscountCost && (
                          <span className={`shoppingListTableprice`}>
                            ${addCommaDecimal(row?.partDiscountCost)}
                          </span>
                        )}
                        {row?.partOrigionalCost &&
                          row?.partOrigionalCost !== row?.partDiscountCost &&
                          addCommaDecimal(row?.partOrigionalCost) && (
                            <span
                              className={`shoppingListTablepriceDiscounted`}
                            >
                              ${addCommaDecimal(row?.partOrigionalCost)}
                            </span>
                          )}
                      </div>
                      <div className="shopListTabDiv">
                        <span className="shopTableLabel">Total</span>
                        <span
                          className={`shoppingListTableprice fontforTotoal`}
                        >
                          $
                          {addCommaDecimal(row.partQty * row?.partDiscountCost)}
                        </span>
                      </div>
                      {reviewOrder && (
                        <div className="shopListTabDiv">
                          <span className="shopTableLabel">Total</span>
                          <span className={`shoppingListTableprice`}>
                            $
                            {addCommaDecimal(
                              row.partQty * row?.partDiscountCost
                            )}
                          </span>
                        </div>
                      )}
                      {/* {!reviewOrder && (
                        <div className="shopListTabDiv">
                          <span className="shopTableLabel">Added By</span>
                          <span className={`shoppingListTableaddedBy`}>
                            {row.added_by}
                          </span>
                        </div>
                      )}
                      {!reviewOrder && (
                        <div className="shopListTabDiv">
                          <span className="shopTableLabel">Date Added</span>
                          <span className={`shoppingListTabledate`}>
                            {row.added_date}
                          </span>
                        </div>
                      )}
                      {!reviewOrder && (
                        <div className="shopListTabDiv">
                          <span className="shopTableLabel">Status</span>
                          <span className={`shoppingListTablestatus`}>
                            {row.partStatus}
                          </span>
                        </div>
                      )} */}
                    </div>
                    {/* {!reviewOrder && (
                      <input
                        type="text"
                        placeholder="Add notes"
                        className={`shoppingListInput`}
                        value={row.add_note}
                        onChange={(event) =>
                          handleInputChange(event, row.partId)
                        }
                      ></input>
                    )} */}
                    {/* { !addToCart && <input
                          type="text"
                          className={`shoppingListInput`}
                          value={row.input}
                          onChange={(event) =>
                            handleInputChange(event, index, "input")
                          }
                        ></input>} */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={`shoppingListButtonSectionDesktop `}>
            <div className={`shoppingListBottomButtonsDesktop`}>
              {returnOrder && !dealerId && (
                <button
                  onClick={handleReturnClose}
                  disabled={
                    returnOk ||
                    myProfile?.profileInfo?.role === "ServiceTechnician"
                  }
                  className={`ilmorButon`}
                >
                  Return Selected Items
                </button>
              )}
              {cencel && (
                <button
                  onClick={handleCancel}
                  disabled={
                    myProfile?.profileInfo?.role === "ServiceTechnician"
                  }
                  className={`ilmorButon`}
                >
                  Cancel Return
                </button>
              )}
              {modify && (
                <button
                  disabled={
                    change1 ||
                    myProfile?.profileInfo?.role === "ServiceTechnician"
                  }
                  onClick={handleUpdate}
                  className={`ilmorButon`}
                >
                  Modify Order
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        currentDevice === "Mobile" && (
          <div className="cartEmptyMessage">your shopping list is empty </div>
        )
      )}
    </>
  );
};
