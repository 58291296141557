import React, { useState, useEffect } from "react";
import "./UpdatePassword.css";
import "./LoginPage.css";
import logo from "../Images/ILMOR_LOGO.png";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useStore } from "../store/home";
import { signInInfo } from "../api/posts";
import { showErrorToast, showSuccessToast } from "../utils/Toaster";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import commonMessages from "../commenComp/CommonMessages";
import Loader from "../commenComp/Loader";
import { initialState } from "../commonFunction";
import { Checkbox, Tooltip } from "@mui/material";
import { toast } from "react-toastify";

const UpdatePassword = () => {
  const schema = yup.object().shape({
    newPass: yup.string().required("Password is required"),
    cnfrmPass: yup.string().required("Password is required"),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const {
    currentDevice,
    setToken,
    token,
    myProfile,
    pass,
    emailStore,
    setLastPage,
  } = useStore();
  useEffect(() => {
    if (localStorage.getItem("email") !== undefined) {
      reset({ username: localStorage.getItem("email") });
    }
  }, []);

  // const [pass, setPass] = useState("");
  // const [cnfrmPass, setCnfrmPass] = useState("");
  const [match, setMatch] = useState(true);
  // const [userIsLoggedIn, setUserIsLoggedIn] = useState("");
  const navigate = useNavigate();
  // const mutation = useMutation((post) => loginToken(post), {
  //   onSuccess: (result) => {
  //     // setUserIsLoggedIn(result.data.token);
  //     localStorage.setItem('token', result.data.token)
  //     setToken(result.data.token)
  //     navigate({
  //       pathname: "/home",
  //     });
  //   },
  //   onError: (error) => {
  //     reset({ username: '' })
  //   },
  // });
  const resetStore = () => {
    useStore.setState(initialState);
  };

  const mutation = useMutation((post) => signInInfo(post, token), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
          resetStore();
          setLastPage(false);
          showSuccessToast(result?.data?.msg);
          localStorage.removeItem("token");
          localStorage.removeItem("my-persisted-store");
          setToken("");
          navigate("/sign-in", { replace: true });
        } else {
          if (
            result?.data?.msg.includes("The new password must have atleast")
          ) {
            toast.dismiss();
            showErrorToast(result?.data?.msg);
          } else {
            setTimeout(() => {
              localStorage.removeItem("token");
              localStorage.removeItem("my-persisted-store");
              navigate("/sign-in", { replace: true });
              setToken("");
              resetStore();
              setLastPage(false);
            }, 4000);
          }
        }
      }
    },
    onError: (error) => {
      setTimeout(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("my-persisted-store");
        navigate("/sign-in", { replace: true });
        setToken("");
        resetStore();
        setLastPage(false);
      }, 4000);
    },
  });
  const onSubmit = (data, event) => {
    if (data.newPass !== data.cnfrmPass) {
      setMatch(false);
    } else {
      setMatch(true);
      const obj = {};
      obj.email = emailStore;
      obj.currentPassword = pass;
      obj.newPassword = data.cnfrmPass;
      obj.confirmNewPassword = data.newPass;
      obj.id = myProfile?.profileInfo?.id;
      mutation.mutate(obj);
    }
  };

  const handlePassChange = (event) => {
    // setPass(event.target.value);
  };
  const handleCnfrPassChange = (event) => {
    // setCnfrmPass(event.target.value);
  };
  const [togleFieldP, settogleField] = useState(true);
  const togleField = () => {
    settogleField(!togleFieldP);
  };

 
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <>
      {mutation.isLoading && <Loader></Loader>}
      <div className={`loginPage__main${currentDevice}`}>
        <div className={`loginContainer${currentDevice}`}>
          <div className={`logo${currentDevice}`}>
            <img
              className={`title-image${currentDevice}`}
              src={logo}
              alt="company-logo"
            />
          </div>
          <div className={`info__main${currentDevice}`}>
            <div className={`headingBox${currentDevice} setPassword`}>
              <h2 className={`heading${currentDevice}`}>
                {"SET NEW PASSWORD"}
              </h2>

              {!match && (
                <div className={`invalidCredMessage${currentDevice}`}>
                  {"The passwords do not match"}
                </div>
              )}
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={`form${currentDevice}`}
            >
              <p className={`info${currentDevice}`}>
                Use the form below to select a new password for your Ilmor parts
                store account.
              </p>
              <hr className="purchase_line"></hr>
              <Tooltip
                className="dffd34383"
                placement="top"
                title={
                  "The new password must have atleast 10 characters, 1 lower case, 1 upper case, 1 number and one special character"
                }
              >
                <div className={`emailLabel${currentDevice}`}>
                  <h5 className={`label${currentDevice}`}>NEW PASSWORD</h5>
                </div>
              <input
                // style={{ border: "none" }}
                className={`usernameInput${currentDevice} setPassword`}
                type={togleFieldP ? "password" : "text"}
                // value={email}
                name="username"
                maxLength={20}
                required
                {...register("newPass", {
                  onChange: (e) => {
                    handlePassChange(e);
                  },
                })}
              ></input>
              </Tooltip>
              <div className={`emailLabel${currentDevice}`}>
                <h5 className={`label${currentDevice}`}>CONFIRM PASSWORD</h5>
              </div>
              <input
                // style={{ border: "none" }}
                className={`usernameInput${currentDevice} setPassword`}
                type={togleFieldP ? "password" : "text"}
                maxLength={20}
                required
                {...register("cnfrmPass", {
                  onChange: (e) => {
                    handleCnfrPassChange(e);
                  },
                })}
              ></input>
              <div className={` checkBox${currentDevice}`}>
                {/* <input
                  className={`inputCheckboxGlobal`}
                  type="checkbox"
                  onChange={handleRememberMeChange}
                />{" "} */}
                <Checkbox
                  {...label}
                  className="opp"
                  sx={{
                    "&.Mui-checked .MuiIconButton-label": {
                      backgroundColor: "black",
                    },
                    "&.MuiCheckbox-root": {
                      border: "none", // Remove border
                    },
                    "&.Mui-checked": {
                      outline: "none", // Remove outline when checked
                    },
                    "&.Mui-focusVisible .MuiSvgIcon-root": {
                      outline: "none",
                    },
                    "&:focus": {
                      outline: "none",
                    },
                  }}
                  name="Remember me"
                  onChange={togleField}
                  // color="default"
                />
                <span class="checkmark"></span>
                <label className={`rememberMe${currentDevice}`}>
                  Show Password
                </label>
              </div>
              <div className={`upBtnDesktop`}>
                <button
                  className={`ilmorButon setPassword`}
                  // onClick={handleSignIn}
                >
                  {" "}
                  Set Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatePassword;
