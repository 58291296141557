import React from 'react'
import { Box ,Grid} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getShippingPolicy } from "../../api/posts";
import { useStore } from "../../store/home";
import Footer from "../Footer";
import Header from "../Header";
const ContactUs = () => {
    const { token } = useStore();
    const {
      data: returnExchangeData,
      error,
      refetch,
      isLoading,
    } = useQuery({
      queryKey: ["getShippingPolicy"],
      retry: 0,
      refetchOnWindowFocus: false,
      queryFn: () => getShippingPolicy("contact", token),
    });
  return (
    <>
    <Header page="Catalog"></Header>
    <div>ContactUs</div>
    <Footer />
    </>
  )
}

export default ContactUs