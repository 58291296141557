import React from "react";
import Header from "./Header";
import PartsCatalog from "../commenComp/PartsCatalog.js";

import Footer from "./Footer";
import "../components/CatalogPage.css";

import { useStore } from "../store/home";
import BrowseCategories from "./BrowseCategories";

import { Box, Grid } from "@material-ui/core";
import CategoryTile from "./CateogoryTile";
import { useEffect } from "react";

export default function CatalogPage() {

  const {
    setKeyWord,
    setKeyWordFinal,
  } = useStore();

  useEffect (()=>{
    setKeyWordFinal('')
    setKeyWord('')
  },[])

  return (
    <>
      <Grid container>
        <Grid item lg={12} className="catalogPageHeaderAndPartsCatalog">
          <Header page="CatalogPage" />
          <PartsCatalog />
        </Grid>
        <Grid item lg={12}>
          <CategoryTile showCatBrowseDiv={true}/>
        </Grid>
        <Grid item md={12} sm={12} lg={12}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
}
