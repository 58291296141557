import React, { useState } from "react";
import "./TaxExemptionPopUp.css";
import { useStore } from "../store/home";
import { ReactComponent as CloseButton } from "../Images/closeButton.svg";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as Cross } from "../Images/crossRed.svg";
import { useMutation } from "@tanstack/react-query";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as CALENDAR } from "../Images/calendar.svg";
import { ReactComponent as CLOSE } from "../Images/closeButton.svg";
import { Button, Grid, Stack } from "@mui/material";
import { labelDesktopAddShippingRequired } from "../components/StyledComponents/ShippingAddressStyled";
import Dropzone from "react-dropzone";
import { storeAdditionalTaxDetails } from "../api/posts";
import { showErrorToast, showSuccessToast } from "../utils/Toaster";
import moment from "moment";
import commonMessages from "../commenComp/CommonMessages";
import Loader from "../commenComp/Loader";

export default function TaxExemptionPopUp({
  isModalOpen,
  setIsModalOpen,
  handleCloseModal,
}) {
  const { currentDevice, token, userInfo } = useStore();
  // const [isModalOpen , setIsModalOpen] = useState(false);

  const mutation = useMutation(
    (post) => storeAdditionalTaxDetails(post, token),
    {
      onSuccess: (result) => {
        if (result) {
          if (result.data.success) {
            showSuccessToast(result?.data?.msg);
            setIsModalOpen(false);
          } else {
            showErrorToast(result?.data?.msg);
          }
        }
      },
      onError: (error) => {},
    }
  );

  const sendSkip = () => {
    let formData = new FormData();
    formData.append("email", userInfo?.username);
    formData.append("skip", true);
    mutation.mutate(formData);
  };

  const onSubmit = (data, event) => {
    event.preventDefault();

    const syear = data.signedDate.getFullYear();
    const smonth = String(data.signedDate.getMonth() + 1).padStart(2, "0");
    const sday = String(data.signedDate.getDate()).padStart(2, "0");
    const formattedsignedDate = `${syear}-${smonth}-${sday}`;

    const eyear = data.expirationDate.getFullYear();
    const emonth = String(data.expirationDate.getMonth() + 1).padStart(2, "0");
    const eday = String(data.expirationDate.getDate()).padStart(2, "0");
    const formattedexpirationDate = `${eyear}-${emonth}-${eday}`;

    let formData = new FormData();
    formData.append("file", attachmentArray[0]);
    formData.append("exemptionReason", data.exemptionReason);
    formData.append("startDate", formattedsignedDate);
    formData.append("expiryDate", formattedexpirationDate);
    formData.append("taxCertificate", attachmentArray[0]);
    formData.append("email", userInfo?.username);

    mutation.mutate(formData);
  };

  const schema = yup.object().shape({
    exemptionReason: yup.string().required("Reason is required"),
    signedDate: yup
      .date()
      .required("Date is required")
      .typeError("Invalid Date"),
    expirationDate: yup
      .date()
      .required("Date is required")
      .typeError("Invalid Date"),
    fileUpload: yup.mixed().required("File is required"),
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      exemptionReason: "",
      signedDate: "",
      expirationDate: "",
      fileUpload: "",
    },
    resolver: yupResolver(schema),
  });

  const [attachmentArray, setAttachmentArray] = useState([]);
  const handleValidateFiles = (acceptedFiles) => {
    setAttachmentArray([...acceptedFiles, ...attachmentArray]);
    const fl = [...acceptedFiles, ...attachmentArray];
    let arrayImage = [];
    for (let i = 0; i < fl?.length; i++) {
      arrayImage.push(fl[i]);
    }
    if (fl?.length < 1) {
      showErrorToast("No file was selected.");
    } else if (fl?.length > 1) {
      showErrorToast("More than one file cannot be selected.");
    } else {
      setAttachmentArray(arrayImage);
    }
  };
  // const handleValidateFiles = (acceptedFiles, rejectedFiles) => {
  //   if (rejectedFiles.length > 0) {
  //     const fileSizeLimit = 10; // Maximum file size limit in MB
  //     const errorMessage = `File size exceeds the ${fileSizeLimit}MB limit.`;
  //     showErrorToast(errorMessage);
  //     // Show the error message to the user in your UI
  // } else {
  //   if (acceptedFiles.length > 1) {
  //     showErrorToast("More than one file cannot be selected");
  //   } else {
  //     setAttachmentArray([...acceptedFiles, ...attachmentArray]);
  //     const fl = [...acceptedFiles, ...attachmentArray];
  //     let arrayImage = [];
  //     for (let i = 0; i < fl?.length; i++) {
  //       arrayImage.push(fl[i]);
  //     }
  //     if (fl?.length > 1) {
  //       // showErrorToast("You can upload only one file")
  //     } else {
  //       setAttachmentArray(arrayImage);
  //       // setUploadFiles(arrayImage);
  //     }
  //   };

  const removeFile = (file) => () => {
    const newFiles = [...attachmentArray];
    newFiles.splice(newFiles.indexOf(file), 1);
    setAttachmentArray(newFiles);
  };

  return (
    isModalOpen && (
      <div className={`taxexmp_main`}>
        {mutation.isLoading && <Loader></Loader>}
        <div className={`taxexmp_centerComponent`}>
          <div className={`taxexmp_header`}>
            <div className={`taxexmp_headerTitle`}>
              <span>{commonMessages?.taxExemptionPopUpHeading}</span>
            </div>
            <div
                onClick={handleCloseModal}
                className={`addToCart_closeBtn center curPoint dfgr4546s`}
              >
                <CloseButton
                  className={`addToCart_closeBtnIcon `}
                ></CloseButton>
              </div>
          </div>
          <hr className="hrLineBreak nomarline" />
          <div className={`taxexmp_body`}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <h5 className="taxexmp_exemption taxexmp_dateHeading labelDesktopAddShippingRequired">
                    EXEMPTION REASON
                  </h5>
                  <textarea
                    className="Txtarea TxtareaDesktop"
                    minLength={10}
                    maxLength={500}
                    type="textarea"
                    name="exReason"
                    {...register("exemptionReason")}
                  />
                  {/* <div className='caldate'> */}
                  {errors.exemptionReason && (
                    <p className="error">{errors.exemptionReason.message}</p>
                  )}
                </Grid>
                {/* <div className="divtax"> */}
                <Grid item xs={6} sm={3} md={4}>
                    <div>
                      <h5 className="dateHeading styuytu labelDesktopAddShippingRequired dateHeadingTabletsigned">
                        SIGNED DATE
                      </h5>
                      <div className="datebg">
                        <CALENDAR className="icon"></CALENDAR>
                        <Controller
                          control={control}
                          name="signedDate"
                          render={({ field }) => (
                            <DatePicker
                              onChange={(date) =>
                                field.onChange(date ? date : "")
                              }
                              inputMode="none"
                              dateFormat={'MM/dd/yy'}
                              selected={field.value}
                              maxDate={moment().toDate()}
                              onFocus={(e) => (e.target.readOnly = true)}
                            />
                          )}
                        />
                      </div>
                      {errors.signedDate && (
                        <p className="error">{errors.signedDate.message}</p>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={3} md={4}>
                    <div>
                      <h5 className="dateHeading styuytu labelDesktopAddShippingRequired dateHeadingTabletsigned">
                        EXPIRATION DATE
                      </h5>
                      <div className="datebg">
                        <CALENDAR className="icon"></CALENDAR>
                        {/* <DatePicker selected={expiryDate} onChange={(date) => setExpiryDate(date)} {...register("expirationDate")} /> */}
                        <Controller
                          control={control}
                          name="expirationDate"
                          render={({ field }) => (
                            <DatePicker
                              onChange={(date) => field.onChange(date)}
                              selected={field.value}
                              inputMode="none"
                              dateFormat={'MM/dd/yy'}
                              minDate={moment().toDate()}
                              onFocus={(e) => (e.target.readOnly = true)}
                            />
                          )}
                        />
                      </div>
                      {errors.expirationDate && (
                        <p className="error">{errors.expirationDate.message}</p>
                      )}
                    </div>
                  </Grid>
                {/* </div> */}
                <Grid item xs={12}>
                  <h5 className="taxexmp_uploadDoc labelDesktopAddShippingRequired">
                    UPLOAD DOCUMENT
                  </h5>
                  <div className="taxexmp_dropzone">
                    <Dropzone
                      accept={{
                        "application/pdf": [".pdf"],
                        "image/jpeg": [".jpeg"],
                      }}
                      // "text/csv": [".csv"],
                      // "image/png": [".png"],
                      // "image/jpg": [".jpg"],
                      // "image/pdf": [".pdf"],
                      // "application/vnd.ms-excel": [".xls"],
                      // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                      //   ["..xlsx"],

                      // maxFiles={1}
                      maxSize={10485760}
                      style={{
                        width: "40%",
                        height: "20%",
                        border: "10px solid black",
                      }}
                      onDrop={(acceptedFiles) =>
                        handleValidateFiles(acceptedFiles)
                      }
                      {...register("fileUpload")}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section
                          style={{
                            pointerEvents: `${
                              attachmentArray.length > 0 ? "none" : "unset"
                            }`,
                          }}
                        >
                          <div {...getRootProps()} className="text-center">
                            <input
                              disabled={
                                attachmentArray.length > 0 ? true : false
                              }
                              {...getInputProps()}
                            />
                            <Stack
                              direction="column"
                              className="borderDragNDrop"
                            >
                              <Button
                                disabled={
                                  attachmentArray.length > 0 ? true : false
                                }
                                className="taxexmp_uploadButton"
                              >
                                <span className="taxexmp_uploadButtonText">
                                  Choose File
                                </span>
                              </Button>
                            </Stack>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    <div className="uploadAttachment">
                      {errors.fileUpload && (
                        <p className="error">{errors.fileUpload.message}</p>
                      )}
                      {attachmentArray.length > 0
                        ? attachmentArray[0]?.name?.length > 16
                          ? attachmentArray[0]?.name?.slice(0, 16) + "..."
                          : attachmentArray[0]?.name
                        : "No file chosen"}
                      {attachmentArray?.map((file) => (
                        <>
                          <Cross
                            className="icon quickCross"
                            style={{ cursor: "pointer" }}
                            onClick={removeFile(file)}
                          />
                        </>
                      ))}
                    </div>
                  </div>
                  <div className="nutpadchoosefile">
                    <span>{`(.pdf and .jpg of size less than 10 MB)`}</span>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="taxexmp_btn_cancel">
                    <button
                      className={`taxexmp_can_button`}
                      type="button"
                      onClick={() => sendSkip()}
                    >
                      Skip
                    </button>
                    <button disabled={attachmentArray.length > 0 ? false : true} className={`ilmorButon`} type="submit">
                      Add Tax Exemption Form
                    </button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </div>
    )
  );
}
