import React from 'react'
import "./DeleteUser.css";
import commonMessages from '../commenComp/CommonMessages';

const DeleteUser = () => {

    return (
        <div className='cart'>
            <div className='text'>
                <h2>{ commonMessages?.removeEmployeeAccountRow}</h2>
                <p>{ commonMessages?.deleteUserSubHeading}</p>
            </div>
            <div className='buttons'>
                <button className='btnCancel'>Cancel</button>
                <button className='btnDelete'>Delete</button>
            </div>
        </div>
    )
}

export default DeleteUser