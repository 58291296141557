import {
  Box,
  Button,
  Card,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { deleteDealerUser, getAllDealer } from "../api/posts";
import { useStore } from "../store/home";
import { showSuccessToast } from "../utils/Toaster";
import AddEmployeeAccount from "./AddEmployeeAccount";
import AddShippingAddress from "./AddShippingAddress";
import "./EmployeeAccountsDetail.css";
import {
  CardContentStyled,
  CardHeadingStyled,
  StyledShippingHeading,
  StyledShippingSubHeading,
} from "./StyledComponents/ShippingAddressStyled";
import {
  ActiveInactiveStyled,
  AddEmployeeAccountBtnStyled,
} from "./StyledComponents/EmployeeAccountStyled.js";
import TabletViewBlackContainer from "./TabletViewBlackContainer";
import Loader from "../commenComp/Loader";
import { ReactComponent as PlusIcon } from "../Images/PlusIcon.svg";
import { ContainerDivStyled } from "./StyledComponents/BusinessDetailsStyled";
import commonMessages from "../commenComp/CommonMessages";
import IlmorModal from "../commenComp/IlmorModal";
import { formatDate } from "../commonFunction";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
// import NumericInput from 'react-numeric-input';

export const EmployeeAccountDetails = () => {
  const [isEmployeeAccountsbtnClicked, setIsEmployeeAccountsbtnClicked] =
    useState(false);
  const [age, setAge] = useState();
  const [removeIdTablerow, setRemoveIdTablerow] = useState();
  const [editID, setEditID] = useState();

  const [dealerUserData, setDealerUserData] = useState();
  const [prepopulateEditDealerUser, setPrepopulateEditDealerUser] = useState();

  const {
    currentUserEmail,
    token,
    setScreenSize,
    setCurrentDevice,
    dealerId,
    currentDevice,
    userInfo,
    myProfile,
  } = useStore();

  const mutation = useMutation((post) => getAllDealer(post, token), {
    onSuccess: (result) => {
      if (result?.data?.data) {
        result?.data?.data.forEach((item) => {
          item.isCustomLoading = false;
        });
        setDealerUserData(result?.data?.data);
      }
    },
    onError: (error) => {},
  });

  useEffect(() => {
    if (!isEmployeeAccountsbtnClicked) {
      const obj = {
        email: userInfo.username,
        dealerId: dealerId,
      };
      mutation.mutate(obj);
    }
  }, [isEmployeeAccountsbtnClicked]);
  const buttonStyles = {
    fontFamily: "FontAwesome",
    fontSize: "12px",
  };

  const handleMaterialSelectChange = (event) => {
    setAge(event.target.value);
  };
  const handleEditClick = (email, id) => {
    setIsEmployeeAccountsbtnClicked(true);

    const editResponse =
      dealerUserData &&
      dealerUserData?.filter((item) => {
        return item?.email == email;
      });

    setPrepopulateEditDealerUser(editResponse);
    setEditID(id);
    window.scrollTo(0, 0);
    let str = `account=${id}&email=${email}`
    navigate({
      pathname: "/account/mgmt/accounts/edit",
      search: `${str}`,
    });
    // mutation.mutate({ id: id });
  };

  const removeDealerMutation = useMutation(
    (post) => deleteDealerUser(post, token),
    {
      onSuccess: (result) => {
        if (result) {
          const email = userInfo?.username;
          mutation.mutate({ email: email });
          showSuccessToast(result?.data?.msg);

          // navigate(
          //   "/reset-password",{ state: { resposnse: result.data.success } });
        }
      },
      onError: (error) => {},
    }
  );
  const [cancelDialog, setCancelDialog] = useState(false);
  const [removeCardId, setRemoveCardId] = useState("");
  const handleCancel = (id) => {
    setCancelDialog(!cancelDialog);
    setRemoveIdTablerow(id);
  };
  const handleOkay = () => {
    removeDealerMutation.mutate({ email: removeIdTablerow });
    const dataDealerArray = dealerUserData;
    dataDealerArray?.forEach((item) => {
      if (item?.email == removeIdTablerow) {
        item.isCustomLoading = true;
      }
    });

    setDealerUserData(dataDealerArray);
    setCancelDialog(!cancelDialog);
  };
  const handleRemoveClick = (id) => {
    removeDealerMutation.mutate({ email: id });
    const dataDealerArray = dealerUserData;
    dataDealerArray?.forEach((item) => {
      if (item?.email == id) {
        item.isCustomLoading = true;
      }
    });

    setDealerUserData(dataDealerArray);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (location.pathname === "/account/mgmt/accounts/add") {
      setIsEmployeeAccountsbtnClicked(true);
      setPrepopulateEditDealerUser();
      window.scrollTo(0, 0); 
    }
    if (location.pathname === "/account/mgmt/accounts/edit" && dealerUserData && searchParams?.get("account") && searchParams?.get("email")) {
      setIsEmployeeAccountsbtnClicked(true);
      const editResponse =
        dealerUserData &&
        dealerUserData?.filter((item) => {
          return item?.email == searchParams?.get("email");
        });
      setPrepopulateEditDealerUser(editResponse);
      setEditID(searchParams?.get("account"));
      window.scrollTo(0, 0);
    }
  }, [location, dealerUserData]);

  const AddEmployeeAccountBtnClick = () => {
    setIsEmployeeAccountsbtnClicked(true);
    setPrepopulateEditDealerUser();
    window.scrollTo(0, 0);
    navigate({
      pathname: "/account/mgmt/accounts/add",
    });
  };

  function addSpaceBeforeCapitalLetters(str) {
    return str.replace(/([A-Z])/g, " $1");
  }

  return (
    <>
      {mutation.isLoading && <Loader></Loader>}
      <IlmorModal
        isopen={cancelDialog}
        handleOkay={handleOkay}
        handleClose={handleCancel}
        message={commonMessages?.removeEmployeeAccountRow}
        messageDetail={"The user will be removed immediately."}
        secondMessage={"Any existing orders/lists will not be affected."}
        fisrtButton={"Cancel"}
        secondButton={"Delete"}
      ></IlmorModal>
      {isEmployeeAccountsbtnClicked ? (
        <>
          <AddEmployeeAccount
            editID={editID}
            setIsEmployeeAccountsbtnClicked={setIsEmployeeAccountsbtnClicked}
            prepopulateEditDealerUser={prepopulateEditDealerUser}
          />
        </>
      ) : (
        <>
          {currentDevice !== "Mobile" && currentDevice !== "Tablet" ? (
            <>
              {" "}
              <Grid
                className="erflmkhjkerfj777"
                container
                columnSpacing={3}
                sx={{ margin: "0 auto" }}
              >
                <Grid
                  container
                  columnSpacing={3}
                  rowSpacing={4}
                  sx={{
                    flexDirection: "column",
                    backgroundColor: "#EAEAEA",
                    margin: "0 auto ",
                    padding: "20px",
                  }}
                >
                  <Grid item sx={{}}>
                    <StyledShippingHeading className="erflmk3hjkerfj777">
                      {commonMessages?.employeeAccountTableHeading}
                    </StyledShippingHeading>
                  </Grid>
                  <Grid
                    item
                    sx={{}}
                    className={`erfghknj PhirMDesktopTopHeading`}
                  >
                    <StyledShippingSubHeading>
                      {commonMessages?.employeeAccountTableSubHeading}
                    </StyledShippingSubHeading>
                  </Grid>
                  <Grid className="erfghknj" item sm sx={{}}>
                    <StyledShippingSubHeading>
                      {commonMessages?.employeeAccountTableSecondSubHeading}
                    </StyledShippingSubHeading>
                  </Grid>
                </Grid>
                <div className={`EmpAcc_out`}>
                  <table className={`EmpAcc_outTable`}>
                    <thead>
                      <tr>
                        <th
                          style={{ paddingLeft: "54px", paddingRight: "10px" }}
                          width="15%"
                          className={`EmpAcc_TableHeading`}
                        >
                          Name
                        </th>
                        <th width="20%" className={`EmpAcc_TableHeading`}>
                          Contact Details
                        </th>
                        <th
                          width="10%"
                          className={`EmpAcc_TableHeading addDate`}
                        >
                          Add Date
                        </th>
                        <th width="20%" className={`EmpAcc_TableHeading Role`}>
                          Role
                        </th>
                        <th width="10%" className={`EmpAcc_TableHeading`}>
                          Status
                        </th>
                        {myProfile?.profileInfo?.permissions?.includes(
                          "manage_users"
                        ) && (
                          <th
                            width="15%"
                            className={`EmpAcc_TableHeading`}
                          ></th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {dealerUserData &&
                        dealerUserData?.length > 0 &&
                        dealerUserData?.map((row, index) => (
                          <tr
                            className={`EmpAcc_TableData${
                              index % 2 === 0 ? "EvenRow" : ""
                            }`}
                            key={index}
                          >
                            <td
                              style={{
                                paddingLeft: "54px",
                                // marginLeft: "23px",
                                paddingRight: "10px",
                              }}
                            >
                              <span
                                // style={{
                                // }}
                                className={`EmpAcc_TableData`}
                              >
                                {row.name}
                              </span>
                            </td>
                            <td>
                              <span className={`EmpAcc_TableData`}>
                                {row.email} {row.input3}
                              </span>
                            </td>
                            <td>
                              <span className={`EmpAcc_TableData`}>
                                {row.addDate}
                              </span>
                            </td>
                            <td>
                              <span className={`EmpAcc_TableData`}>
                                {addSpaceBeforeCapitalLetters(row.role)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`EmpAcc_TableData${
                                  row.editState ? " RowEdit" : ""
                                }`}
                              >
                                {row.status ? (
                                  <ActiveInactiveStyled status={true}>
                                    Active
                                  </ActiveInactiveStyled>
                                ) : (
                                  <ActiveInactiveStyled status={false}>
                                    Inactive
                                  </ActiveInactiveStyled>
                                )}
                              </span>
                            </td>
                            {myProfile?.profileInfo?.permissions?.includes(
                              "manage_users"
                            ) && (
                              <td>
                                <span className={`EmpAcc_TableData`}>
                                  <Button
                                    sx={{
                                      minWidth: "unset",

                                      textTransform: "none",
                                      color: "#CE0E2D",
                                      textAlign: "center",
                                      fontFamily: "Montserrat",
                                      fontSize: "13px",
                                      fontWeight: "500",
                                      textDecoration: "underline",
                                      padding: 0.5,
                                    }}
                                    onClick={() => {
                                      handleCancel(row?.email);
                                      // handleRemoveClick(row?.email);
                                    }}
                                  >
                                    {row?.isCustomLoading ? (
                                      <CircularProgress
                                        style={{ color: "#CE0E2D" }}
                                        size={20}
                                      />
                                    ) : (
                                      "Remove"
                                    )}
                                  </Button>
                                  <span
                                    className={`orderHisTableButtonBar`}
                                  >{` | `}</span>
                                  {/* <button
                                onClick={() => {
                                  handleEditClick(row?.email);
                                }}
                              >
                                edit
                              </button> */}
                                  <Button
                                    className={`ShippingAddressEditButton`}
                                    sx={{
                                      minWidth: "unset",

                                      textTransform: "none",
                                      color: "#CE0E2D",
                                      textAlign: "center",
                                      fontFamily: "Montserrat",
                                      fontSize: "13px",
                                      fontWeight: "500",
                                      textDecoration: "underline",
                                      padding: 0.5,
                                    }}
                                    onClick={() => {
                                      handleEditClick(row?.email, row?.id);
                                    }}
                                  >
                                    Edit
                                  </Button>

                                  {/* <button
                                onClick={() => {
                                  handleRemoveClick(row?.email);
                                }}
                              >
                                remove
                              </button> */}
                                </span>
                              </td>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </table>

                  <Box
                    sx={{
                      padding:
                        currentDevice != "Desktop"
                          ? "10px"
                          : " 20px 44px 100px 49px",
                    }}
                  >
                    {myProfile?.profileInfo?.permissions?.includes(
                      "manage_users"
                    ) && (
                      <AddEmployeeAccountBtnStyled
                        onClick={() => AddEmployeeAccountBtnClick()}
                      >
                        <span>
                          {" "}
                          <PlusIcon />
                          {commonMessages?.addEmployeeAcntbtn}
                        </span>
                      </AddEmployeeAccountBtnStyled>
                    )}
                  </Box>
                </div>{" "}
              </Grid>
            </>
          ) : (
            <>
              <Box
                sx={{
                  background: "#eaeaea",
                  width: "100%",
                }}
              >
                <StyledShippingHeading
                  sx={{
                    padding:
                      currentDevice == "Tablet"
                        ? "24px 28px"
                        : currentDevice == "Mobile"
                        ? "24px 20px"
                        : "60px 60px 0px 60px ",
                  }}
                  currentDevice={currentDevice}
                >
                  Employee Accounts
                </StyledShippingHeading>
                {currentDevice == "Tablet" && (
                  <TabletViewBlackContainer currentDevice={currentDevice} />
                )}
                <ContainerDivStyled currentDevice={currentDevice}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      <Grid
                        container
                        spacing={1}
                        rowSpacing={3}
                        pt={currentDevice == "Tablet" ? 3 : 0}
                      >
                        <Grid
                          item
                          sx={{
                            paddingTop: "0 !important",
                          }}
                        >
                          <div className="padbotaccout">
                            <StyledShippingSubHeading>
                              Accounts are listed below. Edit an account to
                              change details or inactivate.
                              <br />
                              <br />
                              Edit your own information on the Profile
                              Information page.
                            </StyledShippingSubHeading>
                          </div>
                        </Grid>
                        {dealerUserData &&
                          dealerUserData?.length > 0 &&
                          dealerUserData?.map((item, index) => {
                            return (
                              <Grid
                                className="notoppad"
                                item
                                md={4}
                                xs={12}
                                sm={12}
                                sx={{}}
                              >
                                <Card
                                  className={
                                    index % 2 === 0
                                      ? "sdfsdrwewererw evenBackgroundEmployee"
                                      : "sdfsdrwewererw oddBackgroundEmployee"
                                  }
                                  sx={{ padding: 4 }}
                                >
                                  <Grid
                                    container
                                    sx={{
                                      justifyContent: "center",
                                      alignItems: "center",
                                      "& > div": {
                                        flex:
                                          currentDevice == "Tablet"
                                            ? "1"
                                            : "100%",
                                      },
                                    }}
                                  >
                                    <Grid
                                      className="employeeData"
                                      item
                                      sm={8}
                                      sx={{}}
                                    >
                                      <CardHeadingStyled>
                                        {item?.name}
                                      </CardHeadingStyled>
                                      <CardContentStyled>
                                        {item?.email}
                                      </CardContentStyled>
                                      <CardContentStyled>
                                        {item?.phone}
                                      </CardContentStyled>
                                      <Grid
                                        container
                                        columnSpacing={3}
                                        rowSpacing={2}
                                        sx={{ marginTop: 2 }}
                                      >
                                        <Grid item sx={{}}>
                                          <CardHeadingStyled>
                                            Add Date
                                          </CardHeadingStyled>
                                          <CardContentStyled>
                                            {item.addDate}
                                          </CardContentStyled>
                                        </Grid>
                                        <Grid item sx={{}}>
                                          <CardHeadingStyled>
                                            Role{" "}
                                          </CardHeadingStyled>
                                          <CardContentStyled>
                                            {addSpaceBeforeCapitalLetters(
                                              item?.role
                                            )}
                                          </CardContentStyled>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    {/* <Grid
                                item
                                sm={4}
                                sx={{
                                  // paddingLeft: "2px",
                                
                                  "& .css-14ajgnd-MuiGrid-root>.MuiGrid-item": {
                                    paddingLeft: "3px",
                                  },
                                }}
                              > */}
                                    <Grid
                                      container
                                      columnSpacing={3}
                                      sx={{
                                        justifyContent:
                                          currentDevice == "Mobile"
                                            ? "flex-start"
                                            : "center",
                                        alignItems: "center",
                                        marginTop:
                                          currentDevice == "Mobile"
                                            ? "20px"
                                            : "0px",
                                      }}
                                    >
                                      <Grid item sx={{}}>
                                        {item?.status ? (
                                          <ActiveInactiveStyled
                                            sx={{
                                              "& .MuiSlider-thumb": {
                                                // borderRadius: "1px",
                                              },
                                            }}
                                            status={item?.status}
                                          >
                                            Active
                                          </ActiveInactiveStyled>
                                        ) : (
                                          <ActiveInactiveStyled
                                            status={item?.status}
                                          >
                                            InActive
                                          </ActiveInactiveStyled>
                                        )}
                                      </Grid>
                                      {myProfile?.profileInfo?.permissions?.includes(
                                        "manage_users"
                                      ) && (
                                        <Grid item sx={{}}>
                                          <Button
                                            className={`ShippingAddressEditButton`}
                                            sx={{
                                              minWidth: "unset",

                                              textTransform: "none",
                                              color: "red",
                                              textAlign: "center",
                                              fontFamily: "Montserrat",
                                              fontSize: "13px",
                                              fontWeight: "500",
                                              textDecoration: "underline",
                                              padding: 0.5,
                                            }}
                                            onClick={() => {
                                              handleEditClick(
                                                item?.email,
                                                item?.id
                                              );
                                            }}
                                          >
                                            Edit
                                          </Button>
                                        </Grid>
                                      )}
                                      {myProfile?.profileInfo?.permissions?.includes(
                                        "manage_users"
                                      ) && <span className="sepOrder">|</span>}
                                      {myProfile?.profileInfo?.permissions?.includes(
                                        "manage_users"
                                      ) && (
                                        <Grid item sx={{}}>
                                          <Button
                                            sx={{
                                              minWidth: "unset",

                                              textTransform: "none",
                                              color: "red",
                                              textAlign: "center",
                                              fontFamily: "Montserrat",
                                              fontSize: "13px",
                                              fontWeight: "500",
                                              textDecoration: "underline",
                                              padding: 0.5,
                                            }}
                                            onClick={() => {
                                              handleCancel(item?.email);
                                              // handleRemoveClick(row?.email);
                                            }}
                                          >
                                            Remove
                                          </Button>
                                        </Grid>
                                      )}
                                    </Grid>
                                    {/* </Grid> */}
                                  </Grid>
                                </Card>
                              </Grid>
                            );
                          })}
                        {myProfile?.profileInfo?.role === "Admin" && (
                          <Grid
                            container
                            className="padbtomog"
                            sx={{
                              marginTop: "20px",
                              justifyContent: "flex-end",
                            }}
                          >
                            <AddEmployeeAccountBtnStyled
                              onClick={() => AddEmployeeAccountBtnClick()}
                            >
                              + Add Employee Account
                            </AddEmployeeAccountBtnStyled>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box>
                    {currentDevice == "Mobile" && (
                      <TabletViewBlackContainer currentDevice={currentDevice} />
                    )}
                  </Box>
                </ContainerDivStyled>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};
