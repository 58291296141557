import { create } from "zustand";
import zukeeper from "zukeeper";

export const useNonPersistedStore = create(
  zukeeper((set) => ({
    text: "adil",
    setText: (payload) => set({ text: payload }),
    cardEditId: "",
    setCardEditId: (cardEditId) => set({ cardEditId: cardEditId }),
    editPasswordBtnClick: false,
    setEditPasswordBtnClick: (payload) =>
      set({ editPasswordBtnClick: payload }),
    editEmailBtnClick: false,
    setEditEmailBtnClick: (payload) => set({ editEmailBtnClick: payload }),
    useThisAddress: false,
    setUseThisAddress: (payload) => set({ useThisAddress: payload }),
    shippinPaymentAddressUpdated: {},
    setShippingPaymentAddressUpdated: (payload) =>
      set({ shippinPaymentAddressUpdated: payload }),
    tempSummary: false,
    setTempSummary: (tempSummary) => set({ tempSummary: tempSummary }),
    chooseCard: false,
    setChooseCard: (chooseCard) => set({ chooseCard: chooseCard }),
    shipDis: false,
    setShipDis: (shipDis) => set({ shipDis: shipDis }),
    chooseCardId: false,
    setChooseCardId: (chooseCardId) => set({ chooseCardId: chooseCardId }),
    pof: false,
    setPof: (pof) => set({ pof: pof }),
    isSLow: false,
    setLow: (isSLow) => set({ isSLow: isSLow }),
    svgData: false,
    setSvgData: (svgData) => set({ svgData: svgData }),
    isLT: true,
    setLT: (isLT) => set({ isLT: isLT }),
    refreshQ: true,
    setRefreshQ: (refreshQ) => set({ refreshQ: refreshQ }),
    svgDataOriginalL2: false,
    setSvgDataOriginalL2: (svgDataOriginalL2) =>
      set({ svgDataOriginalL2: svgDataOriginalL2 }),
    partListL2: false,
    setPartListL2: (partListL2) => set({ partListL2: partListL2 }),
    notLast: true,
    setNotLast: (notLast) => set({ notLast: notLast }),
    selectAdd: false,
    setselectAdd: (selectAdd) => set({ selectAdd: selectAdd }),
    mod: true,
    setMod: (mod) => set({ mod: mod }),
    noPartLoad: false,
    setnoPartLoad: (noPartLoad) => set({ noPartLoad: noPartLoad }),
    partList: false,
    setPartList: (partList) => set({ partList: partList }),
    reason: "",
    setReason: (reason) => set({ reason: reason }),
    selectedCategory: "",
    setSelectedCategory: (selectedCategory) =>
      set({ selectedCategory: selectedCategory }),
    allCards: false,
    getAllCardsArray: (allCards) => set({ allCards: allCards }),
    failShip: false,
    setfailShip: (failShip) => set({ failShip: failShip }),
    failMandate: false,
    setfailMandate: (failMandate) => set({ failMandate: failMandate }),
    quant: false,
    setquant: (quant) => set({ quant: quant }),
    dis: false,
    setdis: (dis) => set({ dis: dis }),
    dis1: false,
    setdis1: (dis) => set({ dis: dis }),
    dis2: false,
    setdis2: (dis) => set({ dis: dis }),
    dis3: false,
    setdis3: (dis) => set({ dis: dis }),
    dis4: false,
    setdis4: (dis) => set({ dis: dis }),
    change: true,
    setChange: (change) => set({ change: change }),
    lowest: "",
    setLowest: (lowest) => set({ lowest: lowest }),
    start: 0,
    setstart: (start) => set({ start: start }),
    dropFee: 0,
    setdropFee: (dropFee) => set({ dropFee: dropFee }),
    dropFeestate: false,
    setdropFeestate: (dropFeestate) => set({ dropFeestate: dropFeestate }),
    load: false,
    setLoad: (load) => set({ load: load }),
    cardLoad: false,
    setCardLoad: (cardLoad) => set({ cardLoad: cardLoad }),
    loadcart: false,
    setLoadCArt: (loadcart) => set({ loadcart: loadcart }),
    cload: false,
    setCload: (cload) => set({ cload: cload }),
    viaLink: true,
    setViaLink: (viaLink) => set({ viaLink: viaLink }),
    loadcart1: false,
    setLoadCArt1: (loadcart1) => set({ loadcart1: loadcart1 }),
    headLoad: false,
    setHeadLoad: (headLoad) => set({ headLoad: headLoad }),
    start1: 0,
    setstart1: (start1) => set({ start1: start1 }),
    box: 1,
    setBox: (box) => set({ box: box }),
    boxPage: 0,
    setBoxPage: (boxPage) => set({ boxPage: boxPage }),
    isDomesticOption: false,
    setDomesticOption: (isDomesticOption) =>
      set({ isDomesticOption: isDomesticOption }),
  }))
);
