import React, { useState, useEffect } from "react";
import "./TileList.css";
import { useStore } from "../store/home";
import NumericInput from "react-numeric-input";
import { addCommaDecimal, getCurrentDate, lowerBoy } from "../commonFunction";
import { ReactComponent as GreyShopList } from "../Images/greyShopList.svg";
import { ReactComponent as RedCart } from "../Images/redCart.svg";
import loaderImg from "../Images/loaderImg.gif";
import place from "../Images/placeHolder.png";
import { useLocation, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { ReactComponent as Checkmark } from "../Images/checkMark.svg";
import { useMutation } from "@tanstack/react-query";
import { ReactComponent as Exclamation } from "../Images/notCompatible.svg";
import { toast } from "react-toastify";
import { addToCart, getMyCart, updateWishList } from "../api/posts";
import { showErrorToast } from "../utils/Toaster";
import { AddEmployeeAccountBtnStyled } from "./StyledComponents/EmployeeAccountStyled.js";
import Box from "@mui/material/Box";
import { Grid } from "@material-ui/core";

const TileListEngine = ({ item }) => {
  const {
    token,
    userInfo,
    myProfile,
    isCurrentEngSet,
    setMyCartMini,
    currentDevice,
    dealerId,
  } = useStore();
  const location = useLocation();
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const handleInputChangeSelect = (event) => {
    setQuantity(event);
  };

  const mutation_cart = useMutation((post) => getMyCart(post, token), {
    onSuccess: (result) => {
      setMyCartMini(result);
    },
    onError: (error) => {},
  });
  // useEffect(() => {
  //   const obj = {
  //     email: userInfo.username,
  //     dealerId: dealerId,
  //     minicart: true,
  //   };
  //   mutation_cart.mutate(obj);
  // }, []);

  const notify = () => toast.success("Product added to cart");
  const notifyShop = () => toast.success("Product added to Shopping list");
  const mutation = useMutation((post) => updateWishList(post, token), {
    onSuccess: (result) => {
      notifyShop();
    },
    onError: (error) => {},
  });

  const mutationCart = useMutation((post) => addToCart(post, token), {
    onSuccess: (result) => {
      if (result.data.success) {
        notify();
        const obj = {
          email: userInfo.username,
          dealerId: dealerId,
          minicart: true,
        };
        mutation_cart.mutate(obj);
      } else {
        showErrorToast(result?.data?.msg);
      }
    },
    onError: (error) => {
      showErrorToast("Internal Server Error");
    },
  });

  const handleProductClick = (id) => {
    navigate({
      pathname: "/part",
      search: `number=${id}`,
    });
  };

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const handleLoad = () => {
    setIsLoading(false);
  };

  const handleError = () => {
    setHasError(true);
    setIsLoading(false);
  };

  const handleAddToShoppingList = () => {
    const arr1 = [];
    if (item?.additionalInfoFlag) {
      item.additionalInfo.forEach((item) => {
        const obj = {};
        obj.title = item;
        obj.value = "";
        arr1.push(obj);
      });
    }
    const arr = {
      partId: item.partId,
      quantity: quantity <=1 ? 1 : quantity,
      isPartDetail: false,
      date: getCurrentDate(),
      notes: "",
      other_notes: arr1,
    };
    mutation.mutate(arr);
  };

  const handleAddToCart = () => {
    const arr1 = [];
    if (item?.additionalInfoFlag) {
      item.additionalInfo.forEach((item) => {
        const obj = {};
        obj.title = item;
        obj.value = "";
        arr1.push(obj);
      });
    }
    const arr = {
      partId: item.partId,
      quantity: quantity <=1 ? 1 : quantity,
      // date: getCurrentDate(),
      notes: "",
      otherNotes: arr1,
    };
    let obj = [];
    obj.push(arr);
    let data = {
      email: userInfo?.username,
      update: false,
      isPartDetail: false,
      items: obj,
    };
    mutationCart.mutate(data);
  };

  return (
    <div id={lowerBoy(item.partNumber)}>
      {currentDevice === "Mobile" ? (
        <Grid
          style={{
            borderTop: "0.5px solid rgba(97, 94, 92, 0.25)",
            padding: "0px 10px 12px 20px",
          }}
          container
        >
          <Grid item xs={12}>
            <div
              onClick={() => {
                handleProductClick(item.partNumber);
              }}
            >
              <div id="child" className={`cardList__imageDivDesktop  marImage`}>
                <div className="numberSeq">
                <div className="seqNum">
                  <div className="innerdicseq" >{item.sequenceNumber}</div>
                </div>
                  {isLoading && (
                    <div className="loaderDiv center">
                      <img
                        className={`cardList__imageDesktop imgLoad`}
                        src={loaderImg}
                        alt="Loading..."
                      />
                    </div>
                  )}
                  <img
                    className={`cardList__imageDesktop `}
                    onLoad={handleLoad}
                    onError={handleError}
                    style={{
                      display: isLoading || hasError ? "none" : "block",
                    }}
                    src={item?.images ? item?.images : place}
                    alt={item.partName || "Ilmor Part Image"}
                  />
                  {hasError && (
                    <img
                      className={`cardList__imageDesktop`}
                      // onLoad={handleLoad}
                      // onError={handleError}
                      src={place}
                      alt={item.partName || "Ilmor Part Image"}
                    />
                  )}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="csrdListDetail">
              <span
                className={`cardList__newTextDesktop  ${
                  item.productLabel ? "" : " dpHidden"
                }`}
              >{`${item.productLabel}`}</span>
              <div>
                <span className="cardListheadingText">{`${item.partNumber}`}</span>
                <span className="cardListbackordered dfgdfrgegr">
                  {item?.productBackOrder &&
                  !item.isBasePart &&
                  item?.inventory <= item?.inStockQuantity
                    ? "Backordered"
                    : ""}
                </span>
                <span className="cardListheadigSubText">
                  {/* (Replaces 93914-12) */}
                </span>
              </div>
              <span className="cardListsubheadingText">{`${item.partName}`}</span>
            </div>
          </Grid>
          <Grid
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
            item
            xs={12}
          >
            {myProfile?.profileInfo?.permissions?.includes(
              "add_to_own_cart"
            ) && (
              <div className="cardListpriceDetail noShift">
                {item?.isBasePart &&
                myProfile?.profileInfo?.permissions?.includes(
                  "add_to_own_cart"
                ) ? (
                  <span style={{ fontWeight: 700, marginRight: "10px" }}>
                    From:{" "}
                  </span>
                ) : (
                  <></>
                )}{" "}
                {myProfile?.profileInfo?.permissions?.includes(
                  "add_to_own_cart"
                ) && (
                  <>
                    <span className="cardListprice">{`$${
                      item?.productEffectiveCost &&
                      addCommaDecimal(item?.productEffectiveCost)
                    }`}</span>
                    {item?.productOrigionalCost !==
                      item?.productEffectiveCost && (
                      <span className="cardListdiscountedPrice">{`$${
                        item.productOrigionalCost && addCommaDecimal(item?.productOrigionalCost) &&
                        addCommaDecimal(item?.productOrigionalCost)
                      }`}</span>
                    )}
                  </>
                )}
              </div>
            )}
            <div className={`cardListbottomBoxDesktop partimagesidepad`}>
              {item?.isBasePart ? (
                <>
                  {!dealerId && (
                    <>
                      {" "}
                      <AddEmployeeAccountBtnStyled
                        sx={{ marginLeft: "20px", minWidth: "155px" }}
                        onClick={() => {
                          handleProductClick(item?.partNumber);
                        }}
                      >
                        Select Options
                      </AddEmployeeAccountBtnStyled>
                    </>
                  )}
                </>
              ) : (
                <>
                  {!dealerId && (
                    <>
                      <div
                        className={`cardListbottomBoxDesktop someBorder partimagesidepad`}
                      >
                        <div className="inputBoxNUmber mobi">
                          <NumericInput
                            className={`quickOrderTableQuantityDesktop noborlight`}
                            mobile={false}
                            strict
                            max={999}
                            value={quantity <= 0 ? '' : quantity}
                            // min={1}
                            onChange={handleInputChangeSelect}
                            onBlur={(event) => {
                              if (event.target.value == "" || event.target.value <= 0) {
                                handleInputChangeSelect(1);
                              }
                            }}
                            // value={quantity}
                          />
                        </div>
                        <div className="iconWrapper center bgForCart">
                          {mutation.isLoading ? (
                            <CircularProgress
                              style={{ color: "white" }}
                              size={20}
                            />
                          ) : (
                            <GreyShopList
                              onClick={handleAddToShoppingList}
                              className="GreyShopList icon"
                            ></GreyShopList>
                          )}
                        </div>
                        {myProfile?.profileInfo?.permissions?.includes(
                          "add_to_own_cart"
                        ) && (
                          <div className="iconWrapper center noRightMargin bgForCartRed">
                            {mutationCart.isLoading ? (
                              <CircularProgress
                                style={{ color: "white" }}
                                size={20}
                              />
                            ) : (
                              <RedCart
                                onClick={handleAddToCart}
                                className="GreyShopList icon"
                              ></RedCart>
                            )}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </Grid>
        </Grid>
      ) : (
        <div className={`cardListParentDesktop`}>
          <div
            onClick={() => {
              handleProductClick(item.partNumber);
            }}
            className="cardListLesftSide"
          >
            <div
              id="child"
              className={`cardList__imageDivDesktop tabPaddinless  marImage`}
            >
              <div className="numberSeq">
                <div className="seqNum">
                  <div className="innerdicseq">{item.sequenceNumber}</div>
                </div>
                {isLoading && (
                  <div className="loaderDiv center">
                    <img
                      className={`cardList__imageDesktop imgLoad`}
                      src={loaderImg}
                      alt="Loading..."
                    />
                  </div>
                )}
                <img
                  className={`cardList__imageDesktop oidjrgoild`}
                  onLoad={handleLoad}
                  onError={handleError}
                  style={{ display: isLoading || hasError ? "none" : "block" }}
                  src={item?.images ? item?.images : place}
                  alt={item.partName || "Ilmor Part Image"}
                />
                {hasError && (
                  <img
                    className={`cardList__imageDesktop`}
                    // onLoad={handleLoad}
                    // onError={handleError}
                    src={place}
                    alt={item.partName || "Ilmor Part Image"}
                  />
                )}
              </div>
            </div>
            <div className="csrdListDetail">
              <span
                className={`cardList__newTextDesktop  ${
                  item.productLabel ? "" : " dpHidden"
                }`}
              >{`${item.productLabel}`}</span>
              <div>
                <span className="cardListheadingText">{`${item.partNumber}`}</span>
                <span className="cardListbackordered dfgdfrgegr">
                  {" "}
                  {item?.productBackOrder &&
                  !item.isBasePart &&
                  item?.inventory <= item?.inStockQuantity
                    ? "Backordered"
                    : ""}
                </span>
                <span className="cardListheadigSubText">
                  {/* (Replaces 93914-12) */}
                </span>
              </div>
              <span className="cardListsubheadingText">{`${item.partName}`}</span>
            </div>
          </div>
          <div className="cardListRightSide">
            {item?.isSupercedeDiscontinue?.status && (
              <Box
                sx={{
                  fontWeight: "700",
                  fontSize: "12px",
                  display: "flex",
                }}
              >
                {`${"This part is a replacement for "} `}
                <div className="divSku">
                  {item?.isSupercedeDiscontinue?.replacementPartNumber &&
                    item?.isSupercedeDiscontinue?.replacementPartNumber.length >
                      0 &&
                    item?.isSupercedeDiscontinue?.replacementPartNumber.map(
                      (itemName, i) => (
                        <>
                          <span
                            style={{ color: "#CE0E2D" }}
                            key={itemName}
                          >{`${itemName}`}</span>
                          {i !==
                            item?.isSupercedeDiscontinue?.replacementPartNumber
                              .length -
                              1 && <span>{`, `}</span>}
                        </>
                      )
                    )}
                </div>
              </Box>
            )}
            <div className="cardListpriceDetail">
              {item?.isBasePart &&
              myProfile?.profileInfo?.permissions?.includes(
                "add_to_own_cart"
              ) ? (
                <span style={{ fontWeight: 700, marginRight: "10px" }}>
                  From:{" "}
                </span>
              ) : (
                <></>
              )}{" "}
              {myProfile?.profileInfo?.permissions?.includes(
                "add_to_own_cart"
              ) && (
                <>
                  <span className="cardListprice">{`$${
                    item?.productEffectiveCost &&
                    addCommaDecimal(item?.productEffectiveCost)
                  }`}</span>
                  {item?.productOrigionalCost !==
                    item?.productEffectiveCost && (
                    <span className="cardListdiscountedPrice">{`$${
                      item.productOrigionalCost && addCommaDecimal(item?.productOrigionalCost) &&
                      addCommaDecimal(item?.productOrigionalCost)
                    }`}</span>
                  )}
                </>
              )}
            </div>
            {item?.isBasePart ? (
              <>
                {!dealerId && (
                  <>
                    {" "}
                    <AddEmployeeAccountBtnStyled
                      sx={{ marginLeft: "20px", minWidth: "155px" }}
                      onClick={() => {
                        handleProductClick(item?.partNumber);
                      }}
                    >
                      Select Options
                    </AddEmployeeAccountBtnStyled>
                  </>
                )}
              </>
            ) : (
              <>
                {!dealerId && (
                  <>
                    <div className={`cardListbottomBoxDesktop someBorder`}>
                      <div className="inputBoxNUmber">
                        <NumericInput
                          className={`quickOrderTableQuantityDesktop noborlight fontforSHOp`}
                          mobile={false}
                          strict
                          max={999}
                          value={quantity <= 0 ? '' : quantity}
                          // min={1}
                          onChange={handleInputChangeSelect}
                          onBlur={(event) => {
                            if (event.target.value == "" || event.target.value <= 0) {
                              handleInputChangeSelect(1);
                            }
                          }}
                          // value={quantity}
                        />
                      </div>
                      <div className="iconWrapper center bgForCart">
                        {mutation.isLoading ? (
                          <CircularProgress
                            style={{ color: "white" }}
                            size={20}
                          />
                        ) : (
                          <GreyShopList
                            onClick={handleAddToShoppingList}
                            className="GreyShopList icon"
                          ></GreyShopList>
                        )}
                      </div>
                      {myProfile?.profileInfo?.permissions?.includes(
                        "add_to_own_cart"
                      ) && (
                        <div className="iconWrapper center noRightMargin bgForCartRed">
                          {mutationCart.isLoading ? (
                            <CircularProgress
                              style={{ color: "white" }}
                              size={20}
                            />
                          ) : (
                            <RedCart
                              onClick={handleAddToCart}
                              className="GreyShopList icon"
                            ></RedCart>
                          )}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </>
            )}

            {isCurrentEngSet?.engineId &&
              location?.pathname === "/search" && (
                <Box
                  sx={{
                    color: item?.isEngineCompartible ? "#40A900" : "#CE0E2D;",
                    position: "absolute",
                    top: "60px",
                    right: "0",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "12px",
                    fontWeight: "700",
                  }}
                >
                  {item?.isEngineCompartible ? (
                    <>
                      {" "}
                      <Checkmark className={`fillGreen`}></Checkmark>
                      <>Compatible with current engine</>
                    </>
                  ) : (
                    <>
                      <Exclamation className="fillRedNonCompatible"></Exclamation>
                      <>Not compatible with current engine</>
                    </>
                  )}
                </Box>
              )}
            {/* </Box> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default TileListEngine;
