

import { styled, Typography } from "@mui/material";

export const StyledShippingHeading = styled(Typography)(
  ({ theme, currentDevice }) => ({
    fontFamily: "Montserrat",
    fontSize: currentDevice == "Mobile" ? "24px" : "40px",
    // fontSize: 40px,
    fontStyle: "normal",
    fontWeight: "700",

    textTransform: "uppercase",
  })
);

export const StyledShippingHeadingEngine = styled(Typography)(
  ({ theme, currentDevice }) => ({
    fontFamily: "Montserrat",
    fontSize: currentDevice === "Desktop" ? `40px !important` : "",
    fontSize: currentDevice === "Tablet" ? `30px !important` : "",
    fontSize: currentDevice === "Mobile" ? `24px !important` : "",
    // fontSize: "40px",
    fontStyle: "normal",
    fontWeight: "700",

    textTransform: "uppercase",
  })
);

export const StyledShippingSubHeading = styled(Typography)({
    /* Display lg/Bold */
    fontFamily: "Montserrat",
    fontSize: "16px",
  
    fontStyle: "normal",
    fontWeight: "500",
    
  
    
  });
  
  export const StyledRequiredFields = styled(Typography)({
color: 'var(--brand-colors-black, #07080C)',

/* Text sm/Medium */
fontFamily: 'Montserrat',
fontSize: '13px',
fontStyle: 'normal',
fontWeight: '500',
lineHeight: '18px',
    
  
    
  });
 export const CardContentStyled = styled(Typography)({
    /* Display lg/Bold */
    fontFamily: "Montserrat",
    fontSize: "14px",
    
    fontStyle: "normal",
    fontWeight: "500",
  
    
  });
  export const CardHeadingStyled = styled(Typography)({
    /* Display lg/Bold */
    fontFamily: "Montserrat",
    fontSize: "14px",
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: "700",
  
    
  });

  export const StyledRequiredField = styled(Typography)({
    /* Display lg/Bold */
    fontFamily: "Montserrat",
    fontSize: "13px",
    // fontSize: 40px,
    fontStyle: "normal",
    fontWeight: "500",
  
    // textTransform: "uppercase",
    "&::before": {
      content: '"*"',
      color: "red",
      marginRight:"2.3px"
    },
  });