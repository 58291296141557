import React, { useState } from "react";
import { useStore } from "../store/home";
import "./Banner.css";
import coolant from "../Images/coolant.png";
import banner from "../Images/Blacksilk.jpg";
import Button from "@mui/material/Button";
import { useQuery } from "@tanstack/react-query";
import { getGetAllBanners } from "../api/posts";
import { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./Header";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../commenComp/Loader";

const Banner = () => {
  const { currentDevice, setSelectedCategory,banners,setLastPage, setSelectedSubCategory } =
    useStore();
  // const [token, setToken] = useState(localStorage.getItem("token"));
  // const bannerData = useQuery({
  //   queryKey: ["getGetAllBanners"],
  //   retry: 2,
  //   refetchOnWindowFocus: false,
  //   queryFn: () => getGetAllBanners(token),
  // });

  // const [banners, setBanners] = useState(false);
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (bannerData?.data?.data.data.length > 0) {
  //     setBanners(bannerData?.data?.data.data);
  //   }
  // }, [bannerData?.data?.data.data]);

  // useEffect(() => {
  //   if (bannerData?.error?.message.includes("401")) {
  //   setLastPage(false)
  //   localStorage.removeItem("token");
  //     localStorage.removeItem("my-persisted-store");
  //   }
  // }, [bannerData?.error]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const handleProductClick = (type, id) => {
    if (type === "category") {
      setSelectedCategory(parseInt(id));
      setSelectedSubCategory("");
      navigate({
        pathname: "/catalog",
      });
    } else if (type === "part") {
      navigate({
        pathname: "/part",
        search: `number=${id}`,
      });
    } else if (type === "externallink") {
      window.location.replace(id);
    }
  };

  return (
    <>
      {/* {bannerData.isFetching && <Loader></Loader>} */}
      <Header no={true}></Header>
      <Slider {...settings}>
        {banners &&
          banners?.map((item) => {
            return (
              <div key="23" className="bannerMain">
                <div
                  style={{
                    backgroundImage: `url("${item.BannerBackground}")`,
                    backgroundSize: "cover",
                    height: "503px",
                    backgroundPosition: "center",
                  }}
                  className="banner"
                >
                  <div className="bannerImageDiv">
                    <img
                      className={`banner__image${currentDevice}`}
                      src={item.ProductBannerImage}
                      alt="Product Image"
                    />
                  </div>
                  <div className={`banner__text__section${currentDevice}`}>
                    <span className={`banner__h6${currentDevice}`}>
                      {item.productTitle}
                    </span>
                    <span dangerouslySetInnerHTML={{  __html: item.productDescription}} className={`banner__h1${currentDevice}`}>
                      {/* {item.productDescription} */}
                      
                    </span>
                    {/* <span className={`banner__h1${currentDevice}`}>{item.productDescription} </span> */}
                    {item.buttonName && item.buttonLinkType && (
                      <button
                        onClick={() => {
                          handleProductClick(
                            item.buttonLinkType,
                            item.buttonLink
                          );
                        }}
                        style={{ backgroundColor:  item.buttonColor.hex }}
                        className={`banner__button${currentDevice}`}
                      >
                        {item.buttonName}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </Slider>
    </>
  );
};

export default Banner;
