import UpdatePassword from "../components/UpdatePassword";
import Home from "../components/Home";
import Catalog from "../components/Catalog";
import ShopCart from "../components/ShopCart";
import EmployeeAccounts from "../components/EmployeeAccounts";
import AddEmployeeAccount from "../components/AddEmployeeAccount";
import DeleteUser from "../components/DeleteUser";
import PartsDetail from "../components/PartsDetail";
import QuickOrderForm from "../components/QuickOrderForm";
import ShoppingList from "../components/ShoppingList";
import Checkout from "../components/Checkout";
import AddToCartPopUp from "../components/AddToCartPopUp";
import MyProfile from "../components/MyProfile";
import OrdeComplete from "../components/OrdeComplete";
import MyAccount from "../components/MyAccount";
import SearchResults from "../components/SearchResults";
import YourCart from "../components/YourCart";
import CatalogPage from "../components/CatalogPage"
import ShippingPolicy from "../components/ShippingPolicy/ShippingPolicy";
import ReturnExchange from "../components/ReturnExchange/ReturnExchange";
import ShopEngine from "../components/ShopEngine/ShopEngine";
import ContactUs from "../components/ContactUs/ContactUs";
import FooterContent from "../components/FooterContent/FooterContent";


export const routes = [
  {
    link: "/",
    title: "Home",
    element: <Home />,
  },

  {
    link: "/catalog",
    title: "catalog",
    element: <Catalog />,
  },

  {
    link: "/update-password",
    title: "Update Password",
    element: <UpdatePassword />,
  },
  {
    link: "/part",
    title: "Parts Detail",
    element: <PartsDetail/>,
  },
  {
    link: "/quick-order-form",
    title: "Quick Order Form",
    element: <QuickOrderForm/>,
  },
  {
    link: "/shopping-list",
    title: "Shppping List",
    element: <ShoppingList/>,
  },
  {
    link: "/checkout",
    title: "Checkout",
    element: <Checkout/>,
  },
    {
    link: "/orderSuccess",
    title: "OrderSuccess",
    element: <OrdeComplete/>,
  },
  {
    link: "/addtocart",
    title: "AddToCart",
    element: <AddToCartPopUp/>,
  },
  {
    link: "/cart",
    title: "Your Cart",
    element: <YourCart/>,
  },

  {
    link: "/myProfile",
    title: "MyProfile",
    element: <MyProfile/>,
  },
  {
    link: "/account",
    title: "MyProfile",
    element: <MyAccount/>,
  },
  {
    link: "/account/orders",
    title: "MyProfile",
    element: <MyAccount/>,
  },
  {
    link: "/account/order-detail",
    title: "MyProfile",
    element: <MyAccount/>,
  },
  {
    link: "/account/mgmt/accounts",
    title: "MyProfile",
    element: <MyAccount/>,
  },
  {
    link: "/account/mgmt/accounts/add",
    title: "MyProfile",
    element: <MyAccount/>,
  },
  {
    link: "/account/mgmt/accounts/edit",
    title: "MyProfile",
    element: <MyAccount/>,
  },
  {
    link: "/account/mgmt/business",
    title: "MyProfile",
    element: <MyAccount/>,
  },
  {
    link: "/account/mgmt/shipping",
    title: "MyProfile",
    element: <MyAccount/>,
  },
  {
    link: "/account/mgmt/shipping/add",
    title: "MyProfile",
    element: <MyAccount/>,
  },
  {
    link: "/account/mgmt/shipping/edit",
    title: "MyProfile",
    element: <MyAccount/>,
  },
  {
    link: "/account/mgmt/bussiness/edit",
    title: "MyProfile",
    element: <MyAccount/>,
  },
  {
    link: "/account/mgmt/signinfo",
    title: "MyProfile",
    element: <MyAccount/>,
  },
  {
    link: "/account/mgmt/payment",
    title: "MyProfile",
    element: <MyAccount/>,
  },
  {
    link: "/account/mgmt/payment/add",
    title: "MyProfile",
    element: <MyAccount/>,
  },
  {
    link: "/account/mgmt/payment/edit",
    title: "MyProfile",
    element: <MyAccount/>,
  },
  {
    link: "/account/mgmt/tax",
    title: "MyProfile",
    element: <MyAccount/>,
  },
  {
    link: "/account/profile",
    title: "MyProfile",
    element: <MyAccount/>,
  },
  {
    link: "/search",
    title: "Search Results",
    element: <SearchResults/>,
  },
  {
    link: "/ShopCart",
    title: "ShopCart",
    element: <ShopCart/>,
  },
  {
    link: "/EmployeeAccounts",
    title: "EmployeeAccounts",
    element: <EmployeeAccounts/>,
  },
  {
    link: "/AddEmployeeAccount",
    title: "AddEmployeeAccount",
    element: <AddEmployeeAccount/>,
  }, 
  {
    link: "/DeleteUser",
    title: "DeleteUser",
    element: <DeleteUser/>,
  }, 
  {
    link: "/partscatalog",
    title: "CatalogPage",
    element: <CatalogPage/>,
  },
  {
    link: "/shippingPolicy",
    title: "shippingPolicy",
    element: <ShippingPolicy/>,
  },
  {
    link: "/Return&Exchange",
    title: "Return&Exchange",
    element: <ReturnExchange/>,
  },
  {
    link: "/shopEngineDiagram",
    title: "ShopEngineDiagram",
    element: <ShopEngine/>,
  },
  {
    link: "/contact",
    title: "contact",
    element: <ContactUs/>,
  },
  // {
  //   link: "/content",
  //   title: "content",
  //   element: <FooterContent/>,
  // },
];
