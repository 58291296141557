import Header from './Header'
import Footer from './Footer'
import "./Checkout.css";
import ShippingPayment from './ShippingPayment';
import ReviewOrder from './ReviewOrder';
import OrderComplete from './OrderComplete';
import { useStore } from '../store/home'
import NavigationBar from '../commenComp/NavigationBar';
import OrderSummary from './OrderSummary';
import ShippingPaymentComponent from './ShippingPaymentComponent';
import { useEffect } from 'react';

const OrdeComplete = () => {
  const { checkoutState, setCheckoutState } = useStore()
  useEffect(()=>{
    setCheckoutState('order')
  },[])
  // setCheckoutState('order')
  return (
    <>
      <div>
        <Header page='Catalog' ></Header>
        <NavigationBar />
      </div>
      <div className='CheckoutComponent'>
        { <OrderComplete></OrderComplete>}
      </div>
      <Footer></Footer>
    </>
  )
}

export default OrdeComplete