import React, { useEffect, useState } from "react";
import { useStore } from "../store/home";
import "./SideMenu.css";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import { getSvgHotSpot } from "../api/posts";
import { useMutation } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { showErrorToast } from "../utils/Toaster";
import Loader from "./Loader";

export default function SideMenuEngine({ options }) {
  const {
    selectedCategory,
    setSelectedCategory,
    setPartList,
    partList,
    setSvgData,
    svgData,
    setNotLast,
    setdis,
    setdis1,
    setdis2,
    setdis3,
    setdis4,
    svgDataOriginalL2,
    setSvgDataOriginalL2,
    mod,
    setMod,
  } = useNonPersistedStore();
  function ShowHotSpot(hotspotid) {
    function findElementByAnchorValue(value) {
      const elements = document.getElementsByTagName("a");
      for (let i = 0; i < elements.length; i++) {
        const anchor = elements[i];
        if (anchor.getAttribute("link") === value) {
          return anchor.parentElement;
        }
      }
      return null;
    }
    const element = findElementByAnchorValue(hotspotid);
    if (element) {
      element.setAttribute("opacity", "0.5");
    }
  }
  function HideHotSpot(hotspotid) {
    if (selectedCategory !== hotspotid) {
      function findElementByAnchorValue(value) {
        const elements = document.getElementsByTagName("a");
        for (let i = 0; i < elements.length; i++) {
          const anchor = elements[i];
          if (anchor.getAttribute("link") === value) {
            return anchor.parentElement;
          }
        }
        return null;
      }
      const element = findElementByAnchorValue(hotspotid);
      if (element) {
        element.setAttribute("opacity", "0");
      }
    }
  }
  const { currentDevice, isCurrentEngSet, token, dealerId } = useStore();

  const hotspotAPI = useMutation((post) => getSvgHotSpot(post), {
    mutationKey: "getSvgHotSpot",
    method: "GET",
    onSuccess: (result) => {
      if (result.data.success) {
        // setSvgData(result.data.data.component);
        const fetchSvgData = async () => {
          try {
            setdis1(true);
            const response = await fetch(result.data.data.component);
            const data = await response.text();
            const modifiedSvgData = data.replace(/xlink:href/g, "link");
            const blob = new Blob([modifiedSvgData], { type: "image/svg+xml" });
            const svgUrl = URL.createObjectURL(blob);
            if (result.data.data.isChildComponent) {
              setSvgDataOriginalL2(svgUrl);
              setNotLast(true);
            } else {
              setNotLast(false);
            }
            setMod(svgUrl);
            setdis1(false);
            // setIsLoading(false);
          } catch (error) {
            setdis1(false);
            console.error("Error fetching SVG data:", error);
            // setIsLoading(false);
          }
        };
        fetchSvgData();
        setPartList(result.data.data.partList);
      } else {
        showErrorToast(result.data.message);
        // setSvgData(svgDataOriginal);
        // setPartList([]);
      }
    },
    onError: (error) => {},
  });

  useEffect(() => {
    const arr = document.getElementsByClassName("categoryLabelDesktop");
    for (let i = 0; i < arr.length; i++) {
      const ele = arr[i];
      if (ele && ele.id.slice(5) !== selectedCategory) {
        ele.classList.remove("highlighted");
      }
    }
  }, [mod]);
  const navigate = useNavigate();
  const [load, setload] = useState(false);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if(searchParams?.get("category")){
      handleCategoryClick(searchParams?.get("category"))
    } 
  }, [searchParams]);
  const handleCategoryClick = (cat) => {
    setload(true);
    setTimeout(() => {
      setload(false);
    }, 1000);
    setdis(true);
    setTimeout(() => {
      setdis(false);
    }, 2000);
    const params = {
      token: token,
      dealerId: dealerId,
      hotspotId: cat,
      engineId: isCurrentEngSet.engineId || "",
    };
    if(isCurrentEngSet.engineId){
      hotspotAPI.mutate(params);
    }
    if (selectedCategory !== cat) {
      options && options.length>0 && options?.forEach((item) => {
        HideHotSpot(item.id);
      });
      ShowHotSpot(cat);
      // document
      //   ?.getElementById("svg")
      //   ?.getElementById(`${cat}`)
      //   ?.setAttribute("opacity", "0.5");
      setSelectedCategory(cat);
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("category", (cat))
      const newSearchParams = queryParams.toString();
      navigate({
        search: `?${newSearchParams}`,
      });
    } else {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("category", (cat))
      const newSearchParams = queryParams.toString();
      navigate({
        search: `?${newSearchParams}`,
      });
      setSelectedCategory(cat);
    }
  };
  return (
    <>
      {(hotspotAPI.isLoading || load) && <Loader></Loader>}
      <div className={`sideMEnuShop sidemenu__maincontainer${currentDevice}`}>
        <div className={`sidemenu__category${currentDevice}`}>
          <div className={`sidemenu__categoryTitleBox${currentDevice}`}>
            <h2 className={`sidemenu__categoryLabel${currentDevice}`}>
              {"Engine Areas"}
            </h2>
          </div>
          <div className={`sidemenu__categoryList${currentDevice}`}>
            <div className={`sidemenu__list${currentDevice}`}>
              <p>
                {Object.values(options).map((category, index) => (
                  <div
                    key={category.id}
                    className={`sidemenu__list2${currentDevice}`}
                    onMouseOver={() => {
                      ShowHotSpot(category.id);
                    }}
                    onMouseOut={() => {
                      HideHotSpot(category.id);
                    }}
                    onClick={() => handleCategoryClick(category.id)}
                  >
                    <p
                      id={`menu.${category.id}`}
                      className={`categoryLabelDesktop shopEngineHover ${
                        selectedCategory === category.id ? "highlighted" : ""
                      }`}
                      // onClick={() => handleCategoryClick(category.id)}
                    >
                      {category.name}
                    </p>
                  </div>
                ))}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
