import React, { useState } from "react";
import { Button, Grid, Paper, makeStyles } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import "./PasswordRecovery.css";
import logo from "../Images/ILMOR_LOGO.png";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../store/home";
import { Box } from "@mui/material";
import commonMessages from "../commenComp/CommonMessages";
// import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(10),
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
    maxHeight: 600,
    backgroundColor: "rgb(255,255,255)",
    boxShadow: "rgb(153, 153, 153) 2px 2px 2px",
    borderRadius: 10,
    letterSpacing: 0.8,
  },
  textField: {
    margin: theme.spacing(3),
    width: "70%",
    backgroundColor: "rgb(255,255,255)",
    // marginLeft: "-7%",
  },
  button: {
    marginTop: theme.spacing(1),
    borderRadius: 0,
    marginBottom: "4%",
    marginLeft: "-60%",
    textTransform: "capitalize",
  },
}));

const PasswordRecovery = () => {
  const { currentDevice, forgotEmail, setFooterKeyZustand } = useStore();
  const location = useLocation();
  const [IsValidEmail, setIsValidEmail] = useState(location?.state?.resposnse);
  const classes = useStyles();
  const navigate = useNavigate();

  const onSubmit = (data, event) => {
    event.preventDefault();
  };

  const schema = yup.object().shape({
    username: yup.string().email("Invalid email").required("Email is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const handleFooterClick = (key) => {
    setFooterKeyZustand(key);

    navigate({
      pathname: "/content/contact",
    });
  };

  return (
    <>
      <div className={`PasswordRecovery__main${currentDevice}`}>
        <div className={`PasswordRecovery_Container${currentDevice}`}>
          <div className={`PasswordRecovery_logo${currentDevice}`}>
            <img
              className={`PasswordRecovery_title-image${currentDevice}`}
              src={logo}
              alt="company-logo"
            />
          </div>
          <div className={`PasswordRecovery_info__main${currentDevice}`}>
            <div className={`PasswordRecovery_headingBox${currentDevice}`}>
              <h2 className={`PasswordRecovery_heading${currentDevice}  passwordRecLastStepTablet`}
                // style={{
                //   marginBottom: currentDevice == "Mobile" ? "12px" :  currentDevice == "Tablet"?"25px": "15px",
                // }}
                >
                {commonMessages?.passwordRecoveryHeading}
              </h2>
              <div className={`PasswordRecovery_Msg${currentDevice}`}>
                <p className="pwRecoverymsg1">
                  If we find{" "}
                  <p style={{ fontWeight: "500", display: "inline" }}>
                    {`${forgotEmail}`}
                  </p>{" "}
                  in our system, we will send you an email with a link to reset
                  your password.
                </p>
                <div>
                  If you don't receive the email, check your spam folder or
                  <Box
                    sx={{
                      display: "inline",
                      cursor: "pointer",
                      textDecoration: "underline",
                      marginLeft: "4px",
                    }}
                    onClick={() => {
                      handleFooterClick("CONTACT");
                    }}
                  >
                    contact us.
                  </Box>{" "}
                </div>
              </div>{" "}
              {/* : */}
              {/* <div className={`PasswordRecovery_Msg${currentDevice}`}>
                  We could not find  <p style={{ fontWeight: '700', display: 'inline' }}>{forgotEmail}</p>  in our system, Please Try again with a reigistred email Id
                </div> */}
            </div>

            <hr
              className={`PasswordRecovery_lineBreak__main${currentDevice}`}
            ></hr>

            <div className="pwRecoveryFooter" onSubmit={handleSubmit(onSubmit)}>
              <div className={`PasswordRecoveryBtnBox${currentDevice}`}>
                <button className={`PasswordRecovery_Btn${currentDevice}`}>
                  <Link
                    className={`PasswordRecoveryLink${currentDevice}`}
                    to="/sign-in"
                    style={{
                      textDecoration: "none",
                      color: "rgb(247, 247, 247)",
                    }}
                  >
                    Return to Sign In
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordRecovery;
