import "./ShippingPayment.css";
// import { ReactComponent as Radio } from "../Images/radioButton.svg";
// import { ReactComponent as RadioEmpty } from "../Images/radioEmpty.svg";
import InputBase from "@mui/material/InputBase";
import classnames from "classnames";
import { Box } from "@mui/material";
import { useStore } from "../store/home";
import { Radio, Tooltip } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import Select from "react-select";
import { useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useLocation } from "react-router-dom";
import {
  authorizeNet,
  dhl,
  getMyProfile,
  getPay,
  getShipRate,
  updateOrderSummay,
  ups,
} from "../api/posts";
import { useEffect } from "react";
// import { ReactComponent as Visa } from "../../Images/visa.svg";
import Loader from "../commenComp/Loader";
import commonMessages from "../commenComp/CommonMessages";
import { customStyles } from "../utils/ReactSelectCustomStyles";
import { showErrorToast } from "../utils/Toaster";
import { useNonPersistedStore } from "../store/NonPersistedStore";

import { ReactComponent as Visa } from "../Images/visa.svg";
import { ReactComponent as Master } from "../Images/master.svg";
import american from "../Images/american.png";
import jcb from "../Images/jcb.png";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { ReactComponent as Discover } from "../Images/discover.svg";

const ShippingPayment = ({
  reviewOrder,
  orderDetail,
  dataArray,
  handleUpdateOrder,
}) => {
  const {
    defaultCard,
    setDefaultCard,
    checkoutState,
    token,
    myProfile,
    cusProfile,
    setservice,
    service,
    isPO,
    dealerId,
    shipPrice,
    setshipPrice,
    isPOValue,
    setFinalShipPrice,
    setIsPOValue,
    snote,
    setSnote,
    setSideMenuAccount,
    setIsPO,
    setDomestic,
    userInfo,
    setShipInterOption,
    isDomestic,
    setMyProfile,
    isDomesticCH,
    currentDevice,
    setIsCardActive,
  } = useStore();
  const {
    setUseThisAddress,
    setChooseCard,
    chooseCardId,
    getAllCardsArray,
    allCards,
    setLowest,
    lowest,
    shippinPaymentAddressUpdated,
    isDomesticOption,
    setfailShip,
    setDomesticOption,
    setLoad,
    setdropFee,
    setdropFeestate,
    setCload,
    shipDis,
    isSLow,
  } = useNonPersistedStore();
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (flag) => {
    setIsPO(flag);
  };
  const [tbd, setTBD] = useState("notSet");
  useEffect(() => {
    if (dataArray.shipping === "TBD") {
      setTBD(true);
    } else {
      setTBD(false);
    }
  }, [dataArray]);

  const [lodaer, setlodaer] = useState(false);
  const [minset, setMinSet] = useState(false);
  const [lod, setLod] = useState(false);
  const authorize = useMutation((post) => authorizeNet(post), {
    onSuccess: (result) => {
      setlodaer(false);
      if (result) {
        getAllCardsArray(result.data);
        if (!result.data.profile?.paymentProfiles) {
          setDefaultCard(false);
        }
      }
    },
    onError: (error) => {
      setlodaer(false);
    },
  });

  const mutation = useMutation((post) => getMyProfile(post, token), {
    onSuccess: (result) => {
      setMyProfile(result?.data);
    },
    onError: (error) => {},
  });
  useEffect(() => {
    const obj = {};
    obj.email = userInfo?.username;
    obj.dealerId = dealerId;
    if (reviewOrder) {
      setservice({ value: "dhl", label: "DHL Express Worldwide" });
    }
    // mutation.mutate(obj);
  }, []);
  const [allCardsStatus, getAllCardsArrayStatus] = useState(false);
  const getPayMethod = useMutation((post) => getPay(post, token), {
    onSuccess: (result) => {
      if (result?.data?.success && result?.data?.data?.CustomerProfileId) {
        getAllCardsArrayStatus(result?.data?.data?.cards);
      }
    },
    onError: (error) => {},
  });

  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      if (checkoutState === "ship") {
        if (Object.values(shippinPaymentAddressUpdated).length === 0) {
          setlodaer(true);
          authorize.mutate(getAllCards);
          const data = {
            id: myProfile?.profileInfo?.id,
          };
          getPayMethod.mutate(data);
        }
        if (myProfile?.shippingInfo?.isDomestic) {
          setDomestic("domestic");
        } else {
          setDomestic("inter");
        }
      }
      return;
    }
    setCload(false);
  }, []);

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    let isCancelled = false;
    if (
      reviewOrder &&
      dataArray.totalWeight &&
      tbd !== "notSet" &&
      !chooseCardId
    ) {
      if (
        shippinPaymentAddressUpdated &&
        Object.values(shippinPaymentAddressUpdated).length > 0
      ) {
        if (!tbd) {
          let obj = {};
          if (isDomesticCH === "domestic") {
            obj = {
              userId: "",
              weight: dataArray.totalWeight
                ? parseFloat(dataArray.totalWeight).toFixed(2).toString()
                : "0",
              addressId: shippinPaymentAddressUpdated.id,
              shipmentMethod: "ups_domestic",
              serviceId: "",
            };
          } else if (isDomesticCH === "inter") {
            if (service.value === "dhl") {
              obj = {
                userId: "",
                weight: dataArray.totalWeight
                  ? parseFloat(dataArray.totalWeight).toFixed(2).toString()
                  : "0",
                addressId: shippinPaymentAddressUpdated.id,
                shipmentMethod: "dhl_worldwide",
                serviceId: "",
              };
            } else {
              obj = {
                userId: "",
                weight: dataArray.totalWeight
                  ? parseFloat(dataArray.totalWeight).toFixed(2).toString()
                  : "0",
                addressId: shippinPaymentAddressUpdated.id,
                shipmentMethod: "ups_worldwide",
                serviceId: "07",
              };
            }
          }
          const handleChange = async () => {
            setLod(true);
            await timeout(400);
            setLod(false);
            if (!isCancelled) {
              getShipRateAPI.mutate(obj);
            }
          };
          handleChange();
        } else {
          const obj = {};
          obj.email = userInfo.username;
          obj.shippingId = shippinPaymentAddressUpdated.id || "";
          obj.shipMethodPrice = "";
          obj.shipMethodCode = "";
          updateSummary.mutate(obj);
        }
      }
    }
    return () => {
      isCancelled = true;
    };
  }, [service, reviewOrder, isDomesticCH, dataArray, tbd]);

  useEffect(() => {
    let isCancelled = false;
    if (tbd !== "notSet" && !chooseCardId) {
      if (reviewOrder && dataArray.totalWeight && !tbd) {
        if (
          !(
            shippinPaymentAddressUpdated &&
            Object.values(shippinPaymentAddressUpdated).length > 0
          )
        ) {
          if (isDomestic === "domestic" && myProfile?.profileInfo?.id) {
            const obj = {};
            obj.userId = myProfile?.profileInfo?.id;
            obj.weight = dataArray.totalWeight
              ? parseFloat(dataArray.totalWeight).toFixed(2).toString()
              : "0";
            obj.requestOption = "Shop";
            const handleChange = async () => {
              setLod(true);
              await timeout(700);
              setLod(false);
              if (!isCancelled) {
                upsAPIDome.mutate(obj);
              }
            };
            handleChange();
          } else if (isDomestic === "inter") {
            if (service.value === "dhl") {
              const obj = {};
              obj.userId = myProfile?.profileInfo?.id;
              obj.weight = dataArray.totalWeight
                ? parseFloat(dataArray.totalWeight).toFixed(2).toString()
                : "0";

              const handleChange = async () => {
                setLod(true);
                await timeout(700);
                setLod(false);
                if (!isCancelled) {
                  dhlAPI.mutate(obj);
                }
              };
              handleChange();
            }
            if (service.value === "ups") {
              const obj = {};
              obj.userId = myProfile?.profileInfo?.id;
              obj.weight = dataArray.totalWeight
                ? parseFloat(dataArray.totalWeight).toFixed(2).toString()
                : "0";
              obj.serviceId = "07";
              obj.requestOption = "Rate";
              const handleChange = async () => {
                setLod(true);
                await timeout(700);
                setLod(false);
                if (!isCancelled) {
                  upsAPIInter.mutate(obj);
                }
              };
              handleChange();
            }
          }
        }
      }
    }
    return () => {
      isCancelled = true;
    };
  }, [service, isDomestic, reviewOrder, dataArray, tbd]);

  useEffect(() => {
    if (reviewOrder && isPO === "notSet") {
      if (!myProfile?.canManagePurchaseOrder) {
        setIsPO(false);
      } else {
        setIsPO(true);
      }
    }
  }, [myProfile]);

  useEffect(() => {
    if (!defaultCard && isPO === "notSet") {
      setIsPO(true);
    } else {
      if (allCardsStatus?.length > 0 && defaultCard) {
        allCardsStatus.forEach((item) => {
          if (
            item.profileInformationId === defaultCard.customerPaymentProfileId
          ) {
            if (item.active) {
              setIsCardActive(true);
            } else {
              setIsCardActive(false);
            }
            defaultCard.referenceLabel = item.referenceLabel;
          }
        });
      }
    }
  }, [defaultCard, allCardsStatus]);

  const handleChangePay = (event) => {
    setChooseCard(true);
    setSideMenuAccount("paymentMethods");
    navigate("/account/mgmt/payment", { state: { useCard: true } });
  };

  const upsAPIInter = useMutation((post) => ups(post, token), {
    onSuccess: (result) => {
      if (result.data.success) {
        if (result?.data?.data) {
          setshipPrice(result?.data?.data[0].TotalCharges);
          setFinalShipPrice(result?.data?.data[0]?.TotalCharges);
          const obj = {};
          obj.email = userInfo.username;
          obj.shippingId = "";
          obj.shipMethodCode = result?.data?.data[0]?.Code || "";
          obj.shipMethodPrice = result?.data?.data[0]?.TotalCharges
            ? parseFloat(result?.data?.data[0]?.TotalCharges)
            : 0;
          setShipInterOption(result?.data?.data[0]);
          updateSummary.mutate(obj);
        }
      } else {
        setservice({ value: "dhl", label: "DHL Express Worldwide" });
        showErrorToast(result.data.msg);
      }
    },
    onError: (error) => {},
  });

  const dhlAPI = useMutation((post) => dhl(post, token), {
    onSuccess: (result) => {
      if (result.data.success) {
        if (result?.data?.data) {
          setshipPrice(result?.data?.data[0]?.TotalCharges);
          setFinalShipPrice(result?.data?.data[0]?.TotalCharges);
          const obj = {};
          obj.email = userInfo.username;
          obj.shippingId = "";
          obj.shipMethodCode = result?.data?.data[0]?.Code || "";
          obj.shipMethodPrice = result?.data?.data[0]?.TotalCharges
            ? parseFloat(result?.data?.data[0]?.TotalCharges)
            : 0;
          setShipInterOption(result?.data?.data[0]);
          updateSummary.mutate(obj);
        }
      } else {
        showErrorToast(result?.data?.msg);
      }
    },
    onError: (error) => {},
  });

  const handleService = (option) => {
    setFinalShipPrice(option.price);
    setservice(option);
  };
  const handleServiceDomestic = (option) => {
    const obj = {};
    obj.email = userInfo.username;
    obj.shipMethodCode = option.code || "";
    obj.shippingId = shippinPaymentAddressUpdated.id || "";
    obj.shipMethodPrice = option.price ? parseFloat(option.price) : 0;
    updateSummary.mutate(obj);
    setFinalShipPrice(option.price);
    setLowest(option);
    setshipPrice(option);
  };

  // const StyledRadio = styled(Radio)({
  //   color: "black", // Change the color here
  //   "&.Mui-checked": {
  //     color: "black", // Change the color for the checked state
  //   },
  // });

  const getAllCards = {
    getCustomerProfileRequest: {
      merchantAuthentication: {
        name: process.env.REACT_APP_MERCHANT_NAME,
        transactionKey: process.env.REACT_APP_MERCHANT_TRANSACTION_KEY,
      },
      customerProfileId: cusProfile,
      // includeIssuerInfo: "true",
      unmaskExpirationDate: true,
    },
  };

  // const [allCards, getAllCardsArray] = useState(false);

  const updateSummary = useMutation((post) => updateOrderSummay(post, token), {
    onSuccess: (result) => {
      setCload(false);
      if (result.data.success) {
        setdropFee(0);
        setdropFeestate(false);
        if (result.data.data.handellingFees) {
          setdropFeestate(20);
        }
        if (result.data.data.couponMessage) {
          showErrorToast(result.data.data.couponMessage);
        }
        handleUpdateOrder(result.data.data);
      }
    },
    onError: (error) => {
      setCload(false);
    },
  });

  // const [lowest, setLowest] = useState("");
  const upsAPIDome = useMutation((post) => ups(post, token), {
    onSuccess: (result) => {
      if (result.data.success) {
        if (result?.data?.data && result?.data?.data.length > 0) {
          const arr = [];
          result?.data?.data.forEach((item) => {
            const obj = {};
            obj.label = `${item.Description} ($${item.TotalCharges})`;
            obj.value = item.Code + " " + item.TotalCharges;
            obj.code = item.Code;
            obj.price = parseFloat(item.TotalCharges);
            arr.push(obj);
          });
          let minPriceOption = arr.reduce((minOption, currentOption) =>
            currentOption.price < minOption.price ? currentOption : minOption
          );
          if (isSLow && shipPrice?.price) {
            minPriceOption = shipPrice;
          }
          setFinalShipPrice(minPriceOption.price);
          setshipPrice(minPriceOption);
          // setservice(minPriceOption)
          setLowest(minPriceOption);
          setDomesticOption(arr);
          const obj = {};
          obj.email = userInfo.username;
          obj.shipMethodCode = minPriceOption.code || "";
          obj.shippingId = "";
          obj.shipMethodPrice = minPriceOption.price
            ? parseFloat(minPriceOption.price)
            : 0;
          updateSummary.mutate(obj);
        }
      } else {
        showErrorToast(result.data.msg);
      }
    },
    onError: (error) => {},
  });

  // useEffect(() => {
  //   if (
  //     (dataArray.shipping === "FREE" || tempSummary.shipping === "FREE") &&
  //     isDomestic === "domestic"
  //   ) {
  //     if (
  //       myProfile?.profileInfo?.dealerLevel === "Platinum" &&
  //       myProfile?.profileInfo?.isMasterZipMatch &&
  //       Object.values(shippinPaymentAddressUpdated).length === 0
  //     ) {
  //       // setMinSet(false);
  //     }
  //     if (dataArray.coupon || tempSummary.coupon) {
  //       setMinSet(true);
  //       if (isDomesticOption && isDomesticOption.length > 0) {
  //         const minPriceOption = isDomesticOption.reduce(
  //           (minOption, currentOption) =>
  //             currentOption.price < minOption.price ? currentOption : minOption
  //         );
  //         setFinalShipPrice(minPriceOption.price);
  //         setshipPrice(minPriceOption);
  //         setLowest(minPriceOption);
  //       }
  //     } else {
  //       setMinSet(false);
  //     }
  //     // if(tempSummary && !tempSummary.coupon){
  //     //   setMinSet(false);
  //     // }
  //   } else {
  //     setMinSet(false);
  //   }
  // }, [dataArray, shippinPaymentAddressUpdated, tempSummary]);

  function convertDateFormat(originalDate) {
    const [year, month] = originalDate.split("-");
    const formattedDate = `${month}/${year.slice(-2)}`;
    return formattedDate;
  }
  const getShipRateAPI = useMutation((post) => getShipRate(post, token), {
    onSuccess: (result) => {
      if (result.data.success) {
        if (result.data.isDomestic) {
          const arr = [];
          result?.data?.data.forEach((item) => {
            const obj = {};
            obj.label = `${item.Description} ($${item.TotalCharges})`;
            obj.value = item.Code + " " + item.TotalCharges;
            obj.code = item.Code;
            obj.price = parseFloat(item.TotalCharges);
            arr.push(obj);
          });
          let minPriceOption = arr.reduce((minOption, currentOption) =>
            currentOption.price < minOption.price ? currentOption : minOption
          );
          if (isSLow && shipPrice?.price) {
            minPriceOption = shipPrice;
          }
          setFinalShipPrice(minPriceOption.price);
          setshipPrice(minPriceOption);
          setLowest(minPriceOption);
          setDomesticOption(arr);
          setDomestic("domestic");
          const obj = {};
          obj.email = userInfo.username;
          obj.shipMethodCode = minPriceOption.code || "";
          obj.shippingId = shippinPaymentAddressUpdated.id || "";
          obj.shipMethodPrice = minPriceOption.price
            ? parseFloat(minPriceOption.price)
            : 0;
          updateSummary.mutate(obj);
        } else {
          setshipPrice(result?.data?.data[0]?.TotalCharges);
          setFinalShipPrice(result?.data?.data[0]?.TotalCharges);
          const obj = {};
          obj.email = userInfo.username;
          obj.shipMethodCode = result?.data?.data[0]?.Code || "";
          obj.shippingId = shippinPaymentAddressUpdated.id || "";
          obj.shipMethodPrice = result?.data?.data[0]?.TotalCharges
            ? parseFloat(result?.data?.data[0]?.TotalCharges)
            : 0;
          setShipInterOption(result?.data?.data[0]);
          updateSummary.mutate(obj);
          setDomestic("inter");
        }
      } else {
        if (isDomesticCH === "inter" && service.value === "ups") {
          setservice({ value: "dhl", label: "DHL Express Worldwide" });
        }
        setfailShip(result.data.msg);
        showErrorToast(result.data.msg);
      }
      // setUseThisAddress(false)
    },
    onError: (error) => {},
  });
  useEffect(() => {
    if (defaultCard && defaultCard.card && !myProfile?.canManagePurchaseOrder) {
      setIsPO(false);
    }
  }, [defaultCard, myProfile]);
  useEffect(() => {
    if (reviewOrder) {
      if (
        allCards &&
        allCards?.profile?.paymentProfiles &&
        allCards?.profile?.paymentProfiles?.length > 0
      ) {
        let defCard = false;
        allCards.profile.paymentProfiles.forEach((item) => {
          if (chooseCardId) {
            if (item.customerPaymentProfileId === chooseCardId) {
              const obj = {};
              obj.reference = item?.billTo?.firstName;
              obj.reference1 = item?.billTo?.lastName;
              obj.cusProfile = allCards?.profile?.customerProfileId;
              obj.customerPaymentProfileId = item?.customerPaymentProfileId;
              obj.card = item?.payment?.creditCard?.cardNumber;
              obj.expiration = item?.payment?.creditCard?.expirationDate;
              obj.status = item?.defaultPaymentProfile;
              obj.cardType = item?.payment?.creditCard?.cardType;
              defCard = obj;
              setChooseCard(false);
            }
          } else {
            if (item.defaultPaymentProfile) {
              const obj = {};
              obj.reference = item?.billTo?.firstName;
              obj.reference1 = item?.billTo?.lastName;
              obj.cusProfile = allCards?.profile?.customerProfileId;
              obj.customerPaymentProfileId = item?.customerPaymentProfileId;
              obj.card = item?.payment?.creditCard?.cardNumber;
              obj.expiration = item?.payment?.creditCard?.expirationDate;
              obj.status = item?.defaultPaymentProfile;
              obj.cardType = item?.payment?.creditCard?.cardType;
              defCard = obj;
            }
          }
        });
        if (defCard) {
          setDefaultCard(defCard);
        } else {
          setDefaultCard(false);
        }
      }
    }
  }, [allCards]);

  const [showLoader, setShowLoader] = useState(true);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowLoader(false);
    }, 3000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  useEffect(() => {
    if (
      updateSummary.isLoading ||
      dhlAPI.isLoading ||
      upsAPIDome.isLoading ||
      upsAPIInter.isLoading ||
      lod ||
      getShipRateAPI.isLoading
      // &&
      // authorize.isLoading ||
      // lod ||
      // showLoader
      // reviewOrder
    ) {
      setLoad(true);
    } else {
      setLoad(false);
    }
  }, [
    updateSummary.isLoading,
    dhlAPI.isLoading,
    upsAPIDome.isLoading,
    upsAPIInter.isLoading,
    getShipRateAPI.isLoading,
    // authorize.isLoading,
    // lod,
    // showLoader,
  ]);

  return (
    <div
      className={classnames("shipping", {
        borderBottomCheckoutShipping: location.pathname == "/checkout",
        borderBottomCheckoutShipping1:
          location.pathname == "/checkout" && !reviewOrder,
      })}
    >
      {/* {(lodaer ||
        upsAPIDome.isLoading ||
        // showLoader ||
        // lod ||
        // getShipRateAPI.isLoading ||
        updateSummary.isLoading ||
        dhlAPI.isLoading ||
        upsAPIInter.isLoading) &&
        reviewOrder && (
          <>
            <Loader></Loader>
            <Loader></Loader>
          </>
        )} */}
      {!orderDetail && (
        <div className="shippingTitile">
          {reviewOrder ? "Shipping & payment" : "Review Order"}
        </div>
      )}
      <div
        className={classnames("billingShop", {
          orderDetailBillShopiing: orderDetail,
        })}
      >
        <div className="billTtile">
          <span>Billing Address</span>
        </div>
        {myProfile?.billingAddress?.locationName ? (
          <>
            <div className="billTtile2">
              <span>{myProfile?.billingAddress?.locationName}</span>
            </div>
            <div className="billTtile3">
              <span>{myProfile?.billingAddress?.address1}</span>
              <span>{myProfile?.billingAddress?.address2}</span>
              <span>{`${myProfile?.billingAddress?.city?.label}, ${myProfile?.billingAddress?.state?.label} ${myProfile?.billingAddress?.zipCode}`}</span>
              <span>{myProfile?.billingAddress?.country?.label}</span>
            </div>
          </>
        ) : (
          <>
            <div className="billTtile2">
              <span>{"Not available"}</span>
            </div>
          </>
        )}
      </div>
      <div
        // className="shippingAddress"
        className={classnames("shippingAddress", {
          ngkhjredkj456: tbd && location.pathname === "/checkout",
        })}
      >
        <div className="billTtile">
          <span>Shipping Address</span>
        </div>
        <div className="billTtile2 swgrghty43">
          <span>
            {Object.values(shippinPaymentAddressUpdated)?.length > 0
              ? shippinPaymentAddressUpdated?.locationName
              : myProfile?.shippingInfo?.locationName}
          </span>
        </div>
        <div className="billAddLastComp addon">
          <div className="billTtile3">
            <span>
              {Object.values(shippinPaymentAddressUpdated)?.length > 0
                ? shippinPaymentAddressUpdated?.address1
                : myProfile?.shippingInfo?.address1
                ? myProfile?.shippingInfo?.address1
                : ""}
            </span>
            <span>
              {Object.values(shippinPaymentAddressUpdated)?.length > 0
                ? shippinPaymentAddressUpdated?.address2
                : myProfile?.shippingInfo?.address2
                ? myProfile?.shippingInfo?.address2
                : ""}
            </span>
            {Object.values(shippinPaymentAddressUpdated)?.length > 0 ? (
              <span>{`${shippinPaymentAddressUpdated?.city?.label} ${shippinPaymentAddressUpdated?.state?.label} ${shippinPaymentAddressUpdated?.zipCode}`}</span>
            ) : (
              <span>{`${
                myProfile?.shippingInfo?.city?.label
                  ? myProfile?.shippingInfo?.city?.label
                  : ""
              } ${
                myProfile?.shippingInfo?.state?.label
                  ? myProfile?.shippingInfo?.state?.label
                  : ""
              } ${
                myProfile?.shippingInfo?.zipCode
                  ? myProfile?.shippingInfo?.zipCode
                  : ""
              }`}</span>
            )}
            <span>
              {Object.values(shippinPaymentAddressUpdated)?.length > 0
                ? shippinPaymentAddressUpdated?.country?.label
                : myProfile?.shippingInfo?.country?.label
                ? myProfile?.shippingInfo?.country?.label
                : ""}
            </span>
          </div>
          {reviewOrder && (
            <div className="billChangeButt">
              <button
                onClick={() => {
                  setSideMenuAccount("shipping");
                  setUseThisAddress(true);
                  navigate({
                    pathname: "/account/mgmt/shipping",
                  });
                }}
                className="ilmorButonGrey"
              >
                Change
              </button>
            </div>
          )}
        </div>
      </div>
      {!tbd && (
        <div
          className={classnames("shippingAddress ", {
            borderbottomReviewShippingYourCart:
              location.pathname == "/checkout",
          })}
        >
          <div className="billTtile">
            <span>Shipping Methods</span>
          </div>
          {!reviewOrder && (
            <>
              {isDomestic === "domestic" ? (
                <>
                  {dataArray.shipping === "FREE" &&
                  (dataArray?.shippingDiscount || shipDis) &&
                  shipPrice.label ? (
                    <div className="center">
                      <span className="shipMethodSel billTtile3">
                        {`${shipPrice?.label?.split(" ")[0]} `}
                      </span>&nbsp;
                      <span className="strike shipMethodSel billTtile3">
                        {` ${shipPrice.label?.split(" ")[1]} `}
                      </span>
                      <span className="shipMethodSel billTtile3">
                        &nbsp;{` FREE`}
                      </span>
                    </div>
                  ) : (
                    <span className="shipMethodSel">{shipPrice?.label}</span>
                  )}
                </>
              ) : (
                <span className="shipMethodSel">{service?.label}</span>
              )}
            </>
          )}
          {reviewOrder && (
            <Tooltip
              placement="top"
              title={
                minset ? "Remove coupon to change the shipping method" : ""
              }
            >
              <div className="billAddLastComp  shipMethod shipMethodDomestic">
                {isDomestic === "domestic" && (
                  <Select
                    className="shipMethodDropdown"
                    isSearchable={false}
                    isDisabled={minset}
                    placeholder="Choose Delivery Service"
                    options={isDomesticOption || []}
                    onChange={handleServiceDomestic}
                    value={lowest}
                    styles={customStyles}
                  />
                )}
              </div>
            </Tooltip>
          )}
          {reviewOrder && (
            <div className="billAddLastComp shipMethod">
              {isDomestic === "inter" && (
                <Select
                  isSearchable={false}
                  className="shipMethodDropdown"
                  placeholder="Choose Delivery Service"
                  value={service}
                  options={[
                    {
                      label: "UPS Worldwide",
                      value: "ups",
                    },
                    {
                      label: "DHL Express Worldwide",
                      value: "dhl",
                    },
                  ]}
                  onChange={handleService}
                  styles={customStyles}
                />
              )}
            </div>
          )}
        </div>
      )}

      <Box
        className={classnames(" ", {
          payment: currentDevice !== "Desktop",
          paymentforpay: location.pathname === "/checkout" && reviewOrder,
          paymentforpay23:
            location.pathname === "/checkout" && reviewOrder && tbd,
          paymentforpayBoy: location.pathname === "/checkout" && !reviewOrder,
          // payment: currentDevice === 'Desktop',
        })}
      >
        <div className="billTtile">
          <span>Payment method</span>
        </div>
        <div className="paymetnOptions addon">
          <div>
            {reviewOrder &&
              defaultCard &&
              myProfile?.canManagePurchaseOrder && (
                <Radio
                  checked={!isPO}
                  className="classPORadioMobile"
                  onChange={() => {
                    handleChange(false);
                  }}
                  value={!isPO}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "B" }}
                />
              )}
            {defaultCard ? (
              <>
                {reviewOrder && (
                  <>
                    <div className="dpC">
                      <span className="personDetail">
                        {defaultCard.referenceLabel
                          ? defaultCard.referenceLabel
                          : ""}
                      </span>
                      <span className="personDetail">
                        {`${
                          defaultCard?.reference ? defaultCard?.reference : ""
                        } ${
                          defaultCard?.reference1 ? defaultCard?.reference1 : ""
                        }`}
                      </span>
                    </div>
                    <div className="paymethodCard">
                      {defaultCard.cardType === "Visa" ? (
                        <Visa className="catogeryIcon"></Visa>
                      ) : (
                        ""
                      )}
                      {defaultCard.cardType === "AmericanExpress" ? (
                        <img src={american} className="cardWidth"></img>
                      ) : (
                        ""
                      )}
                      {defaultCard.cardType === "MasterCard" ? (
                        <Master className="catogeryIcon"></Master>
                      ) : (
                        ""
                      )}
                      {defaultCard.cardType === "JCB" ? (
                        <img src={jcb} className="cardWidth"></img>
                      ) : (
                        ""
                      )}
                      {defaultCard.cardType === "Discover" ? (
                        <Discover className="catogeryIcon"></Discover>
                      ) : (
                        ""
                      )}
                      {!(
                        defaultCard.cardType === "Discover" ||
                        defaultCard.cardType === "JCB" ||
                        defaultCard.cardType === "MasterCard" ||
                        defaultCard.cardType === "Visa" ||
                        defaultCard.cardType === "AmericanExpress"
                      ) ? (
                        <CreditCardIcon sx={{ fontSize: 35 }}></CreditCardIcon>
                      ) : (
                        ""
                      )}
                    </div>
                    <span className="visaCode">
                      {defaultCard.card.replace(/X/g, "")}
                    </span>
                    <span className="visaCode">
                      {" "}
                      {convertDateFormat(defaultCard.expiration)}
                    </span>
                  </>
                )}
                {!reviewOrder && !isPO && (
                  <>
                    <div className="dpC">
                      <span className="personDetail">
                        {defaultCard.referenceLabel
                          ? defaultCard.referenceLabel
                          : ""}
                      </span>
                      <span className="personDetail">
                        {`${
                          defaultCard?.reference ? defaultCard?.reference : ""
                        } ${
                          defaultCard?.reference1 ? defaultCard?.reference1 : ""
                        }`}
                      </span>
                    </div>
                    <div className="minCard center">
                      {defaultCard.cardType === "Visa" ? (
                        <Visa className="catogeryIcon"></Visa>
                      ) : (
                        ""
                      )}
                      {defaultCard.cardType === "AmericanExpress" ? (
                        <img src={american} className="cardWidth"></img>
                      ) : (
                        ""
                      )}
                      {defaultCard.cardType === "MasterCard" ? (
                        <Master className="catogeryIcon"></Master>
                      ) : (
                        ""
                      )}
                      {defaultCard.cardType === "JCB" ? (
                        <img src={jcb} className="cardWidth"></img>
                      ) : (
                        ""
                      )}
                      {defaultCard.cardType === "Discover" ? (
                        <Discover className="catogeryIcon"></Discover>
                      ) : (
                        ""
                      )}
                      {!(
                        defaultCard.cardType === "Discover" ||
                        defaultCard.cardType === "JCB" ||
                        defaultCard.cardType === "MasterCard" ||
                        defaultCard.cardType === "Visa" ||
                        defaultCard.cardType === "AmericanExpress"
                      ) ? (
                        <CreditCardIcon sx={{ fontSize: 35 }}></CreditCardIcon>
                      ) : (
                        ""
                      )}
                    </div>
                    <span className="visaCode">
                      {defaultCard.card.replace(/X/g, "")}
                    </span>
                    <span className="visaCode">
                      {" "}
                      {convertDateFormat(defaultCard.expiration)}
                    </span>
                  </>
                )}
              </>
            ) : (
              <>{reviewOrder && <>{commonMessages.shippingPaymentNoCard}</>}</>
            )}
          </div>
          {reviewOrder && (
            <div className="billChangeButt">
              <button onClick={handleChangePay} className="ilmorButonGrey">
                {defaultCard ? "Change" : "Add"}
              </button>
            </div>
          )}
        </div>
        {reviewOrder && myProfile?.canManagePurchaseOrder && (
          <hr className="purchase_line" />
        )}

        <div
          className={classnames("paymetnOptionsPO", {
            marginBottomCheckoutShipping: location.pathname == "/checkout",
          })}
        >
          {reviewOrder && myProfile?.canManagePurchaseOrder && (
            <Radio
              checked={isPO}
              className="classPORadioMobile"
              onChange={() => {
                handleChange(true);
              }}
              value={isPO}
              color="black"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
            />
          )}

          {reviewOrder && myProfile?.canManagePurchaseOrder && (
            <span className="personDetail padRight">Purchase Order</span>
          )}

          {!reviewOrder && isPO && myProfile?.canManagePurchaseOrder && (
            <span className="personDetail padRight">Purchase Order</span>
          )}
          {!reviewOrder && isPO && (
            <>
              <span className="personDetail">{isPOValue}</span>
            </>
          )}
          {reviewOrder && myProfile?.canManagePurchaseOrder && (
            <InputBase
              style={{
                backgroundColor: "white",
                border: "none",
                outline: "none",
                padding: "5px",
                fontStyle: "italic",
              }}
              placeholder="PO Number / Reference"
              onInput={(e) => {
                e.target.value = e.target.value.slice(0, 25);
              }}
              value={isPOValue}
              // onChange={(e) => { setInput(e.target.value) }}
              // value={input}
              onChange={(e) => {
                if(e.target.value !== ' '){
                  setIsPOValue(e.target.value);
                }
              }}
              className="header__input__base border newwimin maxWidth minWidth"
              inputProps={{ "aria-label": "search google maps" }}
            />
          )}
        </div>
      </Box>
      {reviewOrder ? (
        <div className="billTtile topline newCommit">
          <div className="hgjy676">
            <span>shipping notes</span>
            <InputBase
              style={{
                backgroundColor: "white",
                border: "none",
                outline: "none",
                padding: "5px",
                fontStyle: "italic",
              }}
              placeholder="Shipping Notes"
              multiline={true}
              inputProps={{ maxLength: 500 }}
              rows={5}
              onInput={(e) => {
                e.target.value = e.target.value.slice(0, 500);
              }}
              value={snote}
              // onChange={(e) => { setInput(e.target.value) }}
              // value={input}
              onChange={(e) => {
                if(e.target.value !== ' '){
                  setSnote(e.target.value);
                }
              }}
              className="header__input__base border newwimin maxWidth minWidth"
            />
          </div>
        </div>
      ) : (
        <div className="billTtile topline">
        <div className="hgjy676">
          <span>shipping notes</span>
          <span className="personDetail232">{snote}</span>
        </div>
      </div>
      )}
    </div>
  );
};

export default ShippingPayment;
