import React from "react";
import { useState } from "react";
import {
  Button,
  MenuItem,
  Select,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Input,
  Stack,
} from "@mui/material";
import "./SubHeading.css";
import { useStore } from "../store/home";
// import useCollapse from 'react-collapsed';


const SubHeading = ({ heading, isSmallHeading, yourCart, isHeading }) => {
  const { setScreenSize, setCurrentDevice, categoryIdSearch, screenSize, keyWordFinal, currentDevice } =
    useStore();
  const [visible, setVisible] = useState(false)
  let str = ''
  if (keyWordFinal.length > 20) {
    str = keyWordFinal.slice(0, 30) + "...."
  } else {
    str = keyWordFinal;
  }
  return (
    <>
      <div className={`subHeadDesktop${currentDevice === 'Mobile' ? ' subHeadMobile' : ''} `}>
        {heading && <b className={`subHeadTextDesktop${currentDevice === 'Mobile' ? ' subHeadTextMobile' : ''}`}>{heading}</b>}
        {!heading && <b className={`subHeadTextDesktop${currentDevice === 'Mobile' ? ' subHeadTextMobile' : ''}${isSmallHeading ? ' SmalllHeading' : ''}`}>{`${ (isSmallHeading && !keyWordFinal)  ? `SEARCH RESULTS FOR "${categoryIdSearch?.label ? categoryIdSearch?.label : 'ALL PRODUCTS'}" CATEGORY` : isSmallHeading  }`}</b>}
        {isSmallHeading && keyWordFinal && <b className={`subHeadTextDesktop2${currentDevice === 'Mobile' ? ' subHeadTextMobile' : ''}${isSmallHeading ? ' SmalllHeading' : ''}`}>{`"${str}"`}</b>}
      </div>
    </>
  );
};

export default SubHeading;