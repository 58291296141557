import { useEffect, useState } from "react";
import { useStore } from "../store/home";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import Header from "./Header";
import Footer from "./Footer";
import ProductTile from "./ProductTile";
import { deviceDetector } from "../commonFunction";
import FindParts from "../commenComp/FindParts";
import loaderImg from "../Images/loaderImg.gif";
import "./Catalog.css";
import SideMenu from "../commenComp/SideMenu";
import PartsCatalog from "../commenComp/PartsCatalog.js";
import ShopComponent from "./ShopComponent";
import { useSearchParams } from "react-router-dom";
import {
  getMyCart,
  getPartList,
  getProductListCategory,
  searcPartsYMM,
} from "../api/posts";
import { useMutation, useQuery } from "@tanstack/react-query";
import Loader from "../commenComp/Loader";

const Catalog = () => {
  const {
    token,
    setVals,
    categorySide,
    setPartListArray,
    selectedSubCategory,
    isCurrentEngSetValue,
    setMyCartMini,
    userInfo,
    selectedCategory,
    globalcategoryIdCatalog,
    isCurrentEngSet,
    setOffset,
    dealerId,
    offset,
    setTRecord,
    sort,
    setBox,
    setRecord,
    setSelectedCategory,
    setSelectedSubCategory,
    setIsCurrentEngSet,
    partListArray,
    setGlobalcategoryIdCatalog,
    setIsCurrentEngSetValue,
    setSort,
  } = useStore();

  const { noPartLoad, setRefreshQ, refreshQ } = useNonPersistedStore();
  // const { pof, setPof } = useNonPersistedStore();
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if(isCurrentEngSet){
      setSort('comp')
    } else {
      setSort('partNumber')
    }
  }, [isCurrentEngSet])
  

  useEffect(() => {
    if (searchParams?.get("category")) {
      setSelectedCategory(searchParams?.get("category"));
    } else {
      setSelectedCategory("");
    }
    if (searchParams?.get("subCategory")) {
      setSelectedSubCategory(searchParams?.get("subCategory"));
    } else {
      setSelectedSubCategory("");
    }
    if (searchParams?.get("engineId")) {
      setVals(true);
      let obj = {};
      obj.engineId = searchParams?.get("engineId");
      obj.year = searchParams?.get("year");
      obj.make = searchParams?.get("make");
      obj.model = searchParams?.get("model");
      obj.isShopByDiagram = searchParams?.get("isShopByDiagram");
      setIsCurrentEngSet(obj);
      let obVal = {};
      obVal.engineId = searchParams?.get("engineId");
      obVal.year = searchParams?.get("yearId");
      obVal.make = searchParams?.get("makeId");
      obVal.model = searchParams?.get("modelId");
      setIsCurrentEngSetValue(obVal);
    } else {
      setIsCurrentEngSet(false);
      setIsCurrentEngSetValue(false);
    }
  }, [searchParams]);

  const email = userInfo.username;
  const mutation_cart = useMutation((post) => getMyCart(post, token), {
    onSuccess: (result) => {
      setMyCartMini(result);
    },
    onError: (error) => {},
  });
  useEffect(() => {
    const obj = {
      email: userInfo.username,
      minicart: true,
      dealerId: dealerId,
    };
    mutation_cart.mutate(obj);
  }, []);

  // const mutation1 = useMutation((post) => getProductListCategory(post), {
  //   mutationKey: "getProductListCategory",
  //   method: "GET",
  //   onSuccess: (result) => {
  //     setCategorySide(result.data.data)
  //   },
  //   onError: (error) => {
  //   },
  // });

  // useEffect(() => {
  //   const data = {
  //     token: token,
  //     EngineId: isCurrentEngSet.engineId,
  //   };
  //   mutation1.mutate(data);
  // }, [isCurrentEngSet])

  // const sideMenuData = useQuery({
  //   queryKey: ["sidemenu"],
  //   retry: 2,
  //   refetchOnWindowFocus: false,
  //   queryFn: () => getProductListCategory(token),
  // });

  const mutation = useMutation((post) => getPartList(post), {
    mutationKey: "getPartList",
    method: "GET",
    onSuccess: (result) => {
      setTRecord(result?.data?.total);
      setRecord(result?.data?.perPage);
      setRefreshQ(!refreshQ)
      setPartListArray(result?.data?.data);
      setGlobalcategoryIdCatalog(selectedCategory);
    },
    onError: (error) => {},
  });

  useEffect(() => {
    let timeoutId;
    if (!isCurrentEngSet) {
      setTRecord(0);
      setRecord(0);
      setOffset(parseInt(searchParams?.get("p") ?? 0));
      setBox(1);
      setPartListArray([]);
      let arr1 = [];
      if (partListArray && partListArray.length > 0) {
        arr1 = partListArray.map((item) => {
          item.images = loaderImg;
          item.isLoad = true;
          return item;
        });
      }
      setRefreshQ(!refreshQ)
      setPartListArray(arr1);
      const params = {
        token: token,
        categoryId: searchParams?.get("category") ?? selectedCategory,
        subCategoryId: searchParams?.get("subCategory") ?? selectedSubCategory,
        keyword: "",
        dealerId: dealerId,
        perPage: 20,
        offset: searchParams?.get("p") ?? 0,
        sortBy: searchParams?.get("s") ?? sort,
        // engineId:isCurrentEngSet?.engineId
      };
      timeoutId = setTimeout(() => {
        mutation.mutate(params);
      }, 0);
    }
    return () => {
      clearTimeout(timeoutId); // Clear the timeout when the component unmounts or when the effect runs again
    };
  }, [selectedCategory, selectedSubCategory, isCurrentEngSet, sort]);

  const searchParts = useMutation((post) => searcPartsYMM(post, token), {
    onSuccess: (result) => {
      const obj = { ...isCurrentEngSet };
      obj.engineId = result.data.engineId;
      obj.engineImage = result.data.engineImage;
      obj.isShopByDiagram = result.data.isShopByDiagram;
      setIsCurrentEngSet(obj);
      setTRecord(result?.data?.total);
      setRecord(result?.data?.perPage);
      setGlobalcategoryIdCatalog(selectedCategory);
      setRefreshQ(!refreshQ)
      setPartListArray(result?.data?.data);
    },
    onError: (error) => {},
  });

  // useEffect(() => {
  //   setTimeout(() => {
  //     setPof(false);
  //   }, 500);
  // }, []);

  useEffect(() => {
    let timeoutId;
    if (isCurrentEngSetValue) {
      setTRecord(0);
      setRecord(0);
      setOffset(parseInt(searchParams?.get("p") ?? 0));
      setBox(1);
      setPartListArray([]);
      let arr1 = [];
      if (partListArray && partListArray.length > 0) {
        arr1 = partListArray.map((item) => {
          item.images = loaderImg;
          item.isLoad = true;
          return item;
        });
      }
      setRefreshQ(!refreshQ)
      setPartListArray(arr1);
      const arr = {
        year: searchParams?.get("yearId") ?? isCurrentEngSetValue.year,
        make: searchParams?.get("makeId") ?? isCurrentEngSetValue.make,
        model: searchParams?.get("modelId") ?? isCurrentEngSetValue.model,
        dealerId: dealerId,
        categoryId: searchParams?.get("category") ?? selectedCategory,
        subcategoryId: searchParams?.get("subCategory") ?? selectedSubCategory,
        perPage: 20,
        offset: searchParams?.get("p") ?? 0,
        sortBy: searchParams?.get("s") ?? sort,
      };
      timeoutId = setTimeout(() => {
        searchParts.mutate(arr);
      }, 0);
    }
    return () => {
      clearTimeout(timeoutId); // Clear the timeout when the component unmounts or when the effect runs again
    };
  }, [selectedCategory, selectedSubCategory, isCurrentEngSetValue, sort]);

  useEffect(() => {
    if (categorySide && Object.entries(categorySide)) {
      let res = [];
      let subcat = [];
      Object.entries(categorySide)?.forEach((val) => {
        const arr = [];
        val[1].subcategories &&
          Object.entries(val[1].subcategories)?.forEach((item) => {
            arr.push({ label: item[0], value: item[1]?.id });
          });
        res.push({ label: val[0], value: val[1]?.id, arr: arr });
      });
      setCategoryList(res);
    }
  }, [categorySide]);

  // useEffect(() => {
  //   if (sideMenuData?.data?.data?.data) {
  //     setCategorySide(sideMenuData?.data?.data.data);
  //   }
  // }, [sideMenuData?.data?.data.data]);

  let sortOptions = [
    {
      label: "Part Number",
      value: "partNumber",
    },
    {
      label: "Price",
      value: "price",
    },
  ];
  return (
    <div className="catalogMainDiv">
      {/* {mutation.isLoading && <Loader></Loader>} */}
      {/* {searchParts.isLoading && <Loader></Loader>} */}
      <div>
        <Header page="Catalog"></Header>
        <PartsCatalog />
        <ShopComponent
          cat={{ label: globalcategoryIdCatalog, value: selectedCategory }}
          categoryList={categoryList}
        />
      </div>
      <div className="catalog__mainbody">
        <SideMenu data={categorySide} />
        <ProductTile
          load={
            mutation.isLoading ||
            searchParts.isLoading ||
            noPartLoad ||
            mutation.isLoading
          }
          sortOptions={sortOptions}
        ></ProductTile>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Catalog;
