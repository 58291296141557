import { Button, styled } from "@mui/material";
export const ActiveInactiveStyled = styled(Button)(
  ({ theme, buttonColor, expiredStatus, status }) => ({
    color: status ? "#FFF" : expiredStatus ? "#FFF" : "#615E5C",
    fontFamily: "Montserrat",
    fontSize: "12px",
    padding: "2px 12px",
    fontWeight: "700",
    cursor: "unset",

    textTransform: "uppercase",

    borderRadius: "12px",
    "&:hover": {
      background: status ? "#40A900" : "grey",
    },

    background: buttonColor ? buttonColor : status ? "#40A900" : "#EAEAEA",
  })
);

export const OrderStatusStyled = styled(Button)(
  ({ theme, buttonColor, status }) => ({
    color: status ? "#FFF !important" : "#615E5C",
    fontFamily: "Montserrat",
    fontSize: "12px",
    padding: "2px 12px !important",
    fontWeight: "700",
    cursor: "unset",

    textTransform: "uppercase",

    borderRadius: "12px !important",
    // '&:hover': {
    //   background: status ? "#40A900" : "grey",

    //   },

    background:
      status === "Completed"
        ? "#40A900"
        : status === "shipped"
        ? "#40A900"
        : status === "cancelled"
        ? "#a19d9d"
        : status === "returned"
        ? "#a19d9d"
        : status === "pending"
        ? "#ce0e2d"
        : status === "processing"
        ? "#EE9209"
        : "#EE9209",
  })
);

export const AddEmployeeAccountBtnStyled = styled(Button)(
  ({ theme, status }) => ({
    
    fontSize: "13px",
    backgroundColor: "#CE0E2D !important",
    textTransform: "none",
    letterSpacing: "1px",
    padding: " 12px 20px !important",
    borderRadius: "0px",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "5px",
    border: "none",
    color: "#FFF !important",
    textAlign: "center",
    /* Text sm/Bold */
    fontSize: "13px",
    fontFamily: "Montserrat",
    fontWeight: "700",
 
    "&:disabled": {
      backgroundColor: "grey",
    },
    "&:hover": {
      background: "#CE0E2D",
      transitionDuration: "500ms",
 
      background: " #900a1f",
    },
    cursor: "pointer",
  })
);