import { Box, Divider, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../store/home";
import Footer from "../Footer";
import Header from "../Header";
import { useForm, Controller } from "react-hook-form";
import { Radio } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { contactSchema } from "../../utils/schemas/contactSchema";
import ReCAPTCHA from "react-google-recaptcha";
import Select from "react-select";
import {
  ErrorMessageStyled,
  ShippingPolicyGreyHeading,
} from "../StyledComponents/CommonStyled";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { StyledShippingSubHeading } from "../StyledComponents/ShippingAddressStyled";
import { parsePhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";
import { customStyles } from "../../utils/ReactSelectCustomStyles";
import { ContainerDivStyled } from "../StyledComponents/BusinessDetailsStyled";
import { postContactFormdata } from "../../api/posts";
import { useMutation } from "@tanstack/react-query";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import ContactUsTemplateModal from "../../commenComp/ContactUsTemplateModal";
const FooterContent = () => {
  const [contentDataFromChild, setContentDataFromChild] = useState();
  const [checked, setIsChecked] = useState(false);
  const [nationalNumber, setNationalNumber] = useState("");
  const [completePhoneNumber, setCompletePhoneNumber] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const reasonRef = useRef();
  const phoneInputRef = useRef();

  const {
    setFooterKeyZustand,
    footerKeyZustand,
    setFooterContentdataInZustand,
    footerContentdataInZustand,
    currentDevice,
    token,
  } = useStore();
  const navigate = useNavigate();

  const defineCallbackFunctionInParent = (contentData) => {
    setContentDataFromChild(contentData);
  };
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const handleCaptchaChange = (value) => {
    // This function will be called when the user interacts with the reCAPTCHA component
    setIsCaptchaVerified(true);
  };

  const {
    register,
    watch,
    handleSubmit,
    reset,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(contactSchema),
  });
  const postContactFormdataMutation = useMutation(
    (post) => postContactFormdata(post, token),
    {
      onSuccess: (result) => {
        if (result) {
          if (result.data.success) {
            setIsModalOpen(true);
            reset();
          } else {
            showErrorToast(result?.data?.message);
          }
        }
      },
      onError: (error) => {},
    }
  );
  const handlePhoneNumberChange = (data, field) => {
    if (!data) {
      setCompletePhoneNumber("");
      setNationalNumber("");

      return;
    }

    try {
      const parsedNumber = parsePhoneNumber(data?.toString());
      setCompletePhoneNumber(parsedNumber?.number);
      setNationalNumber(parsedNumber?.nationalNumber);
    } catch (error) {}
  };
  const onSubmit = (data, event) => {
    // setValue("contactReason", {label:null,value:null});
    // setValue("primaryPhone", null);

    event.preventDefault();

    postContactFormdataMutation.mutate({
      ...data,
      isReceiveProductServiceInfo: checked,
      phoneNumber: completePhoneNumber,
      contactReason: data?.contactReason?.label,
    });
    setValue("contactReason", { label: null, value: null });
    setValue("primaryPhone", null);
  };
  const handleChange = (flag) => {
    setIsChecked(flag);
  };
  // const StyledRadio = styled(Radio)({
  //   color: "black", // Change the color here
  //   "&.Mui-checked": {
  //     color: "black", // Change the color for the checked state
  //   },
  // });

  const reasonDropdown = [
    {
      label: "Product Related",
      value: 55,
    },
    {
      label: "Engine Related",
      value: 79,
    },
    {
      label: "Other",
      value: 84,
    },
  ];

  const handleCancel = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <ContactUsTemplateModal
        isopen={isModalOpen}
        // handleOkay={handleOkay}
        handleClose={handleCancel}
      >
        {" "}
      </ContactUsTemplateModal>

      <Header page="Catalog"></Header>
      {footerContentdataInZustand[0]?.layout == "Layout 1" &&
        footerContentdataInZustand?.map((item) => {
          return (
            <>
              <ShippingPolicyGreyHeading
                sx={{
                  backgroundColor: "#E4E4E4",
                  padding: "20px 48px 20px 48px",
                }}
              >
                {item?.title}
              </ShippingPolicyGreyHeading>
            </>
          );
        })}
      {footerContentdataInZustand[0]?.layout == "Layout 1" && (
        <Grid
          container
          
          justifyContent="center"
          spacing={2}
          sx={{ wordBreak: "break-word", padding: "30px 0px 70px 0px" }}
        >
          <Grid item xs={8}>
            {footerContentdataInZustand?.map((item) => {
              return (
                <>
                  <Box
                    dangerouslySetInnerHTML={{ __html: item?.contentData }}
                  />
                </>
              );
            })}
          </Grid>
        </Grid>
      )}

      {footerContentdataInZustand[0]?.layout == "Layout 2" && (
        <ContainerDivStyled
          className="mobilePaddingContactUsilmor sdf35866"
          currentDevice={currentDevice}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            xs={12}
            lg={8}
            spacing={4}
            sx={{ wordBreak: "break-word", margin: '0 auto',
            padding: currentDevice =="Desktop" ?"0px 0px 70px 0px" : "30px 10px 70px 0px"
            // padding:"30px 0px 70px 70px"
         }}
          >
            {footerContentdataInZustand[0]?.formType == "Contact" &&
            footerContentdataInZustand[0]?.position == "header" ? (
              <>
                {" "}
                <Grid item xs={9}>
                  {footerContentdataInZustand[0]?.formType == "Contact" && (
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className={`form shippingForm`}
                      noValidate
                    >
                      <div className={`passwordlabelDesktopAddShipping`}>
                        <Box
                          component="span"
                          sx={{
                            display: "inline",

                            fontSize: "80%",
                            fontWeight: "700",
                            marginBottom: "2px",
                            letterSpacing: "2px",
                          }}
                        >
                          REASON FOR CONTACTING
                        </Box>
                        <Box
                          component="span"
                          sx={{
                            display: "inline",

                            fontSize: "80%",
                            fontWeight: "700",
                            marginBottom: "2px",
                            letterSpacing: "2px",
                            color: "red",
                          }}
                        >
                          *
                        </Box>
                      </div>
                      <Controller
                        // rules={{ required: true }}
                        name="contactReason"
                        control={control}
                        defaultValue=""
                        render={({ onChange, value, ref, field }) => {
                          return (
                            <Select
                            className='jkdhs'
                              {...field}
                              options={reasonDropdown}
                              // value={countrySelectOption?.find(
                              //   (option) => option.value === field.value
                              // )}
                              // // value={}
                              // onChange={(selectedOption) => {
                              //   field.onChange(selectedOption?.value);
                              //   handleCountrySelectChange(selectedOption?.value);
                              // }}
                              value={
                                reasonDropdown?.find(
                                  (option) => option.value === field.value
                                ) || null
                              }
                              styles={customStyles}
                              //  menuIsOpen={true}
                              // defaultValue={userRole.find((c) => c.value === countryValue)}
                            />
                          );
                        }}
                      />

                      {errors?.contactReason && (
                        <ErrorMessageStyled>
                          {errors?.contactReason?.message
                            ? "Reason is required"
                            : ""}
                        </ErrorMessageStyled>
                      )}
                      <Grid container spacing={1}>
                        <Grid
                          item
                          sm={6}
                          xs={6}
                          md={6}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div>
                            <h5 className={`labelDesktopAddShippingRequired`}>
                              First Name
                            </h5>
                          </div>
                          <input
                            // maxlength="5"
                            // style={{ border: "none" }}
                            className={`InputFieldShippingAddress`}
                            type="text"
                            // value={email}
                            name="firstName"
                            maxLength={50}
                            // onChange={handleEmailChange}
                            {...register("firstName")}
                          ></input>
                          {errors?.firstName && (
                            <ErrorMessageStyled>
                              {errors.firstName?.message}
                            </ErrorMessageStyled>
                          )}
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          xs={6}
                          md={6}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div>
                            <h5 className={`labelDesktopAddShippingRequired`}>
                              Last Name
                            </h5>
                          </div>
                          <input
                            // style={{ border: "none" }}
                            className={`InputFieldShippingAddress`}
                            type="text"
                            // value={email}
                            name="lastName"
                            maxLength={50}
                            // onChange={handleEmailChange}
                            {...register("lastName")}
                          ></input>
                          {errors?.lastName && (
                            <ErrorMessageStyled>
                              {errors.lastName?.message}
                            </ErrorMessageStyled>
                          )}
                        </Grid>
                      </Grid>

                      <div className={`emailLabel`}>
                        <h5 className={`labelDesktopAddShippingRequired`}>
                          EMAIL 1
                        </h5>
                      </div>
                      <input
                        // style={{ border: "none" }}
                        className={`InputFieldShippingAddress`}
                        type="text"
                        // value={email}
                        name="email"
                        maxLength={100}
                        // onChange={handleEmailChange}
                        {...register("email")}
                      ></input>
                      {errors?.email && (
                        <ErrorMessageStyled>
                          {errors.email?.message}
                        </ErrorMessageStyled>
                      )}

                      <div className={`passwordlabelDesktopAddShipping`}>
                        <h5 className={`labelDesktopAddShipping`}>
                          PHONE NUMBER
                        </h5>
                      </div>
                      <Controller
                        // rules={{ required: true }}
                        name="primaryPhone"
                        control={control}
                        render={({ onChange, value, onBlur, ref, field }) => {
                          return (
                            <PhoneInput
                              {...field}
                              ref={phoneInputRef}
                              // value={userRole.find((c) => c.value === value)}
                              // onChange={(selectedOption) => {
                              //   handleSelectChange(selectedOption?.label,selectedOption?.value);
                              // }}
                              // countries =  {["RU", "UA", "KZ","IN","PK","US","NZ"]}
                              // placeholder="Enter Phone Number"
                              // onChange={(data)=>handlePhoneNumberChange(data,field)}
                              onChange={handlePhoneNumberChange}
                              //  international
                              countryCallingCodeEditable={false}
                              addInternationalOption={false}
                              defaultCountry="US"
                              className="ReactPhoneNumInputt"
                              minLength="7"
                              limitMaxLength={true}
                              style={{
                                backgroundColor: "white",
                                border: "none",
                                padding: "0px 10px",
                              }}
                            />
                          );
                        }}
                      />
                      {errors?.primaryPhone && (
                        <ErrorMessageStyled>
                          {errors.primaryPhone?.message}
                        </ErrorMessageStyled>
                      )}

                      <div>
                        <h5 className={`labelDesktopAddShippingRequired`}>
                          COMPANY/ORGANIZATION
                        </h5>
                      </div>
                      <input
                        // style={{ border: "none" }}
                        className={`InputFieldShippingAddress`}
                        type="text"
                        // value={email}
                        name="companyOrganization"
                        maxLength={100}
                        // onChange={handleEmailChange}
                        {...register("companyOrganization")}
                      />
                      {errors?.companyOrganization && (
                        <ErrorMessageStyled>
                          {errors?.companyOrganization?.message}
                        </ErrorMessageStyled>
                      )}
                      <h5 className="exemption dateHeading labelDesktopAddShippingRequired">
                        COMMENTS/QUESTIONS
                      </h5>
                      <textarea
                        className="Txtarea"
                        type="textarea"
                        name="content"
                        minLength={10}
                        maxLength={500}
                        {...register("content")}
                      />
                      {/* <div className='caldate'> */}
                      {errors?.content && (
                        <ErrorMessageStyled>
                          {errors?.content?.message}
                        </ErrorMessageStyled>
                      )}
                      <Box
                        sx={{
                          marginTop: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <input
                          className={`inputCheckboxGlobal`}
                          type="checkbox"
                          id="makeDefaults"
                          name="isReceiveNewletter"
                          // value={true}
                          {...register("isReceiveNewletter")}
                        />
                        <label
                          className={`labelCheckboxGlobal`}
                          for="makeDefaults"
                        >
                          I'd Like to recieve the ilmor newsletter
                        </label>
                      </Box>

                      <Box>
                        {" "}
                        <Radio
                          checked={checked}
                          onChange={handleChange}
                          value={true}
                          color="black"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "A" }}
                        />
                        <span className="personDetail padRight">
                          Yes, I want to receive information about Ilmor's
                          products and services.
                        </span>
                      </Box>

                      <Radio
                        checked={!checked}
                        onChange={handleChange}
                        value={false}
                        color="black"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "A" }}
                      />
                      <span className="personDetail padRight">
                        No, I do not want to receive information about Ilmor's
                        products and services.
                      </span>
                      <Box
                        sx={{
                          display: "flex",

                          flexDirection: "row",
                          flexGrow: 1,
                          my: 1.5,
                          gap: 2,
                          alignItems: "center",

                          borderRadius: 1,
                        }}
                      >
                        <button
                          // className='select'}
                          className={`ilmorButon big`}
                          //  onClick={handleSignIn}
                        >
                          Submit
                        </button>
                      </Box>
                    </form>
                  )}
                </Grid>
              </>
            ) : (
              <Grid
                item
                // sm={10}
                // md={10}
                xs={12}
                className="greyHeadingContainerContactIlmor"
              >
                <>
                  <ShippingPolicyGreyHeading className="greyHeadingContactIlmor">
                    {footerContentdataInZustand[0]?.title}
                  </ShippingPolicyGreyHeading>

                  {currentDevice != "Desktop" && (
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: footerContentdataInZustand[0]?.headerContent,
                      }}
                    />
                  )}
                  {currentDevice == "Desktop" && (
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: footerContentdataInZustand[0]?.headerContent,
                      }}
                      sx={{
                        maxWidth: "950px",
                      }}
                    />
                  )}
                </>
               </Grid>
            )}

            <Grid
              // className="sdkjbfbkj desktopMArginLEftPadding"
              item
              xs={12}
              sm={8}
              // md={8}
            >
              {footerContentdataInZustand[0]?.formType == "Contact" &&
                footerContentdataInZustand[0]?.position == "left" && (
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={`form shippingForm`}
                    noValidate
                  >
                    <div
                      className={`passwordlabelDesktopAddShipping marbotcon marbortbor`}
                    >
                      <Box
                        className="fontcontfomr"
                        component="span"
                        sx={
                          {
                            // display: "inline",
                            // fontSize: "80%",
                            // fontWeight: "700",
                            // marginBottom: "2px",
                            // letterSpacing: "2px",
                          }
                        }
                      >
                        Contact Form
                      </Box>
                    </div>
                    <div
                      className={`passwordlabelDesktopAddShipping marbotcon`}
                    >
                      <Box
                        className="fontwetstile fon labelDesktopAddShippingRequiredBefore"
                        component="span"
                        sx={
                          {
                            // display: "inline",
                            // fontSize: "80%",
                            // fontWeight: "700",
                            // marginBottom: "2px",
                            // letterSpacing: "2px",
                          }
                        }
                      >
                        Required fields
                      </Box>
                    </div>
                    <div className={`passwordlabelDesktopAddShipping bortop`}>
                      <Box
                        component="span"
                        sx={{
                          display: "inline",
                          fontSize: "71%",
                          fontWeight: "680",
                          marginBottom: "2px",
                          letterSpacing: "2px",
                        }}
                      >
                        REASON FOR CONTACTING
                      </Box>
                      <Box
                        component="span"
                        sx={{
                          display: "inline",

                          fontSize: "80%",
                          fontWeight: "700",
                          marginBottom: "2px",
                          letterSpacing: "2px",
                          color: "red",
                        }}
                      >
                        *
                      </Box>
                    </div>
                    <Controller
                      // rules={{ required: true }}
                      name="contactReason"
                      defaultValue=""
                      control={control}
                      render={({ onChange, value, ref, field }) => {
                        return (
                          <Select
                            className="selReason jkdhs"
                            {...field}
                            options={reasonDropdown}
                            // value={countrySelectOption?.find(
                            //   (option) => option.value === field.value
                            // )}
                            // // value={}
                            // onChange={(selectedOption) => {
                            //   field.onChange(selectedOption?.value);
                            //   handleCountrySelectChange(selectedOption?.value);
                            // }}
                            styles={customStyles}
                            placeholder=""
                            //  menuIsOpen={true}
                            // defaultValue={userRole.find((c) => c.value === countryValue)}
                          />
                        );
                      }}
                    />

                    {errors?.contactReason && (
                      <ErrorMessageStyled>
                        {errors?.contactReason?.message
                          ? "Reason is required"
                          : ""}
                      </ErrorMessageStyled>
                    )}
                    {currentDevice == "Desktop" && (
                      <Divider sx={{ margin: "30px 0px 10px 0px" }} />
                    )}

                    <Grid className={""} container spacing={1}>
                      <Grid
                        item
                        sm={6}
                        xs={12}
                        md={6}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <h5 className={`labelDesktopAddShippingRequired`}>
                            First Name
                          </h5>
                        </div>
                        <input
                          // maxlength="5"
                          // style={{ border: "none" }}
                          className={`InputFieldShippingAddress`}
                          type="text"
                          // value={email}
                          name="firstName"
                          maxLength={50}
                          // onChange={handleEmailChange}
                          {...register("firstName")}
                        ></input>
                        {errors?.firstName && (
                          <ErrorMessageStyled>
                            {errors.firstName?.message}
                          </ErrorMessageStyled>
                        )}
                      </Grid>
                      <Grid
                        item
                        sm={6}
                        xs={12}
                        md={6}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <h5 className={`labelDesktopAddShippingRequired`}>
                            Last Name
                          </h5>
                        </div>
                        <input
                          // style={{ border: "none" }}
                          maxLength={50}
                          className={`InputFieldShippingAddress`}
                          type="text"
                          // value={email}
                          name="lastName"
                          // onChange={handleEmailChange}
                          {...register("lastName")}
                        ></input>
                        {errors?.lastName && (
                          <ErrorMessageStyled>
                            {errors.lastName?.message}
                          </ErrorMessageStyled>
                        )}
                      </Grid>
                    </Grid>

                    <div className={`emailLabel`}>
                      <h5 className={`labelDesktopAddShippingRequired`}>
                        EMAIL
                      </h5>
                    </div>
                    <input
                      // style={{ border: "none" }}
                      className={`InputFieldShippingAddress`}
                      type="text"
                      // value={email}
                      name="email"
                      maxLength={100}
                      // onChange={handleEmailChange}
                      {...register("email")}
                    ></input>
                    {errors?.email && (
                      <ErrorMessageStyled>
                        {errors.email?.message}
                      </ErrorMessageStyled>
                    )}

                    <div className={`passwordlabelDesktopAddShipping`}>
                      <h5
                        className={`labelDesktopAddShipping`}
                        style={{
                          fontSize: "71%",
                          fontWeight: "680",
                        }}
                      >
                        PHONE NUMBER
                      </h5>
                    </div>
                    <Controller
                      // rules={{ required: true }}
                      name="primaryPhone"
                      defaultValue=""
                      control={control}
                      render={({ onChange, value, onBlur, ref, field }) => {
                        return (
                          <PhoneInput
                            ref={phoneInputRef}
                            {...field}
                            // value={userRole.find((c) => c.value === value)}
                            // onChange={(selectedOption) => {
                            //   handleSelectChange(selectedOption?.label,selectedOption?.value);
                            // }}
                            // countries =  {["RU", "UA", "KZ","IN","PK","US","NZ"]}
                            // placeholder="Enter Phone Number"
                            // onChange={(data)=>handlePhoneNumberChange(data,field)}
                            onChange={handlePhoneNumberChange}
                            //  international
                            countryCallingCodeEditable={false}
                            addInternationalOption={false}
                            defaultCountry="US"
                            className="ReactPhoneNumInputt"
                            minLength="7"
                            limitMaxLength={true}
                          
                            style={{
                              backgroundColor: "white",
                              border: "none",
                              padding: "0px 10px",
                            
                            }}
                          />
                        );
                      }}
                    />
                    {errors?.primaryPhone && (
                      <ErrorMessageStyled>
                        {errors.primaryPhone?.message}
                      </ErrorMessageStyled>
                    )}

                    <div>
                      <h5 className={`labelDesktopAddShippingRequired`}>
                        COMPANY/ORGANIZATION
                      </h5>
                    </div>
                    <input
                      // style={{ border: "none" }}
                      className={`InputFieldShippingAddress`}
                      type="text"
                      maxLength={100}
                      // value={email}
                      name="companyOrganization"
                      // onChange={handleEmailChange}
                      {...register("companyOrganization")}
                    />
                    {errors?.companyOrganization && (
                      <ErrorMessageStyled>
                        {errors?.companyOrganization?.message}
                      </ErrorMessageStyled>
                    )}
                    <h5 className="exemption dateHeading labelDesktopAddShippingRequired aachakMArginTopConTActDesktop">
                      COMMENTS/QUESTIONS
                    </h5>
                    <textarea
                      className="Txtarea TxtareacontactDsktop InputFieldShippingAddress"
                      type="textarea"
                      name="content"
                      minLength={10}
                      maxLength={500}
                      {...register("content")}
                    />
                    {/* <div className='caldate'> */}
                    {errors?.content && (
                      <ErrorMessageStyled>
                        {errors?.content?.message}
                      </ErrorMessageStyled>
                    )}
                    <Box
                      className={"bortop"}
                      sx={{
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        className={`inputCheckboxGlobal`}
                        type="checkbox"
                        id="makeDefaults"
                        name="isReceiveNewletter"
                        // value={true}
                        {...register("isReceiveNewletter")}
                      />
                      <label
                        className={`labelCheckboxGlobal`}
                        for="makeDefaults"
                      >
                        I'd Like to recieve the ilmor newsletter
                      </label>
                    </Box>

                    <Box
                      sx={{
                        marginLeft: "-7px",
                        marginTop: "11px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <Radio
                        checked={checked}
                        onChange={() => {
                          handleChange(true);
                        }}
                        className="dktnjg567"
                        value={checked}
                        color="black"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "A" }}
                      />
                      <span className="personDetail padRight fontwetstile">
                        Yes, I want to receive information about Ilmor's
                        products and services.
                      </span>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "-7px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Radio
                        checked={!checked}
                        onChange={() => {
                          handleChange(false);
                        }}
                        className="dktnjg567"
                        value={!checked}
                        color="black"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "A" }}
                      />
                      <span className="personDetail padRight fontwetstile ">
                        No, I do not want to receive information about Ilmor's
                        products and services.
                      </span>
                    </Box>
                    <Box className="margincaptcha">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        onChange={handleCaptchaChange}
                      />
                    </Box>
                    <Box
                      sx={
                        {
                          // marginLeft: "-7px",
                          // marginTop: "11px",
                          // display: "flex",
                          // alignItems: "center",
                        }
                      }
                    >
                      <span className="personDetail padRight fontwetstile fontwetstileDesktopFOntSize">
                        {`By submitting this form, you agree to the `}
                        <span className={`partsDe_container5LinkDesktop col`}>
                          Terms of Use
                        </span>
                        {` and `}
                        <span className={`partsDe_container5LinkDesktop col`}>
                          Privacy Policy
                        </span>
                        {` of this website.`}
                      </span>
                    </Box>
                    <Box
                      className="bortop"
                      sx={{
                        display: "flex",

                        flexDirection: "row",
                        flexGrow: 1,
                        my: 1.5,
                        gap: 2,
                        alignItems: "center",

                        borderRadius: 1,
                      }}
                    >
                      <button
                        disabled={!isCaptchaVerified}
                        // className='select'}
                        className={`ilmorButon big`}
                        //  onClick={handleSignIn}
                      >
                        Submit
                      </button>
                    </Box>
                  </form>
                )}
            </Grid>

            {footerContentdataInZustand[0]?.formType == "Contact" &&
            footerContentdataInZustand[0]?.position == "right" ? (
              <>
                {" "}
                <Grid item xs={6}>
                  {footerContentdataInZustand[0]?.formType == "Contact" && (
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className={`form shippingForm`}
                      noValidate
                    >
                      <div className={`passwordlabelDesktopAddShipping`}>
                        <Box
                          component="span"
                          sx={{
                            display: "inline",

                            fontSize: "80%",
                            fontWeight: "700",
                            marginBottom: "2px",
                            letterSpacing: "2px",
                          }}
                        >
                          REASON FOR CONTACTING
                        </Box>
                        <Box
                          component="span"
                          sx={{
                            display: "inline",

                            fontSize: "80%",
                            fontWeight: "700",
                            marginBottom: "2px",
                            letterSpacing: "2px",
                            color: "red",
                          }}
                        >
                          *
                        </Box>
                      </div>
                      <Controller
                        // rules={{ required: true }}
                        name="contactReason"
                        control={control}
                        render={({ onChange, value, ref, field }) => {
                          return (
                            <Select
                              className='jkdhs'
                              {...field}
                              options={reasonDropdown}
                              // value={countrySelectOption?.find(
                              //   (option) => option.value === field.value
                              // )}
                              // // value={}
                              // onChange={(selectedOption) => {
                              //   field.onChange(selectedOption?.value);
                              //   handleCountrySelectChange(selectedOption?.value);
                              // }}
                              styles={customStyles}
                              //  menuIsOpen={true}
                              // defaultValue={userRole.find((c) => c.value === countryValue)}
                            />
                          );
                        }}
                      />

                      {errors?.contactReason && (
                        <ErrorMessageStyled>
                          {errors?.contactReason?.message
                            ? "Reason is required"
                            : ""}
                        </ErrorMessageStyled>
                      )}
                      <Grid container spacing={1}>
                        <Grid
                          item
                          sm={6}
                          xs={6}
                          md={6}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div>
                            <h5 className={`labelDesktopAddShippingRequired`}>
                              First Name
                            </h5>
                          </div>
                          <input
                            // maxlength="5"
                            // style={{ border: "none" }}
                            className={`InputFieldShippingAddress`}
                            type="text"
                            maxLength={50}
                            // value={email}
                            name="firstName"
                            // onChange={handleEmailChange}
                            {...register("firstName")}
                          ></input>
                          {errors?.firstName && (
                            <ErrorMessageStyled>
                              {errors.firstName?.message}
                            </ErrorMessageStyled>
                          )}
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          xs={6}
                          md={6}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div>
                            <h5 className={`labelDesktopAddShippingRequired`}>
                              Last Name
                            </h5>
                          </div>
                          <input
                            // style={{ border: "none" }}
                            className={`InputFieldShippingAddress`}
                            type="text"
                            maxLength={50}
                            // value={email}
                            name="lastName"
                            // onChange={handleEmailChange}
                            {...register("lastName")}
                          ></input>
                          {errors?.lastName && (
                            <ErrorMessageStyled>
                              {errors.lastName?.message}
                            </ErrorMessageStyled>
                          )}
                        </Grid>
                      </Grid>

                      <div className={`emailLabel`}>
                        <h5 className={`labelDesktopAddShippingRequired`}>
                          EMAIL 1
                        </h5>
                      </div>
                      <input
                        // style={{ border: "none" }}
                        className={`InputFieldShippingAddress`}
                        type="text"
                        maxLength={100}
                        // value={email}
                        name="email"
                        // onChange={handleEmailChange}
                        {...register("email")}
                      ></input>
                      {errors?.email && (
                        <ErrorMessageStyled>
                          {errors.email?.message}
                        </ErrorMessageStyled>
                      )}

                      <div className={`passwordlabelDesktopAddShipping`}>
                        <h5 className={`labelDesktopAddShipping`}>
                          PHONE NUMBER
                        </h5>
                      </div>
                      <Controller
                        // rules={{ required: true }}
                        name="primaryPhone"
                        control={control}
                        render={({ onChange, value, onBlur, ref, field }) => {
                          return (
                            <PhoneInput
                              {...field}
                              ref={phoneInputRef}
                              // value={userRole.find((c) => c.value === value)}
                              // onChange={(selectedOption) => {
                              //   handleSelectChange(selectedOption?.label,selectedOption?.value);
                              // }}
                              // countries =  {["RU", "UA", "KZ","IN","PK","US","NZ"]}
                              placeholder="Enter Phone Number"
                              // onChange={(data)=>handlePhoneNumberChange(data,field)}
                              onChange={handlePhoneNumberChange}
                              //  international
                              countryCallingCodeEditable={false}
                              addInternationalOption={false}
                              defaultCountry="US"
                              className="ReactPhoneNumInputt"
                              minLength="7"
                              limitMaxLength={true}
                              style={{
                                backgroundColor: "white",
                                border: "none",
                                padding: "0px 10px",
                              }}
                            />
                          );
                        }}
                      />
                      {errors?.primaryPhone && (
                        <ErrorMessageStyled>
                          {errors.primaryPhone?.message}
                        </ErrorMessageStyled>
                      )}

                      <div>
                        <h5 className={`labelDesktopAddShippingRequired`}>
                          COMPANY/ORGANIZATION
                        </h5>
                      </div>
                      <input
                        // style={{ border: "none" }}
                        className={`InputFieldShippingAddress`}
                        type="text"
                        maxLength={100}
                        // value={email}
                        name="companyOrganization"
                        // onChange={handleEmailChange}
                        {...register("companyOrganization")}
                      />
                      {errors?.companyOrganization && (
                        <ErrorMessageStyled>
                          {errors?.companyOrganization?.message}
                        </ErrorMessageStyled>
                      )}
                      <h5 className="exemption dateHeading labelDesktopAddShippingRequired">
                        COMMENTS/QUESTIONS
                      </h5>
                      <textarea
                        className="Txtarea"
                        type="textarea"
                        name="content"
                        minLength={10}
                        maxLength={500}
                        {...register("content")}
                      />
                      {/* <div className='caldate'> */}
                      {errors?.content && (
                        <ErrorMessageStyled>
                          {errors?.content?.message}
                        </ErrorMessageStyled>
                      )}

                      <input
                        className={`inputCheckboxGlobal`}
                        type="checkbox"
                        id="makeDefaults"
                        name="isReceiveNewletter"
                        // value={true}
                        {...register("isReceiveNewletter")}
                      />
                      <label
                        className={`labelCheckboxGlobal`}
                        for="makeDefaults"
                      >
                        I'd Like to recieve the ilmor newsletter
                      </label>
                      <Box>
                        {" "}
                        <Radio
                          checked={checked}
                          onChange={handleChange}
                          value={true}
                          color="black"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "A" }}
                        />
                        <span className="personDetail padRight">
                          Yes, I want to receive information about Ilmor's
                          products and services.
                        </span>
                      </Box>

                      <Radio
                        checked={!checked}
                        onChange={handleChange}
                        value={false}
                        color="black"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "A" }}
                      />
                      <span className="personDetail padRight">
                        No, I do not want to receive information about Ilmor's
                        products and services.
                      </span>
                      <Box
                        sx={{
                          display: "flex",

                          flexDirection: "row",
                          flexGrow: 1,
                          my: 1.5,
                          gap: 2,
                          alignItems: "center",

                          borderRadius: 1,
                        }}
                      >
                        <button
                          // className='select'}
                          className={`ilmorButon big`}
                          //  onClick={handleSignIn}
                        >
                          Submit
                        </button>
                      </Box>
                    </form>
                  )}
                </Grid>
              </>
            ) : (
              <Grid item xs={12} sm={4} className="gridAlignContactUs">
                {" "}
                {footerContentdataInZustand?.map((item) => {
                  return (
                    <>
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: item?.rightContent,
                        }}
                      />
                    </>
                  );
                })}
              </Grid>
            )}
          </Grid>
        </ContainerDivStyled>
      )}

      <Footer defineCallbackFunctionInParent={defineCallbackFunctionInParent} />
    </>
  );
};

export default FooterContent;
