import "./ShippingPaymentComponent.css";
import ShippingPayment from "./ShippingPayment";
import Divider from "@mui/material/Divider";
import ReviewOrder from "./ReviewOrder";
import OrderComplete from "./OrderComplete";
import OrderSummary from "./OrderSummary";
import { useStore } from "../store/home";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import { ShoppingListComponent } from "./ShoppingListComponent";
import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const ShippingPaymentComponent = ({
  reviewOrder,
  dataArray,
  handleRefreshCallBack,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { currentDevice, allow } = useStore();
  const { setTempSummary, tempSummary } = useNonPersistedStore();

  const navigate = useNavigate();
  useEffect(() => {
    if (!allow) {
      navigate({
        pathname: "/cart",
      });
    }
  }, [allow]);

  const handleUpdateOrder = (data) => {
    if (data?.estiamtedTotal) {
      setTempSummary(data);
    }
  };
  return (
    <>
      {currentDevice === "Desktop" && (
        <Grid container spacing={1}>
          {/* <div className="ShippingPaymentComponentTop"> */}
          <Grid item xs={12} md={8}>
            <ShippingPayment
              handleUpdateOrder={handleUpdateOrder}
              handleRefreshCallBack={handleRefreshCallBack}
              dataArray={dataArray}
              reviewOrder={reviewOrder}
            ></ShippingPayment>
            {!reviewOrder && (
              <ShoppingListComponent
                reviewOrder={!reviewOrder}
                orderDetail={true}
                dataArray={dataArray?.items}
                addtoCart={false}
              ></ShoppingListComponent>
            )}
          </Grid>
          <Grid item xs={0} md={1}></Grid>
          <Grid display={"flex"} justifyContent={"center"} item xs={12} md={3}>
            <OrderSummary
              dataArray={tempSummary ? tempSummary : dataArray}
              handleRefreshCallBack={handleRefreshCallBack}
              isFee={true}
              reviewOrder={reviewOrder}
            ></OrderSummary>
          </Grid>
        </Grid>
      )}
      {(currentDevice === "Tablet" || currentDevice === "Mobile") && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={8} className="ShippingPaymentComponentTop">
            <ShippingPayment
              handleUpdateOrder={handleUpdateOrder}
              handleRefreshCallBack={handleRefreshCallBack}
              dataArray={dataArray}
              reviewOrder={reviewOrder}
            ></ShippingPayment>
          </Grid>
          {!reviewOrder && (
            <ShoppingListComponent
              reviewOrder={!reviewOrder}
              orderDetail={true}
              dataArray={dataArray?.items}
              addtoCart={true}
            ></ShoppingListComponent>
          )}
          {/* <Divider
            sx={{
              margin: "0px 0px 40px 0px",
              width: "100%",
              border: "1.3px solid black",
            }}
          /> */}
          <Grid display={"flex"} justifyContent={"center"} item xs={12} md={3}>
            <OrderSummary
              dataArray={tempSummary ? tempSummary : dataArray}
              handleRefreshCallBack={handleRefreshCallBack}
              isFee={true}
              reviewOrder={reviewOrder}
            ></OrderSummary>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ShippingPaymentComponent;
