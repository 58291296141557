import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStore } from "../store/home";
import classnames from "classnames";
import {
  Box,
  Grid,
  Typography,
  styled,
  Divider,
  Checkbox,
} from "@mui/material";
import { DevTool } from "@hookform/devtools";
import "./AddShippingAddress.css";
import {
  addEditShippingAddress,
  getMyProfile,
  getStateCity,
  removeShippingAddress,
} from "../api/posts";
import { useMutation } from "@tanstack/react-query";
import { showErrorToast, showSuccessToast } from "../utils/Toaster";
import { toast } from "react-toastify";
import Select from "react-select";
import { customStyles } from "../utils/ReactSelectCustomStyles";
import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import {
  StyledShippingHeading,
  CardContentStyled,
  StyledShippingSubHeading,
  StyledRequiredField,
} from "../components/StyledComponents/ShippingAddressStyled";
import Loader from "../commenComp/Loader";
import { ErrorMessageStyled } from "./StyledComponents/CommonStyled";
import { ContainerDivStyled } from "./StyledComponents/BusinessDetailsStyled";
import TabletViewBlackContainer from "./TabletViewBlackContainer";
import { AddEmployeeAccountBtnStyled } from "./StyledComponents/EmployeeAccountStyled";
import commonMessages from "../commenComp/CommonMessages";
import { shippingAdressschema } from "../utils/schemas/shippingAddressSchema";
import { ReactComponent as RedCrossIcon } from "../Images/RedCrossIcon.svg";
import IlmorModal from "../commenComp/IlmorModal";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import { Upcoming } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const AddShippingAddress = ({
  setIsShippingAddressbtnClicked,
  editID,
  prepopulateEditAddress,
}) => {
  const {
    currentDevice,
    setMyProfile,
    myProfile,
    setToken,
    userInfo,
    token,
    dealerId,
    setSideMenuAccount,
    sideMenuAccount,
  } = useStore();

  const [cancelDialog, setCancelDialog] = useState(false);
  const {
    useThisAddress,
    setselectAdd,
  } = useNonPersistedStore();
  const stateRef = useRef();
  const [countryCode, setCountryCode] = useState("");
  const [stateCityData, setStateCityData] = useState([]);
  const [countrySelectOption, setCountrySelectOption] = useState();
  const [stateDefaultValue, setStateDefaultValue] = useState();
  const [nationalNumber, setNationalNumber] = useState("");
  const [completePhoneNumber, setCompletePhoneNumber] = useState("");
  const [removeIdTablerow, setRemoveIdTablerow] = useState();
  const [removeCardId, setRemoveCardId] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(shippingAdressschema),
    defaultValues: {
      addressLine1:
        prepopulateEditAddress &&
        prepopulateEditAddress?.length > 0 &&
        prepopulateEditAddress[0]?.address1,
      addressLine2:
        prepopulateEditAddress?.length > 0
          ? prepopulateEditAddress[0]?.address2
          : "",
      city:
        prepopulateEditAddress?.length > 0
          ? prepopulateEditAddress[0]?.city?.label
          : "",
      zipPostalCode:
        prepopulateEditAddress?.length > 0
          ? prepopulateEditAddress[0]?.zipCode
          : "",
      // StateProvince:
      //   prepopulateEditAddress?.length > 0
      //     ? prepopulateEditAddress[0]?.state?.value
      //     : "",
      countryRegion:
        prepopulateEditAddress?.length > 0
          ? prepopulateEditAddress[0]?.country?.value
          : "",

      businessLocationName:
        prepopulateEditAddress?.length > 0
          ? prepopulateEditAddress[0]?.locationName
          : "",
      contactName:
        prepopulateEditAddress?.length > 0
          ? prepopulateEditAddress[0]?.contactName
          : "",
      contactEmail:
        prepopulateEditAddress?.length > 0
          ? prepopulateEditAddress[0]?.contactEmail
          : "",
      primaryPhone:
        prepopulateEditAddress?.length > 0
          ? prepopulateEditAddress[0]?.primaryPhone
          : "",
      makeDefault:
        prepopulateEditAddress?.length > 0
          ? prepopulateEditAddress[0]?.isDefault
          : false,

      //   username: localStorage.getItem("email") || "",
      //   password: localStorage.getItem("password") || "",
    },
  });
  const getMyProfileMutation = useMutation(
    (post) => getMyProfile(post, token),
    {
      onSuccess: (result) => {
        setMyProfile(result?.data);
      },
      onError: (error) => {},
    }
  );
  const navigate = useNavigate();

  const [flagFirstRender, setflagflagFirstRender] = useState(true);
  const getStateCityMutation = useMutation(
    (post) => getStateCity(post, token),
    {
      onSuccess: (result) => {
        if (result) {
          if (result.data.success) {
            // setValue("StateProvince", 56);
            if (
              flagFirstRender &&
              prepopulateEditAddress &&
              prepopulateEditAddress?.length > 0
            ) {
              setValue(
                "StateProvince",
                prepopulateEditAddress[0]?.state?.value
              );
              setflagflagFirstRender(false);
            }
            showSuccessToast(result?.data?.msg);
            setStateCityData(result?.data?.data);
          } else {
            showErrorToast(result?.data?.msg);
          }
        }
      },
      onError: (error) => {},
    }
  );

  useEffect(() => {
    const obj = {};
    obj.email = userInfo?.username;
    obj.dealerId = dealerId;
    getMyProfileMutation.mutate(obj);
  }, []);

  useEffect(() => {
    if (myProfile?.countryList) {
      setCountrySelectOption(myProfile?.countryList);
    }
  }, []);
  const mutation = useMutation((post) => addEditShippingAddress(post, token), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
          showSuccessToast(result?.data?.msg);
          setIsShippingAddressbtnClicked(false);
          if(useThisAddress){
            setselectAdd(true)
          }
          navigate({
            pathname: "/account/mgmt/shipping",
          });
        } else {
          showErrorToast(result?.data?.msg);
        }
      }
    },
    onError: (error) => {},
  });
  const removeShippingMutation = useMutation(
    (post) => removeShippingAddress(post, token),
    {
      onSuccess: (result) => {
        if (result) {
          if (result?.data?.success) {
            showSuccessToast(result?.data?.msg);
            setIsShippingAddressbtnClicked(false);
            navigate({
              pathname: "/account/mgmt/shipping",
            });
          }
          // mutation.mutate({ email: currentUserEmail });

          // navigate(
          //   "/reset-password",{ state: { resposnse: result.data.success } });
        }
      },
      onError: (error) => {},
    }
  );

  useEffect(() => {
    if (prepopulateEditAddress && prepopulateEditAddress?.length > 0) {
      handleCountrySelectChange(getValues("countryRegion"));
    }
  }, [countrySelectOption]);
  useEffect(() => {
    if (prepopulateEditAddress && prepopulateEditAddress?.length > 0) {
      setCompletePhoneNumber(prepopulateEditAddress[0]?.primaryPhone);
      setNationalNumber(prepopulateEditAddress[0]?.shippingNationalNumber);
    }
  }, []);

  const handleCountrySelectChange = (value) => {
    stateRef.current.setValue("StateProvince", null);
    if (value) {
      getStateCityMutation.mutate({ countryID: value });
    }
  };

  const onSubmit = (data, event) => {
    event.preventDefault();
    if (prepopulateEditAddress && prepopulateEditAddress?.length > 0) {
      mutation.mutate({
        ...data,
        email: userInfo?.username,
        id: editID,
        // countryCode: countryCode,
        primaryPhone: completePhoneNumber,
        nationalNumber: nationalNumber,
      });
    } else {
      mutation.mutate({
        ...data,
        email: userInfo?.username,
        // countryCode: countryCode,
        primaryPhone: completePhoneNumber,
        nationalNumber: nationalNumber,
      });
    }

    // setEmail(data.username)
    // setPassword(data.password)
    // const obj = {
    //   username: data.username,
    //   password: data.password,
    // };
    // setLoginData(obj);
    // if (data.username) {
    //   mutation.mutate(obj);

    // }
  };

  const handleCancel = (id) => {
    setCancelDialog(!cancelDialog);
    setRemoveIdTablerow(id);
  };
  const handleOkay = () => {
    removeShippingMutation?.mutate({ id: removeIdTablerow });
    setCancelDialog(!cancelDialog);
  };
  const handleRemoveClick = (id) => {
    removeShippingMutation.mutate({ id: id });
  };
  const handlePhoneNumberChange = (data, field) => {
    if (!data) {
      setCompletePhoneNumber("");
      setNationalNumber("");

      return;
    }

    try {
      const parsedNumber = parsePhoneNumber(data?.toString());

      // setCountryCode(parsedNumber?.countryCallingCode);

      setCompletePhoneNumber(parsedNumber?.number);
      // setCustomPhoneNumber(parsedNumber?.number)
      setNationalNumber(parsedNumber?.nationalNumber);
    } catch (error) {}
  };

  return (
    <>
      {mutation.isLoading && <Loader></Loader>}
      <IlmorModal
        isopen={cancelDialog}
        handleOkay={handleOkay}
        handleClose={handleCancel}
        message={commonMessages?.removeShippingAddressCard}
        messageDetail={"The user will be removed immediately."}
        secondMessage={"Any existing orders/lists will not be affected."}
        fisrtButton={"Cancel"}
        secondButton={"Delete"}
      ></IlmorModal>
      <Box
        sx={{
          background: "#eaeaea",
          width: "100%",
        }}
      >
        <StyledShippingHeading
          sx={{
            padding:
              currentDevice == "Tablet"
                ? "24px 28px"
                : currentDevice == "Mobile"
                ? "24px 20px"
                : "30px 60px 0px 60px ",
          }}
          currentDevice={currentDevice}
        >
          {prepopulateEditAddress && prepopulateEditAddress?.length > 0
            ? "Edit Shipping Address"
            : "  Add shipping address"}
        </StyledShippingHeading>

        {currentDevice == "Tablet" && (
          <TabletViewBlackContainer currentDevice={currentDevice} />
        )}
        <ContainerDivStyled currentDevice={currentDevice}>
          <Grid container>
            <Grid item xs={12} sm={12} md={9}>
              <Grid
                container
                spacing={1}
                rowSpacing={3}
                pt={currentDevice == "Tablet" ? 3 : 0}
              >
                <Grid item sm={12} md={12}>
                  <StyledShippingSubHeading className="rhtmk567867">
                    {prepopulateEditAddress &&
                    prepopulateEditAddress?.length > 0
                      ? "Use the form below to update your shipping address."
                      : commonMessages?.addShippingAddressSubHeading}
                  </StyledShippingSubHeading>{" "}
                </Grid>
                <Grid item sm={8} md={12}>
                  <StyledRequiredField>
                    {" "}
                    {commonMessages?.addShippingAddressSecondSubHeading}
                  </StyledRequiredField>
                </Grid>
              </Grid>

              <Divider
                className={classnames("", {
                  dividerclass: !(
                    sideMenuAccount == "shipping" && currentDevice == "Desktop"
                  ),
                })}
                sx={{ margin: "35px 0px 20px 0px" }}
              />
              <Grid container spacing={1}>
                <Grid item sm={12} md={12}>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={`form shippingForm`}
                    noValidate
                  >
                    <div>
                      <h5 className={`labelDesktopAddShippingRequired`}>
                        BUSINESS / LOCATION NAME
                      </h5>
                    </div>
                    <input
                      // style={{ border: "none" }}
                      className={`InputFieldShippingAddress`}
                      type="text"
                      maxLength={200}
                      // value={email}
                      name="businessLocationName"
                      // onChange={handleEmailChange}
                      {...register(
                        "businessLocationName"
                        // {
                        //   required: {
                        //     value: true,
                        //     message: "businessLocationName is required",
                        //   },
                        // }
                      )}
                    ></input>
                    {errors?.businessLocationName && (
                      <ErrorMessageStyled>
                        {errors.businessLocationName?.message}
                      </ErrorMessageStyled>
                    )}
                    {/* <p>{errors?.businessLocationName?.message}</p> */}
                    <div className={`passwordlabelDesktopAddShipping`}>
                      <h5 className={`labelDesktopAddShippingRequired`}>
                        ADDRESS LINE 1
                      </h5>
                    </div>
                    <input
                      name="addressLine1"
                      // style={{ border: "none" }}
                      className={`InputFieldShippingAddress`}
                      type="text"
                      maxLength={200}
                      {...register("addressLine1")}
                    ></input>

                    {errors?.addressLine1 && (
                      <ErrorMessageStyled>
                        {errors?.addressLine1?.message}
                      </ErrorMessageStyled>
                    )}
                    <div className={`emailLabel`}>
                      <h5 className={`labelDesktopAddShipping`}>
                        ADDRESS LINE 2
                      </h5>
                    </div>
                    <input
                      // style={{ border: "none" }}
                      className={`InputFieldShippingAddress`}
                      type="text"
                      maxLength={200}
                      // value={email}
                      name="addressLine2"
                      // onChange={handleEmailChange}
                      {...register("addressLine2")}
                    ></input>
                    <div className={`passwordlabelDesktopAddShipping`}>
                      <h5 className={`labelDesktopAddShippingRequired`}>
                        COUNTRY / REGION
                      </h5>
                    </div>
                    <Controller
                      // rules={{ required: true }}
                      name="countryRegion"
                      control={control}
                      render={({ onChange, value, ref, field }) => {
                        return (
                          <Select
                            // className='select'
                            {...field}
                            className="jkdhs"
                            placeholder={<div></div>}
                            options={countrySelectOption}
                            value={countrySelectOption?.find(
                              (option) => option.value === field.value
                            )}
                            // value={}
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption?.value);
                              handleCountrySelectChange(selectedOption?.value);
                            }}
                            styles={customStyles}
                            //  menuIsOpen={true}
                            // defaultValue={userRole.find((c) => c.value === countryValue)}
                          />
                        );
                      }}
                    />
                    {errors?.countryRegion && (
                      <ErrorMessageStyled>
                        {errors?.countryRegion?.message}
                      </ErrorMessageStyled>
                    )}

                    {/* <Grid container spacing={1}> */}
                    <Grid container spacing={1}>
                      <Grid
                        item
                        sm={4}
                        xs={12}
                        md={4}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div className={`emailLabel`}>
                          <h5 className={`labelDesktopAddShippingRequired`}>
                            STATE / PROVINCE
                          </h5>
                        </div>
                        <Controller
                          // rules={{ required: true }}
                          name="StateProvince"
                          control={control}
                          render={({ onChange, value, ref, field }) => {
                            return (
                              <Select
                                // className='select'
                                {...field}
                                // value={stateCityData?.find(
                                //   (option) => option.value === field.value
                                // )}
                                isLoading={getStateCityMutation.isLoading}
                                isDisabled={!getValues("countryRegion")}
                                options={stateCityData}
                                className="jkdhs"
                                placeholder={<div></div>}
                                //  value={stateCityData.find((c) => c.value === value)}
                                value={stateCityData?.find(
                                  (option) => option.value === field.value
                                )}
                                onChange={(selectedOption) => {
                                  field?.onChange(selectedOption?.value);
                                }}
                                ref={stateRef}
                                styles={customStyles}
                                //  menuIsOpen={true}
                                // defaultValue={userRole.find((c) => c.value === countryValue)}
                              />
                            );
                          }}
                        />
                        {errors?.StateProvince && (
                          <ErrorMessageStyled>
                            {errors?.StateProvince?.message}
                          </ErrorMessageStyled>
                        )}
                      </Grid>
                      <Grid
                        item
                        md={4}
                        xs={12}
                        sm={4}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div className={`emailLabel`}>
                          <h5 className={`labelDesktopAddShippingRequired`}>
                            CITY
                          </h5>
                        </div>
                        <input
                          // style={{ border: "none" }}
                          className={`InputFieldShippingAddress`}
                          type="text"
                          // value={email}
                          name="city"
                          maxLength={50}
                          // onChange={handleEmailChange}
                          {...register("city")}
                        ></input>{" "}
                        {errors?.city && (
                          <ErrorMessageStyled>
                            {errors?.city?.message}
                          </ErrorMessageStyled>
                        )}
                      </Grid>
                      <Grid
                        item
                        md={4}
                        xs={12}
                        sm={4}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div className={`emailLabel`}>
                          <h5 className={`labelDesktopAddShippingRequired`}>
                            ZIP POSTAL CODE
                          </h5>
                        </div>
                        <input
                          // style={{ border: "none" }}
                          className={`InputFieldShippingAddress`}
                          type="text"
                          maxlength="12"
                          // value={email}
                          name="zipPostalCode"
                          // onChange={handleEmailChange}
                          {...register("zipPostalCode")}
                        ></input>
                        {errors?.zipPostalCode && (
                          <ErrorMessageStyled>
                            {errors?.zipPostalCode?.message}
                          </ErrorMessageStyled>
                        )}
                      </Grid>
                    </Grid>

                    <div className={`passwordlabelDesktopAddShipping`}>
                      <h5 className={`labelDesktopAddShipping`}>
                        CONTACT NAME
                      </h5>
                    </div>
                    <input
                      name="contactName"
                      // style={{ border: "none" }}
                      className={`InputFieldShippingAddress`}
                      maxLength={100}
                      type="text"
                      {...register("contactName")}
                    ></input>
                    <div className={`emailLabel`}>
                      <h5 className={`labelDesktopAddShipping`}>
                        CONTACT EMAIL
                      </h5>
                    </div>
                    <input
                      // style={{ border: "none" }}
                      className={`InputFieldShippingAddress`}
                      type="text"
                      // value={email}
                      name="contactEmail"
                      maxLength={100}
                      // onChange={handleEmailChange}
                      {...register(
                        "contactEmail"
                        // {
                        //   pattern: {
                        //     value:
                        //       /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        //     message: "invalid email format",
                        //   },
                        // }
                      )}
                    ></input>
                    {errors?.contactEmail && (
                      <ErrorMessageStyled>
                        {errors.contactEmail?.message}
                      </ErrorMessageStyled>
                    )}
                    <div className={`passwordlabelDesktopAddShipping`}>
                      <h5 className={`labelDesktopAddShipping`}>
                        PRIMARY PHONE
                      </h5>
                    </div>
                    <Controller
                      // rules={{ required: true }}
                      name="primaryPhone"
                      control={control}
                      render={({ onChange, value, onBlur, ref, field }) => {
                        return (
                          <PhoneInput
                            {...field}
                            // value={customPhoneNumber}
                            // value={userRole.find((c) => c.value === value)}
                            // onChange={(selectedOption) => {
                            //   handleSelectChange(selectedOption?.label,selectedOption?.value);
                            // }}
                            // countries =  {["RU", "UA", "KZ","IN","PK","US","NZ"]}
                            placeholder="Enter Phone Number"
                            // onChange={(data)=>handlePhoneNumberChange(data,field)}
                            onChange={handlePhoneNumberChange}
                            // international
                            countryCallingCodeEditable={false}
                            addInternationalOption={false}
                            defaultCountry="US"
                            className="ReactPhoneNumInputt"
                            minLength="7"
                            limitMaxLength={true}
                            style={{
                              backgroundColor: "white",
                              border: "none",
                              padding: "0px 10px",
                            }}
                          />
                        );
                      }}
                    />
                    {/* {errors?.primaryPhone && (
                    <ErrorMessageStyled>
                      {errors.primaryPhone?.message}
                    </ErrorMessageStyled>
                  )} */}
                    {/* <input
                    // style={{ border: "none" }}
                    className={`InputFieldShippingAddress`}
                    name="primaryPhone"
                    type="text"
                    {...register("primaryPhone")}
                  ></input>
                  {errors?.primaryPhone && (
                    <ErrorMessageStyled>
                      {errors?.primaryPhone?.message}
                    </ErrorMessageStyled>
                  )} */}

                    <Box
                      sx={{
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {/* <input
                        className={`inputCheckboxGlobal`}
                        type="checkbox"
                        id="makeDefaults"
                        name="makeDefault"
                        // value={true}
                        {...register("makeDefault")}
                      /> */}
                      <Controller
                        name="makeDefault"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            sx={{
                              // color: "white",
                              // "&.Mui-checked": {
                              //   color: "white",
                              // },
                              // "& .MuiSvgIcon-root": {
                              //   fill: "black",
                              // },
                              // "&.Mui-checked .MuiSvgIcon-root": {
                              //   fill: "black",
                              // },
                              "&.Mui-checked .MuiIconButton-label": {
                                backgroundColor: "black",
                              },
                            }}
                            checked={field.value ? true : false}
                          />
                        )}
                      />
                      <label
                        className={`labelCheckboxGlobal`}
                        for="makeDefaults"
                      >
                        {commonMessages?.shippingAddressCheckBox}
                      </label>
                    </Box>
                    {currentDevice == "Desktop" ? (
                      <Divider sx={{ margin: "30px 0px 30px 0px" }} />
                    ) : currentDevice == "Tablet" ? (
                      <Divider sx={{ margin: "32px 0px 36px 0px" }} />
                    ) : (
                      <Divider sx={{ margin: "30px 0px 10px 0px" }} />
                    )}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        flexDirection: "row",
                        flexGrow: 1,
                        my: 1.5,
                        gap: 2,
                        alignItems: "center",

                        borderRadius: 1,
                      }}
                      className="marginBottomShippingButtonsTablet"
                    >
                      <button
                        type="button"
                        style={{ marginRight: "19px" }}
                        className={`cancelButonAddShipping`}
                        onClick={() => {
                          setIsShippingAddressbtnClicked(false);
                          navigate({
                            pathname: "/account/mgmt/shipping",
                          });
                        }}
                      >
                        Cancel
                      </button>
                      {prepopulateEditAddress &&
                        prepopulateEditAddress?.length > 0 && (
                          <>
                            <Box
                              onClick={() => {
                                handleCancel(prepopulateEditAddress[0]?.id);
                                // handleRemoveClick(row?.email);
                              }}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <RedCrossIcon />
                              <button
                                type="button"
                                className={`cancelButonAddShipping`}
                              >
                                Delete Address
                              </button>
                            </Box>
                          </>
                        )}
                      {/* <AddEmployeeAccountBtnStyled >
                      {prepopulateEditAddress &&
                      prepopulateEditAddress?.length > 0
                        ? "Update Address"
                        : "Add Address"}
                    </AddEmployeeAccountBtnStyled> */}
                      <button
                        className={`ilmorButon`}
                        //  onClick={handleSignIn}
                      >
                        {prepopulateEditAddress &&
                        prepopulateEditAddress?.length > 0
                          ? "Update Address"
                          : "Add Address"}
                      </button>
                    </Box>
                  </form>
                  <DevTool control={control} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box>
            {currentDevice == "Mobile" && (
              <TabletViewBlackContainer currentDevice={currentDevice} />
            )}
          </Box>
        </ContainerDivStyled>
      </Box>
    </>
  );
};

export default AddShippingAddress;
