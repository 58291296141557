import * as yup from "yup";


export const shippingAdressschema = yup.object().shape({
  // contactEmail: yup.string().email().required(),
  // primaryPhone: yup
  // .string()
  // .matches(
  //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
  //   "Enter a valid phone number"
  // ),
  businessLocationName: yup
    .string()
    .required("Business location name is required").test('no-spaces', 'Business location name should not contain only spaces', (value) => {
      return value?.trim() !== '';
    }),
  addressLine1: yup.string().required("Address Line One  is required  ").test('no-spaces', 'Address Line One should not contain only spaces', (value) => {
    return value?.trim() !== '';
  }),
  // countryRegion: yup.string().required("Country Region  is required  "),
  // StateProvince: yup.string().required("State  is required  "),
  city: yup.string().required("City is required  ").test('no-spaces', 'City should not contain only spaces', (value) => {
    return value?.trim() !== '';
  }),
  zipPostalCode: yup.string().required("Zip is required  ").test('no-spaces', 'Zip should not contain only spaces', (value) => {
    return value?.trim() !== '';
  }),
  contactEmail: yup
    .string()
  .nullable(true)
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      {
        message: "Invalid email",
        excludeEmptyString: true,
      }  
    ),
    countryRegion:yup.string().required("Country/Region is required  "),
    StateProvince:yup.string().required("State is required  "),

  // primaryPhone: yup
  //   .string()
  //   .min(7, "Minimum 7 Characters are required")
  //   .max(14, "Maximum 14 Characters are required")
  //   .matches(phoneRegExp, "Phone number is not valid"),

  // primaryPhone: yup.string().when("val", {
  //   is: (val) => val?.length > 0,
  //   then: yup
  //     .string()
  //     .min(7, "Minimum 7 Characters are required")
  //     .max(14, "Maximum 14 Characters are required")
  //     .matches(phoneRegExp, "Phone number is not valid"),
  // }),
});
