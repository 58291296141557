import * as yup from "yup";

export const addEmployeeAcntSchema = yup.object().shape({
  firstname: yup.string().required("First Name  is required").test('no-spaces', 'First Name should not contain only spaces', (value) => {
    return value?.trim() !== '';
  }),
  lastname: yup.string().required("Last Name  is required").test('no-spaces', 'Last Name should not contain only spaces', (value) => {
    return value?.trim() !== '';
  }),
  userEmail: yup
    .string()
    .required("Email is required")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),

  // role:yup.string().required("Role is required"),
  role: yup.object().shape({
    label: yup.string().required("User Role is required "),
    value: yup.string().required("User Role is required"),
  }),
  // .nullable() // for handling null value when clearing options via clicking "x"
  // .required("Role is required ")
  // primaryPhone: yup
  //   .string()
  //   .min(7, "Minimum 7 Characters are required")
  //   // .max(14, "Maximum 20 Characters are required")
  //   // .matches(phoneRegExp, "Phone number is not valid"),

  // username: yup.string().email("The entered email is not valid"),
  // password: yup
  //   .string()
  //   .min(4, "Password must be at least 4 characters")
  //   .max(20, "Password must be at maximum 20 characters"),
});
