import React, { useEffect, useRef, useState } from "react";
import { useStore } from "../../store/home";
import { useNonPersistedStore } from "../../store/NonPersistedStore";
import { Box, Card, Grid } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import classnames from "classnames";
import { authorizeNet, getPay, storePay } from "../../api/posts";
import commonMessages from "../../commenComp/CommonMessages";
import { useLocation, useSearchParams } from "react-router-dom";
import Loader from "../../commenComp/Loader";
import TabletViewBlackContainer from "../../components/TabletViewBlackContainer";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Plus } from "../../Images/plusbutton.svg";
import { ReactComponent as Visa } from "../../Images/visa.svg";
import { ReactComponent as Master } from "../../Images/master.svg";
import american from "../../Images/american.png";
import jcb from "../../Images/jcb.png";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { ReactComponent as Discover } from "../../Images/discover.svg";

import { ContainerDivStyled } from "../StyledComponents/BusinessDetailsStyled";
import {
  StyledShippingHeading,
  StyledShippingSubHeading,
} from "../StyledComponents/ShippingAddressStyled";
import IlmorModal from "../../commenComp/IlmorModal";
import {
  ActiveInactiveStyled,
  AddEmployeeAccountBtnStyled,
} from "../StyledComponents/EmployeeAccountStyled";
import { toast } from "react-toastify";
import { showErrorToast } from "../../utils/Toaster";
import EditPaymentMethod from "./EditPaymentMethod";

const PaymentMethods = () => {
  const {
    currentDevice,
    setCardEditId,
    cusProfile,
    setcusProfile,
    token,
    myProfile,
    seCardInfo,
    dealerId,
    setSideMenuAccount,
    sideMenuAccount,
  } = useStore();
  const location = useLocation();
  const { setChooseCardId, chooseCard, setCardLoad, cardLoad } =
    useNonPersistedStore();
  const [allCards, getAllCardsArray] = useState(false);
  const [load, setload] = useState(false);
  const [load1, setload1] = useState(false);
  const [edit, setEdit] = useState(false);
  const [allCardsStatus, getAllCardsArrayStatus] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getAllCards = {
    getCustomerProfileRequest: {
      merchantAuthentication: {
        name: process.env.REACT_APP_MERCHANT_NAME,
        transactionKey: process.env.REACT_APP_MERCHANT_TRANSACTION_KEY,
      },
      customerProfileId: cusProfile,
      // includeIssuerInfo: true,
      unmaskExpirationDate: true,
    },
  };

  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      if (cusProfile) {
        setload(true);
        authorize.mutate(getAllCards);
      }
      return;
    }
  }, [cusProfile]);

  const getPayMethod = useMutation((post) => getPay(post, token), {
    onSuccess: (result) => {
      setload1(false);
      if (result?.data?.success && result?.data?.data?.CustomerProfileId) {
        setcusProfile(result?.data?.data?.CustomerProfileId);
        getAllCardsArrayStatus(result?.data?.data?.cards);
      }
    },
    onError: (error) => {
      setload1(false);
    },
  });
  const initialRender1 = useRef(true);
  useEffect(() => {
    if (initialRender1.current) {
      initialRender1.current = false;
      const data = {
        id: myProfile?.profileInfo?.id,
      };
      setload1(true);
      getPayMethod.mutate(data);
      return;
    }
  }, []);

  useEffect(() => {
    if (cardLoad) {
      const data = {
        id: myProfile?.profileInfo?.id,
      };
      authorize.mutate(getAllCards);
      getPayMethod.mutate(data);
    }
  }, [cardLoad]);

  const addCard = {
    createCustomerPaymentProfileRequest: {
      merchantAuthentication: {
        name: process.env.REACT_APP_MERCHANT_NAME,
        transactionKey: process.env.REACT_APP_MERCHANT_TRANSACTION_KEY,
      },
      customerProfileId: "cusId",
      paymentProfile: {
        billTo: {
          // firstName: "John",
          // lastName: "Doe",
          address: "123 Main St.",
          // city: "Bellevue",
          // state: "WA",
          zip: "98004",
          // country: "US",
          // phoneNumber: "000-000-0000",
        },
        payment: {
          creditCard: {
            cardNumber: "cardNumber",
            expirationDate: "2023-12",
          },
        },
        defaultPaymentProfile: false,
      },
      validationMode: "liveMode",
    },
  };

  const notify = () => toast.success(commonMessages.removeCardSuccess);
  const authorize = useMutation((post) => authorizeNet(post), {
    onSuccess: (result) => {
      if (result) {
        setload(false);
        getAllCardsArray(result.data.profile.paymentProfiles);
      }
    },
    onError: (error) => {
      setload(false);
    },
  });

  const storePayMethod = useMutation((post) => storePay(post, token), {
    onSuccess: (result) => {
      notify();
      authorize.mutate(getAllCards);
    },
    onError: (error) => {},
  });

  const authAPI = useMutation((post) => authorizeNet(post), {
    onSuccess: (result) => {
      if (result.data.messages.resultCode === "Ok") {
        const obj = {};
        obj.id = myProfile.profileInfo.id;
        obj.uId = removeCardUId;
        obj.flag = -1;
        storePayMethod.mutate(obj);
      } else {
        showErrorToast(result.data.messages.message[0].text);
      }
    },
    onError: (error) => {},
  });

  const [tableData, setTableData] = useState([]);
  const handleRemoveCard = (id) => {
    setRemoveCardId(id);
    const removeCard = {
      deleteCustomerPaymentProfileRequest: {
        merchantAuthentication: {
          name: process.env.REACT_APP_MERCHANT_NAME,
          transactionKey: process.env.REACT_APP_MERCHANT_TRANSACTION_KEY,
        },
        customerProfileId: cusProfile,
        customerPaymentProfileId: id,
      },
    };
    authAPI.mutate(removeCard);
  };
  useEffect(() => {
    let arr = [];
    if (allCardsStatus?.length > 0 && allCards?.length > 0) {
      allCardsStatus.forEach((item) => {
        allCards.forEach((card) => {
          if (item.profileInformationId === card.customerPaymentProfileId) {
            card.payment.creditCard.active = item.active;
            card.payment.creditCard.referenceLabel = item.referenceLabel;
            card.payment.creditCard.uId = item.uId;
          }
        });
      });
    }
    if (allCards) {
      allCards.forEach((item) => {
        const obj = {};
        obj.reference = item?.payment?.creditCard?.referenceLabel;
        obj.customerPaymentProfileId = item?.customerPaymentProfileId;
        obj.card = item?.payment?.creditCard?.cardNumber;
        obj.expiration = item?.payment?.creditCard?.expirationDate;
        obj.status = item?.defaultPaymentProfile;
        obj.cardType = item?.payment?.creditCard?.cardType;
        obj.active = item?.payment?.creditCard?.active;
        obj.uId = item?.payment?.creditCard?.uId;
        arr.push(obj);
      });
    }
    setTableData(arr);
  }, [allCards, allCardsStatus]);

  const [cancelDialog, setCancelDialog] = useState(false);
  const [removeCardId, setRemoveCardId] = useState("");
  const [removeCardUId, setRemoveCardUId] = useState("");
  const navigate = useNavigate();
  const handleCancel = (data) => {
    setCancelDialog(!cancelDialog);
    setRemoveCardId(data.customerPaymentProfileId);
    setRemoveCardUId(data.uId);
  };
  const handleOkay = () => {
    handleRemoveCard(removeCardId);
    setCancelDialog(!cancelDialog);
  };

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (location.pathname === "/account/mgmt/payment/add") {
      setSideMenuAccount("addPaymentMethods");
      window.scrollTo(0, 0);
    }
    if (
      location.pathname === "/account/mgmt/payment/edit" &&
      tableData &&
      allCardsStatus && 
      tableData.length > 0 &&
      allCardsStatus.length >0 &&
      searchParams?.get("card")
    ) {
      const editResponse =
      tableData &&
      tableData?.filter((item) => {
        return item?.customerPaymentProfileId == searchParams?.get("card");
      });
      setCardEditId(
        editResponse.customerPaymentProfileId
      );
      if(editResponse && editResponse.length > 0){
        seCardInfo(editResponse[0]);
        setEdit(true);
        setSideMenuAccount("editPaymentMethods");
      } else{
        showErrorToast('The card does not exist')
      }
      window.scrollTo(0, 0);
    } 
  }, [location, tableData , allCardsStatus]);

  function convertDateFormat(originalDate) {
    const [year, month] = originalDate.split("-");
    const formattedDate = `${month}/${year.slice(-2)}`;
    return formattedDate;
  }
  return (
    <>
      {(authAPI.isLoading || load1 || storePayMethod.isLoading) && (
        <Loader></Loader>
      )}
      {load && <Loader></Loader>}
      <IlmorModal
        isopen={cancelDialog}
        handleOkay={handleOkay}
        handleClose={handleCancel}
        message={commonMessages?.removeCard}
        messageDetail={"The Card will be removed immediately."}
        secondMessage={"Any existing orders/lists will not be affected."}
        fisrtButton={"Cancel"}
        secondButton={"Delete"}
      ></IlmorModal>
      {!edit ? (
        <Box
          sx={{
            width: "100%",
            background: "#eaeaea",
          }}
        >
          <StyledShippingHeading
            sx={{
              background: "#eaeaea",
              padding:
                currentDevice == "Tablet"
                  ? "24px 28px"
                  : currentDevice == "Mobile"
                  ? "24px 20px"
                  : "30px 60px 0px 60px ",
            }}
            currentDevice={currentDevice}
          >
            {commonMessages?.paymentMethodsHeading}
          </StyledShippingHeading>

          {currentDevice == "Tablet" && (
            <Grid item sm={12} md={12}>
              <TabletViewBlackContainer />
            </Grid>
          )}
          {(currentDevice === "Desktop" ||
            currentDevice === "Tablet" ||
            currentDevice === "Mobile") && (
            <ContainerDivStyled
              greyUpperPart={true}
              currentDevice={currentDevice}
              className="subHeadPaymentMainFirst"
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid item sm={12} md={12}>
                    <StyledShippingSubHeading>
                      {commonMessages?.paymentMethodsSubHeadingOne}
                      <br></br>
                      {commonMessages?.paymentMethodsSubHeadingTwo}
                    </StyledShippingSubHeading>
                  </Grid>
                </Grid>
              </Grid>
            </ContainerDivStyled>
          )}
          <Grid
            sx={{
              backgroundColor: currentDevice !== "Desktop" ? "#eaeaea" : "none",
            }}
            container
            spacing={1}
          >
            {tableData && tableData.length > 0 ? (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {currentDevice === "Desktop" && (
                  <table className={`orderHisTable`} dataId="payment">
                    <thead className={`orderHisTableHead`}>
                      <tr
                        className={classnames("orderHisTableRow", {
                          paymenTableFirstWhite:
                            sideMenuAccount == "paymentMethods",
                        })}
                      >
                        <th className={`orderHisTableRowHeading`}>Reference</th>
                        <th width="20%" className={`orderHisTableRowHeading`}>
                          Card
                        </th>
                        <th className={`orderHisTableRowHeading`}>
                          Expiration
                        </th>
                        <th className={`orderHisTableRowHeading`}>Status</th>
                        {myProfile?.profileInfo?.role !==
                          "ServiceTechnician" && (
                          <th
                            width="20%"
                            className={`orderHisTableRowHeading`}
                          ></th>
                        )}
                      </tr>
                    </thead>
                    <tbody className={`orderHisTableData  sdfsefsee445454`}>
                      {tableData.map((row, index) => (
                        <tr
                          // onClick={() => {
                          //   setSideMenuAccount("orderDetail");
                          // }}
                          className={`quickOrderTableRowDesktop${
                            index % 2 === 0 ? "EvenRow" : ""
                          }`}
                          key={index}
                        >
                          <td
                            className={`orderHisTableDataDiv paymentTableDiv`}
                          >
                            <span className={`orderHisTableData`}>
                              {row.reference}
                            </span>
                          </td>
                          <td
                            className={`orderHisTableDataDiv paymentTableDiv`}
                          >
                            <div className="paymethodCard">
                              <div className="minCard center">
                                {row.cardType === "Visa" ? (
                                  <Visa className="catogeryIcon"></Visa>
                                ) : (
                                  ""
                                )}
                                {row.cardType === "AmericanExpress" ? (
                                  <img
                                    src={american}
                                    className="cardWidth"
                                  ></img>
                                ) : (
                                  ""
                                )}
                                {row.cardType === "MasterCard" ? (
                                  <Master className="catogeryIcon"></Master>
                                ) : (
                                  ""
                                )}
                                {row.cardType === "JCB" ? (
                                  <img src={jcb} className="cardWidth"></img>
                                ) : (
                                  ""
                                )}
                                {row.cardType === "Discover" ? (
                                  <Discover className="catogeryIcon"></Discover>
                                ) : (
                                  ""
                                )}
                                {!(
                                  row.cardType === "Discover" ||
                                  row.cardType === "JCB" ||
                                  row.cardType === "MasterCard" ||
                                  row.cardType === "Visa" ||
                                  row.cardType === "AmericanExpress"
                                ) ? (
                                  <CreditCardIcon
                                    sx={{ fontSize: 35 }}
                                  ></CreditCardIcon>
                                ) : (
                                  ""
                                )}
                              </div>
                              <span className={`orderHisTableData`}>
                                {row.card.replace(/X/g, "")}
                              </span>
                            </div>
                          </td>
                          <td
                            className={`orderHisTableDataDiv paymentTableDiv`}
                          >
                            <span className={`orderHisTableData`}>
                              {convertDateFormat(row.expiration)}
                            </span>
                          </td>

                          <td
                            className={`orderHisTableDataDiv paymentTableDiv`}
                          >
                            {new Date(row.expiration).getFullYear() <
                              new Date().getFullYear() ||
                            (new Date(row.expiration).getFullYear() ===
                              new Date().getFullYear() &&
                              new Date(row.expiration).getMonth() <
                                new Date().getMonth()) ? (
                              <ActiveInactiveStyled
                                buttonColor={"#CE0E2D"}
                                expiredStatus={true}
                                status={false}
                              >
                                Expired
                              </ActiveInactiveStyled>
                            ) : (
                              <>
                                {row.active ? (
                                  <ActiveInactiveStyled status={true}>
                                    Active
                                  </ActiveInactiveStyled>
                                ) : (
                                  <ActiveInactiveStyled>
                                    Inactive
                                  </ActiveInactiveStyled>
                                )}
                              </>
                            )}
                            {/* <span
                          className={`orderHisTableData ilmorRoundGreenButton`}
                        >
                          {new Date(row.expiration) < new Date()
                            ? "EXPIRED"
                            : "ACTIVE"}
                        </span> */}
                          </td>
                          {myProfile?.profileInfo?.role !==
                            "ServiceTechnician" && (
                            <td
                              className={`orderHisTableDataDiv paymentTableDiv`}
                            >
                              {chooseCard && location?.state?.useCard ? (
                                <div className="paymethodCard">
                                  <span
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      setChooseCardId(
                                        row.customerPaymentProfileId
                                      );
                                      navigate({
                                        pathname: "/checkout",
                                      });
                                    }}
                                    className={`orderHisTableButton`}
                                  >
                                    {"Use this Card"}
                                  </span>
                                </div>
                              ) : (
                                <div className="paymethodCard">
                                  <span
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      setCardEditId(
                                        row.customerPaymentProfileId
                                      );
                                      seCardInfo(row);
                                      setSideMenuAccount("editPaymentMethods");
                                      let str = `card=${row.customerPaymentProfileId}&uid=${row.uId}`;
                                      navigate({
                                        pathname: "/account/mgmt/payment/edit",
                                        search: `${str}`,
                                      });
                                    }}
                                    className={`orderHisTableButton`}
                                  >
                                    {"Edit"}
                                  </span>
                                  |
                                  <span
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleCancel(row);
                                    }}
                                    className={`orderHisTableButton`}
                                  >
                                    {"Remove"}
                                  </span>
                                </div>
                              )}
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {currentDevice === "Tablet" && (
                  <>
                    <Card
                      className="tablastilne"
                      sx={{
                        padding: 4,
                        boxShadow: "none",
                        backgroundColor: "#eaeaea",
                      }}
                    >
                      {tableData?.map((row, index) => (
                        <div
                          className={classnames("TabletOrderDetail", {
                            evenRow: index % 2 === 0,
                          })}
                        >
                          <div className="ordeTabDetail paymentMethodCards">
                            <div className="tabOrdeDetaLeft">
                              <div className="paymethodCard">
                                {row.cardType === "Visa" ? (
                                  <Visa className="catogeryIcon"></Visa>
                                ) : (
                                  ""
                                )}
                                {row.cardType === "AmericanExpress" ? (
                                  <img
                                    src={american}
                                    className="cardWidth"
                                  ></img>
                                ) : (
                                  ""
                                )}
                                {row.cardType === "MasterCard" ? (
                                  <Master className="catogeryIcon"></Master>
                                ) : (
                                  ""
                                )}
                                {row.cardType === "JCB" ? (
                                  <img src={jcb} className="cardWidth"></img>
                                ) : (
                                  ""
                                )}
                                {row.cardType === "Discover" ? (
                                  <Discover className="catogeryIcon"></Discover>
                                ) : (
                                  ""
                                )}
                                {!(
                                  row.cardType === "Discover" ||
                                  row.cardType === "JCB" ||
                                  row.cardType === "MasterCard" ||
                                  row.cardType === "Visa" ||
                                  row.cardType === "AmericanExpress"
                                ) ? (
                                  <CreditCardIcon
                                    sx={{ fontSize: 35 }}
                                  ></CreditCardIcon>
                                ) : (
                                  ""
                                )}
                                <span className={`orderHisTableData`}>
                                  {row.card.replace(/X/g, "")}
                                </span>
                              </div>
                            </div>
                            <div className="orderDate">
                              <div className="dateTab paymenthodtab">
                                <div className="ordeDetailTitle">
                                  Expiration
                                </div>
                                <div className="ordeDetailValue">
                                  {/* {row.expiration} */}
                                  {convertDateFormat(row.expiration)}
                                </div>
                              </div>
                              <div className="dateTab paymenthodtab">
                                <div className="ordeDetailTitle">Reference</div>
                                <div className="ordeDetailValue">
                                  {row.reference}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="tabOrdeDetaRight paymentethodStatus">
                            {/* <ActiveInactiveStyled
                            sx={{
                              marginRight: currentDevice == "Tablet" && "11px",
                              "& .MuiSlider-thumb": {
                                // borderRadius: "1px",
                              },
                            }}
                            status={"Active"}
                          >
                            Active
                          </ActiveInactiveStyled> */}
                            {row.active ? (
                              <ActiveInactiveStyled
                                sx={{
                                  marginRight:
                                    currentDevice == "Tablet" && "11px",
                                  "& .MuiSlider-thumb": {
                                    // borderRadius: "1px",
                                  },
                                }}
                                status={"Active"}
                              >
                                Active
                              </ActiveInactiveStyled>
                            ) : (
                              <ActiveInactiveStyled>
                                Inactive
                              </ActiveInactiveStyled>
                            )}
                            {chooseCard && location?.state?.useCard ? (
                              <div className="paymethodCard">
                                <span
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setChooseCardId(
                                      row.customerPaymentProfileId
                                    );
                                    navigate({
                                      pathname: "/checkout",
                                    });
                                  }}
                                  className={`orderHisTableButton`}
                                >
                                  {"Use this Card"}
                                </span>
                              </div>
                            ) : (
                              <>
                                <div
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setCardEditId(row.customerPaymentProfileId);
                                    seCardInfo(row);
                                    setSideMenuAccount("editPaymentMethods");
                                    let str = `card=${row.customerPaymentProfileId}&uid=${row.uId}`;
                                    navigate({
                                      pathname: "/account/mgmt/payment/edit",
                                      search: `${str}`,
                                    });
                                  }}
                                  className="redUnderLinedText"
                                >
                                  Edit
                                </div>
                                <div>|</div>
                                <div
                                  // onClick={(event) => {
                                  //   event.stopPropagation();
                                  //   setSideMenuAccount("returnOrder");
                                  // }}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleCancel(row);
                                  }}
                                  className="redUnderLinedText"
                                >
                                  Remove
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                    </Card>
                  </>
                )}
                {currentDevice === "Mobile" && (
                  <>
                    <Card
                      className="tablastilne"
                      sx={{
                        padding: 4,
                        boxShadow: "none",
                        backgroundColor: "#eaeaea",
                      }}
                    >
                      {tableData.map((row, index) => (
                        <div
                          className={classnames("TabletOrderDetail", {
                            evenRow: index % 2 !== 0,
                          })}
                        >
                          <div className="ordeTabDetail paymentMethodCards">
                            <div className="tabOrdeDetaLeft">
                              <div className="paymethodCard">
                                {row.cardType === "Visa" ? (
                                  <Visa className="catogeryIcon"></Visa>
                                ) : (
                                  ""
                                )}
                                {row.cardType === "AmericanExpress" ? (
                                  <img
                                    src={american}
                                    className="cardWidth"
                                  ></img>
                                ) : (
                                  ""
                                )}
                                {row.cardType === "MasterCard" ? (
                                  <Master className="catogeryIcon"></Master>
                                ) : (
                                  ""
                                )}
                                {row.cardType === "JCB" ? (
                                  <img src={jcb} className="cardWidth"></img>
                                ) : (
                                  ""
                                )}
                                {row.cardType === "Discover" ? (
                                  <Discover className="catogeryIcon"></Discover>
                                ) : (
                                  ""
                                )}
                                {!(
                                  row.cardType === "Discover" ||
                                  row.cardType === "JCB" ||
                                  row.cardType === "MasterCard" ||
                                  row.cardType === "Visa" ||
                                  row.cardType === "AmericanExpress"
                                ) ? (
                                  <CreditCardIcon
                                    sx={{ fontSize: 35 }}
                                  ></CreditCardIcon>
                                ) : (
                                  ""
                                )}
                                <span className={`orderHisTableData`}>
                                  {row.card.replace(/X/g, "")}
                                </span>
                              </div>
                            </div>
                            <div className="orderDate">
                              <div className="dateTab paymenthodtab">
                                <div className="ordeDetailTitle">
                                  Expiration
                                </div>
                                <div className="ordeDetailValue">
                                  {/* {row.expiration} */}
                                  {convertDateFormat(row.expiration)}
                                </div>
                              </div>
                              <div className="dateTab paymenthodtab">
                                <div className="ordeDetailTitle">Reference</div>
                                <div className="ordeDetailValue">
                                  {row.reference}
                                </div>
                              </div>
                            </div>
                            <div className="tabOrdeDetaRight paymentethodStatus">
                              {row.active ? (
                                <ActiveInactiveStyled
                                  sx={{
                                    marginRight:
                                      currentDevice == "Tablet" && "11px",
                                    "& .MuiSlider-thumb": {
                                      // borderRadius: "1px",
                                    },
                                  }}
                                  status={"Active"}
                                >
                                  Active
                                </ActiveInactiveStyled>
                              ) : (
                                <ActiveInactiveStyled>
                                  Inactive
                                </ActiveInactiveStyled>
                              )}
                              {chooseCard && location?.state?.useCard ? (
                                <div className="paymethodCard">
                                  <span
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      setChooseCardId(
                                        row.customerPaymentProfileId
                                      );
                                      navigate({
                                        pathname: "/checkout",
                                      });
                                    }}
                                    className={`orderHisTableButton`}
                                  >
                                    {"Use this Card"}
                                  </span>
                                </div>
                              ) : (
                                <>
                                  <div
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      setCardEditId(
                                        row.customerPaymentProfileId
                                      );
                                      seCardInfo(row);
                                      setSideMenuAccount("editPaymentMethods");
                                      let str = `card=${row.customerPaymentProfileId}&uid=${row.uId}`;
                                      navigate({
                                        pathname: "/account/mgmt/payment/edit",
                                        search: `${str}`,
                                      });
                                    }}
                                    className="redUnderLinedText"
                                  >
                                    Edit
                                  </div>
                                  <div>|</div>
                                  <div
                                    // onClick={(event) => {
                                    //   event.stopPropagation();
                                    //   setSideMenuAccount("returnOrder");
                                    // }}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleCancel(row);
                                    }}
                                    className="redUnderLinedText"
                                  >
                                    Remove
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </Card>
                  </>
                )}
              </Grid>
            ) : (
              <Grid item spacing={10} xs={12} sm={12} md={12} lg={12}>
                {/* <div className="nocard"> */}
                {/* <StyledShippingSubHeading>
                  Use add payment method button to add card information
                </StyledShippingSubHeading> */}
                {/* </div> */}
              </Grid>
            )}
            {currentDevice === "Mobile" || currentDevice === "Tablet" ? (
              <>
                <Grid
                  className={classnames("tabletadd", {
                    noCardPadding: tableData.length === 0,
                  })}
                  sx={{
                    // marginBottom: "50px !important",
                    // marginLeft: "35px !important",
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "0 32px 11px 0 !important",
                  }}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  {!dealerId &&
                    myProfile?.profileInfo?.role !== "ServiceTechnician" && (
                      <>
                        <AddEmployeeAccountBtnStyled
                          sx={
                            {
                              // marginLeft: currentDevice === "Desktop" && "24px !important",
                              // justifyContent: currentDevice === "Mobile" && 'flex-start'
                            }
                          }
                          onClick={() => {
                            setSideMenuAccount("addPaymentMethods");
                            window.scrollTo(0, 0);
                            navigate("/account/mgmt/payment/add", {
                              state: { useCard: location?.state?.useCard },
                            });
                          }}
                        >
                          <Plus /> Add Payment Method
                        </AddEmployeeAccountBtnStyled>
                      </>
                    )}
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  sx={{
                    // marginBottom: "50px !important",
                    // marginLeft: "35px !important",
                    display: "flex",
                    justifyContent: "flex-start",
                    padding: "0 0 32px 32px !important",
                  }}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  {!dealerId &&
                    myProfile?.profileInfo?.role !== "ServiceTechnician" && (
                      <div className="botpadipay">
                        <AddEmployeeAccountBtnStyled
                          sx={{
                            marginLeft:
                              currentDevice === "Desktop" && "24px !important",
                            // justifyContent: currentDevice === "Mobile" && 'flex-start'
                          }}
                          onClick={() => {
                            setSideMenuAccount("addPaymentMethods");
                            navigate("/account/mgmt/payment/add", {
                              state: { useCard: location?.state?.useCard },
                            });
                          }}
                        >
                          + Add Payment Method
                        </AddEmployeeAccountBtnStyled>
                      </div>
                    )}
                </Grid>
              </>
            )}
          </Grid>
          <Box sx={{ marginBottom: "" }}>
            {currentDevice == "Mobile" && (
              <TabletViewBlackContainer currentDevice={currentDevice} />
            )}
          </Box>
        </Box>
      ) : (
        <EditPaymentMethod></EditPaymentMethod>
      )}
    </>
  );
};

export default PaymentMethods;
