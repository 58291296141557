const commonMessages = {
  confirmPay:
    "The order will be placed immediately. Any existing orders/lists will not be affected.",
  removeCardSuccess: "Card is removed successfully",
  shippingPaymentNoCard: "No default payment card is selected",
  paymentMethods: {
    removePaymentMessages: "Are you sure you want to remove this card ?",
  },
  addEmployeeAccountSubHeading:
    " Fill out the form below to add a new user to your account.They will be sent a temporary password, which will be changed on first visit.",
  addEmployeeAccountRequiredField: "Required Fields",
  addEmployeeAccountPersonalInformation: "PERSONAL INFORMATION",
  employeeAccountTableHeading: "Employee Accounts",
  employeeAccountTableSubHeading:
    "Accounts are listed below. Edit an account to change details or inactivate.",
  employeeAccountTableSecondSubHeading:
    "Edit your own information on the Profile Information page.",
  addEmployeeAcntbtn: "Add Employee Account",
  shippingAddressHeading: "Shipping Address Book",
  bussiness: "Business Detail",
  shippingAddressSubHeading:
    "For reference, your billing address is shown first. Shipping addresses are listed below.",
  shippingAddressSecondSubHeading:
    "Edit an address to set it as the default on checkout.",
  addShippingAddressBtn: "Add Shipping Address",
  addShippingAddressSubHeading:
    "Fill out the form below to add a new shipping address to your account, if it is seperate from your billing address.",
  addShippingAddressSecondSubHeading: "Required fields",
  shippingAddressCheckBox: "Make this our default shipping address",
  businessDetailsHeading: "Business Details",
  businessDetailsSubheading:
    "Please maintain accuracy with your business details.",
  deleteUserCross: "Are you sure you want delete this User?",
  removeEmployeeAccountRow: "Delete User?",
  removeShippingAddressCard: "DELETE ADDRESS?",
  removeCard: "DELETE CARD?",
  orderHistoryHeadingOne: "One year of orders are available online.",
  orderHistoryHeadingTwo:
    " Orders flow through the following statuses: Pending, Processing, Partially Shipped, Completed, Initiated Return, Cancelled",
  orderHistoryHeadingThree:
    "Only Pending orders are able to be modified or cancelled.",
  orderHistoryHeadingFour:
    "Shipped orders receive a tracking number when they are about to leave our warehouse.",
  orderHistoryHeadingFive: "Completed orders are accessible to start a return.",
  addTaxExemptFormSubHeading:
    "Add details and upload your tax exempt certificate. Note: It may take up to 2 business days for your status to be approved.",
  addTaxExemptFormHeading: "Add Tax Exempt Form",
  addToCartPopUpHeading: "PRODUCTS ADDED TO CART",
  addToShopPopUpHeading: "PRODUCTS ADDED TO SHOPPING LIST",
  categoryTileHeading: "Browse Product Categories",
  deleteUserSubHeading:
    "The user will be removed immediately. Any existing orders/lists will not be affected.",
  footerStatement: "©2024 Ilmor Engineering, Inc. All rights reserved.",
  forgetPasswordHeading: "PASSWORD RESET",
  forgetPasswordSubHeadingOne:
    "Enter the email address associated with your account, and we'll send you a link to reset your password.",
  forgetPasswordSubHeadingTwo:
    "The email or password you entered was incorrect. Please try again or reset your password with the Forgot Password link below.",
  loginFormHeading: "DEALER PARTS STORE",
  loginFormSubHeading:
    "The email or password you entered was incorrect. Please try again or reset your password with the Forgot Password link below.",
  loginFormSubHeadingInactive: "The user account is inactive",
  loginFormContactOne: "Contact Ilmor",
  loginFormContactTwo:
    "if you would like to become a certified parts dealer and access this site,or",
  loginFormContactThree: "visit our retail parts store",
  loginFormContactFour: "to order parts for your Ilmor engine.",
  myProfileSubHeadingOne: "Below is your user profile information.",
  myProfileSubHeadingTwo: "You are an administrator of your company.",
  myProfileSubHeadingThree: "Personal Information",
  myProfileSubHeadingFour: "Sign In Information",
  orderCompleteHeading: "Order Complete",
  orderCompleteSubHeadingOne: "Thank You for your order. ",
  orderCompleteSubHeadingOne1: "Email confirmation has been sent to ",
  orderCompleteSubHeadingTwo: "Order Number: ",
  orderCompleteSubHeadingThree:
    "Go to Order Status & History to review, track, modify or cancel.",
  orderCompleteSubHeadingFour:
    "We’ll send an email with tracking information when your order ships.",
  orderCompleteSubHeadingFive: "If you need assistance, call",
  partDetailSubHeadingOne: "Compatible with current engine",
  partDetailSubHeadingTwo: " Not Compatible with current engine",
  partDetailSubHeadingThree: "Information Required for purchase",
  partDetailHeading: "COMPATIBLE WITH THE FOLLOWING ENGINES",
  partDetailWarning:
    "Operating, servicing and maintaining a recreational marine vessel can expose you to chemicals including engine exhaust, carbon monoxide, phthalates, and lead, which are known to the State of California to cause cancer and birth defects or other reproductive harm.  To minimize exposure, avoid breathing exhaust, service your vessel in a well-ventilated area and wear gloves or wash your hands frequently when servicing this vessel. For more information go to",
  partDetailWebsite: "www.P65warnings.ca.gov/marine",
  passwordRecoveryHeading: "PASSWORD RESET",
  productTileHeading: "Shop Engine Diagram",
  productTileSubHeading: "No parts to display",
  noItemInQuickOrder: "Please add atleast one item in quickorder form",
  quickOrderComponentSuccess: "Products added to Shopping list",
  quickOrderComponentErrorOne: "Update the quantity for ",
  quickOrderComponentErrorTwo: "No Product was added to the shopping list",
  quickOrderComponentErrorThree: "Enter a valid part number to search",
  quickOrderComponentErrorFour: "Remove duplicate items from the list",
  quickOrderComponentSubHeading: `1. Enter part numbers and quantities, then click "Check Availability".`,
  quickOrderComponentSubHeadingTwo:
    "2. Then you can add all items to your Shopping List or orderable items to your Cart.",
  quickOrderComponentSubHeading2: `Enter part numbers and quantities, then click "Check Availability".`,
  quickOrderComponentSubHeadingTwo2:
    "Then you can add all items to your Shopping List or orderable items to your Cart.",
  shippingPaymentComponentForOrderDetailMessage1: `As long as this order is pending, you can modify your order`,
  shippingPaymentComponentForOrderDetailMessage2: `Below will be a new cart for the modified order.`,
  shippingPaymentComponentForOrderDetailMessage3: `Make any changes and then Proceed to Checkout and complete the order.`,
  shippingPaymentComponentForOrderDetailMessage4: `Once you checkout, the previous order will be replaced.`,
  cancelOrder1: "As long as this order is pending, you can cancel this order.",
  cancelOrder2:
    "You will receive an email confirmation and the status will be changed in Order Status and History",
  returnOrder1: "Select items you will be returning.",
  returnOrder2: " Once submitted, we will contact you with more information.",
  signInInfoHeading: "Sign In Information",
  signInInfoSubHeading: "You can change your password here.",
  signInInfoSubHeadingTwo:
    " If you are signed in and forgot your password, use the link at the bottom of the screen.",
  signInInfoHeadingTwo: "Choose a New Password",
  signInInfoSubHeadingThree:
    "Leave the following blank if only updating email, otherwise enter your new password twice.",
  taxExemptionPopUpHeading: "ADD TAX EXEMPTION FORM",
  paymentMethodsHeading: "PAYMENT METHODS",
  paymentMethodsSubHeadingOne:
    "Payment methods are listed below. Edit a method to set it as the default on checkout.",
  paymentMethodsSubHeadingTwo:
    "You must have at least one active card on file to place orders.",
  addPaymentMethodHeading: "ADD PAYMENT METHOD",
  addPaymentMethodsSubHeadingOne: "We accept the following cards:",
  addPaymentMethodsSubHeadingTwo:
    "This can be anything to help you identify the card during checkout.",
  addPaymentMethodsSubHeadingThree: "Make this our default payment method",
  EditPaymentMethodsHeading: "EDIT PAYMENT METHODS",
  EditPaymentMethodsSubHeadingOne:
    "Use the form below to update your payment method.",
};

export default commonMessages;
