import { Box } from "@mui/system";
import React from "react";
import "./CategoryTile.css";
import CardContent from "@mui/material/CardContent";
import BrowseProductCat from "../Images/BrowseProductCat.png";
import BaseEngine from "../Images/BaseEngine1.png";
import { useStore } from "../store/home";
import { useNavigate } from "react-router-dom";
// import BaseEngine from "../Images/BaseEngine2.png";
// import Cooling from "../Images/Cooling.png";
import Cooling from "../Images/Cooling1.png";
import place from "../Images/placeHolder.png";
// import Exhaust from "../Images/Exhaust.png";
// import BaseEngine from "../Images/BaseEngine.png";
import Exhaust from "../Images/Exhaust1.png";
// import FuelAirDelivery from "../Images/FuelAirDelivery.png";
import FuelAirDelivery from "../Images/FuelAirDelivery1.png";
// import Electrical from "../Images/Electricals.png";
import Electrical from "../Images/Electricals1.png";
// import Transmission from "../Images/Transmission.png";
import Transmission from "../Images/Transmission1.png";
// import Paint from "../Images/Paint.png";
import Paint from "../Images/Paint1.png";
import DecalsLabels from "../Images/Decals_labels1.png";
import Fluids from "../Images/Fluids1.png";
import ServiceKits from "../Images/ServiceKits1.png";
import BeltDrive from "../Images/BeltDrive1.png";
import OneTouch from "../Images/OneTouch1.png";
import OneDrive from "../Images/OneDrive1.png";
import vesselControls from "../Images/Vessel_Controls1.png";
import ManualsDocuments from "../Images/Manuals_Documents1.png";
import commonMessages from "../commenComp/CommonMessages";

import { Card, Grid } from "@material-ui/core";

export default function CategoryTile({ showCatBrowseDiv }) {
  const {
    currentDevice,
    categoryName,
    setSelectedSubCategory,
    setSelectedCategory,
    setSubArray,
    categorySide,
    isCurrentEngSet,
    isCurrentEngSetValue,
  } = useStore();

  const navigate = useNavigate();
  const handleCategoryClick = (item) => {
    if (item.subcategories) {
      if (
        Object.values(item.subcategories) &&
        Object.values(item.subcategories).length > 0
      ) {
        let arr = [];
        Object.values(item.subcategories).forEach((item) => {
          arr.push({ label: item.name, value: item.id });
        });
        setSubArray(arr);
      }
    }
    // setSubArray
    setSelectedCategory(item.id);
    setSelectedSubCategory("");
    if (isCurrentEngSet && isCurrentEngSetValue) {
      navigate({
        pathname: "/catalog",
        search: `isShopByDiagram=${isCurrentEngSet.isShopByDiagram}&yearId=${
          isCurrentEngSetValue.year
        }&year=${isCurrentEngSet.year}&make=${isCurrentEngSet.make}&makeId=${
          isCurrentEngSetValue.make
        }&model=${isCurrentEngSet.model}&modelId=${
          isCurrentEngSetValue.model
        }&category=${item.id}&subCategory=${""}&engineId=${
          isCurrentEngSet.engineId
        }`,
      });
    } else {
      navigate({
        pathname: "/catalog",
        search: `category=${item.id}`,
      });
    }
  };

  return (
    <>
      <Grid container spacing={0} className={`browseCategory__main`}>
        {!showCatBrowseDiv && (
          <Grid
            item
            sm={3}
            xs={6}
            md={3}
            lg={2}
            className={`mainTileDiv mainTileDivBrowseCat `}
          >
            <div className="borno">
              <div className={`browseCategoryContainer`}>
                <span className={`browseCategoryLabel`}>
                  {commonMessages?.categoryTileHeading}
                </span>
              </div>
            </div>
          </Grid>
        )}
        {categoryName &&
          categoryName?.length > 0 &&
          categoryName?.map((item) => {
            if (item?.data?.name !== "All Products") {
              return (
                <Grid
                  item
                  sm={3}
                  xs={6}
                  md={3}
                  lg={2}
                  className={`mainTileDiv`}
                  onClick={() => {
                    handleCategoryClick(item?.data);
                  }}
                >
                  <div className="hover">
                    <img
                      className={`image`}
                      src={item?.data?.image ? item?.data?.image : place}
                      alt={item?.data?.name}
                    />
                    <h5 className={`browseCategorylabel`}>
                      {item?.data?.name}
                    </h5>
                  </div>
                </Grid>
              );
            }
          })}
      </Grid>
    </>
  );
}
