import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStore } from "../store/home";
import { Box, Grid, Typography, styled } from "@mui/material";
import classnames from "classnames";
import "./OrderDetail.css";
import InputBase from "@mui/material/InputBase";
import search from "../Images/searchIcon.svg";
import { ReactComponent as ArrowIlmor } from "../Images/arrowIlmor.svg";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as Print } from "../Images/printRed.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Pdf } from "../Images/pdfRed.svg";
import { ReactComponent as Mail } from "../Images/mail.svg";
import { ReactComponent as LeftArrow } from "../Images/leftArrow.svg";
// import ShippingPaymentComponent from './ShippingPaymentComponent';
import ShippingPaymentComponentForOrderDetail from "./ShippingPaymentComponentForOrderDetail";
import { authorizeNet, getOrder, getReturnReasonListing } from "../api/posts";
import { useMutation } from "@tanstack/react-query";
import Loader from "../commenComp/Loader";
import { OrderStatusStyled } from "./StyledComponents/EmployeeAccountStyled";
import { showErrorToast } from "../utils/Toaster";
import { useRef } from "react";
import { handleSixty } from "../commonFunction";
const StyledShippingHeading = styled(Typography)({
  /* Display lg/Bold */
  fontFamily: "Montserrat",
  fontSize: "40px",
  // fontSize: 40px,
  fontStyle: "normal",
  paddingLeft: "44px",
  fontWeight: "700",

  textTransform: "uppercase",
});

const OrderDetail = ({
  setIsShippingAddressbtnClicked,
  modify,
  cencel,
  returnOrder,
}) => {
  const schema = yup.object().shape({
    username: yup.string().email("The entered email is not valid"),
    password: yup
      .string()
      .min(4, "Password must be at least 4 characters")
      .max(20, "Password must be at maximum 20 characters"),
  });
  const {
    token,
    orderID,
    setSideMenuAccount,
    dealerId,
    setReturnReasonZustand,
    myProfile,
    currentDevice,
    sideMenuAccount,
  } = useStore();

  const [errorToastShown, setErrorToastShown] = useState(false);
  const [orderDetail, setOrderDetail] = useState(false);
  const navigate = useNavigate();
  const mutation = useMutation((post) => getOrder(post, token), {
    onSuccess: (result) => {
      setload(false);
      if (result.data.success) {
        setOrderDetail(result.data.data);
      } else {
        setTimeout(() => {
          showErrorToast(result.data.msg);
        }, 500);
        setSideMenuAccount("orderDefault");
        navigate({
          pathname: "/account/orders",
        });
      }
    },
    onError: (error) => {
      setload(false);
      if (!errorToastShown) {
        setErrorToastShown(true);
        showErrorToast("Order does not exist");
        setSideMenuAccount("orderDefault");
        navigate({
          pathname: "/account/orders",
        });
      }
    },
  });

  const returnReasonApi = useMutation(
    (post) => getReturnReasonListing(post, token),
    {
      onSuccess: (result) => {
        if (result.data.success) {
          setReturnReasonZustand(result.data.data);
        }
      },
      onError: (error) => {},
    }
  );

  useEffect(() => {
    if (returnOrder) {
      returnReasonApi.mutate(token);
    }
  }, [returnOrder]);
  const [load, setload] = useState(true);
  const [searchParams] = useSearchParams();
  const initialRender1 = useRef(true);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (initialRender1.current) {
      initialRender1.current = false;
      const obj = {
        orderId: searchParams?.get("orderId") ?? orderID,
        dealerId:dealerId
      };
      mutation.mutate(obj);
    }
  }, [searchParams]);
  const [allCards, getAllCardsArray] = useState(false);
  const [defaultCardData, setDefaultCard] = useState(false);
  const authorize = useMutation((post) => authorizeNet(post), {
    onSuccess: (result) => {
      if (result) {
        getAllCardsArray(result.data);
      }
    },
    onError: (error) => {},
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (orderDetail) {
      const getAllCards = {
        getCustomerProfileRequest: {
          merchantAuthentication: {
            name: process.env.REACT_APP_MERCHANT_NAME,
            transactionKey: process.env.REACT_APP_MERCHANT_TRANSACTION_KEY,
          },
          customerProfileId: orderDetail?.paymentInfo?.customerProfileId,
          // includeIssuerInfo: true,
          unmaskExpirationDate: true,
        },
      };
      if (!orderDetail?.paymentInfo?.po) {
        authorize.mutate(getAllCards);
      }
    }
  }, [orderDetail]);

  useEffect(() => {
    if (
      allCards &&
      allCards?.profile?.paymentProfiles &&
      allCards?.profile?.paymentProfiles?.length > 0
    ) {
      let defCard = false;
      allCards.profile.paymentProfiles.forEach((item) => {
        if (
          item.customerPaymentProfileId ===
          orderDetail.paymentInfo.profileInformationId
        ) {
          const obj = {};
          obj.reference1 = item?.billTo?.lastName;
          obj.reference = item?.billTo?.firstName;
          obj.cusProfile = allCards?.profile?.customerProfileId;
          obj.customerPaymentProfileId = item?.customerPaymentProfileId;
          obj.card = item?.payment?.creditCard?.cardNumber;
          obj.expiration = item?.payment?.creditCard?.expirationDate;
          obj.status = item?.defaultPaymentProfile;
          obj.cardType = item?.payment?.creditCard?.cardType;
          defCard = obj;
        }
      });
      setDefaultCard(defCard);
    }
  }, [allCards]);

  const {
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      //   username: localStorage.getItem("email") || "",
      //   password: localStorage.getItem("password") || "",
    },
  });
  const onSubmit = (data, event) => {
    event.preventDefault();
    // setEmail(data.username)
    // setPassword(data.password)
    // const obj = {
    //   username: data.username,
    //   password: data.password,
    // };
    // setLoginData(obj);
    // if (data.username) {
    //   mutation.mutate(obj);

    // }
  };
  const handleSearch = () => {};

  let title = "Order Detail";
  if (modify) {
    title = "Modify Order";
  }
  if (cencel) {
    title = "Cancel Order";
  }
  if (returnOrder) {
    title = "Start A Return";
  }

  return (
    <div className="orderHistoryMainDiv">
      {load && (
        <>
          <Loader></Loader>
          <Loader></Loader>
        </>
      )}
      <div className={`orderDetail borderTopOrderGreyTabletLeftPadding`}>
        <Grid container spacing={1}>
          <Grid
            sx={{ display: "flex", alignItems: "center", padding: "15px 6px" }}
            item
            sm={12}
            md={7}
          >
            <button
              className="ilmorButon"
              onClick={() => {
                setSideMenuAccount("orderDefault");
                navigate({
                  pathname: "/account/orders",
                });
              }}
            >
              <LeftArrow className="catogeryIcon"></LeftArrow> Back
            </button>

            <StyledShippingHeading className="headingCssOrder">
              {title}
            </StyledShippingHeading>
          </Grid>
          {/* <Grid
            justifyContent={"flex-end"}
            className="rightSideOrderDetail"
            sx={{ display: "flex", alignItems: "center" }}
            item
            sm={12}
            md={5}
          > */}
          {/* <div className="center padLeft">
              <Print className="catogeryIcon"></Print>
              <div className="orderDetailLabel">Print</div>
            </div> */}
          {/* <div className="center padLeft">
              <Pdf className="catogeryIcon"></Pdf>
              <div className="orderDetailLabel">Download</div>
            </div> */}
          {/* <div className="center padLeft">
              <Mail className="catogeryIcon"></Mail>
              <div className="orderDetailLabel">Email</div>
            </div> */}
          {/* </Grid> */}
        </Grid>
      </div>
      <Grid
        className="orderDetailNotification tabletLeftPaddingOrderNumb"
        container
        spacing={1}
      >
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            gap: "8px",
          }}
          xs={12}
          md={6}
        >
          <div className="textOrderDetail">{`Order Number: ${orderDetail.orderId}`}</div>
          {/* EE9209 */}

          {currentDevice === "Mobile" ? <br></br> : <></>}
          <OrderStatusStyled
            className={classnames("", {
              justifyHoverDetailPage:
                orderDetail?.orderStatus == "Partially Shipped" ||
                orderDetail?.orderStatus == "Return Initiated" ||
                orderDetail?.orderStatus == "Denied" ||
                orderDetail?.orderStatus == "Return Completed",
            })}
            status={orderDetail.orderStatus}
          >
            {orderDetail.orderStatus == "partiallyShipped"
              ? "Partially Shipped"
              : orderDetail?.orderStatus}
          </OrderStatusStyled>
          {/* <button className="ilmorStatusRed">{`${orderDetail.orderStatus}`}</button> */}
        </Grid>
        {!(modify || cencel || returnOrder) && (
          <Grid
            className="justifyGrid"
            item
            sx={{ display: "flex", alignItems: "center", gap: "8px" }}
            md={6}
            xs={12}
          >
            {orderDetail.orderStatus !== "partiallyShipped" && (
              <>
                {orderDetail.orderStatus !== "Completed" ? (
                  <>
                    {myProfile?.profileInfo?.permissions?.includes(
                      "modify_order"
                    )  && (
                      <>
                        <Box
                          component="span"
                          sx={{ display: "inline" }}
                          className="modifyOrderCancelOrderTabletUpPadding"
                        >
                          {!(
                            dealerId ||
                            orderDetail.orderStatus === "Cancelled" ||
                            orderDetail.orderStatus === "Processing" ||
                            orderDetail.orderStatus === "Partially Shipped" ||
                            orderDetail.orderStatus === "Return Initiated" ||
                            orderDetail.orderStatus === "Return Completed" ||
                            orderDetail.orderStatus === "Denied" ||
                            myProfile?.profileInfo?.role === "ServiceTechnician"
                          ) && (
                            <button
                              onClick={() => {
                                setSideMenuAccount("orderModify");
                                navigate({
                                  pathname: "/account/order-detail",
                                });
                              }}
                              // disabled={
                              //   dealerId ||
                              //   orderDetail.orderStatus === "Cancelled" ||
                              //   orderDetail.orderStatus === "Partially Shipped" ||
                              //   orderDetail.orderStatus === "Return Initiated" ||
                              //   myProfile?.profileInfo?.role ===
                              //     "ServiceTechnician"
                              // }
                              className="ilmorButon"
                            >
                              Modify Order
                            </button>
                          )}
                        </Box>
                      </>
                    )}
                    {myProfile?.profileInfo?.permissions?.includes(
                      "cancel_order"
                    )  &&  (
                      <>
                        <Box
                          component="span"
                          sx={{ display: "inline" }}
                          className="modifyOrderCancelOrderTabletUpPadding"
                        >
                          {!(
                            orderDetail.orderStatus === "Cancelled" ||
                            orderDetail.orderStatus === "Processing" ||
                            orderDetail.orderStatus === "Partially Shipped" ||
                            orderDetail.orderStatus === "Return Initiated" ||
                            orderDetail.orderStatus === "Return Completed" ||
                            orderDetail.orderStatus === "Denied" ||
                            myProfile?.profileInfo?.role === "ServiceTechnician"
                          ) && (
                            <button
                              onClick={() => {
                                setSideMenuAccount("orderCencel");
                                navigate({
                                  pathname: "/account/order-detail",
                                });
                              }}
                              // disabled={
                              //   orderDetail.orderStatus === "Cancelled" ||
                              //   orderDetail.orderStatus === "Partially Shipped" ||
                              //   orderDetail.orderStatus === "Return Initiated" ||
                              //   myProfile?.profileInfo?.role ===
                              //     "ServiceTechnician"
                              // }
                              className="ilmorButon"
                            >
                              Cancel Order
                            </button>
                          )}
                        </Box>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {orderDetail.isOrderHistorical !== 1 &&
                      handleSixty(orderDetail.orderCompletionDate) && !dealerId && (
                        <button
                          onClick={() => {
                            setSideMenuAccount("returnOrder");
                            navigate({
                              pathname: "/account/order-detail",
                            });
                          }}
                          disabled={
                            orderDetail.orderStatus === "Cancelled" ||
                            myProfile?.profileInfo?.role === "ServiceTechnician"
                          }
                          className="ilmorButon"
                        >
                          Return Order
                        </button>
                      )}
                  </>
                )}
              </>
            )}
          </Grid>
        )}
      </Grid>
      <div className="shoppingListOrderDetail">
        <ShippingPaymentComponentForOrderDetail
          modify={modify}
          returnOrder={returnOrder}
          orderDetailData={orderDetail}
          defaultCardData={defaultCardData}
          cencel={cencel}
          orderDetail={true}
          reviewOrder={false}
        ></ShippingPaymentComponentForOrderDetail>
      </div>
    </div>
  );
};

export default OrderDetail;
