
import { EmployeeAccountDetails } from './EmployeeAccountDetails';
import './EmployeeAccounts.css'




const EmployeeAccounts = () => {

//   const employeeAccount = useQuery({
//     queryKey: ['getAllDealerUser'],
//     retry: 2,
//     refetchOnWindowFocus: false,
//     queryFn: () => getAllDealerUser(),
// })

  return (
    <>
    
     
        
          <EmployeeAccountDetails></EmployeeAccountDetails>
      
    
    </>
  )
  }

  export default EmployeeAccounts;