import React, { useState } from "react";
import { useStore } from "../store/home";
import classnames from "classnames";
import NumericInput from "react-numeric-input";
import place from "../Images/placeHolder.png";
import coolant from "../Images/coolant.png";
import Select from "react-select";
import { useEffect } from "react";
import {
  addToCart,
  getMyCart,
  removeFromCart,
  removeItemWish,
} from "../api/posts";
import {
  sortArrayOptions,
  bulkActions,
  addCommaDecimal,
} from "../commonFunction";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import { addWishList } from "../api/posts";
import { getCurrentDate } from "../commonFunction";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../commenComp/Loader";
import CircularProgress from "@mui/material/CircularProgress";
import IlmorModal from "../commenComp/IlmorModal";
import { Grid } from "@material-ui/core";
import classNames from "classnames";

export const CartViewMobile = ({
  reviewOrder,
  addtoCart,
  orderDetail,
  cencel,
  isCart,
  yourCart,
  isAutoApi,
  dataArray,
  handleRefreshCallBack,
}) => {
  const { userInfo, token, dealerId, myProfile, setMyCartMini } = useStore();
  const [tableData, setTableData] = useState([]);
  const { change, setChange, setLoadCArt, loadcart, loadcart1} = useNonPersistedStore();
  // const [change, setChange] = useState(true);
  const buttonStyles = {
    fontFamily: "FontAwesome",
    fontSize: "12px",
  };
  useEffect(() => {
    let arr = [];
    if (dataArray && dataArray?.length > 0) {
      arr = dataArray;
      arr.forEach((element) => {
        element.isloadingRemove = false;
        element.isloadingCart = false;
        element.isChecked = false;
      });
    }
    setTableData(arr);
  }, [dataArray]);

  const email = userInfo.username;
  const mutation_cart = useMutation((post) => getMyCart(post, token), {
    onSuccess: (result) => {
      setMyCartMini(result);
    },
    onError: (error) => {},
  });
  useEffect(() => {
    const obj = {
      email: userInfo.username,
      minicart: true,
      dealerId: dealerId,
    };
    mutation_cart.mutate(obj);
  }, []);


  const handleInputChange = (event, recordId) => {
    const updatedTableData = tableData.map((record) => {
      if (record.partId === recordId) {
        return { ...record, notes: event.target.value };
      }
      return record;
    });
    setChange(false);
    setTableData(updatedTableData);
  };

  const listIsupdated = () =>
    toast.success("Shopping list is updated", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "dark",
    });

  const mutation = useMutation((post) => addWishList(post, token), {
    onSuccess: (result) => {
      listIsupdated();
    },
    onError: (error) => {},
  });

  const handleAddToCartapi = (arAuto,iidd) => {
    let arr = [];
    let ele = document.getElementById(iidd)
    if(ele){
      // ele.blur()
    }
    arAuto.forEach((element) => {
      const obj = {};
      obj.partId = element.partId;
      obj.quantity = element?.partQty <=0 ? 1 :element?.partQty;
      // obj.addedBy= element?.addedBy;
      obj.notes = element.notes;
      const objFinal = {};
      if (element?.otherNotes && Object.values(element.otherNotes).length > 0) {
        Object.entries(element.otherNotes).forEach(([key, item]) => {
          const arr1 = [];
          item.forEach((par) => {
            const objnote = {};
            objnote.title = par.title;
            objnote.value = par.value;
            arr1.push(objnote);
          });
          objFinal[key] = arr1;
        });
      }
      obj.otherNotes = objFinal;
      arr.push(obj);
    });
    let data = {
      email: userInfo?.username,
      isPartDetail: true,
      update: true,
      items: arr,
    };
    toast.dismiss()
    mutationCart.mutate(data);
  };

  const hanbldof = ()=>{
  }
  
  const handleInputChangeSelect = (event, row,allow) => {
    const arrr = tableData;
    arrr.forEach((element) => {
      if (element.partId === row.partId) {
        if(event != element.partQty){
          let ele = document.getElementById(row.partId)
          if(ele){
            ele.focus()
          }
        }
        element.partQty = event;
      }
    });
    setChange(false);
    setTableData(arrr);
    if (isAutoApi && allow) {
      handleAddToCartapi(arrr,row.partId);
    }
  };

  const notifyShop = () =>
    toast.success("Item removed from Shopping List", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "dark",
    });

  const notifyShopCart = () =>
    toast.success("Item removed from Cart", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "dark",
    });

  const removeItem = useMutation((post) => removeItemWish(post, token), {
    onSuccess: (result) => {
      notifyShop();
      handleRefreshCallBack();
    },
    onError: (error) => {},
  });

  const removeItemCart = useMutation((post) => removeFromCart(post, token), {
    onSuccess: (result) => {
      notifyShopCart();
      handleRefreshCallBack();
    },
    onError: (error) => {},
  });

  const notifyShop1 = () =>
    toast.success(
      `${selectedItems.length} Products removed from Shopping list`
    );

  const removeItemAll = useMutation((post) => removeItemWish(post, token), {
    onSuccess: (result) => {
      notifyShop1();
      handleRefreshCallBack();
    },
    onError: (error) => {},
  });
  const handleSave = () => {
    const finalarray = [];
    tableData.forEach((element) => {
      const arr1 = [];
      const obj = {};
      obj.partId = element.partId;
      obj.isShoppingList = true;
      obj.quantity = element?.partQty <=0 ? 1 :element?.partQty;
      obj.date = getCurrentDate();
      obj.notes = element.notes || "";
      if (element?.otherNotes) {
        element.otherNotes.forEach((item) => {
          const objnote = {};
          objnote[item.title] = item.value;
          arr1.push(objnote);
        });
      }
      obj.other_notes = arr1;
      finalarray.push(obj);
    });
    const arr = {
      partDetails: finalarray,
    };
    mutation.mutate(arr);
  };

  const [selectedItems, setSelected] = useState([]);
  const [allCheck, setAllCheck] = useState(false);
  const handleRemove = (row) => {
    const arrr = tableData;
    arrr.forEach((element) => {
      if (element.partId === row.partId) {
        element.isloadingRemove = true;
      }
    });
    setTableData(arrr);
    const arr = [];
    const obj = {};
    obj.wishlistId = row.wishlistId;
    obj.partId = row.partId;
    arr.push(obj);
    removeItem.mutate(arr);
  };

  const handleRemoveCart = (row) => {
    const arrr = tableData;
    arrr.forEach((element) => {
      if (element.partId === row.partId) {
        element.isloadingRemove = true;
      }
    });
    setTableData(arrr);
    const obj = {};
    obj.email = userInfo?.username;
    obj.partId = row.partId;
    obj.partName = row.partName;
    removeItemCart.mutate(obj);
  };
  const notify = () => toast.success("Your Cart is updated");
  const notify1 = () => toast.success("Item was added to cart");
  const notify2 = () => toast.success("All selected itmes added to cart");

  const mutationCart = useMutation((post) => addToCart(post, token), {
    onSuccess: (result) => {
      if (result.data.success) {
        notify();
        const obj = {
          email: userInfo.username,
          dealerId: dealerId,
          minicart: true,
        };
        mutation_cart.mutate(obj);
        handleRefreshCallBack();
        window.scrollTo(0, 0);
      }
    },
    onError: (error) => {},
  });

  const mutationCartSingle = useMutation((post) => addToCart(post, token), {
    onSuccess: (result) => {
      if (result.data.success) {
        setAllCheck(false);
        notify1();
        const obj = {
          email: userInfo.username,
          minicart: true,
        };
        mutation_cart.mutate(obj);
        handleRefreshCallBack();
      }
    },
    onError: (error) => {},
  });

  const mutationCartAll = useMutation((post) => addToCart(post, token), {
    onSuccess: (result) => {
      notify2();
      const obj = {
        email: userInfo.username,
        minicart: true,
      };
      mutation_cart.mutate(obj);
      handleRefreshCallBack();
    },
    onError: (error) => {},
  });

  const handleAddToCart = () => {
    let arr = [];
    tableData.forEach((element) => {
      const obj = {};
      obj.partId = element.partId;
      obj.quantity = element?.partQty <=0 ? 1 :element?.partQty;
      // obj.addedBy= element?.addedBy;
      obj.notes = element.notes;
      const objFinal = {};
      if (element?.otherNotes && Object.values(element.otherNotes).length > 0) {
        Object.entries(element.otherNotes).forEach(([key, item]) => {
          const arr1 = [];
          item.forEach((par) => {
            const objnote = {};
            objnote.title = par.title;
            objnote.value = par.value;
            arr1.push(objnote);
          });
          objFinal[key] = arr1;
        });
      }
      obj.otherNotes = objFinal;
      arr.push(obj);
    });
    let data = {
      email: userInfo?.username,
      update: true,
      isPartDetail: true,
      items: arr,
    };
    mutationCart.mutate(data);
  };
  const handleAddToCartSingleItem = (row) => {
    const arrr = tableData;
    arrr.forEach((element) => {
      if (element.partId === row.partId) {
        element.isloadingCart = true;
      }
    });
    const arrNote = [];
    if (row.otherNotes && row.otherNotes.length > 0) {
      row.otherNotes.forEach((item) => {
        const obj = {};
        obj.title = item.title;
        obj.value = item.value;
        arrNote.push(obj);
      });
    }
    const arr = {
      partId: row.partId,
      quantity: row?.partQty,
      // isPartDetail: false,
      // date: getCurrentDate(),
      notes: row.notes,
      otherNotes: arrNote,
      addedBy: row?.addedBy,
    };
    let obj = [];
    obj.push(arr);

    let data = {
      wishListId: row?.wishlistId,
      email: userInfo?.username,
      items: obj,
    };

    mutationCartSingle.mutate(data);
  };

  const handleSort = (selection) => {
    if (selection.value === "status") {
      const arrr = tableData;
      const sortedArray = [...arrr].sort((a, b) =>
        a.partStatus.toLowerCase().localeCompare(b.partStatus.toLowerCase())
      );
      setTableData(sortedArray);
    }
    if (selection.value === "descAZ") {
      const arrr = tableData;
      const sortedArray = [...arrr].sort((a, b) =>
        a.partName.toLowerCase().localeCompare(b.partName.toLowerCase())
      );
      setTableData(sortedArray);
    }
    if (selection.value === "partNo") {
      const arrr = tableData;
      const sortedArray = [...arrr].sort((a, b) =>
        a?.partName?.toLowerCase()?.localeCompare(b?.partName?.toLowerCase())
      );
      setTableData(sortedArray);
    }
    if (selection.value === "addedBy") {
      const arrr = tableData;
      const sortedArray = [...arrr].sort((a, b) =>
        a?.addedBy?.toLowerCase()?.localeCompare(b?.addedBy?.toLowerCase())
      );
      setTableData(sortedArray);
    }
    if (selection.value === "priceLH") {
      const arrr = tableData;
      const sortedArray = [...arrr].sort((a, b) => {
        const aValue = a?.partDiscountCost;
        const bValue = b?.partDiscountCost;
        const aIsNumber = !isNaN(parseFloat(aValue));
        const bIsNumber = !isNaN(parseFloat(bValue));

        if (aIsNumber && bIsNumber) {
          return parseFloat(aValue) - parseFloat(bValue);
        } else if (aIsNumber) {
          return -1;
        } else if (bIsNumber) {
          return 1;
        } else {
          return aValue.localeCompare(bValue);
        }
      });

      setTableData(sortedArray);
    }
    if (selection.value === "priceHL") {
      const arrr = tableData;
      const sortedArray = [...arrr].sort((a, b) => {
        const aValue = a.partDiscountCost;
        const bValue = b.partDiscountCost;
        const aIsNumber = !isNaN(parseFloat(aValue));
        const bIsNumber = !isNaN(parseFloat(bValue));

        if (aIsNumber && bIsNumber) {
          return parseFloat(bValue) - parseFloat(aValue);
        } else if (aIsNumber) {
          return -1;
        } else if (bIsNumber) {
          return 1;
        } else {
          return aValue.localeCompare(bValue);
        }
      });

      setTableData(sortedArray);
    }
    if (selection.value === "newToOld") {
      const arrr = tableData;
      const sortedArray = [...arrr].sort((a, b) => {
        const aValue = a.addedDate.toLowerCase();
        const bValue = b.addedDate.toLowerCase();
        const aIsDate = !isNaN(Date.parse(aValue));
        const bIsDate = !isNaN(Date.parse(bValue));

        if (aIsDate && bIsDate) {
          return new Date(aValue) - new Date(bValue);
        } else if (aIsDate) {
          return -1;
        } else if (bIsDate) {
          return 1;
        } else {
          return aValue.localeCompare(bValue);
        }
      });

      setTableData(sortedArray);
    }
    if (selection.value === "oldToNew") {
      const arrr = tableData;
      const sortedArray = [...arrr].sort((a, b) => {
        const aValue = a.addedDate.toLowerCase();
        const bValue = b.addedDate.toLowerCase();
        const aIsDate = !isNaN(Date.parse(aValue));
        const bIsDate = !isNaN(Date.parse(bValue));

        if (aIsDate && bIsDate) {
          return new Date(bValue) - new Date(aValue);
        } else if (aIsDate) {
          return -1;
        } else if (bIsDate) {
          return 1;
        } else {
          return aValue.localeCompare(bValue);
        }
      });

      setTableData(sortedArray);
    }
  };

  const navigate = useNavigate();
  const [cancelDialog, setCancelDialog] = useState(false);
  const handleCancel = () => {
    setCancelDialog(!cancelDialog);
  };

  const handleOkay = () => {
    setCancelDialog(!cancelDialog);
  };

  const handleApply = () => {
    if (bulkAction.value === "move") {
      const filteredArray1 = tableData.filter((obj1) =>
        selectedItems.some((obj2) => obj2.partId === obj1.partId)
      );

      const arr = [];
      filteredArray1.forEach((element) => {
        const arrNote = [];
        if (element.otherNotes && element.otherNotes.length > 0) {
          element.otherNotes.forEach((item) => {
            const obj = {};
            obj[item.title] = item.value;
            arrNote.push(obj);
          });
        }
        const obj = {};
        obj.partId = element.partId;
        obj.quantity = element.partQty;
        obj.addedBy = element?.addedBy;
        obj.notes = element.notes;
        obj.otherNotes = arrNote;

        arr.push(obj);
      });
      let data = {
        wishListId: filteredArray1[0]?.wishlistId,
        email: userInfo?.username,
        items: arr,
      };
      mutationCartAll.mutate(data);
    }
    if (bulkAction.value === "remove") {
      const filteredArray1 = tableData.filter((obj1) =>
        selectedItems.some((obj2) => obj2.partId === obj1.partId)
      );

      const arr = [];
      filteredArray1.forEach((element) => {
        const obj = {};
        obj.wishlistId = element.wishlistId;
        obj.partId = element.partId;

        arr.push(obj);
      });
      removeItemAll.mutate(arr);
    }
  };

  const [ok, setOk] = useState(false);
  useEffect(() => {
    if (tableData.length > 0) {
      const selItem = tableData.filter((item) => item.isChecked);
      const allFlagsChecked = tableData.every((obj) => obj.isChecked);
      if (allFlagsChecked) {
        setAllCheck(true);
      }
      setSelected(selItem);
    }
  }, [tableData, ok]);

  const handleCheck = (flag, row) => {
    if (!flag) {
      setAllCheck(false);
    }
    const arrr = tableData;
    arrr.forEach((element) => {
      if (element.partId === row.partId) {
        element.isChecked = flag;
      }
    });
    setOk(!ok);
    setTableData(arrr);
  };

  const handleAddInfo = (row, item, val, key, index) => {
    const updatedTableData = [...tableData];
    const partNote = updatedTableData.find((partNote) => {
      if (
        partNote?.otherNotes &&
        Object.values(partNote?.otherNotes).length > 0
      ) {
        if (partNote.addedBy) {
          return (
            partNote.partId + partNote.addedBy === row.partId + row.addedBy
          );
        } else {
          return partNote.partId === row.partId;
        }
      }
    });

    if (partNote) {
      const obj = partNote?.otherNotes[key];
      const obval = {};
      obval.title = item.title;
      obval.value = val;

      obj[index] = obval;

      // const note = partNote.otherNotes.find(
      //   (note) => note.title === item.title
      // );
      // if (note) {
      //   note.value = val;
      // }
    }
    setChange(false);
    setTableData(updatedTableData);
  };

  const handleCheckAll = (flag) => {
    setAllCheck(flag);
    const arrr = tableData;
    const currentTime = new Date();
    const updatedTableData = tableData.map((element) => ({
      ...element,
      isChecked: flag,
    }));
    setOk(!ok);
    setTableData([...updatedTableData]);
  };

  const handleProductClick = (id) => {
    navigate({
      pathname: "/part",
      search: `number=${id}`,
    });
  };

  useEffect(() => {
    if (mutationCart.isLoading) {
      setLoadCArt(true);
    } else {
      setLoadCArt(false);
    }
  }, [mutationCart.isLoading]);

  const [bulkAction, setbulkAction] = useState("");
  return (
    <>
      <IlmorModal
        isopen={cancelDialog}
        handleOkay={handleOkay}
        handleClose={handleCancel}
        message={"Cancel this order?"}
        fisrtButton={"Don’t Cancel"}
        secondButton={"Yes Cancel"}
      ></IlmorModal>
      {/* {tableData && tableData.length === 0 && (
        <div className="cartEmptyMessage">
          {isCart ? "your cart is empty" : "your shopping list is empty"}{" "}
        </div>
      )} */}
      {(mutation.isLoading || mutationCartAll.isLoading) && <Loader></Loader>}
      {removeItemAll.isLoading && <Loader></Loader>}
      {reviewOrder &&
        !addtoCart &&
        !yourCart &&
        tableData &&
        tableData.length > 0 && (
          <div className="billTtile marginTop partOrderMob">PArts Ordered</div>
        )}

      {/* ==================> MOBILE CODE <==============================*/}

      <div
        className={classnames("shoppingListoutDesktop", {
          carMobile: tableData && tableData.length > 0,
        })}
      >
        <table className={`shoppingListTableDesktop dsd`}>
          <thead className={`shoppingListTableHeadDesktop`}>
            <tr className={`shoppingListTableHeadRowDesktop`}>
              {/* <th width="1%" >
                                    <input type="checkbox" id="ckbCheckAll"></input>
                                </th> */}
              <th
                width="100%"
                className={`shoppingListTableHeadingDesktop`}
              ></th>
            </tr>
          </thead>
          <tbody className={`shoppingListTableBodyDesktop`}>
            {tableData && tableData.length > 0 ? (
              tableData.map((row, index) => (
                <tr
                  className={`shoppingListTableRowDesktop${
                    index % 2 === 0 ? " EvenRow" : ""
                  } ${orderDetail ? " ordeDetailWhiteRow" : ""}`}
                  key={index}
                >
                  {/* <td className={`shoppingListprice`}> */}
                  {/* </td> */}
                  {
                    <td className={`shoppingListTableInputDivDesktop`}>
                      <div className="shopRow1Cart">
                        <div
                          onClick={() => {
                            handleProductClick(
                              row?.partNumber,
                              row?.partStatus === "Discontinued"
                            );
                          }}
                          className="shopListRow1LeftSide"
                        >
                          {/* {!reviewOrder && (
                            <input
                              className={`myProfile_sendNotiFcncheckBox`}
                              onClick={(event) =>
                                handleCheck(event.target.checked, row)
                              }
                              checked={row.isChecked}
                              type="checkbox"
                            />
                          )} */}
                          <img
                            className="shopListRow1Image"
                            src={row.images ? row.images : place}
                          ></img>
                        </div>
                        <div className="shopListRow1RightSide">
                          <div className="shopListTabDetailsSecMain">
                            <div className="shopListTabDetailsSec1">
                              <div className="shopListTabDetailsSec1">
                                {row.productLabel ? (
                                  <span
                                    className={`cardList__newTextDesktop part_align ${
                                      row.productLabel ? "" : " dpHidden"
                                    }`}
                                  >{`${row.productLabel}`}</span>
                                ) : (
                                  ""
                                )}
                                <span className={`shoppingListSmallTitile`}>
                                  {row.partNumber}
                                </span>
                              </div>
                              <span                           onClick={() => {
                            handleProductClick(
                              row?.partNumber,
                              row?.partStatus === "Discontinued"
                            );
                          }} className={`curp shoppingListTitle`}>
                                {row.partName}
                              </span>
                            </div>
                            <span
                              className={`shoppingListTitle notAvailableText`}
                            >
                              {row.isBackordered &&
                              row?.inventory <= row?.inStockQuantity
                                ? "Backordered"
                                : ""}
                            </span>
                            <span
                              className={`shoppingListTitle notAvailableText `}
                            >
                              {row.partStatus ? row.partStatus : ""}
                            </span>
                            {row.specialOrder === "Yes" && (
                              <span
                                className={`shoppingListTitle notAvailableText shoppingListTablepriceMessage`}
                              >
                                {row.specialOrder === "Yes"
                                  ? "(Longer Lead Time)"
                                  : ""}
                              </span>
                            )}
                            {row.message && (
                              <>
                                <span
                                  className={`shoppingListTitle  shoppingListTablepriceMessage`}
                                >
                                  {row.message ? ` (${row.message})` : ""}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="shopListDetailTablet">
                        {reviewOrder && (
                          <div className="shopListTabDiv">
                            <span className="shopTableLabel">Qty</span>
                            <span className={`shoppingListTableprice`}>
                              {addCommaDecimal(row.partQty)}
                            </span>
                          </div>
                        )}
                        {
                          <div
                            // className="shopListTabDetailsSec2Cart"
                            className={classNames(
                              "shopListTabDetailsSec2Cart",
                              {
                                blurQuant: mutationCart.isLoading  || loadcart1 || loadcart,
                              }
                            )}
                          >
                            <span className="shopTableLabel">Qty</span>
                            <NumericInput
                              disabled={
                                myProfile?.profileInfo?.role ===
                                  "ServiceTechnician" && !row?.isPermission
                              }
                              className={`shoppingListTableQuantityDesktop lighBorder`}
                              style={buttonStyles}
                              max={999}
                              id={row.partId}
                              // min={1}
                              strict
                              mobile={false}
                              onChange={(event) =>
                                handleInputChangeSelect(event, row)
                              }
                              onBlur={(event) => {
                                if (event.target.value =="") {
                                  handleInputChangeSelect(1, row, true);
                                } else {
                                  handleInputChangeSelect(event.target.value, row, true);
                                }
                              }}
                              value={row.partQty <= 0 ? "" : row.partQty}

                              // value={row.partQty}
                            />
                            {!reviewOrder && (
                              <div className="shopListLow lowpaddingformob">
                                {row?.isloadingRemove ? (
                                  <CircularProgress
                                    style={{ color: "#CE0E2D" }}
                                    size={20}
                                  />
                                ) : (
                                  <>
                                    {!(
                                      myProfile?.profileInfo?.role ===
                                        "ServiceTechnician" &&
                                      !row?.isPermission
                                    ) && (
                                      <span
                                        onClick={(event) =>
                                          handleRemoveCart(row)
                                        }
                                        className={`shopLiRemoveCart`}
                                      >
                                        Remove
                                      </span>
                                    )}
                                  </>
                                )}
                                {/* {myProfile?.profileInfo?.permissions?.includes(
                            "add_to_own_cart"
                          ) && (
                            <span className={`shopLiMovToCart moveCart`}>
                              Move to Cart
                            </span>
                          )} */}
                              </div>
                            )}
                          </div>
                        }

                        <div className="shopListTabDivCart xfgth">
                          <span className="shopTableLabelCart">Price</span>
                          {row?.partDiscountCost && (
                            <span className={`shoppingListTableprice`}>
                              ${addCommaDecimal(row?.partDiscountCost)}
                            </span>
                          )}
                          {/* <br></br> */}
                          {row?.partOrigionalCost && addCommaDecimal(row?.partOrigionalCost) &&  (
                            <span
                              className={`shoppingListTablepriceDiscounted`}
                            >
                              ${addCommaDecimal(row?.partOrigionalCost)}
                            </span>
                          )}
                        </div>
                        {
                          <div className="shopListTabDivCart xfgth">
                            <span className="shopTableLabelCart">Total</span>
                            <span className={`shoppingListTableprice fontforTotoal`}>
                              $
                              {addCommaDecimal(row.partQty * row?.partDiscountCost)}
                            </span>
                          </div>
                        }
                        {/* {!reviewOrder && (
                          <div className="shopListTabDiv">
                            <span className="shopTableLabel">Added By</span>
                            <span className={`shoppingListTableaddedBy`}>
                              {row.addedBy}
                            </span>
                          </div>
                        )} */}
                        {!reviewOrder && !yourCart && (
                          <div className="shopListTabDiv">
                            <span className="shopTableLabel">Date Added</span>
                            <span className={`shoppingListTabledate`}>
                              {row.addedDate}
                            </span>
                          </div>
                        )}
                        {/* {!reviewOrder && !yourCart && (
                            <div className="shopListTabDiv">
                              <span className="shopTableLabel">Status</span>
                              <span className={`shoppingListTablestatus`}>
                                {row.partStatus}
                              </span>
                            </div>
                          )} */}
                        {reviewOrder && !addtoCart && (
                          <td className={`shoppingListdateTotaldiv`}>
                            <span className={`shoppingListTableprice`}>
                              Qty : {row.partQty}
                            </span>
                          </td>
                        )}
                        {reviewOrder && !addtoCart && (
                          <td className={`shoppingListdateTotaldiv`}>
                            <span className={`shoppingListTabledateTotal`}>
                              ${row.part_price}
                            </span>
                          </td>
                        )}
                      </div>
                      {/* {!reviewOrder && (
                        <>
                          <Grid
                            container
                            style={{
                              // flexDirection: "column",
                              // display: "flex",
                              marginTop: "20px",
                              textAlign: "left",
                            }}
                            spacing={1}
                            lg={12}
                            xs={12}
                            md={12}
                          >
                            {row?.otherNotes &&
                              row?.otherNotes.map((item) => (
                                <Grid item md={4}>
                                  <div className="labelPartDetail">
                                    {item.title}
                                  </div>
                                  <input
                                    className={`InputFieldShippingAddressPayment shoppingListInput`}
                                    onChange={(event) => {
                                      handleAddInfo(
                                        row,
                                        item,
                                        event.target.value
                                      );
                                    }}
                                    value={item.value}
                                    type="text"
                                    name="firstname"
                                  ></input>
                                </Grid>
                              ))}
                          </Grid>
                        </>
                      )} */}

                      {/* { !addToCart && <input
                          type="text"
                          className={`shoppingListInput`}
                          value={row.input}
                          onChange={(event) =>
                            handleInputChange(event, index, "input")
                          }
                        ></input>} */}
                      {row?.otherNotes &&
                        Object.values(row?.otherNotes).length > 0 &&
                        Object.values(row?.otherNotes)[0].length > 0 && (
                          <>
                            <div className="labelfonttitle">
                              Required information
                            </div>
                            <Grid
                              container
                              style={{
                                // flexDirection: "column",
                                // display: "flex",
                                // marginTop: "20px",
                                textAlign: "left",
                                paddingBottom: "24px",
                              }}
                              spacing={1}
                              lg={12}
                              xs={12}
                              md={12}
                            >
                              {Object.entries(row?.otherNotes).map(
                                ([key, value]) =>
                                  value &&
                                  value.length > 0 &&
                                  value?.map((innerArray, index) => (
                                    <>
                                      <Grid item xs={12}>
                                        <div className="labelDesktopAddShippingRequired labelfont">
                                          {innerArray.title}
                                        </div>
                                        <input
                                          className={`InputFieldShippingAddressPayment shoppingListInput addRed`}
                                          onChange={(event) => {
                                            handleAddInfo(
                                              row,
                                              innerArray,
                                              event.target.value,
                                              key,
                                              index
                                            );
                                          }}
                                          value={innerArray.value}
                                          type="text"
                                          name="firstname"
                                        ></input>
                                      </Grid>
                                    </>
                                  ))
                              )}
                            </Grid>
                          </>
                        )}
                    </td>
                  }
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </table>
        {!reviewOrder && (
          <div className={`shoppingListButtonSectionDesktop`}>
            <div className={`shoppingListBottomButtonsDesktop`}>
              {/* <div className="leftSideShop">
                <Select
                  className="header__select iLmorborder"
                  // defaultValue={''}
                  placeholder="Bulk Actions"
                  value={bulkAction}
                  onChange={(e) => {
                    setbulkAction(e);
                  }}
                  // menuIsOpen={true}
                  options={bulkActions}
                />
                <button
                  onClick={handleApply}
                  disabled={!bulkAction || !selectedItems.length > 0}
                  className="ilmorButon"
                >
                  Apply
                </button>
              </div> */}
              {tableData && tableData.length > 0 && (
                <div className={`shoppingListTableRightButtonsDesktop asdsasdee1`}>
                  <button
                    onClick={handleAddToCart}
                    disabled={change || mutationCart.isLoading}
                    className={`ilmorButon cartButon updateCartMobileButton`}
                  >
                    Update Cart
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
