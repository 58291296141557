import { Box } from '@mui/system';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import background from "../../Images/notFoundIlmor.png";
import Footer from '../Footer';
import Header from '../Header';
import { StyledFourOFour } from "../StyledComponents/CommonStyled";
import { AddEmployeeAccountBtnStyled } from '../StyledComponents/EmployeeAccountStyled';
const NotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header></Header>
      <Box
        sx={{
          height: " calc(100% - 243px)",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
            height: " 100%",
            backgroundPosition: "left",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <StyledFourOFour text="fourOfour">404</StyledFourOFour>
          <StyledFourOFour text="pageNot">PAGE NOT FOUND</StyledFourOFour>
          <StyledFourOFour   sx={{
              paddingTop: "22px",
            }}>
            This page doesn't exist or was removed.
          </StyledFourOFour>
          <StyledFourOFour
            sx={{
              paddingBottom: "22px",
            }}
          >
            Head home to find what you're looking for.
          </StyledFourOFour>
          <AddEmployeeAccountBtnStyled
            onClick={() => {
              navigate({
                pathname: "/",
              });
            }}
          >
            Shop Main Page
          </AddEmployeeAccountBtnStyled>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default NotFound;