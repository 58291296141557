import moment from "moment";
export const deviceDetector = (screenSize) => {
  if (screenSize > 900) {
    return "Desktop";
  } else if (screenSize > 600 && screenSize <= 900) {
    return "Tablet";
  } else {
    return "Mobile";
  }
};

export const getCurrentDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const truncBoy = (stri, len) => {
  let final = stri;
  if (final && final.length < len) {
    return final;
  } else if (final && final.length > len) {
    return final.slice(0, len) + "...";
  } else {
    return "";
  }
};

export const lowerBoy = (text) => {
  if(text){
    return text.toLowerCase();
  }
};

export const addCommaDecimal = (number) => {
  if ((number || number === "0") && parseFloat(number)) {
    return parseFloat(number)?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else {
    return "";
  }
};

export const handleSixty = (date) => {
  if (date) {
    const orderDate = date;
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const orderDateTime = new Date(
      "20" + orderDate.replace(/(\d{2})\/(\d{2})\/(\d{2})/, "$3-$1-$2")
    );
    const currentDate = new Date();
    const differenceInDays = (currentDate - orderDateTime) / millisecondsPerDay;
    if (differenceInDays <= 61) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const addCommaDecimalreturnZ = (number) => {
  if ((number || number === "0") && parseFloat(number)) {
    return parseFloat(number)?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else {
    return "0.00";
  }
};

export const formatDate = (date) => {
  const currentDate = new Date(date);
  const myFormat = "YYYY-MM-DD";
  const myDate = moment(currentDate, "YYYYMMDDTHHmmss").format(myFormat);
  const dateMoment = moment(myDate);
  const formattedDateMoment = dateMoment.format("DD/MM/YY");
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${month}/${day}/${year.toString().slice(2, 4)}`;
  return formattedDateMoment;
};

export const sortArrayOptions = [
  {
    label: "Date Added New to Old",
    value: "oldToNew",
  },
  {
    label: "Date Added Old to New",
    value: "newToOld",
  },
  {
    label: "Added By",
    value: "addedBy",
  },
  {
    label: "Price Low to High",
    value: "priceLH",
  },
  {
    label: "Price High to Low",
    value: "priceHL",
  },
  {
    label: "Part Number A-Z",
    value: "partNo",
  },
  {
    label: "Description A-Z",
    value: "descAZ",
  },
  {
    label: "Inventory Status",
    value: "status",
  },
];

export const bulkActions = [
  {
    label: "Move selected items to Cart",
    value: "move",
  },
  {
    label: "Remove selected items from Shopping list",
    value: "remove",
  },
];
export const bulkActionsTech = [
  {
    label: "Remove selected items from Shopping list",
    value: "remove",
  },
];

export const myAccountCategory = [
  {
    name: "Account Home",
    id: "home",
  },
  {
    name: "Order Status & History",
    id: "orderDefault",
  },
  {
    name: "Account Management",
    id: "employee1",
  },
  {
    name: "Employee Accounts",
    id: "employee",
  },
  {
    name: "Business Details",
    id: "businessDetails",
  },
  {
    name: "Shipping Addresses",
    id: "shipping",
  },
  {
    name: "Payment Methods",
    id: "paymentMethods",
  },
  {
    name: "Tax Exemption",
    id: "tax",
  },

  {
    name: "My Profile",
    id: "myProfile",
  },
  // {
  //   name: "Sign In Information",
  //   id: "signInInfo",
  // },
];

export const initialState = {
  quickOrderData: [
    {
      partName: "",
      quantity: 1,
      status: "",
      originalPrice: "",
      dealerPrice: "",
      message: "",
    },
    {
      partName: "",
      quantity: 1,
      status: "",
      originalPrice: "",
      dealerPrice: "",
      message: "",
    },
    {
      partName: "",
      quantity: 1,
      status: "",
      originalPrice: "",
      dealerPrice: "",
      message: "",
    },
    {
      partName: "",
      quantity: 1,
      status: "",
      originalPrice: "",
      dealerPrice: "",
      message: "",
    },
    {
      partName: "",
      quantity: 1,
      status: "",
      originalPrice: "",
      dealerPrice: "",
      message: "",
    },
  ],
  userInfo: false,
  defaultCard: false,
  paymentStatus: false,
  isCurrentEngSet: false,
  isCurrentEngSetValue: false,
  cardEditId: "",
  cusProfile: "",
  dealerId: "",
  myProfile: [],
  myCart: [],
  myCartMini: [],
  partListArray: [],
};
