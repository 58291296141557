import React, { useState } from "react";
import "./FindParts.css";
import { useStore } from "../store/home";
import Select from "react-select";
import { Button } from "@material-ui/core";
// import EastIcon from "@mui/icons-material/East";
import { ReactComponent as ViewPartArrow } from "../Images/arroWhite.svg";
import loaderImg from "../Images/loaderImg.gif";
import { useEffect } from "react";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import { useMutation } from "@tanstack/react-query";
import { getAllDropdownOptions, getProductListCategory, searcPartsYMM } from "../api/posts";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import { customStyles } from "../utils/ReactSelectCustomStyles";
import { customStyles3 } from "../utils/ReactSelectCustomStylesPart";

const FindParts = () => {
  const {
    setScreenSize,
    setCurrentDevice,
    screenSize,
    isCurrentEngSetValue,
    token,
    setPartListArray,
    isCurrentEngSet,
    partListArray,
    setIsCurrentEngSet,
    EngineYear,
    setCategorySide,
    setSelectedSubCategory,
    setIsCurrentEngSetValue,
    setSelectedCategory,
    EngineModel,
    currentDevice,
    dealerId,
  } = useStore();

  // const options1 = [
  //   { value: "2020", label: "2020" },
  //   { value: "2021", label: "2021" },
  //   { value: "2022", label: "2022" },
  // ];

  // const options2 = [
  //   { value: "Mercedes", label: "Mercedes" },
  //   { value: "Audi", label: "Audi" },
  //   { value: "BMW", label: "BMW" },
  // ];

  // const options3 = [
  //   { value: "Mercedes", label: "Mercedes" },
  //   { value: "Audi", label: "Audi" },
  //   { value: "BMW", label: "BMW" },
  // ];

  const [yearValue, setYearValue] = useState("");
  const [typeValue, setTypeValue] = useState(null);
  const [modelValue, setModelValue] = useState(null);
  const { setRefreshQ,refreshQ } = useNonPersistedStore();

  const isButtonDisabled = !yearValue || !typeValue || !modelValue;

  const handleOption1Change = (option) => {
    setYearValue(option);
    setTypeValue(null);
    setModelValue(null);
  };

  const handleOption2Change = (option) => {
    setTypeValue(option);
    setModelValue(null);
  };

  const handleOption3Change = (option) => {
    setModelValue(option);
  };
  const navigate = useNavigate();
  const [year, setYear] = useState([]);
  const [type, setType] = useState([]);
  const [model, setModel] = useState([]);

  const mutation1 = useMutation((post) => getAllDropdownOptions(post), {
    mutationKey: "getDropDownValues",
    method: "GET",
    onSuccess: (result) => {
      if (result?.data?.data?.year && !yearValue) {
        const options = Object.entries(result?.data?.data?.year).map(
          ([label, value]) => ({
            label,
            value,
          })
        );
        setYear(options.reverse());
      }
    },
    onError: (error) => {},
  });

  const mutation2 = useMutation((post) => getAllDropdownOptions(post), {
    mutationKey: "getDropDownValues",
    method: "GET",
    onSuccess: (result) => {
      if (result?.data?.data?.make && yearValue) {
        const options = Object.entries(result?.data?.data?.make).map(
          ([value, label]) => ({
            label,
            value,
          })
        );
        setType(options);
      }
    },
    onError: (error) => {},
  });

  const mutation3 = useMutation((post) => getAllDropdownOptions(post), {
    mutationKey: "getDropDownValues",
    method: "GET",
    onSuccess: (result) => {
      if (result?.data?.data?.make && yearValue && typeValue) {
        const options = Object.entries(result?.data?.data?.model).map(
          ([value, label]) => ({
            label,
            value,
          })
        );
        setModel(options);
      }
    },
    onError: (error) => {},
  });

  useEffect(() => {
    const params = {
      token: token,
      year: true,
      make: "",
    };
    mutation1.mutate(params);
  }, []);

  useEffect(() => {
    if (yearValue) {
      const params = {
        token: token,
        year: yearValue.value,
        make: "",
      };
      mutation2.mutate(params);
    }
  }, [yearValue]);

  useEffect(() => {
    if (typeValue) {
      const params = {
        token: token,
        year: yearValue.value,
        make: typeValue.value,
      };
      mutation3.mutate(params);
    }
  }, [typeValue]);

  const mutation11 = useMutation((post) => getProductListCategory(post), {
    mutationKey: "getProductListCategory",
    method: "GET",
    onSuccess: (result) => {
      setCategorySide(result.data.data)
    },
    onError: (error) => {
    },
  });

  const searchParts = useMutation((post) => searcPartsYMM(post, token), {
    onSuccess: (result) => {
      const obj = { ...isCurrentEngSet };
      obj.engineId = result.data.engineId;
      obj.engineImage = result.data.engineImage;
      obj.isShopByDiagram = result.data.isShopByDiagram;
      setIsCurrentEngSet(obj);
      const data = {
        token: token,
        dealerId:dealerId,
        EngineId: result.data.engineId,
      };
      mutation11.mutate(data);
      setRefreshQ(!refreshQ)
      setPartListArray(result.data.data);
      setSelectedSubCategory("");
      setSelectedCategory("");
      navigate({
        pathname: "/catalog",
        search: `isShopByDiagram=${obj.isShopByDiagram}&yearId=${isCurrentEngSetValue.year}&year=${obj.year}&make=${obj.make}&makeId=${isCurrentEngSetValue.make}&model=${obj.model}&modelId=${isCurrentEngSetValue.model}&category=${''}&p=${0}&subCategory=${''}&engineId=${obj.engineId}`,
      });
    },
    onError: (error) => {},
  });

  const handleSave = () => {
    const arr = {
      year: yearValue.value,
      make: typeValue.value,
      model: modelValue.value,
      dealerId: dealerId,
      categoryId: "",
      subcategoryId: "",
    };
    const arrLabel = {
      year: yearValue.label,
      make: typeValue.label,
      model: modelValue.label,
      categoryId: "",
      subcategoryId: "",
    };
    let arr1 = [];
    if (partListArray && partListArray.length > 0) {
      arr1 = partListArray.map((item) => {
        item.images = loaderImg;
        item.isLoad = true;
        return item;
      });
    }
    setRefreshQ(!refreshQ)
    setPartListArray(arr1);
    setIsCurrentEngSet(arrLabel);
    setIsCurrentEngSetValue(arr);
    searchParts.mutate(arr);
  };
  return (
    <>
      {/* {searchParts.isLoading && <Loader></Loader>} */}
      <div className={`findParts__main${currentDevice}`}>
        <div className={`findParts__findparts${currentDevice}`}>
          <div className={`findParts__title2${currentDevice}`}>
            <h2 className={`findParts__h2${currentDevice}`}>FIND PARTS FOR</h2>
            <h2 className={`findParts__h2b${currentDevice}`}>
              {" "}
              A SPECIFIC ENGINE{" "}
            </h2>
          </div>
          <div className={`findParts__boxes${currentDevice}`}>
            <Select
              styles={customStyles3}
              // className={`findParts__box${currentDevice}`}
              value={yearValue}
              isLoading={mutation1.isLoading}
              isSearchable={false}
              placeholder="Model Year"
              onChange={handleOption1Change}
              options={year}
              // menuIsOpen ={true}
            />
            <Select
              styles={customStyles3}
              className={`${!yearValue ? " findPartsBoxdisabled" : ""}`}
              // className={`findParts__box${currentDevice}${
              //   !yearValue ? " findPartsBoxdisabled" : ""
              // }`}

              value={typeValue}
              placeholder="Engine Type"
              isLoading={mutation2.isLoading}
              isSearchable={false}
              onChange={handleOption2Change}
              options={type}
              isDisabled={!yearValue}
            />
            <Select
              styles={customStyles3}
              className={`${!typeValue ? " findPartsBoxdisabled" : ""}`}
              // className={`findParts__box${currentDevice}${
              //   !typeValue ? " findPartsBoxdisabled" : ""
              // }`}
              value={modelValue}
              placeholder="Engine Model"
              isLoading={mutation3.isLoading}
              isSearchable={false}
              onChange={handleOption3Change}
              options={model}
              isDisabled={!typeValue}
            />
            <button
              onClick={handleSave}
              className={`findParts__btn${currentDevice}${
                isButtonDisabled ? " findPartsBtndisabled" : " viewPartsActive"
              }`}
              disabled={isButtonDisabled}
            >
              <span className={`viewPartsText${currentDevice}`}>
                View Parts
              </span>
              <ViewPartArrow
                className={`revers viewPartsArrow${currentDevice}`}
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FindParts;
