import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import zukeeper from "zukeeper";

export const useStore = create(
  persist(
    zukeeper((set) => ({
      screenSize: [window.innerWidth, window.innerHeight],
      setScreenSize: (str) => set({ screenSize: str }),
      currentDevice: "Desktop",
      setCurrentDevice: (str) => set({ currentDevice: str }),
      token: localStorage.getItem("token"),
      setToken: (token) => set({ token: token }),
      categoryId: "",
      setCategoryId: (categoryId) => set({ categoryId: categoryId }),
      categoryIdSearch: "",
      setCategoryIdSearch: (categoryIdSearch) =>
        set({ categoryIdSearch: categoryIdSearch }),
      subCategoryId: "",
      setSubCategoryId: (subCategoryId) =>
        set({ subCategoryId: subCategoryId }),
      loading: false,
      setLoading: (loading) => set({ loading: loading }),
      categoryOptions: [],
      setCategory: (options) => set({ categoryOptions: options }),
      isCurrentEngSet: false,
      setIsCurrentEngSet: (isCurrentEngSet) =>
        set({ isCurrentEngSet: isCurrentEngSet }),
      isCurrentEngSetValue: false,
      setIsCurrentEngSetValue: (isCurrentEngSetValue) =>
        set({ isCurrentEngSetValue: isCurrentEngSetValue }),
      vals: false,
      setVals: (vals) => set({ vals: vals }),
      checkActive: false,
      setCheckActive: (checkActive) => set({ checkActive: checkActive }),
      partListArray: [],
      setPartListArray: (partListArray) =>
        set({ partListArray: partListArray }),
      ModelYear: [],
      setModelYear: (options) => set({ ModelYear: options }),
      EngineYear: [],
      setEngineYear: (options) => set({ EngineYear: options }),
      banners: [],
      setBanners: (banners) => set({ banners: banners }),
      EngineModel: [],
      setEngineModel: (options) => set({ EngineModel: options }),
      categoryName: [],
      setCategoryName: (options) => set({ categoryName: options }),
      isCard: false,
      setIsCard: (toggle) => set({ isCard: toggle }),
      isCardActive: true,
      setIsCardActive: (isCardActive) => set({ isCardActive: isCardActive }),
      accountState: "add",
      setAccountState: (accountState) => set({ accountState: accountState }),
      forgotEmail: [],
      setForgotEmail: (email) => set({ forgotEmail: email }),
      checkoutState: "order",
      setCheckoutState: (checkoutState) =>
        set({ checkoutState: checkoutState }),
      keyWord: "",
      setKeyWord: (keyWord) => set({ keyWord: keyWord }),
      currentLocation: "",
      setCurrentLocation: (currentLocation) =>
        set({ currentLocation: currentLocation }),
      keyWordFinal: "",
      setKeyWordFinal: (keyWordFinal) => set({ keyWordFinal: keyWordFinal }),
      cat: [],
      setCatagory: (options) => set({ category: options }),
      selectedCategory: "",
      setSelectedCategory: (options) => set({ selectedCategory: options }),
      categorySide: [],
      setCategorySide: (categorySide) => set({ categorySide: categorySide }),
      wishList: [],
      setWishList: (wishList) => set({ wishList: wishList }),
      // selectedCategory: [],
      // setSelectedCategory: (options) => set({ selectedCategory: options }),
      selectedSubCategory: "",
      setSelectedSubCategory: (options) =>
        set({ selectedSubCategory: options }),
      quickOrderData: [
        {
          partName: "",
          quantity: 1,
          status: "",
          originalPrice: "",
          dealerPrice: "",
          message: "",
        },
        {
          partName: "",
          quantity: 1,
          status: "",
          originalPrice: "",
          dealerPrice: "",
          message: "",
        },
        {
          partName: "",
          quantity: 1,
          status: "",
          originalPrice: "",
          dealerPrice: "",
          message: "",
        },
        {
          partName: "",
          quantity: 1,
          status: "",
          originalPrice: "",
          dealerPrice: "",
          message: "",
        },
        {
          partName: "",
          quantity: 1,
          status: "",
          originalPrice: "",
          dealerPrice: "",
          message: "",
        },
      ],
      setQuickOrderData: (quickOrderData) =>
        set({ quickOrderData: quickOrderData }),
      myAccountSelect: [],
      setMyAccountSelect: (myAccountSelect) =>
        set({ myAccountSelect: myAccountSelect }),
      currentUserEmail: "",
      setCurrentUserEmail: (payload) => set({ currentUserEmail: payload }),
      orderHistoryState: "default",
      setOrderHistoryState: (orderHistoryState) =>
        set({ orderHistoryState: orderHistoryState }),
      sideMenuAccount: "home",
      setSideMenuAccount: (sideMenuAccount) =>
        set({ sideMenuAccount: sideMenuAccount }),
      myProfile: [],
      setMyProfile: (options) => set({ myProfile: options }),
      returnReasonZustand: [],
      setReturnReasonZustand: (payload) =>
        set({ returnReasonZustand: payload }),

      returnOptionZustand: [],
      setReturnOptionZustand: (payload) =>
        set({ returnOptionZustand: payload }),
      storeTax: [],
      setstoreTax: (options) => set({ storeTax: options }),
      defaultCard: false,
      setDefaultCard: (defaultCard) => set({ defaultCard: defaultCard }),
      paymentStatus: false,
      setPaymentStatus: (paymentStatus) =>
        set({ paymentStatus: paymentStatus }),
      cardEditId: "",
      setCardEditId: (cardEditId) => set({ cardEditId: cardEditId }),
      stopNavigate: false,
      setStopNavigate: (stopNavigate) => set({ stopNavigate: stopNavigate }),
      myCart: [],
      setMyCart: (options) => set({ myCart: options }),
      myCartMini: [],
      setMyCartMini: (myCartMini) => set({ myCartMini: myCartMini }),
      userInfo: false,
      setUserInfo: (userInfo) => set({ userInfo: userInfo }),
      bussData: false,
      setBussData: (bussData) => set({ bussData: bussData }),
      signInInfo: [],
      setSignInInfo: (options) => set({ signInInfo: options }),
      service: { value: "dhl", label: "DHL Express Worldwide" },
      setservice: (service) => set({ service: service }),
      shipPrice: "",
      setshipPrice: (shipPrice) => set({ shipPrice: shipPrice }),
      shipInterOption: "",
      setShipInterOption: (shipInterOption) =>
        set({ shipInterOption: shipInterOption }),
      cusProfile: "",
      setcusProfile: (cusProfile) => set({ cusProfile: cusProfile }),
      editCardInfo: "",
      seCardInfo: (editCardInfo) => set({ editCardInfo: editCardInfo }),
      isPO: "notSet",
      setIsPO: (isPO) => set({ isPO: isPO }),
      isDomestic: "",
      setDomestic: (isDomestic) => set({ isDomestic: isDomestic }),
      isDomesticCH: "",
      setDomesticCH: (isDomesticCH) => set({ isDomesticCH: isDomesticCH }),
      isPOValue: "",
      setIsPOValue: (isPOValue) => set({ isPOValue: isPOValue }),
      snote: "",
      setSnote: (snote) => set({ snote: snote }),
      orderDetailInfo: "",
      setOrderDetailInfo: (orderDetailInfo) =>
        set({ orderDetailInfo: orderDetailInfo }),
      orderDetailInfoComp: "",
      setOrderDetailInfoComp: (orderDetailInfoComp) =>
        set({ orderDetailInfoComp: orderDetailInfoComp }),
      footerContentdataInZustand: [],
      setFooterContentdataInZustand: (payload) =>
        set({ footerContentdataInZustand: payload }),
      footerApiLinkData: [],
      setFooterApiLinkData: (payload) => set({ footerApiLinkData: payload }),
      footerKeyZustand: [],
      setFooterKeyZustand: (payload) => set({ footerKeyZustand: payload }),
      orderID: [],
      setOrderID: (orderID) => set({ orderID: orderID }),
      orderFail: false,
      setOrderFail: (orderFail) => set({ orderFail: orderFail }),
      unsaved: false,
      setUnsaved: (unsaved) => set({ unsaved: unsaved }),
      cCode: false,
      setCode: (cCode) => set({ cCode: cCode }),
      dealerId: "",
      setDealerId: (dealerId) => set({ dealerId: dealerId }),
      pass: "",
      setPass: (pass) => set({ pass: pass }),
      emailStore: "",
      setEmailStore: (emailStore) => set({ emailStore: emailStore }),
      parID: "",
      setParID: (parID) => set({ parID: parID }),
      offset: 0,
      setOffset: (offset) => set({ offset: offset }),
      trecord: 0,
      setTRecord: (trecord) => set({ trecord: trecord }),
      record: 20,
      setRecord: (record) => set({ record: record }),
      start: 0,
      setstart: (start) => set({ start: start }),
      allow: false,
      setAllow: (allow) => set({ allow: allow }),
      box: 1,
      setBox: (box) => set({ box: box }),
      boxPage: 0,
      setBoxPage: (boxPage) => set({ boxPage: boxPage }),
      sort: "partNumber",
      setSort: (sort) => set({ sort: sort }),
      diableDiv: false,
      setDiableDiv: (diableDiv) => set({ diableDiv: diableDiv }),
      lastPage: false,
      setLastPage: (lastPage) => set({ lastPage: lastPage }),
      refreshToken: false,
      setRefreshToken: (refreshToken) => set({ refreshToken: refreshToken }),
      globalcategoryIdCatalog: "",
      setGlobalcategoryIdCatalog: (payload) =>
        set({ globalcategoryIdCatalog: payload }),
      subArray: "",
      setSubArray: (subArray) => set({ subArray: subArray }),
      finalShipPrice: 0,
      setFinalShipPrice: (finalShipPrice) =>
        set({ finalShipPrice: finalShipPrice }),
    })),
    {
      name: "my-persisted-store",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
