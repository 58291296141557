import Header from "./Header";
import Footer from "./Footer";
import "./Checkout.css";
import SubHeading from "../commenComp/SubHeading";
import { Grid } from "@mui/material";
import { useEffect } from "react";

const Warranty = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  
  return (
    <>
      <Grid  container>
        <Grid item lg={12} className="catalogPageHeaderAndPartsCatalog">
          <Header page="Catalog"></Header>
          {<SubHeading heading={"Warranty"} />}
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Grid
            container
            className="sdfds232323de"
            justifyContent="center"
            spacing={2}
            sx={{ wordBreak: "break-word", padding: "30px 0px 70px 0px" }}
          >
            <Grid item xs={8}>
              <span>{`All parts sold on the Ilmor store (`}</span>{" "}
              <span
                className="under"
                onClick={() => {
                  window.open("https://portal.ilmor.com/", "_blank");
                }}
              >
                https://portal.ilmor.com/
              </span>{" "}
              <span>
                {`) come with a 60 day warranty from the date of purchase.`}{" "}
              </span>
              <div className="dfglkjuji7675">Warranty for repower and replacement engines is as follows:</div>
              <ol>
                <li className="liClas">
                  Long Block: Six (6) month warranty or remaining factory
                  warranty
                </li>
                <li className="liClas">
                  Bobtail: One (1) year warranty or remaining factory warranty
                </li>
              </ol>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="fedm12n" item md={12} sm={12} lg={12}>
          <Footer></Footer>
        </Grid>
      </Grid>
    </>
  );
};

export default Warranty;
