const apiEndpoints = {
  API_URL: process.env.REACT_APP_API,
  GET_LOGIN_TOKEN: "api/login_check",
  GET_FEATURED_PRODUCTS: "api/rest/getfeaturedProducts",
  GET_PRODUCT_LISTING_BY_CATEGORY: "api/rest/getProductListingByCategory",
  GET_ENGINE_CATEGORIES: "api/rest/getEngineCategories",
  GET_PART_LIST: "api/rest/parts-list",
  GET_PART_DETAIL: "api/rest/parts-list/",
  GET_ALL_BANNERS: "api/rest/get-all-banners",
  GET_SHIP_NOTICE: "api/rest/get-shipping-notes",
  FORGOT_PASSWORD: "forgotPassword",
  REMOVE_ITEM_WISHLIST: "api/rest/removePartFromWishlist",
  GET_WISH_LIST: "api/rest/getMyWishList",
  ADD_WISH_LIST: "api/rest/addPartFromWishlist",
  UPDATE_WISH_LIST: "api/rest/updatePartFromWishlist",
  GET_ALL_DROPDOWN_VALUES: "api/rest/getAllDropdownOptions",
  AUTHORIZE_API: "https://apitest.authorize.net/xml/v1/request.api",
  GET_MY_PROFILE: "api/getMyprofile",
  ADD_EDIT_SHIPPING_ADDRESS: "api/addEditShippingAddress",
  SELECT_SHIPPING_ADDRESS: "api/selectShippingAddress",
  REMOVE_SHIPPING_ADDRESS: "api/removeShippingAddress",
  ADD_EMPLOYEE_ACCOUNT: "api/addDealerUser",
  GET_ALL_DEALER: "api/getAllDealerUser",
  DELETE_DEALER: "api/deleteDealerUser",
  UPDATE_PROFILE_INFORMATION: "api/editProfileInformation",
  GET_ALL_ORER_HISTORY: "api/getOrderStatusAndHistory",
  GET_ALL_DEALER_USER: "api/get_all_dealer_user",
  SHIPPING_ADDRESS_DEFAULT: "api/setShippingAddressAsDefault",
  GET_PARTS_FOR_SPECIFIC: "api/rest/searchPartForSpecificEngine",
  UPDATE_MY_PROFILE: "api/editProfileInformation",
  CHECK_AVAILIBILITY: "api/rest/quickbuy",
  UPDATE_BUSINESS_DETAILS: "api/updateBussinessDetails",
  GET_STATE_CITY: "api/getStateCityList",
  TAX_EXEMPT_FORM: "api/storeAdditionalTaxDetails",
  GET_MY_CART: "api/getMyCart",
  SIGN_IN_INFO: "api/editEmailPassword",
  GET_SHIPPING_POLICY: "rest/content-management",
  UPS_RATE: "api/upsShippingRate",
  DHL_RATE: "api/dhlShippingRate",
  ADD_TO_CART: "api/addToCart",
  DEALER_LOGIN: "shipping/getToken",
  REMOVE_CART: "api/removeFromCart",
  GET_PAY: "api/getPaymentMethods",
  STORE_PAY: "api/storePaymentMethods",
  CREATE_ORDER: "api/createOrder",
  APPLY_COUPON: "api/applyCouponToShoppingCart",
  UPDATE_PAY_INFO: "api/updateOrderPayment",
  GET_FOOTER_CONTENT: "rest/content-link-name-list",
  GET_SHIP_RATE: "api/getShippingRate",
  GET_ORDER: "api/getOrderById",
  CENCEL_ORDER: "api/cancelOrder",
  POST_CONTACT_FORM_DATA: "rest/contactus",
  MODIFY_ORDER: "api/modifyOrder",
  UPDATE_ORDER_SUMMARY: "api/getUpdatedOrderSummary",
  RETURN_ORDER: "api/returnOrder",
  GET_SVG: "api/rest/engine-component-data",
  GET_SVG_HOTSPOT:"api/rest/engine-component-detail",
  GET_FEES : "api/getDropShipFee",
  GET_RETURN_REASON_LISTING:"api/rest/returnReasonCode",
  GET_REFRESH:"api/token/refresh",
  RESET_MODIFY_ID:"api/resetPreviousOrderId",
  LOG_API:"api/rest/logApi",
  UPDATE_BUSSINESS:"api/rest/updateBusinessShippingAddress",
  BUSSINESS:"api/rest/getPendingBusinessDetail"
};

export default apiEndpoints;
