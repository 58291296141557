import React, { useState, useEffect } from "react";
import "./AddToCartPopUp.css";
import { useStore } from "../store/home";
import { ReactComponent as CloseButton } from "../Images/closeButton.svg";
import { ShoppingListComponent } from "./ShoppingListComponent";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import commonMessages from "../commenComp/CommonMessages";
import { getMyCart } from "../api/posts";
import Loader from "../commenComp/Loader";

export default function AddToShopPop({
  isModalOpen,
  shopList,
  setIsModalOpen,
}) {
  const { token, userInfo, myCartMini, myProfile } = useStore();
  // const [isModalOpen , setIsModalOpen] = useState(false);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const navigate = useNavigate();
  const handleViewCart = () => {
    navigate({
      pathname: "/shopping-list",
    });
  };

  const [dataArray, setDataArray] = useState(shopList);
  const [sum, setSum] = useState(0);

  useEffect(() => {
    let sum = 0;
    if (dataArray && dataArray?.length > 0) {
      dataArray?.forEach((item) => {
        sum = sum + parseInt(item?.partQty) * parseFloat(item?.partDiscountCost);
      });
    }
    setSum(sum);
  }, [dataArray]);

  const mutation1 = useMutation((post) => getMyCart(post, token), {
    mutationKey: "getMyCart",
    onSuccess: (result) => {
      // setDataArray(result.data);
    },
    onError: (error) => {},
  });

  const handleRefreshCallBack = () => {
    let data = {
      email: userInfo?.username,
    };
    // mutation1.mutate(data);
  };

  const han1 = () => {
    setIsModalOpen(false);
  };
  const han2 = (e) => {
    e.stopPropagation()
  };
  return (
    isModalOpen && (
      <div  onClick={han1} className={`addToCart_main`}>
        {mutation1.isLoading ? (
          <div>
            <Loader></Loader>
          </div>
        ) : (
          <div onClick={han2} className={`addToCart_centerComponent`}>
            <div className={`addToCart_header`}>
              <div className={`addToCart_headerTitle`}>
                <span className={`addToCart_titleLabel`}>
                  <span className={`addToCart_numberOfProduct`}>
                    {myCartMini?.data?.minicart?.itemCount}
                  </span>{" "}
                  {`${dataArray?.length} `}
                  {`${
                    dataArray?.length === 1
                      ? "PRODUCT ADDED TO SHOPPING LIST"
                      : "PRODUCTS ADDED TO SHOPPING LIST"
                  } `}
                </span>
              </div>
              <div
                onClick={handleCloseModal}
                className={`addToCart_closeBtn center curPoint`}
              >
                <CloseButton
                  className={`addToCart_closeBtnIcon `}
                ></CloseButton>
              </div>
            </div>

            <div className={`addToCart_body`}>
              {/* <ShoppingListComponent addToCart = {true}/> */}
              <div className="addToCart_shoppingList">
                <ShoppingListComponent
                  // dataArray={dataArray?.data?.items}
                  dataArray={dataArray}
                  isCart={true}
                  noUpdateCart={true}
                  pop={true}
                  handleRefreshCallBack={handleRefreshCallBack}
                  reviewOrder={true}
                  addtoCart={true}
                  yourCart={true}
                  noRowColor={true}
                ></ShoppingListComponent>
              </div>
            </div>
            <div className={`addToCart_footer`}>
              {
                // myProfile?.profileInfo?.permissions?.includes(
                //   "add_to_own_cart"
                // ) ? (
                //   <div className={`addToCart_footerText`}>
                //     <h4 className={`addToCart_estimateCartTotal`}>
                //       Estimated Cart Total :{" "}
                //     </h4>{" "}
                //     <span className={`addToCart_totalPrice`}>
                //       {`  $${sum.toFixed(2)}`}
                //     </span>
                //   </div>
                // ) : (
                <div></div>
                // )
              }
              <div className={`addToCart_footerBtns`}>
                <button className={`addToCart_continueShopBtn`}>
                  <span
                    onClick={handleCloseModal}
                    // onClick={() => {
                    //   navigate({
                    //     pathname: "/catalog",
                    //   });
                    // }}
                    className={`addToCart_continueShopLabel curPoint`}
                  >
                    Continue Shopping
                  </span>
                </button>
                <button
                  onClick={handleViewCart}
                  className={`addToCart_viewCartBtn curPoint`}
                >
                  <span className={`addToCart_viewCartLabel`}>
                    View Shopping List
                  </span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  );
}
