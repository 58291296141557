import { useState } from "react";
import { useStore } from "../store/home";
import Header from "./Header";
import Footer from "./Footer";
import Tooltip from "@mui/material/Tooltip";
import place from "../Images/placeHolder.png";
import ProductTile from "./ProductTile";
import { addCommaDecimal, deviceDetector, getCurrentDate } from "../commonFunction";
import { useNavigate, useSearchParams } from "react-router-dom";
import FindParts from "../commenComp/FindParts";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import "./PartsDetail.css";
import SideMenu from "../commenComp/SideMenu";
import { useMutation, useQuery } from "@tanstack/react-query";
import PartsCatalog from "../commenComp/PartsCatalog";
import ShopComponent from "./ShopComponent";
import classnames from "classnames";
import { Modal } from "@mui/material";
import AddToCartPopUp from "./AddToCartPopUp";
import AddToShopPop from "./AddToShopPopUp";
import Carousal from "./Carousal";
import { Link } from "react-router-dom";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
// import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Select from "react-select";
import {
  updateWishList,
  getPartDetail,
  addToCart,
  getMyCart,
} from "../api/posts";
import { ReactComponent as GreyShopList } from "../Images/greyShopList.svg";
import { ReactComponent as RedCart } from "../Images/redCart.svg";
import Loader from "../commenComp/Loader";
import { ReactComponent as Checkmark } from "../Images/checkMark.svg";
import { ReactComponent as Exclamation } from "../Images/exclamation.svg";
import { ReactComponent as Warning } from "../Images/warningIcon.svg";
import NumericInput from "react-numeric-input";
import { useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { toast } from "react-toastify";
import { Box, Grid } from "@mui/material";
import commonMessages from "../commenComp/CommonMessages";
import { customStyles } from "../utils/ReactSelectCustomStyles";
import { useRef } from "react";
import { showErrorToast } from "../utils/Toaster";

const PartsDetail = () => {
  const [searchParams] = useSearchParams();
  const [selected, setSelected] = useState(null);
  const [defaultSelected, setDefaultSelected] = useState("");
  const [show, setShow] = useState(false);
  const [load, setload] = useState(true);
  const [shopListPop, setShopListPop] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenShop, setIsModalOpenShop] = useState(false);
  const [varrientDropdown, setVarrientDropdown] = useState([]);
  const selectVarientRef = useRef();
  const {
    currentDevice,
    token,
    setKeyWord,
    isCurrentEngSet,
    userInfo,
    setKeyWordFinal,
    myProfile,
    globalcategoryIdCatalog,
    dealerId,
    setMyCartMini,
    setParID,
    trecord,
    offset,
    box,
    start,
  } = useStore();
  const data = {
    token: token,
    id: searchParams?.get("id"),
    dealerId: dealerId,
    currentEngine: isCurrentEngSet ? isCurrentEngSet.engineId : "",
  };
  const [partDetail, setPartDetail] = useState();
  const { setPof } = useNonPersistedStore();
  const mutation1 = useMutation((post) => getPartDetail(post), {
    mutationKey: "getPartDetail",
    method: "GET",
    onSuccess: (result) => {
      if(result?.data?.success){
        const obj = { data: result };
        setload(false);
        setPartDetail(obj);
      } else {
        setload(false);
        navigate({
          pathname: "/partNotFound",
        });
        setTimeout(() => {
          showErrorToast('Requested part does not exist')
        }, 200);
      }
    },
    onError: (error) => {
      // showErrorToast('Requested part does not exist')
      setload(false);
    },
  });
  const initialRender1 = useRef(true);
  useEffect(() => {
    if (initialRender1.current) {
      initialRender1.current = false;
      setParID(searchParams?.get("number"));
      const data = {
        token: token,
        number: searchParams?.get("number"),
        dealerId: dealerId,
        currentEngine: isCurrentEngSet ? isCurrentEngSet.engineId : "",
      };
      setload(true);
      window.scrollTo(0, 0);
      setpartData([]);
      mutation1.mutate(data);
    }
  }, []);
  // const partDetail = useQuery({
  //   queryKey: ["getPartDetail"],
  //   retry: 2,
  //   refetchOnWindowFocus: false,
  //   queryFn: () => getPartDetail(data),
  // });
  const [quantity, setQuantity] = useState(1);
  const handleInputChangeSelect = (event) => {
    setQuantity(event);
  };
  const navigate = useNavigate();
  const [partData, setpartData] = useState();
  const [disableBut, setIsDiable] = useState(false);
  const [mes, setmes] = useState(false);
  const [addinfoText, setaddinfoText] = useState({});
  useEffect(() => {
    setVarrientDropdown(partDetail?.data?.data?.varientDrodown);
    if (
      partDetail?.data?.data?.varientDrodown &&
      partDetail?.data?.data?.varientDrodown?.length > 1
    ) {
      if (partDetail?.data?.data?.varientDrodown[1].length === 0) {
        setIsDiable(true);
        setmes(true);
      }
    }
    partDetail?.data?.data?.data?.map((item, index) => {
      setpartData(partDetail?.data?.data?.data[0]);
      if (
        partDetail?.data?.data?.data[0] &&
        partDetail?.data?.data?.data[0]?.isDiscontinue?.status
      ) {
        navigate('/catalog')
        setTimeout(() => {
          showErrorToast('The requested part is discontinued')
        }, 500);
      }
      if (
        partDetail?.data?.data?.data[0] &&
        partDetail?.data?.data?.data[0].additionalInfoFlag
      ) {
        setIsDiable(true);
      }
    });
  }, [partDetail]);

  useEffect(() => {
    partDetail?.data?.data?.varientDrodown?.forEach((obj) => {
      if (partDetail?.data?.data?.data[0]?.partId == obj?.value) {
        setSelected(obj);
      }
    });
  }, [partDetail]);

  const email = userInfo.username;
  const mutation_cart = useMutation((post) => getMyCart(post, token), {
    onSuccess: (result) => {
      setMyCartMini(result);
    },
    onError: (error) => {},
  });
  useEffect(() => {
    const obj = {
      email: userInfo.username,
      dealerId: dealerId,
      minicart: true,
    };
    // mutation_cart.mutate(obj);
    // setPof({
    //   trecord,
    //   record,
    //   offset,
    //   box,
    //   start,
    // });
  }, []);

  const handleAddToShoppingList = () => {
    const arr1 = [];
    if (partData?.additionalInfoFlag) {
      Object.entries(addinfoText).forEach(([key, value]) => {
        const obj = {};
        obj[key] = value;
        arr1.push(obj);
      });
    }
    let objFinal = {};
    let newArr = [];
    arr1.forEach((obj, index) => {
      let newObj = { title: Object.keys(obj)[0], value: Object.values(obj)[0] };
      newArr.push(newObj);
    });

    objFinal[
      myProfile?.profileInfo?.firstname + " " + myProfile?.profileInfo?.lastname
    ] = newArr;
    const arr = {
      // partId: searchParams?.get("id"),
      partId: partData?.partId,

      quantity: quantity <=0 ? 1 :quantity,
      date: getCurrentDate(),
      notes: "",
      // other_notes: arr1,
      userId: myProfile?.profileInfo?.id,
      other_notes: objFinal,

      isPartDetail: true,
    };
    if (!disableBut) {
      mutation.mutate(arr);
    }
  };

  const handleAddToCart = () => {
    const arr1 = [];
    if (partData?.additionalInfoFlag) {
      Object.entries(addinfoText).forEach(([key, value]) => {
        const obj = {};
        obj.title = key;
        obj.value = value;
        arr1.push(obj);
      });
    }
    const arr = {
      // partId: searchParams?.get("id"),
      partId: partData?.partId,
      userId: myProfile?.profileInfo?.id,
      quantity:  quantity <=0 ? 1 :quantity,
      notes: "",
      otherNotes: arr1,
    };
    let obj = [];
    obj.push(arr);
    let data = {
      email: userInfo?.username,
      update: false,
      isPartDetail: true,
      items: obj,
    };
    if (!disableBut) {
      mutationCart.mutate(data);
    }
  };

  const handleAddInfo = (item, val) => {
    const obj = { ...addinfoText };
    obj[item] = val;
    setaddinfoText(obj);
  };

  const notify = () => toast.success("Product added to cart");
  const notifyShop = () => toast.success("Product added to Shopping list");

  const mutation = useMutation((post) => updateWishList(post, token), {
    onSuccess: (result) => {
      setShopListPop(result?.data?.data);
      setIsModalOpenShop(true);
      // notifyShop();
    },
    onError: (error) => {},
  });

  const mutationCart = useMutation((post) => addToCart(post, token), {
    onSuccess: (result) => {
      // notify();
      const obj = {
        email: userInfo.username,
        dealerId: dealerId,
        minicart: true,
      };
      setShopListPop(result?.data?.cart?.items);
      setIsModalOpen(true);
      mutation_cart.mutate(obj);
    },
    onError: (error) => {},
  });

  useEffect(() => {
    setKeyWordFinal("");
    setKeyWord("");
  }, []);

  // useEffect(() => {
  //   handleVarientChange(selected)
  // }, [selected])

  const handleVarientChange = (selected) => {
    setSelected(selected);
  };

  useEffect(() => {
    partDetail?.data?.data?.data?.forEach((obj, index) => {
      if (obj?.partId === selected?.value) {
        setpartData(partDetail?.data?.data?.data[index]);
        if (
          partDetail?.data?.data?.data[index] &&
          partDetail?.data?.data?.data[index].additionalInfoFlag
        ) {
          setIsDiable(true);
        }
      } else {
        // setpartData(partDetail?.data?.data?.data[0]);
      }
    });
  }, [selected]);

  useEffect(() => {
    let disable = false;
    if (addinfoText) {
      if (partData?.additionalInfoFlag && partData?.additionalInfo.length > 0) {
        let arr = Object.values(addinfoText);
        let count = 0;
        if (arr && arr.length > 0) {
          arr.forEach((item) => {
            if (item !== "") {
              count += 1;
            }
          });
        }
        if (count === partData?.additionalInfo.length) {
          disable = false;
        } else {
          disable = true;
        }
      }
    }
    setIsDiable(disable);
  }, [addinfoText]);
  useEffect(() => {
    // if (currentDevice !== "Mobile") {
      setShow(false);
      setTimeout(() => {
        setShow(true);
      }, 2000);
    // }
  }, []);

  return (
    <Grid container direction="column">
            <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="add-to-cart-modal"
        aria-describedby="add-to-cart-modal-description"
      >
        <div className="addToCart-modal">
          <AddToCartPopUp
            shopList={shopListPop}
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
          />
        </div>
      </Modal>
      <Modal
        open={isModalOpenShop}
        onClose={() => setIsModalOpenShop(false)}
        aria-labelledby="add-to-cart-modal"
        aria-describedby="add-to-cart-modal-description"
      >
        <div className="addToCart-modal">
          <AddToShopPop
            setIsModalOpen={setIsModalOpenShop}
            shopList={shopListPop}
            yourCart={true}
            isModalOpen={isModalOpenShop}
          />
        </div>
      </Modal>
      {load && <Loader></Loader>}
      {load && <Loader></Loader>}
      <Grid item>
        <Header page="Catalog"></Header>
        <PartsCatalog />
      </Grid>
      <Grid item>
        <Grid container>
          <div className="partsDetailMainComponent">
            <Grid item md={5}>
              <div className={`partsDetailLeftSideComponent__mainDesktop`}>
                <div className={`partsDe_container1Desktop`}>
                  <Link className={`partsDe_catalogLinkDesktop`} to="/catalog">
                    <p>Catalog</p>
                  </Link>{" "}
                  <span className="ferltjngrtujik">/</span>
                  <p
                    style={{
                      cursor: "pointer",
                      color: "#ce0e2d",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      textDecoration: "underline ",
                      fontWeight: "700",
                    }}
                    onClick={() => {
                      navigate({
                        pathname: "/catalog",
                      });
                    }}
                  >
                    {globalcategoryIdCatalog}
                  </p>{" "}
                  <Link className={`partsDe_TransmissionLinkDesktop`}>
                    <p>{partData?.primaryCategoryName}</p>
                  </Link>
                </div>
                <div className={`partsDe_container2Desktop`}>
                  <div className={`partsDe_nameDesktop`}>
                    {partData?.partName}
                  </div>
                </div>
                {isCurrentEngSet && (
                  <>
                    {partData?.isEngineCompartible ? (
                      <div className={`partsDe_compatibilityMsgBoxDesktop`}>
                        <Checkmark
                          className={`partsDe_checkMarkDesktop`}
                        ></Checkmark>
                        <p className={`partsDe_NOTcompatibilityMsg`}>
                          Compatible with current engine
                        </p>
                      </div>
                    ) : (
                      <div className="partsDe_NOTcompatibilityMsgBox">
                        <Exclamation className="partsDe_exclamationMark"></Exclamation>
                        <p className="partsDe_NOTcompatibilityMsg">
                          Not compatible with current engine
                        </p>
                      </div>
                    )}
                  </>
                )}

                {/* <div className={`partsDe_colorBoxDesktop`}>
            <p className={`partsDe_colorLabelDesktop`}>COLOR</p>
            <br></br>
           
          </div> */}
                {/* stateRef.current.setValue("StateProvince",null) */}
                {partDetail?.data?.data?.varientDrodown &&
                  partDetail?.data?.data?.varientDrodown?.length > 0 && (
                    <>
                      <Box sx={{ marginTop: "10px", fontWeight: "700" }}>
                        {partDetail?.data?.data?.variantDropdownName}

                        {partDetail?.data?.data?.varientDrodown?.length ==
                          1 && (
                          <span style={{ marginLeft: "12px" }}>
                            {partDetail?.data?.data?.varientDrodown[0]?.label}
                          </span>
                        )}
                      </Box>
                      {!disableBut &&
                      partDetail?.data?.data?.varientDrodown &&
                      partDetail?.data?.data?.varientDrodown?.length > 1 ? (
                        <Box
                          sx={{
                            marginTop: "2px",
                          }}
                          className="margInBottomLine"
                        >
                          <div className="seloptdiv">
                            <span className="selopt">Select an option</span>
                          </div>
                          <Select
                            isLoading={partDetail?.isFetching}
                            onChange={handleVarientChange}
                            value={selected}
                            styles={customStyles}
                            //  defaultValue= { defaultSelected}
                            options={varrientDropdown}
                          />
                        </Box>
                      ) : (
                        <>
                          {/* <Box>{ partDetail?.data?.data?.varientDrodown[0]?.label}</Box> */}
                        </>
                      )}
                    </>
                  )}

                <div className={`parstsDe_PARTinfoDesktop`}>
                  <h3 className={`partsDe_code1Desktop`}>PART#</h3>
                  <h3 className={`partsDe_code2Desktop`}>
                    {partData?.partNumber}
                  </h3>
                  <h3 className={`partsDe_code2_mobDesktop`}>501-0018-RED</h3>
                  <h3 className={`partsDe_code3_mobDesktop`}>
                    {/* (Replaces Part# <span className="code3">PE01318</span>){" "} */}
                  </h3>
                </div>
                <div className={`partsDe_container3Desktop`}>
                  <Grid
                    sx={{ marginTop: "10px", gap: "10px" }}
                    container
                    columnSpacing={2}
                  >
                    {partData?.additionalInfoFlag && (
                      <Grid
                        item
                        sx={{
                          flexDirection: "row",
                          display: "flex",
                          gap: "10px",
                        }}
                        md={12}
                      >
                        <div className="partDetailTitile">
                          {`${commonMessages?.partDetailSubHeadingThree}`}
                          <span className="red">*</span>{" "}
                        </div>
                      </Grid>
                    )}
                    <Grid
                      item
                      sx={{
                        flexDirection: "row",
                        display: "flex",
                        gap: "10px",
                      }}
                      md={12}
                    >
                      {partData?.additionalInfoFlag &&
                        partData?.additionalInfo.map((item) => (
                          <Grid item md={4}>
                            <div className="reDenter">
                              <div className="labelPartDetail">{item}</div>
                              <span className="red">*</span>
                            </div>
                            <input
                              className={`InputFieldShippingAddressPayment`}
                              onChange={(event) => {
                                handleAddInfo(item, event.target.value);
                              }}
                              type="text"
                              name="firstname"
                            ></input>
                          </Grid>
                        ))}
                      {/* <Grid item md={3}>
                    <div className="labelPartDetail">Hours *</div>
                    <input
                      className={`InputFieldShippingAddressPayment`}
                      type="text"
                      name="firstname"
                    ></input>
                  </Grid> */}
                    </Grid>
                  </Grid>

                  {myProfile?.profileInfo?.permissions?.includes(
                    "add_to_own_cart"
                  ) || dealerId ? (
                    <div className={`partsDe_container3msgDesktop`}>
                      {partData?.productEffectiveCost !==
                        partData?.productOrigionalCost && addCommaDecimal(partData?.productOrigionalCost) &&  (
                        <>
                          <h4 className={`partsDe_container3_msg1Desktop`}>
                            Special Price for {partData?.dealerLevel} Dealers
                          </h4>
                          <br></br>
                          <p className={`partsDe_container3_msg2Desktop sdsds`}>
                            (Regular $
                            {addCommaDecimal(partData?.productOrigionalCost)}
                            )
                          </p>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="margingwholepart"> </div>
                  )}
                  <div
                    className={`partsDe_container3_inputBoxPriceCartDesktop`}
                  >
                    {(myProfile?.profileInfo?.permissions?.includes(
                      "add_to_own_cart"
                    ) ||
                      dealerId) && (
                      <div className={`partsDe_container3_priceDesktop`}>
                        {"$" +
                          parseFloat(
                            partData?.productEffectiveCost
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </div>
                    )}
                    <Tooltip
                      placement="right"
                      title={
                        mes
                          ? "Variants do not exist for this product. Please contact Ilmor for further assistance."
                          : ""
                      }
                    >
                      {!dealerId && (
                        <Box
                          className="center"
                          sx={{
                            marginLeft: currentDevice !== "Mobile" && "-173px",
                          }}
                        >
                          <NumericInput
                            className={`partDetails_InputFieldDesktop`}
                            mobile={false}
                            strict
                            max={999}
                            // min={1}
                            value={quantity <= 0 ? "" : quantity}
                            onChange={handleInputChangeSelect}
                            onBlur={(event) => {
                              if (event.target.value == "" || event.target.value <= 0) {
                                handleInputChangeSelect(1);
                              }
                            }}
                            // value={quantity}
                          />
                          <div
                            className={classnames(
                              "iconWrapper center bgForCart",
                              {
                                disableBut: disableBut || partData?.doNotSell,
                              }
                            )}
                          >
                            {mutation.isLoading ? (
                              <CircularProgress
                                style={{ color: "white" }}
                                size={20}
                              />
                            ) : (
                              <GreyShopList
                                onClick={handleAddToShoppingList}
                                className="GreyShopList icon"
                              ></GreyShopList>
                            )}
                          </div>
                          {myProfile?.profileInfo?.permissions?.includes(
                            "add_to_own_cart"
                          ) && (
                            <div
                              className={classnames(
                                "iconWrapper center bgForCart",
                                {
                                  disableBut: disableBut || partData?.doNotSell,
                                }
                              )}
                            >
                              {mutationCart.isLoading ? (
                                <CircularProgress
                                  style={{ color: "white" }}
                                  size={20}
                                />
                              ) : (
                                <RedCart
                                  onClick={handleAddToCart}
                                  // className="redCartHover"
                                  className="GreyShopList icon"
                                ></RedCart>
                              )}
                            </div>
                          )}
                        </Box>
                      )}
                    </Tooltip>
                  </div>
                </div>

                {/* <div className={`partsDe_lineBreakDesktop`}></div> */}

                {partData &&
                  partData?.engineCompartabiltiy &&
                  partData?.engineCompartabiltiy.length > 0 && (
                    <div className={`partsDe_container4Desktop borderPart`}>
                      <h4 className={`partsDe_container4_msgDesktop`}>
                        {commonMessages?.partDetailHeading}
                      </h4>
                      <div className={`partsDe_container4_engineNamesDesktop`}>
                        <div
                          className={`partsDe_container4_engineNamesListDesktop`}
                        >
                          <ul
                            className={`partsDe_container4_engineNameDesktop`}
                          >
                            {partData &&
                              partData?.engineCompartabiltiy &&
                              partData?.engineCompartabiltiy.length > 0 &&
                              partData?.engineCompartabiltiy.map((item) => {
                                return <li className="lipat sdsds">{item}</li>;
                              })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}

                {/* <div className={`partsDe_lineBreakDesktop`}></div> */}

                {partData?.description && (
                  <div className={`partsDe_descriptionDesktop btop`}>
                    <span className={`partsDe_descriptionLabelDesktop`}>
                      Description
                    </span>
                    <span className={`partsDe_descriptionMsgDesktop fnt`}>
                      {partData?.description && partData?.description.length > 2000 ? `${partData?.description.slice(0,2000)}...` : partData?.description }
                    </span>
                  </div>
                )}

                {/* <div className={`partsDe_lineBreakDesktop`}></div> */}

                <div className={`partsDe_container5Desktop btop`}>
                  <div className={`partsDe_container5MsgBoxDesktop`}>
                    <div className={`partsDe_footerDescripDesktop`}>
                      <div className={`partsDe_container5MsgDesktop fnt`}>
                        <Warning
                          className={`partsDe_warningIconOutlinedDesktop`}
                        ></Warning>
                        <b> WARNING: </b>
                        {commonMessages?.partDetailWarning}
                        {/* {partData?.description} */}
                        <span
                          onClick={() => {
                            window.open(
                              "https://www.p65warnings.ca.gov/marine",
                              "_blank"
                            );
                          }}
                          className={`partsDe_container5LinkDesktop fnt`}
                        >
                          {" "}
                          {commonMessages?.partDetailWebsite}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            {partData?.images && partData?.images.length > 0 ? (
              <Grid className="center" item md={7}>
                <div
                  className={classnames("partsDetailCorousalComponent", {
                    dpcar: !show,
                  })}
                >
                  <Carousal images={partData?.images}></Carousal>
                </div>
                <div
                  className={classnames("partsDetailCorousalComponent center", {
                    dpcar: show,
                  })}
                >
                  <img
                    // className={`card__imageDesktop`}
                    className={classnames("jdfhgdhjf454 ", {
                      // dpcar: !show,
                    })}

                    // onLoad={handleLoad}
                    // onError={handleError}
                    // style={{ display: isLoading || hasError ? "none" : "block" }}
                    src={partData?.images[0]}
                    alt={"Ilmor Part Image"}
                  />
                  {/* <div className="bar">
                    <div className="barthumb"></div>
                  </div> */}
                </div>
              </Grid>
            ) : (
              <Grid className="center" item md={7}>
                <img
                  // className={`card__imageDesktop`}
                  // onLoad={handleLoad}
                  // onError={handleError}
                  // style={{ display: isLoading || hasError ? "none" : "block" }}
                  src={place}
                  alt={"Ilmor Part Image"}
                />
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>
      <Footer></Footer>
    </Grid>
  );
};

export default PartsDetail;
