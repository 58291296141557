import React, { useState } from "react";
import { Box, Grid, InputLabel, Select, MenuItem } from "@mui/material";
import { AddEmployeeAccountBtnStyled } from "./StyledComponents/EmployeeAccountStyled";
import { initialState, myAccountCategory } from "../commonFunction";
import { useStore } from "../store/home";
import classnames from "classnames";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { customStyles } from "../utils/ReactSelectCustomStyles";
import { useEffect } from "react";
import { ReactComponent as ArrowIlmor } from "../Images/downDrop.svg";
const TabletViewBlackContainer = ({ currentDevice }) => {
  const {
    myProfile,
    setMyProfile,
    setSideMenuAccount,
    sideMenuAccount,
    setSelectedCategory,
    setFooterContentdataInZustand,
    setLastPage,
    dealerId,
  } = useStore();
  const navigate = useNavigate();
  const [age, setAge] = useState();
  const [menu, setmenu] = useState();
  const [sideValue, setSideValue] = useState("");
  const handleMaterialSelectChange = (event) => {
    setAge(event.target.value);
  };
  const resetStore = () => {
    useStore.setState(initialState);
  };
  const handleSignOut = () => {
    setSideMenuAccount("home");
    setSelectedCategory("home");
    setLastPage(false);
    localStorage.removeItem("token");
    setFooterContentdataInZustand([]);
    localStorage.removeItem("my-persisted-store");
    resetStore();
    navigate("/sign-in", { replace: true });
  };

  const handleURL = (id) => {
    if (id === "home") {
      navigate({
        pathname: "/account",
      });
    }
    if (id === "orderDefault") {
      navigate({
        pathname: "/account/orders",
      });
    }
    if (id === "employee") {
      navigate({
        pathname: "/account/mgmt/accounts",
      });
    }
    if (id === "employee1") {
      navigate({
        pathname: "/account/mgmt/accounts",
      });
    }
    if (id === "myProfile") {
      navigate({
        pathname: "/account/profile",
      });
    }
    if (id === "employee") {
      navigate({
        pathname: "/account/mgmt/accounts",
      });
    }
    if (id === "businessDetails") {
      navigate({
        pathname: "/account/mgmt/business",
      });
    }
    if (id === "shipping") {
      navigate({
        pathname: "/account/mgmt/shipping",
      });
    }
    if (id === "paymentMethods") {
      navigate({
        pathname: "/account/mgmt/payment",
      });
    }
    if (id === "tax") {
      navigate({
        pathname: "/account/mgmt/tax",
      });
    }
  };

  const plcaHold = () => {
    return (
      <Box
        component="span"
        sx={{
          display: "inline",
          fontSize: 14,
          color: "rgba(6, 7, 10, 0.50)",
          fontWeight: 500,
          fontStyle: "italic",
        }}
      >
        jump to page
      </Box>
    );
  };

  useEffect(() => {
    let arr = [];
    if (
      !dealerId &&
      myProfile?.profileInfo?.role !== "ServiceTechnician" &&
      myProfile?.profileInfo?.USDealer &&
      myProfile?.profileInfo?.permissions?.includes("manage_tax_exempt_form")
    ) {
      arr = myAccountCategory;
    } else {
      arr = myAccountCategory.filter((item) => item.id !== "tax");
    }
    if (myProfile?.profileInfo?.role === "ServiceTechnician") {
      arr = arr.filter((item) => item.id !== "paymentMethods");
      arr = arr.filter((item) => item.id !== "businessDetails");
    }
    setmenu(arr);
  }, [myAccountCategory, dealerId, myProfile]);

  useEffect(() => {
    if (
      sideMenuAccount === "orderDetail" ||
      sideMenuAccount === "orderModify" ||
      sideMenuAccount === "orderCencel" ||
      sideMenuAccount === "returnOrder"
    ) {
      setSideValue("orderDefault");
      // navigate({
      //   pathname: "/account/order-detail",
      // });
    } else if (
      sideMenuAccount === "paymentMethods" ||
      sideMenuAccount === "addPaymentMethods" ||
      sideMenuAccount === "editPaymentMethods"
    ) {
      setSideValue("paymentMethods");
    } else if (
      sideMenuAccount === "myProfile" ||
      sideMenuAccount === "signInInfo"
    ) {
      setSideValue("myProfile");
    } else {
      setSideValue(sideMenuAccount);
    }
  }, [sideMenuAccount]);

  let sub = [, "businessDetails", "shipping", "paymentMethods", "tax"];

  const [searchParams] = useSearchParams();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/account/orders") {
      setSideValue("orderDefault");
    }
    if (
      location.pathname === "/account/mgmt/accounts" ||
      location.pathname === "/account/mgmt/accounts/add" ||
      location.pathname === "/account/mgmt/accounts/edit"
    ) {
      setSideValue("employee");
    }
    if (location.pathname === "/account/profile") {
      setSideValue("myProfile");
    }
    if (location.pathname === "/account/mgmt/accounts") {
      setSideValue("employee");
    }
    if (location.pathname === "/account/mgmt/business") {
      setSideValue("businessDetails");
    }
    if (
      location.pathname === "/account/mgmt/shipping" ||
      location.pathname === "/account/mgmt/shipping/add" ||
      location.pathname === "/account/mgmt/shipping/edit"
    ) {
      setSideValue("shipping");
    }
    if (location.pathname === "/account/mgmt/payment") {
      setSideValue("paymentMethods");
    }
    if (location.pathname === "/account/mgmt/tax") {
      setSideValue("tax");
    }
    if (location.pathname === "/account") {
      setSideValue("home");
    }
  }, [searchParams, location]);

  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={3}
      className={classnames("gridBlackContainer", {
        sdfsdf782: location.pathname === "/account/mgmt/tax",
      })}
      sx={{
        width: "100vw !important",
        // maxWidth:"100%",
        position: "relative",
        left: "50%",
        right: "50%",
        marginLeft: "-50vw",
        marginRight: " -50vw",
        marginTop: currentDevice == "Mobile" ? "65px" : "2px",
        marginBottom: "2px",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px",
        backgroundColor: "#07080C",
      }}
    >
      <Grid
        item
        spacing={0}
        xs={12}
        sm={6}
        sx={{ paddingLeft: "8px !important", paddingTop: "0px !important" }}
      >
        <Box sx={{}}>
          <InputLabel
            className="inenlab"
            id="demo-simple-select-label"
            sx={{
              color: "white",
              background: "none",
              marginBottom: "4px",
            }}
          >
            Account
          </InputLabel>
          <Select
            className="seldivrad"
            styles={customStyles}
            displayEmpty={true}
            renderValue={plcaHold}
            IconComponent={ArrowIlmor}
            sx={{
              // width:"100%",
              width: currentDevice == "Tablet" ? "80%" : "100%",
              height: 60,
              // marginRight: currentDevice == "Tablet" ? 15 : 2,
              // border: "1px solid darkgrey",
              // color: "black",
              bgcolor: "white",
              "& .MuiSvgIcon-root": {
                color: "red",
              },
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={sideValue}
            label="Age"
            // onChange={handleMaterialSelectChange}
            MenuProps={{
              PaperProps: {
                sx: {
                  // bgcolor: "#615E5C",
                  "&& .Mui-selected": {
                    backgroundColor: "#ce0e2d !important",
                    color: "white",
                  },
                  "& .MuiMenuItem-root": {
                    padding: "5px 15px",
                    color: "black",
                    fontFamily: "Montserrat",
                    justifyContent: "left",
                    fontWeight: 500,
                    fontSize: "14px",
                  },
                },
              },
            }}
          >
            {menu?.map(({ id, name }, index) => (
              <MenuItem
                key={index}
                value={id}
                // className="dpBlock"
                className={`dpBlock ${
                  sub.includes(id) || name === "Employee Accounts"
                    ? "smallMenuItem"
                    : ""
                }`}
                onClick={() => {
                  setSideMenuAccount(id);
                  handleURL(id);
                  window.scrollTo(0, 0);
                }}
              >
                <div className="">{name}</div>
              </MenuItem>
            ))}

            {/* <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem> */}
          </Select>
        </Box>
      </Grid>
      <Grid
        className="sknfvjdjksfb"
        item
        sm={4}
        xs={12}
        sx={{
          paddingLeft: "8px !important",
          "@media (min-width:600px)": {
            display: "flex",
            justifyContent: "flex-end",
          },
          // ml: currentDevice == "Tablet" ? "0px" : "0px",
        }}
      >
        <AddEmployeeAccountBtnStyled onClick={handleSignOut}>
          {" "}
          Sign Out
        </AddEmployeeAccountBtnStyled>
      </Grid>
    </Grid>
  );
};

export default TabletViewBlackContainer;
