import React, { useState } from "react";
import "./Carousal.css";
import partDetail1 from "../Images/partDetail.png";
import partDetail2 from "../Images/partDetail2.png";
import partDetail3 from "../Images/partDetail3.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useEffect } from "react";
import Loader from "../commenComp/Loader";
import CircularProgress from "@mui/material/CircularProgress";
import { useStore } from "../store/home";
import classNames from "classnames";

const Carousal = ({ images }) => {
  const [load, setload] = useState(true);
  const {
    currentDevice,
  } = useStore();

  useEffect(() => {
    setTimeout(() => {
      let element = document.getElementsByClassName("thumb selected");
      if (element.length > 0 && element[0]) {
        element[0].click();
      }
      setload(false);
    }, 1500);
  }, []);
  return (
    <>
      {!load ? (
        <Carousel 
        emulateTouch={true}
        swipeable={true}
        showIndicators={currentDevice === 'Mobile'}
        showThumbs={currentDevice !== 'Mobile'}
        verticalSwipe="natural"
        // showThumbs={false}
        className={classNames(" ", {
          someAboc: images && images.length >3,
        })}
        infiniteLoop={true}>
          {images &&
            images?.map((item) => {
              return (
                <div 
                className="carous"
                >
                  <img width="350px" src={item} />
                </div>
              );
            })}
        </Carousel>
      ) : (
        <>
          {/* <Loader></Loader>
          <Loader></Loader> */}
        </>
      )}
    </>
  );
};

export default Carousal;
