import { useEffect } from "react";
import { useStore } from "../store/home";
import Header from "./Header";
import Footer from "./Footer";
import ProductTile from "./ProductTile";
import { deviceDetector } from "../commonFunction";
import FindParts from "../commenComp/FindParts";
import "./QuickOrderForm.css";
import SideMenu from "../commenComp/SideMenu";
import PartsCatalog from "../commenComp/PartsCatalog";
import SubHeading from "../commenComp/SubHeading";
import ShopComponent from "./ShopComponent";
import Carousal from "./Carousal";
import { QuickOrderComponent } from "./QuickOrderComponent";
const QuickOrderForm = () => {
  const { setKeyWord, setKeyWordFinal } = useStore();
  useEffect(() => {
    setKeyWordFinal("");
    setKeyWord("");
  }, []);
  return (
    <>
      <div>
        <Header page="Catalog"></Header>
        <SubHeading heading={"Quick order Form"} />
      </div>
      <div className="quickOrderFormComponent">
        <QuickOrderComponent></QuickOrderComponent>
      </div>
      <Footer />
      {/* <Footer></Footer> */}
    </>
  );
};

export default QuickOrderForm;
