import React, { useEffect, useRef, useState } from "react";
import { useStore } from "../store/home";
import { Box, Grid, Typography, styled, Input } from "@mui/material";
import "./OrderStatusHistory.css";
import InputBase from "@mui/material/InputBase";
import search from "../Images/searchIcon.svg";
import { ReactComponent as ArrowIlmor } from "../Images/arrowIlmor.svg";
import classnames from "classnames";
import { useMutation, useQuery } from "@tanstack/react-query";
import TabletViewBlackContainer from "./TabletViewBlackContainer";
import { StyledOrderHistoryHeading } from "../components/StyledComponents/OrderHistoryStyled";
import { getAllOrderHistory } from "../api/posts";
import { useNavigate } from "react-router-dom";
import commonMessages from "../commenComp/CommonMessages";
import Loader from "../commenComp/Loader";
import { OrderStatusStyled } from "./StyledComponents/EmployeeAccountStyled";
import { addCommaDecimal, formatDate, handleSixty } from "../commonFunction";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import { useLocation } from "react-router-dom";

const StyledShippingHeading = styled(Typography)({
  /* Display lg/Bold */
  fontFamily: "Montserrat",
  fontSize: "40px",
  // fontSize: 40px,
  fontStyle: "normal",
  fontWeight: "700",

  textTransform: "uppercase",
});

const OrderStatusHistory = () => {
  const {
    currentDevice,
    setSideMenuAccount,
    token,
    currentUserEmail,
    myProfile,
    setOrderID,
    dealerId,
    sideMenuAccount,
  } = useStore();
  const navigate = useNavigate();
  const [orderStatusArray, setOrderStatusArray] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [text, setText] = useState("");
  const inputRef = useRef();
  const { setstart1, start1, setBox, box, setBoxPage, boxPage } =
    useNonPersistedStore();

  const mutation = useMutation((post) => getAllOrderHistory(post, token), {
    onSuccess: (result) => {
      if (result?.data?.data) {
        setOrderStatusArray(result?.data?.data.slice().reverse());
      }
    },
    onError: (error) => {},
  });

  const location = useLocation();
  useEffect(() => {
    mutation.mutate({
      email: currentUserEmail,
      searchParam: "",
      dealerId: dealerId,
    });
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    setstart1(0);
    setBox(1);
    setBoxPage(0);
    inputRef.current.focus();
    setText(keyWord);
  };
  const [keyWord, setKeyWord] = useState("");

  // const [start1, setstart1] = useState(0);
  // const [box, setBox] = useState(1);
  // const [boxPage, setBoxPage] = useState(0);

  const handlePage = (page) => {
    // setBoxPage(Math.floor(page));
    setBoxPage(page);
    if (page !== "next") {
      setstart1(page * 20);
    } else {
      if (start1 < orderStatusArray?.length - 20) {
        setstart1(start1 + 20);
      }
      // setBoxPage( start1 / 20)
    }
  };

  useEffect(() => {
    if (orderStatusArray && orderStatusArray.length > 0) {
      const filteredArray = orderStatusArray.filter((obj) =>
        Object.values(obj).some((value) =>
          value?.toString()?.toLowerCase()?.includes(text.toLowerCase())
        )
      );
      setTableData(filteredArray);
    }
  }, [text, orderStatusArray]);

  useEffect(() => {
    if (tableData && tableData?.length > 0) {
      let num = parseInt(tableData?.length / 20);
      let numPer = parseInt(tableData?.length % 20);
      if (!numPer) {
        setBox(num);
      } else {
        setBox(num + 1);
      }
    }
  }, [tableData]);

  const [isSelecting, setIsSelecting] = useState(false);
  const divRef = useRef(null);

  const handleMouseDown = () => {
    setIsSelecting(false);
  };

  const handleMouseMove = () => {
    setIsSelecting(true);
  };

  const handleMouseUp = (e,row) => {
    if (!isSelecting) {
      handleClick(e,row);
    }
  };

  const handleClick = (e,row) => {
    if (
      row.isOrderHistorical !== 1
    ) {
      // const queryParams = new URLSearchParams(window.location.search);
      // queryParams.set("orderId", row.id);
      // const newSearchParams = queryParams.toString();
      setOrderID(row.id);
      setSideMenuAccount("orderDetail");
      // navigate({
      //   pathname: "/account/order-detail",
      // });
      navigate({
        pathname: "/account/order-detail",
        search: `&orderId=${row.id}`,
      });
    }
  };

  return (
    <div className="orderHistoryMainDiv">
      {mutation.isLoading && <Loader></Loader>}
      <div className={`ShippingAddressbackGround newordertab`}>
        <div className={`ShippingouterContainer`}>
          <Grid container spacing={1}>
            <Grid item sm={12} md={12}>
              <div className="orderpad">
                <StyledShippingHeading className="headingCssOrder sdfdsr44e">
                  Order status & History
                </StyledShippingHeading>
              </div>
              {currentDevice == "Tablet" && <TabletViewBlackContainer />}
            </Grid>
            <Grid className="orderhispagpad" item sm={12} md={12}>
              <StyledOrderHistoryHeading>
                {commonMessages?.orderHistoryHeadingOne}
              </StyledOrderHistoryHeading>{" "}
              <br />
              <StyledOrderHistoryHeading>
                {commonMessages?.orderHistoryHeadingTwo}
              </StyledOrderHistoryHeading>{" "}
              <br />
              <StyledOrderHistoryHeading>
                {commonMessages?.orderHistoryHeadingThree}
              </StyledOrderHistoryHeading>{" "}
              <br />
              <StyledOrderHistoryHeading>
                {commonMessages?.orderHistoryHeadingFour}
              </StyledOrderHistoryHeading>{" "}
              <br />
              <StyledOrderHistoryHeading>
                {commonMessages?.orderHistoryHeadingFive}
              </StyledOrderHistoryHeading>{" "}
            </Grid>
          </Grid>
        </div>
      </div>
      <Grid
        sx={{
          background: "#F7F7F7",
          padding: `${currentDevice === "Desktop" ? "10px 48px" : "10px 10px"}`,
          borderBottom: "2px solid black",
        }}
        className="tabpadorder"
        container
        spacing={0}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <div className="orderSearch">
            <span className="searchFiledTExt">Search</span>
            <div onKeyDown={handleKeyDown} className="searchFieldOrder">
              {/* <InputBase */}
              <input
                style={{
                  backgroundColor: "white",
                  border: "none",
                  outline: "none",
                  padding: "11px",
                  fontStyle: "italic",
                }}
                ref={inputRef}
                placeholder="Enter search terms"
                // placeholder="Enter keyword or part number"
                onChange={(e) => {
                  setKeyWord(e.target.value);
                }}
                value={keyWord}
                className="header__input__base5 widthforSearch orderhistor"
                inputProps={{ "aria-label": "search google maps" }}
              />
              <img
                className="searchRed"
                style={{ marginRight: "10px", cursor: "pointer" }}
                onClick={handleSearch}
                src={search}
              ></img>
            </div>
          </div>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            //  marginRight: '4px'
            //  border:'1px solid red',
            // background: "#F7F7F7",
          }}
          item
          className="mobileWidth"
          xs={12}
          sm={6}
          md={6}
          lg={6}
        >
          {/* <div className="paginationRightSide"> */}
          {tableData && tableData.length > 0 && tableData.length < 100 ? (
            <>
              <div className="center">
                <span className="paginationNumber">{`${start1 + 1}-${
                  start1 + 20 > tableData?.length
                    ? tableData?.length
                    : start1 + 20
                } of ${tableData?.length}`}</span>
              </div>
              <div className="paginationNumberBoxWhole">
                {Array(box)
                  .fill()
                  .map((item, i) => (
                    <span
                      onClick={() => {
                        handlePage(i);
                      }}
                      className={classnames("paginationNumberBox", {
                        activeBox: start1 / 20 === i,
                      })}
                    >
                      {i + 1}
                    </span>
                  ))}
                {/* <span className="paginationNumberBox activeBox">1</span>
              <span className="paginationNumberBox">2</span>
              <span className="paginationNumberBox">3</span>
              <span className="paginationNumberBox">4</span> */}
                {tableData.length > 20 && (
                  <div
                    onClick={() => {
                      handlePage("next");
                    }}
                    className="iconWrapper greay paginationNumberBox"
                  >
                    <ArrowIlmor className="icon"></ArrowIlmor>
                  </div>
                )}
              </div>
              {/* </div> */}
            </>
          ) : (
            <>
              {tableData && tableData.length > 0 && (
                <div
                  className={classnames("paginationRightSide", {
                    justidyForOrder: location.pathname === "/account",
                  })}
                >
                  <span className="paginationNumber">{`${start1 + 1}-${
                    start1 + 20 > tableData?.length
                      ? tableData?.length
                      : start1 + 20
                  } of ${tableData?.length}`}</span>
                  {tableData?.length && (
                    <div className="paginationNumberBoxWhole">
                      <div className="paginationNumberBoxWhole">
                        {Array(box)
                          .fill()
                          .map((_, i) => {
                            if (
                              (i >= boxPage - 2 && i < boxPage + 2) ||
                              i === box - 1 ||
                              i === 0 ||
                              i === start1 / 20 ||
                              i === start1 / 20 - 1 ||
                              i === start1 / 20 + 1 ||
                              (i === boxPage - 3 && boxPage > 3) ||
                              (i === boxPage + 3 && boxPage < box - 4)
                            ) {
                              if (i === boxPage - 3 && boxPage > 3) {
                                return <span key={i}>...</span>;
                              }
                              if (i === boxPage + 3 && boxPage < box - 4) {
                                return <span key={i}>...</span>;
                              }
                              return (
                                <span
                                  key={i}
                                  onClick={() => handlePage(i)}
                                  className={classnames("paginationNumberBox", {
                                    activeBox: start1 / 20 === i,
                                    // activeBoxBig: i === boxPage * 10,
                                  })}
                                >
                                  {i + 1}
                                </span>
                              );
                            }
                            return null;
                          })}
                        <div
                          onClick={() => {
                            handlePage("next");
                          }}
                          className="iconWrapper greay paginationNumberBox"
                        >
                          <ArrowIlmor className="icon"></ArrowIlmor>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {currentDevice === "Desktop" && (
            <>
              <table className={`orderHisTable`}>
                <thead className={`orderHisTableHead`}>
                  <tr className={`orderHisTableRow`}>
                    <th
                      style={{ paddingLeft: "60px", paddingRight: "10px" }}
                      className={`orderHisTableRowHeading`}
                    >
                      Order Number
                    </th>
                    <th className={`orderHisTableRowHeading`}>Order Date</th>
                    <th className={`orderHisTableRowHeading`}>Order Total</th>
                    <th className={`orderHisTableRowHeading`}>Placed By</th>
                    <th className={`orderHisTableRowHeading`}>Status</th>
                    <th className={`orderHisTableRowHeading`}></th>
                  </tr>
                </thead>
                <tbody className={`orderHisTableData`}>
                  {tableData &&
                    tableData?.length > 0 &&
                    tableData?.map((row, index) => {
                      if (index >= start1 && index < start1 + 21) {
                        return (
                          <tr
                            ref={divRef}
                            onMouseDown={handleMouseDown}
                            onMouseMove={handleMouseMove}
                            onMouseUp={(e)=>{handleMouseUp(e,row)}}
                            // onClick={(e) => {
                            //   if (
                            //     row.isOrderHistorical !== 1 &&
                            //     e.target.id !== "nooperation"
                            //   ) {
                            //     // const queryParams = new URLSearchParams(window.location.search);
                            //     // queryParams.set("orderId", row.id);
                            //     // const newSearchParams = queryParams.toString();
                            //     setOrderID(row.id);
                            //     setSideMenuAccount("orderDetail");
                            //     // navigate({
                            //     //   pathname: "/account/order-detail",
                            //     // });
                            //     navigate({
                            //       pathname: "/account/order-detail",
                            //       search: `&orderId=${row.id}`,
                            //     });
                            //   }
                            // }}
                            className={`quickOrderTableRowDesktop${
                              index % 2 === 0 ? "EvenRow" : ""
                            }`}
                            key={index}
                          >
                            <td
                              style={{
                                paddingLeft: "60px",
                                paddingRight: "10px",
                              }}
                              // id="nooperation"
                              className={classnames("orderHisTableDataDiv", {
                                noClick: row.isOrderHistorical === 1,
                              })}
                            >
                              <span
                                id="nooperation"
                                className={`orderHisTableData`}
                              >
                                {row.orderId}
                              </span>
                            </td>
                            <td
                              className={classnames("orderHisTableDataDiv", {
                                noClick: row.isOrderHistorical === 1,
                              })}
                            >
                              <span className={`orderHisTableData`}>
                                {row.orderDate}
                              </span>
                            </td>
                            <td
                              className={classnames("orderHisTableDataDiv", {
                                noClick: row.isOrderHistorical === 1,
                              })}
                            >
                              <span className={`orderHisTableData`}>
                                $
                                {parseFloat(row.orderTotal)?.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </span>
                            </td>
                            <td
                              className={classnames("orderHisTableDataDiv", {
                                noClick: row.isOrderHistorical === 1,
                              })}
                            >
                              <span className={`orderHisTableData`}>
                                {row?.placedBy ?? row?.customerName}
                              </span>
                            </td>

                            <td
                              className={classnames("orderHisTableDataDiv", {
                                noClick: row.isOrderHistorical === 1,
                              })}
                            >
                              <OrderStatusStyled
                                className={classnames("", {
                                  justifyGridDesktopHover:
                                    row?.Status === "partiallyShipped" ||
                                    row?.Status == "denied" ||
                                    row?.Status == "return_completed",
                                })}
                                status={row?.Status}
                              >
                                {row?.Status === "partiallyShipped"
                                  ? "Partially Shipped"
                                  : row?.Status == "paymentPending"
                                  ? "payment Pending"
                                  : row?.Status == "intiatedReturn"
                                  ? "Initiated Return"
                                  : row?.Status == "return_completed"
                                  ? "Return Completed"
                                  : row?.Status}
                              </OrderStatusStyled>
                              {/* <span
                                className={`orderHisTableData ilmorRoundRedButton`}
                                // ilmorRoundGreenButton
                              >
                                {row.Status}
                              </span> */}
                            </td>
                            {myProfile?.profileInfo?.permissions?.includes(
                              "initiate_returns"
                            ) &&
                              myProfile?.profileInfo?.role !==
                                "ServiceTechnician" && (
                                <td className={`orderHisTableDataDiv`}>
                                  {row.Status === "pending" && (
                                    <>
                                      <span
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          setOrderID(row.id);
                                          setSideMenuAccount("orderModify");
                                          navigate({
                                            pathname: "/account/order-detail",
                                            search: `&orderId=${row.id}`,
                                          });
                                        }}
                                        className={`orderHisTableButton`}
                                      >{`Modify`}</span>
                                      <span
                                        className={`orderHisTableButtonBar`}
                                      >{` | `}</span>
                                    </>
                                  )}
                                  {row.Status === "pending" && (
                                    <>
                                      <span
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          setOrderID(row.id);
                                          setSideMenuAccount("orderCencel");
                                          navigate({
                                            pathname: "/account/order-detail",
                                            search: `&orderId=${row.id}`,
                                          });
                                        }}
                                        className={`orderHisTableButton`}
                                      >{`Cancel`}</span>
                                      {/* <span
                                      className={`orderHisTableButtonBar`}
                                    >{` | `}</span> */}
                                    </>
                                  )}
                                  {row?.Status === "partiallyShipped" && (
                                    <span
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        setOrderID(row.id);
                                        setSideMenuAccount("orderDetail");
                                        navigate({
                                          pathname: "/account/order-detail",
                                          search: `&orderId=${row.id}`,
                                        });
                                      }}
                                      className={`orderHisTableButton`}
                                    >
                                      {"View Details"}
                                    </span>
                                  )}
                                  {row?.Status === "Completed" &&
                                    row?.isOrderHistorical !== 1 &&
                                    handleSixty(row?.orderCompletionDate) && (
                                      <span
                                        onClick={(event) => {
                                          if (row.isOrderHistorical !== 1) {
                                            event.stopPropagation();
                                            setOrderID(row.id);
                                            window.scrollTo(0, 0);
                                            setSideMenuAccount("returnOrder");
                                            navigate({
                                              pathname: "/account/order-detail",
                                              search: `&orderId=${row.id}`,
                                            });
                                          }
                                        }}
                                        className={`orderHisTableButton`}
                                      >
                                        {"Start a Return"}
                                      </span>
                                    )}
                                  {row?.Status === "shipped" && (
                                    <span
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        setOrderID(row.id);
                                        setSideMenuAccount("orderDetail");
                                        navigate({
                                          pathname: "/account/order-detail",
                                          search: `&orderId=${row.id}`,
                                        });
                                      }}
                                      className={`orderHisTableButton`}
                                    >
                                      {"Track Order"}
                                    </span>
                                  )}
                                </td>
                              )}
                          </tr>
                        );
                      }
                    })}
                </tbody>
              </table>
              <div className="center marbot">
                {tableData && tableData.length === 0 && (
                  <div className="noOrdemessage">
                    No Matching orders to show
                  </div>
                )}
              </div>
            </>
          )}
          {currentDevice === "Tablet" && (
            <>
              {tableData.map((row, index) => {
                if (index >= start1 && index < start1 + 21) {
                  return (
                    <div
                      onClick={() => {
                        if (row.isOrderHistorical !== 1) {
                          setOrderID(row.id);
                          setSideMenuAccount("orderDetail");
                          navigate({
                            pathname: "/account/order-detail",
                            search: `&orderId=${row.id}`,
                          });
                        }
                      }}
                      className={classnames("TabletOrderDetail", {
                        evenRow: index % 2 === 0,
                      })}
                    >
                      <div className="ordeTabDetailTab">
                        <div className="tabOrdeDetaLeft  orderDetailOrderNumberTabletBottom">
                          <div className="leftSideOrder">
                            <div className="ordeDetailTitle">Order Number</div>
                            <div className="tabOrderNumber">{row.orderId}</div>
                            <div>
                              <OrderStatusStyled status={row?.Status}>
                                {row?.Status === "partiallyShipped"
                                  ? "Partially Shipped"
                                  : row?.Status == "paymentPending"
                                  ? "payment Pending"
                                  : row?.Status == "intiatedReturn"
                                  ? "Initiated Return"
                                  : row?.Status == "return_completed"
                                  ? "Return Completed"
                                  : row?.Status}
                              </OrderStatusStyled>
                              {/* {row.Status} */}
                            </div>
                          </div>
                          <div className="tabOrdeDetaRight">
                            {myProfile?.profileInfo?.permissions?.includes(
                              "initiate_returns"
                            ) &&
                              myProfile?.profileInfo?.role !==
                                "ServiceTechnician" && (
                                <div className={`orderHisTableDataDiv`}>
                                  {row.Status === "pending" && (
                                    <>
                                      <span
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          setOrderID(row.id);
                                          setSideMenuAccount("orderModify");
                                          navigate({
                                            pathname: "/account/order-detail",
                                            search: `&orderId=${row.id}`,
                                          });
                                        }}
                                        className={`orderHisTableButton`}
                                      >{`Modify`}</span>
                                      <span
                                        className={`orderHisTableButtonBar`}
                                      >{` | `}</span>
                                    </>
                                  )}
                                  {row.Status === "pending" && (
                                    <>
                                      <span
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          setOrderID(row.id);
                                          setSideMenuAccount("orderCencel");
                                          navigate({
                                            pathname: "/account/order-detail",
                                            search: `&orderId=${row.id}`,
                                          });
                                        }}
                                        className={`orderHisTableButton`}
                                      >{`Cancel`}</span>
                                      {/* <span
                                      className={`orderHisTableButtonBar`}
                                    >{` | `}</span> */}
                                    </>
                                  )}
                                  {/* <span className={`orderHisTableButton`}>{'Cancel'}</span> */}
                                  {/* {(row?.Status === "completed" ||
                                  row?.Status === "partially shipped") && (
                                  <span
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      setOrderID(row.id);
                                      setSideMenuAccount("returnOrder");
                                    }}
                                    className={`orderHisTableButton`}
                                  >
                                    {"Start a Return"}
                                  </span>
                                )} */}
                                  {row?.Status === "partiallyShipped" && (
                                    <span
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        setOrderID(row.id);
                                        setSideMenuAccount("orderDetail");
                                        navigate({
                                          pathname: "/account/order-detail",
                                          search: `&orderId=${row.id}`,
                                        });
                                      }}
                                      className={`orderHisTableButton`}
                                    >
                                      {"View Details"}
                                    </span>
                                  )}
                                  {row?.Status === "Completed" &&
                                    row?.isOrderHistorical !== 1 &&
                                    handleSixty(row?.orderCompletionDate) && (
                                      <span
                                        onClick={(event) => {
                                          if (row.isOrderHistorical !== 1) {
                                            event.stopPropagation();
                                            setOrderID(row.id);
                                            window.scrollTo(0, 0);
                                            setSideMenuAccount("returnOrder");
                                            navigate({
                                              pathname: "/account/order-detail",
                                              search: `&orderId=${row.id}`,
                                            });
                                          }
                                        }}
                                        className={`orderHisTableButton`}
                                      >
                                        {"Start a Return"}
                                      </span>
                                    )}
                                  {row?.Status === "shipped" && (
                                    <span
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        setOrderID(row.id);
                                        setSideMenuAccount("orderDetail");
                                        navigate({
                                          pathname: "/account/order-detail",
                                          search: `&orderId=${row.id}`,
                                        });
                                      }}
                                      className={`orderHisTableButton`}
                                    >
                                      {"Track Order"}
                                    </span>
                                  )}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="orderDate">
                          <div className="dateTab">
                            <div className="ordeDetailTitle">Order Date</div>
                            <div className="ordeDetailValue">
                              {row.orderDate}
                            </div>
                          </div>
                          <div className="dateTab">
                            <div className="ordeDetailTitle">Order Total</div>
                            <div className="ordeDetailValue">
                              ${addCommaDecimal(row.orderTotal)}
                            </div>
                          </div>
                          <div className="dateTab">
                            <div className="ordeDetailTitle">Place By</div>
                            <div className="ordeDetailValue">
                              {row?.placedBy ?? row?.customerName}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
              <div className="center">
                {tableData && tableData.length === 0 && (
                  <div className="noOrdemessage">
                    No Mathicng orders to show
                  </div>
                )}
              </div>
            </>
          )}
          {currentDevice === "Mobile" && (
            <>
              {tableData.map((row, index) => {
                if (index >= start1 && index < start1 + 21) {
                  return (
                    <div
                      onClick={() => {
                        if (row.isOrderHistorical !== 1) {
                          setOrderID(row.id);
                          setSideMenuAccount("orderDetail");
                          navigate({
                            pathname: "/account/order-detail",
                            search: `&orderId=${row.id}`,
                          });
                        }
                      }}
                      className={classnames("TabletOrderDetail", {
                        evenRow: index % 2 === 0,
                      })}
                    >
                      <div className="ordeTabDetailTab">
                        <div className="tabOrdeDetaLeft">
                          <div className="leftSideOrder">
                            <div className="ordeDetailTitle">Order Number</div>
                            <div className="tabOrderNumber">{row.orderId}</div>
                          </div>
                        </div>
                        <div className="tabOrdeDetaLeft">
                          <div className="tabOrdeDetaRightMob">
                            <div>
                              <OrderStatusStyled status={row?.Status}>
                                {row?.Status === "partiallyShipped"
                                  ? "Partially Shipped"
                                  : row?.Status == "paymentPending"
                                  ? "payment Pending"
                                  : row?.Status == "intiatedReturn"
                                  ? "Initiated Return"
                                  : row?.Status == "return_completed"
                                  ? "Return Completed"
                                  : row?.Status}
                              </OrderStatusStyled>
                              {/* {row.Status} */}
                            </div>
                            {myProfile?.profileInfo?.permissions?.includes(
                              "initiate_returns"
                            ) &&
                              myProfile?.profileInfo?.role !==
                                "ServiceTechnician" && (
                                <div className={``}>
                                  {row.Status === "pending" && (
                                    <>
                                      <span
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          setOrderID(row.id);
                                          setSideMenuAccount("orderModify");
                                          navigate({
                                            pathname: "/account/order-detail",
                                            search: `&orderId=${row.id}`,
                                          });
                                        }}
                                        className={`orderHisTableButton`}
                                      >{`Modify`}</span>
                                      <span
                                        className={`orderHisTableButtonBar`}
                                      >{` | `}</span>
                                    </>
                                  )}
                                  {row.Status === "pending" && (
                                    <>
                                      <span
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          setOrderID(row.id);
                                          setSideMenuAccount("orderCencel");
                                          navigate({
                                            pathname: "/account/order-detail",
                                            search: `&orderId=${row.id}`,
                                          });
                                        }}
                                        className={`orderHisTableButton`}
                                      >{`Cancel`}</span>
                                      {/* <span
                                      className={`orderHisTableButtonBar`}
                                    >{` | `}</span> */}
                                    </>
                                  )}
                                  {/* <span className={`orderHisTableButton`}>{'Cancel'}</span> */}
                                  {/* {(row?.Status === "completed" ||
                                    row?.Status === "partially shipped") && (
                                    <span
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        setOrderID(row.id);
                                        setSideMenuAccount("returnOrder");
                                      }}
                                      className={`orderHisTableButton`}
                                    >
                                      {"Start a Return"}
                                    </span>
                                  )} */}
                                  {row?.Status === "partiallyShipped" && (
                                    <span
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        setOrderID(row.id);
                                        setSideMenuAccount("orderDetail");
                                        navigate({
                                          pathname: "/account/order-detail",
                                          search: `&orderId=${row.id}`,
                                        });
                                      }}
                                      className={`orderHisTableButton`}
                                    >
                                      {"View Details"}
                                    </span>
                                  )}
                                  {row?.Status === "Completed" &&
                                    row?.isOrderHistorical !== 1 &&
                                    handleSixty(row?.orderCompletionDate) && (
                                      <span
                                        onClick={(event) => {
                                          if (row.isOrderHistorical !== 1) {
                                            event.stopPropagation();
                                            setOrderID(row.id);
                                            window.scrollTo(0, 0);
                                            setSideMenuAccount("returnOrder");
                                            navigate({
                                              pathname: "/account/order-detail",
                                              search: `&orderId=${row.id}`,
                                            });
                                          }
                                        }}
                                        className={`orderHisTableButton`}
                                      >
                                        {"Start a Return"}
                                      </span>
                                    )}
                                  {row?.Status === "shipped" && (
                                    <span
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        setOrderID(row.id);
                                        setSideMenuAccount("orderDetail");
                                        navigate({
                                          pathname: "/account/order-detail",
                                          search: `&orderId=${row.id}`,
                                        });
                                      }}
                                      className={`orderHisTableButton`}
                                    >
                                      {"Track Order"}
                                    </span>
                                  )}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="orderDate orderMob">
                          <div className="dateTab">
                            <div className="ordeDetailTitle">Order Date</div>
                            <div className="ordeDetailValue">
                              {row.orderDate}
                            </div>
                          </div>
                          <div className="dateTab">
                            <div className="ordeDetailTitle">Order Total</div>
                            <div className="ordeDetailValue">
                              ${addCommaDecimal(row.orderTotal)}
                            </div>
                          </div>
                          <div className="dateTab">
                            <div className="ordeDetailTitle">Place By</div>
                            <div className="ordeDetailValue">
                              {row?.placedBy ?? row?.customerName}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
              <div className="center">
                {tableData && tableData.length === 0 && (
                  <div className="noOrdemessage">
                    No Mathicng orders to show
                  </div>
                )}
              </div>
            </>
          )}
        </Grid>
      </Grid>
      <Box className="sdewsdfsdsdfsdfsdfsd">
        {currentDevice == "Mobile" && (
          <TabletViewBlackContainer currentDevice={currentDevice} />
        )}
      </Box>
    </div>
  );
};

export default OrderStatusHistory;
